import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Fragment } from "react";
import Loader from "../../layouts/Loader";
import { useSelector } from "react-redux";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
const ServicePaymentList = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [paginate, setPaginate] = useState(20);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentAction, SetPaymentAction] = useState("");
  const [id, setId] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [advanceSearch, setAdvanceSearch] = useState(false);

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  useMemo(() => {
    const url = new URL(window.location).searchParams;
    const start = url.get("start");
    const end = url.get("end");

    if (start) {
      console.log(start, "checkk");
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  const permissions = React.useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-service-payment-list?sort=${sort}&search=${search}&page=${page}&paginate=${paginate}&start=${startDate}&end=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        // setContent(res.data.consultantList?.data);
        // seCreatedBy(res.data.consultantList?.child);
        setContent(res.data?.serviceOrder?.data || []);
        setFrom(res.data.serviceOrder?.from);
        setTo(res.data?.serviceOrder?.to);
        setTotal(res.data?.serviceOrder.total);
        setPerPage(res.data?.serviceOrder?.per_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.response?.data?.message);
      });
  }, [sort, page, refresh, paginate, search, auth]);
  //

  useEffect(() => {
    if (paymentAction) {
      axios
        .post(
          "/api/crm/update-databank-payment",
          { paymentAction, id },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setRefresh(true);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, [paymentAction, id]);

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  console.log(content, "content");

  return (
    <MainWrapper>
      <main className="content pt-4">
        <div className="page-header mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="c-card-top">
                <div className="c-card-top-title ml-2">
                  <h4 className="mb-0">Service Payment List</h4>
                </div>
                <div className="c-card-top-inputs c-card-top-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                    <span className="custom-select">Search</span>

                    <Input
                      placeholder="Search here..."
                      onChange={handleSearch}
                      className="c-form-control-serch"
                    />
                    <div class="input-group-append">
                      <button
                        title="Advance Search"
                        onClick={() => {
                          advanceSearch
                            ? setAdvanceSearch(false)
                            : setAdvanceSearch(true);
                        }}
                        id="c_searchBlock_toggle"
                        class="btn btn-secondary btn-sm c-card-top-block-icon"
                        type="button"
                      >
                        <FiChevronDown />
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        id="button-addon2"
                      >
                        GO
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header d-flex justify-content-between align-items-center">
                    <div>
                      <button className="btn btn-secondary btn-sm mr-1">
                        <i className="fa fa-trash pr-1" /> Delete
                      </button>

                      <button
                        className="btn btn-secondary btn-sm btn-circle"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                    <div>
                      <select
                        id="sort"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custome-select small-select mr-1"
                        // style={{ width: 135 }}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    checked={
                                      deleteIds.length === content.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Mobile</th>
                                {/* {associateType == 5 ? (
                                  <>
                                    {" "}
                                    <th className="font-weight-bold">
                                      Institute Type
                                    </th>
                                  </>
                                ) : null} */}

                                <th className="font-weight-bold">Email</th>

                                <th className="font-weight-bold">
                                  Project Name
                                </th>

                                <th className="font-weight-bold">Amount</th>

                                <th className="font-weight-bold">
                                  Transaction Date
                                </th>

                                <th className="font-weight-bold">
                                  Payment Status
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7}>
                                        <Loader loading={loading} />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {content.length ? (
                                      <Fragment>
                                        {_.map(content, (is, i) => (
                                          <tr key={is?.id}>
                                            <td>{i + 1}</td>
                                            <td>
                                              <span
                                                style={{
                                                  color: "#3647c3",
                                                }}
                                              >
                                                {is.user ? is?.user?.name : ""}
                                              </span>
                                            </td>
                                            <td>
                                              {is.user
                                                ? is?.user?.mobile
                                                : null}
                                            </td>
                                            <td>
                                              {" "}
                                              {is.user ? is?.user?.email : null}
                                            </td>

                                            <td>{is?.title}</td>
                                            <td>{is?.amount}</td>

                                            <td>
                                              <strong>
                                                {moment(is.created_at).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </strong>
                                            </td>

                                            {/* <td>{is?.paymentsdd?.app_type}</td>

                                            <td>
                                              {is.paymentsdd.status ===
                                                "success" ||
                                              is.status === "SUCCESS"
                                                ? ""
                                                : is.error ??
                                                  "This is a temporary error. The customer must retry."}
                                            </td> */}

                                            {/* {permission.some(
                                              (item) =>
                                                item.name ===
                                                "crm-consultant-approve-view"
                                            ) && (
                                              <td>
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    handleApproved(e, is)
                                                  }
                                                  className={
                                                    is.consultant_approve
                                                      ? `badge badge-success text-white`
                                                      : `badge badge-danger text-white`
                                                  }
                                                >
                                                  {is.consultant_approve
                                                    ? "Active"
                                                    : "Approve"}
                                                </a>
                                              </td>
                                            )} */}
                                            <td>
                                              <span
                                                className={`badge ${
                                                  is.payment_status ===
                                                    "success" ||
                                                  is.payment_status ===
                                                    "SUCCESS"
                                                    ? "badge-success"
                                                    : "badge-danger"
                                                }`}
                                              >
                                                {is.payment_status}
                                              </span>
                                            </td>

                                            {/* <td>
                                              <div>
                                                <select
                                                  className="custome-select small-select mr-1"
                                                  // style={{ width: 135 }}
                                                  onChange={(e) => {
                                                    SetPaymentAction(
                                                      e.target.value
                                                    );
                                                    setId(is.id);
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>

                                                  <option value="SUCCESS">
                                                    SUCCESS
                                                  </option>
                                                  <option value="FAILED">
                                                    FAILED
                                                  </option>
                                                  <option value="CANCEL">
                                                    CANCEL
                                                  </option>
                                                </select>
                                              </div>
                                            </td> */}
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={7}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custom-select custom-select-page"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                        {/* <select className="custom-select custom-select-page">
                                                    <option selected>10</option>
                                                    <option value="1">
                                                        20
                                                    </option>
                                                    <option value="2">
                                                        30
                                                    </option>
                                                    <option value="3">
                                                        40
                                                    </option>
                                                </select> */}
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={perPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MainWrapper>
  );
};

export default ServicePaymentList;
