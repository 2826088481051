import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getLeadUser } from "../../store/actions/LeadActions";
import { isNull, size } from "lodash";
import { NotificationManager } from "react-notifications";
import Pusher from "pusher-js";
import { useParams, useLocation } from "react-router-dom";

const LeadPage = ({ history }) => {
  const [search, setSearch] = useState("");
  const [leadSearch, setLeadSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { isLoading, results } = useSelector(({ leadPage }) => leadPage);
  const { auth } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const leadData = useMemo(() => results, [results]);
  const { leads, user } = leadData;
  const params = useParams();
  const { search: sear } = useLocation();
  const { newMobile } = useSelector(({ lead }) => lead);
  const handleSearch = (e) => {
    e.preventDefault();
    if (!search)
      NotificationManager.error("Please Enter Mobile Number!!", "Error");
    setRefresh(true);
    dispatch(getLeadUser(search, auth?.token));
  };

  // console.log((sear).search(), "raa");
  useEffect(() => {
    const url = new URL(window.location).searchParams;
    const mo = url.get("mobile");
    if (mo?.length >= 10) {
      setSearch(mo);
      setLeadSearch(mo);
    }
  });

  useEffect(() => {
    let timer;
    if (leadSearch) {
      timer = setTimeout(() => {
        dispatch(getLeadUser(leadSearch, auth?.token));
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [leadSearch]);

  console.log(isLoading, "cehck");
  console.log(search, "check22");

  // console.log(url.get("mobile"));
  useEffect(() => {
    if (search && !isLoading) {
      if (user && !isNull(user)) {
        history.push(`/user-profile/${user?.id}`);
      } else if (leads && !isNull(leads)) {
        history.push(`/lead/${leads?.id}/details`);
      } else {
        localStorage.setItem("newMobile", JSON.stringify(search));
        dispatch({ type: "SET_MOBILE", payload: search });
        NotificationManager.error("Mobile number does not exits!!", "Error");
        history.push(`/leads/add`);
      }
    }
  }, [leads, user, refresh, isLoading]);

  // Pusher.logToConsole = true;

  var pusher = new Pusher("90534977c42d10c5c54d", {
    cluster: "ap2",
  });

  var channel = pusher.subscribe("inbound-call");
  channel.bind("incoming-call", function (data) {
    var d = data;
    var s = JSON.parse(JSON.stringify(d.message));
    var z = Object.entries(s)[0][0];
    var sc = JSON.parse(z);
    setSearch(
      sc.caller_id_number?.startsWith("0")
        ? sc.caller_id_number.slice(1, sc.caller_id_number.length)
        : sc.caller_id_number
    );
    localStorage.setItem(
      "newMobile",
      JSON.stringify(
        sc.caller_id_number?.startsWith("0")
          ? sc.caller_id_number.slice(1, sc.caller_id_number.length)
          : sc.caller_id_number
      )
    );
    dispatch({ type: "SET_MOBILE", payload: search });
    window.location.reload();
  });

  return (
    <>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Lead</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12">
                <div className="text-right right-side-links">
                  <div className="btn-group mr-1 advance-search-options">
                    <div className="c-card-top-inputs c-card-top-search w-100">
                      <div className="input-group">
                        <Input
                          placeholder="Enter Mobile Number...."
                          className="c-form-control-serch"
                          onChange={(e) => {
                            !isNaN(e.target.value) &&
                              e.target.value.length <= 12 &&
                              setSearch(e.currentTarget.value);
                          }}
                          value={search}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleSearch(e)
                          }
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-secondary btn-lg"
                            type="button"
                            onClick={handleSearch}
                          >
                            {isLoading ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              "Go"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </>
  );
};

export default LeadPage;
