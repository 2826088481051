import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../components/MainWrapper";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import { createSourceApi, getSourceList } from "../store/actions/LeadActions";
import { Link } from "react-router-dom";

import { FaSpinner } from "react-icons/fa";
import _ from "lodash";
import moment from "moment";

import Select, { components } from "react-select";
import { getCrmUsersApi } from "../store/actions/LeadActions";
import { useDispatch, useSelector } from "react-redux";
const Create = () => {
  const [professionalservices, setProfessionalServices] = useState([]);
  const [professionalName, setProfessionalName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [expertName, setExpertName] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [mailShared, setMailShared] = useState("");
  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [addSource, setAddSource] = useState(false);
  const [leadSource, setleadSource] = useState("");
  const [mobile, setMobile] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [segment, setSegment] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [sources, setSources] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [industries, setIndustries] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [industory, setIndustory] = useState([]);
  const [name, setName] = useState("");
  const [subcategory, setSubCategory] = useState([]);
  const [subsubcategory, setSubSubcategory] = useState([]);
  const [instituteType, setInstituteType] = useState("");
  const [source, setSource] = useState("");
  const [subsubcategories, setSubSubCategories] = useState([]);
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [multiAssociate, setMultiAssociate] = useState([
    { value: 1, label: "Professional Expert" },
  ]);
  const [genralNotes, setGenralNotes] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [other, setOther] = useState("");
  const [linkedID, setLinkedID] = useState("");
  const [otherIds, setOtherIds] = useState("");
  const [facebookID, setFacebookID] = useState("");
  const [instagramID, setInstagramID] = useState("");

  const [eventDate, setEventDate] = useState("");
  const [eventName, setEventName] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD") || "");
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => auth);
  const [assignTo, setAssignTo] = useState(auth?.id || "");
  const {
    leadData,
    createSource: createSourcee,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
  } = useSelector(({ lead }) => lead);

  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  useEffect(() => {
    axios
      .get(`/api/crm/sectordata-mega`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        
        setIndustries(res.data.subcategory);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/crm/professionalServices`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setProfessionalServices(res.data.professionalservices);
      })
      .catch((err) => console.log(err));

    dispatch(getCrmUsersApi(auth?.token));
  }, [auth]);
  useEffect(() => {
    console.log(industory);
    if (!_.isNull(industory)) {
      axios
        .get(
          `/api/crm/subsectordatanew?industry=${industory
            .map((item) => item?.value)
            .join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.subSubCats);
          setSubSubCategories(res.data.subSubCats);
        })
        .catch((err) => console.log(err));
      // }
    }
  }, [industory]);
  useEffect(() => {
    const subCat = subcategory ? subcategory.map((s) => s.value) : [];
    const subSubCat = subsubcategory
      ? subsubcategory.filter((s) => s.parent === 0).map((s) => s.id)
      : [];

    let sub = subCat
      ? subsubcategory &&
        subsubcategory.filter((res) => subCat.find((p) => res.parent === p))
      : [];
    setSubSubcategory(sub);
  }, [subcategory]);
  useEffect(() => {
    // $('#profilePic').dropify().on('dropify.afterClear', function () {
    //     setProfilePic('');
    // });
  }, []);
  const handleIndustriesChange = (value) => {
    setSubCategory(value);
    setIndustory(value);
  };
  const handleAssociate = (value) => {
    setMultiAssociate(value);
    setSubCategory([]);
    setIndustory([]);
  };

  const mAssociate = useMemo(() => {
    if (multiAssociate) {
      return multiAssociate.map((m) => m.value).join(",");
    } else {
      return null;
    }
  }, [multiAssociate]);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("working");
    const data = new FormData();
    //data.append("associate", associate);
    data.append("associate", mAssociate);
    data.append("professionalName", professionalName);
    data.append("supplier", supplier);
    data.append("bussinessName", bussinessName);
    data.append("expertName", expertName);
    subcategory
      ? subcategory.map((s, i) => {
          data.append(`subcategory[${i}][value]`, s?.value);
          data.append(`subcategory[${i}][label]`, s?.label);
          data.append(
            `subcategory[${i}][child]`,
            subsubcategory
              ?.filter((st) => st?.parent == s?.value)
              ?.map((sm) => sm?.value)
              .join(",")
          );
        })
      : data.append("subcategory[]", []);
    subsubcategory
      ? subsubcategory.map((s, i) => {
          data.append(`subsubcategory[${i}][id]`, s?.id);
          data.append(`subsubcategory[${i}][value]`, s?.value);
          data.append(`subsubcategory[${i}][label]`, s?.label);
          data.append(`subsubcategory[${i}][parent]`, s?.parent);
        })
      : data.append("subcategory[]", []);
    data.append("address", address);
    data.append("remarks", remarks);
    data.append("pincode", pincode);
    data.append("city", city);
    data.append("state", state);
    data.append("contactPerson", contactPerson);
    data.append("mobile", mobile);
    data.append("name", name);
    data.append("phoneNo", phoneNo);
    data.append("assign_to", assignTo);
    data.append("lead_date", leadDate);
    data.append("email", email);
    data.append("websiteUrl", websiteUrl);
    data.append("contactPerson", contactPerson);
    data.append("designation", designation);
    data.append("experience", experience);
    data.append("profilePic", profilePic ? profilePic : "");
    data.append("other", other);
    data.append("source", leadSource);
    data.append("instituteType", instituteType);
    data.append("note", genralNotes);
    data.append("lead_status", leadStatus);
    data.append("eventdate", eventDate);
    data.append("eventname", eventName);
    data.append("disposition_type", dispositiontype);
    data.append("disposition", disposition);
    data.append("mail_shared", mailShared);
    data.append(
      "social_ids",
      JSON.stringify({
        linkedinid: linkedID,
        instagramid: instagramID,
        facebookid: facebookID,
        otherId: otherIds,
      })
    );
    setLoading(true);
    setErrors({});
    axios
      .post(`/api/crm/add-consultant`, data, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        NotificationManager.success(res.data.message);

        setTimeout(() => {
          window.location.href = "/consultant";
        }, 1000);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setLoading(false);
          setErrors(err?.response?.data?.errors);
          NotificationManager.error("Somthing went wrong");
        } else {
          setLoading(false);
          NotificationManager.error("Somthing went wrong");
        }
      });
  };
  let obj = { value: "others", label: "Others" };

  useEffect(() => {
    dispatch(getSourceList(auth?.token));
  }, [auth?.token]);

  const handleAddSource = (e) => {
    e.preventDefault();
    const data = { sourceName };
    dispatch(createSourceApi(data));
  };

  const handleSource = (e) => {
    e.preventDefault();
    setAddSource(!addSource);
  };
  const handlePinCodeChange = (e) => {
    const { value } = e.target;
    if (value.length < 7 || _.isEmpty(value)) {
      setPincode(value);
    }
    if (e.target.value.length === 6) {
      axios(`https://api.postalpincode.in/pincode/${e.target.value}`).then(
        (res) => {
          if (res.data[0].Status === "Success") {
            setCity(res.data[0].PostOffice[0].Block);
            setState(res.data[0].PostOffice[0].State);
          }
        }
      );
    } else {
      setCity("");
      setState("");
    }
  };
  const getType = (type) => {
    switch (type) {
      case 1:
        return "fan";

      default:
        return "";
    }
  };

  return (
    <MainWrapper>
      <main className="content">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>CREATE CONSULTANT</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Assigned To
                    </label>

                    <Input
                      className="custom-select col-md-7"
                      type="select"
                      id="assignTo"
                      disabled={true}
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                      invalid={!!errors?.assign_to}
                    >
                      <option value="">select</option>

                      {(Array.isArray(leadData.users)
                        ? leadData?.users
                        : []
                      ).map((item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      disabled={true}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="associate"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Associate with us as{" "}
                    </Label>
                    <Select
                      isMulti
                      options={[
                        { value: 1, label: "Professional Expert" },
                        { value: 2, label: "Supplier" },
                        { value: 3, label: "Industry Expert" },
                        { value: 4, label: "Enterpreneur" },
                        { value: 5, label: "Institutional" },
                        { value: 6, label: "Exhibitors" },
                        { value: 7, label: "Visitors" },
                        { value: 8, label: "Franchise Network" },
                        { value: 9, label: "Startup Network" },
                        { value: 10, label: "Others" },
                        { value: 11, label: "Tata Play" },
                        { value: 12, label: "Lecturer" },
                        { value: 13, label: "Brand Onboarding" },
                      ]}
                      onChange={handleAssociate}
                      value={multiAssociate}
                      invalid={!!errors.associate}
                      className="col-md-7 p-0"
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.associate ? errors.associate[0] : ""}
                    </FormFeedback>
                    {/* {multiAssociate?.value==10&&"hi"} */}
                    {(multiAssociate ? multiAssociate : []).map((item) => {
                      if (item?.value == 10)
                        return (
                          <>
                            <Label
                              for="associate"
                              className="col-md-5 control-label col-form-label col-12 text-md-right my-2"
                            >
                              Enter Other Associate name{" "}
                            </Label>
                            <Input
                              id="associate"
                              className="col-md-7 my-2"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </>
                        );
                    })}
                  </FormGroup>
                </Col>
                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 1) ||
                  multiAssociate.find((m) => m.value == 4) ||
                  multiAssociate.find((m) => m.value == 6) ||
                  multiAssociate.find((m) => m.value == 7) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="professionalName"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          {multiAssociate.find((m) => m.value == 4)
                            ? "Enterpreneur Name"
                            : "Professional Name"}
                        </Label>
                        <Input
                          id="professionalName"
                          className="col-md-7"
                          value={professionalName}
                          onChange={(e) => setProfessionalName(e.target.value)}
                          invalid={!!errors.professionalName}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.professionalName
                            ? errors.professionalName[0]
                            : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}
                {multiAssociate ? (
                  multiAssociate?.find((m) => m.value == 2) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="supplier"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Supplier Type
                        </Label>
                        <Input
                          type="select"
                          id="supplier"
                          className="col-md-7 custom-select"
                          value={supplier}
                          onChange={(e) => setSupplier(e.target.value)}
                          invalid={!!errors.supplier}
                        >
                          <option value="">select</option>
                          <option value="1">Platinum</option>
                          <option value="2">Gold</option>
                          <option value="3">Silver</option>
                          <option value="4">General</option>
                        </Input>
                        <FormFeedback className="offset-5">
                          {!!errors.supplier ? errors.supplier[0] : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate?.find((m) => m.value == 3) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="expertName"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Expert Name
                        </Label>
                        <Input
                          id="expertName"
                          className="col-md-7"
                          value={expertName}
                          onChange={(e) => setExpertName(e.target.value)}
                          invalid={!!errors.expertName}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.expertName ? errors.expertName[0] : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 2) ||
                  multiAssociate.find((m) => m.value == 4) ||
                  multiAssociate.find((m) => m.value == 5) ||
                  multiAssociate.find((m) => m.value == 11) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="bussinessName"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          {multiAssociate.find((m) => m.value == 5)
                            ? "Institutional Name"
                            : "Bussiness Name"}
                        </Label>
                        <Input
                          id="bussinessName"
                          className="col-md-7"
                          value={bussinessName}
                          onChange={(e) => setBussinessName(e.target.value)}
                          invalid={!!errors.bussinessName}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.bussinessName
                            ? errors.bussinessName[0]
                            : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}
                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 5) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="instituteType"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Choose Institute Type
                        </Label>
                        <Input
                          type="select"
                          id="instituteType"
                          className="col-md-7 custom-select"
                          value={instituteType}
                          onChange={(e) => setInstituteType(e.target.value)}
                        >
                          <option value="Select">Select</option>
                          <option value="University">University</option>
                          <option value="Engineering College">
                            Engineering College
                          </option>
                          <option value="Medical College">
                            Medical College
                          </option>
                          <option value="Research Institute">
                            Research Institute
                          </option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="College">College</option>
                          <option value="Sector Skill Council">
                            Sector Skill Council
                          </option>
                          <option value="Association">Association</option>
                          <option value="Industry Institution">
                            Industry Institution
                          </option>
                          <option value="Research Institution">
                            Research Institution
                          </option>
                          <option value="Finance Institution">
                            Finance Institution
                          </option>
                          <option value="Goverment Institution">
                            Goverment Institution
                          </option>
                          <option value="Ngo Institution">
                            Ngo Institution
                          </option>
                          <option value="Incubation Institution">
                            Incubation Institution
                          </option>
                          <option value="Skill Institution">
                            Skill Institution
                          </option>
                          <option value="Social Media Institution">
                            Social Media Institution
                          </option>
                          <option value="Other">Other</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}
                {multiAssociate ? (
                  multiAssociate.find((m) => m.value != 5) ? (
                    <>
                      {multiAssociate ? (
                        multiAssociate?.find((m) => m.value == 1) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="industries"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Service Category
                              </Label>
                              <Select
                                isMulti
                                options={[...professionalservices, obj]}
                                onChange={handleIndustriesChange}
                                value={industory}
                                invalid={!!errors.professionalservices}
                                className="col-md-7 p-0"
                              />
                              <FormFeedback className="d-block">
                                {!!errors.professionalservices
                                  ? errors.professionalservices[0]
                                  : ""}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="industries"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Sector/Industry
                              </Label>
                              <Select
                                isMulti
                                options={[...industries, obj]}
                                className="col-md-7 p-0"
                                onChange={handleIndustriesChange}
                                value={industory}
                              />
                              <FormFeedback className="d-block offset-5">
                                {!!errors.industory ? errors.industory[0] : ""}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        )
                      ) : null}

                      {industory?.some((item) => item.value === "others") ? (
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="other"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Other
                            </Label>
                            <Input
                              id="other"
                              className="col-md-7"
                              value={other}
                              onChange={(e) => setOther(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="industries"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Choose Industry
                            </Label>
                            <Select
                              options={subsubcategories}
                              isMulti
                              className="col-md-7 p-0"
                              onChange={(value) => {
                                if (value) {
                                  const parent = value.filter(
                                    (v) => v.parent === 0
                                  );
                                  value = value.filter(
                                    (v) =>
                                      !parent.some((p) => p.id === v.parent)
                                  );
                                }
                                setSubSubcategory(value);
                              }}
                              value={subsubcategory}
                            />
                            <FormFeedback className="d-block offset-5">
                              {!!errors.subsubcategory
                                ? errors.subsubcategory[0]
                                : ""}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                    </>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 13) ? (
                    <Col md={6}>
                      <FormGroup className="mb-3">
                        <Row className="align-items-center">
                          <Col md={5} className="text-md-right">
                            <Label>Segment</Label>
                          </Col>

                          <Col>
                            <Input
                              type="select"
                              value={segment}
                              onChange={(e) => setSegment(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Brand Acquisition">
                                Brand Acquisition
                              </option>
                              <option value="Grow Your Franchise">
                                Grow Your Franchise
                              </option>
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      id="address"
                      className="col-md-7"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      invalid={!!errors.address}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.address ? errors.address[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="pincode"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Pincode
                    </Label>
                    <Input
                      id="pincode"
                      className="col-md-7"
                      value={pincode}
                      onChange={(e) => {
                        setPincode(e.target.value);
                        handlePinCodeChange(e);
                      }}
                      invalid={!!errors.pincode}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.pincode ? errors.pincode[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="city"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      id="city"
                      className="col-md-7"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      invalid={!!errors.city}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.city ? errors.city[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="state"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      id="state"
                      className="col-md-7"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      invalid={!!errors.state}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.state ? errors.state[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="contactPerson"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Contact Person
                    </Label>
                    <Input
                      id="contactPerson"
                      className="col-md-7"
                      value={contactPerson}
                      onChange={(e) => setContactPerson(e.target.value)}
                      invalid={!!errors.contactPerson}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.contactPerson ? errors.contactPerson[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile No
                    </Label>
                    <Input
                      id="mobile"
                      className="col-md-7"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      invalid={!!errors.mobile}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.mobile ? errors.mobile[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="phoneNo"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Whatsapp No
                    </Label>
                    <Input
                      id="phoneNo"
                      className="col-md-7"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      invalid={!!errors.phoneNo}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.phoneNo ? errors.phoneNo[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email Id
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      className="col-md-7"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!!errors.email}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.email ? errors.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="websiteUrl"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Website(if any)
                    </Label>
                    <Input
                      id="websiteUrl"
                      className="col-md-7"
                      value={websiteUrl}
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                      invalid={!!errors.websiteUrl}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.websiteUrl ? errors.websiteUrl[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="designation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Designation<span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="designation"
                      className="col-md-7"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      invalid={errors.designation}
                    />
                    <FormFeedback className="offset-5">
                      {errors.designation}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="experience"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Total experience<span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="experience"
                      className="col-md-7"
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                      invalid={errors.experience}
                    />
                    <FormFeedback className="offset-5">
                      {errors.experience}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="row">
                    <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                      Source
                    </label>
                    <div className="col-lg-8 position-relative">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="form-control p-0 border-0">
                            <Input
                              type="select"
                              className="custom-select"
                              value={leadSource}
                              onChange={(e) => setleadSource(e.target.value)}
                            >
                              <option>Select</option>
                              {sources.map((asu) => (
                                <option value={asu.label} key={asu.label}>
                                  {asu.label}
                                </option>
                              ))}
                            </Input>
                          </div>

                          <span className="input-group-append input-group-btn">
                            <Link
                              className="btn btn-default btn-flat openSourcePop"
                              to="#"
                              onClick={handleSource}
                            >
                              <i className="fa fa-plus"></i>
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div
                        id="addSourcePop"
                        className="dv3 dvPopB"
                        style={{ display: addSource ? "" : "none" }}
                      >
                        <div className="arrowB"></div>
                        <div className="arrow-borderB"></div>
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="popHeading text-left">
                                Create Source
                              </td>
                            </tr>
                            <tr>
                              <td className="pl-2 pr-2">
                                <div id="container">
                                  <div id="dvLeft">
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td align="left" className="tdcss">
                                            <label className="lblcss">
                                              Source Name
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" className="tdcss">
                                            <Input
                                              type="text"
                                              name="source_name"
                                              id="source_name"
                                              value={sourceName}
                                              onChange={(e) =>
                                                setSourceName(e.target.value)
                                              }
                                              className="textcl w-100"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center pl-2 pt-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={handleAddSource}
                                >
                                  {createSourceLoading ? (
                                    <FaSpinner className="fa-spin" />
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger closeSourcePop ml-2"
                                  onClick={handleSource}
                                >
                                  Close
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="instituteType"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Source
                    </Label>
                    <Input
                      type="select"
                      id="source"
                      className="col-md-7 custom-select"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="IID Reference">IID Reference</option>
                      <option value="Google">Google</option>
                      <option value="Exibition">Exhibition</option>
                      <option value="Facebook">youbtube</option>
                      <option value="walking">walking</option>
                      <option value="event">Event</option>
                      <option value="other">Other</option>
                    </Input>
                  </FormGroup>
                </Col> */}

                {leadSource == "Events" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="websiteUrl"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Event Date
                      </Label>
                      <Input
                        type="date"
                        className="col-md-7"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        // invalid={!!errors.websiteUrl}
                      />
                      {/* <FormFeedback className="offset-5">
                      {!!errors.websiteUrl ? errors.websiteUrl[0] : ""}
                    </FormFeedback> */}
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                {leadSource == "Events" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="websiteUrl"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Event Name
                      </Label>
                      <Input
                        id="websiteUrl"
                        className="col-md-7"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                      <FormFeedback className="offset-5">
                        {/* {!!errors.websiteUrl ? errors.websiteUrl[0] : ""} */}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.remarks ? errors.remarks[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    {multiAssociate ? (
                      multiAssociate?.find((m) => m.value == 0) ||
                      multiAssociate?.find((m) => m.value == 1) ||
                      multiAssociate?.find((m) => m.value == 3) ||
                      multiAssociate?.find((m) => m.value == 6) ||
                      multiAssociate?.find((m) => m.value == 7) ||
                      multiAssociate?.find((m) => m.value == 10) ||
                      multiAssociate?.find((m) => m.value == 13) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Profile Pic
                        </Label>
                      ) : null
                    ) : null}
                    {!multiAssociate && (
                      <Label
                        for="profilePic"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Profile Pic
                      </Label>
                    )}
                    {multiAssociate ? (
                      multiAssociate?.find((m) => m.value == 2) ||
                      multiAssociate?.find((m) => m.value == 11) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Company Logo/Profile Pic
                        </Label>
                      ) : null
                    ) : null}

                    {multiAssociate ? (
                      multiAssociate?.find((m) => m.value == 4) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Business Logo
                        </Label>
                      ) : null
                    ) : null}
                    {multiAssociate ? (
                      multiAssociate?.find((m) => m.value == 5) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Institutional Logo
                        </Label>
                      ) : null
                    ) : null}

                    <Col md={7} className="p-0">
                      <Input
                        type="file"
                        id="profilePic"
                        className="dropify"
                        onChange={(e) => setProfilePic(e.target.files[0])}
                        invalid={!!errors.profilePic}
                      />
                    </Col>
                    <FormFeedback className="offset-5">
                      {!!errors.profilePic ? errors.profilePic[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      General Notes
                    </Label>

                    <textarea
                      className="w-100 col-md-7 mb-3"
                      value={genralNotes}
                      onChange={({ target }) => setGenralNotes(target.value)}
                    />
                  </Row>
                </Col>

                <Col md={6}>
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Lead Status
                    </Label>

                    <Input
                      type="select"
                      value={leadStatus}
                      onChange={({ target }) => setLeadStatus(target.value)}
                      className="w-100 col-md-7 mb-3"
                    >
                      <option value="">Select</option>
                      <option value="converted">Converted</option>
                      <option value="active">Active</option>
                      <option value="shoot-plan">Shoot Plan</option>
                      <option value="follow-up">Follow up</option>
                      <option value="not-intrested">Not Intrested</option>
                    </Input>
                  </Row>
                </Col>

                <div className="col-md-6">
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Social Media
                    </Label>

                    <Select
                      isMulti
                      options={[
                        { value: "linkedinid", label: "Linkedin ID" },
                        { value: "facebookid", label: "Facebook ID" },
                        { value: "instaid", label: "Instagram ID" },
                        { value: "other", label: "Other" },
                      ]}
                      className="basic-multi-select col-md-7  mb-3 px-0"
                      classNamePrefix="select"
                      onChange={(val) => setLeadId(val || [])}
                    />
                  </Row>
                </div>

                {leadId?.some((item) => item.value === "linkedinid") && (
                  <div className="col-md-6">
                    <Row>
                      <Label className="col-md-5 text-md-right">
                        Linkedin ID
                      </Label>

                      <Input
                        className="col-md-7"
                        value={linkedID}
                        onChange={({ target }) => setLinkedID(target.value)}
                      />
                    </Row>
                  </div>
                )}

                {leadId?.some((item) => item.value === "facebookid") && (
                  <div className="col-md-6">
                    <Row>
                      <Label className="col-md-5 text-md-right">
                        Facebook ID
                      </Label>

                      <Input
                        className="col-md-7"
                        value={facebookID}
                        onChange={({ target }) => setFacebookID(target.value)}
                      />
                    </Row>
                  </div>
                )}

                {leadId.some((item) => item.value === "instaid") && (
                  <div className="col-md-6">
                    <Row>
                      <Label className="col-md-5 text-md-right">
                        Instagram ID
                      </Label>

                      <Input
                        className="col-md-7"
                        value={instagramID}
                        onChange={({ target }) => setInstagramID(target.value)}
                      />
                    </Row>
                  </div>
                )}

                {leadId.some((item) => item.value === "other") && (
                  <div className="col-md-6 ">
                    <Row>
                      <Label className="col-md-5 text-md-right">Other</Label>

                      <Input
                        className="col-md-7 "
                        value={otherIds}
                        onChange={({ target }) => setOtherIds(target.value)}
                      />
                    </Row>
                  </div>
                )}
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Disposition Type
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDispositionType(e.target.value)}
                      value={dispositiontype}
                    >
                      <option value=""> Select </option>

                      <option value="CallBack/Busy">CallBack/Busy</option>
                      <option value="Franchise Fee Picked">
                        Franchise Fee Picked
                      </option>
                      <option value="Membership Picked">
                        Membership Picked
                      </option>
                      <option value="On Negotiation">On Negotiation</option>
                      <option value="Praposal Shared">Praposal Shared</option>
                      <option value="Budget Issue">Budget Issue</option>
                      <option value="Wrong Number">Wrong Number</option>

                      <option value="Meeting Met">Meeting Met</option>
                      <option value="Not Reachable">Not Reachable</option>
                      <option value="Not Answering/Ringing">
                        Not Answering/Ringing
                      </option>
                      <option value="Invalid_Number">Invalid Number</option>
                      <option value="Meeting Booked">Meeting Booked</option>
                      <option value="Token Picked">Token Picked</option>
                      <option value="Membership Picked">
                        Membership Picked
                      </option>
                      <option value="Fee Picked">Fee Picked</option>
                      <option value="Switch_Off">Switch off</option>
                      <option value="Dump Lead">Dump Lead</option>
                      <option value="Follow_Up_Hot">Follow up(Hot)</option>
                      <option value="Meeting_Align">Meeting Align</option>
                      <option value="Meeting_Done">Meeting Done</option>
                      <option value="Follow_Up">Follow up</option>
                      <option value="Call_Back">Call Back</option>
                      <option value="Not_Interested">Not Interested</option>
                      <option value="Payment_Done">Payment Done</option>
                      <option value="Call_Rejected">Call Rejected</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Is Mail Shared</Label>
                      </Col>

                      <Col>
                        <Input
                          type="select"
                          value={mailShared}
                          onChange={(e) => setMailShared(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </main>
    </MainWrapper>
  );
};

export default Create;
