import React, { useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import EditCustomizeProjectReport from "./EditCustomizeProjectReport";
const CustomizeProjectReport = ({ leadDetails, setWsStatus }) => {
  const boodedUser = useMemo(() => {
    if (leadDetails) return leadDetails;
  }, [leadDetails]);

  const bookedProjectReport = useMemo(() => {
    if (leadDetails) return leadDetails?.user_customize_report;
  }, [leadDetails]);

  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-3">
            <Link
              className="card-title m-0 h5 text-white d-block p-2 collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapse-6"
              aria-expanded="true"
              aria-controls="collapse-6"
            >
              Customize Project Report ({_.size(bookedProjectReport)})
            </Link>
          </div>
          <div
            id="collapse-6"
            className="collapse"
            aria-labelledby="heading-6"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Payment Id</th>
                      <th>Payment Mode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookedProjectReport
                      ? bookedProjectReport.map((customizeReport, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>{boodedUser?.name}</td>
                            <td>{boodedUser?.mobile}</td>
                            <td>{boodedUser?.email}</td>
                            <td>{customizeReport.requirement}</td>
                            <td>{customizeReport.actual_price}</td>
                            <td>{customizeReport.payment_id}</td>
                            <td>{customizeReport.payment_mode}</td>
                            {customizeReport.agentcode == null ? (
                              <td>
                                <EditCustomizeProjectReport
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  item={customizeReport}
                                  bookedProjectReport={bookedProjectReport}
                                  projectId={customizeReport?.customizeId}
                                />
                              </td>
                            ) : null}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizeProjectReport;
