/* eslint-disable default-case */
import _ from "lodash";

const initialState = {
  edp: {},
  edpcreated: false,
  loading: false,
  error: {},
  errors:{},
  edpStatus:false
};

const edpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_EDP":
      state = {
        ...state,
        loading: true,
        error: {},
        edpStatus:false
      };
      break;
    case "ADD_EDP_FULFILLED":
      const { payload } = action;
      state = {
        ...state,
        loading: false,
        edpcreated: true,
        edpStatus:true,
        edp: _.get(payload, "data", {}),
        isAuthenticated: true,
      };

      break;
    case "ADD_EDP_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          edpStatus:false,
          errors: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          edpStatus:false,
          errors: _.get(data, "message", {}),
        };
      }
      break;
    case "ADD_EDP_RESET":
      state = {
        ...state,
        loading: false,
        edpcreated: false,
      };

      break;
  }

  return state;
};

export default edpReducer;
