/* eslint-disable default-case */
import _ from "lodash";

const initialState = {
  courses: [],
  bookservices: {},
  servicespurchased: false,
  loading: false,
  error: {},
  errors:{},
  loading: false,
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BOOK_SERVICES":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "BOOK_SERVICES_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        servicespurchased: true,
        bookservices: _.get(payload, "data", {}),
      };

      case "RESET_PURCHASED":
        return{
          ...state,
          servicespurchased:action.payload
        }

      break;
    case "BOOK_SERVICES_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          errors: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          errors: _.get(data, "message", {}),
        };
      }
      break;
  }

  return state;
};

export default serviceReducer;
