import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../components/MainWrapper";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  CustomInput,
} from "reactstrap";
import { Fragment } from "react";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus, FaSpinner } from "react-icons/fa";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { getCrmUsersApi } from "../store/actions/LeadActions";
import { useDispatch, useSelector } from "react-redux";
const Create = () => {
  const [name, setName] = useState("");
  const [expertName, setExpertName] = useState("");

  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD") || "");
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => auth);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("working");
    const data = new FormData();
    data.append("name", name);
    data.append("address", address);
    data.append("remarks", remarks);
    data.append("pincode", pincode);
    data.append("city", city);
    data.append("state", state);
    data.append("mobile", mobile);
    data.append("email", email);
    data.append("lead_date", leadDate);
    setLoading(true);
    axios
      .post(`/api/crm/add-pmfme`, data, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        NotificationManager.success(res.data.message);

        setTimeout(() => {
          window.location.href = "/pmfme";
        }, 1000);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setLoading(false);
          setErrors(err?.response?.data?.errors);
          NotificationManager.error("Somthing went wrong");
        } else {
          setLoading(false);
          NotificationManager.error("Somthing went wrong");
        }
      });
  };

  return (
    <MainWrapper>
      <main className="content">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>CREATE PMFME</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      disabled={true}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="bussinessName"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Name
                    </Label>
                    <Input
                      id="bussinessName"
                      className="col-md-7"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      invalid={!!errors.name}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.name ? errors.name[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="city"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      id="city"
                      className="col-md-7"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      invalid={!!errors.city}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.city ? errors.city[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="state"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      id="state"
                      className="col-md-7"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      invalid={!!errors.state}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.state ? errors.state[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile No
                    </Label>
                    <Input
                      id="mobile"
                      className="col-md-7"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      invalid={!!errors.mobile}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.mobile ? errors.mobile[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email Id
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      className="col-md-7"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!!errors.email}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.email ? errors.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.remarks ? errors.remarks[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/pmfme")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </main>
    </MainWrapper>
  );
};

export default Create;
