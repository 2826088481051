import axios from "axios";
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FaList, FaTrash } from "react-icons/fa";
import { FiEdit, FiTrash } from "react-icons/fi";
import MainWrapper from "../../components/MainWrapper";
import { NotificationManager } from "react-notifications";
import {
    Button,
    ButtonDropdown,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    CardFooter

} from "reactstrap";
import { map, capitalize, last, split, mapValues } from "lodash";
import { FiEye, FiUpload } from "react-icons/fi";
import Pagination from "react-js-pagination";

const PaidServiceIprRequest = memo(() => {
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [statuses, setStatuses] = useState({});
    const [prevData, setPrevData] = useState({
        value: "",
        idx: "",
        key: ""
    });
    const [modal, setModal] = useState(false);
    const [feedBack, setFeedBack] = useState("");
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState(2);
    const [paginate, setPaginate] = useState(10);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("desc");
    const [uploadmodal, setUploadModal] = useState(false);
    const [fields, setFields] = useState({});
    const [uploadFile, setUploadFile] = useState();
    const [member, setMemeber] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteIds, setDeleteIds] = useState("");
    const [singleuser, setSingle] = useState({});
    const [serviceStatus, setServiceStatus] = useState("");
    const dropdownOpenToggle = () =>
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            axios.get(`/fssai-delete/${id}`, id).then(res => {
                setLoading(false);
                setRefresh(refresh => refresh + 1);
                setDeleteIds([]);
                NotificationManager.success(res.data.message);
                console.log(res.data, 'delete');
            }).catch(err => {
                setLoading(false);
                NotificationManager.error(err.response.data.message);

            });
        }
    };


    useEffect(() => {
        axios.get(`/api/crm/paid-service-requests/details?activeTab=${activeTab}&search=${search}&sort=${sort}&paginate=${paginate}&page=${page}&service_status=${serviceStatus}`)
            .then(res => {
                setData(res.data.iprDetails.data);
                setFrom(res.data.iprDetails.from);
                setTo(res.data.iprDetails.to);
                setTotal(res.data.iprDetails.total);
                setPerPage(res.data.iprDetails.per_page);
                mapValues(res.data.iprDetails, (value, key) => {
                    setStatuses(prevState => {
                        return {
                            ...prevState,
                            [key]: map(value, v => ({
                                id: v.id,
                                status: v.status
                            }))
                        };
                    });
                });
            })
            .catch(err => console.log(err.response));
    }, [refresh, activeTab, search, sort, paginate, page, serviceStatus]);
    const handleView = (e, data, user) => {

        e.preventDefault();
        setModal(true);
        setFields(data);
        setSingle(user);


        axios
            .get(`/getUserbyId/` + user.id)
            .then(res => {

                setMemeber(res.data.memebership);
            })
            .catch(err => console.log(err));
    };
    const datediff = (date1, date2) => {
        const date4 = new Date(date1);
        const date5 = new Date();

        const diffInMs = Math.abs(date5 - date4);
        if (diffInMs > 0) {
            return Math.round(diffInMs / (1000 * 60 * 60 * 24));
        } else {
            return 0;
        }
    };
    const handleStatusChange = (value, idx, key, file) => {
        if (value === "INVALID") {
            setPrevData({
                value,
                idx,
                key
            });
            setModal(true);
        } else if (file != "" && value != 'COMPLETED' && value != 'IN_PROGRESS') {
            NotificationManager.error("do not change status on this stage..")
        } else if (file == null && value === 'COMPLETED') {
            NotificationManager.error("Please Upload File..")
        } else {
            setPrevData({
                value,
                idx,
                key
            });
            setStatuses(prev => {
                const updatedKey = prev[key];
                updatedKey[idx].status = value;
                return {
                    ...prev,
                    [key]: updatedKey
                };
            });
            handleStatusSubmit(value, idx, key);
        }
    };
    const handleFeedbackSubmit = () => {
        setStatuses(prev => {
            const updatedKey = prev[prevData.key];
            updatedKey[prevData.idx].status = prevData.value;
            return {
                ...prev,
                [prevData.key]: updatedKey
            };
        });
        setModal(false);
        handleStatusSubmit();
    };

    const handleStatusSubmit = (value, idx, key) => {
        axios.post(`/service-requests/status`, {
            key: key ?? prevData.key,
            status: statuses[key ?? prevData.key]?.[idx ?? prevData.idx],
            feedBack
        })
            .then(() => {
                setPrevData({
                    value: "",
                    idx: "",
                    key: ""
                });
                setFeedBack("");
                setRefresh(prev => prev + 1);
            })
            .catch(error =>
                console.log(
                    "🚀 ~ file: ServiceRequests.js ~ line 105 ~ handleStatusSubmit ~ error",
                    error
                )
            );
    };
    const UploadView = (e, data, user, partner) => {

        e.preventDefault();
        setUploadModal(true);
        setFields(data);



    };
    const submitForm = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("id", fields.id);
        data.append("uploadFile", uploadFile);



        axios.post('/service-requests/iprfile', data).then(res => {
            NotificationManager.success('Uploaded successfully');
            window.location.reload();
            setUploadModal(false);

        }).catch(err => NotificationManager.error(err.response.data.message));
        //  updateProfile(data, token);
    };
    const handleSearch = e => {
        setPage(1);
        setSearch(e.target.value);
    };


    return (
        <Fragment>
            <MainWrapper fluid>
                <main className="content pt-4">
                    <div className="page-header mb-4">
                        <Row>
                            <Col md="12">
                                <div className="c-card-top">
                                    <div className="c-card-top-title">
                                        {/* <h4 className="mb-0">Service Requests</h4> */}
                                        <h4 className="mb-0">
                                            FSSAI Registrations
                                        </h4>
                                    </div>
                                    <div className="c-card-top-inputs c-card-top-search">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </div>
                                            <span className="custom-select">
                                                Search
                                            </span>
                                            <Input
                                                placeholder="Search here..."
                                                onChange={handleSearch}
                                                className="c-form-control-serch"
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    class="btn btn-secondary btn-sm"
                                                    type="button"
                                                    id="button-addon2"
                                                >
                                                    GO
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-card-top-buttons">
                                        <ButtonGroup className="">
                                            <ButtonDropdown
                                                isOpen={dropdownOpen}
                                                toggle={dropdownOpenToggle}
                                            >
                                                <DropdownToggle caret size="sm">
                                                    <FaList />
                                                    &nbsp;Service Tools
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem tag="a" href="#">
                                                        Tool Name
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-center">
                                <h4>FSSAI Registrations</h4>
                                <div className="d-flex justify-content-between align-items-center">

                                    <InputGroup className="mr-1">
                                        <Input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={({ target: { value } }) =>
                                                setSearch(value)
                                            }
                                        />
                                    </InputGroup>
                                    <ButtonDropdown
                                        isOpen={dropdownOpen}
                                        toggle={dropdownOpenToggle}
                                    >
                                        <DropdownToggle caret>
                                            <FaList />
                                            &nbsp;Service Tools
                                        </DropdownToggle>
                                        <DropdownMenu className="w-100">
                                            <DropdownItem tag="a" href="#">
                                                Tool Name
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div> */}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <Button
                                            color="secondary"
                                            size="sm"
                                            className="btn btn-sm btn-secondry btn-circle mr-1"
                                        >
                                            <i className="fa fa-trash pr-1" /> Delete
                                        </Button>
                                        <Button
                                            className="btn btn-sm btn-secondry btn-circle"
                                            color="secondary"
                                            size="sm"
                                        >
                                            <i className="fa fa-refresh" />
                                        </Button>
                                    </div>
                                    <div>
                                        <select
                                            id="serviceStatus"
                                            onChange={(e) =>
                                                setServiceStatus(e.target.value)
                                            }
                                            value={serviceStatus}
                                            className="custome-select mr-1"
                                            style={{
                                                width: 195,
                                            }}
                                        >
                                            <option value="">Select Service Status</option>
                                            <option value="APPLIED">Applied</option>
                                            <option value="IN_PROGRESS">In Progress</option>
                                            <option value="COMPLETED">Completed</option>
                                            <option value="ON HOLD">On Hold</option>
                                            <option value="INVALID">Invalid</option>
                                            <option value="CANCEL">Cancel</option>

                                        </select>
                                        <select
                                            id="sort"
                                            onChange={e => setSort(e.target.value)}
                                            value={sort}
                                            className="custome-select"
                                            style={{ width: 135 }}
                                        >
                                            <option value="asc">Ascending</option>
                                            <option value="desc">Descending</option>
                                        </select>
                                        <select
                                            id="paginate"
                                            onChange={e =>
                                                setPaginate(e.target.value)
                                            }
                                            value={paginate}
                                            className="custome-select custom-select-page"
                                        >
                                            <option value="10">10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-0">
                                    {/* <Nav pills>
                                    <NavItem onClick={() => setActiveTab(2)}>
                                        <NavLink
                                            href="#"
                                            active={activeTab === 2}
                                        >
                                            FSSAI Registrations
                                        </NavLink>
                                    </NavItem>
                                </Nav> */}
                                    <TabContent className="" activeTab={activeTab}>
                                        <TabPane tabId={2}>
                                            <Row>
                                                <Col sm="12">
                                                    <div className="table-responsive">
                                                        <Table
                                                            size="sm"
                                                            className="small mb-0"
                                                            hover
                                                        >
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="font-weight-bold">
                                                                        SNo.
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Member Name
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Mobile
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Email
                                                                    </th>
                                                                    <th
                                                                        rowSpan={2}
                                                                        className="font-weight-bold"
                                                                    >
                                                                        Membership
                                                                        Type
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Applied On
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Status
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Food
                                                                        Business
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Identity
                                                                        proof
                                                                    </th>
                                                                    <th className="font-weight-bold">
                                                                        Supporting
                                                                        Document
                                                                    </th>
                                                                    {/* <th className="font-weight-bold">
                                                                    Feedback
                                                                </th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="d-table-body">
                                                                <Fragment>
                                                                    {data.length ? (
                                                                        <Fragment>
                                                                            {map(
                                                                                data,
                                                                                (ipr, idx) => (
                                                                                    <Fragment
                                                                                        key={`ipr-${ipr.id}`}
                                                                                    >
                                                                                        <tr>
                                                                                            <td>{idx + 1}</td>
                                                                                            <td>
                                                                                                <a
                                                                                                    className="c-text-btn-primary"
                                                                                                    href={`/user-profile/${ipr.user.id}`}
                                                                                                >
                                                                                                    {capitalize(
                                                                                                        ipr
                                                                                                            .user
                                                                                                            .name
                                                                                                    )}
                                                                                                </a>
                                                                                            </td>

                                                                                            <td>
                                                                                                {
                                                                                                    ipr
                                                                                                        .user
                                                                                                        .mobile
                                                                                                }
                                                                                            </td>

                                                                                            <td>
                                                                                                {
                                                                                                    ipr
                                                                                                        .user
                                                                                                        .email
                                                                                                }
                                                                                            </td>

                                                                                            <td

                                                                                            >
                                                                                                {ipr?.userpl ? ipr?.userpl?.name : ''}
                                                                                                <span className="badge badge-sm badge-info">
                                                                                                    {member ===
                                                                                                        null
                                                                                                        ? ""
                                                                                                        : datediff(
                                                                                                            ipr?.userpl ? ipr.userpl.expiry : '',
                                                                                                            ipr?.userpl ? ipr.userpl.purchased_at : ''
                                                                                                        )}


                                                                                                    {member ===
                                                                                                        null
                                                                                                        ? ""
                                                                                                        : (<span>Days
                                                                                                            Left</span>)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                {ipr.created_at}
                                                                                            </td>

                                                                                            <td>
                                                                                                {ipr.status}
                                                                                            </td>
                                                                                            <td>
                                                                                                {ipr?.food_business && (
                                                                                                    <a
                                                                                                        href={`/storage/${ipr?.food_business}`}
                                                                                                        target="_blank"
                                                                                                        className="text-primary"
                                                                                                    >
                                                                                                        View
                                                                                                        Photo
                                                                                                    </a>
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {ipr?.identity_proof && (
                                                                                                    <a
                                                                                                        href={`/storage/${ipr?.identity_proof}`}
                                                                                                        target="_blank"
                                                                                                        className="text-primary"
                                                                                                    >
                                                                                                        View
                                                                                                        Identity
                                                                                                        Proof
                                                                                                    </a>
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {ipr?.supporting_document && (
                                                                                                    <a
                                                                                                        href={`/storage/${ipr?.supporting_document}`}
                                                                                                        target="_blank"
                                                                                                        className="text-primary"
                                                                                                    >
                                                                                                        View
                                                                                                        Supporting
                                                                                                        Document
                                                                                                    </a>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                )
                                                                            )}
                                                                        </Fragment>
                                                                    ) : (
                                                                        <tr className="text-center">
                                                                            <td colSpan={12}>No data found</td>
                                                                        </tr>
                                                                    )}
                                                                </Fragment>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                                <div className="card-footer">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            Showing {from} to {to} of{" "}
                                            {total} entries
                                        </span>
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={perPage}
                                            totalItemsCount={total}
                                            pageRangeDisplayed={5}
                                            onChange={page => setPage(page)}
                                            innerClass="pagination pagination-sm"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </main>
            </MainWrapper>
            <Modal isOpen={modal}>
                <ModalBody className="px-2 py-1">
                    <FormGroup>
                        <Label>Feedback</Label>
                        <Input
                            type="textarea"
                            onChange={({ target: { value } }) =>
                                setFeedBack(value)
                            }
                            value={feedBack}
                        />
                    </FormGroup>
                    <Button
                        color="success"
                        size="sm"
                        onClick={handleFeedbackSubmit}
                    >
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={uploadmodal}>
                <ModalHeader
                    style={{ background: "#e5e5e5" }}
                    toggle={() => setUploadModal(false)}
                >

                </ModalHeader>
                <ModalBody className="px-2 py-1">
                    <FormGroup>
                        <Label>Upload Document</Label>
                        <Input
                            type="file"
                            onChange={(e) => setUploadFile(e.target.files[0])}

                        />
                    </FormGroup>
                    <Button
                        color="success"
                        size="sm"
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
});

export default PaidServiceIprRequest;

