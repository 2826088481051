import React, { useMemo, Fragment, memo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import EditWorkshop from "./EditWorkShop";
const List = memo(({ leadDetails, setWsStatus }) => {
  const boodedWorkshop = useMemo(() => {
    if (leadDetails) return leadDetails?.paidworkshopcount;
  }, [leadDetails]);

  console.log("rajuu", boodedWorkshop);
  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-3">
            <Link
              className="card-title m-0 h5 text-white d-block p-2 collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapse-3"
              aria-expanded="true"
              aria-controls="collapse-3"
            >
              WorkShops ({_.size(boodedWorkshop)})
            </Link>
          </div>
          <div
            id="collapse-3"
            className="collapse"
            aria-labelledby="heading-3"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Transaction ID</th>
                      <th>Mode</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boodedWorkshop
                      ? boodedWorkshop.map((workShop, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>{workShop?.work_shop_details?.name}</td>
                            <td>{workShop?.work_shop_details?.date}</td>
                            <td>{workShop?.work_shop_details?.end_date}</td>

                            <td>{workShop?.payment_id}</td>
                            <td>{workShop?.payment_mode}</td>

                            {workShop?.agent_id == null ? (
                              <td className="text-right">
                                <EditWorkshop
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  workShopId={workShop?.id}
                                  boodedWorkshop={boodedWorkshop}
                                />
                              </td>
                            ) : null}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default List;
