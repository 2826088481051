import _ from "lodash";
import { FaSadCry } from "react-icons/fa";

const initialState = {
    getAllRelated: [],
    getAllRelatedLoading: false,
    getAllRelatedStatus: false,

    getAllSubRelated: [],
    getAllSubRelatedLoading: false,
    getAllSubRelatedStatus: false,

    getLeadList: [],
    getLeadLoading: false,
    getLeadStatus: false,

    getPriorityList: [],
    getPriorityListStatus: false,

    getSubPriorityList: [],
    getSubPriorityListStatus: false,

    listActivity: [],
    listActivityStatus: false,
    listActivityLoading: false,

    listReminder: [],
    listReminderStatus: false,

    listCallPurpose: [],
    listCallPurposeStatus: false,

    listContactType: [],
    listContactTypeStatus: false,

    listSourceDetails: [],
    listSourceDetailsStatus: false,
    listService: [],
    listServiceStatus: false,

    error: {},
};

const commonReducer = (state = initialState, actions) => {
    const { payload } = actions;
    // eslint-disable-next-line default-case
    switch (actions.type) {
        case "SERVICE_DETAILS_PENDING":
            state = {
                ...state,
                loading: true,
                listServiceStatus: false,
                error: {},
            };
            break;
        case "SERVICE_DETAILS_FULFILLED":
            state = {
                ...state,
                loading: false,
                listServiceStatus: true,
                listService: _.get(payload, "data", {}),
            };
            break;
        case "SERVICE_DETAILS_REJECTED":
            state = {
                ...state,
                loading: false,
                listServiceStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "SOURCE_DETAILS_PENDING":
            state = {
                ...state,
                loading: true,
                listSourceDetailsStatus: false,
                error: {},
            };
            break;
        case "SOURCE_DETAILS_FULFILLED":
            state = {
                ...state,
                loading: false,
                listSourceDetailsStatus: true,
                listSourceDetails: _.get(payload, "data", {}),
            };
            break;
        case "SOURCE_DETAILS_REJECTED":
            state = {
                ...state,
                loading: false,
                listSourceDetailsStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "RELATED_PENDING":
            state = {
                ...state,
                getAllRelatedLoading: true,
                getAllRelatedStatus: false,
                error: {},
            };
            break;
        case "RELATED_FULFILLED":
            state = {
                ...state,
                getAllRelatedLoading: false,
                getAllRelatedStatus: true,
                getAllRelated: _.get(payload, "data", {}),
            };
            break;
        case "RELATED_REJECTED":
            state = {
                ...state,
                getAllRelatedLoading: false,
                getAllRelatedStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "SUB_RELATED_PENDING":
            state = {
                ...state,
                getAllSubRelatedLoading: true,
                getAllSubRelatedStatus: false,
                error: {},
            };
            break;
        case "SUB_RELATED_FULFILLED":
            state = {
                ...state,
                getAllSubRelatedLoading: false,
                getAllSubRelatedStatus: true,
                getAllSubRelated: _.get(payload, "data", {}),
            };
            break;
        case "SUB_RELATED_REJECTED":
            state = {
                ...state,
                getAllSubRelatedLoading: false,
                getAllSubRelatedStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "GET_LEAD_PENDING":
            state = {
                ...state,
                getLeadLoading: true,
                getLeadStatus: false,
                error: {},
            };
            break;
        case "GET_LEAD_FULFILLED":
            state = {
                ...state,
                getLeadLoading: false,
                getLeadStatus: true,
                getLeadList: _.get(payload, "data", {}),
            };
            break;
        case "GET_LEAD_REJECTED":
            state = {
                ...state,
                getLeadLoading: false,
                getLeadStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "GET_PRIORITY_LIST_PENDING":
            state = {
                ...state,
                getPriorityListStatus: false,
                error: {},
            };
            break;
        case "GET_PRIORITY_LIST_FULFILLED":
            state = {
                ...state,
                getPriorityListStatus: true,
                getPriorityList: _.get(payload, "data", {}),
            };
            break;
        case "GET_PRIORITY_LIST_REJECTED":
            state = {
                ...state,
                getPriorityListStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "GET_SUB_PRIORITY_PENDING":
            state = {
                ...state,
                getSubPriorityListStatus: false,
                error: {},
            };
            break;
        case "GET_SUB_PRIORITY_FULFILLED":
            state = {
                ...state,
                getSubPriorityListStatus: true,
                getSubPriorityList: _.get(payload, "data", {}),
            };
            break;
        case "GET_SUB_PRIORITY_REJECTED":
            state = {
                ...state,
                getSubPriorityListStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "LIST_ACTIVITY_PENDING":
            state = {
                ...state,
                listActivityLoading: true,
                listActivityStatus: false,
                error: {},
            };
            break;
        case "LIST_ACTIVITY_FULFILLED":
            state = {
                ...state,
                listActivityLoading: false,
                listActivityStatus: true,
                listActivity: _.get(payload, "data", {}),
            };
            break;
        case "LIST_ACTIVITY_REJECTED":
            state = {
                ...state,
                listActivityLoading: false,
                listActivityStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "LIST_REMINDER_PENDING":
            state = {
                ...state,
                listReminderStatus: false,
                error: {},
            };
            break;
        case "LIST_REMINDER_FULFILLED":
            state = {
                ...state,
                listReminderStatus: true,
                listReminder: _.get(payload, "data", {}),
            };
            break;
        case "LIST_REMINDER_REJECTED":
            state = {
                ...state,
                listReminderStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "LIST_CALL_PURPOSE_PENDING":
            state = {
                ...state,
                listCallPurposeStatus: false,
                error: {},
            };
            break;
        case "LIST_CALL_PURPOSE_FULFILLED":
            state = {
                ...state,
                listCallPurposeStatus: true,
                listCallPurpose: _.get(payload, "data", {}),
            };
            break;
        case "LIST_CALL_PURPOSE_REJECTED":
            state = {
                ...state,
                listCallPurposeStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "LIST_CONTACT_TYPE_PENDING":
            state = {
                ...state,
                listContactTypeStatus: false,
                error: {},
            };
            break;
        case "LIST_CONTACT_TYPE_FULFILLED":
            state = {
                ...state,
                listContactTypeStatus: true,
                listContactType: _.get(payload, "data", {}),
            };
            break;
        case "LIST_CONTACT_TYPE_REJECTED":
            state = {
                ...state,
                listContactTypeStatus: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
    }
    return state;
};

export default commonReducer;
