import _ from "lodash";

const initialState = {
  workShops: [],
  workshopbyid: {},
  bookedWorkShops: [],
  bookedWorkShop: {},
  users: [],
  loading: false,
  success: false,
  createStatus: false,
  errors: {},
  booked: {},
  workshopStatus:false
}

const bookWorkShopReducer = (state = initialState, action) => {

  switch (action.type) {

    case "WORKSHOP_LIST_PENDING":
    case "WORKSHOP_PENDING":
    case "WORKSHOP_CREATE_PENDING":
    case "WORKSHOP_EDIT_PENDING":
    case "WORKSHOP_USER_PENDING":
    case "WORKSHOP_BY_ID_PENDING":
    case "CHANGE_WORKSHOP_STATE":
      return {
        ...state,
        loading: true,
        success: false,
        createStatus: false,
        errors: {},
        booked: {},
        workshopStatus:false
      };
    case "WORKSHOP_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        bookedWorkShops: _.get(action.payload, "data", {}),
      };
    case "WORKSHOP_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        workShops: _.get(action.payload, "data", {}),
      };
    case "WORKSHOP_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        users: _.get(action.payload, "data", {}),
      };
    case "WORKSHOP_BY_ID_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        workshopbyid: _.get(action.payload, "data", {}),
      };
    case "WORKSHOP_LIST_REJECTED":
    case "WORKSHOP_REJECTED":
    case "WORKSHOP_CREATE_REJECTED":
    case "WORKSHOP_EDIT_REJECTED":
    case "WORKSHOP_USER_REJECTED":
    case "WORKSHOP_BY_ID_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          errors: _.get(data, "errors", {}),
        };
      } else {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          errors: _.get(data, "message", ""),
        };
      }
    case "WORKSHOP_EDIT_FULFILLED":
    case "WORKSHOP_CREATE_FULFILLED":
      return {
        ...state,
        loading: false,
        createStatus: true,
        workshopStatus:true,
        booked: _.get(action?.payload?.data, "payment", {}),
      };
    default:
      return state;
  }

}

export default bookWorkShopReducer;