import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Button,
} from "reactstrap";
import { Fragment } from "react";
import Loader from "../../layouts/Loader";
import { useSelector } from "react-redux";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
const HunarLead = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [paginate, setPaginate] = useState(20);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [createdBy, seCreatedBy] = useState([]);

  const [createdByAdmin, seCreatedByAdmin] = useState([]);
  const [user, setUser] = useState([]);
  const [supplierType, setSupplierType] = useState("");
  const [professionalType, setProfessionalType] = useState([]);
  const [instituteType, setInstituteType] = useState("");
  const [appType, setAppType] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [professionalservices, setProfessionalServices] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [associateType, setAssociateType] = useState("");
  const [modal, setModal] = useState(false);
  const [fields, setFields] = useState("");
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  const permissions = React.useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-hunarlead?sort=${sort}&search=${search}&page=${page}&paginate=${paginate}&start=${startDate}&end=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        // setContent(res.data.consultantList?.data);
        // seCreatedBy(res.data.consultantList?.child);
        setContent(res.data.hunarlead?.data || []);
        setFrom(res.data.hunarlead?.from);
        setTo(res.data.hunarlead?.to);
        setTotal(res.data.hunarlead?.total);
        setPerPage(res.data.hunarlead?.per_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.response?.data?.message);
      });
  }, [sort, page, refresh, paginate, search, auth]);
  //

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  console.log(content, "content");

  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("import_hunar_excel", fields);
    axios
      .post("/api/crm/hunar-india-import", data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        window.location.reload();
        NotificationManager.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoading(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  return (
    <MainWrapper>
      <main className="content pt-4">
        <div className="page-header mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="c-card-top flex-wrap">
                <div className="c-card-top-title ml-2">
                  <h4 className="mb-0">Hunar Lead</h4>
                </div>
                <div className="c-card-top-inputs c-card-top-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                    <span className="custom-select">Search</span>

                    <Input
                      placeholder="Search here..."
                      onChange={handleSearch}
                      className="c-form-control-serch"
                    />
                    <div class="input-group-append">
                      <button
                        title="Advance Search"
                        onClick={() => {
                          advanceSearch
                            ? setAdvanceSearch(false)
                            : setAdvanceSearch(true);
                        }}
                        id="c_searchBlock_toggle"
                        class="btn btn-secondary btn-sm c-card-top-block-icon"
                        type="button"
                      >
                        <FiChevronDown />
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        id="button-addon2"
                      >
                        GO
                      </button>
                    </div>
                  </div>
                </div>
                <div className="c-card-top-buttons mr-2">
                  <ButtonGroup className="">
                    <Link to="/create-hunar-lead" className="">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        <i className="fa fa-plus btn-i-icon"></i> Add New
                      </button>
                    </Link>
                  </ButtonGroup>
                  <ButtonGroup className="">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm ml-1"
                      onClick={() => setModal(true)}
                    >
                      <i className="fa fa-plus btn-i-icon"></i> Import Data
                    </button>
                  </ButtonGroup>
                  <Modal isOpen={modal} toggle={() => setModal(false)}>
                    <ModalHeader toggle={() => setModal(false)}>
                      Import Questions
                      <a
                        href="/import_hunar_excel.csv"
                        style={{ "margin-left": "170px", color: "blue" }}
                      >
                        sheet
                      </a>
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={handleSubmit}>
                        <FormGroup>
                          <CustomInput
                            type="file"
                            id="file"
                            name="file"
                            label="Choose File"
                            onChange={handleChange}
                            invalid={!!errors?.file}
                            required
                          />
                          <FormFeedback>
                            {!!errors.file ? errors.file[0] : ""}
                          </FormFeedback>
                        </FormGroup>
                        <div className="text-center mb-3">
                          <Loader loading={loading}>
                            <Button
                              type="submit"
                              color="secondary"
                              size="sm"
                              className="mr-1"
                            >
                              Save
                            </Button>
                            <Button
                              type="button"
                              color="secondary"
                              size="sm"
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          </Loader>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header d-flex justify-content-between align-items-center">
                    <div>
                      <button className="btn btn-secondary btn-sm mr-1">
                        <i className="fa fa-trash pr-1" /> Delete
                      </button>

                      <button
                        className="btn btn-secondary btn-sm btn-circle"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                    <div>
                      <select
                        id="sort"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custome-select small-select mr-1"
                        // style={{ width: 135 }}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    checked={
                                      deleteIds.length === content.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">
                                  Phone Number
                                </th>
                                <th className="font-weight-bold">Board Exam</th>
                                <th className="font-weight-bold">Class</th>
                                <th className="font-weight-bold">
                                  Calling Person
                                </th>

                                <th className="font-weight-bold">
                                  Created Date
                                </th>

                                <th className="font-weight-bold">Remarks</th>

                                <th className="font-weight-bold text-right">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7}>
                                        <Loader loading={loading} />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {content.length ? (
                                      <Fragment>
                                        {_.map(content, (is, i) => (
                                          <tr key={is.id}>
                                            <td>{i + 1}</td>
                                            <td>
                                              <span
                                                style={{
                                                  color: "#3647c3",
                                                }}
                                              >
                                                <a>{is?.name}</a>
                                              </span>
                                            </td>
                                            <td>{is?.mobile}</td>
                                            <td>{is?.board_exam}</td>

                                            <td>{is.class}</td>
                                            <td>{is?.calling_person}</td>

                                            <td>
                                              <strong>
                                                {moment(is.created_at).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </strong>
                                            </td>
                                            {/* {permission.some(
                                              (item) =>
                                                item.name ===
                                                "crm-consultant-approve-view"
                                            ) && (
                                              <td>
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    handleApproved(e, is)
                                                  }
                                                  className={
                                                    is.consultant_approve
                                                      ? `badge badge-success text-white`
                                                      : `badge badge-danger text-white`
                                                  }
                                                >
                                                  {is.consultant_approve
                                                    ? "Active"
                                                    : "Approve"}
                                                </a>
                                              </td>
                                            )} */}
                                            <td>{is.remarks}</td>

                                            <td className="text-right">
                                              <Link
                                                className="c-icon-btn-light mr-2"
                                                to={`/hunar-edit/${is?.id}`}
                                              >
                                                <FiEdit />
                                              </Link>
                                            </td>
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={7}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custom-select custom-select-page"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                        {/* <select className="custom-select custom-select-page">
                                                    <option selected>10</option>
                                                    <option value="1">
                                                        20
                                                    </option>
                                                    <option value="2">
                                                        30
                                                    </option>
                                                    <option value="3">
                                                        40
                                                    </option>
                                                </select> */}
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={perPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal isOpen={isOpen} size="sm">
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          User Contact Details
        </ModalHeader>
        <ModalBody className="industry-overflow">
          <div className="row">
            <div className="col-md-12">
              <p>
                <strong>Mobile: </strong> {details.mobile}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <strong>Password: </strong> {details.plain_password}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <strong>Email: </strong> {details.email}
              </p>{" "}
            </div>
            <div className="col-md-12">
              <p>
                <strong>Address: </strong> {details.address}
              </p>{" "}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </MainWrapper>
  );
};

export default HunarLead;
