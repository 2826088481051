import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import moment from "moment";
import Axios from "axios";
import { BiDetail } from "react-icons/bi";
import { MdCallEnd } from "react-icons/md";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import CollapseCard from "../../components/dashboard-accordian/CollapseCard";
import { ImHappy2 } from "react-icons/im";
import Accordian from "../../components/dashboard-accordian/Accordian";
import useScrollTrigger from "./useScrollTrigger";
const SalesLeadStatus = () => {
  const [salesFilter, setSalesFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [salesData, setSalesData] = useState([]);
  const [isInview, setIsinview] = useState(false);
  const [associate, setAssociate] = useState([]);
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;
  const targetRef = useScrollTrigger((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Element is now in view
        setIsinview(true);
      }
    });
  });
  useEffect(() => {
    if (isInview) fetchData();
  }, [isInview, token, salesFilter]);
  const {
    leaddetails,
    dashboardcount,
    dashboardconsultant,
    dashboarduserconsultant,
  } = useSelector((state) => state.dashboardReducer);
  const fetchData = () => {
    Axios.get(
      `/api/crm/get-dashboard-detail?start_date=${salesFilter?.startDate}&end_date=${salesFilter?.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => setSalesData(res?.data))
      .catch((err) => console.log(err));
  };

  return (
    <div ref={targetRef}>
      <h2>Sales Lead Stats</h2>
      <div className="row">
        <div class="col-md-5">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              Start Date
            </label>
            <Input
              type="date"
              style={{ borderRadius: "6px" }}
              // value={salesStartDate}
              // onChange={({ target: { value } }) =>setSalesStartDate(value)}
              value={salesFilter.startDate}
              onChange={({ target: { value } }) =>
                setSalesFilter((prev) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
          </div>
        </div>
        <div class="col-md-5">
          <div className="form-group">
            <label htmlFor="" className="form-label mb-2">
              End Date
            </label>
            <Input
              type="date"
              style={{ borderRadius: "6px" }}
              // value={salesEndDate}
              // onChange={({ target: { value } }) =>setSalesEndDate(value)}
              value={salesFilter.endDate}
              onChange={({ target: { value } }) =>
                setSalesFilter((prev) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darked">
            <div className="card-body py-4">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="square-box blue">
                    <FaUser color="white" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.todayLead}</h3>
                  <div className="mb-0 font-commom-2">Today Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-green">
            <div className="card-body py-4">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="square-box skyblue">
                    <FaUserCheck color="white" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.totalLead}</h3>
                  <div className="mb-0 font-commom-2">Total Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darkblue">
            <div className="card-body py-4">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="square-box orange">
                    <FaPhoneAlt color="white" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.totalConnected}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Connected</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-orange">
            <div className="card-body py-4">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="square-box pink">
                    <ImHappy2 color="white" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.totalInterested}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Interested</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="card flex-fill common-card round-border">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box green">
                    <FaSadCry color="#33d69f" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.totalNotInterested}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Not Interested</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkred">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.totalDetailShared}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Detailed </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.totalCallBack}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Call Back</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill common-card round-border">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box green">
                    <FaSadCry color="#33d69f" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.getHot}</h3>
                  <div className="mb-0 font-commom-2">Total Hot Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkred">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.getCold}</h3>
                  <div className="mb-0 font-commom-2">Total Cold Lead </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.getWarm}</h3>
                  <div className="mb-0 font-commom-2">Total Warm Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {salesData?.getConverted}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Converted</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.Perspective}</h3>
                  <div className="mb-0 font-commom-2">Total Perspective</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.totalActive}</h3>
                  <div className="mb-0 font-commom-2">Total Activity</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">{salesData?.todayActive}</h3>
                  <div className="mb-0 font-commom-2">Today Activity</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row cursor-pointer">
        {salesData?.getTeamList?.map((consultant) => (
          <div className="col-md-4">
            <div>
              <Accordian
                consultant={consultant}
                key={consultant?.id}
                type="4"
              />
            </div>
          </div>
        ))}
      </div>
      {auth?.id == "50" || auth?.id == "83" || auth?.id == "47" ? (
        <>
          <div>
            <h5>Teams</h5>
            <div className="row cursor-pointer">
              {dashboardcount?.getTeamList?.map((consultant) => (
                <div className="col-md-4 my-2">
                  <div>
                    <Accordian
                      consultant={consultant}
                      key={consultant?.id}
                      type="1"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h5>Associate Partners Stats</h5>
            <div className="row cursor-pointer">
              {associate?.map((consultant) => (
                <div className="col-md-4 ">
                  <div>
                    <Accordian
                      consultant={consultant}
                      key={consultant?.id}
                      type="2"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SalesLeadStatus;
