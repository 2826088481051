import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import { useSelector, useDispatch } from "react-redux";
import { callOptionHandler } from "../../store/actions/CallActions";
import { Calling, hangUpCalling } from "../../store/actions/LoginCallActions";
let hdnTimer = 0;
let timer;

const NewCall = ({ leadDetail, setRefresh }) => {
  const [assignToList, setAssignToList] = useState([]);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const [subject, setSubject] = useState("");
  const [call, setCall] = useState("");
  const [callPurpose, setCallPurpose] = useState("");
  const [callCurrentDetails, setCallCurrentDetails] = useState(false);
  const [callCompleteDetails, setCallCompleteDetails] = useState(true);
  const [callStartDate, setCallStartDate] = useState("");
  const [callStartTime, setCallStartTime] = useState("");
  const [callDurationHours, setCallDurationHours] = useState("");
  const [callDurationMinutes, setCallDurationMinutes] = useState("");
  const [callDurationSeconds, setCallDurationSeconds] = useState("");

  const [remark, setRemark] = useState("");

  const [addCallStatus, setAddCallStatus] = useState(false);
  const [stopStatus, setStopStatus] = useState(false);

  const [minuteData, setMinuteData] = useState(0);
  const [secondData, setSecondData] = useState(0);

  const { addStatus, } = useSelector((state) => state.callingReducer);
  const { telecallauth, loadingg, error } = useSelector((state) => state.callReducer);
  const handleCallDetails = (e) => {
    setCall(e.target.value);
  };




  const handleStartTimer = (e) => {
    e.preventDefault();
    timer = setInterval(UpdateTimer, 1000);
    const data = new FormData();
    data.append("agent_number", auth?.mobile);
    data.append("destination_number", leadDetail?.mobile);
    data.append("id", leadDetail?.id);
    data.append("Authorization", telecallauth.access_token);
    dispatch(Calling(data, auth.token));
  };
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.auth);
  const { userStatus, leadData } = useSelector((state) => state.lead);
  const [assignTo, setAssignTo] = useState(auth.name);
  useEffect(() => {
    const curr = new Date();
    curr.setDate(curr.getDate() + 3);
    const date = curr.toISOString().substr(0, 10);
    setCallStartDate(date);
  }, []);
  const UpdateTimer = () => {
    var valTimer = parseInt(hdnTimer);
    valTimer = valTimer + 1;
    var m = parseInt((valTimer / 60).toString().split(".")[0]);
    var s = (valTimer % 60).toFixed(0);
    var mm = (m < 10 ? "0" : "") + m.toString();
    var ss = (s < 10 ? "0" : "") + s.toString();
    setMinuteData(mm);
    setSecondData(ss);
    setCallDurationMinutes(mm);
    setCallDurationSeconds(ss);
    setCallCurrentDetails(true);
    setCallCompleteDetails(false);
    hdnTimer = valTimer;
  };
  const handleStopTimer = (e) => {
    e.preventDefault();
    clearInterval(timer);
    setCallCurrentDetails(false);
    setCallCompleteDetails(true);
    const data = new FormData();
    data.append("agent_number", auth?.mobile);
    data.append("extension", auth?.mobile);
    data.append("Authorization", telecallauth.access_token);
    data.append("engaged", 1);
    data.append("id", leadDetail?.id);
    data.append("agent_id", auth?.id);
    dispatch(hangUpCalling(data, auth.token));
  };

  useEffect(() => {
    if (typeof error === "string") {
      NotificationManager.error(error);
      clearInterval(timer);
    }
  }, [error])
  

  useEffect(() => {
    dispatch(getCrmUsersApi(auth.token));
  }, [getCrmUsersApi, auth.token]);

  useEffect(() => {
    if (userStatus) {
      setAssignToList(leadData.users);
    }
  }, [leadData, userStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const insertArr = {
      assign_to: auth.id,
      subject: subject,
      call_id: leadDetail?.id,
      leadtype: "freshlead",
      call_type: call,
      call_purpose: callPurpose,
      start_date: callStartDate,
      start_time: callStartTime,
      call_duration_minutes: callDurationMinutes,
      call_duration_second: callDurationSeconds,
      remark: remark,
    };

    dispatch(callOptionHandler(insertArr, auth.token));
  };

  useEffect(() => {
    if (addStatus) {
      NotificationManager.success("Call Done Successfully", "Success");
      setRefresh((refresh) => refresh + 1);
      setModal(false);
      setCallCurrentDetails(false);
      setCall("");
      setCallPurpose("");
      setCallStartDate("");
      setCallStartTime("");
      setCallDurationMinutes("");
      setCallDurationSeconds("");
      setRemark("");
      setMinuteData(0);
      setSecondData(0);
    }
  }, [addStatus]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Call
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          New Call
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="col-md-12 col-lg-8 offset-lg-2 mb-15">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row form-group">
                    <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                      Assigned To
                    </label>
                    <div className="col-md-8">
                      <Input
                        type="text"
                        value={auth.name}
                        onChange={(e) => setAssignTo(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row form-group">
                    <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                      Call Purpose
                    </label>
                    <div className="col-md-8">
                      <Input
                        type="text"
                        className="form-control"
                        value={callPurpose}
                        onChange={(e) => setCallPurpose(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row form-group">
                    <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                      Call Details
                    </label>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="border">
                            <div className="col-md-12 pt-1 mb-2">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="incomingCall"
                                  name="customRadioInline1"
                                  className="custom-control-input"
                                  value="Incoming"
                                  onChange={handleCallDetails}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="incomingCall"
                                >
                                  Incoming
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="outgoingCall"
                                  name="customRadioInline1"
                                  className="custom-control-input"
                                  value="Outgoing"
                                  onChange={handleCallDetails}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="outgoingCall"
                                >
                                  Outgoing
                                </label>
                              </div>
                            </div>

                            <div className="col-md-12 bg-dark call-timer pt-2 pb-2 mb-1">
                              <div className="row justify-content-center">
                                <div className="col-md-7">
                                  <div className="border-0 text-center">
                                    <div className="text-white mb-1">
                                      Call Timer
                                    </div>
                                    <div className="text-center">
                                      <div className="timer text-white">
                                        <span className="minute">
                                          {minuteData}
                                        </span>
                                        <span className="pr-1 pl-1">:</span>
                                        <span className="second">
                                          {secondData}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="text-center border-0 mt-3">
                                    {loadingg ? <FaSpinner className="fa fa-spin" /> : callCurrentDetails && typeof error!=="string"  ? (
                                      <a
                                        href="#"
                                        className="btn btn-danger stop-timer"
                                        onClick={handleStopTimer}
                                      >
                                        STOP
                                      </a>
                                    ) :
                                      (
                                          
                                        <a
                                          href="#"
                                          className="btn btn-success start-timer"
                                          onClick={handleStartTimer}
                                        >
                                          START
                                        </a>
                                      )}

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 pt-2">
                              <div className="row">
                                <label className="col-md-3 control-label col-form-label col-12">
                                  Call Start Time
                                </label>
                                <div className="col-md-9">
                                  <div className="row">
                                    <div className="col-md-6 form-group">
                                      <Input
                                        className="form-control selectdate"
                                        type="date"
                                        placeholder="Select Date"
                                        onChange={(e) =>
                                          setCallStartDate(e.target.value)
                                        }
                                        value={callStartDate}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <div className="input-group clockpicker-example">
                                        <Input
                                          type="time"
                                          className="form-control"
                                          placeholder="Select Time"
                                          onChange={(e) =>
                                            setCallStartTime(e.target.value)
                                          }
                                          value={callStartTime}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-md-3 control-label col-form-label col-12">
                                  Call Duration
                                </label>
                                <div className="col-md-9">
                                  <div className="row">
                                    <div className="col-md-6 form-group">
                                      <Input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) =>
                                          setCallDurationMinutes(e.target.value)
                                        }
                                        value={callDurationMinutes}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <div className="input-group">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          onChange={(e) =>
                                            setCallDurationSeconds(
                                              e.target.value
                                            )
                                          }
                                          value={callDurationSeconds}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row form-group">
                    <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                      Call Disposition
                    </label>
                    <div className="col-md-8">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setRemark(e.target.value)}
                      >
                        <option value="">select</option>
                        <option value="Busy">Busy</option>
                        <option value="Callback">Call Back</option>
                        <option value="Deadcall">Dead Call </option>
                        <option value="Follow up call">Follow up Call</option>
                        <option value="Follow up interested">
                          Follow up Interested
                        </option>
                        <option value="Invalid number">Invalid Number</option>
                        <option value="No answer">No Answer</option>
                        <option value="Not interested">Not Interested</option>
                        <option value="Registration done">
                          Registration Done
                        </option>
                        <option value="Phone not picked">
                          Phone Not Picked
                        </option>
                      </Input>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row form-group">
                    <div className="col-md-4 control-label col-form-label col-12 text-md-right"></div>
                    <div className="col-md-8">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                      <button type="button" className="btn btn-secondary ml-2">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default NewCall;
