import React, { useState, useEffect, useCallback } from "react";
import { Input } from "reactstrap";
import { BiDetail } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { HiPhoneIncoming } from "react-icons/hi";
import Axios from "axios";
import moment from "moment";
import CollapseCard from "../../components/dashboard-accordian/CollapseCard";
import { FiUser, FiUserCheck, FiRepeat } from "react-icons/fi";
import { FaMailBulk } from "react-icons/fa";
import useScrollTrigger from "./useScrollTrigger";
const FranchiseInvestor = () => {
  const { auth } = useSelector((state) => state.auth);
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [franchiseFilter, setFranchiseFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [reportings, setReportings] = useState({});

  const [isInview, setIsinview] = useState(false);

  const {
    leaddetails,
    dashboardcount,
    dashboardconsultant,
    dashboarduserconsultant,
  } = useSelector((state) => state.dashboardReducer);
  const [franchiseData, setFranchiseData] = useState([]);

  const fetchData = () => {
    Axios.get(
      `/api/crm/get-franchise-investor-dashboard-count?start=${franchiseFilter.startDate}&end=${franchiseFilter.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
      .then((res) => setFranchiseData(res.data))
      .catch((err) => console.log(err));
  };
  const targetRef = useScrollTrigger((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsinview(true);
      }
    });
  });

  useEffect(() => {
    if (isInview) fetchData();
  }, [isInview, auth?.token, franchiseFilter]);

  // useEffect(()=>fetchData(),[])

  return (
    <div ref={targetRef}>
      <h3>Franchise Investor Stats</h3>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              Start Date
            </label>
            <Input
              type="date"
              value={franchiseFilter.startDate}
              onChange={({ target: { value } }) =>
                setFranchiseFilter((prev) => ({
                  ...prev,
                  startDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              End Date
            </label>
            <Input
              type="date"
              value={franchiseFilter.endDate}
              onChange={({ target: { value } }) =>
                setFranchiseFilter((prev) => ({
                  ...prev,
                  endDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl d-flex">
          <div className="card flex-fill">
            <a
              href={`/franchise-list-investor?leaddate=${leadDate}`}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-2">
                      {franchiseData?.todayInvestor ?? 0}
                    </h3>
                    <div className="mb-0">Today Franchise Investor</div>
                  </div>
                  <div className="col-4 ml-auto text-right">
                    <div className="d-inline-block mt-2">
                      <FiUser className="feather-lg text-primary" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl d-flex">
          <div className="card flex-fill">
            <a
              href={`/franchise-list-investor`}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-2">
                      {franchiseData?.totalInvestor ?? 0}
                    </h3>
                    <div className="mb-0">Total Franchise Investor</div>
                  </div>
                  <div className="col-4 ml-auto text-right">
                    <div className="d-inline-block mt-2">
                      <FiUserCheck className="feather-lg text-warning" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl d-flex">
          <div className="card flex-fill">
            <a
              href={`/franchise-list-investor?disposition_type=Connected`}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-2">
                      {franchiseData?.totalConnected ?? 0}
                    </h3>
                    <div className="mb-0">Total Connected</div>
                  </div>
                  <div className="col-4 ml-auto text-right">
                    <div className="d-inline-block mt-2">
                      <FiRepeat className="feather-lg text-danger" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl d-flex">
          <div className="card flex-fill">
            <a
              href={`/franchise-list-investor?disposition_type=Not_Connected`}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-2">
                      {franchiseData?.totalNotConnected ?? 0}
                    </h3>
                    <div className="mb-0">Total Not Connected</div>
                  </div>
                  <div className="col-4 ml-auto text-right">
                    <div className="d-inline-block mt-2">
                      <FiRepeat className="feather-lg text-danger" />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl d-flex">
          <div className="card flex-fill">
            <a
              href={`/franchise-list-investor?disposition_type=Connected&Connected=Follow_Up
                `}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-8">
                    <h3 className="mb-2">
                      {franchiseData?.totalFollowUp ?? 0}
                    </h3>
                    <div className="mb-0">Total Follow up(Hot) </div>
                  </div>
                  <div className="col-4 ml-auto text-right">
                    <div className="d-inline-block mt-2">
                      <div className="square-box darkred">
                        <BiDetail color="#FF0032" size={18} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        {reportings?.franchiseLeadList?.map((item) => {
          return (
            <div className="col-md-3" key={item.id}>
              <CollapseCard
                name={item.name}
                id={item.id}
                moduleType="franchise"
                showFilters={true}
              />
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.todayConnected ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Today Connected</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <HiPhoneIncoming color="#6C00FF" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.todayNotConnected ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Today Not Connected</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <a
              href={`/franchise-list-investor?disposition_type=Connected&Connected=Call_Back
        `}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <HiPhoneIncoming color="#6C00FF" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {franchiseData?.totalCallBack ?? 0}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Call Back</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <a
              href={`/franchise-list-investor?disposition_type=Connected&Connected=Mail_shared
        `}
              style={{ textDecoration: "none" }}
            >
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <FaMailBulk color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {franchiseData?.totalMailShared ?? 0}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Mail Shared</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.totalManufacturing ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Manufacturing</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.totalHot ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Hot Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.totalWarm ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Warm Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {franchiseData?.totalCold ?? 0}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Cold Lead</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FranchiseInvestor;
