import React, { useState, useEffect, useCallback, useMemo } from "react";
import MainWrapper from "../../components/MainWrapper";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { editMeeting } from "../../store/actions/MeetingActions";
import { useHistory } from "react-router";
import { useParams,useLocation } from "react-router";
import { FaSpinner } from "react-icons/fa";
import Axios from "axios";
import moment from "moment";
import { getCrmUsersApi } from "../../store/actions/LeadActions";

const EditMeeting = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [meetingPurpose, setMeetingPurpose] = useState("");
  const [occupation, setOccupation] = useState("");
  const [industry, setIndustry] = useState([]);
  const [sector, setSector] = useState("");
  const [bussinesstype, setBussinessType] = useState("");
  const [investment, setInvestment] = useState("");
  const [otherInvestment, setOtherInvestment] = useState("");
  const [timerequired, setTimeRequired] = useState("");
  const [leadtype, setLeadType] = useState("");
  const [leadsource, setLeadSource] = useState("");
  const [leadstatus, setLeadStatus] = useState("");
  const [payment, setPayment] = useState(false);
  const [contactperson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [operateBusiness, setOperateBusiness] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const [sectors, setSectors] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [conversion, setConversion] = useState("");
  const [paymentstatus, setPaymentStatus] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [website, setWebsite] = useState("");
  const [details, setDetails] = useState([
    { contact_person_name: "", contact_number: "", email: "" },
  ]);
  const history = useHistory();

  const { auth } = useSelector(({ auth }) => auth);
  const [assignTo, setAssignTo] = useState("");
  const [leadDate, setLeadDate] = useState("");

  const { leadData } = useSelector(({ lead }) => lead);

  useEffect(() => {
    if (pincode?.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
    axios(`/api/crm/getcrm-meetingby/${id}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        setName(res.data?.meeting?.name || "");
        setAssignTo(res?.data?.meeting?.assign_to || "");
        setDetails([...res?.data?.meeting?.contact_detail]);
        setAmount(res.data?.meeting?.amount);
        setPincode(res.data?.meeting?.pincode);
        setBussinessType(res.data?.meeting?.bussiness_type);
        setLeadStatus(res?.data?.meeting?.lead_status);
        setLeadSource(res?.data?.meeting?.lead_source);
        setContactPerson(res?.data?.meeting?.contact_person);
        setMobile(res?.data?.meeting?.mobile);
        setEmail(res?.data?.meeting?.email);
        setCity(res?.data?.meeting?.city);
        setPaymentStatus(res?.data?.meeting?.payment_status);
        setConversion(res?.data?.meeting?.conversion);
        setNotes(res?.data?.meeting?.note);
        setMeetingPurpose(res?.data?.meeting?.purpose_of_meeting);
        setPaymentStatus(res?.data?.meeting?.payment_status);
        setTimeRequired(res?.data?.meeting?.time_required_bussiness);
        setOccupation(res?.data?.meeting?.occupation);
        setOperateBusiness(res?.data?.meeting?.operate_business);
        setWebsite(res?.data?.meeting?.website);
        setLeadDate(
          moment(res.data?.crmFranchiseTeam?.lead_date).format("YYYY-MM-DD") ||
            ""
        );
        setDisposition(res.data?.meeting?.disposition);
        setDispositionType(res.data?.meeting?.disposition_type);
      })
      .catch((err) => console.log(err));
  }, [auth]);

  const handleValidate = () => {
    let error = {
      name: "",
    };
    let valid = true;

    if (!name) {
      error["name"] = " Name field is required";
      valid = false;
    }

    setErrors(error);
    return valid;
  };

  const { updateStatus, loading } = useSelector(
    (state) => state.meetingReducer
  );

  useEffect(() => {
    if (updateStatus) {
      NotificationManager.success("Updated Successfully");
      dispatch({ type: "RESET_MEETING_STATUS", payload: false });
      history.push(`/associate-team?page=${page}`);
    }
  }, [updateStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidate()) {
      const d = {
        name: name,
        address: address,
        pincode: pincode,
        website: website,
        city: city,
        state: state,
        purpose_of_meeting: meetingPurpose,
        bussiness_type: bussinesstype,
        time_required_bussiness: timerequired,
        occupation: occupation,
        lead_source: leadsource,
        lead_status: leadstatus,
        conversion: conversion,
        payment_status: paymentstatus,
        amount: amount,
        note: notes,
        status: 1,
        email: email,
        mobile: mobile,
        contact_person: contactperson,
        id: id,
        lead_date: leadDate,
        assign_to: assignTo,
        disposition_type: dispositiontype,
        disposition: disposition,
        operate_business: operateBusiness,
      };
      const detailedArr = [...details];

      d["contact_details"] = detailedArr;
      console.table(d);
      dispatch(editMeeting(d, auth.token));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/franchise/sub-categories/list?category=${3}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSector = (value) => {
    setSubCategory(value);
    axios
      .get(`/api/franchise/subsectordata?industry=${value}`)
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };
  //   useEffect(() => {
  //     dispatch({
  //       type: PARTNER_ALLOWED_PRODUCT_REQUEST,
  //       payload: auth?.token,
  //       fk_franchise_type: auth?.fk_franchise_type,
  //     });
  //   }, [auth]);

  //   const { saleTarget, allowedProduct } = useSelector(
  //     ({ dashboard }) => dashboard
  //   );
  //   const franchiseServicesList = useMemo(() => allowedProduct, [allowedProduct]);
  //   console.log(franchiseServicesList, "hiiiii");

  const phoneValid = (val) => {
    return val.length < 11 && !isNaN(val) ? true : false;
  };

  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Create User</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Assigned To
                    </label>

                    <Input
                      className="custom-select col-md-7"
                      type="select"
                      id="assignTo"
                      disabled={
                        auth?.id == "47" || auth?.id == "50" || auth?.id == "83"
                          ? false
                          : true
                      }
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                      invalid={!!errors?.assign_to}
                    >
                      <option value="">select</option>

                      {(Array.isArray(leadData.users)
                        ? leadData?.users
                        : []
                      ).map((item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Company Name</Label>
                      </Col>

                      <Col>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Email</Label>
                      </Col>

                      <Col>
                        <Input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Mobile</Label>
                      </Col>

                      <Col>
                        <Input
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Contact Person</Label>
                      </Col>

                      <Col>
                        <Input
                          value={contactperson}
                          onChange={(e) => setContactPerson(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Pincode
                    </Label>
                    <Input
                      type="text"
                      value={pincode}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setPincode(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      type="text"
                      value={city}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setCity(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      type="text"
                      value={state}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setState(value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      value={address}
                      rows={1}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAddress(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Purpose Of Meeting
                    </Label>
                    <Input
                      type="select"
                      value={meetingPurpose}
                      // invalid={Boolean(errors?.meetingPurpose)}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setMeetingPurpose(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Mitra">Mitra</option>
                      <option value="Sarthi">Sarthi</option>
                      <option value="Faciliation">Faciliation</option>
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.meetingPurpose}
                    </span>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Occupation
                    </Label>
                    <Input
                      type="text"
                      value={occupation}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setOccupation(value)}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Operate Any Business
                    </Label>
                    <Input
                      type="select"
                      value={operateBusiness}
                      // invalid={Boolean(errors?.meetingPurpose)}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setOperateBusiness(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.meetingPurpose}
                    </span>
                  </FormGroup>
                </Col>
                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Sector
                    </Label>
                    <Input
                      type="select"
                      value={sector}
                      className="col-md-7"
                      invalid={Boolean(errors?.sector)}
                      onChange={({ target: { value } }) => {
                        setSector(value);
                        handleSector(value);
                      }}
                    >
                      <option value="">Select</option>
                      {sectors.map((a) => {
                        return <option value={a.value}>{a.label}</option>;
                      })}
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.sector}
                    </span>
                  </FormGroup>
                </Col> */}
                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Industry
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      invalid={Boolean(errors?.industry)}
                      onChange={(e) => setSubCategory(e.target.value)}
                      value={subCategory}
                    >
                      <option value="">Select</option>
                      {industry.map((item) => {
                        return (
                          <option value={item.value} key={item.value}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.industry}
                    </span>
                  </FormGroup>
                </Col> */}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Lead Source
                    </Label>
                    <Input
                      type="select"
                      value={leadsource}
                      invalid={Boolean(errors?.leadsource)}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setLeadSource(value)}
                    >
                      <option value="">Select</option>

                      <option value="Ads">Ads</option>
                      <option value="Reference">Reference</option>
                      <option value="Inbound">Inbound</option>
                      <option value="Old_Leads">Old Leads</option>
                      <option value="Website">Website</option>
                      <option value="Google">Google</option>
                      <option value="Linkdin">Linkedin</option>
                      <option value="Call Center">Call Center</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.leadsource}
                  </span>
                </Col>

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Lead Status
                    </Label>
                    <Input
                      type="select"
                      value={leadstatus}
                      className="col-md-7"
                      invalid={Boolean(errors?.leadstatus)}
                      onChange={({ target: { value } }) => setLeadStatus(value)}
                    >
                      <option value="">Select</option>
                      <option value="active">Active</option>
                      <option value="hot">Hot</option>
                      <option value="warm">Warm</option>
                      <option value="not intrested">Not Interested</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.leadstatus}
                  </span>
                </Col> */}

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Investment
                    </Label>
                    <Input
                      type="select"
                      value={investment}
                      className="col-md-7"
                      invalid={Boolean(errors?.investment)}
                      onChange={({ target: { value } }) => {
                        setInvestment(value);
                        setPayment(value === "other");
                      }}
                    >
                      <option value="">Select</option>
                      <option value="walkin">0-10Lakh</option>
                      <option value="facebook">10-25Lakh</option>
                      <option value="google">20Lakh</option>
                      <option value="iid">30Lakh</option>
                      <option value="50lak">50Lakh</option>
                      <option value="1crore">1Crore</option>
                      <option value="2crore">2Crore</option>
                      <option value="5crore">5Crore</option>
                      <option value="other">Other</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.investment}
                  </span>
                </Col> */}

                {/* {payment && (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="mobile"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Investment Amount
                      </Label>
                      <Input
                        type="text"
                        value={otherInvestment}
                        className="col-md-7"
                        onChange={({ target: { value } }) => {
                          setOtherInvestment(value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )} */}
                {/* 
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Time required to start the association
                    </Label>
                    <Input
                      type="select"
                      value={timerequired}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setTimeRequired(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="15days">15 Days</option>
                      <option value="1months">1 Months</option>
                      <option value="2months">2 Months</option>
                      <option value="3months">3 Months</option>
                      <option value="6months">6 Months</option>
                      <option value="1year">1 Year</option>
                      <option value="2year">2 Years</option>
                      <option value="3year">3 Years</option>
                    </Input>
                  </FormGroup>
                </Col> */}

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Conversion
                    </Label>
                    <Input
                      type="select"
                      value={conversion}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setConversion(value)}
                    >
                      <option value="">Select</option>
                      <option value="15days">Yes</option>
                      <option value="1months">No</option>
                    </Input>
                  </FormGroup>
                </Col> */}

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Payment Status
                    </Label>
                    <Input
                      type="select"
                      value={paymentstatus}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setPaymentStatus(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="15days">Full Paid</option>
                      <option value="1months">Partial Paid</option>
                    </Input>
                  </FormGroup>
                </Col> */}

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Amount
                    </Label>
                    <Input
                      type="text"
                      value={amount}
                      className="col-md-7"
                      // invalid={Boolean(errors?.name)}
                      onChange={({ target: { value } }) => setAmount(value)}
                    />
                    <span className="d-block text-danger  col-md-7 ml-auto">
                      {errors?.name}
                    </span>
                  </FormGroup>
                </Col> */}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Disposition Type
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDispositionType(e.target.value)}
                      value={dispositiontype}
                    >
                      <option value=""> Select </option>
                      <option value="Connected">Connected</option>
                      <option value="Not_Connected">Not Connected</option>
                      <option value="Payment_Done">Payment Done</option>
                      <option value="Dump_Data">Dump Data</option>
                    </Input>
                  </FormGroup>
                </Col>

                {dispositiontype == "Connected" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="mobile"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Connected
                      </Label>
                      <Input
                        type="select"
                        className="col-md-7"
                        onChange={(e) => setDisposition(e.target.value)}
                        value={disposition}
                      >
                        <option value=""> Select </option>
                        <option value="Mail_Shared">Mail Shared</option>
                        <option value="Meeting_Align">Meeting Align</option>
                        <option value="Meeting_Done">Meeting Done</option>
                        <option value="Mail_Reshared">Mail Reshared</option>
                        <option value="Follow_Up">Follow up(Hot)</option>
                        <option value="Call_Back">Call Back</option>
                        <option value="Not_Interested">Not Interested</option>
                        <option value="Meeting_Done_NOI">
                          Meeting Done Not Interested
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                ) : null}

                {dispositiontype == "Not_Connected" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="mobile"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Not Connected
                      </Label>
                      <Input
                        type="select"
                        className="col-md-7"
                        onChange={(e) => setDisposition(e.target.value)}
                        value={disposition}
                      >
                        <option value=""> Select </option>

                        <option value="Npc">NPC</option>
                        <option value="Switch_Off">Switch Off</option>
                        <option value="Invalid_Number">Invalid Number</option>
                        <option value="Call_Rejected">Call Rejected</option>
                      </Input>
                    </FormGroup>
                  </Col>
                ) : null}
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      General Notes
                    </Label>
                    <textarea
                      type="text"
                      value={notes}
                      className="col-md-7"
                      // invalid={Boolean(errors?.name)}
                      onChange={({ target: { value } }) => setNotes(value)}
                    />
                    {/* <span className="d-block text-danger  col-md-7 ml-auto">
                      {errors?.name}
                    </span> */}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="website"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Website
                    </Label>
                    <Input
                      type="textarea"
                      value={website}
                      rows={1}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setWebsite(value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <div className="text-center">
                    <Button
                      type="submit"
                      color="secondary"
                      size="sm"
                      className="mr-1"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>

                    <Button color="secondary" size="sm" className="mr-1">
                      Save & New
                    </Button>

                    <a href={`/associate-team?page=${page}`}>
                      <Button type="button" color="secondary" size="sm">
                        Close
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </MainWrapper>
    </div>
  );
};

export default EditMeeting;
