import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import {sendSmsSales, sendSms, addSms } from "../../store/actions/LoginCallActions";
import { FaSpinner } from "react-icons/fa";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const sendSmsSale = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mobile, setMobile] = useState("");
  const [industry, setIndustry] = useState("");
  const [sector, setSector] = useState("");
  const [agent, setAgent] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("1");
  const [campaign, setCampaign] = useState("");
  
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector((state) => state.auth);

  const { send, addSmsStatus,errors, loading,sendsmssale } = useSelector((state) => state.callReducer);

  const { token } = auth;

  const handleSubmit = (e) => {
    e.preventDefault();
    setRefresh(true);
    const data = new FormData();
    data.append("mobile", mobile);
    data.append("industry", industry);
    data.append("sector", sector);
    data.append("agent", agent);
    data.append("campaign", campaign);
    dispatch(sendSmsSales(data, token));
    // const da = {
    //   agent_id: auth.id,
    //   agent_mobile: mobile,
    //   message: message,
    //   city: city,
    //   id: leadDetail?.id,
    // };
    // dispatch(addSms(da, token));
  };
  // useEffect(() => {
  //   const dataa = {
  //     lead_id: leadDetail?.id,
  //     activity_type: "Whatsapp",
  //     description: message,
  //   };
  //   if (sendsmssale) {
  //     setWsStatus(true);
  //     setModal(!modal);
  //     NotificationManager.success("Created Successfully", "Sccess");
  //     dispatch(addActivity(dataa, token));
  //   }
  // }, [sendsmssale]);

 

  useEffect(() => {
    if (sendsmssale && send) {
     
      setModal(false);
      //setRefresh(false);
      setWsStatus(true);
      setModal(!modal);
      setMessage("");
      NotificationManager.success("Message Send Successfully", "Success");
      dispatch({ type: "RESET_SMS_STATUS", payload: false });
    }
  }, [sendsmssale , send]);

  useEffect(() => {
    if (leadDetail?.mobile) {
      setMobile(
        leadDetail?.mobile?.startsWith("+91" || "91+" || "91")
          ? leadDetail?.mobile
          : `91${leadDetail?.mobile}`
      );
    }
  }, [leadDetail]);

  console.log(errors ,'campaign')
  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Send WhatsApp Sale
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="sm">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Send Sms
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>Mobile</Label>
                  <Input
                    name="mobile"
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                  <small className="text-danger">
                    Enter the number with country code. Example: 918400999177
                  </small>
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup>
                  <Label for="paymentType">Select Campaign <span className="text-danger pl-1">*</span></Label>
                  <Input
                    type="select"
                    id="paymentType"
                    value={campaign}
                    onChange={(e) => setCampaign(e.target.value)}
                    invalid={!!errors?.campaign}
                    className="custom-select"
                  >
                     <option value="">Select Campaign</option>
                    <option value="CRM_SALE">CRM SALE</option>
                    <option value="CRM_WELCOME">CRM WELCOME</option>
                    <option value="membershipmsg">CRM MEMBERSHIP</option>
                    <option value="prmessage">CRM PROJECT REPORT</option>
                    <option value="coursemsg">CRM COURSE</option>
                    <option value="bankdetail">CRM BANK DETAIL</option>
                    <option value="startup_studio_welcome">Startup Studio Welcome</option>
                    <option value="pnpmessage2">PNP MESSAGE</option>
                  </Input>
                  <FormFeedback className="text-left">
                              {errors?.campaign}
                            </FormFeedback>
                </FormGroup>
              </Col>
              { campaign == 'CRM_SALE' || campaign == 'CRM_WELCOME' ?
                (
                  <Col sm="12">
                  <FormGroup>
                    <Label>Industry Name <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="industry"
                      value={industry}
                      onChange={({ target: { value } }) => setIndustry(value)}
                      invalid={!!errors.industry}
                    />
                     <FormFeedback className="text-left">
                                {errors?.industry}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }
                  { campaign == 'CRM_SALE' || campaign == 'CRM_WELCOME' ?
                (
                <Col sm="12">
                  <FormGroup>
                    <Label>Sector Name <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="sector"
                      value={sector}
                      onChange={({ target: { value } }) => setSector(value)}
                      invalid={!!errors.sector}
                    />
                     <FormFeedback className="text-left">
                                {errors?.sector}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }
                  { campaign == 'CRM_SALE' || campaign == 'CRM_WELCOME' ?
                (
                <Col sm="12">
                  <FormGroup>
                    <Label>Agent Name <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="agent"
                      value={agent}
                      onChange={({ target: { value } }) => setAgent(value)}
                      invalid={!!errors.agent}
                    />
                     <FormFeedback className="text-left">
                                {errors?.agent}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                )  : ''  }

{ campaign == 'membershipmsg' || campaign == 'prmessage'  || campaign == 'coursemsg' ?
                (
                  <Col sm="12">
                  <FormGroup>
                    <Label>Website <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="industry"
                      value={industry}
                      onChange={({ target: { value } }) => setIndustry(value)}
                      invalid={!!errors.industry}
                    />
                     <FormFeedback className="text-left">
                                {errors?.industry}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }
                  { campaign == 'membershipmsg' || campaign == 'prmessage'  || campaign == 'coursemsg' ?
                (
                <Col sm="12">
                  <FormGroup>
                    <Label>Download App <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="sector"
                      value={sector}
                      onChange={({ target: { value } }) => setSector(value)}
                      invalid={!!errors.sector}
                    />
                     <FormFeedback className="text-left">
                                {errors?.sector}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }

{ campaign == 'startup_studio_welcome' ?
                (
                  <Col sm="12">
                  <FormGroup>
                    <Label>Service Name <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="industry"
                      value={industry}
                      onChange={({ target: { value } }) => setIndustry(value)}
                      invalid={!!errors.industry}
                    />
                     <FormFeedback className="text-left">
                                {errors?.industry}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }
                  { campaign == 'startup_studio_welcome' ?
                (
                <Col sm="12">
                  <FormGroup>
                    <Label>Name <span className="text-danger pl-1">*</span></Label>
                    <Input
                      name="sector"
                      value={sector}
                      onChange={({ target: { value } }) => setSector(value)}
                      invalid={!!errors.sector}
                    />
                     <FormFeedback className="text-left">
                                {errors?.sector}
                              </FormFeedback>
                  </FormGroup>
                </Col>
                 )  : ''  }

            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {/* {refresh ? <FaSpinner /> : "Send"} */}

              {loading ? (
                                <FaSpinner className="fa-spin" />
                              ) : (
                                "Send"
                              )}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default sendSmsSale;
