import _ from "lodash";

const initialState = {
  memberShips: [],
  memberShipbyId: {},
  bookedMemberShips: [],
  bookedMemberShip: {},
  users: [],
  sectors: [],
  sectorsDrop: [],
  projects: [],
  loading: false,
  success: false,
  createStatus: false,
  errors: {},
  bookedMember: {},
  membershipStatus:false
}

const memberShipReducer = (state = initialState, action) => {

  switch (action.type) {
    case "MEMBERSHIP_LIST_PENDING":
    case "MEMBERSHIP_PENDING":
    case "MEMBERSHIP_CREATE_PENDING":
    case "MEMBERSHIP_EDIT_PENDING":
    case "MEMBERSHIP_USER_PENDING":
    case "MEMBERSHIP_BY_ID_PENDING":
    case "SECTOR_LIST_PENDING":
    case "SECTOR_LIST_DROP_PENDING":
    case "PROJECT_LIST_PENDING":
    case "CHANGE_MEMBERSHIP_STATE":
      return {
        ...state,
        loading: true,
        success: false,
        createStatus: false,
        membershipStatus:false,
        errors: {},
        bookedMember: {}
      };
    case "MEMBERSHIP_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        bookedMemberShips: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        memberShips: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        users: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_BY_ID_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        memberShipbyId: _.get(action.payload, "data", {}),
      };
    case "SECTOR_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        sectorsDrop: _.get(action.payload, "data", {}),
      };
    case "SECTOR_LIST_DROP_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        sectors: _.get(action.payload, "data", {}),
      };
    case "PROJECT_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        projects: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_LIST_REJECTED":
    case "MEMBERSHIP_REJECTED":
    case "MEMBERSHIP_CREATE_REJECTED":
    case "MEMBERSHIP_EDIT_REJECTED":
    case "MEMBERSHIP_USER_REJECTED":
    case "MEMBERSHIP_BY_ID_REJECTED":
    case "SECTOR_LIST_REJECTED":
    case "SECTOR_LIST_DROP_REJECTED":
    case "PROJECT_LIST_REJECTED":

      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          membershipStatus:false,
          errors: _.get(data, "errors", {}),
        };
      } else {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          membershipStatus:false,
          errors: _.get(data, "message", ""),
        };
      }

    case "MEMBERSHIP_EDIT_FULFILLED":
    case "MEMBERSHIP_CREATE_FULFILLED":
      return {
        ...state,
        loading: false,
       createStatus: true,
        membershipStatus:true,
        bookedMember: _.get(action?.payload?.data, "payment", {}),
      };
    default:
      return state;
  }

}

export default memberShipReducer;