
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { getCrmUsersApi } from '../../../store/actions/LeadActions';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { useParams } from 'react-router';
import { NotificationManager } from 'react-notifications';
const LeadClosed = ({setRefresh}) => {
    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        closeDate: "",
        activity: ""
    })
    const { id } = useParams()
    const { auth, lead } = useSelector((state) => ({
        auth: state.auth,
        lead: state.lead.leadData
    }))
    const toggle = () =>{
         setModal(!modal)
         if (modal) {
            setFormData(
                {
                    closeDate: "",
                    activity: ""
                }
            )
         }
        
        };
    const handleSubmit = () => {
        axios.post("/api/crm/save-lead-close", {
            close_date: formData.closeDate,
            close_type: formData.activity,
            lead_id: id,
            agent_id: auth?.auth?.id
        })
            .then(({ data }) => {
                NotificationManager.success("Updated close date")
                setFormData({
                    closeDate: "",
                    activity: ""
                })
                setModal(false)
                setRefresh(prev=>prev+1)
            })
            .catch(err => {
                console.error(err)
                setErrors(err?.response?.data?.errors)
                console.log(err?.response?.data)
            })
    }

    const inputHandler = (e) => {
        e.persist()
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    return (
        <div>
            <span role='button' className='text-primary cursor-pointer' onClick={toggle}>
                Lead Closed
            </span>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Lead Closed</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Activity</Label>
                        <Input name="activity" invalid={Boolean(errors?.close_type)} value={formData.activity} onChange={inputHandler} type="select">
                            <option value="">Select</option>
                            <option className="Membership">Membership</option>
                            <option className="Workshop">Workshop</option>
                            <option className="Project Report">
                                Project Report
                            </option>
                            <option className="Industrial Report">
                                Industrial Report
                            </option>
                            <option className="Customized Report">
                                Customized Report
                            </option>
                            <option className="Edp">Edp</option>
                            <option className="Course">Course</option>
                        </Input>
                        <span className='d-block text-danger' > {errors?.close_type} </span>
                    </FormGroup>
                    <FormGroup>
                        <Label>Closed Date</Label>
                        <Input invalid={Boolean(errors?.close_date)} type="date" name="closeDate" value={formData.closeDate} onChange={inputHandler} />
                        <span className='d-block text-danger' > {errors?.close_date} </span>
                    </FormGroup>

                </ModalBody>
                <ModalFooter className="justify-content-start" >
                    <Button color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default LeadClosed


