import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  createSourceApi,
  getSourceList,
} from "../../store/actions/LeadActions";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router";
import { FaSpinner } from "react-icons/fa";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import moment from "moment";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router";

const EditTataPlayLead = () => {
  const { id } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [brandName, setBrandname] = useState("");
  const [customername, setCustomerName] = useState("");

  const [conversion, setConversion] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactNo] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);

  const [occupation, setOccupation] = useState("");

  const [leadSource, setleadSource] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [Amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [website, setWebsite] = useState("");
  const [sources, setSources] = useState([]);
  const [investment, setInvestment] = useState("");
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [errors, setErrors] = useState({});
  const [leadDate, setLeadDate] = useState("");
  const dispatch = useDispatch();
  const { auth } = useSelector(({ auth }) => auth);
  const [assignTo, setAssignTo] = useState(auth?.id);
  const { leadData } = useSelector(({ lead }) => lead);
  const [mailShared, setMailShared] = useState("");
  const [frenchiseList, setFranchiseList] = useState();
  const [assignedLocation, setAssignLocation] = useState("");
  const [brand, setBrand] = useState("");
  const [page, setPage] = useState("");
  const [paginate, setPaginate] = useState("");
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    const url = new URL(window.location).searchParams;
    const mo = url.get("page");
    const pa = url.get("paginate");
    setPage(mo);
    setPaginate(pa);
  }, []);

  const {
    createSource: createSourcee,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
  } = useSelector(({ lead }) => lead);

  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  useEffect(() => {
    dispatch(getSourceList(auth?.token));
  }, [auth?.token]);

  useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
    axios
      .get(`/api/crm/get-franchise-investor-detail/?id=${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.franchiseInvestorDetail);
        setAssignTo(res.data?.franchiseInvestorDetail?.assign_to);
        setCustomerName(res.data?.franchiseInvestorDetail?.customer_name);
        setContactNo(res.data?.franchiseInvestorDetail?.contact_number);
        setEmail(res.data?.franchiseInvestorDetail?.email);
        setPincode(res.data?.franchiseInvestorDetail?.pincode);
        setState(res.data?.franchiseInvestorDetail?.state);
        setContactPerson(res.data?.franchiseInvestorDetail?.contact_person);
        setCity(res.data?.franchiseInvestorDetail?.city);
        setInvestment(res.data?.franchiseInvestorDetail?.investment);
        setLeadDate(res.data?.franchiseInvestorDetail?.lead_date);
        setBrandname(res.data?.franchiseInvestorDetail?.brand);
        setOccupation(res.data?.franchiseInvestorDetail?.occupation);
        setleadSource(res.data?.franchiseInvestorDetail?.lead_source);
        setLeadStatus(res.data?.franchiseInvestorDetail?.lead_status);
        setDispositionType(res.data?.franchiseInvestorDetail?.disposition_type);
        setNote(res.data?.franchiseInvestorDetail?.client_remarks);
        setMailShared(res.data?.franchiseInvestorDetail?.mail_shared);

        setContactPerson(res.data?.franchiseInvestorDetail?.contact_person);
        setDisposition(res.data?.franchiseInvestorDetail?.disposition);
        setAssignLocation(
          res.data?.franchiseInvestorDetail?.assign_lead_location
        );
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    if (pincode?.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  useEffect(() => {
    axios
      .get(`/api/crm/franchise-brands`)
      .then((res) => setBrand(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    const data = {};
    data["company_name"] = companyName;
    data["customer_name"] = customername;
    data["contact_number"] = contactno;
    data["email"] = email;
    data["contact_person"] = contactPerson;
    data["pincode"] = pincode;
    data["city"] = city;
    data["state"] = state;
    data["lead_status"] = leadStatus;
    data["lead_source"] = leadSource;
    data["investment"] = investment;
    data["pincode"] = pincode;
    data["client_remarks"] = note;
    data["amount"] = Amount;
    data["company_name"] = companyName;
    data["assign_to"] = assignTo;
    data["lead_date"] = leadDate;
    data["website"] = website;
    data["brand"] = brandName;
    data["status"] = 1;
    data["occupation"] = occupation;
    data["disposition_type"] = dispositiontype;
    data["disposition"] = disposition;
    data["assign_lead_location"] = assignedLocation;
    data["campaign"] = auth.campaign;
    data["mail_shared"] = mailShared;
    axios
      .post(
        `/api/crm/update-franchise-investor/?id=${id}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          NotificationManager.success("Updated Successfully");
          window.location.href = `/tata-play-list?page=${page}&paginate=${paginate}`;
          setLoading(false);
        }
      })
      .catch((errors) => {
        NotificationManager.error("Something went wrong");
        setLoading(false);
        setErrors(errors?.response?.data?.errors);
      });
  };

  const phoneValid = (val) => {
    return val.length < 11 && !isNaN(val) ? true : false;
  };

  console.log(leadSource);
  return (
    <MainWrapper>
      <main className="content">
        <Card>
          <CardHeader>Franchise Investor Acquisition </CardHeader>
          <CardBody>
            <Row>
              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Assigned To
                  </label>

                  <Input
                    className="custom-select col-md-7"
                    type="select"
                    id="assignTo"
                    disabled={true}
                    onChange={(e) => setAssignTo(e.target.value)}
                    value={assignTo}
                  >
                    {(Array.isArray(leadData.users) ? leadData?.users : []).map(
                      (item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      }
                    )}
                  </Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Lead Date
                  </label>

                  <Input
                    type="date"
                    className=" col-md-7"
                    value={leadDate}
                    onChange={({ target: { value } }) => setLeadDate(value)}
                  ></Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Company Name</Label>
                    </Col>

                    <Col>
                      <Input
                        value={customername}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Contact No</Label>
                    </Col>

                    <Col>
                      <Input
                        value={contactno}
                        onChange={(e) => setContactNo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Contact Person</Label>
                    </Col>

                    <Col>
                      <Input
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Email</Label>
                    </Col>

                    <Col>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Pincode</Label>
                    </Col>

                    <Col>
                      <Input
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>City</Label>
                    </Col>

                    <Col>
                      <Input
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>State</Label>
                    </Col>

                    <Col>
                      <Input
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label> Source</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        className="custom-select"
                        value={leadSource}
                        onChange={(e) => setleadSource(e.target.value)}
                      >
                        <option>Select</option>
                        {sources.map((asu) => (
                          <option value={asu.label} key={asu.label}>
                            {asu.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Lead Source</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={leadSource}
                        onChange={(e) => setleadSource(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="website">Website</option>
                        <option value="google">Google</option>
                        <option value="personal">Personal</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Is Mail Shared</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={mailShared}
                        onChange={(e) => setMailShared(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup className="row">
                  <Label
                    for="mobile"
                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                  >
                    Disposition Type
                  </Label>
                  <Input
                    type="select"
                    className="col-md-7"
                    onChange={(e) => setDispositionType(e.target.value)}
                    value={dispositiontype}
                  >
                    <option value=""> Select </option>
                    <option value="Connected">Connected</option>
                    <option value="Not_Connected">Not Connected</option>
                    <option value="Closed">Closed</option>
                  </Input>
                </FormGroup>
              </Col>

              {dispositiontype == "Connected" ? (
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Disposition
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDisposition(e.target.value)}
                      value={disposition}
                    >
                      <option value=""> Select </option>
                      <option value="Follow_Up_Hot">Follow up(Hot)</option>
                      <option value="Meeting_Align">Meeting Align</option>
                      <option value="Meeting_Done">Meeting Done</option>
                      <option value="Follow_Up">Follow up</option>
                      <option value="Call_Back">Call Back</option>
                      <option value="Not_Interested">Not Interested</option>
                      <option value="Meeting_Done_NOI">
                        Meeting Done Not Interested
                      </option>
                      <option value="Payment_Done">Payment Done</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null}

              {dispositiontype == "Not_Connected" ? (
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Not Connected
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDisposition(e.target.value)}
                      value={disposition}
                    >
                      <option value=""> Select </option>

                      <option value="Npc">NPC</option>
                      <option value="Switch_Off">Switch Off</option>
                      <option value="Invalid_Number">Invalid Number</option>
                      <option value="Call_Rejected">Call Rejected</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null}
              <Col sm={6}>
                <FormGroup className="row">
                  <Label
                    for="mobile"
                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                  >
                    Assigned Lead Location
                  </Label>
                  <Input
                    type="select"
                    className="col-md-7"
                    onChange={(e) => setAssignLocation(e.target.value)}
                    value={assignedLocation}
                  >
                    <option value=""> Select </option>
                    <option value="Lucknow">Lucknow</option>
                    <option value="Noida">Noida</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Client Remarks</Label>
                    </Col>

                    <Col>
                      <Input
                        type="textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={12}>
                <div className="btn-wrapper text-center">
                  {loading ? (
                    <FaSpinner className="fa fa-spin" />
                  ) : (
                    <Button color="primary" onClick={handleSubmit}>
                      Update
                    </Button>
                  )}
                  <Button className="ml-2" onClick={() => history.goBack()}>
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </main>
    </MainWrapper>
  );
};

export default EditTataPlayLead;
