import React, { Fragment, useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import Loader from "../../layouts/Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const CreateMeeting = (props) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});
  const [partner, setPartner] = useState("");
  const [fields, setFields] = useState("");
  const [franchise, setFranchise] = useState([]);
  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const { auth } = useSelector(({ auth }) => auth);

  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  useEffect(() => {
    axios
      .get(`api/crm/supplier-all`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => {
        setFranchise(res?.data?.franchise);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(partner);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(fields);
    const data = new FormData();
    data.append("file", fields);
    data.append("partner", partner);
    axios
      .post("api/crm/import-supplier-partner-lead", data)
      .then((res) => {
        setLoading(false);
        console.log(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
        props.addCategory(res.data.category);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          console.log(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <ButtonGroup onClick={() => setModal(true)}>
        <Button className="btn btn-sm btn-secondary">
          <FaPlus className="btn-i-icon" />
          Import
        </Button>
      </ButtonGroup>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>
          Import Meeting
          <a
            href="/storage/partner_sample.csv"
            style={{ "margin-left": "170px", color: "blue" }}
          >
            sheet
          </a>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label>IID Partner</label>
              <CustomInput
                type="select"
                id="partner"
                name="partner"
                label="partner"
                value={partner}
                onChange={(e) => setPartner(e.target.value)}
                invalid={!!errors?.partner}
              >
                <option value="">select</option>
                {franchise.map((fc, i) => (
                  <option value={fc?.value} key={i}>
                    {fc?.label}
                  </option>
                ))}
              </CustomInput>
              <FormFeedback>
                {!!errors.partner ? errors.partner[0] : ""}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <CustomInput
                type="file"
                id="file"
                name="file"
                label="Choose File"
                onChange={handleChange}
                invalid={!!errors?.file}
              />
              <FormFeedback>{!!errors.file ? errors.file[0] : ""}</FormFeedback>
            </FormGroup>
            <div className="text-center mb-3">
              <Loader loading={loading}>
                <Button
                  type="submit"
                  color="secondary"
                  size="sm"
                  className="mr-1"
                >
                  Save
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  size="sm"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Loader>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CreateMeeting;
