import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import SMS from "../utils/SMS";
import Mail from "../utils/Mail";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import moment from "moment";
import {
  leadsApi,
  leadDestroy,
  getSourceList,
} from "../../store/actions/LeadActions";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { sectors, projects } from "../../store/actions/MemberShipActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import { SalesDashboardCount } from "../../store/actions/DashboardActions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
const List = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);

  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  const {
    leadsLoader,
    error,
    leads,
    leadDestroyStatus,
    getSources,
    getSourcesStatus,
    leadsStatus,
  } = useSelector((state) => state.lead);
  const {
    sectors: { subcategory },
    projects: { project_reports },
  } = useSelector((state) => state.memberShip);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(25);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [sectorSearch, setSectorSearch] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [project, setProject] = useState("");
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState("");
  const [leadDate, setLeadDate] = useState("");
  const [createdByAdmin, seCreatedByAdmin] = useState([]);
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [createdby, setCreatedBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cartList, setCartList] = useState([]);
  const [loading, setLoading] = useState(false);
 
  const data = (
    cartList?.products?.data ? cartList?.products?.data : []
  )?.filter((item) => {
    return item?.user !== null && item?.user !== undefined;
  });

  useEffect(() => {
    dispatch(
      leadsApi(
        token,
        paginate,
        search,
        page,
        sort,
        sectorSearch,
        project,
        source,
        leadDate,
        dispositiontype,
        disposition,
        createdby
      )
    );
  }, [
    token,
    refresh,
    paginate,
    search,
    sort,
    page,
    sectorSearch,
    project,
    source,
    leadDate,
    disposition,
    dispositiontype,
    createdby,
  ]);
  useEffect(() => {
    dispatch(SalesDashboardCount(token, startDate, endDate));
  }, [token, endDate]);

  useEffect(() => {
    dispatch(sectors(token));
  }, [token]);
  useEffect(() => {
    if (!_.isNull(sectorSearch) && sectorSearch) {
      dispatch(projects(token, [sectorSearch]));
    }
  }, [token, sectorSearch]);
  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);
  const sectorData = useMemo(() => subcategory, [subcategory]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  const handleProjectChange = (e) => {
    const { value } = e.target;
    setProject(value);
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `/api/crm/product-listing?&paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setCartList(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [paginate, search, page, sort, token]);

  useEffect(() => {
    if (leadDestroyStatus) {
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh((refresh) => refresh + 1);
    }
  }, [leadDestroyStatus]);
  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify(deleteIds),
      };
      dispatch(leadDestroy(delArr, token));
    }
  };

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="c-card-top d-flex flex-wrap">
                    <div className="c-card-top-title">
                      <h4 className="mb-0">Cart List</h4>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                        </div>
                        <Input
                          placeholder="Search here..."
                          value={search}
                          onChange={handleSearch}
                          className="c-form-control-serch"
                        />
                        <div className="input-group-append">
                          <button
                            title="Advance Search"
                            onClick={() => {
                              advanceSearch
                                ? setAdvanceSearch(false)
                                : setAdvanceSearch(true);
                            }}
                            id="c_searchBlock_toggle"
                            className="btn btn-secondary btn-sm c-card-top-block-icon"
                            type="button"
                          >
                            <i
                              className="fa fa-angle-down fa-2x text-dark"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            className="btn btn-secondary btn-sm"
                            type="button"
                            onClick={handleSerchByClick}
                          >
                            GO
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          advanceSearch ? "c-card-top-block" : "d-none"
                        }
                        id="c_searchBlock"
                      >
                        <div className="c-card-top-block-sec">
                          <div className="c-card-top-block-item">
                            <label>Sector</label>
                            <Input
                              type="select"
                              className="custom-select"
                              onChange={(e) => setSectorSearch(e.target.value)}
                              value={sectorSearch}
                            >
                              <option value="">Select</option>
                              {sectorData
                                ? sectorData.map((sec, i) => {
                                    return (
                                      <option value={sec.value} key={i}>
                                        {sec.label}
                                      </option>
                                    );
                                  })
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Industry</label>
                            <Input
                              className="custom-select"
                              type="select"
                              id="project"
                              onChange={handleProjectChange}
                            >
                              <option value="">Select</option>
                              {subSubCategories
                                ? subSubCategories.map((sub, i) => {
                                    return (
                                      <option value={sub.value} key={i}>
                                        {sub.label}
                                      </option>
                                    );
                                  })
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Created By</label>
                            <select
                              id="paginate"
                              onChange={(e) => setCreatedBy(e.target.value)}
                              value={createdby}
                              className="custom-select"
                            >
                              <option value="">Created By</option>
                              {createdByAdmin
                                ? createdByAdmin.map((cr) => (
                                    <option value={cr?.value} key={cr.value}>
                                      {cr?.label}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Sources</label>
                            <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) => setSource(e.target.value)}
                            >
                              <option value="">select</option>
                              {sources
                                ? sources.map((sou, i) => (
                                    <option value={sou?.value} key={i}>
                                      {sou?.label}
                                    </option>
                                  ))
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Deposition Type</label>
                            <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) =>
                                setDispositionType(e.target.value)
                              }
                            >
                              <option value="">select</option>
                              <option value="Connected">Connected</option>
                              <option value="Not_Connected">
                                Not Connected
                              </option>
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Deposition</label>
                            <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) => setDisposition(e.target.value)}
                            >
                              <option value="">select</option>
                              <option value="Interested">Interested</option>
                              <option value="Not_Interested">
                                Not Interested
                              </option>
                              <option value="Details_Shared_Follow_up">
                                Details Shared Follow up
                              </option>
                              <option value="Call_Back">Call Back</option>
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Lead Date</label>
                            <Input
                              type="date"
                              placeholder="Lead Date"
                              onChange={(e) => setLeadDate(e.target.value)}
                            />
                          </div>
                          <div className="c-card-top-block-action">
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Reset
                            </button>
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-card-top-buttons">
                      <div role="group" className="btn-group">
                        {permissions ? (
                          permissions.find((ps) => ps.name === "lead-create") !=
                          null ? (
                            <Link to="/leads/add">
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm mr-1"
                              >
                                <i className="fa fa-plus" />
                                &nbsp;&nbsp;Add New
                              </button>
                            </Link>
                          ) : null
                        ) : null}
                        <Link to="/leads/imports">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm mr-1"
                          >
                            <i className="fa fa-download" />
                            &nbsp;&nbsp;Import Leads
                          </button>
                        </Link>
                        <div className="btn-group">
                          <button
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle btn btn-secondary btn-sm mr-1"
                          >
                            <i className="fa fa-list" />
                            &nbsp;&nbsp;Leads Tools
                          </button>
                          <div className="dropdown-menu">
                            <a href="#" className="dropdown-item">
                              Lead Status
                            </a>
                            <a href="#" className="dropdown-item">
                              Lead Rating
                            </a>
                            <a href="#" className="dropdown-item">
                              Source
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header pl-3 d-card-header d-flex justify-content-between">
                    <div>
                      {/* <SMS />
                      <Mail /> */}
                      {permissions ? (
                        permissions.find((ps) => ps.name === "lead-delete") !=
                        null ? (
                          <button
                            className="btn btn-secondary btn-sm"
                            disabled={!deleteIds.length}
                            onClick={handleDelete}
                          >
                            <i className="fa fa-trash pr-1" /> Delete
                          </button>
                        ) : null
                      ) : null}

                      {permissions ? (
                        permissions.find((ps) => ps.name === "lead-edit") !=
                        null ? (
                          <button
                            className="btn btn-secondary btn-sm"
                            data-toggle="modal"
                            data-target="#massUpdate"
                          >
                            <i className="fa fa-list pr-1" /> Mass Update
                          </button>
                        ) : null
                      ) : null}
                      <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>

                    <div className="box-tools pull-right text-end">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">S.No</th>
                                <th className="font-weight-bold">Full Name</th>
                                <th className="font-weight-bold">Mobile</th>
                                <th className="font-weight-bold">Product</th>
                                <th className="font-weight-bold">
                                  product Price
                                </th>
                                <th className="font-weight-bold">
                                  product Type
                                </th>
                                <th className="font-weight-bold">
                                  Created Time
                                </th>

                                {permissions.find(
                                  (ps) =>
                                    ps.name === "lead-delete" ||
                                    ps.name === "lead-edit"
                                ) != null ? (
                                  <th className="text-right font-weight-bold">
                                    Actions
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            {loading ? (
                              <Fragment>
                                <tr>
                                  <td colSpan={7}>
                                    <Loader loading={loading} />
                                  </td>
                                </tr>
                              </Fragment>
                            ) : (
                              <>
                                {(data ? data : [])?.map((item, index) => {
                                  return (
                                    <tbody
                                      className="d-table-body"
                                      key={item?.id}
                                    >
                                      <Fragment>
                                        <tr key={Math.random()}>
                                          <td>{index + 1}</td>
                                          <td>{item?.user?.name}</td>
                                          <td>{item?.user?.mobile}</td>
                                          <td>{item?.project?.title}</td>
                                          <td>{item?.project?.amount}</td>
                                          <td>{item?.product_type}</td>
                                          <td>
                                            {moment(item?.created_at).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                        </tr>
                                      </Fragment>
                                    </tbody>
                                  );
                                })}
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {cartList?.products?.from} to{" "}
                        {cartList?.products?.to} of {cartList?.products?.total}{" "}
                        entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={cartList?.products?.total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default List;
