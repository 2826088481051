import React, { Fragment } from 'react';
import { NotificationContainer } from 'react-notifications';
import Routes from './Routes';
import "./index.css"

const App = () => {

  return (
    <Fragment>
      <NotificationContainer />
      <Routes />
    </Fragment>
  );
};


export default App;
