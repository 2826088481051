import _ from "lodash";

const initialState = {
    createPriorityStatus: false,
    createPriorityLoader: false,
    createPriority: [],

    loading: false,
    status: false,
    priority: [],

    deletePriority: [],

    getPrioritybyIdLoading: false,
    getPrioritybyIdStatus: false,
    getPrioritybyId: [],

    updatePriorityLoader: false,
    updatePriorityStatus: false,
    updatePriority: [],

    allPriorityLoading: false,
    allPriorityStatus: false,
    allPriority: [],

    error: {},
};

const priorityReducer = (state = initialState, actions) => {
    const { payload } = actions;
    switch (actions.type) {
        case "CREATE_PRIORITY_PENDING":
            state = {
                ...state,
                createPriorityLoader: true,
                createPriorityStatus: false,
                error: {},
            };
            break;
        case "CREATE_PRIORITY_FULFILLED":
            state = {
                ...state,
                createPriorityLoader: false,
                createPriorityStatus: true,
                createPriority: _.get(payload, "data", {}),
            };
            break;
        case "CREATE_PRIORITY_REJECTED":
            const {
                payload: {
                    response: { status, data },
                },
            } = actions;
            if (status === 422) {
                state = {
                    ...state,
                    createPriorityLoader: false,
                    createPriorityStatus: false,
                    error: _.get(data, "errors", {}),
                };
            } else {
                state = {
                    ...state,
                    createPriorityLoader: false,
                    createPriorityStatus: false,
                    error: _.get(data, "message", {}),
                };
            }
            break;
        case "PRIORITY_LIST_PENDING":
            state = {
                ...state,
                loading: true,
                updatePriorityStatus: false,
                createPriorityStatus: false,
                status: false,
                error: {},
            };
            break;
        case "PRIORITY_LIST_FULFILLED":
            state = {
                ...state,
                loading: false,
                status: true,
                priority: _.get(payload, "data", {}),
            };
            break;
        case "PRIORITY_LIST_REJECTED":
            state = {
                ...state,
                loading: false,
                status: false,
                error: _.get(payload.response.data, "errors", {}),
            };
            break;
        case "PRIORITY_DELETE_PENDING":
            state = {
                ...state,
                loading: true,
                status: false,
                error: {},
            };
            break;
        case "PRIORITY_DELETE_FULFILLED":
            state = {
                ...state,
                loading: false,
                status: true,
                deletePriority: _.get(payload, "data", {}),
            };
            break;
        case "PRIORITY_DELETE_REJECTED":
            state = {
                ...state,
                loading: false,
                status: false,
                error: _.get(payload.response.data, "error", {}),
            };
            break;
        case "PRIORITY_BY_SLUG_PENDING":
            state = {
                ...state,
                getPrioritybyIdLoading: true,
                getPrioritybyIdStatus: false,
                error: {},
            };
            break;
        case "PRIORITY_BY_SLUG_FULFILLED":
            state = {
                ...state,
                getPrioritybyIdLoading: false,
                getPrioritybyIdStatus: true,
                getPrioritybyId: _.get(payload, "data", {}),
            };
            break;
        case "PRIORITY_BY_SLUG_REJECTED":
            state = {
                ...state,
                getPrioritybyIdLoading: false,
                getPrioritybyIdStatus: false,
                error: _.get(payload.response.data, "errors", {}),
            };
            break;
        case "UPDATE_PRIORITY_PENDING":
            state = {
                ...state,
                updatePriorityLoader: true,
                updatePriorityStatus: false,
                error: {},
            };
            break;
        case "UPDATE_PRIORITY_FULFILLED":
            state = {
                ...state,
                updatePriorityLoader: false,
                updatePriorityStatus: true,
                updatePriority: _.get(payload, "data", {}),
            };
            break;
        case "UPDATE_PRIORITY_REJECTED":
            if (status === 422) {
                state = {
                    ...state,
                    updatePriorityLoader: false,
                    updatePriorityStatus: false,
                    error: _.get(payload.response.data, "errors", {}),
                };
            } else {
                state = {
                    ...state,
                    updatePriorityLoader: false,
                    updatePriorityStatus: false,
                    error: _.get(payload.response.data, "message", {}),
                };
            }
            break;
        case "ALL_PRIORITY_PENDING":
            state = {
                ...state,
                allPriorityLoading: true,
                allPriorityStatus: false,
                error: {},
            };
            break;
        case "ALL_PRIORITY_FULFILLED":
            state = {
                ...state,
                allPriorityLoading: false,
                allPriorityStatus: true,
                allPriority: _.get(payload, "data", {}),
            };
            break;
        case "ALL_PRIORITY_REJECTED":
            state = {
                ...state,
                allPriorityLoading: false,
                allPriorityStatus: false,
                error: _.get(payload.response.data, "errors", {}),
            };
            break;
    }
    return state;
};

export default priorityReducer;
