import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import "highcharts/css/highcharts.css";
import HighchartsMap from "highcharts/modules/map";
const Location = () => {
  const [datas, setDatas] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [user, setUser] = useState(10);
  const [loading, setLoading] = useState(true);
  const [pointers, setPointers] = useState([]);

  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const permissions = React.useMemo(() => permission ?? [], [permission]);
  const { leadData } = useSelector(({ lead }) => lead);
  HighchartsMap(Highcharts);

  useEffect(() => {
    setDatas(locationList);
  }, [locationList])

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-location-report?user_id=${user}`,
        {
          headers: { authorization: `Bearer ${auth?.token}` },
        }
      )
      .then((res) => {
        setLocationList(res.data.getReport.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [user, auth?.token]);


  useEffect(() => {
    setPointers(datas.map(v => {
      return [v?.crm_user.name, v?.punch_In_address, v?.punch_In_datetime, parseFloat(v?.punch_In_lat), parseFloat(v?.punch_In_long), 1], [v?.crm_user.name, v?.punch_out_address, v?.punch_out_datetime, parseFloat(v?.punch_out_lat), parseFloat(v?.punch_out_long), 1]

    }))
  }, [datas])

  useEffect(() => {
    (async () => {
      const topology = await fetch(
        "https://code.highcharts.com/mapdata/countries/in/custom/in-all-disputed.topo.json"
      ).then((response) => response.json());


      Highcharts.mapChart("container", {
        chart: {
          map: topology,
          backgroundColor: '#d8f9d8',
          width: 1000, // Set the width of the chart
          height: 800,
        },
        title: {
          text: "",
          align: "left",
        },
        subtitle: {
          align: "left",
        },
        mapNavigation: {
          enabled: true,
        },
        legend: {
          enabled: false,
          itemHoverStyle: {
            color: '#ff0000',
          }
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom",
          },
        },

        mapView: {
          padding: 5,
        },

        tooltip: {
          headerFormat: "",
          pointFormat: '<span style="color: #000;">Agent : </span>{point.name}<br/>' +
            '<span style="color: #000;">City : </span> {point.city}<br/>' +
            '<span style="color: #000;">Date : </span> {point.date}<br/>',
        },
        series: [
          {
            name: "Agent Locations",
            borderColor: "#A0A0A0",
            nullColor: "#FF0000",
            showInLegend: false,
          },

          {
            name: "Agent Location",
            type: "mapbubble",
            color: "#d568fb",
            lineWidth: 1,

            keys: [
              "name",
              "city",
              "date",
              "lat",
              "lon",
              "z"
            ],
            data: pointers,
            minSize: "2%",
            maxSize: "2%",
            accessibility: {
              point: {
                valueDescriptionFormat: "{point.name}",
              },
            },
          },
        ],
      });
    })();
  }, [pointers]);

  return (
    <>
      <div className="row">
        <div className="col-md-6 py-3 px-4">
          <h3>Agents Location</h3>
        </div>
        <div className="col-md-6 py-3 px-4">
          <div className="criteria-search-box d-flex justify-content-end">
            <select
              id="paginate"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              className="custom-select w-50"
            >
              <option value="">Select Agent Name</option>
              {leadData?.users?.map((cr) => (
                <option value={cr?.value} key={cr.value}>
                  {cr?.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div id="container" style={{ width: 1000, height: 800 }}>
      </div>
    </>
  );
};


export default Location;

