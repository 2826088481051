/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
  FiPhoneCall,
} from "react-icons/fi";

import { FcCallback } from "react-icons/fc";
import { AiOutlineFileAdd } from "react-icons/ai";
import {
  FaUser,
  FaUserCheck,
  FaPhoneAlt,
  FaSadCry,
  FaMailBulk,
} from "react-icons/fa";
import { MdDateRange, MdCallEnd } from "react-icons/md";
import { ImHappy2 } from "react-icons/im";
import { BiDetail } from "react-icons/bi";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getLeadsDetail,
  getDashboardCount,
  SalesDashboardCount,
  DashboardCountById,
} from "../store/actions/DashboardActions";
import {
  Input,
  CustomInput,
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Label,
  Button,
} from "reactstrap";
import Pagination from "react-js-pagination";
import Axios from "axios";
import Accordian from "../../src/components/dashboard-accordian/Accordian";
const MarketingDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [associate, setAssociate] = useState([]);
  const [toggle, setToggle] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [id, setId] = useState("");
  const [associateFilter, setAssociateFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [associatecount, setAssociateCount] = useState("");
  const [tataPlayData, setTataPlayData] = useState("");
  const [associateteam, setAssociateTeam] = useState("");
  const [franchiseData, setFranchiseData] = useState([]);
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);

  useEffect(() => {
    dispatch(
      getDashboardCount(token, "get-associate-dashboard-count", associateFilter)
    );
  }, [token, associateFilter]);

  useEffect(() => {
    Axios.get(`/api/crm/get-associate-dashboard?id=${toggle}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociateCount(res.data))
      .catch((err) => console.log(err));
  }, [toggle]);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-investor-tata-play-count`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setTataPlayData(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-count?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociateTeam(res.data))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    Axios.get(`/api/crm/get-associate-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociate(res.data.franchise))
      .catch((err) => console.log(err));
  }, [auth.token]);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-investor-tata-play-count`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setTataPlayData(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    SetLead(leaddetails.convertedlead);
   
  }, [leaddetails, dashboardcount]);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-investor-dashboard-count`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setFranchiseData(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <h3>Associate Lead Stats</h3>
            <div className="row">
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darked">
                  <div className="card-body py-4">
                    <a href={`/associate-team?leaddate=${leadDate}`}>
                      <div className="row align-items-center ">
                        <div className="col-4">
                          <div className="square-box blue">
                            <FaUser color="white" size={18} />
                          </div>
                        </div>

                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.todayLead}
                          </h3>
                          <div className="mb-0 font-commom-2">Today Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-green">
                  <div className="card-body py-4">
                    <a href={`/associate-team?leadsource=Ads`}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box skyblue">
                            <FaUserCheck color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalLead}
                          </h3>
                          <div className="mb-0 font-commom-2">Total Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darkblue">
                  <div className="card-body py-4">
                    <a href={`/associate-team?dispositiontype=Connected`}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box orange">
                            <FaPhoneAlt color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-orange">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box pink">
                          <ImHappy2 color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcount?.totalAmount}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Sales</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcount?.todayAmount}
                        </h3>
                        <div className="mb-0 font-commom-2">Today Sales</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Not_Interested`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box green">
                            <FaSadCry color="#33d69f" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalNotInterested}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Follow_Up`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkred">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalFollowUp}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Follow up{" "}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Call_Back`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <HiPhoneIncoming color="#6C00FF" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalCallBack}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Call Back
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Mail_Shared`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMailShared}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Mail Shared
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Align`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingAlign}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Align
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Done`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingDone}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Done
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Done_NOI`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingDoneNotInterested}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Meeting Done Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?dispositiontype=Not_Connected&`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalNotConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Franchise Investor Stats</h3>
              <div className="row">
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill">
                    <a
                      href={`/franchise-list-investor?leaddate=${leadDate}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2">
                              {franchiseData?.todayInvestor ?? 0}
                            </h3>
                            <div className="mb-0">Today Franchise Investor</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiUser className="feather-lg text-primary" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill">
                    <a
                      href={`/franchise-list-investor`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2">
                              {franchiseData?.totalInvestor ?? 0}
                            </h3>
                            <div className="mb-0">Total Franchise Investor</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiUserCheck className="feather-lg text-warning" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill">
                    <a
                      href={`/franchise-list-investor?disposition_type=Connected`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2">
                              {franchiseData?.totalConnected ?? 0}
                            </h3>
                            <div className="mb-0">Total Connected</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiRepeat className="feather-lg text-danger" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill">
                    <a
                      href={`/franchise-list-investor?disposition_type=Not_Connected`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2">
                              {franchiseData?.totalNotConnected ?? 0}
                            </h3>
                            <div className="mb-0">Total Not Connected</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiRepeat className="feather-lg text-danger" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill">
                    <a
                      href={`/franchise-list-investor?disposition_type=Connected&Connected=Follow_Up
                    `}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2">
                              {franchiseData?.totalFollowUp ?? 0}
                            </h3>
                            <div className="mb-0">Total Follow up(Hot) </div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <div className="square-box darkred">
                                <BiDetail color="#FF0032" size={18} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <FaSadCry color="#33d69f" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkred">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalFollowUp ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Follow up(Hot){" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.todayConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.todayNotConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Not Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <a
                    href={`/franchise-list-investor?disposition_type=Connected&Connected=Call_Back
                    `}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <HiPhoneIncoming color="#6C00FF" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {franchiseData?.totalCallBack ?? 0}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Call Back
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <a
                    href={`/franchise-list-investor?disposition_type=Connected&Connected=Mail_shared
                    `}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <FaMailBulk color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {franchiseData?.totalMailShared ?? 0}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Mail Shared
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalManufacturing ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Manufacturing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalHot ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Hot Lead</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalWarm ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Warm Lead
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalCold ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Cold Lead
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Tata Play Lead Stats</h3>
              <div className="row">
                <div className="col-md-3">
                  <div className="card flex-fill common-card round-border box-darked">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?leaddate=${leadDate}`}>
                        <div className="row align-items-center ">
                          <div className="col-4">
                            <div className="square-box blue">
                              <FaUser color="white" size={18} />
                            </div>
                          </div>

                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.todayInvestor}
                            </h3>
                            <div className="mb-0 font-commom-2">Today Lead</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card flex-fill common-card round-border box-green">
                    <div className="card-body py-4">
                      <a href="/tata-play-list">
                        <div className="row align-items-center">
                          <div className="col-4">
                            <div className="square-box skyblue">
                              <FaUserCheck color="white" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalInvestor}
                            </h3>
                            <div className="mb-0 font-commom-2">Total Lead</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card flex-fill common-card round-border box-darkblue">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?dispositiontype=Connected`}>
                        <div className="row align-items-center">
                          <div className="col-4">
                            <div className="square-box orange">
                              <FaPhoneAlt color="white" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalConnected}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Connected
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card flex-fill common-card round-border box-orange">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?dispositiontype=Not_Connected`}>
                        <div className="row align-items-center">
                          <div className="col-4">
                            <div className="square-box pink">
                              <MdCallEnd color="white" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalNotConnected}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Not Connected
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card flex-fill common-card round-border">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Not_Interested`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box green">
                              <FaSadCry color="#33d69f" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalNotInterested}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Not Interested
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Follow_Up`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkred">
                              <BiDetail color="#FF0032" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalFollowUp}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Follow up{" "}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Call_Back`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkblue">
                              <HiPhoneIncoming color="#6C00FF" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalCallBack}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Call Back
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?mailshared=Yes`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkblue">
                              <BiDetail color="#FF0032" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalMailSharedYes}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Is Mail Shared
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Meeting_Align`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkblue">
                              <BiDetail color="#FF0032" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalMeetingAlign}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Meeting Align
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Meeting_Done`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkblue">
                              <BiDetail color="#FF0032" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalMeetingDone}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Meeting Done
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <a href={`/tata-play-list?disposition=Meeting_Done_NOI`}>
                        <div className="row">
                          <div className="col-4">
                            <div className="square-box darkblue">
                              <BiDetail color="#FF0032" size={18} />
                            </div>
                          </div>
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {tataPlayData?.totalMeetingDoneNOI}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Meeting Done Not Interested
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {auth?.id == "50" || auth?.id == "83" || auth?.id == "47" ? (
              <>
                <div>
                  <h5>Teams</h5>
                  <div className="row cursor-pointer">
                    {dashboardcount?.getTeamList?.map((consultant) => (
                      <div className="col-md-4 my-2">
                        <div>
                          {/* <Card>
                            <CardHeader onClick={() => setId(consultant?.id)}>
                              <span className="font-weight-bold cursor-pointer">
                                {consultant?.name}
                              </span>
                            </CardHeader>
                            <Collapse
                              isOpen={id === consultant?.id ? true : false}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between pt-4">
                                  {" "}
                                  <div>
                                    <h6>Today Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.todayLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Today Connected</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalConnected}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total CallBack</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalCallBack}</div>
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card> */}
                          <Accordian
                            consultant={consultant}
                            key={consultant?.id}
                            type="1"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h5>Associate Partners Stats</h5>
                  <div className="row cursor-pointer">
                    {associate?.map((consultant) => (
                      <div className="col-md-4 ">
                        <div>
                          {/* <Card>
                            <CardHeader
                              onClick={() => setToggle(consultant?.id)}
                            >
                              <span className="font-weight-bold cursor-pointer">
                                {consultant?.name}
                              </span>
                            </CardHeader>
                            <Collapse
                              isOpen={toggle === consultant?.id ? true : false}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between pt-4">
                                  {" "}
                                  <div>
                                    <h6>Today Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.todayAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Monthly Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.monthlyAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.totalAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.todayleadCount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Monthly Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.monthlyleadCount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.totalleadCount}</div>
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card> */}
                          <Accordian
                            consultant={consultant}
                            key={consultant?.id}
                            type="2"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}

            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.no</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Campaign</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lead?.map((le, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>{le.full_name}</td>
                                    <td>{le.email}</td>
                                    <td>{le.mobile}</td>
                                    <td>{le?.compains}</td>
                                    <td>{le?.state}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default MarketingDashboard;
