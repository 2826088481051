import _ from "lodash";

const initialState = {
  taskData: [],
  loading: false,
  taskCreateStatus: false,
  taskDeleteStatus: false,
  userTask: [],
  userTaskLoader: false,
  userTaskStatus: false,
  error: {},
  errors: {},
  errorss: {},
};

const taskReducer = (state = initialState, action) => {
  const { payload } = action;
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "CHANGE_TASK_STATE":
      state = {
        ...state,
        taskData: [],
        loading: false,
        taskCreateStatus: false,
        taskDeleteStatus: false,
        error: {},
      };
      break;
    case "ADD_LEAD_TASK_PENDING":
      state = {
        ...state,
        loading: true,
        taskCreateStatus: false,
        error: {},
      };
      break;
    case "ADD_LEAD_TASK_FULFILLED":
      state = {
        ...state,
        loading: false,
        taskCreateStatus: true,
        taskData: _.get(payload, "data", {}),
      };
      break;
    case "ADD_LEAD_TASK_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          taskCreateStatus: false,
          errors: _.get(data, "errors", {}),
          errorss: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          taskCreateStatus: false,
          errors: _.get(data, "message", {}),
        };
      }
      break;

    case "RESET_TASK_CREATE_STATUS":
      return {
        ...state,
        taskCreateStatus: action.payload,
      };
    case "TASK_UPDATE_PENDING":
      state = {
        ...state,
        userTaskLoader: true,
        userTaskStatus: false,
        error: {},
      };
      break;
    case "TASK_UPDATE_FULFILLED":
      state = {
        ...state,
        userTaskLoader: false,
        userTaskStatus: true,
        userTask: _.get(payload, "data", []),
      };
      break;
    case "TASK_UPDATE_REJECTED":
      if (status === 422) {
        state = {
          ...state,
          userTaskLoader: false,
          error: _.get(data, "errors", []),
          userTaskStatus: false,
        };
      } else {
        state = {
          ...state,
          userTaskLoader: false,
          error: _.get(data, "message", []),
          userTaskStatus: false,
        };
      }
      break;
    case "TASK_DELETE_PENDING":
      state = {
        ...state,
        loading: true,
        taskDeleteStatus: false,
        error: {},
      };
      break;
    case "TASK_DELETE_FULFILLED":
      state = {
        ...state,
        loading: false,
        taskDeleteStatus: true,
      };
      break;
    case "TASK_DELETE_REJECTED":
      state = {
        ...state,
        loading: false,
        error: _.get(payload.response.data, "data", {}),
        taskDeleteStatus: false,
      };
      break;
  }
  return state;
};

export default taskReducer;
