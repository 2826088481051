import React, { Fragment } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const Submodal = (props) => {
  return (
    <Fragment>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Source</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label className="m-0">{props.name}</label>
            <Input
              type="text"
              value={props.modalrating}
              onChange={props.changerating}
            />
          </div>
          <div className="form-group">
            <label className="m-0">Description</label>
            <Input
              type="text"
              value={props.modaldescription}
              onChange={props.changedescription}
            />
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <Input
              type="checkbox"
              id="isActive"
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="isActive">
              Is Active
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            Close
          </Button>
          <Button color="primary" onClick={props.toggle}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Submodal;
