import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import MainWrapper from "../MainWrapper";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  MemberShipData,
  sectors,
  projects,
  memberShipCreate,
  changeReducerState,
} from "../../store/actions/MemberShipActions";
import { workShopUser } from "../../store/actions/WorkShopActions";
import Axios from "axios";
import moment from "moment";
import _ from "lodash";
import Creatable, { components } from "react-select";

const AddMembership = memo(({ leadDetail, setWsStatus }) => {
  const [fields, setFields] = useState({
    name: leadDetail?.name ?? "",
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    plan: true,
  });
  const [project, setProject] = useState([]);
  const [sector, setSector] = useState([]);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({});
  const [file, setFile] = useState();
  const [otherSector, SetOtherSector] = useState("");
  const [otherIndustry, SetOtherIndustry] = useState("");
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [newMembership, setNewMembership] = useState([]);
  const [multiOptions, setMultiOptions] = useState([]);
  const [courses, setCourses] = useState([]);
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const {
    memberShips: { memberShip },
    errors,
    loading,
    sectors: { subcategory },
    projects: { project_reports },
    createStatus,
  } = useSelector((state) => state.memberShip);

  const {
    users: { user },
  } = useSelector((state) => state.workShop);
  useEffect(() => {
    dispatch(MemberShipData(token));
    dispatch(sectors(token));
  }, [token]);

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = memberShip.find(
        (v) => v.value == fields?.selectedMemberShip
      );
      return selected;
    }
  }, [fields?.selectedMemberShip]);

  useEffect(() => {
    if (selectedMemberShip?.label == "Startup") {
      Axios.get(`/api/crm/get-StartupCourse`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) =>
          setMultiOptions(
            res.data?.startupCourses
              ?.map?.((item) => item.course_detail)
              .flat(Infinity)
              .map((item) => {
                return { label: item?.title, value: item.id };
              })
          )
        )
        .catch((err) => console.log(err));
    }

    if (selectedMemberShip?.label == "Franchise") {
      Axios.get(`/api/crm/get-franchiseCourse`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) =>
          setMultiOptions(
            res.data?.franchiseCourses
              ?.map?.((item) => item.course_detail)
              .flat(Infinity)
              .map((item) => {
                return { label: item?.title, value: item.id };
              })
          )
        )
        .catch((err) => console.log(err));
    }
  }, [selectedMemberShip, fields]);

  const memberShipData = useMemo(() => memberShip, [memberShip]);

  const handleSwitchChange = () => {
    setFields({ ...fields, status: !fields.status });
  };
  const handleSectorChange = (val) => {
    if (!val || val.length <= parseInt(selectedMemberShip?.industries)) {
      setSector(val);
      if (!val) {
        setProject("");
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  const handleProjectChange = (value) => {
    if (!value || value.length <= parseInt(selectedMemberShip?.sub_category)) {
      setProject(value);
    }
  };

  const handleCourseChange = (value) => {
    console.log(value);
    if (!value || value.length <= parseInt(selectedMemberShip?.industries)) {
      setCourses(value);
    }
  };
  console.log(leadDetail);

  useMemo(() => {
    if (fields.mobile) {
      if (fields.mobile?.length == 10) {
        dispatch(workShopUser(token, fields.mobile));
      }
    }
  }, [fields.mobile, fields.workShop]);
  useEffect(() => {
    if (user && modal) {
      setFields({
        ...fields,
        name: user.name,
        email: user.email,
        id: user.id,
      });
    } else if (leadDetail) {
      setFields({
        ...fields,
        name: leadDetail?.name ?? "",
        mobile: leadDetail?.mobile ?? "",
        email: leadDetail?.email ?? "",
      });
    } else {
      setFields({
        ...fields,
        name: "",
        email: "",
        id: "",
      });
    }
  }, [user, leadDetail, modal]);

  const cs = { value: 1, label: "OTHER" };
  const css = [{ value: 1, label: "OTHER" }];
  // const subCategoryy = [...subCategory, Array]

  const sectorD = useMemo(() => subcategory, [subcategory]);

  const sectorData = Array.isArray(sectorD) ? [...sectorD, cs] : cs;

  const subSubCategorie = useMemo(() => project_reports, [project_reports]);

  useEffect(() => {
    let sectorV = _.map(sector, (s) => s.value);
    if (sectorV == 1) {
      setSubSubCategories([cs]);
    } else {
      setSubSubCategories(subSubCategorie);
    }
  }, [sector, project_reports]);
  useEffect(() => {
    if (!_.isNull(sector) && selectedMemberShip?.sub_category) {
      let sectorValue = _.map(sector, (s) => s.value);
      dispatch(projects(token, sectorValue));
    }
  }, [token, sector]);
  const Menu = (menuProps) => {
    const optionSelectedLength = menuProps.getValue().length || 0;
    return (
      <components.Menu {...menuProps}>
        {optionSelectedLength < parseInt(selectedMemberShip?.industries) ? (
          menuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {selectedMemberShip?.industries} Sector
          </div>
        )}
      </components.Menu>
    );
  };
  const projectMenu = (projectMenuProps) => {
    const optionSelectedLength1 = projectMenuProps.getValue().length || 0;
    return (
      <components.Menu {...projectMenuProps}>
        {optionSelectedLength1 < parseInt(selectedMemberShip.sub_category) ? (
          projectMenuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {parseInt(selectedMemberShip.sub_category)} Project
          </div>
        )}
      </components.Menu>
    );
  };

  const courseMenu = (courseMenuProps) => {
    const optionSelectedLength1 = courseMenuProps.getValue().length || 0;
    return (
      <components.Menu {...courseMenuProps}>
        {optionSelectedLength1 < parseInt(selectedMemberShip.industries) ? (
          courseMenuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {parseInt(selectedMemberShip.industries)} Course
          </div>
        )}
      </components.Menu>
    );
  };

  const handleDocumentChange = (e) => {
    setFields({
      ...fields,
      document: e.target.files[0],
    });
  };
  const validationForm = () => {
    let formIsValid = true;
    // if (!_.size(sector)) {
    //   formIsValid = false;
    //   error["sector"] = "Sector is required.";
    //   NotificationManager.error("Sector is required.", "Error");
    // }
    if (selectedMemberShip?.sub_category > 0) {
      if (!_.size(project)) {
        formIsValid = false;
        error["project"] = "Project is required.";
        NotificationManager.error("Project is required.", "Error");
      }
    }
    setError(error);
    return formIsValid;
  };

  // useEffect(() => {
  //   if (user.plan_detail_all) {
  //     setFields(...fields, {
  //       selectedMemberShip: user.plan_detail_all,
  //     });
  //   }
  // }, [user]);

  const tt = sector?.map((se) => {
    return {
      sector: se.value,
      project: _.filter(project, (p) => p.sector === se.value),
    };
  });

  console.log("tt");
  console.log(tt, "rajuu");
  console.log(leadDetail, "uisser");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const formData = new FormData();

      formData.append("payment_img", file);
      formData.append("user_id", leadDetail.id);
      formData.append("name", fields?.name);
      formData.append("email", fields?.email);
      formData.append("mobile", fields?.mobile);
      formData.append("payment_id", fields?.trxId);
      formData.append("mailing", fields?.maillingAddress);
      // formData.append("sectors", JSON.stringify(tt));
      {
        selectedMemberShip?.label == "Startup" ||
        selectedMemberShip?.label == "Franchise"
          ? formData.append("sectors", JSON.stringify(courses))
          : formData.append("sectors", JSON.stringify(tt));
      }

      formData.append("membership_type", selectedMemberShip?.label);
      formData.append("membership", fields?.selectedMemberShip);
      formData.append("total", selectedMemberShip?.total);
      formData.append("amount", selectedMemberShip?.total);
      formData.append("expiry", selectedMemberShip?.expiry);
      formData.append("sub_sub_category", selectedMemberShip?.sub_category);
      formData.append("bussinessName", fields?.bussinessName);
      formData.append("pincode", fields?.pincode);
      formData.append("gst", fields?.gst);
      formData.append("mode", fields?.mode);
      formData.append("paymentType", fields?.paymentType);
      formData.append("othersector", otherSector);
      formData.append("otherindustry", otherIndustry);

      for (let data of formData) {
        console.log(data);
      }
      dispatch(memberShipCreate(formData, token));
    }
  };

  useEffect(() => {
    const sk = sector?.map((se) => {
      return {
        sector: se.value,
        project: _.filter(project, (p) => p.sector === se.value),
      };
    });
    console.log(sk, "tajd");
  }, [sector, project]);

  console.log(sector, "raju");

  useEffect(() => {
    setFields({
      ...fields,
      name: leadDetail?.name ?? "",
      mobile: leadDetail?.mobile ?? "",
      email: leadDetail?.email ?? "",
    });
  }, [leadDetail, modal]);

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 &&
    selectValue.length < parseInt(selectedMemberShip?.industries);
  useEffect(() => {
    if (createStatus) {
      setWsStatus((prev) => !prev);
      dispatch(changeReducerState());
      NotificationManager.success("Created Successfully", "Success");
    }
  }, [createStatus]);
  if (!isAuthenticated) return <Redirect to="/login" />;
  console.log(leadDetail, "membership");

  const oo = _.map(sector, (s) => s.value);

  const ind = _.map(project, (s) => s.label);

  console.log(selectedMemberShip, "memberhsipcheck");

  return (
    <Fragment>
      {_.isNull(leadDetail?.plan_detail_all?.agent_id) ||
      _.isNull(leadDetail?.plan_detail_all) ? (
        <a
          color="secondary"
          href="#"
          size="sm"
          onClick={() => setModal(true)}
          className="flex-grow-1"
        >
          MemberShip
        </a>
      ) : (
        <a
          color="secondary"
          href="#"
          size="sm"
          onClick={() => setModal(false)}
          className="flex-grow-1"
        >
          MemberShip
        </a>
      )}
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add MemberShip
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">MemberShip</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.selectedMemberShip}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option>select</option>
                    {_.size(memberShipData)
                      ? _.map(memberShipData, (mem) => (
                          <option value={mem?.value} key={mem?.value}>
                            {mem?.label}/{mem?.total}
                          </option>
                        ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {!_.isUndefined(selectedMemberShip) ? (
                <Col sm="6">
                  <h5>Membership Plan</h5>
                  <p>
                    <strong>For Individual/Enterprises/Startups</strong>
                  </p>
                  <CustomInput
                    type="switch"
                    id="plan"
                    name="plan"
                    label={`${selectedMemberShip?.label} ${selectedMemberShip?.total}`}
                    checked={fields.plan}
                    onChange={handleSwitchChange}
                  />
                  <p>
                    The entire fee amount is inclusive of GST calculated @ 18%.
                  </p>
                </Col>
              ) : null}
              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile</Label>
                  <Input
                    invalid={!!errors.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    invalid={!!errors.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    invalid={!!errors.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="maillingAddress">Mailing Address</Label>
                  <Input
                    type="textarea"
                    invalid={!!errors.maillingAddress}
                    id="maillingAddress"
                    value={fields.maillingAddress ? fields.maillingAddress : ""}
                    onChange={handleChange}
                    className="custom-select"
                  ></Input>
                </FormGroup>
              </Col>
              {selectedMemberShip?.label !== "Franchise" &&
              selectedMemberShip?.label !== "Startup" ? (
                <Col sm={6}>
                  <FormGroup>
                    <Label for="sector">Proposed Area of Sector</Label>
                    <Creatable
                      components={{ Menu }}
                      isValidNewOption={isValidNewOption}
                      isMulti
                      options={sectorData}
                      value={sector}
                      onChange={handleSectorChange}
                    />
                  </FormGroup>
                  <FormFeedback className="d-block">
                    {errors.sector}
                  </FormFeedback>
                </Col>
              ) : null}
              {oo == 1 ? (
                <Col sm={6}>
                  <FormGroup>
                    <Label for="othersector">Other Sector</Label>
                    <Input
                      value={otherSector}
                      onChange={(e) => SetOtherSector(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {selectedMemberShip?.sub_category > 0 ? (
                <Col sm="6">
                  <FormGroup>
                    <Label for="sector">Proposed Area of Industry Report</Label>
                    <Creatable
                      components={{ Menu: projectMenu }}
                      isValidNewOption={isValidNewOption}
                      isMulti
                      options={subSubCategories}
                      value={project}
                      onChange={handleProjectChange}
                    />
                  </FormGroup>
                  <FormFeedback className="d-block">
                    {errors.project}
                  </FormFeedback>
                </Col>
              ) : null}

              {ind == "OTHER" ? (
                <Col sm={6}>
                  <FormGroup>
                    <Label for="othersector">Other Industry</Label>
                    <Input
                      value={otherIndustry}
                      onChange={(e) => SetOtherIndustry(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {selectedMemberShip?.label == "Startup" ||
              selectedMemberShip?.label == "Franchise" ? (
                <Col sm="6">
                  <FormGroup>
                    <Label for="sector">
                      Only 3 Courses is Allow on this Package
                    </Label>
                    <Creatable
                      components={{ courseMenu }}
                      isValidNewOption={isValidNewOption}
                      isMulti
                      options={multiOptions}
                      onChange={handleCourseChange}
                    />
                  </FormGroup>
                  <FormFeedback className="d-block">
                    {errors.project}
                  </FormFeedback>
                </Col>
              ) : null}

              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Total Paid</Label>
                  <Input
                    id="total"
                    value={selectedMemberShip?.total}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="bussinessName">Bussiness Name</Label>
                  <Input
                    invalid={!!errors.bussinessName}
                    id="bussinessName"
                    value={fields.bussinessName ? fields.bussinessName : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="gst">GST Number</Label>
                  <Input
                    invalid={!!errors.gst}
                    id="gst"
                    value={fields.gst ? fields.gst : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>

                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="trxId">Trx Id</Label>
                  <Input
                    invalid={!!errors.payment_id}
                    id="trxId"
                    value={fields.trxId ? fields.trxId : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
              <Col sm="10">
                <div className="text-center mb-3">
                  <Button
                    color="secondary"
                    onClick={() => setModal(false)}
                    className="mr-2"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={!_.isEmpty(user?.plan_detail)}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default AddMembership;
