import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import moment from "moment";
import { ImHappy2 } from "react-icons/im";
import { BiDetail } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { HiPhoneIncoming } from "react-icons/hi";
import Axios from "axios";
import CollapseCard from "../../components/dashboard-accordian/CollapseCard";
import { getDashboardCount } from "../../store/actions/DashboardActions";
import useScrollTrigger from "./useScrollTrigger";
const AssociateLeadStatus = () => {
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;
  const dispatch = useDispatch();
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [leadSource, setLeadsource] = useState("");
  const [purpose, SetPurpose] = useState("");
  const [associateFilter, setAssociateFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [reportings, setReportings] = useState({});
  const {
    leaddetails,
    dashboardcount,
    dashboardconsultant,
    dashboarduserconsultant,
  } = useSelector((state) => state.dashboardReducer);
  const fetchData = () => {
    dispatch(
      getDashboardCount(
        token,
        "get-associate-dashboard-count",
        associateFilter,
        leadSource,
        purpose
      )
    );
    Axios.get(`/api/crm/get-all-leads`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => setReportings(data))
      .catch((err) => console.error(err));
  };

  useEffect(() => fetchData(), [token, associateFilter]);

  return (
    <div>
      <h3>Associate Lead Stats</h3>

      <div className="row gy-3">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              Start Date
            </label>
            <Input
              type="date"
              value={associateFilter.startDate}
              onChange={({ target: { value } }) =>
                setAssociateFilter((prev) => ({
                  ...prev,
                  startDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              End Date
            </label>
            <Input
              type="date"
              value={associateFilter.endDate}
              onChange={({ target: { value } }) =>
                setAssociateFilter((prev) => ({
                  ...prev,
                  endDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darked">
            <div className="card-body py-4">
              <a href={`/associate-team?leaddate=${leadDate}`}>
                <div className="row align-items-center ">
                  <div className="col-4">
                    <div className="square-box blue">
                      <FaUser color="white" size={18} />
                    </div>
                  </div>

                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.todayLead}
                    </h3>
                    <div className="mb-0 font-commom-2">Today Lead</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-green">
            <div className="card-body py-4">
              <a href={`/associate-team?leadsource=Ads`}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="square-box skyblue">
                      <FaUserCheck color="white" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalLead}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Lead</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darkblue">
            <div className="card-body py-4">
              <a href={`/associate-team?dispositiontype=Connected`}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="square-box orange">
                      <FaPhoneAlt color="white" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalConnected}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Connected</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-orange">
            <div className="card-body py-4">
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="square-box pink">
                    <ImHappy2 color="white" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {dashboardcount?.totalAmount}
                  </h3>
                  <div className="mb-0 font-commom-2">Total Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <div className="row">
                <div className="col-4">
                  <div className="square-box darkblue">
                    <BiDetail color="#FF0032" size={18} />
                  </div>
                </div>
                <div className="col-8">
                  <h3 className="mb-0 font-commom">
                    {dashboardcount?.todayAmount}
                  </h3>
                  <div className="mb-0 font-commom-2">Today Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill common-card round-border">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Not_Interested`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box green">
                      <FaSadCry color="#33d69f" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalNotInterested}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Not Interested
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Follow_Up`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkred">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalFollowUp}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Follow up </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Call_Back`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <HiPhoneIncoming color="#6C00FF" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalCallBack}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Call Back</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Mail_Shared`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalMailShared}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Mail Shared</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Meeting_Align`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalMeetingAlign}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Meeting Align
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Meeting_Done`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalMeetingDone}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Meeting Done</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?disposition=Meeting_Done_NOI`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalMeetingDoneNotInterested}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Meeting Done Not Interested
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/associate-team?dispositiontype=Not_Connected&`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {dashboardcount?.totalNotConnected}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Not Connected
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        {reportings?.associateleadlist?.map((item) => {
          return (
            <div className="col-md-3" key={item.id}>
              <CollapseCard
                name={item.name}
                id={item.id}
                moduleType="associate"
                showFilters={false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AssociateLeadStatus;
