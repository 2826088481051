import React, { useEffect } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import MainWrapper from '../components/MainWrapper'
import { useState } from 'react'
import { FiUser } from 'react-icons/fi'
import Note from "../components/franchise-team/subComponent/Note"
import Task from '../components/lead/Task'
import TaskList from '../components/lead/sub-component/TaskList'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
    
   
    FiArrowLeft,
  } from "react-icons/fi";
const FranchiseDetail = () => {
    const [details, setDetails] = useState({})
    const { id } = useParams()
    const { auth } = useSelector((state) => state.auth);
    const [refresh, setRefresh] = useState(0);
    const [taskList, setTaskList] = useState([])
    const [page, setPage] = useState(1);
    useEffect(() => {
        if (id) {
            axios(`/api/crm/get-franchise-investor-detail/?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            })
                .then(({ data }) => setDetails(data.franchiseInvestorDetail || {}))
                .catch((err) => console.error(err))
        }
    }, [id])

    useEffect(() => {
        axios(`/api/crm/fetch-lead-task/${id}?type=franchise_investor`, {
            headers: { Authorization: `Bearer ${auth.token}` },
        })
            .then((res) => {
                setTaskList(res.data?.task);
            })
            .catch((err) => console.log(err));
    }, [refresh]);
// useEffect(()=>
// {
//     const path = new URL(window.location.href);
//     setPage(path.searchParams.get("page"));
// },[])

const location = useLocation();
const pageValue = new URLSearchParams(location.search).get('page');
const disposition = new URLSearchParams(location.search).get('disposition');
const dispositiontype = new URLSearchParams(location.search).get('disposition_type');
const leadsource = new URLSearchParams(location.search).get('leadsource');
const createDate = new URLSearchParams(location.search).get('createDate');
const endCreateDate = new URLSearchParams(location.search).get('endCreateDate');
const assignDate = new URLSearchParams(location.search).get('assignDate');
const assignEndDate = new URLSearchParams(location.search).get('assignEndDate');
const assign = new URLSearchParams(location.search).get('assign');


//&campaign=Franchis

useEffect(() => {
    if (pageValue) {
        setPage(pageValue)
    }
  }, [])
    return (
        <MainWrapper>

            <main className='content'  >
                <div className="row">
                    <div className="col-md-4">
                    <Link to={page ? `/franchise-list-investor?page=${page}&disposition=${disposition}&disposition_type=${dispositiontype}&source=${leadsource}&start=${createDate}&end=${endCreateDate}&start_assign=${assignDate}&end_assign=${assignEndDate}&assign_to=${assign}&campaign=Franchise` : `/franchise-list-investor`}>
            <FiArrowLeft size={30} />
          </Link>
                        <div className="overflow-hidden rounded bg-white border">
                            <div className="px-3 py-2 border-bottom bg-secondary">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span
                                            className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mr-2"
                                            style={{
                                                fontSize: 25,
                                                height: 50,
                                                minWidth: 50,
                                                width: 50,
                                            }}
                                        >
                                            <FiUser />
                                        </span>
                                        <div>

                                            <h5> {details?.customer_name} </h5>
                                            <span className='d-block' > {details?.email} </span>
                                        </div>

                                    </div>



                                </div>

                            </div>

                            <div className='py-4 px-3' >
                                <div className="row">
                                    <div className="col-md-6 pr-0 text-right">
                                        <div className='mb-2' >
                                            <span>Mobile : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Contact Person : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>City : </span>
                                        </div>


                                        <div className='mb-2' >
                                            <span>State : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Pincode : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Occupation : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Lead Source : </span>
                                        </div>

                                        <div className='mb-2' >
                                            <span>Investment : </span>
                                        </div>

                                        <div className='mb-2' >
                                            <span>Brand : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Disposition : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Disposition Type : </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span>Campaign : </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-1">
                                        <div className='mb-2' >
                                            <span> {details?.contact_number} </span>
                                        </div>

                                        <div className='mb-2' >
                                            <span> {details?.contact_person || "N/a"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.city} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.state || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.pincode || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.occupation || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.lead_source || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.investment || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.brand || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.disposition || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.disposition_type || "N/A"} </span>
                                        </div>
                                        <div className='mb-2' >
                                            <span> {details?.campaign || "N/A"} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="overflow-hidden rounded bg-white border" >
                            <div className='px-3 py-2 border-bottom bg-secondary' >
                            
                            </div>
                            <div className='p-3' >
                                <Note id={id} details={details}/>
                                <div>
                                    <TaskList taskList={taskList} type="franchise_investor" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='overflow-hidden rounded bg-white border' >
                            <div className="bg-secondary py-2 px-3">
                                <span>
                                    Actions
                                </span>
                            </div>
                            <div className="bg-white px-3 py-2 ">
                                <Task leadDetail={{ id, create: { id: details?.assign_to } }} setRefresh={setRefresh} type="franchise_investor" setWsStatus={(val) => setRefresh(prev => prev + 1)} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </MainWrapper>
    )
}

export default FranchiseDetail