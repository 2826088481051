import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Fragment } from "react";
import Loader from "../../layouts/Loader";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Link } from "react-router-dom";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
const MembershipReport = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [paginate, setPaginate] = useState(20);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentAction, SetPaymentAction] = useState("");
  const [memberShipType, setMemberShipType] = useState("");
  const [upgrade, setUpgrade] = useState("");
  const [sectorList, setSectorList] = useState([]);
  const [sector, setSector] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [industry, setIndustry] = useState("");
  const [id, setId] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [advanceSearch, setAdvanceSearch] = useState(false);

  const url = new URL(window.location).searchParams;
  const start = url.get("start");
  const end = url.get("end");
  console.log(end);
  console.log(start);

  useMemo(() => {
    const url = new URL(window.location).searchParams;
    const start = url.get("start");
    const end = url.get("end");

    if (start) {
      console.log(start, "checkk");
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  useEffect(() => {
    axios
      .get(`/api/crm/sectordata1`)
      .then((res) => {
        // console.log(res.data)
        setSectorList(res.data.subcategory);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/crm/subsectordata?industry=${sector}`, {
        headers: {
          Authorization: ` Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setIndustryList(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  }, [auth, sector]);

  const permissions = React.useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/membership-registration-list?sort=${sort}&search=${search}&page=${page}&paginate=${paginate}&start=${startDate}&end=${endDate}&memberType=${memberShipType}&upgrade=${upgrade}&sector=${sector}&industry=${industry}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        // setContent(res.data.consultantList?.data);
        // seCreatedBy(res.data.consultantList?.child);
        setContent(res.data.paidMembers?.data || []);
        setFrom(res.data.paidMembers?.from);
        setTo(res.data.paidMembers?.to);
        setTotal(res.data.paidMembers?.total);
        setPerPage(res.data.paidMembers?.per_page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.response?.data?.message);
      });
  }, [
    sort,
    page,
    refresh,
    paginate,
    search,
    auth,
    memberShipType,
    upgrade,
    sector,
    industry,
  ]);
  //

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const remainingDays = (endDate) => {
  
    const dt1 = new Date();
    const dt2 = new Date(endDate);
    console.log(dt2.getFullYear(),'endDate')
if(dt2.getFullYear() != 2021 && dt2.getFullYear() != 2022){
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
} else {
  return Math.floor(
    (Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) -
      Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};
} 

  useEffect(() => {
    if (paymentAction) {
      axios
        .post(
          "/api/crm/update-membership-payment",
          { paymentAction, id },
          {
            headers: {
              Authorization: `Bearer ${auth?.auth?.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setRefresh(true);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, [paymentAction, id]);

  const csvHead = [
    [
      "Name",
      "Email",
      "Mobile",
      "Membership Type",
      "Sector",
      "Industry",
      "Gst Status",
      "Bankable Status",
      "Udyam Status",
      "Enrollment Date",
      "Expiry Date",
      "Payment Status",
    ],
  ];

  const csvData = content?.map((cv) => [
    cv?.user_list?.name,
    cv?.user_list?.email,
    cv?.user_list?.mobile,
    cv?.plan_name.name,
    cv?.sector?.name,
    cv?.projects?.name,
    cv?.user_list.gst?.status,
    cv?.user_list.bankable?.status,
    cv?.user_list.udyam?.status,
    moment(cv.joining).format("DD/MM/YYYY"),
    cv?.expiry,
    cv?.payment_status,
  ]);
  const exportData = Array.isArray(csvData) ? csvHead.concat(csvData) : [];

  return (
    <MainWrapper>
      <main className="content pt-4">
        <div className="page-header mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="c-card-top">
                <div className="c-card-top-title ml-2">
                  <h4 className="mb-0">MemberShip Report</h4>
                </div>
                <div className="c-card-top-inputs c-card-top-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                    <span className="custom-select">Search</span>

                    <Input
                      placeholder="Search here..."
                      onChange={handleSearch}
                      className="c-form-control-serch"
                    />
                    <div class="input-group-append">
                      <button
                        title="Advance Search"
                        onClick={() => {
                          advanceSearch
                            ? setAdvanceSearch(false)
                            : setAdvanceSearch(true);
                        }}
                        id="c_searchBlock_toggle"
                        class="btn btn-secondary btn-sm c-card-top-block-icon"
                        type="button"
                      >
                        <FiChevronDown />
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        id="button-addon2"
                      >
                        GO
                      </button>
                    </div>
                  </div>

                  <div
                    className={advanceSearch ? "c-card-top-block" : "d-none"}
                    id="c_searchBlock"
                  >
                    <div className="c-card-top-block-sec">
                      <div className="c-card-top-block-item">
                        <label>Select Type</label>
                        <select
                          id="memberShipType"
                          onChange={(e) => setMemberShipType(e.target.value)}
                          value={memberShipType}
                          className="custom-select"
                        >
                          <option value="">Select Type</option>
                          <option value="Incubatee">Incubatee</option>
                          <option value="Executive Member">
                            Executive Member
                          </option>
                          <option value="Associate Member">
                            Associate Member
                          </option>
                          <option value="Institution/Expert/Supplier Associate Membership">
                            Institution/Expert/Supplier Associate Membership
                          </option>
                          <option value="Corporate Member">
                            Corporate Member
                          </option>
                          <option value="Institution/Expert/Suppliers Corporate Membership">
                            Institution/Expert/Suppliers Corporate Membership
                          </option>
                        </select>
                      </div>
                      <div className="c-card-top-block-item">
                        <label>Select Upgrade Option</label>
                        <select
                          id="upgrade"
                          onChange={(e) => setUpgrade(e.target.value)}
                          value={upgrade}
                          className="custom-select"
                        >
                          <option value="">Select Option</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="c-card-top-block-item">
                        <label>Start Date</label>
                        <Input
                          type="date"
                          placeholder="End Date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="c-card-top-block-item">
                        <label>End Date</label>
                        <Input
                          type="date"
                          placeholder="End Date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      <div className="c-card-top-block-action">
                        <button
                          onClick={() => setAdvanceSearch(false)}
                          className="btn btn-sm btn-secondary mr-2"
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => setAdvanceSearch(false)}
                          className="btn btn-sm btn-secondary"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header d-flex justify-content-between align-items-center">
                    <div>
                      <button className="btn btn-secondary btn-sm mr-1">
                        <i className="fa fa-trash pr-1" /> Delete
                      </button>

                      <button
                        className="btn btn-secondary btn-sm btn-circle"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                    <div>
                      <select
                        id="paginate"
                        onChange={(e) => setSector(e.target.value)}
                        value={sector}
                        className="custome-select mr-1"
                        style={{
                          width: 145,
                        }}
                      >
                        <option value="">Select Sector</option>
                        {sectorList.map((e) => (
                          <option value={e.value} key={Math.random()}>
                            {e.label}
                          </option>
                        ))}
                      </select>
                      <select
                        id="paginate"
                        onChange={(e) => setIndustry(e.target.value)}
                        value={industry}
                        className="custome-select mr-1"
                        style={{
                          width: 155,
                        }}
                      >
                        <option value="">Select Industry</option>
                        {industryList?.map((e) => (
                          <option value={e.value} key={Math.random()}>
                            {e.label}
                          </option>
                        ))}
                      </select>
                      <select
                        id="sort"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custome-select mr-1"
                        style={{ width: 135 }}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <select
                        id="paginate"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                        className="custome-select custom-select-page mr-1"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <CSVLink
                        filename={"MasterData.csv"}
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To CSV"
                        data={exportData}
                      >
                        <i className="fa fa-file-excel-o" />
                      </CSVLink>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    checked={
                                      deleteIds.length === content.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold">Mobile</th>
                                <th className="font-weight-bold">
                                  MemberShip Type
                                </th>
                                <th className="font-weight-bold">Sector</th>
                                <th className="font-weight-bold">Industry</th>
                                <th className="font-weight-bold">GST Status</th>

                                <th className="font-weight-bold">
                                  Bankable Status
                                </th>
                                <th className="font-weight-bold">
                                  Udyam Status
                                </th>
                                <th className="font-weight-bold">
                                  Enrollment Date
                                </th>

                                <th className="font-weight-bold">
                                  Expiry Date
                                </th>
                                <th className="font-weight-bold">
                                  Membership Status
                                </th>
                                <th className="font-weight-bold">
                                  Payment Status
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7}>
                                        <Loader loading={loading} />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {console.log(content, 'content')}
                                    {content.length ? (
                                      <Fragment>
                                        {_.map(content, (is, i) => (
                                          <tr key={is?.id}>
                                            <td>{i + 1}</td>

                                            <Link
                                              // to={`/user-profile/${is?.user_id}`}
                                              to={`/lead/${is?.user_id}/details?page=${page}`}
                                            >
                                              <td className="text-primary">
                                                {" "}
                                                {is?.user_list?.name ?? ""}
                                              </td>
                                            </Link>
                                            <td>
                                              {is?.user_list?.email ?? ""}
                                            </td>
                                            <td>
                                              {is?.user_list.mobile ?? ""}
                                            </td>
                                            <td>
                                              {is?.plan_name
                                                ? is?.plan_name.name
                                                  ? is?.plan_name.name
                                                  : "N/A"
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {is?.sector
                                                ? is?.sector.name
                                                  ? is?.sector.name
                                                  : ""
                                                : ""}
                                            </td>
                                            <td>
                                              {is?.projects
                                                ? is?.projects.name
                                                  ? is?.projects.name
                                                  : ""
                                                : ""}
                                            </td>
                                            <td>
                                              {is.user_list
                                                ? is.user_list.gst
                                                  ? is.user_list.gst.status
                                                    ? is.user_list.gst.status
                                                    : ""
                                                  : ""
                                                : ""}
                                            </td>

                                            <td>
                                              {is.user_list
                                                ? is.user_list.bankable
                                                  ? is.user_list.bankable.status
                                                    ? is.user_list.bankable
                                                        .status
                                                    : ""
                                                  : ""
                                                : ""}
                                            </td>
                                            <td>
                                              {is.user_list
                                                ? is.user_list.udyam
                                                  ? is.user_list.udyam.status
                                                    ? is.user_list.udyam.status
                                                    : ""
                                                  : ""
                                                : ""}
                                            </td>
                                            <td>
                                              {moment(is.joining).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {is.expiry ? is.expiry : "N/A"}
                                              <br />
                                              {remainingDays(
                                                  is.expiry ? is.expiry : "N/A"
                                                ) < 0 ? (
                                              <label className="badge badge-danger mb-0 mt-1">
                                                Expired
                                              </label> ) : (
                                              <label className="badge badge-success mb-0 mt-1">
                                                In{" "}
                                                {remainingDays(
                                                  is.expiry ? is.expiry : "N/A"
                                                )}{" "}
                                                Days
                                              </label>)}
                                            </td>
                                            <td>
                                              {is.upgrade === "yes"
                                                ? "Upgraded"
                                                : moment(
                                                    is.expiry ? is.expiry : ""
                                                  ).format("DD/MM/YYYY") ===
                                                  moment(new Date() + 1).format(
                                                    "DD/MM/YYYY"
                                                  )
                                                ? "Expired"
                                                : "Active"}
                                            </td>
                                            <td>
                                              <span
                                                className={`badge ${
                                                  is?.payment_status ===
                                                    "success" ||
                                                  is?.payment_status ===
                                                    "SUCCESS"
                                                    ? "badge-success"
                                                    : is?.payment_status ===
                                                        "pending" ||
                                                      is?.payment_status ===
                                                        "PENDING"
                                                    ? "badge-warning"
                                                    : "badge-danger"
                                                }`}
                                              >
                                                {is?.payment_status ===
                                                  "success" ||
                                                is?.payment_status === "SUCCESS"
                                                  ? "success"
                                                  : is?.payment_status ===
                                                      "pending" ||
                                                    is?.payment_status ===
                                                      "PENDING"
                                                  ? "pending"
                                                  : "failed"}
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={12}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        {/* <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custome-select custom-select-page mr-1"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select> */}
                        {/* <select className="custom-select custom-select-page">
                                                    <option selected>10</option>
                                                    <option value="1">
                                                        20
                                                    </option>
                                                    <option value="2">
                                                        30
                                                    </option>
                                                    <option value="3">
                                                        40
                                                    </option>
                                                </select> */}
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={perPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MainWrapper>
  );
};

export default MembershipReport;
