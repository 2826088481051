import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import {
//   workShopUser,
//   workShopCreate,
//   workShopList,
//   workShopData,
//   changeReducerState,
// } from "../../store/actions/WorkShopActions";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { Redirect } from "react-router";
import { bookServices } from "../../store/actions/ServicesActions";
const AddServices = memo(({ leadDetail, setWsStatus }) => {
  const [services, setServices] = useState([]);
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mode, setMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentid, setPaymentId] = useState("");
  const [amount, setAmount] = useState("");
  const [id, setId] = useState("");
  const [servicesList, setServicesList] = useState("");
  const [leadId, setLeadId] = useState("");
  const [multipleSelection, setMultipleSelection] = useState(false);
  const [fssaiMultiple, setFssaiMultiple] = useState("");
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;
  const { servicespurchased } = useSelector((state) => state.serviceReducer);
  const [file, setFile] = useState();
  useEffect(() => {
    setName(leadDetail?.name);
    setMobile(leadDetail?.mobile);
    setEmail(leadDetail?.email);
    setId(leadDetail?.id);
    setLeadId(leadDetail?.id);
  }, [leadDetail]);

  useEffect(() => {
    axios("/api/crm/service-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      setServices(
        response.data?.subCategories.filter((item) => item.value !== "0") || []
      )
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    console.log(services);
    data.append(
      "title",
      services.find((item) => item?.value == servicesList)?.label
    );
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("mode", mode);
    data.append("paymentType", paymentType);
    data.append("payment_id", paymentid);
    data.append("amount", amount);
    data.append("id", id);
    data.append("leadId", leadId);
    data.append("service_id", servicesList);
    data.append("payment_img", file);
    dispatch(bookServices(data, token));

    // Axios.post("/api/crm/add-services-crm", data, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }).then((res) => console.log(res));
  };

  const handleValue = (value) => {
    if (value) {
      switch (value) {
        case "30":
          setAmount(1500 + (1500 / 100) * 18);
          break;

        case "31":
        case "35":
        case "36":
          setAmount(500 + (500 / 100) * 18);
          break;

        case "29":
          setAmount(5000 + (5000 / 100) * 18);
          break;

        case "33":
          setAmount(1500 + (1500 / 100) * 18);
          break;

        case "32":
          setAmount(5000 + (5000 / 100) * 18);
          break;

        case "34":
          setAmount(2000 + (2000 / 100) * 18);
          break;
        case "38":
          setAmount(3000 + (3000 / 100) * 18);
          break;
        case "39":
          setAmount(5000 + (5000 / 100) * 18);
          break;
      }
    }
  };

  useEffect(() => {
    if (servicespurchased) {
      setModal(false);
      NotificationManager.success("Service purchase completed successfully");
      dispatch({ type: "RESET_PURCHASED", payload: false });
    }
  }, [servicespurchased]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Services
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add User Services
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Services</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={servicesList}
                    onChange={({ target }) => {
                      setServicesList(target.value);
                      handleValue(target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {services.map((item, i) => {
                      return (
                        <option value={item.value} key={i}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              {multipleSelection && servicesList && (
                <Col sm="6">
                  <FormGroup>
                    <Label>Select Fsaai Registration</Label>
                    <Input
                      type="select"
                      name="multiple"
                      value={fssaiMultiple}
                      onChange={({ target }) => {
                        setFssaiMultiple(target.value);
                        handleValue(target.value);
                      }}
                      className="custom-select"
                    >
                      <option value="fssai-basic">
                        Fssai Basic Registration
                      </option>
                      <option value="fssai-state">FSSAI State License</option>
                      <option value="fssai-central">
                        FSSAI Central License
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              )}

              {servicesList && (
                <Col sm="6">
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input value={amount} name="amount" disabled />
                  </FormGroup>
                </Col>
              )}

              <Col sm="6">
                <FormGroup>
                  <Label>Name</Label>

                  <Input
                    value={name}
                    name="name"
                    onChange={({ target: { value } }) => setName(value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Mobile</Label>
                  <Input
                    name="mobile"
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    name="mode"
                    value={mode}
                    onChange={({ target: { value } }) => setMode(value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={paymentType}
                    name="paymentType"
                    onChange={({ target: { value } }) => setPaymentType(value)}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id</Label>
                  <Input
                    name="payment_id"
                    value={paymentid}
                    onChange={({ target: { value } }) => setPaymentId(value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddServices;
