import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  workShopUser,
  workShopCreate,
  workShopList,
  workShopData,
  changeReducerState,
} from "../../store/actions/WorkShopActions";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { useHistory } from "react-router";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
import { addEdp } from "../../store/actions/EdpAction";
const AddEdpLead = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [file, setFile] = useState();
  const [email, setEmail] = useState("");
  const [mode, setMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentid, setPaymentId] = useState("");
  const [amount, setAmount] = useState("5900");
  const [id, setId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [selectedEdp, setSelectedEdp] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector((state) => state.auth);
  const { edpcreated, errors, edpStatus } = useSelector(
    (state) => state.edpReducer
  );
  console.log(errors, 'errors')
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Add EdpLead",
      description: "Add EdpLead Sale",
    };
    if (edpStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [edpStatus]);
  const { token } = auth;

  useEffect(() => {
    setName(leadDetail?.full_name);
    setMobile(leadDetail?.mobile);
    setEmail(leadDetail?.email);
    setId(leadDetail?.id);
    setLeadId(leadDetail?.id);
  }, [leadDetail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("payment_img", file);
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("mode", mode);
    data.append("paymentType", paymentType);
    data.append("payment_id", paymentid);
    data.append("amount", amount);
    data.append("id", "");
    data.append("leadId", leadId);
    data.append("engaged", 1);
    dispatch(addEdp(data, token));
  };

  // useEffect(() => {
  //   if (edpcreated) {
  //     setModal(false);
  //     NotificationManager.success("Booked Successfully", "Success");
  //     setPaymentId("");
  //     dispatch({ type: "ADD_EDP_RESET" });
  //     history.push("/users");
  //   }
  // }, [edpcreated]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Edp
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Edp User
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Edp</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={selectedEdp}
                    onChange={({ target }) => setSelectedEdp(target.value)}
                  >
                    <option value="">Select</option>
                    <option value="edp">Edp</option>
                  </Input>
                </FormGroup>
              </Col>

              {selectedEdp === "edp" && (
                <Col sm="6">
                  <FormGroup>
                    <Label>Amount <span className="text-danger">*</span></Label>
                    <Input value={5900} invalid={!!errors?.amount} name="amount" disabled />
                    <FormFeedback>
                      {!!errors?.amount
                        ? errors?.amount[0]
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              )}

              <Col sm="6">
                <FormGroup>
                  <Label>Name <span className="text-danger">*</span></Label>
                  <Input
                    value={name}
                    name="name"
                    invalid={!!errors?.name}
                    onChange={({ target: { value } }) => setName(value)}
                  />
                  <FormFeedback>
                    {!!errors?.name
                      ? errors?.name[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Mobile <span className="text-danger">*</span></Label>
                  <Input
                    name="mobile"
                    invalid={!!errors?.mobile}
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                  <FormFeedback>
                    {!!errors?.mobile
                      ? errors?.mobile[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email <span className="text-danger">*</span></Label>
                  <Input
                    name="email"
                    invalid={!!errors?.email}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <FormFeedback>
                    {!!errors?.email
                      ? errors?.email[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode <span className="text-danger">*</span></Label>
                  <Input
                    type="select"
                    invalid={!!errors?.mode}
                    className="custom-select"
                    name="mode"
                    value={mode}
                    onChange={({ target: { value } }) => setMode(value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors?.mode
                      ? errors?.mode[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={paymentType}
                    name="paymentType"
                    onChange={({ target: { value } }) => setPaymentType(value)}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id <span className="text-danger">*</span></Label>
                  <Input
                    name="payment_id"
                    invalid={!!errors?.payment_id}
                    value={paymentid}
                    onChange={({ target: { value } }) => setPaymentId(value)}
                  />
                  <FormFeedback>
                    {!!errors?.payment_id
                      ? errors?.payment_id[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>

              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddEdpLead;
