import Axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { NotificationManager } from "react-notifications";

const CustomizeModal = ({ field, setRefresh }) => {
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(parseInt(field.status) ? true : false);
  const [actualPrice, setActualPrice] = useState(field.actual_price ?? "");
  const [thumbnail, setThumbnail] = useState(field.thumbnail ?? "");
  const [enquiryStatus, setEnquiryStatus] = useState(
    field.enquiry_status ?? ""
  );

  //   setInterval(() => {
  //     $("#thumbnail")
  //       .dropify()
  //       .on("dropify.afterClear", function () {
  //         setThumbnail("");
  //       });
  //   }, 1000);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Submitted amount must be final payable amount by the Customer inclusives of discount & taxes. If confirm ? Press OK"
      )
    ) {
      const data = new FormData();
      data.append("id", field.id);
      data.append("status", status ? "1" : "0");
      data.append("actual_price", actualPrice);
      data.append("enquiry_status", enquiryStatus);

      status &&
        (field.payment_status == "SUCCESS" ||
          field.payment_status == "success") &&
        data.append("thumbnail", thumbnail);

      Axios.post("/api/crm/edit-customize", data)
        .then((res) => {
          setModal(false);
          NotificationManager.success(res.data.message);
          setRefresh((refresh) => refresh + 1);
        })
        .catch((err) => {
          NotificationManager.error(err.response.data.message);
        });
    }
  };

  return (
    <Fragment>
      <Button
        type="button"
        size="sm"
        color="link"
        onClick={() => setModal(!modal)}
      >
        Process
      </Button>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>
          Edit Customize Report of {field.name} on{" "}
          {field.project ? field.project.title : null}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Status</Label>
                  <CustomInput
                    type="switch"
                    name="status"
                    checked={status}
                    id="status"
                    onChange={() => setStatus(!status)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Payable Amount after discount</Label>
                  <Input
                    type="text"
                    value={actualPrice}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setActualPrice(e.target.value);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Enquiry closed with status</Label>
                  <Input
                    type="select"
                    value={enquiryStatus}
                    onChange={(e) => setEnquiryStatus(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Customized DPR Sent</option>
                    <option value="2">Prototype DPR Sent</option>
                    <option value="3">Pitched or Membership</option>
                    <option value="4">Not Interested Now</option>
                    <option value="5">Other Services</option>
                  </Input>
                </FormGroup>
              </Col>
              {(field.payment_status == "success" ||
                field.payment_status == "SUCCESS") && (
                <Col md={12}>
                  <FormGroup>
                    <Label>Document</Label>
                    <Input
                      type="file"
                      id="thumbnail"
                      className="dropify"
                      data-default-file={
                        typeof thumbnail === "string"
                          ? `/storage/${thumbnail}`
                          : ""
                      }
                      onChange={(e) => setThumbnail(e.target.files[0])}
                    />
                  </FormGroup>
                </Col>
              )}

              <Col md={12}>
                <Button type="submit" block>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomizeModal;
