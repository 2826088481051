import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import promise from "redux-promise-middleware";
import activityReducer from "./store/reducers/ActivityReducer";
import authReducer from "./store/reducers/AuthReducer";
import userReducer from "./store/reducers/UserReducer";
import leadReducer from "./store/reducers/LeadReducer";
import assignReducer from "./store/reducers/AsignPermissionReducer";
import priorityReducer from "./store/reducers/PriorityReducer";
import commonReducer from "./store/reducers/CommonReducer";
import taskReducer from "./store/reducers/TaskReducer";
import noteReducer from "./store/reducers/NoteReducer";
import bookWorkShopReducer from "./store/reducers/BookWorkShopReducer";
import memberShipReducer from "./store/reducers/MemberShipReducer";
import projectReportReducer from "./store/reducers/ProjectReportReducer";
import allActivityReducer from "./store/reducers/AllActivityReducer";
import leadPageReducer from "./store/reducers/LeadReducer";
import crmUserReducer from "./store/reducers/CrmUserReducer";
import edpReducer from "./store/reducers/EdpReducer";
import courseReducer from "./store/reducers/CourseReducer";
import dashboardReducer from "./store/reducers/DashboardReducer";
import serviceReducer from "./store/reducers/ServicesReducer";
import callReducer from "./store/reducers/LoginCallReducer";
import callingReducer from "./store/reducers/CallingReducer";
import campaignReducer from "./store/reducers/CampaignReducer";
import FranchiseReducer from "./store/reducers/FranchiseReducer";
import meetingReducer from "./store/reducers/MeetingReducer";
import instituteReducer from "./store/reducers/InstituteTeamReducer";
const store = createStore(
  combineReducers({
    auth: authReducer,
    user: userReducer,
    lead: leadReducer,
    assign: assignReducer,
    priority: priorityReducer,
    common: commonReducer,
    task: taskReducer,
    note: noteReducer,
    workShop: bookWorkShopReducer,
    memberShip: memberShipReducer,
    projectReport: projectReportReducer,
    leadPage: leadPageReducer,
    crmUser: crmUserReducer,
    activity: activityReducer,
    edpReducer,
    courseReducer,
    dashboardReducer,
    serviceReducer,
    callReducer,
    callingReducer,
    campaignReducer,
    FranchiseReducer,
    meetingReducer,
    instituteReducer,
    allActivityReducer
  }),
  {},
  applyMiddleware(thunk, promise, createLogger())
);

export default store;
