import React, { Fragment, useState, useEffect, memo } from "react";
import { getCrmUsersApi } from "../../../store/actions/LeadActions";
import { Link, Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
} from "reactstrap";
import {
  
  getListActivityData,
  getListReminderData,
} from "../../../store/actions/CommonActions";
import {
  taskUpdate,
  changeReducerState,
} from "../../../store/actions/TaskActions";

import { useSelector, useDispatch } from "react-redux";
import { getPriority } from "../../../store/actions/AllActivityActions";
const EditTask = memo(
  ({ setWsStatus, leadsl, setModal, modal, handleModal, type }) => {
    const dispatch = useDispatch();
    const { auth } = useSelector(({ auth }) => auth);
    const {priorityy}=useSelector((state)=>state.allActivityReducer)
    const { error, userTaskLoader, userTaskStatus } = useSelector(
      ({ task }) => task
    );
    const { userStatus, leadData } = useSelector((state) => state.lead);
    const {
      getPriorityListStatus,
      getPriorityList,
      listActivityStatus,
      listActivity,
      listReminderStatus,
      listReminder,
    } = useSelector(({ common }) => common);

    const [reminderList, setReminderList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [activity, setActivity] = useState("");
    const [priorityList, setPriorityList] = useState([]);
    const [assignUser, setAssignUser] = useState([]);
    const [assign, setAssign] = useState("");
    const [subject, setSubject] = useState("");
    const [startdate, setStartdate] = useState("");
    const [starttime, setStarttime] = useState("");
    const [priority, setPriority] = useState("");
    const [reminder, setReminder] = useState("");
    const [emailcheck, setEmailcheck] = useState("");
    const [smscheck, setSmscheck] = useState("");
    const [remark, setRemark] = useState("");
    const [id, setId] = useState("");
    useEffect(() => {
      setId(leadsl?.id);
      setActivity(leadsl?.activity);
      setAssign(leadsl?.assign_to);
      setSubject(leadsl?.subject);
      setStartdate(leadsl?.start_date);
      setStarttime(leadsl?.start_time);
      setPriority(leadsl?.priority);
      setReminder(leadsl?.reminder_before);
      setRemark(leadsl?.remark);
      setEmailcheck(leadsl?.email_notify);
      setSmscheck(leadsl?.sms_notify);
    }, [leadsl]);

    const emailHandler = (e) => {
      if (e.target.checked) {
        setEmailcheck(1);
      } else {
        setEmailcheck(0);
      }
    };
    const smsHandler = (e) => {
      if (e.target.checked) {
        setSmscheck(1);
      } else {
        setSmscheck(0);
      }
    };

    useEffect(() => {
      dispatch(getCrmUsersApi(auth?.token));
    }, [auth?.token]);
    useEffect(() => {
      if (userStatus) {
        setAssignUser(leadData.users);
      }
    }, [userStatus]);
    useEffect(() => {
      if (userTaskStatus) {
        dispatch(changeReducerState());
        setModal(false);
        setWsStatus(true);
        NotificationManager.success("Updated Successfully", "Sccess");
      }
    }, [userTaskStatus]);
    useEffect(() => {
      if (listReminderStatus) {
        setReminderList(listReminder.reminder);
      }
    }, [listReminderStatus, auth?.token]);
    useEffect(() => {
      dispatch(getListReminderData(auth?.token));
    }, [auth?.token]);
    useEffect(() => {
      dispatch(getListActivityData(auth?.token));
    }, [auth?.token]);
    useEffect(() => {
      if (listActivityStatus) {
        setActivityList(listActivity.activity);
      }
    }, [listActivityStatus, auth?.token]);

   
    useEffect(() => {
      if (getPriorityListStatus) {
        setPriorityList(getPriorityList.allPriority);
      }
    }, [getPriorityListStatus]);
    useEffect(() => {
      dispatch(getPriority(auth?.token));
    }, [auth?.token]);
    const saveHandler = (event) => {
      event.preventDefault();
      const inputData = new FormData();
      inputData.append("lead_id", id);
      inputData.append("activity", activity);
      inputData.append("assign_to", assign);
      inputData.append("subject", subject);
      inputData.append("start_date", startdate);
      inputData.append("start_time", starttime);
      inputData.append("priority", priority);
      inputData.append("reminder_before", reminder);
      inputData.append("sms_notify", smscheck);
      inputData.append("email_notify", emailcheck);
      inputData.append("remark", remark);
      inputData.append("type", type);
      dispatch(taskUpdate(id, auth?.token, inputData));
    };

    if (!auth?.token) return <Redirect to="/login" />;
    return (
      <Fragment>
        <Modal isOpen={modal} toggle={handleModal} size="md">
          <ModalHeader tag="h5" toggle={handleModal}>
            Edit Task
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={saveHandler}>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 mb-15">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                              Activity
                            </label>
                            <div className="col-md-8">
                              <Input
                                type="select"
                                className="custom-select"
                                id="assignTo"
                                onChange={(e) => setActivity(e.target.value)}
                                value={activity}
                                invalid={!!error.activity}
                              >
                                <option>select</option>
                                {activityList?.map((asu) => (
                                  <option value={asu.id} key={asu.id}>
                                    {asu.name}
                                  </option>
                                ))}
                              </Input>
                              <FormFeedback className="text-left">
                                {error.activity}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                              Assigned To
                            </label>
                            <div className="col-md-8">
                              <Input
                                type="select"
                                className="custom-select"
                                id="assignTo"
                                onChange={(e) => setAssign(e.target.value)}
                                value={assign}
                                invalid={!!error.assign_to}
                              >
                                <option>select</option>
                                {assignUser?.map((asu) => (
                                  <option value={asu.value} key={asu.value}>
                                    {asu.label}
                                  </option>
                                ))}
                              </Input>
                              <FormFeedback className="text-left">
                                {error.assign_to}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right text-danger">
                              Subject
                            </label>
                            <div className="col-md-8">
                              <Input
                                type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                invalid={!!error.subject}
                              />
                              <FormFeedback className="text-left">
                                {error.subject}
                              </FormFeedback>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right text-danger">
                              Start Date &amp; Time
                            </label>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6 form-group">
                                  <Input
                                    type="date"
                                    value={startdate}
                                    onChange={(e) =>
                                      setStartdate(e.target.value)
                                    }
                                    invalid={!!error.start_date}
                                  />
                                  <FormFeedback className="text-left">
                                    {error.start_date}
                                  </FormFeedback>
                                </div>
                                <div className="col-md-6 form-group">
                                  <div className="input-group clockpicker-example">
                                    <Input
                                      type="time"
                                      value={starttime}
                                      onChange={(e) =>
                                        setStarttime(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right text-danger">
                              Priority
                            </label>
                            <div className="col-md-8">
                              <Input
                                type="select"
                                className="custom-select"
                                id="priority"
                                onChange={(e) => setPriority(e.target.value)}
                                value={priority}
                              >
                                <option value="">select</option>
                                    {priorityy?.map((item)=>
                                    {
                                      return(
                                        <option value={item?.title} key={item?.id}>{item?.title}</option>
                                      )
                                    })}
                                <option value="Warm">Warm</option>
                              </Input>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                              Reminder Before
                            </label>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-12 form-group">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    id="reminder"
                                    onChange={(e) =>
                                      setReminder(e.target.value)
                                    }
                                    value={reminder}
                                  >
                                    <option value="">select</option>
                                    <option value="15">15 Min</option>
                                    <option value="30">30 Min</option>
                                    <option value="60">60 Min</option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                              Remark
                            </label>
                            <div className="col-md-8">
                              <Input
                                type="textarea"
                                className="form-control"
                                rows="3"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                              ></Input>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <div className="col-md-4 control-label col-form-label col-12 text-md-right" />
                            <div className="col-md-8">
                              <Button type="submit" color="primary">
                                {userTaskLoader ? (
                                  <FaSpinner className="fa-spin" />
                                ) : (
                                  "Update"
                                )}
                              </Button>

                              <Link to="/lead/1/details">
                                <Button
                                  type="button"
                                  color="secondry"
                                  className="ml-2"
                                  onClick={handleModal}
                                >
                                  Close
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
);

export default EditTask;
