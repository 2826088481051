import React,{useState,useMemo} from 'react'
import _ from "lodash";
import { Input } from "reactstrap";
import { Modal } from "reactstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
    Button,
    ModalHeader,
    ModalBody,
    FormGroup,
    ModalFooter,
    Label,
    Row,
    Col,
    
  } from "reactstrap";
  import {
    FiEdit, 
  } from "react-icons/fi";
  import { FaSpinner } from "react-icons/fa";
const UserDetail = ({leadDetails,showuser,users,refresh}) => {
    const [membershipModel, setemembershipModel] = useState(false);
    const [file, setFile] = useState();
    const {
        auth,
       
      } = useSelector(({ auth }) => auth);
      const token = useMemo(() => auth?.token, [auth]);
    
    const updateMembership = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("agent_id", auth?.id);
        data.append("id", users?.id);
    
        data.append("file", file);
        axios
          .post(`/api/crm/update-lead-member`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            NotificationManager.success(res.data?.message);
            setemembershipModel(!membershipModel);
          })
          .catch((err) => console.log(err));
      };
  return (
    <div>
         <div className="px-3 py-2">
                  <div className="row lead-info">
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Assigned To
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {leadDetails?.crm_user_tbl?.name || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Email
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "email", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Mobile
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "mobile", "N/A")}
                        </span>
                      </div>
                    </div>
                    {showuser && (
                      <div className="row justify-content-between">
                        <div className="col-md-10">
                          <div className="font-weight-bold d-inline-block label-tag">
                            MemberShip
                          </div>
                          <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                            :
                          </div>
                          <div className="d-inline-block data-tag">
                            <span id="l_Code">
                              {users?.plan_detail_all?.name
                                ? users?.plan_detail_all?.name
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                        {users?.plan_detail_all?.name&&
                        <div className="col-md-2">
                          <FiEdit
                            className="mr-2 cursor-pointer"
                            onClick={() =>
                              setemembershipModel(!membershipModel)
                            }
                          />
                        </div>
}
                      </div>
                    )}
                    <Modal
                      isOpen={membershipModel}
                      toggle={() => setemembershipModel(false)}
                      size="sm"
                    >
                      <ModalHeader
                        tag="h5"
                        toggle={() => setemembershipModel(false)}
                      >
                        Edit Membership
                      </ModalHeader>
                      <form onSubmit={updateMembership}>
                        <ModalBody>
                          <Row>
                            <Col sm="12">
                              <FormGroup>
                                <Label for="paymentType">
                                  Select Membership
                                </Label>
                                <Input
                                  type="select"
                                  id="paymentType"
                                  value={users?.plan_detail_all?.name}
                                  className="custom-select"
                                >
                                  <option>
                                    {users?.plan_detail_all?.name}
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label for="paymentType">Select City</Label>
                                <Input
                                  type="select"
                                  id="paymentType"
                                  value={users?.plan_detail_all?.base_price}
                                  className="custom-select"
                                >
                                  <option>
                                    {users?.plan_detail_all?.base_price}
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <label for="formFileSm" class="form-label">
                                Payment Screenshot{" "}
                              </label>
                              <input
                                class="form-control form-control-sm p-1 mb-2"
                                id="formFileSm"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                              />
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" type="submit">
                            {refresh ? <FaSpinner /> : "Send"}
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() => {
                              setemembershipModel(false);
                            }}
                          >
                            Close
                          </Button>
                        </ModalFooter>
                      </form>
                    </Modal>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Sector
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {
                            
                            
                            users?.user_plan_details?.some?.((item)=>item?.sector
                            ?.name)?
                            users?.user_plan_details?.map((item)=>
                            {
                              return item?.sector?.name

                            }).join(",")
                            :_.get(leadDetails?.lead_sector, "name", "N/A")

                          }
                          {/* {_.get(leadDetails?.lead_sector, "name", "N/A")} */}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Industry
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                        {
                            users?.user_plan_details?.some?.((item)=>item?.project?.name)?
                            users?.user_plan_details?.map((item)=>
                            {
                              return item?.project?.name

                            }).join(",")
                            :_.get(leadDetails?.lead_industry, "name", "N/A")

                          }
                          {/* {_.get(leadDetails?.lead_industry, "name", "N/A")} */}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Source
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails?.lead_source, "name", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Lead Status
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.capitalize(
                            _.get(leadDetails, "lead_status.title", "N/A")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Priority(Interested)
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {leadDetails?.priority
                            ? leadDetails?.priority
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  )
}

export default UserDetail