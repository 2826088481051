import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";

import {
  workShopData,
  workShopUser,
  workShopCreate,
} from "../../store/actions/WorkShopActions";
import moment from "moment";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
const Create = ({ history: { push } }) => {
  const [fields, setFields] = useState({ status: true });
  const dispatch = useDispatch();
  const { auth, isAuthenticated } = useSelector((state) => state.auth);
  const {
    workShops,
    users: { user },
    createStatus,
    errors,
    loading,
  } = useSelector((state) => state.workShop);
  useEffect(() => {
    dispatch(workShopData(auth?.token));
  }, [auth?.token]);
  useMemo(() => {
    if (fields.mobile) {
      if (fields.mobile?.length == 10) {
        dispatch(workShopUser(auth?.token, fields.mobile));
      }
    }
  }, [fields.mobile]);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  const handleSwitchChange = () => {
    setFields({ ...fields, status: !fields.status });
  };
  const selectedWorkShop = useMemo(() => {
    if (fields.workshop) {
      let selected = workShops?.upcomming.find(
        (ws) => ws.id == fields.workshop
      );
      return selected;
    }
  }, [fields.workshop]);
  const fees = useMemo(() => {
    if (!_.isEmpty(selectedWorkShop)) {
      if (
        !_.isEqual(selectedWorkShop?.online_fee, "null") &&
        !_.isEqual(selectedWorkShop?.online_fee, "Free")
      ) {
        setFields({
          ...fields,
          amount: selectedWorkShop?.online_fee,
        });
        return selectedWorkShop?.online_fee;
      } else {
        if (
          !_.isEqual(selectedWorkShop?.offline_fee, "null") &&
          !_.isEqual(selectedWorkShop?.offline_fee, "Free")
        ) {
          setFields({
            ...fields,
            amount: selectedWorkShop?.offline_fee,
          });
          return selectedWorkShop?.offline_fee;
        } else {
          setFields({
            ...fields,
            amount: "Free",
          });
          return "Free";
        }
      }
    }
  }, [selectedWorkShop]);
  const timeConvert = (time) => {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
        _.remove(time, (t, i) => i === 3); // Removing seconds
      }
      return time.join(""); // return adjusted time or original string
    }
  };
  useEffect(() => {
    if (selectedWorkShop) {
      setFields({
        ...fields,
        mode: selectedWorkShop?.online_available,
      });
    }
  }, [selectedWorkShop]);
  useEffect(() => {
    if (user) {
      setFields({
        ...fields,
        name: user.name,
        email: user.email,
        id: user.id,
      });
    }
  }, [user]);
  const handleSubmit = (e) => {
    e.preventDefault();
    
    dispatch(workShopCreate(fields, auth?.token));
  };
  useEffect(() => {
    if (createStatus) {
      NotificationManager.success("Created Successfully", "Sccess");

      push("/booking-workshop");
    }
  }, [createStatus]);
  const handleClose = (e) => {
    e.preventDefault();
    push("/booking-workshop");
  };
  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Create WorkShop</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 offset-md-2 mb-15">
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="workshop">WorkShop</Label>
                                <Input
                                  type="select"
                                  invalid={!!errors.workshop}
                                  id="workshop"
                                  value={fields.workshop ? fields.workshop : ""}
                                  onChange={handleChange}
                                  className="custom-select"
                                >
                                  <option>select</option>
                                  {workShops?.upcomming
                                    ? _.map(workShops?.upcomming, (ws) => (
                                        <option value={ws.id} key={ws.id}>
                                          {ws.name}
                                        </option>
                                      ))
                                    : null}
                                </Input>
                                <FormFeedback>
                                  {!!errors.workshop ? errors.workshop[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            {selectedWorkShop ? (
                              <Col sm="6">
                                <Fragment>
                                  <FormGroup>
                                    <p>
                                      <strong>Name:</strong>
                                      <span>{selectedWorkShop?.name}</span>
                                    </p>
                                    <p>
                                      <strong>Date Time:</strong>
                                      <span>
                                        {moment(selectedWorkShop?.date).format(
                                          "Do MMM YYYY"
                                        )}
                                        {timeConvert(
                                          selectedWorkShop?.start_time
                                        )}{" "}
                                        to{" "}
                                        {timeConvert(
                                          selectedWorkShop?.end_time
                                        )}
                                      </span>
                                    </p>
                                    <p>
                                      <strong>Price:</strong>
                                      <span> {fees}</span>
                                    </p>
                                    <p>
                                      <strong>Mode:</strong>
                                      <span>
                                        {selectedWorkShop?.online_available == 1
                                          ? "Online"
                                          : "Offline"}
                                      </span>
                                    </p>
                                  </FormGroup>
                                </Fragment>
                              </Col>
                            ) : null}
                            <Col sm="6">
                              <FormGroup>
                                <Label for="mobile">Mobile</Label>
                                <Input
                                  invalid={!!errors.mobile}
                                  id="mobile"
                                  value={fields.mobile ? fields.mobile : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.mobile ? errors.mobile[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                  invalid={!!errors.name}
                                  id="name"
                                  value={fields.name ? fields.name : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.name ? errors.name[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  invalid={!!errors.email}
                                  id="email"
                                  value={fields.email ? fields.email : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.email ? errors.email[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="mode">Payment Mode</Label>
                                <Input
                                  type="select"
                                  invalid={!!errors.mode}
                                  id="mode"
                                  value={fields.mode ? fields.mode : ""}
                                  onChange={handleChange}
                                  className="custom-select"
                                >
                                  <option>select</option>
                                  <option value="1">Online</option>
                                  <option value="0">Offline</option>
                                </Input>
                                <FormFeedback>
                                  {!!errors.mode ? errors.mode[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="paymentType">Payment Type</Label>
                                <Input
                                  type="select"
                                  invalid={!!errors.paymentType}
                                  id="paymentType"
                                  value={
                                    fields.paymentType ? fields.paymentType : ""
                                  }
                                  onChange={handleChange}
                                  className="custom-select"
                                >
                                  <option>select</option>
                                  <option value="upi">UPI</option>
                                  <option value="imps">IMPS</option>
                                </Input>
                                <FormFeedback>
                                  {!!errors.paymentType
                                    ? errors.paymentType[0]
                                    : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="trxId">Trx Id</Label>
                                <Input
                                  invalid={!!errors.trxId}
                                  id="trxId"
                                  value={fields.trxId ? fields.trxId : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.trxId ? errors.trxId[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col sm="10">
                              <div className="text-center mb-3">
                                <Button
                                  type="submit"
                                  color="secondary"
                                  size="sm"
                                  className="mr-1"
                                >
                                  {loading ? <FaSpinner /> : "Save"}
                                </Button>

                                <Button
                                  type="button"
                                  color="secondary"
                                  size="sm"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Create;
