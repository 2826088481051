import Axios from "axios";

export const getAllActivityList = (token, paginate, page, search, sort) => {
  return {
    type: "ALL_ACTIVITY",
    payload: Axios.get(
      `/api/crm/all-activities?paginate=${paginate}&page=${page}&search=${search}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err) => err),
  };
};

export const getPriority = (token) => {
  return {
    type: "ALL_PRIORITY",
    payload: Axios.get(
      `/api/crm/priority`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err) => err),
  };
};
