import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import {
  Container,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
} from "reactstrap";
import { FiEdit, FiTrash } from "react-icons/fi";
import { FaPlus, FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../layouts/Loader";
import MainWrapper from "../../components/MainWrapper";
import { NotificationManager } from "react-notifications";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";

const ServicesEnquiry = (props) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [paginate, setPaginate] = useState(10);
  const [permissions, setPermissions] = useState([]);

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/serviceenquiry?sort=${sort}&page=${page}&search=${search}&paginate=${paginate}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        setServices(res.data.serviceEnquiry.data);
        setFrom(res.data.serviceEnquiry.from);
        setTo(res.data.serviceEnquiry.to);
        setTotal(res.data.serviceEnquiry.total);
        setPerPage(res.data.serviceEnquiry.per_page);
        setLoading(false);
        setPermissions(res.data.permission);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err.response.data.message);
      });
  }, [sort, page, refresh, search, paginate, auth]);

  const csvHead = [["Name", "Email", "Mobile", "Service Name"]];
  const csvData = services.map((cv) => [
    cv.name,
    cv.email,
    cv.mobile,
    cv.service ? cv.service.name : "--",
  ]);
  const exportData = csvHead.concat(csvData);

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/serviceenquiry/destroy", {
          ids: JSON.stringify(deleteIds),
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/serviceenquiry/destroy", { ids: JSON.stringify([id]) })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        services.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };
  const dropdownOpenToggle = () =>
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  return (
    <Fragment>
      <MainWrapper>
        <main className="content pt-4">
          <div className="page-header mb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="c-card-top">
                  <div className="c-card-top-title">
                    <h4 className="mb-0">Services Enquiry List</h4>
                  </div>
                  <div className="c-card-top-inputs c-card-top-search">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                      <span className="custom-select">Search</span>
                      <Input
                        placeholder="Search here..."
                        onChange={handleSearch}
                        value={search}
                        className="c-form-control-serch"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-secondary btn-sm"
                          type="button"
                          id="button-addon2"
                        >
                          GO
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="c-card-top-buttons">
                    <ButtonGroup className="">
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={dropdownOpenToggle}
                      >
                        <DropdownToggle caret size="sm">
                          <FaList />
                          &nbsp;Services Tools
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="#">
                            Tool Name
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </ButtonGroup>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between justify-content-center">
                                <h4>Services Enquiry List</h4>
                                <div className="d-flex justify-content-between justify-content-center">
                                    <InputGroup className="mr-1">
                                        <Input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={handleSearch}
                                        />
                                    </InputGroup>
                                    <ButtonDropdown
                                        isOpen={dropdownOpen}
                                        toggle={dropdownOpenToggle}
                                    >
                                        <DropdownToggle caret size="sm">
                                            <FaList />
                                            &nbsp;Services Tools
                                        </DropdownToggle>
                                        <DropdownMenu className="w-100">
                                            <DropdownItem tag="a" href="#">
                                                Tool Name
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between justify-content-center">
                      <div>
                        {permissions.find(
                          (ps) => ps.name === "service-delete"
                        ) != null ? (
                          <button
                            className="btn btn-secondary btn-sm mr-1"
                            disabled={!deleteIds.length}
                            onClick={handleDelete}
                          >
                            <i className="fa fa-trash pr-1" /> Delete
                          </button>
                        ) : null}
                        <button
                          className="btn btn-secondary btn-circle btn-sm"
                          title="Refresh"
                          onClick={() => setRefresh((refresh) => refresh + 1)}
                        >
                          <i className="fa fa-refresh" />
                        </button>
                      </div>
                      <div>
                        <select
                          id="sort"
                          onChange={(e) => setSort(e.target.value)}
                          value={sort}
                          className="custom-select mr-1"
                          style={{ width: 135 }}
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <button
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Print"
                        >
                          <i className="fa fa-print" />
                        </button>
                        <button
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Export To PDF"
                        >
                          <i className="fa fa-file-pdf-o" />
                        </button>
                        <CSVLink
                          filename={"service-enquiry.csv"}
                          className="btn btn-secondary btn-floating btn-sm mr-1 text-white"
                          title="Export To CSV"
                          data={exportData}
                        >
                          <i className="fa fa-file-excel-o" />
                        </CSVLink>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0 table-sm small">
                              <thead className="thead-light">
                                <tr>
                                  <th className="font-weight-bold">
                                    <CustomInput
                                      id="default"
                                      type="checkbox"
                                      onChange={handleMultiSelect}
                                      checked={
                                        deleteIds.length === services.length &&
                                        deleteIds.length
                                      }
                                    />
                                  </th>
                                  <th className="font-weight-bold">Name</th>
                                  <th className="font-weight-bold">Email</th>
                                  <th className="font-weight-bold">Mobile</th>
                                  <th className="font-weight-bold">
                                    Service Name
                                  </th>

                                  <th className="font-weight-bold">Message</th>
                                  <th className="font-weight-bold">
                                    Enquiry Type
                                  </th>
                                  <th className="font-weight-bold">
                                    Created At
                                  </th>
                                  {permissions.find(
                                    (ps) => ps.name === "service-delete"
                                  ) != null ? (
                                    <th className="font-weight-bold text-right">
                                      Action
                                    </th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                <Fragment>
                                  {loading ? (
                                    <Fragment>
                                      <tr>
                                        <td colSpan={7}>
                                          <Loader loading={loading} />
                                        </td>
                                      </tr>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {services.length ? (
                                        <Fragment>
                                          {services.map((is) => (
                                            <tr key={is.id}>
                                              <td>
                                                <CustomInput
                                                  id={`default-${is.id}`}
                                                  type="checkbox"
                                                  checked={deleteIds.some(
                                                    (d) => d === is.id
                                                  )}
                                                  onChange={(e) =>
                                                    handleSingleSelect(e, is.id)
                                                  }
                                                />
                                              </td>
                                              <td>{is.name}</td>
                                              <td>{is.email}</td>
                                              <td>
                                                {is.mobile ? is.mobile : "--"}
                                              </td>
                                              <td>
                                                {is.service
                                                  ? is.service.name
                                                  : "--"}
                                              </td>

                                              <td
                                                className="text-wrap"
                                                style={{
                                                  maxWidth: "850px",
                                                  width: "850px",
                                                }}
                                              >
                                                {is.message}
                                              </td>
                                              <td className="text-capitalize">
                                                {is.type &&
                                                  is.type.replaceAll("_", " ")}
                                              </td>
                                              <td>{is.created_at}</td>
                                              {permissions.find(
                                                (ps) =>
                                                  ps.name === "service-delete"
                                              ) != null ? (
                                                <td className="text-right">
                                                  {permissions.find(
                                                    (ps) =>
                                                      ps.name ===
                                                      "service-delete"
                                                  ) != null ? (
                                                    <a
                                                      className="c-icon-btn-light"
                                                      href="#"
                                                      onClick={(e) =>
                                                        handleOneDelete(
                                                          e,
                                                          is.id
                                                        )
                                                      }
                                                    >
                                                      <FiTrash />
                                                    </a>
                                                  ) : null}
                                                </td>
                                              ) : null}
                                            </tr>
                                          ))}
                                        </Fragment>
                                      ) : (
                                        <tr className="text-center">
                                          <td colSpan={7}>No data found</td>
                                        </tr>
                                      )}
                                    </Fragment>
                                  )}
                                </Fragment>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <span>
                          Showing {from} to {to} of {total} entries
                        </span>
                        <div className=" d-flex">
                          <select
                            id="paginate"
                            onChange={(e) => setPaginate(e.target.value)}
                            value={paginate}
                            className="custom-select custom-select-page"
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                          </select>
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={(page) => setPage(page)}
                            innerClass="pagination pagination-sm"
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default ServicesEnquiry;
