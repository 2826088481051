import _ from "lodash";

const initialState = {
  permissions: [],
  permission: [],
  permissionUpdate: [],
  permissionDelete: [],
  permissionCreateStatus: false,
  permissionDeleteStatus: false,
  permissionsStatus: false,
  permissionLoading: false,
  rolesList: [],
  rolesStatus: false,
  rolesLoading: false,
  updateRoleStatus: false,
  role: [],
  createRoleStatus: false,
  users: [],
  error: {},
  loading: false,
  roleDestroy: [],
  roleDestroyStatus: false,
  updateLoading: false,
  useDetails: [],
};

const userReducer = (state = initialState, action) => {
  const { payload } = action;

  // eslint-disable-next-line default-case
  switch (action.type) {
    case "USER_ROLE_PENDING":

      state = {
        ...state,
        loading: true,
        createRoleStatus: false,
        error: {},
      };
      break;
    case "USER_ROLE_FULFILLED":
      state = {
        ...state,
        loading: false,
        role: _.get(payload, "data", {}),
        createRoleStatus: true,
      };
      break;
    case "USER_ROLE_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
          createRoleStatus: false,
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
          createRoleStatus: false,
        };
      }
      break;
    case "ROLES_PENDING":
    case "USER_LIST_PENDING":
    case "USER_DETAILS_PENDING":
      state = {
        ...state,
        rolesLoading: true,
        rolesStatus: false,
        error: {},
      };
      break;
    case "ROLES_FULFILLED":
     
      state = {
        ...state,
        rolesList: _.get(payload, "data", {}),
        rolesLoading: false,
        rolesStatus: true,
      };
      break;
    case "USER_LIST_FULFILLED":
      state = {
        ...state,
        users: _.get(payload, "data", {}),
        rolesLoading: false,
        rolesStatus: true,
      };
      break;
    case "USER_DETAILS_FULFILLED":
      state = {
        ...state,
        useDetails: _.get(payload, "data", {}),
        rolesLoading: false,
        rolesStatus: true,
      };
      break;
    case "USER_LIST_REJECTED":
    case "USER_DETAILS_REJECTED":
    case "ROLES_REJECTED":
      state = {
        ...state,
        rolesLoading: false,
        error: _.get(payload.response.data, "message", {}),
        rolesStatus: false,
      };
      break;
    case "ROLE_DESTROY_PENDING":
      state = {
        ...state,
        rolesLoading: true,
        error: {},
        roleDestroyStatus: false,
      };
      break;
    case "ROLE_DESTROY_FULFILLED":
      state = {
        ...state,
        rolesLoading: false,
        roleDestroy: _.get(payload, "data", {}),
        roleDestroyStatus: true,
      };
      break;
    case "ROLE_DESTROY_REJECTED":
      state = {
        ...state,
        rolesLoading: false,
        error: _.get(payload.response.data, "message", {}),
        roleDestroyStatus: false,
      };
      break;
    case "ROLE_UPDATE_PENDING":
      state = {
        ...state,
        updateLoading: true,
        updateRoleStatus: false,
        error: {},
      };
      break;
    case "Role_UPDATE_FULFILLED":
      state = {
        ...state,
        loading: false,
        role: _.get(payload, "data", {}),
        createRoleStatus: true,
      };
      break;
    case "ROLE_UPDATE_REJECTED":
      if (payload.response.status === 422) {
        state = {
          ...state,
          updateLoading: false,
          error: _.get(payload.response.data, "errors", {}),
          updateRoleStatus: false,
        };
      } else {
        state = {
          ...state,
          updateLoading: false,
          error: _.get(payload.response.data, "message", {}),
          updateRoleStatus: false,
        };
      }
      break;
    case "CREATE_PERMISSION_PENDING":
      state = {
        ...state,
        loading: true,
        permissionCreateStatus: false,
        error: {},
      };
      break;
    case "CREATE_PERMISSION_FULFILLED":
      state = {
        ...state,
        loading: false,
        permissionCreateStatus: true,
        permission: _.get(payload, "data", {}),
      };
      break;
    case "CREATE_PERMISSION_REJECTED":
      if (payload.response.state === 422) {
        state = {
          ...state,
          loading: false,
          permissionCreateStatus: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          permissionCreateStatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
        break;
      }
    case "PERMISSIONS_PENDING":
      state = {
        ...state,
        permissionLoading: true,
        permissionsStatus: false,
        error: {},
      };
      break;
    case "PERMISSIONS_FULFILLED":
      state = {
        ...state,
        permissionLoading: false,
        permissionsStatus: true,
        permissions: _.get(payload, "data", {}),
      };
      break;
    case "PERMISSIONS_REJECTED":
      state = {
        ...state,
        permissionLoading: false,
        permissionsStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "PERMISSION_UPDATE_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "PERMISSION_UPDATE_FULFILLED":
      state = {
        ...state,
        loading: false,
        permissionUpdate: _.get(payload, "data", {}),
      };
      break;
    case "PERMISSION_UPDATE_REJECTED":
      if (payload.response.state === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(payload.response.data, "message", {}),
        };
        break;
      }
    case "PERMISSION_DELETE_PENDING":
      state = {
        ...state,
        loading: true,
        permissionDeleteStatus: false,
        error: {},
      };
      break;
    case "PERMISSION_DELETE_FULFILLED":
      state = {
        loading: false,
        permissionDeleteStatus: true,
      };
      break;
    case "PERMISSION_DELETE_REJECTED":
      state = {
        loading: false,
        permissionDeleteStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
  }
  return state;
};

export default userReducer;
