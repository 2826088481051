import React, { Fragment, useState, useEffect, useMemo, memo } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import Creatable, { components } from "react-select";
import {
  projectreportEdit,
  changeReducerState
} from '../../store/actions/ProjectReportActions';
import _ from 'lodash'
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import axios from "axios";
import { FiEdit } from "react-icons/fi";
const EditCustomizeProjectReport = memo(({ setWsStatus, leadDetail, item, projectId, bookedProjectReport }) => {



  const [fields, setFields] = useState({
    name: leadDetail?.name ?? "",
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    pincode: leadDetail?.pincode ?? "",


  });
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [project, setProject] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [error, setError] = useState({});
  const [orderId, setOrderId] = useState('')
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [projectreport, setProjectreport] = useState("");
  const [file, setFile] = useState('')
  const [formData, setFormData] = useState(
    {
      project_id: '',
      name: leadDetail?.name ?? '',
      mobile: leadDetail?.mobile ?? '',
      email: leadDetail?.email ?? '',
      mode: '',
      paymentType: '',
      payment_id: '',
      amount: '',
      id: leadDetail?.user?.id ?? '',
    })
  const {
    users: { user },
    createStatus,
    errors,
    loading,
  } = useSelector(({ workShop }) => workShop)
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state.auth)
  const { token } = auth
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append("name", formData?.name)
    data.append("email", formData?.email)
    data.append("mobile", formData?.mobile)
    data.append("amount", formData?.amount)
    data.append("project_id", fields?.selectedMemberShip)
    data.append("associate_amount", formData?.associate_amount)
    data.append("payment_id", fields?.payment_id)
    data.append("paymentType", fields?.paymentType)
    data.append("payment_mode", fields?.mode)
    data.append("payment_img", file)
    data.append("price",fields?.report_price)
    axios.post(`/api/crm/update-customize-report/${orderId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      console.log(res.data)
      setWsStatus(prev => !prev)
      NotificationManager.success(res.data?.message)
    })
      .catch(err => NotificationManager.error(err.response?.data?.message))

  }
  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${3}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);



  useEffect(() => {
    if (orderId) {
      axios(`/api/crm/get-customize-report-details?customizeId=${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          setFields({ ...fields, payment_id: res.data?.CustomizeReport?.payments?.payment_id, selectedMemberShip: res.data?.CustomizeReport?.project?.getpro?.project_id, mode: res.data?.CustomizeReport?.payments?.payment_mode === "online" ? "0" : "1", paymentType: res.data?.CustomizeReport?.payments?.gatway_type,report_price: res.data?.CustomizeReport?.actual_price, })
          handleSector({ value: res.data?.CustomizeReport?.project?.getpro?.sub_category_id, label: sectors?.find(item => item?.value === res.data?.CustomizeReport?.project?.getpro?.sub_category_id)?.label }, true, res.data?.CustomizeReport?.project?.getpro?.sub_sub_category_id)
          axios
            .get(`/api/crm/projectreport-list/${res.data?.CustomizeReport?.project?.getpro?.sub_category_id}/${res.data?.CustomizeReport?.project?.getpro?.sub_sub_category_id}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            .then((response) => {
              setProject(response?.data?.projectReport);
            })
            .catch((err) => console.log(err));
        })
        .catch(error => console.log(error))
    }
  }, [orderId, item, sectors])

  useEffect(() => {
    if (createStatus) {
      setWsStatus(true);
      dispatch(changeReducerState());
      NotificationManager.success('Updated Successfully !', 'Sccess');
    }
  }, [createStatus]);

  const handleSector = (e, def = false, subId = '') => {
    setSubCategory(e);
    axios
      .get(`/api/crm/subsectordata?industry=${e.value}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIndustry(res.data?.subSubCats);
        if (def) {
          setSubSubCategory(res.data?.subSubCats?.filter?.(item => item.value === subId))
        }
      })
      .catch((err) => console.log(err));
  };

  const handleProjectReport = (e) => {
    setProjectreport(e);
    axios
      .get(`/api/crm/projectreport-list/${subCategory.value}/${e.value}`)
      .then((res) => {
        setProject(res?.data?.projectReport);
      })
      .catch((err) => console.log(err));
  };
  console.log(project, 'setProject')

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = project?.find(
        (v) => v?.projectreports?.id == fields?.selectedMemberShip
      );
      return selected;
    }
  }, [fields?.selectedMemberShip]);

  console.log(selectedMemberShip, "priceeeeeeeee");
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });

  };
  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        name: user.name,
        email: user.email,
        id: user.id
      })
    }
  }, [user])

  const editProjectReport = useMemo(() => {
    if (projectId && _.size(bookedProjectReport)) {
      let booked = bookedProjectReport.find(pr => pr?.customizeId === projectId);
      return booked;
    }
  }, [projectId, bookedProjectReport])
  console.log(editProjectReport, "reportss");

  useMemo(() => {
    if (selectedMemberShip) {
      setProjectID(selectedMemberShip?.project_id);

    } else {
      setProjectID(editProjectReport?.id);
    }

  }, [selectedMemberShip])
  console.log(selectedMemberShip, "price");

  useMemo(() => {

    if (editProjectReport && modal) {
      setFields({ ...fields, [editProjectReport.id]: editProjectReport?.title })
      setFormData({
        name: leadDetail?.name ?? '',
        mobile: leadDetail?.mobile ?? '',
        email: leadDetail?.email ?? '',
        order_id: projectId,
        project_name: editProjectReport?.title,
        mode: editProjectReport?.model,
        paymentType: editProjectReport?.payment_mode,
        payment_id: editProjectReport?.payment_id,
        amount: editProjectReport?.amount,
        associate_amount: editProjectReport?.associate_amount
      })
    }
  }, [editProjectReport, modal])
  return (
    <Fragment>
      <a color="secondary" type='btn' size="sm" onClick={() => {
        setModal(true)
        setOrderId(item?.customizeId)
      }} className="flex-grow-1"><FiEdit /></a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>Edit Customize Project Report</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="sector">Sector</Label>

                  <Creatable
                    options={[...sectors]}
                    onChange={handleSector}
                    value={subCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">{errors.sector}</FormFeedback>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="industry">Industry</Label>
                  <Creatable
                    options={[...industry]}
                    onChange={(e) => {
                      setSubSubCategory(e)
                      handleProjectReport(e)
                    }}
                    value={subSubCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errors.industry}
                </FormFeedback>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">Project Report</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.selectedMemberShip}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    {/* <option>select</option> */}
                    <option>{editProjectReport?.title}</option>

                    {_.size(project)
                      ? _.map(project, (mem) => (
                        <option value={mem.projectreports?.id} key={mem.projectreports?.id}>
                          {mem.projectreports?.title}
                        </option>
                      ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile</Label>
                  <Input
                    invalid={!!errors.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    invalid={!!errors.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    invalid={!!errors.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6} >
                <FormGroup>
                  <Label for="report_price">Customized Report Price For</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="report_price"
                    invalid={!!errors?.report_price}
                    value={selectedMemberShip?.actual_price || fields?.report_price}
                    onChange={handleChange}
                  >
                    <option>select</option>
                    <option value="5900">
                      Project upto Rs. 1.00 crores-Rs. 5900.00
                    </option>
                    <option value="ethnol">
                      Ethanol project report rupees 59000
                    </option>
                    <option value="8850">
                      Project from Rs. 1.00 crores to 3.00 crores - Rs. 8850.00
                    </option>
                    <option value="11800">
                      Project from Rs. 3.00 crores to Rs. 5.00 crores - Rs.
                      11800.00
                    </option>
                    <option value="17700">
                      Project from Rs. 5.00 crores to 10.00 cores - Rs. 17700.00
                    </option>
                    <option value="0">
                      For Project Cost above Rs. 10.00 crores charges as per
                      discussion
                    </option>
                  </Input>
                  <FormFeedback>
                    {!!errors?.report_price ? errors?.report_price?.[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Associate_amount</Label>
                  <Input
                    id="total"
                    value={formData.associate_amount}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="0">Online</option>
                    <option value="1">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>

                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="payment_id">Payment Id</Label>
                  <Input
                    invalid={!!errors.payment_id}
                    id="payment_id"
                    value={fields.payment_id ? fields.payment_id : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => {
              setModal(false)

            }}>Close</Button>
            <Button color="primary" type="submit̥" onClick={() => {
              setModal(false)

            }}>Save</Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  )
})

export default EditCustomizeProjectReport;