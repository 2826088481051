import React, { useState } from 'react'
import { useEffect } from 'react'
import { FaChevronUp, FaChevronDown } from "react-icons/fa"
import { FormGroup, Input, Label } from "reactstrap"
import axios from "axios"
import { useSelector } from 'react-redux'
const CollapseCard = ({ id, name = "", moduleType = "", showFilters = false }) => {
    const [isOpen, setIsOpen] = useState(-1)
    const [filter, setFilter] = useState({
        startDate: "",
        endDate: ""
    })
    const [countData, setCountData] = useState({})
    const { auth } = useSelector((state) => state.auth);
    const { auth: { token } } = useSelector(state => state.auth)
    console.log(isOpen, "isOpen")
    useEffect(() => {
        if (isOpen !== -1) {
            if (moduleType === "associate") {
                console.log("associate", id)
                axios(`/api/crm/get-associate-dashboard?id=${isOpen}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((res) => setCountData(res.data))
                    .catch((err) => console.log(err));
            }

            if (moduleType === "franchise") {
                axios(
                    `/api/crm/get-franchise-investor-count?id=${isOpen}&start=${filter.startDate}&end=${filter.endDate}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => setCountData(res.data))
                    .catch((err) => console.log(err));
            }

            if (moduleType === "tataplay") {
                axios(`/api/crm/get-tataplay-count?id=${isOpen}&start_date=${filter.startDate}&end_date=${filter.endDate}`)
                    .then(({ data }) => setCountData(data))
                    .catch(err => console.error(err))
            }
        }
    }, [isOpen, moduleType, filter])
    return (
        <div className="accordion mb-3" id={id} >
            <div className="accordion-item" >
                <h2 className="accordion-header mb-0" id={id}>
                    <div
                        className="d-flex justify-content-between card-header"
                        onClick={() => {
                            setIsOpen(prev => prev === id ? -1 : id);
                        }}
                    >
                        <h6 className="text-white mb-0">{name}</h6>
                        {isOpen === id ? (
                            <FaChevronUp size={16} color="white" />
                        ) : (
                            <FaChevronDown size={16} color="white" />
                        )}
                    </div>
                </h2>

                {
                    isOpen === id &&
                    <div className="bg-white px-2" >
                        {showFilters && <FormGroup>
                            <Label>
                                Start Date
                            </Label>
                            <Input type="date" value={filter.startDate} onChange={(e) => {
                                e.persist()
                                setFilter(prev => ({ ...prev, startDate: e.target.value }))
                            }
                            } />

                        </FormGroup>}

                        {showFilters && <FormGroup>
                            <Label>
                                End Date
                            </Label>
                            <Input type="date" value={filter.endDate} onChange={(e) => {
                                e.persist()
                                setFilter(prev => ({ ...prev, endDate: e.target.value }))
                            }
                            } />

                        </FormGroup>}

                        {moduleType === "associate" && <div className="py-3">

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Monthly Amount
                                </h5>
                                <h6>
                                    {countData?.monthlyAmount}
                                </h6>
                            </div>

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Monthly Lead Count
                                </h5>
                                <h6>
                                    {countData?.monthlyleadCount}
                                </h6>
                            </div>

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Today Amount
                                </h5>
                                <h6>
                                    {countData?.todayAmount}
                                </h6>
                            </div>

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Today Lead Count
                                </h5>
                                <h6>
                                    {countData?.todayleadCount}
                                </h6>
                            </div>

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Total Amount
                                </h5>
                                <h6>
                                    {countData?.totalAmount}
                                </h6>
                            </div>

                            <div className="d-flex mb-3 align-items-center justify-content-between">
                                <h5 className=' ' >
                                    Total lead Count
                                </h5>
                                <h6>
                                    {countData?.totalleadCount}
                                </h6>
                            </div>
                        </div>}
                        {
                            moduleType === "franchise" &&
                            <div className="py-3" >
                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Cold Lead
                                    </h5>
                                    <h6>
                                        {countData?.coldLead}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Hot Lead
                                    </h5>
                                    <h6>
                                        {countData?.hotLead}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Warm Lead
                                    </h5>
                                    <h6>
                                        {countData?.warmLead}
                                    </h6>
                                </div>


                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Today On Boarded
                                    </h5>
                                    <h6>
                                        {countData?.todayOnBoarding}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Today Franchise Investor
                                    </h5>
                                    <h6>
                                        {countData?.todayfranchiseInvestor}
                                    </h6>
                                </div>


                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Meeting Align
                                    </h5>
                                    <h6>
                                        {countData?.totalMeetingAlign}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Meeting Completed
                                    </h5>
                                    <h6>
                                        {countData?.totalMeetingDone}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Onboarding
                                    </h5>
                                    <h6>
                                        {countData?.totalOnBoarding}
                                    </h6>
                                </div>



                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Completed Payments
                                    </h5>
                                    <h6>
                                        {countData?.totalPaymentDone}
                                    </h6>
                                </div>


                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Franchise Callbacks
                                    </h5>
                                    <h6>
                                        {countData?.totalfranchiseCallBack}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Franchise Follow up
                                    </h5>
                                    <h6>
                                        {countData?.totalfranchiseFollowUp}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Franchise Investor
                                    </h5>
                                    <h6>
                                        {countData?.totalfranchiseInvestor}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Franchise Mail Shared
                                    </h5>
                                    <h6>
                                        {countData?.totalfranchiseMailShared}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Franchise Not Connected
                                    </h5>
                                    <h6>
                                        {countData?.totalfranchiseNotConnected}
                                    </h6>
                                </div>






                            </div>
                        }

                        {
                            moduleType === "tataplay" &&
                            <div className=' py-3 ' >
                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Today Lead
                                    </h5>
                                    <h6>
                                        {countData?.todayLead}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Lead
                                    </h5>
                                    <h6>
                                        {countData?.totalLead}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Connected
                                    </h5>
                                    <h6>
                                        {countData?.totalConnected}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Intrested
                                    </h5>
                                    <h6>
                                        {countData?.totalInterested}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Not Intrested
                                    </h5>
                                    <h6>
                                        {countData?.totalNotInterested}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Detail Shared
                                    </h5>
                                    <h6>
                                        {countData?.totalDetailShared}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Callback
                                    </h5>
                                    <h6>
                                        {countData?.totalCallBack}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Mail Shared
                                    </h5>
                                    <h6>
                                        {countData?.totalMailShared}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Meeting Align
                                    </h5>
                                    <h6>
                                        {countData?.totalMeetingAlign}
                                    </h6>
                                </div>


                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Meeting Done
                                    </h5>
                                    <h6>
                                        {countData?.totalMeetingDone}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Follow Up
                                    </h5>
                                    <h6>
                                        {countData?.totalFollowUp}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Incompleted Meetings
                                    </h5>
                                    <h6>
                                        {countData?.totalMeetingDoneNOI}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Note Count
                                    </h5>
                                    <h6>
                                        {countData?.getNoteCount}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Not Connected
                                    </h5>
                                    <h6>
                                        {countData?.totalNotConnected}
                                    </h6>
                                </div>

                                <div className="d-flex mb-3 align-items-center justify-content-between">
                                    <h5 className=' ' >
                                        Total Payment Done
                                    </h5>
                                    <h6>
                                        {countData?.totalPaymentDone}
                                    </h6>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default CollapseCard