import Axios from "axios";

export const getCourses = (token, sectorId, categoryId) => {
  return {
    type: "GET_COURSE",
    payload: Axios.get(
      `/api/crm/all-courses?sectorId=${sectorId}&categoryId=${categoryId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err)=>err),
  };
};

  export const bookCourse = (data, token) => async (dispatch) => {
  // return {
  //   type: "BOOK_COURSES",
  //   payload: Axios.post(`/api/crm/book-course`, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }).catch((err)=>err),
  // };
  try {
    console.log('testing')
    const resp = await Axios.post(`/api/crm/book-course`,data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: "BOOK_COURSES_FULFILLED",
      payload: { message: "Added Successfully" },
    });
  } catch (errors) {
    dispatch({
      type: "BOOK_COURSES_REJECTED",
      payload: errors,
    });
  }
};
                                  