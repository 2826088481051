import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { eventInsertHandler } from "../../store/actions/TaskActions";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import { Link, Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
} from "reactstrap";
import {
  getLeadListDetails,
  getListActivityData,
  getListReminderData,
} from "../../store/actions/CommonActions";
import { getPriority } from "../../store/actions/AllActivityActions";
const Task = ({ leadDetail, setWsStatus, type }) => {
  const [modal, setModal] = useState(false);
  const [reminderList, setReminderList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [activity, setActivity] = useState("");
  const [priorityList, setPriorityList] = useState([]);
  const [assignUser, setAssignUser] = useState([]);
  const [subject, setSubject] = useState("");
  const [startdate, setStartdate] = useState("");
  const [starttime, setStarttime] = useState("");
  const [priority, setPriority] = useState("");
  const [reminder, setReminder] = useState("");
  const [emailcheck, setEmailcheck] = useState("");
  const [smscheck, setSmscheck] = useState("");
  const [remark, setRemark] = useState("");
  const [id, setId] = useState("");
  const [assign, setAssign] = useState("");
  const dispatch = useDispatch();

  const { auth, isAuthenticated } = useSelector(({ auth }) => auth);
  const {priorityy,error}=useSelector((state)=>state.allActivityReducer)
  const { errors, loading, taskCreateStatus } = useSelector(({ task }) => task);
  const token = useMemo(() => auth?.token, [auth]);
  const { userStatus, leadData } = useSelector(({ lead }) => lead);
  const {
    getPriorityListStatus,
    getPriorityList,
    listActivityStatus,
    listActivity,
    listReminderStatus,
    listReminder,
  } = useSelector((state) => state.common);
  useEffect(() => {
    if (leadDetail) {
      setId(leadDetail?.id);
      setAssign(leadDetail?.create?.id);
    }
  }, [leadDetail]);

  const emailHandler = (e) => {
    if (e.target.checked) {
      setEmailcheck(1);
    } else {
      setEmailcheck(0);
    }
  };
  const smsHandler = (e) => {
    if (e.target.checked) {
      setSmscheck(1);
    } else {
      setSmscheck(0);
    }
  };

  useEffect(() => {
    dispatch(getCrmUsersApi(token));
  }, [token]);
  useEffect(() => {
    if (userStatus) {
      setAssignUser(leadData.users);
    }
  }, [userStatus]);

  useEffect(() => {
    if (taskCreateStatus) {
      setWsStatus(true);
      setModal(!modal);
      NotificationManager.success("Created Successfully", "Sccess");
      dispatch({ type: "RESET_TASK_CREATE_STATUS", payload: false });
    }
  }, [taskCreateStatus]);
  useEffect(() => {
    if (listReminderStatus) {
      setReminderList(listReminder.reminder);
    }
  }, [listReminderStatus, token]);
  useEffect(() => {
    dispatch(getListReminderData(token));
  }, [token]);
  useEffect(() => {
    dispatch(getListActivityData(token));
  }, [token]);
  useEffect(() => {
    if (listActivityStatus) {
      setActivityList(listActivity.activity);
    }
  }, [listActivityStatus, token]);
  useEffect(() => {
    dispatch(getLeadListDetails(token));
  }, [token]);

  useEffect(() => {
    if (getPriorityListStatus) {
      setPriorityList(getPriorityList.allPriority);
    }
  }, [getPriorityListStatus]);
  useEffect(() => {
    dispatch(getPriority(token));
  }, [token]);
  const saveHandler = (event) => {
    event.preventDefault();
    const inputData = new FormData();
    inputData.append("lead_id", id);
    inputData.append("activity", activity);
    inputData.append("assign_to", assign);
    inputData.append("subject", subject);
    inputData.append("start_date", startdate);
    inputData.append("start_time", starttime);
    inputData.append("priority", priority);
    inputData.append("reminder_before", reminder);
    inputData.append("sms_notify", smscheck);
    inputData.append("email_notify", emailcheck);
    inputData.append("remark", remark);
    inputData.append("type", type);
    dispatch(eventInsertHandler(inputData, token));
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <a
        href="#"
        color="secondary"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Task
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="md" backdrop="static">
        <ModalHeader tag="h5" toggle={() => setModal(false)} >
          Add New Task
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={saveHandler}>
                <div className="row">
                  <div className="col-md-8 offset-md-2 mb-15">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Activity <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-8">
                            <Input
                              type="select"
                              className="custom-select"
                              id="assignTo"
                              onChange={(e) => setActivity(e.target.value)}
                              value={activity}
                              invalid={!!errors.activity}
                            >
                              <option value="">select</option>
                              <option value="Associate">Associate</option>
                              <option value="Franchise">Franchise</option>
                              <option value="Membership">Membership</option>
                              <option value="Workshop">Workshop</option>
                              <option value="Project Report">
                                Project Report
                              </option>
                              <option value="Industrial Report">
                                Industrial Report
                              </option>
                              <option value="Customized Report">
                                Customized Report
                              </option>
                              <option value="Edp">Edp</option>
                            </Input>
                            <FormFeedback className="text-left">
                              {errors.activity}
                            </FormFeedback>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Assigned To <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-8">
                            <Input
                              type="select"
                              className="custom-select"
                              id="assignTo"
                              onChange={(e) => setAssign(e.target.value)}
                              value={assign}
                              invalid={!!errors.assign_to}
                            >
                              <option>select</option>
                              {assignUser.map((asu) => (
                                <option value={asu.value} key={asu.value}>
                                  {asu.label}
                                </option>
                              ))}
                            </Input>
                            <FormFeedback className="text-left">
                              {errors.assign_to}
                            </FormFeedback>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Subject <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              invalid={!!errors.subject}
                            />
                            <FormFeedback className="text-left">
                              {errors.subject}
                            </FormFeedback>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Start Date &amp; Time <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6 form-group">
                                <Input
                                  type="date"
                                  value={startdate}
                                  onChange={(e) => setStartdate(e.target.value)}
                                  invalid={!!errors.start_date}
                                />
                                <FormFeedback className="text-left">
                                  {errors.start_date}
                                </FormFeedback>
                              </div>
                              <div className="col-md-6 form-group">
                                <div className="input-group clockpicker-example">
                                  <Input
                                    type="time"
                                    value={starttime}
                                    onChange={(e) =>
                                      setStarttime(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Priority(Intrested)
                          </label>
                          <div className="col-md-8">
                            <Input
                              type="select"
                              className="custom-select"
                              id="priority"
                              onChange={(e) => setPriority(e.target.value)}
                              value={priority}
                            >
                              <option value="">select</option>
                                    {priorityy?.map((item)=>
                                    {
                                      return(
                                        <option value={item?.title} key={item?.id}>{item?.title}</option>
                                      )
                                    })}
                            </Input>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Reminder Before
                          </label>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Input
                                  type="select"
                                  className="custom-select"
                                  id="reminder"
                                  onChange={(e) => setReminder(e.target.value)}
                                  value={reminder}
                                >
                                  <option value="">select</option>
                                  <option value="15">15 Min</option>
                                  <option value="30">30 Min</option>
                                  <option value="60">60 Min</option>
                                </Input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                            Remark <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-8">
                            <Input
                              type="textarea"
                              className="form-control"
                              rows="3"
                              invalid={!!errors.remark}
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            ></Input>
                              <FormFeedback className="text-left">
                                  {errors.remark}
                                </FormFeedback>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row form-group">
                          <div className="col-md-4 control-label col-form-label col-12 text-md-right" />
                          <div className="col-md-8">
                            <Button type="submit" color="primary">
                              {loading ? (
                                <FaSpinner className="fa-spin" />
                              ) : (
                                "Save"
                              )}
                            </Button>

                            <Link to="/lead/1/details">
                              <Button
                                type="button"
                                color="secondry"
                                className="ml-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setModal(false);
                                }}
                              >
                                Close
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Task;
