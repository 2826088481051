import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../components/MainWrapper";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import { getallPermissions } from "../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import Note from "./sub-component/Note";
import Axios from "axios";
import { FiEdit, FiCheckCircle } from "react-icons/fi";
import Task from "../components/lead/Task";
import TaskList from "../components/lead/sub-component/TaskList";
import TataPlayNote from "./sub-component/Tata-Play-Note";
import AddMembership from "../components/lead/addMembership";
const Details = ({ history }) => {
  const [updateName, setUpdateName] = useState("N/A");
  const [isEditing, setIsEditing] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [wsStatus, setWsStatus] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [page, setPagee] = useState(0);

  const location = useLocation();
  const pageValue = new URLSearchParams(location.search).get('page');

  const associateTyp = new URLSearchParams(location.search).get('associateType');
  const serviceCategor = new URLSearchParams(location.search).get('serviceCategory');
  const dispositiontype = new URLSearchParams(location.search).get('dispositiontype');
  const disposition = new URLSearchParams(location.search).get('disposition');
  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const token = useMemo(() => auth?.token, [auth]);
  const dispatch = useDispatch();
  const { id } = useParams();
  let incID = parseInt(id);
  const permissions = useMemo(() => permission ?? [], [permission]);
  const { users, mssg } = useSelector(({ crmUser }) => crmUser);
  const { taskDeleteStatus } = useSelector(({ task }) => task);
  const user = useMemo(() => users, [users, mssg]);
  useEffect(() => {
    if (pageValue) {
      setPagee(pageValue)
    }
  }, [])
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);
  useEffect(() => {
    Axios.get(`/api/crm/get-consultant-details/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLeadDetails(res.data.consultant);
      })
      .catch((err) => console.log(err));
  }, [id, token]);

  useEffect(() => {
    Axios.get(`/api/crm/fetch-lead-task/${id}?type=consultant`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setTaskList(res.data?.task);
      })
      .catch((err) => console.log(err));
  }, [wsStatus, taskDeleteStatus]);
  return (
    <>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Consultant Details</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <div className="card">
                  <div className="card-header btn-header p-2">
                    <div className="left-box-tools">
                      <Link
                        to={`/consultant?page=${page}&associateType=${associateTyp}&serviceCategory=${serviceCategor}&dispositiontype=${dispositiontype}&disposition=${disposition}`}
                        className="btn btn-secondary btn-sm pl-1 pr-1"

                      >
                        <i className="fa fa-mail-reply" aria-hidden="true" />{" "}
                        Back
                      </Link>
                      {permissions?.find(
                        (ps) => ps.name === "consultant-edit"
                      ) != null ? (
                        <button className="btn btn-secondary btn-sm pr-1 ml-1">
                          <i className="fa fa-pencil" />

                          <Link
                            to={`/consultant-edit/${leadDetails?.id}`}
                            className="mr-2 text-info"
                          >
                            Edit
                          </Link>
                        </button>
                      ) : null}

                      {permissions?.find(
                        (ps) => ps.name === "consultant-edit"
                      ) != null ? (
                        <button className="btn btn-secondary btn-sm pr-1 ml-1">
                          <i className="fa fa-pencil" />

                          <Link
                            to={`/consultant-edit-detailed/${leadDetails?.id}`}
                            className="mr-2 text-info"
                          >
                            Edit Detailed
                          </Link>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="card-body p-2">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="small-details">
                          <div className="details">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-md-flex justify-content-between">
                                  <div className="order-md-2">
                                    <p className="mb-2 font-thin small">
                                      {_.get(leadDetails?.name, "name", "N/A")}
                                    </p>
                                  </div>
                                  <div className="order-md-1 d-flex align-items-center">
                                    <h4 className="font-weight-bold mb-0">
                                      {_.get(leadDetails, "name", "N/A")}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row lead-info">
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Assigned To
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  {leadDetails?.agent_id ? (
                                    <span>
                                      {leadDetails?.created_by_crm_user?.name}
                                    </span>
                                  ) : (
                                    <span>
                                      {leadDetails?.assigned_user?.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Email
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "email", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Name
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "name", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Mobile
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "mobile", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Sector
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  {leadDetails?.industry?.map((ind, index) => (
                                    <span id="l_Code">
                                      {ind?.industrydata?.name}
                                    </span>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Industry
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {leadDetails?.industry?.map((ind) =>
                                      ind?.subindustrydata?.map(
                                        (sec, index) => (
                                          <span id="l_Code" key={index}>
                                            {sec?.sub_industry?.name}
                                          </span>
                                        )
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Source
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "source", "N/A")}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Contact Person
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(
                                      leadDetails,
                                      "contact_person",
                                      "N/A"
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Designation
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "designation", "N/A")}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Pincode
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "pincode", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  City
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "city", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  State
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "state", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Address
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "address", "N/A")}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="font-weight-bold d-inline-block label-tag">
                                Website
                              </div>
                              <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                :
                              </div>
                              <div className="d-inline-block data-tag">
                                <a
                                  href={
                                    !leadDetails?.website_url?.startsWith(
                                      "https"
                                    )
                                      ? `https://${leadDetails?.website_url}`
                                      : leadDetails?.website_url
                                  }
                                  target="_blank"
                                >
                                  view
                                </a>
                              </div>
                            </div>

                            <div className="col-sm-12 mb-2 mt-2">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold mt-0 mb-0 inline">
                                  General Notes
                                </h4>
                              </div>
                              <hr className="my-0" />
                            </div>
                            <div className="col-md-12 editable-row mb-2">
                              <div className="remark-box bg-secondary p-2">
                                {_.get(leadDetails, "remarks", "N/A")}
                              </div>
                            </div>

                            {/* <div className="row lead-info">
                              <div className="col-md-6">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Email
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(leadDetails, "email", "N/A")}
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {leadDetails?.type == 11 ? (
                          <TataPlayNote id={id} />
                        ) : (
                          <Note id={id} permissions={permissions} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <TaskList
                  taskList={taskList}
                  setWsStatus={setWsStatus}
                  type="consultant"
                />
              </div>

              <div className="col-lg-3">
                <div className="accordion" id="ActionsAccordion">
                  <div className="card mb-3">
                    <div className="card-header p-0" id="heading-1">
                      <Link
                        className="card-title m-0 h5 text-white d-block p-2"
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapse-3"
                        aria-expanded="true"
                        aria-controls="collapse-3"
                      >
                        Quick Actions
                      </Link>
                    </div>
                    <div
                      id="collapse-3"
                      className="collapse show"
                      aria-labelledby="heading-1"
                      data-parent="#ActionsAccordion"
                    >
                      <div className="card-body p-2">
                        <div className="row">
                          <div
                            id="addSourcePop"
                            className="dv3 dvPopB1"
                            style={{ display: "none" }}
                          >
                            <div className="arrowB"></div>
                            <div className="arrow-borderB"></div>
                          </div>

                          <div className="col-md-6 mb-2">
                            <Task
                              leadDetail={leadDetails}
                              setWsStatus={setWsStatus}
                              type="consultant"
                            />
                          </div>
                          <div className="col-md-6 mb-2">
                            <AddMembership
                              leadDetail={leadDetails}
                              setWsStatus={setWsStatus}
                              type="consultant"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </>
  );
};

export default Details;
