import Axios from "axios";

export const getAllList = (token) => {
    return {
        type: "ROLES_LIST",
        payload: Axios.get(`/api/crm/getlist`, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err),
    };
};

export const assignPermissions = (data) => {
    return {
        type: "ASSIGN_PERMISSIONS",
        payload: Axios.post(`/api/crm/assign-permission`, data, {
            headers: { Authorization: `Bearer ${data.token}` },
        }).catch((err)=>err),
    };
};
