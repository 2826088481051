import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  CustomInput,
} from "reactstrap";
import { Fragment } from "react";
import { FiTrash, FiEdit, FiChevronDown } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus, FaSpinner } from "react-icons/fa";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import { useDispatch, useSelector } from "react-redux";
const CreateHunarIndia = () => {
  const [name, setName] = useState("");
  const [expertName, setExpertName] = useState("");

  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const [callingperson, setCallingPerson] = useState("");
  const [board, setBoard] = useState("");
  const [exam, setExam] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [registered, setRegistered] = useState("");
  const [callingremarks, setCallingRemarks] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD") || "");
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => auth);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("name", name);
    data.append("mobile", mobile);
    data.append("remarks", remarks);
    data.append("calling_remarks", callingremarks);
    data.append("calling_person", callingperson);
    data.append("board_exam", board);
    data.append("class", exam);
    data.append("registered_on", registered);

    setLoading(true);
    axios
      .post(`/api/crm/add-hunlead`, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setLoading(false);

        NotificationManager.success(res.data.message);

        setTimeout(() => {
          window.location.href = "/hunar-lead";
        }, 1000);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setLoading(false);
          setErrors(err?.response?.data?.errors);
          NotificationManager.error("Somthing went wrong");
        } else {
          setLoading(false);
          NotificationManager.error("Somthing went wrong");
        }
      });
  };

  return (
    <MainWrapper>
      <main className="content">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>Create Hunar India</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="bussinessName"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Name
                    </Label>
                    <Input
                      id="bussinessName"
                      className="col-md-7"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      invalid={!!errors.name}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile No
                    </Label>
                    <Input
                      id="mobile"
                      className="col-md-7"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      invalid={!!errors.mobile}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Calling Person
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      className="col-md-7"
                      value={callingperson}
                      onChange={(e) => setCallingPerson(e.target.value)}
                      invalid={!!errors.email}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Board/Exam
                    </Label>
                    <Input
                      type="input"
                      id="remarks"
                      className="col-md-7"
                      value={board}
                      onChange={(e) => setBoard(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Class
                    </Label>
                    <Input
                      type="input"
                      id="remarks"
                      className="col-md-7"
                      value={exam}
                      onChange={(e) => setExam(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Registered On
                    </Label>
                    <Input
                      type="input"
                      id="remarks"
                      className="col-md-7"
                      value={registered}
                      onChange={(e) => setRegistered(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Calling Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={callingremarks}
                      onChange={(e) => setCallingRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/hunar-lead")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </main>
    </MainWrapper>
  );
};

export default CreateHunarIndia;
