import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  createNoteApi,
  getListNote,
  updateNoteApi,
  NoteDelete,
  getListUserNote,
} from "../../../store/actions/NoteActions";
import { updateLeader } from "../../../store/actions/LeadActions";
import { Spinner } from "reactstrap";
import { FiEdit, FiTrash } from "react-icons/fi";
import Axios from "axios";
import { addActivity } from "../../../store/actions/ActivityActions";
import { NotificationManager } from "react-notifications";
import { Input } from "reactstrap";
import moment from "moment";
const Note = ({ leadId, permissions, userid }) => {
  const [editNoteId, setEditNoteId] = useState("");
  const [leadIdd, setLeadIdd] = useState("");
  const [noteListData, setNoteListData] = useState();
  const [title, setTitle] = useState("");
  const [editSwich, setEditSwich] = useState(false);
  const [activeTitle, setActiveTitle] = useState(true);
  const [disposition, setDisposition] = useState("");
  const [userNoteListData, setUserNoteListData] = useState();
  const [dispositionList, setDispositionList] = useState([]);
  const [activityType, setActivityType] = useState("Notes");
  const [note, setNote] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [source, setSource] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [leadStatuss, setLeadStatuss] = useState("");
  const [campaign, setCampaign] = useState("");
  const [initialSector, setInitialSector] = useState("");
  const [verticalType, setVerticalType] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [notify, setNotify] = useState(0);
  const { auth } = useSelector(({ auth }) => auth);
  const { details } = useSelector(({ leadPage }) => leadPage);
  const {
    error,
    createNoteData,
    createNoteLoading,
    createNoteStatus,
    Note,
    listStatus,
    updateNoteStatus,
    updateNoteLoader,
    loading,
    updateNote,
    createNote
  } = useSelector(({ note }) => note);
  const token = useMemo(() => auth?.token, [auth]);
  const { createActivityStatus } = useSelector(({ activity }) => activity);
  const { priorityy } = useSelector((state) => state.allActivityReducer);
  const dispatch = useDispatch();
  //handle title input show and hide
  const handleTitleSwich = (e) => {
    e.preventDefault();
    setActiveTitle(!activeTitle);
  };
  // handle note fields reset
  const handleCancelNote = (e) => {
    e.preventDefault();
    setActiveTitle(false);
    setTitle("");
    setNote("");
  };
  useEffect(() => {
    if (details?.lead?.mobile) {
      // dispatch(getLeadUser(details?.lead?.mobile, auth?.token));
      setLeadIdd(details?.lead?.id);
    }
  }, [details]);

  const getValues = ({
    label,
    activity_type,
    sourceType,
    leadstatus,
    priorityType,
    campaignType,
    industryType,
    verticalType,
    dispositionType
  }) => {
    handleSubmit({
      label,
      activity_type,
      sourceType,
      leadstatus,
      priorityType,
      campaignType,
      industryType,
      verticalType,
      dispositionType
    });
  };
  const handleSubmit = ({
    label,
    activity_type,
    sourceType,
    leadstatus,
    priorityType,
    campaignType,
    industryType,
    verticalType,
    dispositionType
  }) => {
    if (activityType || activity_type) {

      const inputData = new FormData();
      inputData.append("id", leadDetails?.id);
      inputData.append("assign_to", leadDetails?.assign_to);
      inputData.append("lead_date", leadDetails?.lead_date);
      inputData.append("full_name", leadDetails?.full_name);
      inputData.append("first_name", leadDetails?.first_name);
      inputData.append("last_name", leadDetails?.last_name);
      inputData.append("mobile", leadDetails?.mobile);
      inputData.append("company", leadDetails?.company);
      inputData.append("email", leadDetails?.email);
      inputData.append("address", leadDetails?.address);
      inputData.append("city", leadDetails?.city);
      inputData.append("service_id", leadDetails?.service_id);
      inputData.append("remark", leadDetails?.remark);
      inputData.append("email_notify", leadDetails?.email_notify);
      inputData.append("sms_notify", leadDetails?.sms_notify);
      inputData.append("visibility", leadDetails?.visibility);
      inputData.append("source_id", sourceType || source);
      inputData.append(
        "source_type",
        leadDetails?.source_type ? leadDetails?.source_type : ""
      );
      inputData.append("source_details", leadDetails?.source_details);
      inputData.append("status_id", leadstatus || leadStatuss);
      inputData.append("rating_id", leadDetails?.rating_id);
      inputData.append(
        "sector",
        JSON.stringify(leadDetails?.lead_sector) || subCategory
      );
      inputData.append(
        "industry",
        (industryType && JSON.stringify(industryType)) ||
        JSON.stringify(leadDetails?.lead_industry) ||
        subSubCategory
      );
      inputData.append("state", leadDetails?.state);
      inputData.append("country", leadDetails?.country);
      inputData.append("mobile", leadDetails?.mobile);
      inputData.append("disposition_type", leadDetails?.disposition_type);
      inputData.append("disposition", dispositionType || disposition);
      inputData.append("compains", campaignType || campaign);
      inputData.append("token", token);
      inputData.append("priority", priorityType || priority);
      inputData.append(
        "vertical_type",
        leadDetails?.verticalType || verticalType
      );
      dispatch(updateLeader(inputData, token));
      const data = {
        lead_id: leadIdd,
        activity_type: activity_type || activityType,
        description:
          label || description || priorityType || campaignType || verticalType,
      };
      dispatch(addActivity(data, token));
    }
  };
  const leadDetails = useMemo(() => details?.lead, [details]);
  useEffect(() => {
    setSubCategory(leadDetails?.lead_sector?.name);
  }, [leadDetails]);
  useEffect(() => {
    if (leadDetails) {
      setPriority(leadDetails?.priority);
      setSource(leadDetails?.source_id);
      setLeadStatuss(leadDetails?.status_id);
      setCampaign(leadDetails?.compains);
      setInitialSector(leadDetails?.sector);
      setVerticalType(leadDetails?.vertical_type);
      setDisposition(leadDetails?.disposition ?? "")
    }
  }, [leadDetails]);
  useEffect(() => {
    Axios.get(`/api/crm/get-disposition`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
      .then((res) => {
        setDispositionList(res.data?.dispositions);
      })
      .catch((err) => console.log(err));
  }, [auth?.token]);

  //Insert Note method
  const handleNoteSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: leadId,
      title,
      note,
      type: "lead-user",
      user_id: auth?.id,
      engaged: 1,
      disposition: disposition == 'null' ? "" : disposition,
    };
    dispatch(createNoteApi(data, auth?.token));

    if (createNoteStatus) {

      setTimeout(() => {
        dispatch(getListNote(leadId, auth.token));
        NotificationManager.success("Note added successfully");
        setTitle("");
        setNote("");
      }, 1000);
    }

  };

  useEffect(() => {
    if (createNoteStatus) {
      const data = {
        lead_id: leadId,
        activity_type: "Notes Added",
        description: note,
      };

      NotificationManager.success("Note Created Successfully", "Sccess");
      dispatch(addActivity(data, auth?.token));
      setTitle("");
      setNote("");
    }
  }, [createNoteStatus]);

  useEffect(() => {
    if (createActivityStatus) {
      window.location.reload();
    }
  }, [createActivityStatus]);

  useEffect(() => {
    if (listStatus) {
      setNoteListData(Note.note);
    }
  }, [listStatus, auth.token]);

  //get user-profile notes
  useEffect(() => {
    if (userid) {
      Axios.get(`/api/crm/employee-user-note/${userid}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
        .then((res) => setUserNoteListData(res.data.note))
        .catch((err) => console.log(err));
    }
  }, [userid]);

  // get list of employee note
  useEffect(() => {
    dispatch(getListNote(leadId, auth?.token));
    // dispatch(getListUserNote(userId, auth.token));
  }, [auth.token]);
  // handle Employee Delete Action
  const handleDelete = (e, deleteId) => {
    e.preventDefault();
    dispatch(NoteDelete({ ids: JSON.stringify(deleteId) }, auth.token));
    setTimeout(() => {
      dispatch(getListNote(leadId, auth.token));
      NotificationManager.success("Note Deleted Successfully", "Sccess");
    }, 1000);
  };
  // handle Employee Edition Action
  const handleEdit = (e, id) => {
    e.preventDefault();
    let editNote = Note.note.find((nt) => nt.id === id);
    console.log(id, "edited note");
    setEditNoteId(id);
    setTitle(editNote?.title);
    setNote(editNote?.note);
    if (editNote?.title) {
      setActiveTitle(true);
    }
    setEditSwich(!editSwich);
  };
  // handle Note Update
  const handleUpdateEmployeeNote = (e) => {
    e.preventDefault();
    const updateData = { editNoteId, id: leadId, title, note };
    setEditSwich(false);
    setEditNoteId("");
    setEditNoteId("");
    setTitle("");
    setNote("");
    setActiveTitle(false);
    dispatch(updateNoteApi(updateData, auth.token));
  };
  useEffect(() => {
    if (updateNoteStatus) {
      dispatch(getListNote(leadId, auth.token));

      NotificationManager.success("Note Updated Successfully", "Sccess");
    }
  }, [updateNoteStatus]);
  console.log(createNote?.errors, "createNote?.errors?.note?.[0]")
  return (
    <>
      <div className="note-area">
        <div className="row ">
          <div className="col-md-12">
            <div className="">
              <div className="form-group mb-2 d-flex align-items-center justify-content-between">
                <h5 className="inline mt-0">Disposition</h5>
                <Link
                  id="AddTitle"
                  to={`#`}
                  className="pull-right"
                  onClick={handleTitleSwich}
                >
                  Add a Title
                </Link>
              </div>

              <div className={`form-group`}>
                <select
                  id="paginate"
                  onChange={(e) => setDisposition(e.target.value ?? "")}
                  value={disposition}
                  className="custom-select"
                >
                  <option value="">Select</option>
                  {dispositionList
                    ? dispositionList.map((cr) => (
                      <option value={cr?.id} key={cr?.id}>
                        {cr?.remarks}
                      </option>
                    ))
                    : ""}
                </select>
                {createNote?.errors && Array.isArray(createNote.errors.disposition) && createNote.errors.disposition.length > 0 && (
                  <p className="text-danger">{createNote.errors.disposition[0]}</p>
                )}
              </div>

              <div
                className={`form-group ${activeTitle ? "" : "addTitleInput"}`}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add a Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
                {createNote?.errors && Array.isArray(createNote.errors.title) && createNote.errors.title.length > 0 && (
                  <p className="text-danger">{createNote.errors.title[0]}</p>
                )}
              </div>

              <div className="form-group">
                <textarea
                  className="form-control custom-select"
                  placeholder="Add a Note"
                  rows={5}
                  style={{ resize: "none" }}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
                {createNote?.errors && Array.isArray(createNote.errors.note) && createNote.errors.note.length > 0 && (
                  <p className="text-danger">{createNote.errors.note[0]}</p>
                )}
              </div>
              <div className="form-group text-right">
                {editSwich ? (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm"
                    onClick={handleUpdateEmployeeNote}
                  >
                    {updateNoteLoader ? <Spinner /> : "Update"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm"
                    onClick={handleNoteSubmit}
                  >
                    {createNoteLoading ? <Spinner /> : "Save"}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-secondary btn-sm ml-1"
                  onClick={handleCancelNote}
                >
                  {loading ? <Spinner /> : "Cancel"}
                </button>
              </div>
            </div>
            <hr />
            <div>
              {_.map(noteListData, (lt, i) => (
                <div
                  className="note-data mb-2 bg-secondary py-3 px-3 rounded"
                  key={i}
                >
                  {lt?.title && (
                    <div className="">
                      <p className="mb-1 font-weight-bold">{lt?.title}</p>
                    </div>
                  )}
                  <p className="mb-0">
                    Disposition:- {lt?.disposition?.remarks}
                  </p>
                  <p className="mb-0">{lt?.note}</p>
                  <div className="note-bottom small d-flex justify-content-between">
                    <div className="note-data-section">
                      <span className="text-primary">
                        {lt?.created_user?.name}
                      </span>
                      &nbsp;&nbsp;
                      <span className="text-muted">{moment(`${lt.created_at}`).format('YYYY-MM-DD hh:mm:ss a')}</span>
                    </div>
                    {/* <div className="d-flex right-links my-auto">
                      {permissions?.find((ps) => ps.name === "note-edit") !=
                      null ? (
                        <Link
                          id="EditNote"
                          className="tex-primary mr-1"
                          style={{ fontSize: 15 }}
                          to={``}
                          onClick={(e) => handleEdit(e, lt.id)}
                        >
                          <FiEdit />
                        </Link>
                      ) : null}

                      {permissions?.find((ps) => ps.name === "note-delete") !=
                      null ? (
                        <Link
                          id="RemoveNote"
                          className="text-danger"
                          style={{ fontSize: 15 }}
                          to={`#`}
                          onClick={(e) => handleDelete(e, lt.id)}
                        >
                          <FiTrash />
                        </Link>
                      ) : null}
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            {userNoteListData ? (
              <div>
                <h5>Lead User Notes</h5>
                {_.map(userNoteListData, (lt, i) => (
                  <div
                    className="note-data mb-2 bg-secondary py-3 px-3 rounded"
                    key={i}
                  >
                    {lt?.title && (
                      <div className="">
                        <p className="mb-1 font-weight-bold">{lt?.title}</p>
                      </div>
                    )}
                    <p className="mb-0">{lt?.note}</p>
                    <div className="note-bottom small d-flex justify-content-between">
                      <div className="note-data-section">
                        <span className="text-primary">
                          {lt?.created_user?.name}
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-muted">{lt.created_at}</span>
                      </div>
                      {/* <div className="d-flex right-links my-auto">
                      {permissions?.find((ps) => ps.name === "note-edit") !=
                      null ? (
                        <Link
                          id="EditNote"
                          className="tex-primary mr-1"
                          style={{ fontSize: 15 }}
                          to={``}
                          onClick={(e) => handleEdit(e, lt.id)}
                        >
                          <FiEdit />
                        </Link>
                      ) : null}

                      {permissions?.find((ps) => ps.name === "note-delete") !=
                      null ? (
                        <Link
                          id="RemoveNote"
                          className="text-danger"
                          style={{ fontSize: 15 }}
                          to={`#`}
                          onClick={(e) => handleDelete(e, lt.id)}
                        >
                          <FiTrash />
                        </Link>
                      ) : null}
                    </div> */}
                    </div>
                  </div>
                ))}
              </div>
            ) : ""}
          </div>
          {/* <div className="col-md-4">
            <div className="overflow-hidden rounded bg-white border">
              <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  Priority(Interested)
                </span>
              </div>
              <div className="p-2">
                <Input
                  type="select"
                  className="custom-select cursor-pointer"
                  value={priority}
                  onChange={(e) => {
                    setPriority(e.target.value);
                    setActivityType("Priority Updated");
                    getValues({
                      priorityType: e.target.value,
                      activity_type: "Priority Updated",
                    });
                    setNotify((prev) => prev + 1);
                  }}
                >
                  <option value="">select</option>
                  {priorityy?.map((item) => {
                    return (
                      <option value={item?.title} key={item?.id}>{item?.title}</option>
                    )
                  })}
                </Input>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Note;
