/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadsDetail,
  DmtDashboardCount,
} from "../store/actions/DashboardActions";

const DmtDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);

  useEffect(() => {
    dispatch(DmtDashboardCount(token, "consultant-reffer-count"));
  }, [token]);

  useEffect(() => {
    SetLead(leaddetails.convertedlead);
   
  }, [leaddetails, dashboardcount]);

 

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">
                          {dashboardcount?.todayConsultantRefferCount ?? 0}
                        </h3>
                        <div className="mb-0">Today Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiUser className="feather-lg text-primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">
                          {dashboardcount?.totalConsultantRefferCount ?? 0}
                        </h3>
                        <div className="mb-0">Total Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiUserCheck className="feather-lg text-warning" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default DmtDashboard;
