import React from "react";
import MainWrapper from "../MainWrapper";

import img from "../../assets/img/maintenance.gif";

const CrashPage = () => {
  return (
    <MainWrapper>
      <div className="d-flex mt-5 ">Coming Soon....</div>
    </MainWrapper>
  );
};

export default CrashPage;
