import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "../../components/MainWrapper";
import axios from "axios";
import {
  Container,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  Modal,
  ModalBody,
} from "reactstrap";
import { FiEdit, FiTrash } from "react-icons/fi";
import { FaPlus, FaList } from "react-icons/fa";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import _ from "lodash";
import { CSVLink } from "react-csv";
import Axios from "axios";
import Create from "./Create";
const SupplierPartnerLead = () => {
  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [paginate, setPaginate] = useState(10);
  const [modal, setModal] = useState(false);
  const [imgurl, setImgurl] = useState("");
  const [approveStatus, setApproveStatus] = useState(false);
  const dropdownOpenToggle = () =>
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `api/crm/supplier-partner-leads?sort=${sort}&page=${page}&search=${search}&paginate=${paginate}`
      )
      .then((res) => {
        setUsers(res.data.meetingList.data);
        setFrom(res.data.meetingList.from);
        setTo(res.data.meetingList.to);
        setTotal(res.data.meetingList.total);
        setPerPage(res.data.meetingList.per_page);
        setPermissions(res.data.permission);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  }, [sort, page, refresh, search, paginate, approveStatus]);

  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        users.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const csvHead = [
    [
      "Partner Name",
      "Name",
      "Email",
      "Mobile",
      "Business Type",
      "Purpose of Meeting",
      "Occupation",
      "Investment",
    ],
  ];
  const csvData = users.map((cv) => [
    cv?.franchise_user?.name,
    cv?.name,
    cv?.email,
    cv?.mobile,
    cv?.bussiness_type,
    cv?.purpose_of_meeting,
    cv?.occupation,
    cv?.investment,
  ]);
  const exportData = csvHead.concat(csvData);

  const handleImport = (e) => {
    e.preventDefault();
  };

  return (
    <MainWrapper>
      <main className="content pt-4">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between justify-content-center">
                <h4>Customer Meeting</h4>
                <div className="d-flex justify-content-between justify-content-center">
                  <select
                    id="paginate"
                    onChange={(e) => setPaginate(e.target.value)}
                    value={paginate}
                    // className="custom-select custom-select-page"
                    style={{ width: 135 }}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                  <InputGroup className="mr-2">
                    <Input
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>

                  <Create />
                  <ButtonDropdown
                    isOpen={dropdownOpen}
                    toggle={dropdownOpenToggle}
                  >
                    <DropdownToggle caret size="sm">
                      <FaList />
                      &nbsp;Users Tools
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      <DropdownItem tag="a" href="#">
                        Tool Name
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between justify-content-center">
                    <div>
                      <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                    <div>
                      <select
                        id="sort"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custom-select mr-1"
                        style={{ width: 135 }}
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <CSVLink
                        filename={"userList.csv"}
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To CSV"
                        data={exportData}
                      >
                        <i className="fa fa-file-excel-o" />
                      </CSVLink>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    onChange={handleMultiSelect}
                                    checked={
                                      deleteIds.length === users.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">
                                  Partner Name
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold">Mobile</th>
                                <th className="font-weight-bold">
                                  Bussiness Type
                                </th>
                                <th className="font-weight-bold">
                                  Purpose of Meeting
                                </th>
                                <th className="font-weight-bold">Occupation</th>
                                <th className="font-weight-bold">investment</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7}>
                                        <Loader loading={loading} />
                                      </td>
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {users.length ? (
                                      <Fragment>
                                        {users.map((is) => (
                                          <tr key={is.id}>
                                            <td>
                                              <CustomInput
                                                id={`default-${is.id}`}
                                                type="checkbox"
                                                checked={deleteIds.some(
                                                  (d) => d === is.id
                                                )}
                                              />
                                            </td>
                                            <td>
                                              <span className="text-primary">
                                                {is?.franchise_user?.name}
                                              </span>
                                            </td>
                                            <td>{is?.name}</td>
                                            <td>{is?.email}</td>
                                            <td>{is?.mobile}</td>
                                            <td>{is?.bussiness_type}</td>
                                            <td>{is?.purpose_of_meeting}</td>
                                            <td>{is?.occupation}</td>
                                            <td>{is?.investment}</td>
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={7}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        isOpen={modal && !_.isEmpty(imgurl) && !_.isNull(imgurl)}
        toggle={() => setModal(!modal)}
      >
        <ModalBody className="p-0 m-0">
          <img
            height="100%"
            width="100%"
            className="p-0 m-0"
            onContextMenu={(e) => e.preventDefault()}
            src={`/storage/${imgurl}`}
          />
        </ModalBody>
      </Modal>
    </MainWrapper>
  );
};

export default SupplierPartnerLead;
