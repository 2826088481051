import Axios from "axios";

// export const getRelatedToList = (token) => {
//     return {
//         type: "RELATED",
//         payload: Axios.get(`/api/crm/all-related`, {
//             headers: { Authorization: `Bearer ${token}` },
//         }).catch((err)=>err),
//     };
// };

// export const getSubRelatedToList = (ids, token) => {
//     return {
//         type: "SUB_RELATED",
//         payload: Axios.get(`/api/crm/getSubRelatedByRelatedId?id=${ids}`, {
//             headers: { Authorization: `Bearer ${token}` },
//         }).catch((err)=>err),
//     };
// };

export const getLeadListDetails = (token) => {
  return {
    type: "GET_LEAD",
    payload: Axios.get(`/api/crm/get-lead-list`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};



// export const getAllSubPriorityList = (ids, token) => {
//     return {
//         type: "GET_SUB_PRIORITY",
//         payload: Axios.get(`/api/crm/getall-subpriority?id=${ids}`, {
//             headers: { Authorization: `Bearer ${token}` },
//         }).catch((err)=>err),
//     };
// };

export const getListActivityData = (token) => {
  return {
    type: "LIST_ACTIVITY",
    payload: Axios.get(`/api/crm/list-activities`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getListReminderData = (token) => {
  return {
    type: "LIST_REMINDER",
    payload: Axios.get(`/api/crm/getallreminder`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

// export const getListCallPurpose = (token) => {
//     return {
//         type: "LIST_CALL_PURPOSE",
//         payload: Axios.get(`/api/crm/getcallpurpose`, {
//             headers: { Authorization: `Bearer ${token}` },
//         }).catch((err)=>err),
//     };
// };

export const getListContactType = (token) => {
  return {
    type: "LIST_CONTACT_TYPE",
    payload: Axios.get(`/api/crm/getcontacttype`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getListSourceDetails = (data) => {
  return {
    type: "SOURCE_DETAILS",
    payload: Axios.get(`/api/crm/getsourcedetails/${data[1]}`, {
      headers: { Authorization: `Bearer ${data[0]}` },
    }).catch((err) => err),
  };
};

// Service API Call
export const getServiceList = (token) => {
  return {
    type: "SERVICE_DETAILS",
    payload: Axios.get(`/api/crm/get-services/`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};
