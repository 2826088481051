import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import {
  getCrmUsersApi,
  createSourceApi,
  getSourceList,
  createLeadStatus,
  getLeadStatusList,
  createRating,
  leadRatingListData,
  createLead,
} from "../../store/actions/LeadActions";
import {
  getListContactType,
  getListSourceDetails,
  getServiceList,
} from "../../store/actions/CommonActions";
import { getPriority } from "../../store/actions/AllActivityActions";
import { FaSpinner, FaEdit } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router";
import _ from "lodash";
const Create = ({ history: { push } }) => {
  const dispatch = useDispatch();

  const {
    loading,
    errors,
    userStatus,
    leadData,
    createSource: createSourcee,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
    leadStatusLoading,
    leadStatusSuccess,
    addLeadStatus,
    leadsStatusListStatus,
    leadsStatus,
    addRatingLoading,
    addRatingStatus,
    addRating,
    leadRatingListingStatus,
    leadRatingListing,
    createLeadLoader,
    createLeadStatuss,
    newMobile,
  } = useSelector(({ lead }) => lead);
const {priorityy}=useSelector((state)=>state.allActivityReducer)
  const {
    listContactTypeStatus,
    listContactType,
    listSourceDetails,
    listSourceDetailsStatus,
    listService,
    listServiceStatus,
  } = useSelector((state) => state.common);
  const {
    auth: { token },
    isAuthenticated,
    permissions: { permission },
    campaigndetails,
  } = useSelector((state) => state.auth);
  const history = useHistory();
  const { auth } = useSelector(({ auth }) => auth);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [contactType, setContactType] = useState("");
  const [assignUser, setAssignUser] = useState([]);
  const [referd, setReferd] = useState(false);
  const [sources, setSources] = useState([]);
  const [sourceName, setSourceName] = useState("");
  const [campaign, setCampaign] = useState("");
  const [priority, setPriority] = useState("");
  const [addSource, setAddSource] = useState(false);
  const [leadStatus, setLeadStatus] = useState(false);
  const [rating, setRating] = useState(false);
  const [visibility, setVisibility] = useState("");
  const [sourceDetails, setSourceDetails] = useState([]);
  const [sourceDetailsData, setSourceDetailsData] = useState("");
  const [leadStatusData, setLeadStatusData] = useState("");
  const [leadStatusTitle, setLeadStatusTitle] = useState("");
  const [leadRatings, setLeadRatings] = useState("");
  const [leadRating, setLeadRating] = useState([]);
  const [leadGroupSource, setLeadGroupSource] = useState([]);
  const [leadGroupStatus, setLeadGroupStatus] = useState("");
  const [callType, setCallType] = useState("");
  const [leadGroupRating, setLeadGroupRating] = useState("");
  const [leadCustomerShare, setLeadCustomerShare] = useState([]);
  const [assignTo, setAssignTo] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [namePrefix, setNamePrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [service, setService] = useState("");
  const [remark, setRemark] = useState("");
  const [page, setPage] = useState(1);
  const [emailNotification, setEmailNotification] = useState(0);
  const [smsNotification, setSmsNotification] = useState(0);
  const [sourceType, setSourceType] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [state, setState] = useState("");
  const [newSector, setNewSector] = useState("");
  const [newIndustry, setNewIndustry] = useState("");
  const [country, setCountry] = useState("");

  const [disposition, setDisposition] = useState("");
  const [checkMobile, setCheckMobile] = useState();
  const [userData, setUserData] = useState();
  const [cast, setCast] = useState("");
  const [gender, setGender] = useState("");
  const [verticalType, setVerticalType] = useState("");
  const [verticalTypeList, setVerticalTypeList] = useState([]);
  const [dispositionList, setDispositionList] = useState([]);
  const permissions = useMemo(() => permission ?? [], [permission]);
  const [showValidation, setShowValidation] = useState(false);
  const handleSource = (e) => {
    e.preventDefault();
    setAddSource(!addSource);
  };
  const handLeadStatus = (e) => {
    e.preventDefault();
    setLeadStatus(!leadStatus);
  };
  const handleReating = (e) => {
    e.preventDefault();
    setRating(!rating);
  };

  useEffect(() => {
    dispatch(getCrmUsersApi(token));
    setAssignTo(auth?.id);
    setCampaign(campaigndetails ? campaigndetails : "");
    axios(`/api/crm/get-vertical-type`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        setVerticalTypeList(data.target);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [token]);
  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (userStatus) {
      setAssignUser(leadData.users);
    }
  }, [userStatus]);
  useEffect(() => {
    dispatch(getLeadStatusList(token));
  }, [token]);
  useEffect(() => {
    dispatch(leadRatingListData(token));
  }, [token]);
  useEffect(() => {
    dispatch(getServiceList(token));
  }, []);
  useEffect(() => {
    dispatch(getPriority(token));
  }, [token]);
  useEffect(() => {
    if (listServiceStatus) {
      setServiceList(listService.service);
    }
  }, [listServiceStatus]);
  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${5}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);

  const Other = { value: "1", label: "OTHER" };
  const data = sectors.concat(Other);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordata?industry=${e.value}`, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (leadsStatusListStatus) {
      setLeadStatusData(leadsStatus.leadstatus);
    }
  }, [leadsStatusListStatus]);

  useEffect(() => {
    if (leadRatingListingStatus) {
      setLeadRatings(leadRatingListing.leadRating);
    }
  }, [leadRatingListingStatus]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  React.useMemo(() => setMobile(newMobile), [newMobile]);

  useEffect(() => {
    if (listContactTypeStatus) {
      setContactTypeList(listContactType.reminder);
    }
  }, [listContactTypeStatus]);

  useEffect(() => {
    if (createSourceStatus) {
      NotificationManager.success("Source Created Successfully", "Sccess");
      setSources([...sources, createSourcee]);
      setAddSource(!addSource);
      setSourceName("");
    }
  }, [createSourceStatus]);

  const handleAddSource = (e) => {
    e.preventDefault();
    const data = { token, sourceName };
    dispatch(createSourceApi(data));
  };
  useEffect(() => {
    if (leadStatusSuccess) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadStatusData([...leadStatusData, addLeadStatus]);
      setLeadStatus(!leadStatus);
      setLeadStatusTitle("");
    }
  }, [leadStatusSuccess]);

  const handleAddLeadStatus = (e) => {
    e.preventDefault();
    const leadInputArr = {
      token,
      leadStatusTitle,
    };
    dispatch(createLeadStatus(leadInputArr));
  };
  useEffect(() => {
    if (addRatingStatus) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadRatings([...leadRatings, addRating]);
      setRating(!rating);
      setLeadRating("");
    }
  }, [addRatingStatus]);
  useEffect(() => {
    if (createLeadStatuss) {
      NotificationManager.success("Leads Added Successfully", "Success");
      dispatch({ type: "RESET_CREATE_LEAD_STATUS", payload: false });
      if (permissions.some((item) => item.name === "lead-list")) {
        push("/leads");
      } else {
        push("/leads");
      }
    }
  }, [createLeadStatuss]);

  const handleRatingAdd = (e) => {
    e.preventDefault();
    const ratingadd = { token, leadRating };

    dispatch(createRating(ratingadd));
  };
  useEffect(() => {
    dispatch(getListContactType(token));
  }, [token]);
  const handleMobile = (e) => {
    var mob = "";
    var minx = 0;
    var validChar = "";
    var arrmob = e.target.value;
    for (var i = 1; i <= arrmob.length; i++) {
      validChar = arrmob[i - 1];
      if (
        validChar / 1 == validChar &&
        (minx > 0 || (minx == 0 && validChar > 0))
      ) {
        mob += validChar.toString();
        minx++;
      }
      if (minx == 10) {
        minx = 0;
        if (i < arrmob.length) mob += ",";
      }
    }

    setMobile(mob);
  };
  useEffect(() => {
    if (mobile?.length == 10) {
      axios
        .get(`/api/crm/get-lead/${mobile}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserData(res?.data?.leads || res?.data?.user);
          setCheckMobile(res?.data?.leads?.mobile || res?.data?.user?.mobile);
        })
        .catch((err) => console.log(err));
    }
  }, [mobile]);

  const [error, setErrors] = useState({
    disposition: "",
    priority: "",
    campaign: "",
    subCategory: "",
    subSubCategory: "",
    verticalType: "",
    callType: "",
    pincode: "",
    leadGroupSource: "",
    newSector: "",
    newIndustry:""
  });
  const isformValid = () => {
    let error = {
      disposition: "",
      priority: "",
      campaign: "",
      subCategory: "",
      subSubCategory: "",
      verticalType: "",
      callType: "",
      pincode: "",
      leadGroupSource: "",
      newSector: "",
      newIndustry:""
    };
    let valid = true;

    if (_.isEmpty(newSector) && subCategory?.value == 1) {
      error["newSector"] = "New Sector field is required !!";
      valid = false;
    }
    if (_.isEmpty(newIndustry) && subCategory?.value == 1) {
      error["newIndustry"] = "New Industry field is required !!";
      valid = false;
    }
    if (_.isEmpty(leadGroupSource)) {
      error["leadGroupSource"] = "Source field is required !!";
      valid = false;
    }
    if (_.isEmpty(disposition)) {
      error["disposition"] = "disposition field is required !!";
      valid = false;
    }
    if (_.isEmpty(priority)) {
      error["priority"] = "priority field is required !!";
      valid = false;
    }
    if (_.isEmpty(campaign)) {
      error["campaign"] = "campaign field is required !!";
      valid = false;
    }
    if (_.isEmpty(subCategory)) {
      error["subCategory"] = "Sector field is required !!";
      valid = false;
    }
    if (_.isEmpty(subSubCategory) && subCategory?.value != 1) {
      error["subSubCategory"] = "Industry field is required !!";
      valid = false;
    }
    if (_.isEmpty(verticalType)) {
      error["verticalType"] = "Vertical Type field is required !!";
      valid = false;
    }
    if (_.isEmpty(callType)) {
      error["callType"] = "Call Type field is required !!";
      valid = false;
    }
    if (_.isEmpty(pincode)) {
      error["pincode"] = "Pincode field is required !!";
      valid = false;
    }

    setErrors(error);
    console.log(error, "errrrr");
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (showValidation && subCategory?.value != 1 ? true : isformValid()) {
      const inputData = new FormData();
      inputData.append("contact_type", contactType);
      inputData.append("assign_to", assignTo);
      inputData.append("lead_date", leadDate);
      inputData.append("full_name", fullName);
      inputData.append("first_name", firstName);
      inputData.append("last_name", lastName);
      inputData.append("phone", phone);
      inputData.append("calltype", callType);
      inputData.append("pincode", pincode);
      inputData.append("compains", campaign);
      inputData.append("company", company);
      inputData.append("email", email);
      inputData.append("address", address);
      inputData.append("city", city);
      inputData.append("service_id", service);
      inputData.append("remark", remark);
      inputData.append("email_notify", emailNotification);
      inputData.append("sms_notify", smsNotification);
      inputData.append("visibility", visibility);
      inputData.append("source_id", leadGroupSource?.value || "");
      inputData.append("source_type", sourceType ? sourceType : "");
      inputData.append("source_details", sourceDetailsData);
      inputData.append("status_id", leadGroupStatus);
      inputData.append("rating_id", leadGroupRating);
      inputData.append("vertical_type", verticalType);
      inputData.append(
        "sector",
        Object.keys(subCategory).length
          ? JSON.stringify({
            ...subCategory,
            label: subCategory.label,
            id: subCategory?.value || subCategory?.id,
          })
          : ""
      );

      inputData.append("other_sector", newSector || "");
      inputData.append("other_industry", newIndustry || "");
      if (subCategory?.value === "1") {
        inputData.append(
          "industry",
          JSON.stringify({
            label: "OTHER",
            value: 1,
            id: 1,
          })
        );
      } else {
        inputData.append(
          "industry",
          Object.keys(subSubCategory).length
            ? JSON.stringify({
              ...subSubCategory,
              label: subSubCategory?.label,
              value: subSubCategory?.value,
              id: subSubCategory?.value,
            })
            : ""
        );
      }
      inputData.append("state", state);
      inputData.append("country", country);
      inputData.append("mobile", mobile);
      inputData.append("disposition", disposition?.value || "");
      inputData.append("token", token);
      inputData.append("gender", gender);
      inputData.append("cast", cast);
      inputData.append("priority", priority);
      inputData.append("role_id", auth?.roles[0]?.id);
      
      dispatch(createLead(inputData, token));
    }
  };

  useEffect(() => {
    if (pincode.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
            setCountry(response?.data[0]?.PostOffice[0]?.Country);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  // OnChange method of sources
  const handleSourceOptGroup = (e) => {
    if (e.id === "ref") {
      setSourceType(e.id);
      setReferd(true);
      setLeadGroupSource(e);
      const data = [token, e.value];
      dispatch(getListSourceDetails(data));
    } else {
      setLeadGroupSource([]);
      setReferd(false);
    }
  };
  useEffect(() => {
    axios
      .get(`/api/crm/get-disposition`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => {
        setDispositionList(res.data?.dispositions);
      })
      .catch((err) => console.log(err));
  }, [auth?.token]);
  useEffect(() => {
    if (listSourceDetailsStatus) {
      setSourceDetails(listSourceDetails.sourceDetails);
    }
  }, [listSourceDetailsStatus]);
  const optgroups = [
    {
      label: "Others",
      options: sources ? sources : null,
    },
  ];

  useEffect(() => {
    const dispositionName = disposition?.value?.trim();
    if (
      dispositionName === "PNP" ||
      dispositionName === "Not-Connected" ||
      dispositionName === "B-Busy" ||
      dispositionName === "DED - DEAD CALL" ||
      dispositionName === "Invalid Number" ||
      dispositionName === "No Answer" ||
      dispositionName === "NOT AVAILABLE" ||
      dispositionName === "OUT OF NETWORK" ||
      dispositionName === "PHONE NOT PICK" ||
      dispositionName === "Switch Off *"
    ) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
  }, [disposition]);
  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>New Lead</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 mb-15 pb-5">
                          <div className="row pb-5">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Assigned To *
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    disabled={true}
                                    id="assignTo"
                                    // onChange={(e) =>
                                    //   setAssignTo(e.target.value)
                                    // }
                                    value={assignTo}
                                    invalid={!!errors?.assign_to}
                                  >
                                    <option value="">select</option>
                                    {assignUser.map((asu) => (
                                      <option value={asu.value} key={asu.value}>
                                        {asu.label}
                                      </option>
                                    ))}
                                  </Input>
                                  <FormFeedback className="text-left">
                                    {errors?.assign_to}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Lead Date
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control selectdate"
                                    name="lead_date"
                                    id="lead_date"
                                    value={leadDate}
                                    invalid={!!errors?.lead_date}
                                    disabled
                                  />
                                  <FormFeedback className="text-left">
                                    {errors?.lead_date}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Name *
                                </label>
                                <div className="col-lg-8">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        name="nameprefix"
                                        id="nameprefix"
                                        onChange={(e) => {
                                          setNamePrefix(e.target.value);
                                          if (e.target.value === "N/A") {
                                            setFullName(e.target.value);
                                          } else {
                                            setFullName("");
                                          }
                                        }}
                                        value={namePrefix}
                                      >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="N/A">N/A</option>
                                      </Input>
                                    </div>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="name"
                                      onChange={(e) =>
                                        setFullName(e.target.value)
                                      }
                                      value={fullName}
                                      disabled={namePrefix === "N/A"}
                                      invalid={!!errors?.full_name}
                                    />
                                    <FormFeedback className="text-left">
                                      {errors?.full_name}
                                    </FormFeedback>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Mobile *
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    placeholder="Mobile"
                                    id="mobile"
                                    value={mobile}
                                    onChange={handleMobile}
                                    invalid={!!errors?.mobile}
                                    className="position-relative"
                                  />
                                  {checkMobile === mobile && (
                                    <div className="my-2 cursor-pointer">
                                      {/* <a href={`/leads?mobile=${mobile}`}>
                                        {" "}
                                        This user already exist <FiEye />
                                      </a> */}
                                      <Link
                                        to={`/lead/${userData?.lead_id || userData?.id
                                          }/details`}
                                      >
                                        This user already exist <FiEye />
                                      </Link>
                                    </div>
                                  )}

                                  <FormFeedback className="text-left">
                                    {errors?.mobile}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Email Id
                                  <span className="text-danger"> *</span>
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    invalid={!!errors?.email}
                                  />
                                  <FormFeedback className="text-left">
                                    {errors?.email}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Disposition
                                </label>
                                <div className="col-lg-8">
                                 
                                  <Select
                                    id="paginate"
                                    onChange={(e) => setDisposition(e)}
                                    value={disposition}
                                    className="react-select-container col-md-12"
                                    classNamePrefix="react-select"
                                    options={dispositionList?.map?.((item) => ({
                                      value: item?.remarks,
                                      label: item?.remarks,
                                    }))}
                                  />
                                  {showValidation
                                    ? null
                                    : error.disposition && (
                                      <span className="text-danger">
                                        {error.disposition}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Call Type
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="input-group">
                                    <div className="form-control p-0 border-0">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) =>
                                          setCallType(e.target.value)
                                        }
                                        value={callType}
                                      >
                                        <option value="">select</option>
                                        <option value="Inbound">Inbound</option>
                                        <option value="Outbound">
                                          Outbound
                                        </option>
                                      </Input>
                                      {showValidation
                                        ? null
                                        : error.callType && (
                                          <span className="text-danger">
                                            {error.callType}
                                          </span>
                                        )}
                                    </div>
                                    {permissions.some(
                                      (item) =>
                                        item.name === "lead-status-create"
                                    ) && (
                                        <span className="input-group-append input-group-btn">
                                          <Link
                                            className="btn btn-default btn-flat openLeadStatusPop"
                                            to="#"
                                            onClick={handLeadStatus}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </Link>
                                        </span>
                                      )}
                                  </div>
                                  <div
                                    id="addLeadStatusPop"
                                    className="dv3 dvPopB"
                                    style={{
                                      display: leadStatus ? "" : "none",
                                    }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Lead Status
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Lead Status
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="leadStatus"
                                                          id="leadStatus"
                                                          value={
                                                            leadStatusTitle
                                                          }
                                                          onChange={(e) =>
                                                            setLeadStatusTitle(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="textcl w-100"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-left pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleAddLeadStatus}
                                            >
                                              {leadStatusLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeLeadStatusPop"
                                              onClick={handLeadStatus}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Product offered
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="input-group">
                                    <div className="form-control p-0 border-0">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) =>
                                          setLeadGroupRating(e.target.value)
                                        }
                                        value={leadGroupRating}
                                      >
                                        <option value="">Select Type</option>
                                        <option value="Incubatee (I)Membership">
                                          Incubatee (I)Membership
                                        </option>
                                        <option value="Incubatee (W)Workshop">
                                          Incubatee (W)Workshop
                                        </option>
                                        <option value="Incubatee (P)Prototype Project report">
                                          Incubatee (P)Prototype Project report
                                        </option>
                                        <option value="Incubatee (O)Report Volumes/Courses/Services/Customized Project report/Others">
                                          Incubatee (O)Report
                                          Volumes/Courses/Services/Customized
                                          Project report/Others
                                        </option>

                                        <option value="Corporate-Incubation/Business Networking">
                                          Corporate-Incubation/Business
                                          Networking
                                        </option>
                                        <option value="Associate-Incubation/Business Networking Membership">
                                          Associate-Incubation/Business
                                          Networking Membership
                                        </option>
                                        <option value="Membership">
                                          Membership
                                        </option>
                                        <option value="Prototype Report">
                                          Prototype Report
                                        </option>
                                        <option value="Customized Report">
                                          Customized Report
                                        </option>
                                        <option value="Workshop">
                                          Workshop
                                        </option>
                                        <option value="Course">Course</option>
                                      </Input>
                                    </div>
                                    {permissions.some(
                                      (item) =>
                                        item.name === "lead-product-create"
                                    ) && (
                                        <span className="input-group-append input-group-btn">
                                          <Link
                                            className="btn btn-default btn-flat openRatingPop"
                                            to="#"
                                            onClick={handleReating}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </Link>
                                        </span>
                                      )}
                                  </div>
                                  <div
                                    id="addRatingPop"
                                    className="dv3 dvPopB"
                                    style={{ display: rating ? "" : "none" }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Rating
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Rating
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="rating_name"
                                                          className="textcl w-100"
                                                          value={leadRating}
                                                          onChange={(e) =>
                                                            setLeadRating(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-left pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleRatingAdd}
                                            >
                                              {addRatingLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeRatingPop"
                                              onClick={handleReating}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Priority(Interested)
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={priority}
                                    onChange={({ target }) =>
                                      setPriority(target.value)
                                    }
                                  >
                                    {/* <option value="">select</option>
                                    <option value="Hot">Hot</option>
                                    <option value="Cold">Cold</option>

                                    <option value="Converted">Converted</option>
                                    <option value="Warm">Warm</option>
                                    <option value="Active">Active</option>
                                    <option value="Perspective">
                                      Perspective
                                    </option>
                                    <option value="Declined">Declined</option> */}
                                    <option value="">select</option>
                                    {priorityy?.map((item)=>
                                    {
                                      return(
                                        <option value={item?.title} key={item?.id}>{item?.title}</option>
                                      )
                                    })}
                                  </Input>
                                  {showValidation
                                    ? null
                                    : error.priority && (
                                      <span className="text-danger">
                                        {error.priority}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Campaigns
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={campaign}
                                    onChange={({ target }) =>
                                      setCampaign(target.value)
                                    }
                                    invalid={!!errors?.compains}
                                  >
                                    <option value="">select</option>
                                    <option value="workshop">Workshop</option>
                                    <option value="IID Sale">IID-Sale</option>
                                  </Input>
                                  {showValidation
                                    ? null
                                    : error.campaign && (
                                      <span className="text-danger">
                                        {error.campaign}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Pincode
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="custom-select"
                                    value={pincode}
                                    onChange={({ target }) =>
                                      setPinCode(target.value)
                                    }
                                    invalid={!!errors?.pincode}
                                  />
                                  {showValidation
                                    ? null
                                    : error.pincode && (
                                      <span className="text-danger">
                                        {error.pincode}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  City
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="city"
                                    id="city"
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                  ></Input>
                                </div>
                              </div>

                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Investment Upto
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    name="service"
                                    id="service"
                                    onChange={(e) => setService(e.target.value)}
                                    value={service}
                                  >
                                    <option value=""> Select </option>
                                    <option value="0-10 Lakh">0-10 Lakh</option>
                                    <option value="10-25 Lakh">
                                      10-25 Lakh
                                    </option>
                                    <option value="25-50 Lakh">
                                      25-50 Lakh
                                    </option>
                                    <option value="50-1 crore">
                                      50-1 crore
                                    </option>

                                    <option value="1-5 crore">1-5 crore</option>
                                    <option value="Above 5 crore">
                                      Above 5 crore
                                    </option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  State
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="state"
                                    id="state"
                                    onChange={(e) => setState(e.target.value)}
                                    value={state}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Sector
                                </label>
                                <div className="col-lg-8">
                                  <>
                                    <Select
                                      options={[...data]}
                                      onChange={handleSector}
                                      value={subCategory}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                    />
                                    {showValidation
                                      ? null
                                      : error.subCategory && (
                                        <span className="text-danger">
                                          {error.subCategory}
                                        </span>
                                      )}
                                  </>
                                </div>
                              </div>

                              {subCategory?.value == "1" && (
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Add New Sector
                                  </label>
                                  <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control col-lg-8"
                                    rows="4"
                                    name="newSector"
                                    id="newSector"
                                    onChange={(e) =>
                                      setNewSector(e.target.value)
                                    }
                                    value={newSector}
                                  ></Input>
                                 
                                  {(
                                    <span className="text-danger">
                                      {error.newSector}
                                    </span>
                                  )}
                                   </div>
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Industry
                                </label>
                                {subCategory?.value == "1" ? (
                                   <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      className="form-control col-lg-8"
                                      rows="4"
                                      name="industry"
                                      id="industry"
                                      onChange={(e) =>
                                        setNewIndustry(e.target.value)
                                      }
                                      value={newIndustry}
                                    ></Input>
                                     {(
                                    <span className="text-danger">
                                      {error.newIndustry}
                                    </span>
                                  )}
                                 </div>
                                ) : (
                                  <div className="col-lg-8">
                                    <>
                                      <Select
                                        options={[...industry]}
                                        onChange={(e) => setSubSubCategory(e)}
                                        value={subSubCategory}
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                      />
                                      {showValidation
                                        ? null
                                        : error.subSubCategory && (
                                          <span className="text-danger">
                                            {error.subSubCategory}
                                          </span>
                                        )}
                                    </>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Vertical Type
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={verticalType}
                                    onChange={(e) =>
                                      setVerticalType(e.target.value)
                                    }
                                  >
                                    <option value="">select</option>
                                    <option value="Membership">
                                      Membership
                                    </option>
                                    <option value="Workshop">Workshop</option>
                                    <option value="Project_Report">
                                      Project Report
                                    </option>
                                    <option value="Industrial_Report">
                                      Industrial Report
                                    </option>
                                    <option value="Courses">Courses</option>
                                    <option value="Franchise">Franchise</option>
                                    <option value="Startup">Startup</option>

                                    <option value="Edp">Edp</option>
                                  </Input>
                                  {showValidation
                                    ? null
                                    : error.verticalType && (
                                      <span className="text-danger">
                                        {error.verticalType}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Whatsapp No.
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Gender
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={gender}
                                    onChange={({ target }) =>
                                      setGender(target.value)
                                    }
                                  >
                                    <option value="">select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Cast
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={cast}
                                    onChange={({ target }) =>
                                      setCast(target.value)
                                    }
                                  >
                                    <option value="">select</option>
                                    <option value="GENERAL">GENERAL</option>
                                    <option value="OBC">OBC</option>
                                    <option value="SC">SC</option>
                                    <option value="ST">ST</option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Company
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="company"
                                    id="company"
                                    onChange={(e) => setCompany(e.target.value)}
                                    value={company}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Source
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="form-control p-0 border-0">
                                        <Select
                                          options={optgroups}
                                          onChange={(e) => {
                                            handleSourceOptGroup(e);
                                            setLeadGroupSource(e);
                                          }}
                                          value={leadGroupSource}
                                          id="ref"
                                        />
                                        {showValidation
                                          ? null
                                          : error.leadGroupSource && (
                                            <span className="text-danger">
                                              {error.leadGroupSource}
                                            </span>
                                          )}
                                      </div>

                                      {permissions.some(
                                        (item) =>
                                          item.name === "lead-source-create"
                                      ) && (
                                          <span className="input-group-append input-group-btn">
                                            <Link
                                              className="btn btn-default btn-flat openSourcePop"
                                              to="#"
                                              onClick={handleSource}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </Link>
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <div
                                    id="addSourcePop"
                                    className="dv3 dvPopB"
                                    style={{ display: addSource ? "" : "none" }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Source
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Source Name
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="source_name"
                                                          id="source_name"
                                                          value={sourceName}
                                                          onChange={(e) =>
                                                            setSourceName(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="textcl w-100"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleAddSource}
                                            >
                                              {createSourceLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeSourcePop"
                                              onClick={handleSource}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {referd ? (
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Source Details
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      onChange={(e) =>
                                        setSourceDetailsData(e.target.value)
                                      }
                                      value={sourceDetailsData}
                                    >
                                      <option value="">select</option>
                                      {sourceDetails
                                        ? sourceDetails.map((srl) => (
                                          <option
                                            value={srl.value}
                                            key={srl.value}
                                          >
                                            {srl.label}
                                          </option>
                                        ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {/* {dispositiontype == "Not_Connected" ? (
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Not Connected
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      name="service"
                                      id="service"
                                      onChange={(e) =>
                                        setDisposition(e.target.value)
                                      }
                                      value={service}
                                    >
                                      <option value=""> Select </option>

                                      <option value="Ringing">Ringing</option>
                                      <option value="Switch_Off">
                                        Switch Off
                                      </option>
                                      <option value="Invalid_Number">
                                        Invalid Number
                                      </option>
                                      <option value="Call_Rejected">
                                        Call Rejected
                                      </option>
                                    </Input>
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                          </div>

                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Address
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="4"
                                    name="address"
                                    id="address"
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                  ></Input>
                                </div>
                              </div>
                            </div>


                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Country
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="country"
                                    id="country"
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                  ></Input>
                                </div>
                              </div>
                            </div>


                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  General Notes
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="4"
                                    name="remark"
                                    id="remark"
                                    onChange={(e) => setRemark(e.target.value)}
                                    value={remark}
                                  ></Input>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row mb-2 mb-1">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right"></label>
                                <div className="col-lg-8">
                                  <div className="row">
                                    <div className="col-md-12"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group ">
                        <div className="col-lg-12 text-center mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary mr-3"
                          >
                            {createLeadLoader ? (
                              <FaSpinner className="fa-spin" />
                            ) : (
                              "Save"
                            )}
                          </button>
                          <Link
                            to="/leads"
                            type="button"
                            className="btn btn-secondary"
                          >
                            Close
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Create;
