import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import SMS from "../utils/SMS";
import Mail from "../utils/Mail";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import {
  getEngagedLeads,
  leadDestroy,
  getSourceList,
} from "../../store/actions/LeadActions";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { sectors, projects } from "../../store/actions/MemberShipActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
const EngagedAgents = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
    permissions: { permission },
    campaigndetails,
  } = useSelector((state) => state.auth);

  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  const {
    leadsLoader,
    error,
    engagedAgents,
    leadDestroyStatus,
    getSources,
    getSourcesStatus,
    leadsStatus,
  } = useSelector((state) => state.lead);
  const { auth } = useSelector((state) => state.auth);
  const {
    sectors: { subcategory },
    projects: { project_reports },
  } = useSelector((state) => state.memberShip);
  const [leadsList, setLeadsList] = useState([]);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(5);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [sectorSearch, setSectorSearch] = useState("");
  const [industrySearch, setIndustrySearch] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [project, setProject] = useState("");
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState("");
  const [leadDate, setLeadDate] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      getEngagedLeads(
        token,
        paginate,
        search,
        page,
        sort,
        sectorSearch,
        project,
        source,
        leadDate,
        auth.id,
        campaigndetails
      )
    );
  }, [
    token,
    refresh,
    paginate,
    search,
    sort,
    page,
    sectorSearch,
    project,
    source,
    leadDate,
  ]);

  useEffect(() => {
    if (leadsStatus) {
      setLeadsList(engagedAgents.leads ? engagedAgents.leads.data : []);
      setFrom(engagedAgents.leads ? engagedAgents.leads.from : null);
      setTo(engagedAgents.leads ? engagedAgents.leads.to : null);
      setTotal(engagedAgents.leads ? engagedAgents.leads.total : null);
      setPerPage(engagedAgents.leads ? engagedAgents.leads.per_page : null);
    }
  }, [leadsStatus, engagedAgents]);
  useEffect(() => {
    dispatch(sectors(token));
  }, [token]);
  useEffect(() => {
    if (!_.isNull(sectorSearch) && sectorSearch) {
      dispatch(projects(token, [sectorSearch]));
    }
  }, [token, sectorSearch]);
  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);
  const sectorData = useMemo(() => subcategory, [subcategory]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  const handleProjectChange = (e) => {
    const { value } = e.target;
    setProject(value);
  };

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };

  useEffect(() => {
    const data1 = (leadsList ? leadsList : []).filter(
      (a) => a.compains == campaigndetails
    );
    setData(data1);
  }, [isAuthenticated, leadsList, campaigndetails, search]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="c-card-top">
                    <div className="c-card-top-title">
                      <h4 className="mb-0"> Engaged Leads</h4>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                        </div>
                        <Input
                          placeholder="Search here..."
                          value={search}
                          onChange={handleSearch}
                          className="c-form-control-serch"
                        />
                        <div className="input-group-append">
                          <button
                            title="Advance Search"
                            onClick={() => {
                              advanceSearch
                                ? setAdvanceSearch(false)
                                : setAdvanceSearch(true);
                            }}
                            id="c_searchBlock_toggle"
                            className="btn btn-secondary btn-sm c-card-top-block-icon"
                            type="button"
                          >
                            <i
                              className="fa fa-angle-down fa-2x text-dark"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            className="btn btn-secondary btn-sm"
                            type="button"
                            onClick={handleSerchByClick}
                          >
                            GO
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          advanceSearch ? "c-card-top-block" : "d-none"
                        }
                        id="c_searchBlock"
                      >
                        <div className="c-card-top-block-sec">
                          <div className="c-card-top-block-item">
                            <label>Sector</label>
                            <Input
                              type="select"
                              className="custom-select"
                              onChange={(e) => setSectorSearch(e.target.value)}
                              value={sectorSearch}
                            >
                              <option value="">Select</option>
                              {sectorData
                                ? sectorData.map((sec, i) => {
                                    return (
                                      <option value={sec.value} key={i}>
                                        {sec.label}
                                      </option>
                                    );
                                  })
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Industry</label>
                            <Input
                              className="custom-select"
                              type="select"
                              id="project"
                              onChange={handleProjectChange}
                            >
                              <option value="">Select</option>
                              {subSubCategories
                                ? subSubCategories.map((sub, i) => {
                                    return (
                                      <option value={sub.value} key={i}>
                                        {sub.label}
                                      </option>
                                    );
                                  })
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Sources</label>
                            <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) => setSource(e.target.value)}
                            >
                              <option value="">select</option>
                              {sources
                                ? sources.map((sou, i) => (
                                    <option value={sou?.value} key={i}>
                                      {sou?.label}
                                    </option>
                                  ))
                                : null}
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Lead Date</label>
                            <Input
                              type="date"
                              placeholder="Lead Date"
                              onChange={(e) => setLeadDate(e.target.value)}
                            />
                          </div>
                          <div className="c-card-top-block-action">
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Reset
                            </button>
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-card-top-buttons"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.No</th>
                                <th className="font-weight-bold">Full Name</th>
                                <th className="font-weight-bold">Campaign</th>
                                <th className="font-weight-bold">Source</th>
                                <th className="font-weight-bold">Lead Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leadsLoader ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={leadsLoader} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {data
                                    ? data.map((leadsl, i) => (
                                        <tr key={Math.random()}>
                                          <td className="pl-4">{i + 1}</td>
                                          <td>
                                            <Link
                                              to={`/lead/${leadsl.id}/details`}
                                            >
                                              {leadsl.full_name}
                                            </Link>
                                          </td>

                                          <td>{leadsl.compains}</td>

                                          <td>
                                            {leadsl.lead_source
                                              ? leadsl.lead_source.name
                                              : ""}
                                          </td>
                                          <td>{leadsl.created_at}</td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default EngagedAgents;
