import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getSourceList,
  createLeadStatus,
  getLeadStatusList,
  createRating,
  updateLeader,
  leadbyid,
  getCrmUsersApi,
  leadRatingListData,
  changeReducerState,
  createSourceApi,
} from "../../store/actions/LeadActions";
import { getListContactType } from "../../store/actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
import { getPriority } from "../../store/actions/AllActivityActions";
const Edit = ({ history: { push }, match }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const {priorityy}=useSelector((state)=>state.allActivityReducer)
  const {
    loading,
    errors,
    userStatus,
    leadData,
    createSource,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
    leadStatusLoading,
    leadStatusSuccess,
    addLeadStatus,
    leadsStatusListStatus,
    leadsStatus,
    addRatingLoading,
    addRatingStatus,
    addRating,
    leadRatingListingStatus,
    leadRatingListing,
    updateLeadLoader,
    updateLeadStatus,
    updateLeads,
    leadById,
    leadByIdLoader,
    leadByIdStatus,
  } = useSelector((state) => state.lead);
  const {
    listContactTypeStatus,
    listContactType,
    listSourceDetails,
    listSourceDetailsStatus,
  } = useSelector((state) => state.common);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [contactType, setContactType] = useState("");
  const [assignUser, setAssignUser] = useState([]);
  const [sources, setSources] = useState([]);
  const [sourceName, setSourceName] = useState("");
  const [pincode, setPinCode] = useState("");
  const [addSource, setAddSource] = useState(false);
  const [leadStatus, setLeadStatus] = useState(false);
  const [rating, setRating] = useState(false);
  const [visibility, setVisibility] = useState("");
  const [leadStatusData, setLeadStatusData] = useState("");
  const [leadStatusTitle, setLeadStatusTitle] = useState("");
  const [leadRatings, setLeadRatings] = useState("");
  const [leadRating, setLeadRating] = useState([]);
  const [leadGroupSource, setLeadGroupSource] = useState({});
  const [leadGroupStatus, setLeadGroupStatus] = useState("");
  const [leadGroupRating, setLeadGroupRating] = useState("");
  const [leadCustomerShare, setLeadCustomerShare] = useState([]);
  const [assignTo, setAssignTo] = useState("");
  const [leadDate, setLeadDate] = useState("");
  const [namePrefix, setNamePrefix] = useState("");
  const [ids, setIds] = useState("");
  const [campaign, setCampaign] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [service, setService] = useState("");
  const [remark, setRemark] = useState("");
  const [emailNotification, setEmailNotification] = useState("");
  const [smsNotification, setSmsNotification] = useState("");
  const [referd, setReferd] = useState(false);
  const [sourceDetails, setSourceDetails] = useState([]);
  const [sourceDetailsData, setSourceDetailsData] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [disposition, setDisposition] = useState("");
  const [priority, setPriority] = useState("");
  const [verticalType, setVerticalType] = useState("");
  const [verticalTypeList, setVerticalTypeList] = useState([]);
  const [dispositionList, setDispositionList] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/crm/get-disposition`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDispositionList(res.data?.dispositions);
      })
      .catch((err) => console.log(err));
  }, [token]);
  const handleSource = (e) => {
    e.preventDefault();
    setAddSource(!addSource);
  };
  useEffect(() => {
    dispatch(getPriority(token));
  }, [token]);
  const handLeadStatus = (e) => {
    e.preventDefault();
    setLeadStatus(!leadStatus);
  };
  const handleReating = (e) => {
    e.preventDefault();
    setRating(!rating);
  };
  const handleVisibility = (e) => {
    setVisibility(e.target.value);
  };

  // OnChange method of sources
  const handleSourceOptGroup = (value) => {
    setSourceType("");
    setLeadGroupSource(value);
  };
  useEffect(() => {
    if (listSourceDetailsStatus) {
      setSourceDetails(listSourceDetails.sourceDetails);
    }
  }, [listSourceDetailsStatus]);
  // Supports `optgroup`s with their own array of options.
  const optgroups = [
    {
      label: "Others",
      options: sources ? sources : null,
    },
  ];
  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${5}`)
      .then((res) => {
        setSectors(res.data.subCategories);
     
      })
      .catch((err) => console.log(err));
    axios(`/api/crm/get-vertical-type`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        setVerticalTypeList(data.target);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [token]);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordata?industry=${e.value}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(getListContactType(token));
  }, [token]);
  useEffect(() => {
    dispatch(getCrmUsersApi(token));
  }, [token]);
  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (userStatus) {
      setAssignUser(leadData.users);
    }
  }, [userStatus]);
  useEffect(() => {
    dispatch(getLeadStatusList(token));
  }, [token]);
  useEffect(() => {
    dispatch(leadRatingListData(token));
  }, [token]);
  useEffect(() => {
    if (leadsStatusListStatus) {
      setLeadStatusData(leadsStatus.leadstatus);
    }
  }, [leadsStatusListStatus]);

  useEffect(() => {
    if (leadRatingListingStatus) {
      setLeadRatings(leadRatingListing.leadRating);
    }
  }, [leadRatingListingStatus]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(
        getSources.source
          ? getSources.source.map((v) => ({ ...v, id: null }))
          : null
      );
    }
  }, [getSourcesStatus]);
  useEffect(() => {
    if (listContactTypeStatus) {
      setContactTypeList(listContactType.reminder);
    }
  }, [listContactTypeStatus]);

  useEffect(() => {
    if (createSourceStatus) {
      NotificationManager.success("Source Created Successfully", "Sccess");
      setSources([...sources, createSource]);
      setAddSource(!addSource);
      setSourceName("");
    }
  }, [createSourceStatus]);

  const handleAddSource = (e) => {
    e.preventDefault();
    const data = { token, sourceName };
    dispatch(createSourceApi(data));
  };
  useEffect(() => {
    if (leadStatusSuccess) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadStatusData([...leadStatusData, addLeadStatus]);
      setLeadStatus(!leadStatus);
      setLeadStatusTitle("");
    }
  }, [leadStatusSuccess]);

  const handleAddLeadStatus = (e) => {
    e.preventDefault();
    const leadInputArr = {
      token,
      leadStatusTitle,
    };
    dispatch(createLeadStatus(leadInputArr));
  };
  useEffect(() => {
    if (addRatingStatus) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadRatings([...leadRatings, addRating]);
      setRating(!rating);
      setLeadRating("");
    }
  }, [addRatingStatus]);

  const handleRatingAdd = (e) => {
    e.preventDefault();
    const ratingadd = { token, leadRating };
    dispatch(createRating(ratingadd));
  };
  const handleMobile = (e) => {
    var mob = "";
    var minx = 0;
    var validChar = "";
    var arrmob = e.target.value;
    for (var i = 1; i <= arrmob.length; i++) {
      validChar = arrmob[i - 1];
      if (
        validChar / 1 == validChar &&
        (minx > 0 || (minx == 0 && validChar > 0))
      ) {
        mob += validChar.toString();
        minx++;
      }
      if (minx == 10) {
        minx = 0;
        if (i < arrmob.length) mob += ",";
      }
    }
    setMobile(mob);
  };
  useEffect(() => {
    if (updateLeadStatus) {
      NotificationManager.success("Updated Successfully", "Sccess");
      dispatch(changeReducerState());
      // push("/leads");
    }
  }, [updateLeadStatus]);
  useEffect(() => {
    const detailsId = {
      token: token,
      id: match.params.id,
    };
    dispatch(leadbyid(detailsId));
  }, [token]);
  useEffect(() => {
    if (leadByIdStatus) {
      setIds(leadById?.leads?.id);
      setContactType(leadById?.leads.contact_type);
      setNamePrefix(leadById?.leads.name_prefix);
      setFullName(leadById?.leads.full_name);
      setFirstName(leadById?.leads.first_name);
      setLastName(leadById?.leads.last_name);
      setMobile(leadById?.leads.mobile);
      setPhone(leadById?.leads.phone);
      setPinCode(leadById?.leads?.pincode ?? "");
      setCompany(leadById?.leads.company);
      setEmail(leadById?.leads.email);
      setLeadDate(leadById?.leads.lead_date);
      setAddress(leadById?.leads.address);
      setCity(leadById?.leads.city);
      setCountry(leadById?.leads.country);
      setState(leadById?.leads.state);
      setCampaign(leadById?.leads?.compains);
      setService(leadById?.leads.service_id);
      setRemark(leadById?.leads.remark);
      setSourceType(leadById?.leads.source_type);
      setEmailNotification(leadById?.leads.email_notify);
      setSmsNotification(leadById?.leads.sms_notify);
      setVisibility(leadById?.leads.visibility);
      setSubCategory(leadById?.leads.lead_sector1);
      setSubSubCategory(leadById?.leads.lead_industry1);
      setAssignTo(leadById?.leads.assign_to);
      setPriority(leadById?.leads.priority);
      setVerticalType(leadById?.leads?.vertical_type);
      setLeadGroupRating(
        leadById?.leads.rating_id ? leadById?.leads.rating_id : null
      );
      setLeadGroupSource(
        sources.find((ot) => ot.value === leadById?.leads.source_id)
      );
      setLeadGroupStatus(
        leadById?.leads.lead_status ? leadById?.leads.lead_status.id : null
      );
      setLeadCustomerShare(leadById?.leads.lead_customer_share);

      setDisposition(leadById?.leads.disposition);
    }
  }, [leadByIdStatus]);

  const handleSubmit = (e) => {
    const sector = { ...subCategory };
    sector["id"] = sector.value;
 
    e.preventDefault();
    const inputData = new FormData();
    inputData.append("id", ids);
    inputData.append("assign_to", assignTo);
    inputData.append("lead_date", leadDate);
    inputData.append("full_name", fullName);
    inputData.append("first_name", firstName);
    inputData.append("last_name", lastName);
    inputData.append("phone", phone);
    inputData.append("company", company);
    inputData.append("email", email);
    inputData.append("address", address);
    inputData.append("compains", campaign);
    inputData.append("city", city);
    inputData.append("service_id", service);
    inputData.append("remark", remark);
    inputData.append("email_notify", emailNotification);
    inputData.append("sms_notify", smsNotification);
    inputData.append("visibility", visibility);
    inputData.append("source_id", leadGroupSource ? leadGroupSource?.value : 0);
    inputData.append("source_type", sourceType ? sourceType : null);
    inputData.append("source_details", sourceDetailsData);
    inputData.append("status_id", leadGroupStatus);
    inputData.append("rating_id", leadGroupRating);
    inputData.append(
      "sector",
      JSON.stringify({
        ...subCategory,
        id: subCategory?.value || subCategory?.id,
      })
    );
    inputData.append("industry", JSON.stringify(subSubCategory));
    inputData.append("state", state);
    inputData.append("country", country);
    inputData.append("mobile", mobile);

    inputData.append("disposition", disposition);
    inputData.append("token", token);
    inputData.append("priority", priority);
    inputData.append("vertical_type", verticalType);
    dispatch(updateLeader(inputData, token));
    const data = {
      lead_id: ids,
      activity_type: "lead updated",
      description: "lead updated",
    };
    dispatch(addActivity(data, token));
  };

  useEffect(() => {
    if (pincode?.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
            setCountry(response?.data[0]?.PostOffice[0]?.Country);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Edit Lead</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 mb-15">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Assigned To *
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    id="assignTo"
                                    onChange={(e) =>
                                      setAssignTo(e.target.value)
                                    }
                                    value={assignTo}
                                    invalid={!!errors?.assign_to}
                                    disabled
                                  >
                                    <option value="">select</option>
                                    {assignUser.map((asu) => (
                                      <option value={asu.value} key={asu.value}>
                                        {asu.label}
                                      </option>
                                    ))}
                                  </Input>
                                  <FormFeedback className="text-left">
                                    {errors?.assign_to}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Lead Date
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control selectdate"
                                    name="lead_date"
                                    id="lead_date"
                                    value={leadDate}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Name *
                                </label>
                                <div className="col-lg-8">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        name="nameprefix"
                                        id="nameprefix"
                                        onChange={(e) =>
                                          setNamePrefix(e.target.value)
                                        }
                                        value={namePrefix}
                                      >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Miss">Miss</option>
                                      </Input>
                                    </div>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="name"
                                      onChange={(e) =>
                                        setFullName(e.target.value)
                                      }
                                      value={fullName}
                                      invalid={!!errors?.full_name}
                                    />
                                    <FormFeedback className="text-left">
                                      {errors?.full_name}
                                    </FormFeedback>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                  Mobile *
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    placeholder="Mobile"
                                    id="mobile"
                                    value={mobile}
                                    onChange={handleMobile}
                                    invalid={!!errors?.mobile}
                                  />
                                  <FormFeedback className="text-left">
                                    {errors?.mobile}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Whatsapp No.
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Company
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="company"
                                    id="company"
                                    onChange={(e) => setCompany(e.target.value)}
                                    value={company}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Campaign
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="select"
                                    name="company"
                                    id="company"
                                    value={campaign}
                                    onChange={(e) =>
                                      setCampaign(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="workshop">WorkShop</option>
                                    <option value="IID Sale">IID-Sale</option>
                                  </Input>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Email Id
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Source
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="form-group">
                                    <div className="input-group">
                                      <div className="form-control p-0 border-0">
                                        <Select
                                          options={optgroups}
                                          onChange={(value) =>
                                            handleSourceOptGroup(value)
                                          }
                                          value={leadGroupSource}
                                        />
                                      </div>
                                      <span className="input-group-append input-group-btn">
                                        <Link
                                          className="btn btn-default btn-flat openSourcePop"
                                          to="#"
                                          onClick={handleSource}
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    id="addSourcePop"
                                    className="dv3 dvPopB"
                                    style={{ display: addSource ? "" : "none" }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Source
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Source Name
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="source_name"
                                                          id="source_name"
                                                          value={sourceName}
                                                          onChange={(e) =>
                                                            setSourceName(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="textcl w-100"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-left pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleAddSource}
                                            >
                                              {createSourceLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeSourcePop"
                                              onClick={handleSource}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {referd ? (
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Source Details
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      onChange={(e) =>
                                        setSourceDetailsData(e.target.value)
                                      }
                                      value={sourceDetailsData}
                                    >
                                      <option value="">select</option>
                                      {sourceDetails
                                        ? sourceDetails.map((srl) => (
                                            <option
                                              value={srl.value}
                                              key={srl.value}
                                            >
                                              {srl.label}
                                            </option>
                                          ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Disposition
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    name="service"
                                    id="service"
                                    onChange={(e) =>
                                      setDisposition(e.target.value)
                                    }
                                    value={disposition}
                                  >
                                    <option value=""> Select </option>

                                    {dispositionList
                                      ? dispositionList.map((cr) => (
                                          <option
                                            value={cr?.remarks}
                                            key={cr?.id}
                                          >
                                            {cr?.remarks}
                                          </option>
                                        ))
                                      : null}
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Priority(Interested)
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={priority}
                                    onChange={({ target }) =>
                                      setPriority(target.value)
                                    }
                                  >
                                    <option value="">select</option>
                                    {priorityy?.map((item)=>
                                    {
                                      return(
                                        <option value={item?.title} key={item?.id}>{item?.title}</option>
                                      )
                                    })}
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Lead Status
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="input-group">
                                    <div className="form-control p-0 border-0">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) =>
                                          setLeadGroupStatus(e.target.value)
                                        }
                                        value={leadGroupStatus}
                                      >
                                        <option value="">select</option>
                                        <option value="hot">Hot</option>
                                        <option value="warm">Warm</option>
                                        <option value="active">Active</option>
                                        <option value="rejected-leads">
                                          Rejected Leads
                                        </option>
                                        {leadStatusData
                                          ? leadStatusData.map((srl) => (
                                              <option
                                                value={srl.value}
                                                key={srl.value}
                                              >
                                                {srl.label}
                                              </option>
                                            ))
                                          : null}
                                      </Input>
                                    </div>
                                    <span className="input-group-append input-group-btn">
                                      <Link
                                        className="btn btn-default btn-flat openLeadStatusPop"
                                        to="#"
                                        onClick={handLeadStatus}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </Link>
                                    </span>
                                  </div>
                                  <div
                                    id="addLeadStatusPop"
                                    className="dv3 dvPopB"
                                    style={{
                                      display: leadStatus ? "" : "none",
                                    }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Lead Status
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Lead Status
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="leadStatus"
                                                          id="leadStatus"
                                                          value={
                                                            leadStatusTitle
                                                          }
                                                          onChange={(e) =>
                                                            setLeadStatusTitle(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="textcl w-100"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-left pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleAddLeadStatus}
                                            >
                                              {leadStatusLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeLeadStatusPop"
                                              onClick={handLeadStatus}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Product offered
                                </label>
                                <div className="col-lg-8 position-relative">
                                  <div className="input-group">
                                    <div className="form-control p-0 border-0">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) =>
                                          setLeadGroupRating(e.target.value)
                                        }
                                        value={leadGroupRating}
                                      >
                                        <option value="">Select Type</option>
                                        <option value="Incubatee (I)Membership">
                                          Incubatee (I)Membership
                                        </option>
                                        <option value="Incubatee (W)Workshop">
                                          Incubatee (W)Workshop
                                        </option>
                                        <option value="Incubatee (P)Prototype Project report">
                                          Incubatee (P)Prototype Project report
                                        </option>
                                        <option value="Incubatee (O)Report Volumes/Courses/Services/Customized Project report/Others">
                                          Incubatee (O)Report
                                          Volumes/Courses/Services/Customized
                                          Project report/Others
                                        </option>

                                        <option value="Corporate-Incubation/Business Networking">
                                          Corporate-Incubation/Business
                                          Networking
                                        </option>
                                        <option value="Associate-Incubation/Business Networking Membership">
                                          Associate-Incubation/Business
                                          Networking Membership
                                        </option>
                                        <option value="Membership">
                                          Membership
                                        </option>
                                        <option value="Prototype Report">
                                          Prototype Report
                                        </option>
                                        <option value="Customized Report">
                                          Customized Report
                                        </option>
                                        <option value="Workshop">
                                          Workshop
                                        </option>
                                        <option value="Course">Course</option>
                                      </Input>
                                    </div>
                                    <span className="input-group-append input-group-btn">
                                      <Link
                                        className="btn btn-default btn-flat openRatingPop"
                                        to="#"
                                        onClick={handleReating}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </Link>
                                    </span>
                                  </div>
                                  <div
                                    id="addRatingPop"
                                    className="dv3 dvPopB"
                                    style={{ display: rating ? "" : "none" }}
                                  >
                                    <div className="arrowB"></div>
                                    <div className="arrow-borderB"></div>
                                    <table className="w-100">
                                      <tbody>
                                        <tr>
                                          <td className="popHeading text-left">
                                            Create Rating
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="pl-2 pr-2">
                                            <div id="container">
                                              <div id="dvLeft">
                                                <table className="w-100">
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <label className="lblcss">
                                                          Rating
                                                        </label>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        className="tdcss"
                                                      >
                                                        <Input
                                                          type="text"
                                                          name="rating_name"
                                                          className="textcl w-100"
                                                          value={leadRating}
                                                          onChange={(e) =>
                                                            setLeadRating(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-left pl-2 pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleRatingAdd}
                                            >
                                              {addRatingLoading ? (
                                                <FaSpinner className="fa-spin" />
                                              ) : (
                                                "Save"
                                              )}
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger closeRatingPop"
                                              onClick={handleReating}
                                            >
                                              Close
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  PinCode
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="state"
                                    id="state"
                                    onChange={(e) => setPinCode(e.target.value)}
                                    value={pincode}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Address
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="4"
                                    name="address"
                                    id="address"
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  State
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="state"
                                    id="state"
                                    onChange={(e) => setState(e.target.value)}
                                    value={state}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Country
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="country"
                                    id="country"
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  City
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    name="city"
                                    id="city"
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                  ></Input>
                                </div>
                              </div>

                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Investment Upto
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    name="service"
                                    id="service"
                                    onChange={(e) => setService(e.target.value)}
                                    value={service}
                                  >
                                    <option value=""> Select </option>
                                    <option value="0-10 Lakh">0-10 Lakh</option>
                                    <option value="10-25 Lakh">
                                      10-25 Lakh
                                    </option>
                                    <option value="25-50 Lakh">
                                      25-50 Lakh
                                    </option>
                                    <option value="50-1 crore">
                                      50-1 crore
                                    </option>

                                    <option value="1-5 crore">1-5 crore</option>
                                    <option value="Above 5 crore">
                                      Above 5 crore
                                    </option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  General Notes
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="4"
                                    name="remark"
                                    id="remark"
                                    onChange={(e) => setRemark(e.target.value)}
                                    value={remark}
                                  ></Input>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Sector
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    options={[...sectors]}
                                    onChange={handleSector}
                                    value={subCategory}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Industry
                                </label>
                                <div className="col-lg-8">
                                  <Select
                                    options={[...industry]}
                                    onChange={(e) => setSubSubCategory(e)}
                                    value={subSubCategory}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Disposition Type
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    name="service"
                                    id="service"
                                    onChange={(e) =>
                                      setDispositionType(e.target.value)
                                    }
                                    value={dispositiontype}
                                  >
                                    <option value=""> Select </option>
                                    <option value="Connected">Connected</option>
                                    <option value="Not_Connected">
                                      Not Connected
                                    </option>
                                  </Input>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                  Vertical Type
                                </label>
                                <div className="col-lg-8">
                                  <Input
                                    type="select"
                                    className="custom-select"
                                    value={verticalType}
                                    onChange={(e) =>
                                      setVerticalType(e.target.value)
                                    }
                                  >
                                    <option value="">select</option>
                                    <option value="Membership">
                                      Membership
                                    </option>
                                    <option value="Workshop">Workshop</option>
                                    <option value="Project_Report">
                                      Project Report
                                    </option>
                                    <option value="Industrial_Report">
                                      Industrial Report
                                    </option>
                                    <option value="Courses">Courses</option>
                                    <option value="Franchise">Franchise</option>
                                    <option value="Startup">Startup</option>

                                    <option value="Edp">Edp</option>
                                  </Input>
                                </div>
                              </div>
                            </div>

                            {/* {dispositiontype == "Not_Connected" ? (
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Not Connected
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      name="service"
                                      id="service"
                                      onChange={(e) =>
                                        setDisposition(e.target.value)
                                      }
                                      value={service}
                                    >
                                      <option value=""> Select </option>

                                      <option value="Ringing">Ringing</option>
                                      <option value="Switch_Off">
                                        Switch Off
                                      </option>
                                      <option value="Invalid_Number">
                                        Invalid Number
                                      </option>
                                      <option value="Call_Rejected">
                                        Call Rejected
                                      </option>
                                    </Input>
                                  </div>
                                </div>
                              </div>
                            ) : null} */}

                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row mb-2 mb-1">
                                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right"></label>
                                <div className="col-lg-8">
                                  <div className="row">
                                    <div className="col-md-12"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                              <div className="row form-group">
                                <div className="col-lg-4 control-label col-form-label col-12 text-lg-right"></div>
                                <div className="col-lg-8">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    {updateLeadLoader ? (
                                      <FaSpinner className="fa-spin" />
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                  <Link
                                    to="/leads"
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Close
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Edit;
