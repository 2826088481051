import axios from "axios";
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MainWrapper from "../../components/MainWrapper";
import { FaList, FaTrash } from "react-icons/fa";
import { FiEdit, FiTrash } from "react-icons/fi";
import {Button,
    ButtonDropdown,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Label
} from "reactstrap";
import { map, capitalize, last, split, mapValues } from "lodash";
import { FiEye,FiUpload } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
const StartUpRequest = memo(() => {
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [statuses, setStatuses] = useState({});
    const [prevData, setPrevData] = useState({
        value: "",
        idx: "",
        key: ""
    });
    const [modal, setModal] = useState(false);
    const [uploadmodal, setUploadModal] = useState(false);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({});
    const[partnernames,setPartnernames]  = useState([]);
    const[memname,setMebershipName] =useState([]);
    const [singleuser, setSingle] = useState({});
    const [feedBack, setFeedBack] = useState("");
    const [activeTab, setActiveTab] = useState(4);
    const [paginate, setPaginate] = useState(10);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("desc");
    const [member, setMemeber] = useState([]);
    const [uploadFile, setUploadFile] = useState();
    const [loading, setLoading] = useState(true);
    const [deleteIds, setDeleteIds] = useState("");
    const [serviceStatus,setServiceStatus] = useState("");
    const [memberDetail, setMemberDetail] = useState({});
    const dropdownOpenToggle = () =>
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    useEffect(() => {
        axios.get(`/api/crm/service-requests/details?activeTab=${activeTab}&search=${search}&sort=${sort}&paginate=${paginate}&page=${page}&service_status=${serviceStatus}`)
            .then(res => {
                setData(res.data.startupDetails.data);
                setFrom(res.data.startupDetails.from);
                setTo(res.data.startupDetails.to);
                setTotal(res.data.startupDetails.total);
                setPerPage(res.data.startupDetails.per_page);
                mapValues(res.data.startupDetails, (value, key) => {
                    setStatuses(prevState => {
                        return {
                            ...prevState,
                            [key]: map(value, v => ({
                                id: v.id,
                                status: v.status
                            }))
                        };
                    });
                });
            })
            .catch(err => console.log(err.response));
    }, [refresh,activeTab,search,sort,paginate,page,serviceStatus]);
    const handleStatusChange = (value, idx, key, file) => {
       
        if (value === "INVALID") {
            setPrevData({
                value,
                idx,
                key
            });
            setModal(true);
        }

        else if(file != "" && value != 'COMPLETED' && value != 'IN_PROGRESS')
        {
            NotificationManager.error("do not change status on this stage..")
        } else if( file == null && value === 'COMPLETED') {
            NotificationManager.error("Please Upload File..")
        }

        else {
            setPrevData({
                value,
                idx,
                key
            });
            setStatuses(prev => {
                const updatedKey = prev[key];
                updatedKey[idx].status = value;
                return {
                    ...prev,
                    [key]: updatedKey
                };
            });
            handleStatusSubmit(value, idx, key);
        }
    };
    const handleFeedbackSubmit = () => {

        setUploadModal(false);
       // handleStatusSubmit();
    };

    const {
        auth,
        permissions: { permission },
      } = useSelector(({ auth }) => auth);
      const dispatch = useDispatch();

      useEffect(() => {
        dispatch(getallPermissions(auth?.token));
      }, [auth]);

    const handleStatusSubmit = (value, idx, key) => {
        axios.post(`/service-requests/status`, {
            key: key ?? prevData.key,
            status: statuses[key ?? prevData.key]?.[idx ?? prevData.idx],
            feedBack
        })
            .then(() => {
                setPrevData({
                    value: "",
                    idx: "",
                    key: ""
                });
                setFeedBack("");
                setRefresh(prev => prev + 1);
            })
            .catch(error =>
                console.log(
                    "🚀 ~ file: ServiceRequests.js ~ line 105 ~ handleStatusSubmit ~ error",
                    error
                )
            );
    };
     const handleView= (e,data,user,partner) => {

        e.preventDefault();
        setModal(true);
        setFields(data);
        setSingle(user);
        setPartnernames(partner)

         axios
            .get(`/api/crm/getUserbyId/` + user.id, {
                headers: {
                    authorization: `Bearer ${auth?.token}`
                }
            })
            .then(res => {
                setMemeber(res.data.memebership);
                setMemberDetail(res.data.user?.plan_detail_all);
            })
            .catch(err => console.log(err));
    };
    const UploadView= (e,data,user,partner) => {

        e.preventDefault();
        setUploadModal(true);
        setFields(data);
        setSingle(user);
        setPartnernames(partner)

         axios
            .get(`/getUserbyId/` + user.id)
            .then(res => {
                setMemeber(res.data.memebership);
            })
            .catch(err => console.log(err));
    };


   const datediff = (date1, date2) => {
        const date4 = new Date(date1);
        const date5 = new Date();

        const diffInMs = Math.abs(date5 - date4);
        if (diffInMs > 0) {
            return Math.round(diffInMs / (1000 * 60 * 60 * 24));
        } else {
            return 0;
        }
    };

const submitForm = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("id", fields.id);
        data.append("uploadFile", uploadFile);



        axios.post('/service-requests/startupfile',data).then(res => {
              NotificationManager.success('Uploaded successfully');
                window.location.reload();
              setUploadModal(false);

                }).catch(err => NotificationManager.error(err.response.data.message));
        //  updateProfile(data, token);
    };
    const handleSearch = e => {
        setPage(1);
        setSearch(e.target.value);
      };

      const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            setLoading(true);
            axios.get(`/startup-report-delete/${id}`,id ).then(res => {
                setLoading(false);
                setRefresh(refresh => refresh + 1);
                setDeleteIds([]);
                NotificationManager.success(res.data.message);
                console.log(res.data,'delete');
            }).catch(err => {
                setLoading(false);
                NotificationManager.error(err.response.data.message);

            });
        }
    };

    return (
        <Fragment>
            <MainWrapper fluid>
            <main className="content pt-4">
                <div className="page-header mb-4">
                    <Row>
                        <Col md="12">
                            <div className="c-card-top">
                                <div className="c-card-top-title">
                                    {/* <h4 className="mb-0">Service Requests</h4> */}
                                    <h4 className="mb-0">
                                        Startup Registration
                                    </h4>
                                </div>
                                <div className="c-card-top-inputs c-card-top-search">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-search"></i>
                                            </span>
                                        </div>
                                        <span className="custom-select">
                                            Search
                                        </span>
                                        <Input
                                            placeholder="Search here..."
                                            onChange={handleSearch}
                                            className="c-form-control-serch"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-secondary btn-sm"
                                                type="button"
                                                id="button-addon2"
                                            >
                                                GO
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="c-card-top-buttons">
                                    <ButtonGroup className="">
                                        <ButtonDropdown
                                            isOpen={dropdownOpen}
                                            toggle={dropdownOpenToggle}
                                        >
                                            <DropdownToggle caret size="sm">
                                                <FaList />
                                                &nbsp;Service Tools
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem tag="a" href="#">
                                                    Tool Name
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </ButtonGroup>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <div>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="btn btn-sm btn-secondry btn-circle mr-1"
                                    >
                                       <i className="fa fa-trash pr-1" /> Delete
                                    </Button>
                                    <Button
                                        className="btn btn-sm btn-secondry btn-circle"
                                        color="secondary"
                                        size="sm"
                                    >
                                        <i className="fa fa-refresh" />
                                    </Button>
                                </div>
                                <div>
                                <select
                                        id="serviceStatus"
                                        onChange={(e) =>
                                            setServiceStatus(e.target.value)
                                        }
                                        value={serviceStatus}
                                        className="custome-select mr-1"
                                        style={{
                                            width: 195,
                                          }}
                                    >
                                        <option value="">Select Service Status</option>
                                        <option value="APPLIED">Applied</option>
                                        <option value="IN_PROGRESS">In Progress</option>
                                        <option value="COMPLETED">Completed</option>
                                        <option value="ON HOLD">On Hold</option>
                                        <option value="INVALID">Invalid</option>
                                        <option value="CANCEL">Cancel</option>

                                    </select>
                                    <select
                                        id="sort"
                                        onChange={e => setSort(e.target.value)}
                                        value={sort}
                                        className="custome-select"
                                        style={{ width: 130 }}
                                    >
                                        <option value="asc">Ascending</option>
                                        <option value="desc">Descending</option>
                                    </select>
                                    <select
                                            id="paginate"
                                            onChange={e =>
                                                setPaginate(e.target.value)
                                            }
                                            value={paginate}
                                            className="custome-select custom-select-page"
                                        >
                                            <option value="10">10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                </div>
                            </CardHeader>
                            <CardBody className="p-0">

                                <TabContent className="" activeTab={activeTab}>
                                    <TabPane tabId={4}>
                                        <Row>
                                            <Col sm="12">
                                                <div className="table-responsive">
                                                    <Table
                                                        size="sm"
                                                        className="small mb-0"
                                                        hover
                                                    >
                                                        <thead className="thead-light">
                                                            <tr>
                                                            <th className="font-weight-bold">
                                                                    SNo.
                                                                </th>
                                                                <th className="font-weight-bold">
                                                                    Member Name
                                                                </th>
                                                                <th className="font-weight-bold">
                                                                    Mobile
                                                                </th>
                                                                <th className="font-weight-bold">
                                                                    Email
                                                                </th>
                                                                <th
                                                                    rowSpan={2}
                                                                    className="font-weight-bold"
                                                                >
                                                                    Membership
                                                                    Type
                                                                </th>

                                                                <th className="font-weight-bold">
                                                                    Applied On
                                                                </th>
                                                                <th className="font-weight-bold">
                                                                    Status
                                                                </th>
                                                                {/* <th className="font-weight-bold">
                                                                    Feedback
                                                                </th> */}
                                                                <th className="font-weight-bold text-right">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="d-table-body">
                                                            {map(
                                                                data,
                                                                (
                                                                    startup,
                                                                    idx
                                                                ) => (
                                                                    <Fragment
                                                                        key={`startup-${startup.id}`}
                                                                    >
                                                                        <tr>
                                                                            <td> {idx + 1}</td>
                                                                            <td>
                                                                                { startup.user != null ?(
                                                                                <a
                                                                                    className="c-text-btn-primary"
                                                                                    href={`/user-profile/${startup.user.id}`}
                                                                                >
                                                                                    {capitalize(
                                                                                        startup
                                                                                            .user
                                                                                            .name
                                                                                    )}
                                                                                </a>)
                                                                              :''  }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    startup
                                                                                        .user
                                                                                        .mobile
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    startup
                                                                                        .user
                                                                                        .email
                                                                                }
                                                                            </td>
                                                                            <td

                                                                              >
                                                                                  {startup?.userpl ? startup?.userpl?.name : ''}
                                                                                  <span className="badge badge-sm badge-info">
                                                                                      {member ===
                                                                                          null
                                                                                          ? ""
                                                                                          : datediff(
                                                                                            startup?.userpl ? startup.userpl.expiry:'',
                                                                                            startup?.userpl ? startup.userpl.purchased_at :''
                                                                                          )}


                                                                                      {member ===
                                                                                          null
                                                                                          ? ""
                                                                                          : (<span>Days
                                                                                              Left</span>)}
                                                                                  </span>
                                                                              </td>
                                                                            <td>
                                                                                {startup.created_at}
                                                                            </td>

                                                                            <td>
                                                                                {startup.status}
                                                                            </td>
                                                                            <td className="text-left">
                                                                                <span
                                                                                    style={{
                                                                                        cursor:
                                                                                            "pointer"
                                                                                    }}
                                                                                    className="c-icon-btn-light"
                                                                                     onClick={e =>handleView(e,startup,startup.user,startup.directors)}
                                                                                >
                                                                                    <FiEye />
                                                                                </span>
                                                                              
                                                                            </td>                                                                            
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>                          
                            <div className="card-footer">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            Showing {from} to {to} of{" "}
                                            {total} entries
                                        </span>
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={perPage}
                                            totalItemsCount={total}
                                            pageRangeDisplayed={5}
                                            onChange={page => setPage(page)}
                                            innerClass="pagination pagination-sm"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                        </Card>
                    </Col>
                </Row>
                </main>
            </MainWrapper>
         <Modal isOpen={uploadmodal}>
         <ModalHeader
                    style={{ background: "#e5e5e5" }}
                    toggle={() => setUploadModal(false)}
                >

                </ModalHeader>
                <ModalBody className="px-2 py-1">
                    <FormGroup>
                        <Label>Upload Document</Label>
                        <Input
                            type="file"
                            onChange={(e) => setUploadFile(e.target.files[0])}

                        />
                    </FormGroup>
                    <Button
                        color="success"
                        size="sm"
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal} size="lg" style={{ maxWidth: 700 }}>
                <ModalHeader
                    style={{ background: "#e5e5e5" }}
                    toggle={() => setModal(false)}
                >
                   {singleuser.name ? singleuser.name :""}
                </ModalHeader>
                <ModalBody className="px-2 py-3" style={{ fontSize: 12 }}>
                    <FormGroup>
                        <div className="container-fluid">
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Membership Type:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{memberDetail?.name}</span>{" "}
                                    <span className="badge badge-info badge-sm">
                                        {member ===
                                                                            null
                                                                                ? ""
                                                                                : datediff(
                                                                                      memberDetail?.expiry,
                                                                                      memberDetail?.joining
                                                                                  )}


                                                                                   {member ===
                                                                        null
                                                                            ? ""
                                                                            :(<span>Days
                                                                            Left</span>)}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Mobile:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span> {singleuser.mobile ? singleuser.mobile :""}</span>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Email:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span> {singleuser.email ? singleuser.email :""}</span>
                                </div>
                            </div>

                        </div>
                        <div className="container-fluid mt-3">
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Firm Name:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{fields.startup_firm ? fields.startup_firm : ''}</span>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Sector:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{fields.startup_sector ? fields.startup_sector : ''}</span>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Address:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                       {fields.startup_address ? fields.startup_address : ''}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Status:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span className="badge badge-sm badge-warning">
                                      {fields.status ? fields.status : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {partnernames === "" ? "" : partnernames.map((item,index) => (
                        <div className="container-fluid mt-3">
                            <div className="row mb-1">
                                <div className="col-md-12">
                                    <div
                                        className="py-1 px-2"
                                        style={{ background: "#e5e5e5" }}
                                    >
                                        <strong style={{ fontWeight: 600 }}>
                                               {index+1} - Director
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Name:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{item.director_name ? item.director_name: ''}</span>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Gender:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{item.director_gender ? item.director_gender: ''}</span>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Mobile:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>{item.director_mobile ? item.director_mobile: ''}</span>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Email:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>anand@rightmovetech.com</span>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-3">
                                    <strong style={{ fontWeight: 600 }}>
                                        Address:
                                    </strong>
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        {" "}
                                        {item.director_address ? item.director_address: ''}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Photo:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                { item.director_photograph ? (
                                    <a href={`/storage/${item == null ? "": item.director_photograph
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                        </div>
  )
                       )}
                        <div className="container-fluid mt-3">
                            <div className="row mb-1">
                                <div className="col-md-12">
                                    <div
                                        className="py-1 px-2"
                                        style={{ background: "#e5e5e5" }}
                                    >
                                        <strong style={{ fontWeight: 600 }}>
                                            Business Document
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Certificate Doc:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                    { fields.startup_doc_certificate ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_certificate
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>

                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Pan Card:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                    { fields.startup_doc_pancard ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_pancard
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Services Doc:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                      { fields.startup_doc_services ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_services
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Website Address:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                        { fields.startup_doc_website_address ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_website_address
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Udyam Certificate:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                       { fields.startup_doc_udyam_certificate ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_udyam_certificate
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Profile Person:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                   { fields.startup_doc_profile_person ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_doc_profile_person
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-1 px-2">
                                <div className="col-md-10">
                                    <strong style={{ fontWeight: 600 }}>
                                        Answer:
                                    </strong>
                                </div>
                                <div className="col-md-2">
                                     { fields.startup_answer ? (
                                    <a href={`/storage/${fields == null ? "": fields.startup_answer
                                                                                        }`} className="text-primary">
                                        View
                                    </a>) : "N/A"}
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    {/* <div className="text-center mt-2 mb-2">
                   <Button
                        color="secondary"
                        size="sm"
                        onClick={()=>setModal(false)}
                    >
                        Close
                    </Button>
                   </div> */}
                </ModalBody>
            </Modal>
        </Fragment>
    );
});

export default StartUpRequest;
