import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import {
  MemberShipData,
  sectors,
  projects,
  memberShipCreate,
} from "../../store/actions/MemberShipActions";
import { workShopUser } from "../../store/actions/WorkShopActions";
import _ from "lodash";
import Creatable, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
const Create = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState({ plan: true });
  const [project, setProject] = useState([]);
  const [sector, setSector] = useState([]);
  const [error, setError] = useState({});
  const { auth, isAuthenticated } = useSelector((state) => state.auth);
  const {
    memberShips: { memberShip },
    errors,

    sectors: { subcategory },
    projects: { project_reports },
    createStatus,
  } = useSelector((state) => state.memberShipReducer);
  const {
    users: { user },
  } = useSelector((state) => state.workShop);
  useEffect(() => {
    dispatch(MemberShipData(auth?.token));
    dispatch(sectors(auth?.token));
  }, [auth?.token]);
  const memberShipData = useMemo(() => memberShip, [memberShip]);

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = memberShip.find(
        (v) => v.value == fields?.selectedMemberShip
      );
      return selected;
    }
  }, [fields?.selectedMemberShip]);
  const handleSwitchChange = () => {
    setFields({ ...fields, status: !fields.status });
  };
  const handleSectorChange = (val) => {
    if (!val || val.length <= parseInt(selectedMemberShip?.industries)) {
      setSector(val);
      if (!val) {
        setProject("");
      }
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  const handleProjectChange = (value) => {
    if (!value || value.length <= parseInt(selectedMemberShip?.sub_category)) {
      setProject(value);
    }
  };
  useMemo(() => {
    if (fields.mobile) {
      if (fields.mobile?.length == 10) {
        dispatch(workShopUser(auth?.token, fields.mobile));
      }
    }
  }, [fields.mobile]);
  useEffect(() => {
    if (user) {
      if (!_.isEmpty(user?.plan_detail)) {
        NotificationManager.error("User already taken MemberShip", "Error");
      }
      setFields({
        ...fields,
        name: user.name,
        email: user.email,
        id: user.id,
      });
    } else {
      setFields({
        ...fields,
        name: "",
        email: "",
        id: "",
      });
    }
  }, [user]);
  useEffect(() => {
    if (createStatus) {
      NotificationManager.success("Created Successfully", "Sccess");
      push("/membership");
    }
  }, [createStatus]);
  const sectorData = useMemo(() => subcategory, [subcategory]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  useEffect(() => {
    if (!_.isNull(sector) && selectedMemberShip?.sub_category) {
      let sectorValue = _.map(sector, (s) => s.value);

      dispatch(projects(auth?.token, sectorValue));
    }
  }, [auth?.token, sector]);
  const Menu = (menuProps) => {
    const optionSelectedLength = menuProps.getValue().length || 0;
    return (
      <components.Menu {...menuProps}>
        {optionSelectedLength < parseInt(selectedMemberShip?.industries) ? (
          menuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {selectedMemberShip?.industries} Sector
          </div>
        )}
      </components.Menu>
    );
  };
  const projectMenu = (projectMenuProps) => {
    const optionSelectedLength1 = projectMenuProps.getValue().length || 0;
    return (
      <components.Menu {...projectMenuProps}>
        {optionSelectedLength1 < parseInt(selectedMemberShip.sub_category) ? (
          projectMenuProps.children
        ) : (
          <div style={{ margin: 15 }}>
            Only {parseInt(selectedMemberShip.sub_category)} Project
          </div>
        )}
      </components.Menu>
    );
  };

  const validationForm = () => {
    let formIsValid = true;
    if (!_.size(sector)) {
      formIsValid = false;
      error["sector"] = "Sector is required.";
      NotificationManager.error("Sector is required.", "Error");
    }
    if (selectedMemberShip?.sub_category > 0) {
      if (!_.size(project)) {
        formIsValid = false;
        error["project"] = "Project is required.";
        NotificationManager.error("Project is required.", "Error");
      }
    }
    setError(error);
    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const data = {
        user_id: fields?.id,
        name: fields?.name,
        email: fields?.email,
        mobile: fields?.mobile,
        payment_id: fields?.trxId,
        mailing: fields?.maillingAddress,
        sectors: sector.map((se) => {
          return {
            sector: se.value,
            project: _.filter(project, (p) => p.sector === se.value),
          };
        }),
        membership_type: selectedMemberShip?.label,
        membership: fields?.selectedMemberShip,
        total: selectedMemberShip?.total,
        amount: selectedMemberShip?.total,
        expiry: selectedMemberShip?.expiry,
        sub_sub_category: selectedMemberShip?.sub_category,
        bussinessName: fields?.bussinessName,
        pincode: fields?.pincode,
        gst: fields?.gst,
        mode: fields?.mode,
        paymentType: fields?.paymentType,
      };

      dispatch(memberShipCreate(data, auth?.token));
    }
  };
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 &&
    selectValue.length < parseInt(selectedMemberShip?.industries);
  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Create MemberShip</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 offset-md-2 mb-15">
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="selectedMemberShip">
                                  MemberShip
                                </Label>
                                <Input
                                  type="select"
                                  className="custom-select"
                                  id="selectedMemberShip"
                                  invalid={!!errors?.selectedMemberShip}
                                  value={
                                    fields?.selectedMemberShip
                                      ? fields?.selectedMemberShip
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <option>select</option>
                                  {_.size(memberShipData)
                                    ? _.map(memberShipData, (mem) => (
                                        <option
                                          value={mem?.value}
                                          key={mem?.value}
                                        >
                                          {mem?.label}/{mem?.total}
                                        </option>
                                      ))
                                    : null}
                                </Input>
                                <FormFeedback>
                                  {!!errors?.selectedMemberShip
                                    ? errors?.selectedMemberShip[0]
                                    : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            {!_.isUndefined(selectedMemberShip) ? (
                              <Col sm="6">
                                <h5>Membership Plan</h5>
                                <p>
                                  <strong>
                                    For Individual/Enterprises/Startups
                                  </strong>
                                </p>
                                <CustomInput
                                  type="switch"
                                  id="plan"
                                  name="plan"
                                  label={`${selectedMemberShip?.label} ${selectedMemberShip?.total}`}
                                  checked={fields.plan}
                                  onChange={handleSwitchChange}
                                />
                                <p>
                                  The entire fee amount is inclusive of GST
                                  calculated @ 18%.
                                </p>
                              </Col>
                            ) : null}
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="mobile">Mobile</Label>
                                <Input
                                  invalid={!!errors.mobile}
                                  id="mobile"
                                  value={fields?.mobile ? fields?.mobile : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.mobile ? errors.mobile[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col sm={6}>
                              <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                  invalid={!!errors.name}
                                  id="name"
                                  value={fields.name ? fields.name : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.name ? errors.name[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  invalid={!!errors.email}
                                  id="email"
                                  value={fields.email ? fields.email : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.email ? errors.email[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="maillingAddress">
                                  Mailing Address
                                </Label>
                                <Input
                                  type="textarea"
                                  invalid={!!errors.maillingAddress}
                                  id="maillingAddress"
                                  value={
                                    fields.maillingAddress
                                      ? fields.maillingAddress
                                      : ""
                                  }
                                  onChange={handleChange}
                                  className="custom-select"
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="sector">
                                  Proposed Area of Sector
                                </Label>
                                <Creatable
                                  components={{ Menu }}
                                  isValidNewOption={isValidNewOption}
                                  isMulti
                                  options={sectorData}
                                  value={sector}
                                  onChange={handleSectorChange}
                                />
                              </FormGroup>
                              <FormFeedback className="d-block">
                                {errors.sector}
                              </FormFeedback>
                            </Col>
                            {selectedMemberShip?.sub_category > 0 ? (
                              <Col sm="6">
                                <FormGroup>
                                  <Label for="sector">
                                    Proposed Area of Industry Report
                                  </Label>
                                  <Creatable
                                    components={{ Menu: projectMenu }}
                                    isValidNewOption={isValidNewOption}
                                    isMulti
                                    options={subSubCategories}
                                    value={project}
                                    onChange={handleProjectChange}
                                  />
                                </FormGroup>
                                <FormFeedback className="d-block">
                                  {errors.project}
                                </FormFeedback>
                              </Col>
                            ) : null}
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="total">Total Paid</Label>
                                <Input
                                  id="total"
                                  value={selectedMemberShip?.total}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="bussinessName">
                                  Bussiness Name
                                </Label>
                                <Input
                                  invalid={!!errors.bussinessName}
                                  id="bussinessName"
                                  value={
                                    fields.bussinessName
                                      ? fields.bussinessName
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="pincode">Pincode</Label>
                                <Input
                                  invalid={!!errors.pincode}
                                  id="pincode"
                                  value={fields.pincode ? fields.pincode : ""}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label for="gst">GST Number</Label>
                                <Input
                                  invalid={!!errors.gst}
                                  id="gst"
                                  value={fields.gst ? fields.gst : ""}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="mode">Payment Mode</Label>
                                <Input
                                  type="select"
                                  invalid={!!errors.mode}
                                  id="mode"
                                  value={fields.mode ? fields.mode : ""}
                                  onChange={handleChange}
                                  className="custom-select"
                                >
                                  <option>select</option>
                                  <option value="1">Online</option>
                                  <option value="0">Offline</option>
                                </Input>
                                <FormFeedback>
                                  {!!errors.mode ? errors.mode[0] : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="paymentType">Payment Type</Label>
                                <Input
                                  type="select"
                                  invalid={!!errors.paymentType}
                                  id="paymentType"
                                  value={
                                    fields.paymentType ? fields.paymentType : ""
                                  }
                                  onChange={handleChange}
                                  className="custom-select"
                                >
                                  <option>select</option>
                                  <option value="upi">UPI</option>
                                  <option value="imps">IMPS</option>
                                </Input>

                                <FormFeedback>
                                  {!!errors.paymentType
                                    ? errors.paymentType[0]
                                    : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label for="trxId">Trx Id</Label>
                                <Input
                                  invalid={!!errors.payment_id}
                                  id="trxId"
                                  value={fields.trxId ? fields.trxId : ""}
                                  onChange={handleChange}
                                />
                                <FormFeedback>
                                  {!!errors.payment_id
                                    ? errors.payment_id[0]
                                    : ""}
                                </FormFeedback>
                              </FormGroup>
                            </Col>

                            <Col sm="10">
                              <div className="text-center mb-3">
                                <Button
                                  type="submit"
                                  color="secondary"
                                  size="sm"
                                  className="mr-1"
                                  disabled={!_.isEmpty(user?.plan_detail)}
                                >
                                  Save
                                </Button>

                                <Button
                                  type="button"
                                  color="secondary"
                                  size="sm"
                                >
                                  Close
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Create;
