import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  workShopUser,
  workShopCreate,
  workShopList,
  workShopData,
  changeReducerState,
} from "../../store/actions/WorkShopActions";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { Redirect } from "react-router";
const AddWorkshop = memo(({ history, leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [file, setFile] = useState();
  const [formData, setFormData] = useState({
    workshop: "",
    name: leadDetail?.name ?? "",
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    mode: "",
    paymentType: "",
    payment_id: "",
    amount: "",
    id: leadDetail?.id ?? "",
    leadId: leadDetail?.id ?? "",
  });
  const {
    users: { user },
    createStatus,
    errors,
    loading,
    workShops,
    booked,
  } = useSelector(({ workShop }) => workShop);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;

  useEffect(() => {
    dispatch(workShopData(token));
  }, [token]);
  const selectedWorkShop = useMemo(() => {
    if (formData?.workshop) {
      let selected = workShops?.upcomming.find(
        (ws) => ws.id == formData?.workshop
      );
      return selected;
    }
  }, [formData?.workshop]);
  useEffect(() => {
    if (selectedWorkShop) {
      setIsBooked(false);
      Axios.get(
        `/api/crm/booked-workshop-byuser?user_id=${leadDetail?.id}&work_id=${selectedWorkShop?.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          if (_.size(res?.data?.bookWorkShop) > 0) {
            setIsBooked(true);
          } else {
            setIsBooked(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedWorkShop]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        name: user.name,
        email: user.email,
        id: user.id,
      });
    }
  }, [user]);

  useMemo(() => {
    if (formData.mobile) {
      if (formData.mobile?.length == 10) {
        dispatch(workShopUser(auth?.token, formData.mobile));
      }
    }
  }, [formData?.mobile]);

  const fees = useMemo(() => {
    if (!_.isEmpty(selectedWorkShop)) {
      if (
        !_.isEqual(selectedWorkShop?.online_fee, "null") &&
        !_.isEqual(selectedWorkShop?.online_fee, "Free")
      ) {
        setFormData({
          ...formData,
          amount: selectedWorkShop?.online_fee,
        });
        return selectedWorkShop?.online_fee;
      } else {
        if (
          !_.isEqual(selectedWorkShop?.offline_fee, "null") &&
          !_.isEqual(selectedWorkShop?.offline_fee, "Free")
        ) {
          setFormData({
            ...formData,
            amount: selectedWorkShop?.offline_fee,
          });
          return selectedWorkShop?.offline_fee;
        } else {
          setFormData({
            ...formData,
            amount: "Free",
          });
          return "Free";
        }
      }
    }
  }, [selectedWorkShop]);
  useEffect(() => {
    if (selectedWorkShop) {
      setFormData({
        ...formData,
        mode: selectedWorkShop?.online_available,
      });
    }
  }, [selectedWorkShop]);

  const timeConvert = (time) => {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
        _.remove(time, (t, i) => i === 3); // Removing seconds
      }
      return time.join(""); // return adjusted time or original string
    }
  };
  useEffect(() => {
    setFormData({
      workshop: "",
      name: leadDetail?.name,
      mobile: leadDetail?.mobile,
      email: leadDetail?.email,
      mode: "",
      paymentType: "",
      payment_id: "",
      amount: "",
      id: leadDetail?.id ?? "",
      leadId: leadDetail?.id ?? "",
    });
  }, [leadDetail]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("payment_img", file);
    data.append("workshop", formData?.workshop);
    data.append("name", leadDetail?.name);
    data.append("mobile", leadDetail?.mobile);
    data.append("email", leadDetail?.email);
    data.append("paymentType", formData?.paymentType);
    data.append("payment_id", formData?.payment_id);
    data.append("amount", fees);
    data.append("id", leadDetail?.id ?? "");
    data.append("leadId", leadDetail?.id ?? "");
    data.append("mode", selectedWorkShop?.online_available);

    dispatch(workShopCreate(data, token));
  };

  console.log(fees);
  useEffect(() => {
    if (createStatus) {
      setWsStatus((prev)=>!prev);
      dispatch(changeReducerState());
      NotificationManager.success("Created Successfully", "Sccess");
    }
  }, [createStatus]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Workshop
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Workshopp
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>WorkShop</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    invalid={!!errors.workshop}
                    name="workshop"
                    value={formData?.workshop}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {_.map(workShops?.upcomming, (ws, i) => (
                      <option value={ws.id} key={ws.id}>
                        {ws?.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>
                    {!!errors.workshop ? errors.workshop[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {selectedWorkShop ? (
                <Col sm="6">
                  <Fragment>
                    <FormGroup>
                      <p>
                        <strong>Name:</strong>
                        <span>{selectedWorkShop?.name}</span>
                      </p>
                      <p>
                        <strong>Date Time:</strong>
                        <span>
                          {moment(selectedWorkShop?.date).format("Do MMM YYYY")}
                          {timeConvert(selectedWorkShop?.start_time)} to{" "}
                          {timeConvert(selectedWorkShop?.end_time)}
                        </span>
                      </p>
                      <p>
                        <strong>Price:</strong>
                        <span> {fees}</span>
                      </p>
                      <p>
                        <strong>Mode:</strong>
                        <span>
                          {selectedWorkShop?.online_available == 1
                            ? "Online"
                            : "Offline"}
                        </span>
                      </p>
                    </FormGroup>
                  </Fragment>
                </Col>
              ) : null}
              <Col sm="6">
                <FormGroup>
                  <Label>Name</Label>

                  <Input
                    value={formData?.name}
                    name="name"
                    onChange={handleChange}
                    invalid={!!errors.name}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Mobile</Label>
                  <Input
                    value={formData?.mobile}
                    name="mobile"
                    invalid={!!errors.mobile ? errors.mobile[0] : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    value={formData?.email}
                    name="email"
                    onChange={handleChange}
                    invalid={!!errors.email}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    name="mode"
                    onChange={handleChange}
                    invalid={!!errors.mode}
                    value={formData.mode}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={formData.paymentType}
                    onChange={handleChange}
                    name="paymentType"
                    invalid={!!errors.paymentType}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="other">Other</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id</Label>
                  <Input
                    value={formData?.payment_id}
                    name="payment_id"
                    onChange={handleChange}
                    invalid={!!errors.payment_id}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit̥">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddWorkshop;
