import React, { Fragment, useState } from "react";
import {
  FiEdit,
  FiTrash,
  FiChevronDown,
  FiEye,
  FiAlertTriangle,
  FiTablet,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import Note from "../../components/meeting-details/subComponent/Note";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import Loader from "../../layouts/Loader";
import { useSelector, useDispatch } from "react-redux";
import { eventInsertHandler } from "../../store/actions/TaskActions";
import { NotificationManager } from "react-notifications";
const Optionsmodel = ({
  itemid,
  openNotesModal,
  setOpenNotesModal,
  openDispositionModal,
  setOpenDispositionModal,
}) => {
  console.log(itemid, "itemid");
  const dispatch = useDispatch();
  const [modalId, setModalId] = useState("");
  const [startdate, setStartdate] = useState("");
  const [errors, setErrors] = useState({});
  const [purposeModal, SetPurposeModal] = useState("");
  const [city, setCity] = useState("");
  const [loadingg, setLoadingg] = useState(true);
  const [starttime, setStarttime] = useState("");
  const [notes, setNotes] = useState("");
  const [dispositionModal, setDispositionModal] = useState("");
  const [dispositionTypeModal, setDispositionTypeModal] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [subject, setSubject] = useState("Call Back");

  const { getmeeting, loading } = useSelector((state) => state.meetingReducer);
  const [id, setId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [activity, setActivity] = useState("Associate");
  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const handleDisposition = (e) => {
    if (
      dispositionModal == "Call_Back" ||
      dispositionModal == "Mail_Shared" ||
      dispositionModal == "Meeting_Align" ||
      dispositionModal == "Meeting_Done"
    ) {
      e.preventDefault();
      const inputData = new FormData();
      inputData.append("lead_id", itemid);
      inputData.append("activity", activity);
      inputData.append("subject", subject);
      inputData.append("start_date", startdate);
      inputData.append("start_time", starttime);
      inputData.append("assign_to", auth?.id);
      inputData.append("type", "associate_lead");
      inputData.append("remark", notes);

      dispatch(eventInsertHandler(inputData, auth?.token));
      setRefresh(refresh + 1);
      axios
        .get(
          `/api/crm/update-franchise-detail?id=${itemid}&disposition_type=${dispositionTypeModal}&disposition=${dispositionModal}&purpose_of_meeting=${purposeModal}&city=${city}&note=${notes}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          setLoadingg(false);
          setOpenDispositionModal(!openDispositionModal);
          NotificationManager.success(res.data.message);
          setRefresh(refresh + 1);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoadingg(false);
            setErrors(err.response.data.errors);
            NotificationManager.error(err.response.data.errors);
          } else {
            setLoadingg(false);
            NotificationManager.error(err.response.data.errors);
          }
        });
    } else {
      axios
        .get(
          `/api/crm/update-franchise-detail?id=${itemid}&disposition_type=${dispositionTypeModal}&disposition=${dispositionModal}&purpose_of_meeting=${purposeModal}&city=${city}&note=${notes}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          setLoadingg(false);
          setOpenDispositionModal(!openDispositionModal);
          NotificationManager.success(res.data.message);
          setRefresh(refresh + 1);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoadingg(false);
            setErrors(err.response.data.errors);
            NotificationManager.error(err.response.data.errors);
          } else {
            setLoadingg(false);
            NotificationManager.error(err.response.data.errors);
          }
        });
    }
  };

  console.log(openNotesModal, "model");
  return (
    <>
      <a
        href="#"
        className="text-danger"
        onClick={() => {
          setOpenNotesModal(true);
          setId(itemid);
        }}
      >
        <FiEye />
      </a>

      <a
        href="#"
        className="text-danger ml-2"
        onClick={() => {
          setOpenDispositionModal(true);
          setModalId(itemid);
        }}
      >
        <FiTablet />
      </a>
      <Modal
        isOpen={openNotesModal}
        toggle={() => setOpenNotesModal(!openNotesModal)}
      >
        <ModalHeader toggle={() => setIsOpen(!openNotesModal)}>
          Notes
        </ModalHeader>
        <ModalBody className="p-0 m-0">
          <Note id={id} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openDispositionModal}
        toggle={() => setOpenDispositionModal(!openDispositionModal)}
      >
        <ModalHeader toggle={() => setIsOpen(!openDispositionModal)}>
          Disposition Update
        </ModalHeader>
        <ModalBody className="p-0 m-0">
          <Row className="m-3">
            <Col sm={12}>
              <FormGroup className="row">
                <Label
                  for="name"
                  className="col-md-12 control-label col-form-label col-12"
                >
                  Disposition Type
                </Label>
                <select
                  id="paginate"
                  onChange={(e) => setDispositionTypeModal(e.target.value)}
                  value={dispositionTypeModal}
                  className="custom-select"
                >
                  <option value=""> Select </option>
                  <option value="Connected">Connected</option>
                  <option value="Not_Connected">Not Connected</option>
                  <option value="Payment_Done">Payment Done</option>
                  <option value="Dump_Data">Dump Data</option>
                </select>
              </FormGroup>
            </Col>

            {dispositionTypeModal == "Connected" ? (
              <Col sm={12}>
                <FormGroup className="row">
                  <Label
                    for="name"
                    className="col-md-12 control-label col-form-label col-12"
                  >
                    Connected
                  </Label>
                  <select
                    id="paginate"
                    onChange={(e) => setDispositionModal(e.target.value)}
                    value={dispositionModal}
                    className="custom-select"
                  >
                    <option value=""> Select </option>
                    <option value="Mail_Shared">Mail Shared</option>
                    <option value="Meeting_Align">Meeting Align</option>
                    <option value="Meeting_Done">Meeting Done</option>
                    <option value="Follow_Up">Follow up(Hot)</option>
                    <option value="Mail_Reshared">Mail Reshared</option>
                    <option value="Call_Back">Call Back</option>
                    <option value="Not_Interested">Not Interested</option>
                    <option value="Meeting_Done_NOI">
                      Meeting Done Not Interested
                    </option>
                  </select>
                </FormGroup>
              </Col>
            ) : null}
            {dispositionModal == "Call_Back" ||
            dispositionModal == "Mail_Shared" ||
            dispositionModal == "Meeting_Align" ||
            dispositionModal == "Meeting_Done" ? (
              <>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <label className="col-md-4 control-label col-form-label col-12 text-md-right text-danger">
                      Start Date &amp; Time
                    </label>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <Input
                            type="date"
                            value={startdate}
                            onChange={(e) => setStartdate(e.target.value)}
                            invalid={!!errors.start_date}
                          />
                          <FormFeedback className="text-left">
                            {errors.start_date}
                          </FormFeedback>
                        </div>
                        <div className="col-md-6 form-group">
                          <div className="input-group clockpicker-example">
                            <Input
                              type="time"
                              value={starttime}
                              onChange={(e) => setStarttime(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {dispositionTypeModal == "Not_Connected" ? (
              <Col sm={12}>
                <FormGroup className="row">
                  <Label
                    for="name"
                    className="col-md-12 control-label col-form-label col-12"
                  >
                    Not Connected
                  </Label>
                  <select
                    id="paginate"
                    onChange={(e) => setDispositionModal(e.target.value)}
                    value={dispositionModal}
                    className="custom-select"
                  >
                    <option value=""> Select </option>
                    <option value="Npc">NPC</option>
                    <option value="Switch_Off">Switch Off</option>
                    <option value="Invalid_Number">Invalid Number</option>
                    <option value="Call_Rejected">Call Rejected</option>
                  </select>
                </FormGroup>
              </Col>
            ) : null}

            <Col sm={12}>
              <FormGroup className="row">
                <Label
                  for="name"
                  className="col-md-12 control-label col-form-label col-12"
                >
                  Purpose Of Meeting
                </Label>
                <select
                  id="paginate"
                  onChange={(e) => SetPurposeModal(e.target.value)}
                  value={purposeModal}
                  className="custom-select"
                >
                  <option value=""> Select </option>
                  <option value="Mitra">Mitra</option>
                  <option value="Sarthi">Sarthi</option>
                  <option value="Faciliation">Faciliation</option>
                </select>
              </FormGroup>
            </Col>

            <Col sm={12}>
              <FormGroup className="row">
                <Label
                  for="city"
                  className="col-md-12 control-label col-form-label col-12"
                >
                  City
                </Label>
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Enter a City"
                  className="custom-select"
                />
              </FormGroup>
            </Col>

            <Col sm={12}>
              <FormGroup className="row">
                <Label
                  for="city"
                  className="col-md-12 control-label col-form-label col-12"
                >
                  Remarks
                </Label>
                <textarea
                  type="text"
                  value={notes}
                  onChange={({ target: { value } }) => setNotes(value)}
                  className="custom-select"
                  placeholder="Write Your Remarks Here"
                />
              </FormGroup>
            </Col>

            <Col sm={12}>
              <div className="text-center">
                <Loader loading={loading}>
                  <Button
                    type="submit"
                    onClick={handleDisposition}
                    color="secondary"
                    size="sm"
                    className="mr-1"
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    size="sm"
                    onClick={() => (window.location.href = "/associate-team")}
                  >
                    Close
                  </Button>
                </Loader>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Optionsmodel;
