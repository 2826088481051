import Axios from "axios";
import {
  LEAD_REQUEST,
  LEAD_DETAIL_REQUEST,
} from "../../components/utils/constant";
import _ from "lodash";

export const getEngagedLeads = (
  data,
  paginate,
  search,
  page,
  sort,
  sectorSearch,
  project,
  source,
  leadDate,
  id,
  campaigndetails
) => {
  return {
    type: "ENGAGED_AGENTS_FULFILLED",
    payload: Axios.get(
      `/api/crm/agent-engaged-leads-list?agent_id=${
        id ?? ""
      }&paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&sectorSearch=${sectorSearch}&project=${project}&source=${source}&leadDate=${leadDate}&compains=${campaigndetails}`,
      {
        headers: { Authorization: `Bearer ${data}` },
      }
    ).catch((err) => err),
  };
};

// export const getLeadUser = (search, auth) => {
//   console.log(search, "rajuuankit");

//   return {
//     type: LEAD_REQUEST,
//     payload: Axios.get(`/api/crm/get-lead/${search}`, {
//       headers: { Authorization: `Bearer ${auth}` },
//     }).catch((err) => err),
//   };
// };

export const getLeadUser = (search, auth) => async (dispatch) => {
  try {
    dispatch({ type: "LEAD_REQUEST_PENDING" });
    const response = await Axios.get(`/api/crm/get-lead/${search}`, {
      headers: { Authorization: `Bearer ${auth}` },
    });
    dispatch({ type: "LEAD_REQUEST_FULFILLED", payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const fetchLeadDetail = (...data) => {
  return {
    type: LEAD_DETAIL_REQUEST,
    payload: Axios.get(`/api/crm/fetch-lead/${data[0]}/details`, {
      headers: { Authorization: `Bearer ${data[1]}` },
    }).catch((err) => err),
  };
};
export const getCrmUsersApi = (data) => {
  return {
    type: "LEAD_DATA",
    payload: Axios.get(`/api/crm/crm-users`, {
      headers: { Authorization: `Bearer ${data}` },
    }).catch((err) => err),
  };
};

export const createSourceApi = (data) => {
  return {
    type: "SOURCE_CREATE",
    payload: Axios.post(`/api/crm/create-source`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    }).catch((err) => err),
  };
};

export const getSourceList = (token) => {
  return {
    type: "SOURCE_LIST",
    payload: Axios.get(`/api/crm/sources`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const createLeadStatus = (data) => {
  return {
    type: "CREATE_LEAD_STATUS",
    payload: Axios.post(`/api/crm/create-lead-status`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    }).catch((err) => err),
  };
};

export const getLeadStatusList = (token) => {
  return {
    type: "LEAD_STATUS_LIST",
    payload: Axios.get(`/api/crm/lead-status-list`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const createRating = (data) => {
  return {
    type: "LEAD_RATING",
    payload: Axios.post(`/api/crm/create-lead-rating`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    }).catch((err) => err),
  };
};

export const leadRatingListData = (token) => {
  return {
    type: "LEAD_RATING_LISTING",
    payload: Axios.get(`/api/crm/lead-ratings`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const createLead = (data, token) => {
  return {
    type: "CREATE_LEAD",
    payload: Axios.post(`/api/crm/create-lead`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const importLead = (data, token) => {
  return {
    type: "IMPORT_LEAD",
    payload: Axios.post(`/api/crm/importlead`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const leadsApi = (
  data,
  paginate,
  searches,
  page,
  sort,
  sectorSearch,
  project,
  source,
  leadDate,
  disposition,
  createdByy,
  priority,
  leadStatuss,
  campaign,
  start,
  end,
  roleId,
  productOffered,
  assignToUser,
  vertical,
  activityStartDate,
        activityEndDate
) => {
  return {
    type: "LEADS",
    payload: Axios.get(
      `/api/crm/leads-list?paginate=${paginate}&search=${searches}&page=${page}&sort=${sort}&sectorSearch=${sectorSearch}&project=${project}&source=${source}&leadDate=${leadDate}&disposition=${disposition}&created_by=${createdByy}&priority=${priority}&status_id=${leadStatuss}&campaigns=${campaign}&start=${start}&end=${end}&role_id=${roleId}&project_offered=${productOffered}&assign_to=${assignToUser}&vertical=${vertical}&assign_date_start=${activityStartDate}&assign_date_end=${activityEndDate}`,
      {
        headers: { Authorization: `Bearer ${data}` },
      }
    ).catch((err) => err),
  };
};

export const agentLeadsApi = (
  data,
  paginate,
  search,
  page,
  sort,
  sectorSearch,
  project,
  source,
  leadDate,
  id,
  campaigndetails
) => {
  return {
    type: "LEADS",
    payload: Axios.get(
      `/api/crm/agent-leads-list?agent_id=${
        id ?? ""
      }&paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&sectorSearch=${sectorSearch}&project=${project}&source=${source}&leadDate=${leadDate}&compains=${campaigndetails}`,
      {
        headers: { Authorization: `Bearer ${data}` },
      }
    ).catch((err) => err),
  };
};

export const leadbyid = (data) => {
  return {
    type: "LEAD_BY_ID",
    payload: Axios.get(`/api/crm/leads-list-by-id?id=${data.id}`, {
      headers: { Authorization: `Bearer ${data.token}` },
    }).catch((err) => err),
  };
};

export const updateLeader = (data, token) => {
  return {
    type: "UPDATE_LEAD",
    payload: Axios.post(`/api/crm/update-lead`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const leadDestroy = (data, token) => {
  return {
    type: "LEAD_DELETE",
    payload: Axios.post(`/api/crm/lead/destroy`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const changeReducerState = () => {
  return {
    type: "CHANGE_REDUCER_STATE",
  };
};
