import React, { useState } from "react";
import MainWrapper from "../MainWrapper";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router";
import { FaSpinner } from "react-icons/fa";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import moment from "moment";
import { useHistory } from "react-router";
const Create = () => {
  const [companyName, setCompanyName] = useState("");
  const [brandName, setBrandname] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactDetails, setContactDetails] = useState({
    contactNo: "",
  });
  const [conversion, setConversion] = useState("");
  const [emailDetail, setEmailDetail] = useState({
    email: "",
  });
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [meetingPurpose, setMeetingPurpose] = useState("");
  const [leadSource, setleadSource] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [Amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [website, setWebsite] = useState("");
  const [platform, setPlatform] = useState("");
  const [currentBussinessTurnOver, setCurrentBussinessTurnOver] = useState("");
  const [noOfBussiness, setNoOfBussiness] = useState("");
  const [segment, setSegment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [bussinessType, setBussinessType] = useState("");
  const [ogd, setOgd] = useState("");
  const [edc, setEdc] = useState("");
  const [errors, setErrors] = useState({});
  const [details, setDetails] = useState([
    { contact_person_name: "", contact_number: "", email: "" },
  ]);
  const [leadDate, setLeadDate] = useState("");
  const dispatch = useDispatch();
  const { auth } = useSelector(({ auth }) => auth);
  const [assignTo, setAssignTo] = useState(auth?.id || "");
  const { leadData } = useSelector(({ lead }) => lead);

  const history = useHistory();

  React.useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
    setLeadDate(moment().format("YYYY-MM-DD"));
  }, [auth]);

  const handleSubmit = () => {
    setLoading(true);
    const data = {};
    data["company_name"] = companyName;
    data["lead_status"] = leadStatus;
    data["lead_source"] = leadStatus;
    data["purpose_of_meeting"] = meetingPurpose;
    data["pincode"] = pincode;
    data["address"] = address;
    data["note"] = note;
    data["amount"] = Amount;
    data["payment_status"] = paymentStatus;
    data["conversion"] = conversion;
    data["company_name"] = companyName;
    data["assign_to"] = assignTo;
    data["lead_date"] = leadDate;
    data["website"] = website;
    data["status"] = 1;
    data["brand_name"] = brandName;
    data["platform"] = platform;
    data["current_bussiness_turn_over"] = currentBussinessTurnOver;
    data["no_of_bussiness"] = noOfBussiness;
    data["segment"] = segment;
    data["job_title"] = jobTitle;
    data["bussiness_type"] = bussinessType;
    const detailedArr = [...details];

    axios
      .post(
        "/api/crm/get-crm-team-details-create",
        { ...data, contact_details: detailedArr },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          NotificationManager.success("Added Successfully");
          window.location.href = "/franchise-list";
          setLoading(false);
        }
      })
      .catch((errors) => {
        NotificationManager.error("Something went wrong");
        setLoading(false);
        setErrors(errors?.response?.data?.errors);
      });
  };

  const phoneValid = (val) => {
    return val.length < 11 && !isNaN(val) ? true : false;
  };
  return (
    <MainWrapper>
      <main className="content">
        <Card>
          <CardHeader>Franchise Brand OnBoarding </CardHeader>
          <CardBody>
            <Row>
              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Assigned To
                  </label>

                  <Input
                    className="custom-select col-md-7"
                    type="select"
                    id="assignTo"
                    disabled={true}
                    onChange={(e) => setAssignTo(e.target.value)}
                    value={assignTo}
                    invalid={!!errors?.assign_to}
                  >
                    <option value="">select</option>

                    {(Array.isArray(leadData.users) ? leadData?.users : []).map(
                      (item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      }
                    )}
                  </Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Lead Date
                  </label>

                  <Input
                    type="date"
                    className=" col-md-7"
                    value={leadDate}
                    onChange={({ target: { value } }) => setLeadDate(value)}
                  ></Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Company Name</Label>
                    </Col>

                    <Col>
                      <Input
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <span className="d-block text-danger">
                        {errors?.company_name}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Brand Name</Label>
                    </Col>

                    <Col>
                      <Input
                        value={brandName}
                        onChange={(e) => setBrandname(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Platform</Label>
                    </Col>

                    <Col>
                      <Input
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                      />
                      <span className="d-block text-danger">
                        {errors?.platform}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Current Bussiness Turn Over</Label>
                    </Col>

                    <Col>
                      <Input
                        value={currentBussinessTurnOver}
                        onChange={(e) =>
                          setCurrentBussinessTurnOver(e.target.value)
                        }
                      />
                      <span className="d-block text-danger">
                        {errors?.current_bussiness_turn_over}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Number Of Bussiness</Label>
                    </Col>

                    <Col>
                      <Input
                        value={noOfBussiness}
                        onChange={(e) => setNoOfBussiness(e.target.value)}
                      />
                      <span className="d-block text-danger">
                        {errors?.no_of_bussiness}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Segment</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={segment}
                        onChange={(e) => setSegment(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Brand Acquisition">
                          Brand Acquisition
                        </option>
                        <option value="Grow Your Franchise">
                          Grow Your Franchise
                        </option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Job Title</Label>
                    </Col>

                    <Col>
                      <Input
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                      <span className="d-block text-danger">
                        {errors?.job_title}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Bussiness Type</Label>
                    </Col>

                    <Col>
                      <Input
                        value={bussinessType}
                        onChange={(e) => setBussinessType(e.target.value)}
                      />
                      <span className="d-block text-danger">
                        {errors?.bussiness_type}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {details.map((item, index) => {
                return (
                  <div className="bg-secondary w-100 py-2 mb-3" key={index}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Row className="align-items-center">
                            <Col md={5} className="text-md-right">
                              <Label>Contact Person Name</Label>
                            </Col>

                            <Col>
                              <Input
                                value={item?.contact_person_name}
                                name="contact_person_name"
                                onChange={(e) => {
                                  setDetails(
                                    details.map((item, i) => {
                                      if (index === i) {
                                        return {
                                          ...item,
                                          [e.target.name]: e.target.value,
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Row className="align-items-center">
                            <Col md={5} className="text-md-right">
                              <Label>Contact No.</Label>
                            </Col>

                            <Col md={7}>
                              <Input
                                name="contact_number"
                                value={item?.contact_number}
                                onChange={(e) => {
                                  phoneValid(e.target.value) &&
                                    setDetails(
                                      details.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            [e.target.name]: e.target.value,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Row className="align-items-center">
                            <Col md={5} className="text-md-right">
                              <Label>Email</Label>
                            </Col>

                            <Col>
                              <Input
                                name="email"
                                value={item?.email}
                                onChange={(e) => {
                                  setDetails(
                                    details.map((item, i) => {
                                      if (index === i) {
                                        return {
                                          ...item,
                                          [e.target.name]: e.target.value,
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col>
                        <div className="btn-wrapper">
                          {!index ? (
                            <Button
                              onClick={() =>
                                setDetails([
                                  ...details,
                                  {
                                    contact_number: "",
                                    contact_person_name: "",
                                    email: "",
                                  },
                                ])
                              }
                            >
                              + Add More
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                setDetails(
                                  details.filter((it, i) => i !== index)
                                )
                              }
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>PinCode</Label>
                    </Col>

                    <Col>
                      <Input
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Address</Label>
                    </Col>

                    <Col>
                      <Input
                        type="textarea"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Purpose of Meeting</Label>
                    </Col>

                    <Col>
                      <Input
                        type="text"
                        value={meetingPurpose}
                        onChange={(e) => setMeetingPurpose(e.target.value)}
                      ></Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Website</Label>
                    </Col>

                    <Col>
                      <Input
                        type="text"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Occupation</Label>
                    </Col>

                    <Col>
                      <Input
                        type="text"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Business Type</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={businessType}
                        onChange={(e) => setBusinessType(e.target.value)}
                      >
                        <option value="">Select</option>

                        <option value="education">Education</option>

                        <option value="retail-store">Retail Store</option>
                        <option value="dry-cleaning">Dry Cleaning</option>

                        <option value="government-contractor">
                          Govt. Contractor
                        </option>

                        <option value="fashion">Fashion</option>

                        <option value="f&b">F&B</option>
                        <option value="automobile">Automobile</option>
                        <option value="homedecore">HomeDecore</option>

                        <option value="medical">Medical</option>

                        <option value="other">other</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}
              {/* 
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Lead Source</Label>
                    </Col>

                    <Col>
                      <Input
                        type="text"
                        value={leadSource}
                        onChange={(e) => setleadSource(e.target.value)}
                      ></Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Lead Status</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={leadStatus}
                        onChange={(e) => setLeadStatus(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Hot">Hot</option>
                        <option value="Warm">Warm</option>
                        <option value="Cold">Cold</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Conversion</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={conversion}
                        onChange={(e) => setConversion(e.target.value)}
                      >
                        <option value="">Select</option>

                        <option value="yes">Yes</option>

                        <option value="no">No</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Payment Status</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={paymentStatus}
                        onChange={(e) => setPaymentStatus(e.target.value)}
                      >
                        <option value="">Select</option>

                        <option value="Full Paid">Full Paid</option>

                        <option value="Partial Paid">Partial paid</option>
                        <option value="Unpaid">Unpaid</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Amount</Label>
                    </Col>

                    <Col>
                      <Input
                        type="text"
                        value={Amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Remarks</Label>
                    </Col>

                    <Col>
                      <Input
                        type="textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>OGD</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={ogd}
                        onChange={(e) => setOgd(e.target.value)}
                      >
                        <option value="">Select</option>

                        <option value="yes">Yes</option>

                        <option value="no">No</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>EDC</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={edc}
                        onChange={(e) => setEdc(e.target.value)}
                      >
                        <option value="">Select</option>

                        <option value="yes">Yes</option>

                        <option value="no">No</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}

              <Col md={12}>
                <div className="btn-wrapper text-center">
                  {loading ? (
                    <FaSpinner className="fa fa-spin" />
                  ) : (
                    <Button color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                  <Button className="ml-2" onClick={() => history.goBack()}>
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </main>
    </MainWrapper>
  );
};

export default Create;
