import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  workShopUser,
  workShopCreate,
  workShopList,
  workShopData,
  changeReducerState,
} from "../../store/actions/WorkShopActions";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { Redirect } from "react-router";
import { data } from "jquery";
import { addEdp } from "../../store/actions/EdpAction";
const AddEdp = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mode, setMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentid, setPaymentId] = useState("");
  const [amount, setAmount] = useState("5900");
  const [id, setId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [selectedEdp, setSelectedEdp] = useState("");
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { edpcreated, error } = useSelector((state) => state.edpReducer);

  const { token } = auth;

  useEffect(() => {
    setName(leadDetail?.name);
    setMobile(leadDetail?.mobile);
    setEmail(leadDetail?.email);
    setId(leadDetail?.id);
    setLeadId(leadDetail?.id);
  }, [leadDetail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("payment_img", file);
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("mode", mode);
    data.append("paymentType", paymentType);
    data.append("payment_id", paymentid);
    data.append("amount", amount);
    data.append("id", id);
    data.append("leadId", leadId);
    data.append("receipt", file);

    dispatch(addEdp(data, token));
  };

  useEffect(() => {
    console.log(edpcreated);
    if (edpcreated) {
      setModal(false);
    }
  }, [edpcreated]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Edp
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Edp User
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Edp</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={selectedEdp}
                    onChange={({ target }) => setSelectedEdp(target.value)}
                  >
                    <option value="">Select</option>
                    <option value="edp">Edp</option>
                  </Input>
                </FormGroup>
              </Col>

              {selectedEdp === "edp" && (
                <Col sm="6">
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input value={5900} name="amount" disabled />
                  </FormGroup>
                </Col>
              )}

              <Col sm="6">
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    value={name}
                    name="name"
                    onChange={({ target: { value } }) => setName(value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Mobile</Label>
                  <Input
                    name="mobile"
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    name="mode"
                    value={mode}
                    onChange={({ target: { value } }) => setMode(value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={paymentType}
                    name="paymentType"
                    onChange={({ target: { value } }) => setPaymentType(value)}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id</Label>
                  <Input
                    name="payment_id"
                    value={paymentid}
                    onChange={({ target: { value } }) => setPaymentId(value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddEdp;
