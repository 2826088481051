import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import SMS from "../utils/SMS";
import Mail from "../utils/Mail";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import {
  leadsApi,
  leadDestroy,
  getSourceList,
} from "../../store/actions/LeadActions";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap";
import Select from "react-select";

import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { sectors, projects } from "../../store/actions/MemberShipActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import {
  getCrmUsersApi,
  createSourceApi,
  createLeadStatus,
  getLeadStatusList,
  createRating,
  leadRatingListData,
  createLead,
} from "../../store/actions/LeadActions";
import { SalesDashboardCount } from "../../store/actions/DashboardActions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { addActivity } from "../../store/actions/ActivityActions";
import { getPriority } from "../../store/actions/AllActivityActions";
const List = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);

  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  const {
    leadsLoader,
    error,
    leads,
    leadDestroyStatus,
    getSources,
    getSourcesStatus,
    leadsStatusListStatus,
    leadsStatus,
  } = useSelector((state) => state.lead);
  const {
    sectors: { subcategory },
    projects: { project_reports },
  } = useSelector((state) => state.memberShip);
  const [leadsList, setLeadsList] = useState([]);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(25);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState("");
  const [search, setSearch] = useState("");
  const [searches, setSearches] = useState("");
  const [sectorSearch, setSectorSearch] = useState({});
  const [priority, setPriority] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [project, setProject] = useState({});
  const [campaign, setCampaign] = useState("");
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState("");
  const [leadDate, setLeadDate] = useState("");
  const [createdByAdmin, seCreatedByAdmin] = useState([]);
  const [disposition, setDisposition] = useState("");
  const [productOffered, setProductOffered] = useState("");
  const [createdby, setCreatedBy] = useState("");
  const [leadStatuss, setLeadStatuss] = useState("");
  const [campaignList, setCampaignList] = useState([]);
  const [campaignValue, setCampaignValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [vertical, setVertical] = useState("");
  const [roleId, setRoleId] = useState("");
  const [crmuser, setCrmUser] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [createdByy, setCreatedByy] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [myParam, setMyParam] = useState()
  const [assignToUser, setassignToUser] = useState([]);
  const [query, setQuery] = useState(0);
  const [dispositionList, setDispositionList] = useState([]);
  const [activityStartDate, setActivityStartDate] = useState("");
  const [activityEndDate, setActivityEndDate] = useState("");
  const [mail, setMail] = useState({
    users: "",
    message: "",
    city: "",
  });
  const [mailDetails, setMailDetails] = useState([]);
  const [modal, setModal] = useState(false);
  const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { getmeeting, loading } = useSelector((state) => state.meetingReducer);
  const { dashboardcountsale, countbyid } = useSelector(
    (state) => state.dashboardReducer
  );
  const { priorityy } = useSelector((state) => state.allActivityReducer)
  useEffect(() => {
    if (auth) {
      setRoleId(auth?.roles?.[0]?.id);
    }
  }, [auth]);
  useEffect(() => {
    if (createdby) {
      setCreatedByy(createdby.value);
    }
  }, [createdby]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mo = urlParams.get('assign');
    const lead = urlParams.get('lead');
    const despo = urlParams.get("desposition");
    const currentDate = urlParams.get("TodayLead");
    const startDate = urlParams.get("StartDate");
    const endDate = urlParams.get("EndDate");
    const pr = urlParams.get("Priority");
    // const startDate = urlParams.get("StartDate");

    // const endDate = urlParams.get("EndDate");
    if (pr) {
      setPriority(pr)
    }
    if (currentDate) {
      setLeadDate(currentDate)
    }
    if (startDate) {
      setStartDate(startDate)
    }
    if (endDate) {
      setEndDate(startDate)
    }
    // if(startDate)
    // {
    //   setStartDate(startDate)
    // }
    // if(endDate)
    // {
    //   setEndDate(endDate)
    // }
    if (lead) {
      setPriority(lead)
    }

    if (despo) {
      setDisposition({ value: despo, label: despo })
    }

    const data = createdByAdmin?.find((item) => item?.value == mo);
    setassignToUser(data)

  }, [createdByAdmin]);

  useEffect(() => {
    setassignToUser(createdByAdmin?.find((item) => item?.value == query));
  }, [query])


  useEffect(() => {
    dispatch(
      leadsApi(
        token,
        paginate,
        searches,
        page,
        sort,
        sectorSearch?.value || "",
        project?.value || "",
        source,
        leadDate,
        disposition?.value || "",
        createdByy,
        priority,
        leadStatuss,
        campaign,
        startDate,
        endDate,
        roleId,
        productOffered,
        assignToUser?.value || "",
        vertical,
        activityStartDate,
        activityEndDate
      )
    );

  }, [
    token,
    refresh,
    paginate,
    searches,
    sort,
    page,
    sectorSearch,
    project?.value || "",
    source,
    leadDate,
    disposition?.value,

    createdByy,
    priority,
    leadStatuss,
    campaign,
    startDate,
    endDate,
    roleId,
    productOffered,
    assignToUser,
    vertical,
    activityStartDate,
    activityEndDate,
    query
  ]);
  useEffect(() => {
    dispatch(getPriority(token));
  }, [token]);
  useEffect(() => {
    axios.get(`/api/crm/get-disposition`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
      .then((res) => {
        setDispositionList(res.data?.dispositions);
      })
      .catch((err) => console.log(err));
  }, [auth?.token]);
  useEffect(() => {
    if (leadsStatusListStatus) {
      setLeadStatusData(leadsStatus.leadstatus);
    }
  }, [leadsStatusListStatus]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearches(search);
    }, 500);
    return () => {
      clearTimeout(debounce);
    };
  }, [search]);

  useEffect(() => {
    if (leadsStatus) {
      setLeadsList(leads.leads ? leads.leads.data : []);
      setFrom(leads.leads ? leads.leads.from : null);
      setTo(leads.leads ? leads.leads.to : null);
      setTotal(leads?.leads ? leads?.leads?.total : null);
      setPerPage(leads.leads ? leads.leads.per_page : null);
    }
  }, [leadsStatus, leads.leads]);

  useEffect(() => {
    dispatch(SalesDashboardCount(token, startDate, endDate));
  }, [token, endDate]);

  useEffect(() => {
    dispatch(sectors(token));
  }, [token]);
  useEffect(() => {
    if (!_.isNull(sectorSearch) && sectorSearch) {
      dispatch(projects(token, [sectorSearch?.value]));
    }
  }, [token, sectorSearch]);
  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);
  const sectorData = useMemo(() => subcategory, [subcategory]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  const handleProjectChange = (e) => {
    setProject(e);
  };

  const handleSendLeads = (e) => {
    let filterList;
    e.preventDefault();

    filterList = leadsList.filter((v) => deleteIds.some((i) => i == v?.id));
    axios
      .post(
        `/api/crm/update-leads-assignto`,
        {
          leadIds: filterList,
          assignTo: assignTo?.value || "",
          is_used: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Lead Send Successfully");
        setDeleteIds([]);
        setOpenSendLeadModal(!openSendLeadModal);

        window.location.href = "/leads";
      })
      .catch((err) => console.log(err));
  };

  const handleSendLead = (e) => {
    e.preventDefault();
    setOpenSendLeadModal(!openSendLeadModal);
  };

  const handleSms = (mobile, id) => {
    const data = new FormData();
    data.append("mobile", mobile);
    data.append("message", mail.message);
    data.append("engaged", 1);
    data.append("city", mail.city);
    Axios.post("/api/crm/send-whats-app", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => console.log(data))
      .catch((err) => err);
    const da = {
      agent_id: auth.id,
      agent_mobile: mobile,
      message: mail.message,
      city: mail.city,
      id: id,
    };
    Axios.post("/api/crm/add-whatsapp", da, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {

        setModal(false);
        setMail({ users: "", message: "", city: "" });
        setDeleteIds([]);
        setMailDetails([]);
      })
      .catch((err) => err);
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      const ids = leadsList.reduce((ids, is) => {
        return [...ids, is.id];
      }, []);

      const mobiles = leadsList
        .reduce((ids, is) => {
          return [...ids, is.mobile];
        }, [])
        .join(",");

      const usersWithMobile = leadsList.reduce((ids, is) => {
        return [...ids, { mobile: is.mobile, id: is.id }];
      }, []);
      setDeleteIds(ids);
      setMail((prev) => ({ ...prev, users: mobiles }));
      setMailDetails(usersWithMobile);
    } else {
      setDeleteIds([]);
      setMail({ users: "", message: "", city: "" });
      setMailDetails([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    const user = leadsList.find((item) => item.id === id);
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
      setMailDetails((prev) => prev.filter((item) => item.id !== id));
      setMail((prev) => ({
        ...prev,
        users: prev.users
          .split(",")
          .filter((item) => item !== user?.mobile)
          .join(","),
      }));
    } else {
      const usersWithMobile = [];
      leadsList.forEach((is) => {
        if (is.id === id) {
          usersWithMobile.push({ mobile: is.mobile, id: is.id });
        }
      });
      const findUsersMobile = [];
      deleteIds.forEach((item) => {
        leadsList.some((it) => {
          if (it.id === item) findUsersMobile.push(it.mobile);
        });
      });

      setDeleteIds((deleteIds) => [...deleteIds, id]);
      setMail((prev) => ({
        ...prev,
        users: deleteIds.length
          ? `${findUsersMobile.join(",")},${user?.mobile}`
          : user?.mobile,
      }));
      setMailDetails((prev) => [...prev, ...usersWithMobile]);
    }
  };

  useEffect(() => {
    dispatch(getLeadStatusList(token));
  }, [token]);

  useEffect(() => {
    Axios.get(`/api/crm/get-manager`, {
      headers: { authorization: `Bearer ${token}` },
    })
      .then((res) => seCreatedByAdmin(res.data?.manager))
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    Axios.get(`/api/crm/get-campaign-list`, {
      headers: { authorization: `Bearer ${token}` },
    })
      .then((res) => setCampaignList(res.data?.campaigns))
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    const data = new URL(window.location).search;
    const regex = /\bmobile=(\d+)/;

    setSearch(data.match(regex)?.[1] || "");
  }, []);

  useEffect(() => {
    const page = new URL(window.location.href);
    const getPage = page.searchParams.get("page");
    if (getPage) {
      setPage(getPage);
    }
  }, []);

  useEffect(() => {
    Axios.get(`/api/crm/get-campaign-user?campaign=${campaignValue?.value || ""}`, {
      headers: { authorization: `Bearer ${token}` },
    })
      .then((res) => setCrmUser(res?.data?.campaignsUser))
      .catch((error) => console.log(error));
  }, [token, campaignValue]);

  useEffect(() => {
    if (leadDestroyStatus) {
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh((refresh) => refresh + 1);
    }
  }, [leadDestroyStatus]);
  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify(deleteIds),
      };
      dispatch(leadDestroy(delArr, token));
    }
  };

  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
      };
      dispatch(leadDestroy(delArr, token));
    }
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };



  const csvHead = [
    [
      "Full Name",
      "Mobile",
      "Email",
      "Bussiness Name",
      "Priority",
      "Sector",
      "Industry",
      "Source",
      "Lead Date",
      "Campaign",
      "Disposition type",
      "Assigned",
      "Disposition",
      "Comment",
      "Note Date",
      "State",
      "City",
      
    ],
  ];
  const csvData = (Array.isArray(leadsList) ? leadsList : [])?.map?.((cv) => {

    const leadNote = Array.isArray(cv?.lead_note) ? cv?.lead_note.join(', ') : '';
    return [
      //console.log(leadNote,'leadsListNote'),
      // const leadNote = cv?.lead_note ? cv.lead_note.join(', ') : '';
      cv?.full_name,
      cv?.mobile,
      cv?.email,
      cv?.company,
      cv?.priority,
      cv?.lead_sector?.name,
      cv?.lead_industry?.name,
      cv?.lead_source?.name,
      moment(cv?.created_at).format("Do MMM YYYY"),
      cv?.type,
      cv?.disposition_type,
      cv?.crm_user_tbl?.name,
      // cv?.lead_note
      //         .map((ind, i) => _.get(ind, "title", "note"))
      //         .join(",")


      cv?.lead_note?.map((item) => {
        return item?.disposition?.remarks || ""; // Return remarks if it exists, otherwise return an empty string
      }).filter(Boolean).join("|"),

      cv?.lead_note?.map((item) => {
        return item?.note || "";

      }).filter(Boolean).join("|"),
      cv?.lead_note?.map((item) => {
        return item?.created_at || "";

      }).filter(Boolean).join("|"),

      cv?.state ? cv?.state : "",
      cv?.city ? cv?.city : "",
    ]
    // cv?.lead_note ? cv?.lead_note.note.join(', ') : '',

  });


  // const data=createdByAdmin.filter((item)=>item?.value==myParam);
  //  console.log(data,"data");
  //  useEffect(()=>
  //  {
  //   console.log(myParam,"myParam");
  //   console.log(createdByAdmin,"createdByAdmin")
  //   const data=createdByAdmin?.filter((item)=>item?.value==myParam);
  //   console.log(data,"data");
  //   setassignToUser(data)
  //  },[myParam,createdByAdmin])
  const exportData = csvHead.concat(csvData);

  const handleSmsSubmit = () => {
    mailDetails.forEach((item) => {
      handleSms(item.mobile, item.id);
    });
  };
  if (!isAuthenticated) return <Redirect to="/login" />;
  const activeData = (leadsList ? leadsList : [])?.filter((item) => item?.crm_assign_leads &&
    item?.crm_assign_leads?.is_used === 0);
  const inActiveData = (leadsList ? leadsList : [])?.filter((item) => item?.crm_assign_leads &&
    item?.crm_assign_leads?.is_used !== 0);


  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="c-card-top">
                    <div className="c-card-top-title">
                      <h4 className="mb-0">Leads</h4>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                        </div>
                        <Input
                          placeholder="Search here..."
                          value={search}
                          onChange={handleSearch}
                          className="c-form-control-serch"
                        />
                        <div className="input-group-append">
                          <button
                            title="Advance Search"
                            onClick={() => {
                              advanceSearch
                                ? setAdvanceSearch(false)
                                : setAdvanceSearch(true);
                            }}
                            id="c_searchBlock_toggle"
                            className="btn btn-secondary btn-sm c-card-top-block-icon"
                            type="button"
                          >
                            <i
                              className="fa fa-angle-down fa-2x text-dark"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            className="btn btn-secondary btn-sm"
                            type="button"
                            onClick={handleSerchByClick}
                          >
                            GO
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          advanceSearch ? "c-card-top-block" : "d-none"
                        }
                        id="c_searchBlock"
                      >
                        <div className="c-card-top-block-sec">
                          <div className="c-card-top-block-item">
                            <label>Sector</label>
                            <Select
                              options={
                                sectorData?.map?.((item) => ({
                                  value: item.value,
                                  label: item.label,
                                })) || []
                              }
                              className="react-select-container"
                              classNamePrefix="react-select"
                              placeholder="Select Sector"
                              onChange={(e) => {
                                setSectorSearch(e);
                                setProject({});
                              }}
                              value={
                                Object.keys(sectorSearch).length
                                  ? sectorSearch
                                  : ""
                              }
                            ></Select>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Industry</label>
                            <Select
                              value={Object.keys(project).length ? project : ""}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={
                                subSubCategories?.map?.((item) => ({
                                  value: item.value,
                                  label: item.label,
                                })) || []
                              }
                              placeholder="Select Industry"
                              onChange={handleProjectChange}
                            ></Select>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Created By</label>
                            {/* <select
                              id="paginate"
                              onChange={(e) => setCreatedBy(e.target.value)}
                              value={createdby}
                              className="custom-select"
                            >
                              <option value="">Created By</option>
                              {createdByAdmin
                                ? createdByAdmin.map((cr) => (
                                    <option value={cr?.value} key={cr.value}>
                                      {cr?.label}
                                    </option>
                                  ))
                                : null}
                            </select> */}
                            <Select
                              options={[...createdByAdmin]}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              onChange={(createdby) => setCreatedBy(createdby)}
                              value={createdby}
                            />
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Assigned To</label>
                            <Select
                              id="paginate"
                              onChange={(e) => setassignToUser(e)}
                              value={assignToUser}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={createdByAdmin?.map?.((item) => ({
                                value: item?.value,
                                label: item?.label,
                              }))}

                            />

                            {/* <Select
                              options={[...createdByAdmin]}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              onChange={(assignToUser) =>
                                setassignToUser(assignToUser)
                              }
                              value={assignToUser}
                            /> */}
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Lead Status</label>
                            <select
                              id="paginate"
                              value={leadStatuss}
                              onChange={(e) => setLeadStatuss(e.target.value)}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              {leadStatusData
                                ? leadStatusData?.map((cr) => (
                                  <option value={cr?.value} key={cr.value}>
                                    {cr?.label}
                                  </option>
                                ))
                                : null}
                            </select>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Campaign</label>
                            <select
                              id="paginate"
                              value={campaign}
                              onChange={(e) => setCampaign(e.target.value)}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              <option value="workshop">Workshop</option>
                              <option value="IID Sale">IID-Sale</option>
                            </select>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Product Offered</label>
                            <Input
                              type="select"
                              className="custom-select"
                              onChange={(e) =>
                                setProductOffered(e.target.value)
                              }
                              value={productOffered}
                            >
                              <option value="">Select Type</option>
                              <option value="Incubatee (I)Membership">
                                Incubatee (I)Membership
                              </option>
                              <option value="Incubatee (W)Workshop">
                                Incubatee (W)Workshop
                              </option>
                              <option value="Incubatee (P)Prototype Project report">
                                Incubatee (P)Prototype Project report
                              </option>
                              <option value="Incubatee (O)Report Volumes/Courses/Services/Customized Project report/Others">
                                Incubatee (O)Report
                                Volumes/Courses/Services/Customized Project
                                report/Others
                              </option>

                              <option value="Corporate-Incubation/Business Networking">
                                Corporate-Incubation/Business Networking
                              </option>
                              <option value="Associate-Incubation/Business Networking Membership">
                                Associate-Incubation/Business Networking
                                Membership
                              </option>
                              <option value="Membership">Membership</option>
                              <option value="Prototype Report">
                                Prototype Report
                              </option>
                              <option value="Customized Report">
                                Customized Report
                              </option>
                              <option value="Workshop">Workshop</option>
                              <option value="Course">Course</option>
                            </Input>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Sources</label>
                            <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) => setSource(e.target.value)}
                            >
                              <option value="">select</option>
                              {sources
                                ? sources.map((sou, i) => (
                                  <option value={sou?.value} key={i}>
                                    {sou?.label}
                                  </option>
                                ))
                                : null}
                            </Input>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Desposition</label>
                            {/* <Input
                              type="select"
                              className="custom-select"
                              id="source"
                              invalid={!!error?.source}
                              onChange={(e) => setDisposition(e.target.value)}
                            >
                              <option value="">select</option>
                              {dispositionList
                                        ? dispositionList.map((cr) => (
                                          <option value={cr?.remarks} key={cr?.id}>
                                            {cr?.remarks}
                                          </option>
                                        ))
                                        : ""}
                            </Input> */}
                            <Select
                              id="paginate"
                              onChange={(e) => setDisposition(e)}
                              value={disposition}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={dispositionList?.map?.((cr) => ({
                                value: cr?.remarks,
                                label: cr?.remarks,
                              }))}
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Lead Date</label>
                            <Input
                              type="date"
                              placeholder="Lead Date"
                              onChange={(e) => setLeadDate(e.target.value)}
                            />
                          </div>


                          <div className="c-card-top-block-item">
                            <label>Assinged Start Date</label>
                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setActivityStartDate(e.target.value)}
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label> Assinged End Date</label>
                            <Input
                              type="date"
                              placeholder="End Date"
                              onChange={(e) => setActivityEndDate(e.target.value)}
                            />
                          </div>


                          <div className="c-card-top-block-item">
                            <label>Start Date</label>
                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label>End Date</label>
                            <Input
                              type="date"
                              placeholder="End Date"
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label> Priority(Interested)</label>
                            <Input
                              type="select"
                              className="custom-select"
                              value={priority}
                              onChange={({ target }) =>
                                setPriority(target.value)
                              }
                            >
                              <option value="">select</option>
                              {priorityy?.map((item) => {
                                return (
                                  <option value={item?.title} key={item?.id}>{item?.title}</option>
                                )
                              })}

                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Vertical</label>
                            <Input
                              type="select"
                              className="custom-select"
                              value={vertical}
                              onChange={({ target }) =>
                                setVertical(target.value)
                              }
                            >
                              <option value="">select</option>

                              <option value="Membership">Membership</option>
                              <option value="Workshop">Workshop</option>
                              <option value="Project_Report">
                                Project Report
                              </option>
                              <option value="Industrial_Report">
                                Industrial Report
                              </option>
                              <option value="Courses">Courses</option>
                              <option value="Franchise">Franchise</option>
                              <option value="Startup">Startup</option>

                              <option value="Edp">Edp</option>
                            </Input>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Activity Status</label>
                            <Input
                              type="select"
                              className="custom-select"
                              value={leadStatus}
                              onChange={({ target }) =>
                                setLeadStatus(target.value)
                              }
                            >

                              <option value="">select</option>
                              <option value="No">Activity Done</option>
                              <option value="Yes">Activity Not Done</option>




                            </Input>
                          </div>
                          <div className="c-card-top-block-action">
                            <button
                              onClick={() => window.location.reload("/leads")}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Reset
                            </button>
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-card-top-buttons">
                      <div role="group" className="btn-group">
                        {permissions ? (
                          permissions.find((ps) => ps.name === "lead-create") !=
                            null ? (
                            <Link to="/leads/add">
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm mr-1"
                              >
                                <i className="fa fa-plus" />
                                &nbsp;&nbsp;Add New
                              </button>
                            </Link>
                          ) : null
                        ) : null}

                        <Link to="/leads/imports">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm mr-1"
                          >
                            <i className="fa fa-download" />
                            &nbsp;&nbsp;Import Leads
                          </button>
                        </Link>

                        <div className="btn-group">
                          <button
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle btn btn-secondary btn-sm mr-1"
                          >
                            <i className="fa fa-list" />
                            &nbsp;&nbsp;Leads Tools
                          </button>
                          <div className="dropdown-menu">
                            <a href="#" className="dropdown-item">
                              Lead Status
                            </a>
                            <a href="#" className="dropdown-item">
                              Lead Rating
                            </a>
                            <a href="#" className="dropdown-item">
                              Source
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header pl-3 d-card-header">
                    <SMS
                      cb={handleSmsSubmit}
                      modal={modal}
                      mail={mail}
                      setMail={setMail}
                      setModal={setModal}
                    />

                    {permissions ? (
                      permissions.find((ps) => ps.name === "lead-delete") !=
                        null ? (
                        <button
                          className="btn btn-secondary btn-sm"
                          disabled={!deleteIds.length}
                          onClick={handleDelete}
                        >
                          <i className="fa fa-trash pr-1" /> Delete
                        </button>
                      ) : null
                    ) : null}

                    {permissions ? (
                      permissions.find((ps) => ps.name === "lead-edit") !=
                        null ? (
                        <button
                          className="btn btn-secondary btn-sm"
                          data-toggle="modal"
                          data-target="#massUpdate"
                        >
                          <i className="fa fa-list pr-1" /> Mass Update
                        </button>
                      ) : null
                    ) : null}
                    {/* <button
                      className="btn btn-secondary btn-sm"
                      title="Refresh"
                    >
                      <i className="fa fa-refresh" />
                    </button> */}

                    <button
                      className="btn btn-secondary btn-sm mr-1"
                      disabled={!deleteIds.length}
                      onClick={handleSendLead}
                    >
                      <i className="fa fa-list pr-1"></i> Send Lead
                    </button>

                    <Modal
                      isOpen={openSendLeadModal}
                      toggle={() => {
                        setOpenSendLeadModal(!openSendLeadModal);
                      }}
                    >
                      <ModalHeader
                        toggle={() => {
                          setOpenSendLeadModal(!openSendLeadModal);
                        }}
                      >
                        <form onSubmit={handleSendLeads}>
                          <Row className="m-3">
                            <Col sm={12}>
                              <FormGroup className="row">
                                {/* <Label
                                  for="name"
                                  className="col-md-12 control-label col-form-label col-12"
                                >
                                  Sales Team
                                </Label>
                                <Input
                                  type="select"
                                  className="col-md-12"
                                  onChange={(e) => setAssignTo(e.target.value)}
                                  value={assignTo}
                                >
                                  <option>select</option>
                                  <option value={auth?.id}>{auth?.name}</option>
                                  {dashboardcountsale?.getTeamList?.map(
                                    (cr) => {
                                      return (
                                        <option value={cr?.id} key={cr.id}>
                                          {cr?.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Input> */}
                                <Label
                                  for="name"
                                  className="col-md-12 control-label col-form-label col-12"
                                >
                                  Select Campaign
                                </Label>
                                <Select
                                  className="col-md-12"
                                  onChange={(e) =>
                                    setCampaignValue(e)
                                  }
                                  options={campaignList?.map((cr) => {
                                    return { value: cr.name, label: cr.name }
                                  })}
                                  value={campaignValue}
                                />


                                <Label
                                  for="name"
                                  className="col-md-12 control-label col-form-label col-12"
                                >
                                  Select User
                                </Label>
                                <Select
                                  type="select"
                                  className="col-md-12"
                                  onChange={(e) => setAssignTo(e)}
                                  value={assignTo}
                                  options={
                                    crmuser?.map((cr) => {
                                      return { value: cr.id, label: cr.name }
                                    })
                                  }
                                />

                              </FormGroup>
                            </Col>

                            <Col sm={12}>
                              <div className="text-center">
                                <Loader loading={loading}>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    size="sm"
                                    className="mr-1"
                                  >
                                    Send
                                  </Button>
                                  <Button
                                    type="button"
                                    color="secondary"
                                    size="sm"
                                    onClick={() =>
                                      (window.location.href = "/associate-team")
                                    }
                                  >
                                    Close
                                  </Button>
                                </Loader>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </ModalHeader>
                      <ModalBody className="p-0 m-0"></ModalBody>
                    </Modal>
                    <div className="box-tools pull-right">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                      {auth?.id == 10 && (
                        <CSVLink
                          filename={"LeadList11.csv"}
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Export To CSV"
                          data={exportData}
                        >
                          <i className="fa fa-file-excel-o" />
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    <CustomInput
                                      id="default"
                                      type="checkbox"
                                      onChange={(e) => handleMultiSelect(e)}
                                      checked={
                                        deleteIds.length === leadsList.length &&
                                        deleteIds.length
                                      }
                                    />
                                  </div>
                                </th>

                                <th className="font-weight-bold">Full Name</th>
                                <th className="font-weight-bold">Mobile</th>
                                {/* <th className="font-weight-bold">Email</th> */}
                                {/* <th className="font-weight-bold">
                                  Bussiness Name
                                </th> */}
                                {/* <th className="font-weight-bold">
                                  Disposition type
                                </th> */}
                                {/* <th className="font-weight-bold">
                                  Disposition
                                </th> */}
                                <th className="font-weight-bold">Priority</th>
                                <th className="font-weight-bold">Sector</th>
                                <th className="font-weight-bold">Industry</th>
                                <th className="font-weight-bold">Source</th>
                                <th className="font-weight-bold">Assigned</th>
                                <th className="font-weight-bold">Lead Date</th>
                                <th className="font-weight-bold">Campaign</th>
                                <th className="font-weight-bold">
                                  Vertical Type
                                </th>

                                {/* <th className="font-weight-bold">
                                  Want to Start Business
                                </th> */}

                                <th className="font-weight-bold">State</th>
                                <th className="font-weight-bold">City</th>
                                <th className="font-weight-bold">Lat</th>
                                <th className="font-weight-bold">Long</th>
                                {/* <th className="font-weight-bold">Pincode</th> */}

                                {permissions.find(
                                  (ps) =>
                                    ps.name === "lead-delete" ||
                                    ps.name === "lead-edit"
                                ) != null ? (
                                  <th className="text-right font-weight-bold">
                                    Actions
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {leadsLoader ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={leadsLoader} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>

                                  {(leadStatus === "" && leadsList
                                    ?
                                    leadStatus === "" && leadsList
                                      .sort((item) =>
                                        item?.crm_assign_leads &&
                                          item?.crm_assign_leads?.is_used === 0
                                          ? -1
                                          : 0
                                      )
                                    : leadStatus === "Yes" ? activeData : leadStatus === "No" ? inActiveData : [])
                                    .map((leadsl, i) => (
                                      <tr
                                        key={Math.random()}
                                        style={{
                                          background:
                                            leadsl?.crm_assign_leads &&
                                              leadsl?.crm_assign_leads
                                                ?.is_used === 0
                                              ? "orange"
                                              : "",
                                        }}
                                      >
                                        <td>
                                          <div className="custom-checkbox custom-control">
                                            <CustomInput
                                              id={`default-${leadsl.id}`}
                                              type="checkbox"
                                              checked={deleteIds.some(
                                                (d) => d === leadsl.id
                                              )}
                                              onChange={(e) =>
                                                handleSingleSelect(
                                                  e,
                                                  leadsl.id
                                                )
                                              }
                                            />
                                          </div>
                                        </td>

                                        <td>
                                          <Link
                                            to={`/lead/${leadsl.id}/details?page=${page}`}
                                          >
                                            {leadsl.full_name}
                                          </Link>
                                        </td>
                                        <td>{leadsl.mobile}</td>
                                        {/* <td>{leadsl.email}</td> */}

                                        {/* <td>{leadsl.company}</td> */}
                                        {/* <td className="pr-3">
                                          {leadsl?.disposition_type}
                                        </td> */}
                                        {/* <td className="pr-3">
                                          {leadsl?.disposition?leadsl?.disposition:""}
                                        </td> */}
                                        <td>{leadsl.priority}</td>
                                        <td>
                                          {leadsl?.lead_sector
                                            ? leadsl?.lead_sector?.name
                                            : leadsl?.other_sector ? leadsl?.other_sector : ""
                                          }
                                        </td>
                                        <td>
                                          {leadsl?.lead_industry
                                            ? leadsl?.lead_industry?.name
                                            : leadsl?.other_industry ? leadsl?.other_industry : ""
                                          }
                                        </td>
                                        <td>
                                          {leadsl.lead_source
                                            ? leadsl?.lead_source.name
                                            : ""}
                                        </td>
                                        <td className="pr-3">
                                          {leadsl?.crm_user_tbl?.name}
                                        </td>
                                        <td className="pr-3">
                                          {leadsl.created_at}
                                        </td>
                                        {leadsl.compains ? (
                                          <td className="pr-3">
                                            {leadsl.compains}
                                          </td>
                                        ) : (
                                          <td className="pr-3"> </td>
                                        )}

                                        <td>{!leadsl?.vertical_type || leadsl?.vertical_type == "null" ? "" : leadsl?.vertical_type}</td>

                                        {/* <td className="pr-3">
                                          {leadsl?.want_to_start_bussiness ||
                                            ""}
                                        </td> */}


                                        <td>{leadsl?.state ? leadsl?.state : ""}</td>
                                        <td>{leadsl?.city ? leadsl?.city : ""}</td>
                                        <td>{leadsl?.lead_lat ? leadsl?.lead_lat : ""}</td>
                                        <td>{leadsl?.lead_long ? leadsl?.lead_long : ""}</td>
                                        {/* <td>{leadsl?.pincode}</td> */}

                                        {permissions.find(
                                          (ps) =>
                                            ps.name === "lead-delete" ||
                                            ps.name === "lead-edit"
                                        ) != null ? (
                                          <td className="text-right">
                                            {permissions.find(
                                              (ps) =>
                                                ps.name === "lead-edit"
                                            ) != null ? (
                                              <Link
                                                to={`/leads/edit/${leadsl.id}`}
                                                className="mr-2 text-info"
                                              >
                                                <FiEdit />
                                              </Link>
                                            ) : null}
                                            {permissions.find(
                                              (ps) =>
                                                ps.name === "lead-delete"
                                            ) != null ? (
                                              <Link
                                                href="#"
                                                className="text-danger"
                                                onClick={(e) =>
                                                  handleOneDelete(
                                                    e,
                                                    leadsl.id
                                                  )
                                                }
                                              >
                                                <FiTrash />
                                              </Link>
                                            ) : null}
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                  }
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">

                      {leadStatus === "" &&
                        <span>
                          Showing {from} to {to} of {total} entries
                        </span>
                      }
                      {leadStatus === "Yes" &&
                        <span>
                          Showing {activeData.length} of {total} entries
                        </span>
                      }
                      {leadStatus === "No" &&
                        <span>
                          Showing {inActiveData.length} of {total - activeData.length} entries
                        </span>
                      }
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2000">2000</option>
                          <option value="3000">3000</option>
                          <option value="5000">5000</option>
                          <option value="7000">7000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default List;
