import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
} from "reactstrap";

const SMS = ({ modal, setModal, mail, setMail, cb }) => {
  const inputHandler = (e) => {
    e.persist();
    setMail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <Fragment>
      <Button
        color="secondary"
        size="sm"
        onClick={() => setModal(true)}
        className="mr-1"
      >
        <i className="fa fa-mobile pr-1" /> SMS
      </Button>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <ModalHeader
          tag="h5"
          toggle={() => {
            setModal(false);
          }}
        >
          Send SMS
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              value={mail?.users}
              name="users"
              onChange={inputHandler}
              placeholder="Contact Number"
            />
          </FormGroup>

          <FormGroup>
            <Input
              type="select"
              id="paymentType"
              value={mail?.city}
              name="city"
              onChange={inputHandler}
              className="custom-select"
            >
              <option value="1">Select Product</option>
              <option value="delhi">Delhi</option>
              <option value="lucknow">Lucknow</option>
              <option value="franchise">Franchise</option>
              <option value="project_report">Project Report</option>
              <option value="courses">Courses</option>
              <option value="workshop">Workshop</option>
              <option value="service">Service</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              value={mail?.message}
              onChange={inputHandler}
              name="message"
              placeholder="Message"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Close
          </Button>
          <Button color="primary" onClick={() => cb?.()}>
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default SMS;
