import React, { Fragment, useEffect, useState } from "react";
import { login } from "../../store/actions/AuthActions";
import Logo from "../../assets/img/logo.png";
import { Input, FormFeedback, Button } from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { Redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { isAuthenticated, error, loading } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    const body = document.getElementsByTagName("body");
    body[0].classList.toggle("login-bg");
    return () => body[0].classList.toggle("login-bg");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };
  if (isAuthenticated) return <Redirect to="/" />;
  return (
    <Fragment>
      <main className="main h-100 w-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="form-area mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card mt-5 mb-5">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="text-center">
                        <img
                          src={Logo}
                          alt="Institute for Industrial Development"
                          className="img-fluid"
                        />
                      </div>
                      <h1 className="text-center mb-3 mt-3">IID CRM LOGIN</h1>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Email</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.email}
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <FormFeedback>{error.email}</FormFeedback>
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.password}
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <FormFeedback>{error.password}</FormFeedback>
                        </div>

                        <div className="text-center mt-3">
                          <Button
                            size="lg"
                            color="primary"
                            block
                            disabled={loading}
                          >
                            {loading ? (
                              <FaSpinner className="fa-spin" />
                            ) : (
                              "LOGIN"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Login;
