/* eslint-disable default-case */
import _ from "lodash";

const initialState = {
  courses: [],
  bookcourse: {},
  coursepurchased: false,
  loading: false,
  error: {},
  loading: false,
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COURSE":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_COURSE_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        courses: _.get(payload, "data", {}),
      };

      break;
    case "GET_COURSE_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "BOOK_COURSES":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "BOOK_COURSES_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        coursepurchased: true,
        bookcourse: _.get(payload, "data", {}),
      };

      break;
    case "BOOK_COURSES_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
     return    {
          ...state,
          loading: false,
          error: data.errors,
        };
      } else {
        return {
          ...state,
          loading: false,
          error:data,
        };
      }

  }

  return state;
};

export default courseReducer;
