import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { FiEdit, FiPlus } from "react-icons/fi";
import { useMemo } from "react";
import {
  getCrmUsersApi,
  createSourceApi,
  getSourceList,
  createLeadStatus,
  getLeadStatusList,
  createRating,
  leadRatingListData,
  createLead,
} from "../../store/actions/LeadActions";
import {
  getListContactType,
  getListSourceDetails,
  getServiceList,
} from "../../store/actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { createCrmLead } from "../../store/actions/CrmUserActions";

const LeadDetails = ({ push, user, id }) => {
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const { mssg } = useSelector(({ crmUser }) => crmUser);

  const {
    loading,
    errors,
    userStatus,
    leadData,
    createSource: createSourcee,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
    leadStatusLoading,
    leadStatusSuccess,
    addLeadStatus,
    leadsStatusListStatus,
    leadsStatus,
    addRatingLoading,
    addRatingStatus,
    addRating,
    leadRatingListingStatus,
    leadRatingListing,
    createLeadLoader,
    createLeadStatuss,
    newMobile,
  } = useSelector(({ lead }) => lead);

  const {
    listContactTypeStatus,
    listContactType,
    listSourceDetails,
    listSourceDetailsStatus,
    listService,
    listServiceStatus,
  } = useSelector((state) => state.common);
  const {
    auth: { token },
    isAuthenticated,
    permissions: { permission },
    campaigndetails,
  } = useSelector((state) => state.auth);
  const { auth } = useSelector(({ auth }) => auth);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [contactType, setContactType] = useState("");
  const [assignUser, setAssignUser] = useState([]);
  const [referd, setReferd] = useState(false);
  const [sources, setSources] = useState([]);
  const [sourceName, setSourceName] = useState("");
  const [campaign, setCampaign] = useState("");
  const [addSource, setAddSource] = useState(false);
  const [leadStatus, setLeadStatus] = useState(false);
  const [rating, setRating] = useState(false);
  const [visibility, setVisibility] = useState("");
  const [sourceDetails, setSourceDetails] = useState([]);
  const [sourceDetailsData, setSourceDetailsData] = useState("");
  const [leadStatusData, setLeadStatusData] = useState("");
  const [leadStatusTitle, setLeadStatusTitle] = useState("");
  const [leadRatings, setLeadRatings] = useState("");
  const [leadRating, setLeadRating] = useState([]);
  const [leadGroupSource, setLeadGroupSource] = useState([]);
  const [leadGroupStatus, setLeadGroupStatus] = useState(
    user?.user_lead?.status_id || ""
  );
  const [leadGroupRating, setLeadGroupRating] = useState(
    user?.user_lead?.rating_id || ""
  );
  const [leadCustomerShare, setLeadCustomerShare] = useState([]);
  const [assignTo, setAssignTo] = useState("");
  const [leadDate, setLeadDate] = useState(
    moment(user?.user_lead?.created_at).format("YYYY-MM-DD")
  );
  const [namePrefix, setNamePrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState(user?.user_lead?.phone || "");
  const [company, setCompany] = useState(user?.user_lead?.company || "");
  const [email, setEmail] = useState("");
  const [pincode, setPinCode] = useState("");
  const [address, setAddress] = useState(user?.user_lead?.address || "");
  const [city, setCity] = useState("");
  const [service, setService] = useState(user?.user_lead?.service_id || "");
  const [remark, setRemark] = useState("");
  const [emailNotification, setEmailNotification] = useState(0);
  const [smsNotification, setSmsNotification] = useState(0);
  const [sourceType, setSourceType] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const permissions = useMemo(() => permission ?? [], [permission]);
  const handleSource = (e) => {
    e.preventDefault();
    setAddSource(!addSource);
  };
  const handLeadStatus = (e) => {
    e.preventDefault();
    setLeadStatus(!leadStatus);
  };
  const handleReating = (e) => {
    e.preventDefault();
    setRating(!rating);
  };

  useEffect(() => {
    dispatch(getCrmUsersApi(token));
    setAssignTo(auth?.id);
    setCampaign(campaigndetails);
  }, [token]);

  useEffect(() => {
    if (mssg) {
      NotificationManager.success("Your Form Has Been Submitted Successfully");
      dispatch({ type: "RESET_MESSAGE", payload: false });
      // window.location.reload()
    }
  }, [mssg]);

  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);
  useEffect(() => {
    if (userStatus) {
      setAssignUser(leadData.users);
    }
  }, [userStatus]);
  useEffect(() => {
    dispatch(getLeadStatusList(token));
    setEmail(
      user?.user_lead
        ? user?.user_lead?.email == "undefined" ||
          user?.user_lead?.email == false
          ? user?.email
          : user?.user_lead?.email
        : user?.email
    );
    setMobile(user?.user_lead?.mobile ? user?.user_lead.mobile : user.mobile);
    setPinCode(user?.user_lead?.pincode || "N/A");
    setFullName(user?.user_lead?.name ? user?.user_lead.name : user.name);
    setState(user?.user_lead?.state || "N/A");
    setRemark(user?.user_lead?.remark);
    setCity(user?.user_lead?.city || "N/A");
    setSubCategory({
      value: user?.user_lead?.sector?.id,
      label: user?.user_lead?.sector?.name,
    });

    setSubSubCategory({
      value: user?.user_lead?.industry?.id,
      label: user?.user_lead?.industry?.name,
    });
  }, [token]);

  useEffect(() => {
    if (subCategory) {
      axios
        .get(`/api/crm/subsectordatanew?industry=${subCategory?.value}`)
        .then((res) => {
          setIndustry(res.data.subSubCats);
        })
        .catch((err) => console.log(err));
    }
  }, [subCategory])


  useMemo(() => {
    if (user?.user_lead) {
      setSourceType(JSON.parse(user?.user_lead?.source_type));
    }
  }, [user?.user_lead]);

  useEffect(() => {
    dispatch(leadRatingListData(token));
  }, [token]);
  useEffect(() => {
    dispatch(getServiceList(token));
  }, []);
  useEffect(() => {
    if (listServiceStatus) {
      setServiceList(listService.service);
    }
  }, [listServiceStatus]);
  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${5}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordatanew?industry=${e.value}`)
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (leadsStatusListStatus) {
      setLeadStatusData(leadsStatus.leadstatus);
    }
  }, [leadsStatusListStatus]);

  useEffect(() => {
    if (leadRatingListingStatus) {
      setLeadRatings(leadRatingListing.leadRating);
    }
  }, [leadRatingListingStatus]);
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  useEffect(() => {
    if (listContactTypeStatus) {
      setContactTypeList(listContactType.reminder);
    }
  }, [listContactTypeStatus]);

  useEffect(() => {
    if (createSourceStatus) {
      NotificationManager.success("Source Created Successfully", "Sccess");
      setSources([...sources, createSourcee]);
      setAddSource(!addSource);
      setSourceName("");
    }
  }, [createSourceStatus]);

  const handleAddSource = (e) => {
    e.preventDefault();
    const data = { token, sourceName };
    dispatch(createSourceApi(data));
  };
  useEffect(() => {
    if (leadStatusSuccess) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadStatusData([...leadStatusData, addLeadStatus]);
      setLeadStatus(!leadStatus);
      setLeadStatusTitle("");
    }
  }, [leadStatusSuccess]);

  const handleAddLeadStatus = (e) => {
    e.preventDefault();
    const leadInputArr = {
      token,
      leadStatusTitle,
    };
    dispatch(createLeadStatus(leadInputArr));
  };
  useEffect(() => {
    if (addRatingStatus) {
      NotificationManager.success("Created Successfully", "Sccess");
      setLeadRatings([...leadRatings, addRating]);
      setRating(!rating);
      setLeadRating("");
    }
  }, [addRatingStatus]);
  const handleRatingAdd = (e) => {
    e.preventDefault();
    const ratingadd = { token, leadRating };

    dispatch(createRating(ratingadd));
  };
  useEffect(() => {
    dispatch(getListContactType(token));
  }, [token]);
  const handleMobile = (e) => {
    var mob = "";
    var minx = 0;
    var validChar = "";
    var arrmob = e.target.value;
    for (var i = 1; i <= arrmob.length; i++) {
      validChar = arrmob[i - 1];
      if (
        validChar / 1 == validChar &&
        (minx > 0 || (minx == 0 && validChar > 0))
      ) {
        mob += validChar.toString();
        minx++;
      }
      if (minx == 10) {
        minx = 0;
        if (i < arrmob.length) mob += ",";
      }
    }
    setMobile(mob);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputData = new FormData();
    inputData.append("contact_type", contactType);
    inputData.append("user_id", user?.id);
    inputData.append("agent_id", user?.user_lead?.agent_id);
    inputData.append("assign_to", assignTo);
    inputData.append("lead_date", leadDate);
    inputData.append("full_name", fullName);
    inputData.append("first_name", firstName);
    inputData.append("last_name", lastName);
    inputData.append("phone", phone);
    inputData.append("pincode", pincode);
    inputData.append("compains", campaign);
    inputData.append("company", company);
    inputData.append("email", email);
    inputData.append("address", address);
    inputData.append("city", city);
    inputData.append("service_id", service);
    inputData.append("remark", remark);
    inputData.append("email_notify", emailNotification);
    inputData.append("sms_notify", smsNotification);
    inputData.append("visibility", visibility);
    inputData.append(
      "source_id",
      leadGroupSource.value ? leadGroupSource.value : ""
    );
    inputData.append(
      "source_type",
      sourceType ? JSON.stringify(sourceType) : ""
    );
    inputData.append("source_details", sourceDetailsData);
    inputData.append("status_id", leadGroupStatus);
    inputData.append("rating_id", leadGroupRating);
    inputData.append("sector", JSON.stringify(subCategory));
    inputData.append("industry", JSON.stringify(subSubCategory));
    inputData.append("state", state);
    inputData.append("country", country);
    inputData.append("mobile", mobile);
    dispatch(createCrmLead(inputData, token));
  };

  useEffect(() => {
    if (pincode.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
            setCountry(response?.data[0]?.PostOffice[0]?.Country);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  // OnChange method of sources
  const handleSourceOptGroup = (e) => {
    setSourceType(e);
    if (e.id === "ref") {
      setSourceType(e);
      setReferd(true);
      setLeadGroupSource(e);
      const data = [token, e.value];
      dispatch(getListSourceDetails(data));
    } else {
      setLeadGroupSource([]);
      setReferd(false);
    }
  };
  useEffect(() => {
    if (listSourceDetailsStatus) {
      setSourceDetails(listSourceDetails.sourceDetails);
    }
  }, [listSourceDetailsStatus]);
  const optgroups = useMemo(() => {
    return [
      {
        label: "Others",
        options: sources ? sources : null,
      },
    ];
  }, [sources]);

  return (
    <div>
      <span style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
        {user?.user_lead ? <FiEdit size={20} /> : <FiPlus size={20} />}
      </span>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Lead Details
        </ModalHeader>

        <ModalBody>
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 mb-15">
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                    Assigned To *
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      disabled={true}
                                      id="assignTo"
                                      // onChange={(e) =>
                                      //   setAssignTo(e.target.value)
                                      // }
                                      value={assignTo}
                                      invalid={!!errors?.assign_to}
                                    >
                                      <option value="">select</option>
                                      {assignUser.map((asu) => (
                                        <option
                                          value={asu.value}
                                          key={asu.value}
                                        >
                                          {asu.label}
                                        </option>
                                      ))}
                                    </Input>
                                    <FormFeedback className="text-left">
                                      {errors?.assign_to}
                                    </FormFeedback>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Lead Date
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      className="form-control selectdate"
                                      name="lead_date"
                                      id="lead_date"
                                      value={moment(leadDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                      invalid={!!errors?.lead_date}
                                      disabled
                                    />
                                    <FormFeedback className="text-left">
                                      {errors?.lead_date}
                                    </FormFeedback>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                    Name *
                                  </label>
                                  <div className="col-lg-8">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <Input
                                          type="select"
                                          className="custom-select"
                                          name="nameprefix"
                                          id="nameprefix"
                                          onChange={(e) => {
                                            setNamePrefix(e.target.value);
                                            if (e.target.value === "N/A") {
                                              setFullName(e.target.value);
                                            } else {
                                              setFullName("");
                                            }
                                          }}
                                          value={namePrefix}
                                        >
                                          <option value="Mr.">Mr.</option>
                                          <option value="Mrs.">Mrs.</option>
                                          <option value="Miss">Miss</option>
                                          <option value="N/A">N/A</option>
                                        </Input>
                                      </div>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="name"
                                        onChange={(e) =>
                                          setFullName(e.target.value)
                                        }
                                        value={fullName}
                                        invalid={!!errors?.full_name}
                                      />
                                      <FormFeedback className="text-left">
                                        {errors?.full_name}
                                      </FormFeedback>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right text-danger">
                                    Mobile *
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      placeholder="Mobile"
                                      id="mobile"
                                      value={mobile}
                                      onChange={handleMobile}
                                      invalid={!!errors?.mobile}
                                    />
                                    <FormFeedback className="text-left">
                                      {errors?.mobile}
                                    </FormFeedback>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Whatsapp No.
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      className="form-control"
                                      type="text"
                                      name="phone"
                                      id="phone"
                                      onChange={(e) => setPhone(e.target.value)}
                                      value={phone}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Company
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      className="form-control"
                                      type="text"
                                      name="company"
                                      id="company"
                                      onChange={(e) =>
                                        setCompany(e.target.value)
                                      }
                                      value={company}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Email Id
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      className="form-control"
                                      type="text"
                                      name="email"
                                      id="email"
                                      onChange={(e) => setEmail(e.target.value)}
                                      value={email}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Source
                                  </label>
                                  <div className="col-lg-8 position-relative">
                                    <div className="form-group">
                                      <div className="input-group">
                                        <div className="form-control p-0 border-0">
                                          <Select
                                            options={optgroups}
                                            onChange={(e) =>
                                              handleSourceOptGroup(e)
                                            }
                                            value={sourceType}
                                          />
                                        </div>

                                        {permissions.some(
                                          (item) =>
                                            item.name === "lead-source-create"
                                        ) && (
                                            <span className="input-group-append input-group-btn">
                                              <Link
                                                className="btn btn-default btn-flat openSourcePop"
                                                to="#"
                                                onClick={handleSource}
                                              >
                                                <i className="fa fa-plus"></i>
                                              </Link>
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div
                                      id="addSourcePop"
                                      className="dv3 dvPopB"
                                      style={{
                                        display: addSource ? "" : "none",
                                      }}
                                    >
                                      <div className="arrowB"></div>
                                      <div className="arrow-borderB"></div>
                                      <table className="w-100">
                                        <tbody>
                                          <tr>
                                            <td className="popHeading text-left">
                                              Create Source
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="pl-2 pr-2">
                                              <div id="container">
                                                <div id="dvLeft">
                                                  <table className="w-100">
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <label className="lblcss">
                                                            Source Name
                                                          </label>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="source_name"
                                                            id="source_name"
                                                            value={sourceName}
                                                            onChange={(e) =>
                                                              setSourceName(
                                                                e.target.value
                                                              )
                                                            }
                                                            className="textcl w-100"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="text-left pl-2 pt-2">
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={handleAddSource}
                                              >
                                                {createSourceLoading ? (
                                                  <FaSpinner className="fa-spin" />
                                                ) : (
                                                  "Save"
                                                )}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger closeSourcePop"
                                                onClick={handleSource}
                                              >
                                                Close
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {referd ? (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                  <div className="row form-group">
                                    <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                      Source Details
                                    </label>
                                    <div className="col-lg-8">
                                      <Input
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) =>
                                          setSourceDetailsData(e.target.value)
                                        }
                                        value={sourceDetailsData}
                                      >
                                        <option value="">select</option>
                                        {sourceDetails
                                          ? sourceDetails.map((srl) => (
                                            <option
                                              value={srl.value}
                                              key={srl.value}
                                            >
                                              {srl.label}
                                            </option>
                                          ))
                                          : null}
                                      </Input>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Lead Status
                                  </label>
                                  <div className="col-lg-8 position-relative">
                                    <div className="input-group">
                                      <div className="form-control p-0 border-0">
                                        <Input
                                          type="select"
                                          className="custom-select"
                                          onChange={(e) =>
                                            setLeadGroupStatus(e.target.value)
                                          }
                                          value={leadGroupStatus}
                                        >
                                          <option value="">select</option>
                                          <option value="hot">Hot</option>
                                          <option value="warm">Warm</option>
                                          <option value="active">Active</option>
                                          <option value="rejected-leads">
                                            Rejected Leads
                                          </option>

                                          {leadStatusData
                                            ? leadStatusData.map((srl) => (
                                              <option
                                                value={srl.value}
                                                key={srl.value}
                                              >
                                                {srl.label}
                                              </option>
                                            ))
                                            : null}
                                        </Input>
                                      </div>
                                      {permissions.some(
                                        (item) =>
                                          item.name === "lead-status-create"
                                      ) && (
                                          <span className="input-group-append input-group-btn">
                                            <Link
                                              className="btn btn-default btn-flat openLeadStatusPop"
                                              to="#"
                                              onClick={handLeadStatus}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </Link>
                                          </span>
                                        )}
                                    </div>
                                    <div
                                      id="addLeadStatusPop"
                                      className="dv3 dvPopB"
                                      style={{
                                        display: leadStatus ? "" : "none",
                                      }}
                                    >
                                      <div className="arrowB"></div>
                                      <div className="arrow-borderB"></div>
                                      <table className="w-100">
                                        <tbody>
                                          <tr>
                                            <td className="popHeading text-left">
                                              Create Lead Status
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="pl-2 pr-2">
                                              <div id="container">
                                                <div id="dvLeft">
                                                  <table className="w-100">
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <label className="lblcss">
                                                            Lead Status
                                                          </label>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="leadStatus"
                                                            id="leadStatus"
                                                            value={
                                                              leadStatusTitle
                                                            }
                                                            onChange={(e) =>
                                                              setLeadStatusTitle(
                                                                e.target.value
                                                              )
                                                            }
                                                            className="textcl w-100"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="text-left pl-2 pt-2">
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={handleAddLeadStatus}
                                              >
                                                {leadStatusLoading ? (
                                                  <FaSpinner className="fa-spin" />
                                                ) : (
                                                  "Save"
                                                )}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger closeLeadStatusPop"
                                                onClick={handLeadStatus}
                                              >
                                                Close
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Product offered
                                  </label>
                                  <div className="col-lg-8 position-relative">
                                    <div className="input-group">
                                      <div className="form-control p-0 border-0">
                                        <Input
                                          type="select"
                                          className="custom-select"
                                          onChange={(e) =>
                                            setLeadGroupRating(e.target.value)
                                          }
                                          value={leadGroupRating}
                                        >
                                          <option value="">Select Type</option>
                                          <option value="Incubatee (I)Membership">
                                            Incubatee (I)Membership
                                          </option>
                                          <option value="Incubatee (W)Workshop">
                                            Incubatee (W)Workshop
                                          </option>
                                          <option value="Incubatee (P)Prototype Project report">
                                            Incubatee (P)Prototype Project
                                            report
                                          </option>
                                          <option value="Incubatee (O)Report Volumes/Courses/Services/Customized Project report/Others">
                                            Incubatee (O)Report
                                            Volumes/Courses/Services/Customized
                                            Project report/Others
                                          </option>

                                          <option value="Corporate-Incubation/Business Networking">
                                            Corporate-Incubation/Business
                                            Networking
                                          </option>
                                          <option value="Associate-Incubation/Business Networking Membership">
                                            Associate-Incubation/Business
                                            Networking Membership
                                          </option>
                                          <option value="Membership">
                                            Membership
                                          </option>
                                          <option value="Prototype Report">
                                            Prototype Report
                                          </option>
                                          <option value="Customized Report">
                                            Customized Report
                                          </option>
                                          <option value="Workshop">
                                            Workshop
                                          </option>
                                          <option value="Course">Course</option>
                                          <option value="Media Production">Media Production</option>
                                          <option value="Digital Marketing">Digital Marketing</option>
                                          <option value="IT Services">IT Services</option>
                                          <option value="PMFME Loan Disbursement">PMFME Loan Disbursement</option>

                                        </Input>
                                      </div>
                                      {permissions.some(
                                        (item) =>
                                          item.name === "lead-product-create"
                                      ) && (
                                          <span className="input-group-append input-group-btn">
                                            <Link
                                              className="btn btn-default btn-flat openRatingPop"
                                              to="#"
                                              onClick={handleReating}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </Link>
                                          </span>
                                        )}
                                    </div>
                                    <div
                                      id="addRatingPop"
                                      className="dv3 dvPopB"
                                      style={{ display: rating ? "" : "none" }}
                                    >
                                      <div className="arrowB"></div>
                                      <div className="arrow-borderB"></div>
                                      <table className="w-100">
                                        <tbody>
                                          <tr>
                                            <td className="popHeading text-left">
                                              Create Rating
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="pl-2 pr-2">
                                              <div id="container">
                                                <div id="dvLeft">
                                                  <table className="w-100">
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <label className="lblcss">
                                                            Rating
                                                          </label>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          className="tdcss"
                                                        >
                                                          <Input
                                                            type="text"
                                                            name="rating_name"
                                                            className="textcl w-100"
                                                            value={leadRating}
                                                            onChange={(e) =>
                                                              setLeadRating(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="text-left pl-2 pt-2">
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={handleRatingAdd}
                                              >
                                                {addRatingLoading ? (
                                                  <FaSpinner className="fa-spin" />
                                                ) : (
                                                  "Save"
                                                )}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger closeRatingPop"
                                                onClick={handleReating}
                                              >
                                                Close
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Campaigns
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      value={campaign}
                                      onChange={({ target }) =>
                                        setCampaign(target.value)
                                      }
                                    >
                                      <option value="">select</option>
                                      <option value="workshop">Workshop</option>
                                      <option value="IID Sale">IID-Sale</option>
                                      <option value="Media Production">Media Production</option>
                                      <option value="Digital Marketing">Digital Marketing</option>
                                      <option value="IT Services">IT Services</option>
                                      <option value="PMFME Loan Disbursement">PMFME Loan Disbursement</option>

                                    </Input>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Pincode
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      className="custom-select"
                                      value={pincode}
                                      onChange={({ target }) =>
                                        setPinCode(target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Address
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="textarea"
                                      className="form-control"
                                      rows="4"
                                      name="address"
                                      id="address"
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                      value={address}
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    State
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      rows="4"
                                      name="state"
                                      id="state"
                                      onChange={(e) => setState(e.target.value)}
                                      value={state}
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Country
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      rows="4"
                                      name="country"
                                      id="country"
                                      onChange={(e) =>
                                        setCountry(e.target.value)
                                      }
                                      value={country}
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    City
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      rows="4"
                                      name="city"
                                      id="city"
                                      onChange={(e) => setCity(e.target.value)}
                                      value={city}
                                    ></Input>
                                  </div>
                                </div>

                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Investment Upto
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="select"
                                      className="custom-select"
                                      name="service"
                                      id="service"
                                      onChange={(e) =>
                                        setService(e.target.value)
                                      }
                                      value={service}
                                    >
                                      <option value=""> Select </option>
                                      <option value="0-10 Lakh">
                                        0-10 Lakh
                                      </option>
                                      <option value="10-25 Lakh">
                                        10-25 Lakh
                                      </option>
                                      <option value="25-50 Lakh">
                                        25-50 Lakh
                                      </option>
                                      <option value="50-1 crore">
                                        50-1 crore
                                      </option>

                                      <option value="1-5 crore">
                                        1-5 crore
                                      </option>
                                      <option value="Above 5 crore">
                                        Above 5 crore
                                      </option>
                                    </Input>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    General Notes
                                  </label>
                                  <div className="col-lg-8">
                                    <Input
                                      type="textarea"
                                      className="form-control"
                                      rows="4"
                                      name="remark"
                                      id="remark"
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                      value={remark}
                                    ></Input>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Sector
                                  </label>
                                  <div className="col-lg-8">
                                    <Select
                                      options={[...sectors]}
                                      onChange={handleSector}
                                      value={subCategory}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row form-group">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                                    Industry
                                  </label>
                                  <div className="col-lg-8">
                                    <Select
                                      options={[...industry]}
                                      onChange={(e) => setSubSubCategory(e)}
                                      value={subSubCategory}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="row mb-2 mb-1">
                                  <label className="col-lg-4 control-label col-form-label col-12 text-lg-right"></label>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-md-12"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="row form-group text-center">
                                  <div className="col-lg-4 control-label col-form-label col-12 text-lg-right"></div>
                                  <div className="col-lg-12">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      {createLeadLoader ? (
                                        <FaSpinner className="fa-spin" />
                                      ) : (
                                        "Save"
                                      )}
                                    </button>
                                    <a
                                      onClick={() => setModal(false)}
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      Close
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeadDetails;
