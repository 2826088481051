import React from "react";
import { Button } from "reactstrap";

import { Link } from "react-router-dom";

const Subheader = (props) => {
  return (
    <div className="page-header mb-2">
      <div className="row">
        <div className="col-md-12">
          <div className="d-xl-flex align-items-center justify-content-between justify-content-center">
            <h4>{props.name}</h4>
            <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
              <div className="btn-group mr-1">
                <Link className="btn btn-secondary text-nowrap" to="/leads/add">
                  <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                </Link>
              </div>
              <div className="btn-group mr-1">
                <a href="#" className="btn btn-secondary text-nowrap">
                  <i className="fa fa-download"></i>&nbsp;&nbsp;Import Leads
                </a>
              </div>
              <div className="btn-group">
                <Button
                  type="button"
                  className="btn btn-secondary
                          dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="fa fa-list"></i>&nbsp;&nbsp;Leads Tools
                  <span className="caret"></span>
                  <span className="sr-only">Toggle Dropdown</span>
                </Button>
                <ul className="dropdown-menu" role="menu">
                  <li className="w-100">
                    <Link
                      to="/leads/status"
                      className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                    >
                      Lead Status
                    </Link>
                  </li>
                  <li className="w-100">
                    <Link
                      to="/leads/rating"
                      className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                    >
                      Lead Rating
                    </Link>
                  </li>
                  <li className="w-100">
                    <Link
                      to="/leads/source"
                      className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                    >
                      Source
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subheader;
