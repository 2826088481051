import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, Button } from "reactstrap";
import Subheader from "./Subheader";
import Submodal from "./Submodal";
import Subtable from "./Subtable";
import Axios from "axios";

const LeadRating = () => {
  const [modal, setModal] = useState(false);
  const [modalrating, setModalrating] = useState("rating");
  const [modaldescription, setModaldescription] = useState("description");

  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <Subheader name={"Leads Ratings"} />
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header p-2">
                    <div className="row">
                      <div className="col-md-10">
                        <Button className="btn btn-secondary btn-sm pl-1 pr-1 mr-1">
                          <i className="fa fa-trash pr-1"></i> Delete
                        </Button>
                        <Button
                          className="btn btn-secondary btn-sm"
                          title="Refresh"
                        >
                          <i className="fa fa-refresh"></i>
                        </Button>
                      </div>
                      <div className="col-md-2">
                        <Input
                          type="select"
                          id="ddlSortBy"
                          className="form-control custome-select small-select mr-1"
                        >
                          <option value="">Sort By</option>
                          <option value="Ascending">Ascending</option>
                          <option value="Descending">Descending</option>
                        </Input>
                        <Button
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Print"
                        >
                          <i className="fa fa-print"></i>
                        </Button>
                        <Button
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Export To PDF"
                        >
                          <i className="fa fa-file-pdf-o"></i>
                        </Button>
                        <Button
                          className="btn btn-secondary btn-floating btn-sm"
                          title="Export To Excel"
                        >
                          <i className="fa fa-file-excel-o"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>Select</th>
                                <th className="font-weight-bold">
                                  Lead Rating
                                </th>
                                <th className="font-weight-bold">
                                  Description
                                </th>
                                <th className="font-weight-bold">Status</th>
                                <th className="font-weight-bold text-right">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <Subtable toggle={toggle} />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Submodal
              modal={modal}
              toggle={toggle}
              name={"rating"}
              modalrating={modalrating}
              modaldescription={modaldescription}
              changerating={(e) => setModalrating(e.target.value)}
              changedescription={(e) => setModaldescription(e.target.value)}
            />
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default LeadRating;
