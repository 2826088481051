import Axios from "axios";

export const eventInsertHandler = (data, token) => async (dispatch) =>{
    // return {
    //     type: "ADD_LEAD_TASK",
    //     payload: Axios.post(`/api/crm/add-task`, data, {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }).catch((err)=>err),
    // };
    try{
        const resp = await Axios.post(`/api/crm/add-task`,data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          dispatch({
            type: "ADD_LEAD_TASK_FULFILLED",
            payload: { message: "Added Successfully" },
          });

    } catch(errors){

        dispatch({
            type: "ADD_LEAD_TASK_REJECTED",
            payload: errors,
          });

    }


};

export const taskDestroy = (data, token) => {
    return {
        type: "TASK_DELETE",
        payload: Axios.post(`/api/crm/task/destroy`, data, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err),
    };
};

export const changeReducerState = () => {
    return {
        type: "CHANGE_TASK_STATE"
    }
}

export const taskUpdate = (id, token,data) => {
    return {
        type: "TASK_UPDATE",
        payload: Axios.post(`/api/crm/update-task/${id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err),
    };
};