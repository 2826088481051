import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Loader from "../../layouts/Loader";
import { FiEdit, FiTrash } from "react-icons/fi";
import { Priority, priorityDelete } from "../../store/actions/PriorityActions";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
const Index = ({ history: { push } }) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);

  const { loading, status, priority } = useSelector((state) => state.priority);
  const [listData, setListData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");

  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
      };

      dispatch(priorityDelete(delArr, token));
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh(refresh + 1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify(deleteIds),
      };
      dispatch(priorityDelete(delArr, token));

      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh(refresh + 1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        listData.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  useEffect(() => {
    dispatch(Priority(token, paginate, search, page, sort));
  }, [token, paginate, search, page, sort]);
  useEffect(() => {
    if (status) {
      setListData(priority.priority.data);
      setFrom(priority.priority.from);
      setTo(priority.priority.to);
      setTotal(priority.priority.total);
      setPerPage(priority.priority.per_page);
    }
  }, [status]);
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Priority</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              placeholder="Search..."
                              value={search}
                              onChange={handleSearch}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                              onClick={handleSerchByClick}
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        <Link
                          to="/add-prioity"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                        </Link>
                      </div>
                      <div className="btn-group mr-1">
                        <a
                          href="import-lead.html"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-download"></i>&nbsp;&nbsp;Import
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header d-card-header">
                    <div className="left-box-tools">
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#sendSMS"
                      >
                        <i className="fa fa-mobile pr-1"></i> SMS
                      </button>
                      <button className="btn btn-secondary openEmail btn-sm pl-1 pr-1 mr-1">
                        <i className="fa fa-envelope pr-1"></i> Email
                      </button>
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleDelete}
                      >
                        <i className="fa fa-trash pr-1"></i> Delete
                      </button>
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#massUpdate"
                      >
                        <i className="fa fa-list pr-1"></i> Mass Update
                      </button>
                      <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                      >
                        <option value="">Sort By</option>
                        <option value="Ascending">Ascending</option>
                        <option value="Descending">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    <CustomInput
                                      id="default"
                                      type="checkbox"
                                      onChange={(e) => handleMultiSelect(e)}
                                      checked={
                                        deleteIds.length === listData.length &&
                                        deleteIds.length
                                      }
                                    />
                                  </div>
                                </th>
                                <th className="font-weight-bold">Title</th>
                                <th className="font-weight-bold">
                                  Description
                                </th>
                                <th className="text-right font-weight-bold pr-4">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody class="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {listData
                                    ? listData.map((leadsl, i) => (
                                        <tr key={Math.random()}>
                                          <td>
                                            <div className="custom-checkbox custom-control">
                                              <CustomInput
                                                id={`default-${leadsl.id}`}
                                                type="checkbox"
                                                checked={deleteIds.some(
                                                  (d) => d === leadsl.id
                                                )}
                                                onChange={(e) =>
                                                  handleSingleSelect(
                                                    e,
                                                    leadsl.id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>{leadsl.title}</td>
                                          <td>{leadsl.description}</td>
                                          <td className="text-right pr-4">
                                            <Link
                                              to={`/prioities/edit/${leadsl.slug}`}
                                              className="mr-2 text-info"
                                            >
                                              <FiEdit />
                                            </Link>
                                            <a
                                              href="#"
                                              className="text-danger"
                                              onClick={(e) =>
                                                handleOneDelete(e, leadsl.id)
                                              }
                                            >
                                              <FiTrash />
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Index;
