import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import {
  Container,
  InputGroup,
  Input,
  CustomInput,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import {
  FiEdit,
  FiTrash,
  FiChevronDown,
  FiEye,
  FiAlertTriangle,
  FiTablet,
} from "react-icons/fi";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { getMeeting } from "../../store/actions/MeetingActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import { getDashboardCount } from "../../store/actions/DashboardActions";
import { CSVLink } from "react-csv";
import axios from "axios";
import moment from "moment";
import Note from "../../components/meeting-details/subComponent/Note";
import { data } from "jquery";

const AssociateNotesLead = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [purpose, SetPurpose] = useState("");
  const [leadsource, setLeadsource] = useState("");
  const [meeting, setMeeting] = useState([]);
  const [modal, setModal] = useState(false);
  const [fields, setFields] = useState("");
  const [loadingg, setLoadingg] = useState(true);
  const [newAdd, setNewAdd] = useState(false);
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [id, setId] = useState("");
  const [modalId, setModalId] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [assign, setAssign] = useState("");
  const [assignDate, setAssignDate] = useState("");
  const [assignEndDate, setAssignEndDate] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [dispositionModal, setDispositionModal] = useState("");
  const [dispositionTypeModal, setDispositionTypeModal] = useState("");
  const [AssociateteamList, setAssociateTeamList] = useState([]);
  const [purposeModal, SetPurposeModal] = useState("");
  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        meeting.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };

  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };
  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const permissions = React.useMemo(() => permission ?? [], [permission]);

  const { getmeeting, loading } = useSelector((state) => state.meetingReducer);
  const { dashboardcount } = useSelector((state) => state.dashboardReducer);


  useEffect(() => {
    axios
      .get(
        `/api/crm/get-crm-meetingdetails?paginate=${paginate}&sort=${sort}&page=${page}`,
        {
          headers: { authorization: `Bearer ${auth?.token}` },
        }
      )
      .then((res) => {
        setMeeting(res.data.getNotes.data);
        setFrom(res.data?.getNotes?.from);
        setTo(res.data?.getNotes?.to);
        setTotal(res.data?.getNotes?.total);
        setPerPage(res.data?.getNotes?.per_page);
      })
      .catch((error) => console.log(error));
  }, [ paginate,sort,page, search, auth?.token, assign, leadDate]);

  useEffect(() => {
    dispatch(getDashboardCount(auth.token, "get-associate-dashboard-count"));
  }, [auth.token]);

  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoadings(true);
      axios
        .post(
          `/api/crm/deleteMeetingDetail`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then((res) => {
          setLoadings(false);
          setRefresh((refresh) => refresh + 1);
          setDeleteIds([]);
          NotificationManager.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setLoadings(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth.token]);

  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingg(true);
    const data = new FormData();
    data.append("file", fields);
    data.append("assign_to", assignTo);
    axios
      .post(`/api/crm/get-crm-meetingdetails-import`, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoadingg(false);
        window.location.reload();
        NotificationManager.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoadingg(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoadingg(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  useEffect(() => {
    if (modalId) {
      const dataa = meeting.filter((meet) => meet.id == modalId);
      setDispositionTypeModal(dataa[0]?.disposition_type);
      setDispositionModal(dataa[0]?.disposition);
      SetPurposeModal(dataa[0]?.purpose_of_meeting);
    }
  }, [modalId]);


  useEffect(() => {
    axios
      .get(`/api/crm/crm-associate-user`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        console.log(res.data.getList, "qwerty");
        setAssociateTeamList(res.data?.getList);
      })

      .catch((err) => console.log(err));
  }, []);

  const handleDisposition = (e) => {
    axios
      .get(
        `/api/crm/update-franchise-detail?id=${modalId}&disposition_type=${dispositionTypeModal}&disposition=${dispositionModal}&purpose_of_meeting=${purposeModal}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        setLoadingg(false);
        NotificationManager.success("Disposition Updated");
        setOpenDispositionModal(!openDispositionModal);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoadingg(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoadingg(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  const csvHead = [
    [
      "Name",
      "Email",
      "Contact Person",
      "Mobile",
      "Lead Source",
      "Disposition Type",
      "Disposition",
      "City",
      "Purpose",
      "Remarks",
    ],
  ];

  // const csvData = meeting?.map((cv) => [
  //   cv?.name,
  //   cv?.contact_detail?.[0]?.email,
  //   cv?.contact_detail?.[0]?.contact_person_name,
  //   cv?.contact_detail?.[0]?.contact_number,
  //   cv?.lead_source,
  //   cv?.disposition_type,
  //   cv?.disposition,
  //   cv?.city,
  //   cv?.purpose_of_meeting,
  //   cv?.note,
  // ]);
  // const exportData = csvHead.concat(csvData);

  const handleSendLead = (e) => {
    e.preventDefault();
    setOpenSendLeadModal(!openSendLeadModal);
  };

  const handleSendLeads = (e) => {
    let filterList;
    e.preventDefault();

    filterList = meeting.filter((v) => deleteIds.some((i) => i == v?.id));

    axios
      .post(
        `/api/crm/update-assignto`,
        {
          assignTo: assignTo,
          leadIds: filterList,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Lead Send Successfully");
        setDeleteIds([]);
        setOpenSendLeadModal(!openSendLeadModal);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Associate Team</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field m-0">
                      <Input
                        type="select"
                        className="select"
                        value={paginate}
                        onChange={(e) => setPaginate(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>

                    <ButtonGroup className="">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm ml-1"
                        onClick={() => setModal(true)}
                      >
                        <i className="fa fa-plus btn-i-icon"></i> Import Data
                      </button>
                    </ButtonGroup>
                    <Modal isOpen={modal} toggle={() => setModal(false)}>
                      <ModalHeader toggle={() => setModal(false)}>
                        Import Data
                        {/* <a
                          href="/import_hunar_excel.csv"
                          style={{ "margin-left": "170px", color: "blue" }}
                        >
                          sheet
                        </a> */}
                      </ModalHeader>
                      <ModalBody>
                        <form onSubmit={handleSubmit}>
                          <FormGroup>
                            <div>
                              <label>Associate Team</label>
                              {/* <select
                                id="paginate"
                                onChange={(e) => setCreatedBy(e.target.value)}
                                value={createdBy}
                                className="custom-select"
                              >
                                <option value="">Select</option>
                                <option value="100">Ritika Nagar</option>
                                <option value="108">Sourabh</option>
                                <option value="109">Vishal</option>
                              
                              </select> */}
                            </div>

                            <div className="pt-3">
                              <CustomInput
                                type="file"
                                id="file"
                                name="file"
                                label="Choose File"
                                onChange={handleChange}
                                invalid={!!errors?.file}
                                required
                              />
                            </div>
                            <FormFeedback>
                              {!!errors?.file ? errors?.file[0] : ""}
                            </FormFeedback>
                          </FormGroup>
                          <div className="text-center mb-3">
                            <Loader loading={loading}>
                              <Button
                                type="submit"
                                color="secondary"
                                size="sm"
                                className="mr-1"
                              >
                                Save
                              </Button>
                              <Button
                                type="button"
                                color="secondary"
                                size="sm"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Loader>
                          </div>
                        </form>
                      </ModalBody>
                    </Modal>

                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="c-card-top">
                        <div className="c-card-top-title ml-2">
                          <div className="c-card-top-inputs c-card-top-search">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-search"></i>
                                </span>
                              </div>
                              <span className="custom-select">Search</span>

                              <Input
                                placeholder="Search here..."
                                onChange={handleSearch}
                                className="c-form-control-serch"
                              />
                              <div class="input-group-append">
                                <button
                                  title="Advance Search"
                                  onClick={() => {
                                    advanceSearch
                                      ? setAdvanceSearch(false)
                                      : setAdvanceSearch(true);
                                  }}
                                  id="c_searchBlock_toggle"
                                  class="btn btn-secondary btn-sm c-card-top-block-icon"
                                  type="button"
                                >
                                  <FiChevronDown />
                                </button>
                                <button
                                  class="btn btn-secondary btn-sm"
                                  type="button"
                                  id="button-addon2"
                                >
                                  GO
                                </button>
                              </div>
                            </div>

                            <div
                              className={
                                advanceSearch ? "c-card-top-block" : "d-none"
                              }
                              id="c_searchBlock"
                            >
                              <div className="c-card-top-block-sec">
                                <div className="c-card-top-block-item">
                                  <label>Assign To</label>
                                  <select
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => setAssign(e.target.value)}
                                    value={assign}
                                  >
                                    <option>select</option>
                                    {dashboardcount.getTeamList?.map((cr) => (
                                      <option value={cr?.id} key={cr.id}>
                                        {cr?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="c-card-top-block-item">
                                  <label>Note Date</label>

                                  <Input
                                    type="date"
                                    placeholder="End Date"
                                    onChange={(e) =>
                                      setLeadDate(e.target.value)
                                    }
                                    value={leadDate}
                                  />
                                </div>

                                <div className="c-card-top-block-action">
                                  <button
                                    onClick={() => setAdvanceSearch(false)}
                                    className="btn btn-sm btn-secondary mr-2"
                                  >
                                    Apply
                                  </button>

                                  <button
                                    onClick={() => setAdvanceSearch(false)}
                                    className="btn btn-sm btn-secondary mr-2"
                                  >
                                    Cancel
                                  </button>
                                  {/* {associateType ? (
                                <button
                                  className="btn btn-sm btn-secondary "
                                  title="Refresh"
                                >
                                  <i className="fa fa-refresh" />
                                </button>
                              ) : null} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {permissions.some(
                          (item) => item?.name === "associate-create"
                        ) && (
                          <Link
                            to="/add-associate"
                            type="button"
                            className="btn btn-secondary text-nowrap btn-sm"
                          >
                            <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header btn-header d-card-header">
                    <div className="left-box-tools">
                      {/* <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#sendSMS"
                      >
                        <i className="fa fa-mobile pr-1"></i> SMS
                      </button>
                      <button className="btn btn-secondary openEmail btn-sm pl-1 pr-1 mr-1">
                        <i className="fa fa-envelope pr-1"></i> Email
                      </button> */}
                      {/* <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        disabled={!deleteIds.length}
                      >
                        <i className="fa fa-trash pr-1"></i> Delete
                      </button> */}

                      <button
                        className="btn btn-secondary btn-sm mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleSendLead}
                      >
                        <i className="fa fa-list pr-1"></i> Send Lead
                      </button>
                      {/* 
                      <CSVLink
                        filename={"AssociateList.csv"}
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To CSV"
                        data={exportData}
                      >
                        <i className="fa fa-file-excel-o" />
                      </CSVLink> */}

                      {/* <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#massUpdate"
                      >
                        <i className="fa fa-list pr-1"></i> Mass Update
                      </button> */}
                      {/* <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button> */}
                    </div>
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={({ target }) => setSort(target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      {/* <CSVLink
                        filename={"userList.csv"}
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To CSV"
                        data={exportData}
                      >
                        <i className="fa fa-file-excel-o" />
                      </CSVLink> */}
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    <CustomInput
                                      id="default"
                                      type="checkbox"
                                      onChange={(e) => handleMultiSelect(e)}
                                      checked={
                                        deleteIds.length === meeting?.length &&
                                        deleteIds.length
                                      }
                                    />
                                  </div>
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Email</th>

                                {/* <th className="font-weight-bold">Industry</th> */}
                                <th className="font-weight-bold">Mobile</th>

                                <th className="font-weight-bold">
                                  Lead Source
                                </th>
                                <th className="font-weight-bold">
                                  Disposition
                                </th>
                                <th className="font-weight-bold">City</th>
                                <th className="font-weight-bold">Purpose</th>

                                <th className="font-weight-bold">Remarks</th>
                                <th className="font-weight-bold">Action</th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {Array.isArray(meeting)
                                    ? meeting?.map((meet, i) => (
                                        <tr key={i + 1}>
                                          <td>
                                            <div className="custom-checkbox custom-control">
                                              <Input
                                                id={`default-${meet?.id}`}
                                                type="checkbox"
                                                checked={deleteIds?.some(
                                                  (d) => d === meet?.id
                                                )}
                                                onChange={(e) =>
                                                  handleSingleSelect(
                                                    e,
                                                    meet?.id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <Link
                                            to={`/associate-detail/${meet?.crm_franchise_lead?.id}`}
                                          >
                                            <td>
                                              {meet?.crm_franchise_lead?.name}
                                            </td>
                                          </Link>
                                          <td>
                                            {
                                              meet?.crm_franchise_lead
                                                ?.contact_detail?.[0]?.email
                                            }
                                          </td>

                                          <td>
                                            {
                                              meet?.crm_franchise_lead
                                                ?.contact_detail?.[0]
                                                ?.contact_number
                                            }
                                          </td>

                                          <td>
                                            {
                                              meet?.crm_franchise_lead
                                                ?.lead_source
                                            }
                                          </td>
                                          <td>
                                            {
                                              meet?.crm_franchise_lead
                                                ?.disposition
                                            }
                                          </td>
                                          <td>
                                            {meet?.crm_franchise_lead?.city}
                                          </td>
                                          <td>
                                            {
                                              meet?.crm_franchise_lead
                                                ?.purpose_of_meeting
                                            }
                                          </td>
                                          <td>{meet?.note}</td>
                                          <td className="text-right">
                                            <a
                                              href="#"
                                              className="text-danger"
                                              onClick={() => {
                                                setOpenNotesModal(true);
                                                setId(
                                                  meet?.crm_franchise_lead?.id
                                                );
                                              }}
                                            >
                                              <FiEye />
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          isOpen={openSendLeadModal}
          toggle={() => setOpenSendLeadModal(!openSendLeadModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openSendLeadModal)}>
            <form onSubmit={handleSendLeads}>
              <Row className="m-3">
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-12 control-label col-form-label col-12"
                    >
                      Associate Team
                    </Label>
                    <Input
                      type="select"
                      className="col-md-12"
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                    >
                      <option>select</option>
                      {AssociateteamList?.map((cr) => (
                        <option value={cr?.id} key={cr.id}>
                          {cr?.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Loader loading={loading}>
                      <Button
                        type="submit"
                        color="secondary"
                        size="sm"
                        className="mr-1"
                      >
                        Send
                      </Button>
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          (window.location.href = "/associate-team")
                        }
                      >
                        Close
                      </Button>
                    </Loader>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalHeader>
          <ModalBody className="p-0 m-0"></ModalBody>
        </Modal>
        <Modal
          isOpen={openNotesModal}
          toggle={() => setOpenNotesModal(!openNotesModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openNotesModal)}>
            Notes
          </ModalHeader>
          <ModalBody className="p-0 m-0">
            <Note id={id} />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={openDispositionModal}
          toggle={() => setOpenDispositionModal(!openDispositionModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openDispositionModal)}>
            Disposition Update
          </ModalHeader>
          <ModalBody className="p-0 m-0">
            <Row className="m-3">
              <Col sm={12}>
                <FormGroup className="row">
                  <Label
                    for="name"
                    className="col-md-12 control-label col-form-label col-12"
                  >
                    Disposition Type
                  </Label>
                  <select
                    id="paginate"
                    onChange={(e) => setDispositionTypeModal(e.target.value)}
                    value={dispositionTypeModal}
                    className="custom-select"
                  >
                    <option value=""> Select </option>
                    <option value="Connected">Connected</option>
                    <option value="Not_Connected">Not Connected</option>
                  </select>
                </FormGroup>
              </Col>

              {dispositionTypeModal == "Connected" ? (
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-12 control-label col-form-label col-12"
                    >
                      Connected
                    </Label>
                    <select
                      id="paginate"
                      onChange={(e) => setDispositionModal(e.target.value)}
                      value={dispositionModal}
                      className="custom-select"
                    >
                      <option value=""> Select </option>
                      <option value="Mail_Shared">Mail Shared</option>
                      <option value="Meeting_Align">Meeting Align</option>
                      <option value="Meeting_Done">Meeting Done</option>
                      <option value="Follow_Up">Follow up(Hot)</option>
                      <option value="Call_Back">Call Back</option>
                      <option value="Not_Interested">Not Interested</option>
                      <option value="Meeting_Done_NOI">
                        Meeting Done Not Interested
                      </option>
                    </select>
                  </FormGroup>
                </Col>
              ) : null}

              {dispositionTypeModal == "Not_Connected" ? (
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-12 control-label col-form-label col-12"
                    >
                      Not Connected
                    </Label>
                    <select
                      id="paginate"
                      onChange={(e) => setDispositionModal(e.target.value)}
                      value={dispositionModal}
                      className="custom-select"
                    >
                      <option value=""> Select </option>
                      <option value="Npc">NPC</option>
                      <option value="Switch_Off">Switch Off</option>
                      <option value="Invalid_Number">Invalid Number</option>
                      <option value="Call_Rejected">Call Rejected</option>
                    </select>
                  </FormGroup>
                </Col>
              ) : null}

              <Col sm={12}>
                <FormGroup className="row">
                  <Label
                    for="name"
                    className="col-md-12 control-label col-form-label col-12"
                  >
                    Purpose Of Meeting
                  </Label>
                  <select
                    id="paginate"
                    onChange={(e) => SetPurposeModal(e.target.value)}
                    value={purposeModal}
                    className="custom-select"
                  >
                    <option value=""> Select </option>
                    <option value="Mitra">Mitra</option>
                    <option value="Sarthi">Sarthi</option>
                    <option value="Faciliation">Faciliation</option>
                  </select>
                </FormGroup>
              </Col>

              <Col sm={12}>
                <div className="text-center">
                  <Loader loading={loading}>
                    <Button
                      type="submit"
                      onClick={handleDisposition}
                      color="secondary"
                      size="sm"
                      className="mr-1"
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/associate-team")}
                    >
                      Close
                    </Button>
                  </Loader>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </MainWrapper>
    </Fragment>
  );
};

export default AssociateNotesLead;
