import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import {
  createNoteApi,
  getFranchiseList,
  updateNoteApi,
  NoteDelete,
} from "../../../store/actions/NoteActions";
import {

  Input,

} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCrmUsersApi } from "../../../store/actions/LeadActions";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import _ from "lodash";

const Note = ({ id, permissions, details }) => {
  ; // title input swith state
  const [title, setTitle] = useState(""); // Note title state
  const [note, setNote] = useState(""); // Note State
  const [editSwich, setEditSwich] = useState(false);
  const [editNoteId, setEditNoteId] = useState("");
  const [noteListData, setNoteListData] = useState([]);
  const { auth } = useSelector(({ auth }) => auth);
  const {
    createNoteData,
    createNoteStatus,
    Note,
    listStatus,
    error,
    updateNoteStatus,
    createNote,
    franchiseListNotes,
  } = useSelector(({ note }) => note);
  const [dispositiontype, setDispositionType] = useState("");
  const [activeTitle, setActiveTitle] = useState(true)
  const { leadData } = useSelector(({ lead }) => lead);

  const dispatch = useDispatch();
  //handle title input show and hide
  const handleTitleSwich = (e) => {
    e.preventDefault();
    setActiveTitle(!activeTitle);
  };
  // handle note fields reset
  const handleCancelNote = (e) => {
    e.preventDefault();
    setActiveTitle(false);
    setTitle("");
    setNote("");
  };
  //Insert Note method

  const handleNoteSubmit = (e) => {
    e.preventDefault();
    const data = {
      id,
      title,
      note,
      type: "franchise-investor-lead",
      engaged: 1,
      disposition: dispositiontype == 'null' ? "" : dispositiontype,
    };
    dispatch(createNoteApi(data, auth.token));
    if (createNoteStatus) {
      setTimeout(() => {
        dispatch(getFranchiseList(id, auth.token));
        NotificationManager.success("Note added successfully");
        setNote("");
        setTitle("");
      }, 1000);
    }

  };

  useEffect(() => {
    if (createNoteStatus) {
      NotificationManager.success("Note Created Successfully", "Sccess");
      setTitle("");
      setNote("");
      // setNoteListData([...noteListData, createNoteData.employeeNote]);
    }
  }, [createNoteStatus]);
  useEffect(() => {
    setNoteListData(franchiseListNotes);
  }, [franchiseListNotes, auth.token]);

  // get list of employee note
  useEffect(() => {
    dispatch(getFranchiseList(id, auth.token));
    // dispatch(getCrmUsersApi(auth.token));
  }, [auth.token]);
  // handle Employee Edition Action
  const handleEdit = (e, id) => {
    e.preventDefault();
    let editNote = franchiseListNotes.find((nt) => nt.id === id);
    setEditNoteId(id);
    setTitle(editNote?.title);
    setNote(editNote?.note);
    if (editNote?.title) {
      setActiveTitle(true);
    }
    setEditSwich(!editSwich);
  };
  useEffect(() => {
    setDispositionType(details?.disposition_type
    )
  }, [details])
  useEffect(() => {
    if (updateNoteStatus) {
      NotificationManager.success("Note Updated Successfully", "Sccess");
      dispatch(getFranchiseList(id, auth.token));
    }
  }, [updateNoteStatus]);

  // handle Employee Delete Action
  const handleDelete = (e, deleteId) => {
    e.preventDefault();
    dispatch(NoteDelete({ ids: JSON.stringify(deleteId) }, auth.token));
    setTimeout(() => {
      dispatch(getFranchiseList(id, auth.token));
      NotificationManager.success("Note Deleted Successfully", "Sccess");
    }, 1000);
  };

  // handle Note Update
  const handleUpdateEmployeeNote = (e) => {
    e.preventDefault();
    const updateData = { editNoteId, id, title, note };
    setEditSwich(false);
    setEditNoteId("");
    setEditNoteId("");
    setTitle("");
    setNote("");
    setActiveTitle(false);
    dispatch(updateNoteApi(updateData, auth.token));
  };
  return (
    <>
      <div className="note-area my-3">
        <div className="row ">
          <div className="col-md-8 col-sm-12 ">
            <div className="bg-light lter p-2">
              <div className="form-group mb-2 d-flex align-items-center justify-content-between">
                <h5 className="inline mt-0">Note for this employee</h5>
                <Link
                  id="AddTitle"
                  to={`#`}
                  className="pull-right"
                  onClick={handleTitleSwich}
                >
                  Add a Title
                </Link>
              </div>

              <div
                className={`form-group ${activeTitle ? "" : "addTitleInput"}`}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add a Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
                {createNote?.errors && Array.isArray(createNote.errors.title) && createNote.errors.title.length > 0 && (
                  <p className="text-danger">{createNote.errors.title[0]}</p>
                )}
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Add a Note"
                  style={{ height: 100, resize: "none" }}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
                {createNote?.errors && Array.isArray(createNote.errors.note) && createNote.errors.note.length > 0 && (
                  <p className="text-danger">{createNote.errors.note[0]}</p>
                )}
              </div>
              <div className="form-group text-right">
                {editSwich ? (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm"
                    onClick={handleUpdateEmployeeNote}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm mr-1"
                    onClick={handleNoteSubmit}
                  >
                    Save
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={handleCancelNote}
                >
                  Cancel
                </button>
              </div>
              {_.map(noteListData, (lt, i) => (
                <div className="note-data mb-2" key={i}>
                  <p className="mb-1">{lt.note} </p>
                  <div className="note-bottom small d-flex justify-content-between">
                    <div className="note-data-section">
                      <span>{lt.user ? lt.user.name : null}</span>
                      &nbsp;&nbsp;
                      <span className="text-muted">{lt.created_at}</span>
                      <span className="text-muted">
                        {" "}
                        {lt?.created_user?.name}{" "}
                      </span>
                    </div>
                    <div className="right-links pl-2">
                      {permissions?.find((ps) => ps.name === "note-edit") !=
                        null ? (
                        <Link
                          id="EditNote"
                          className="pr-1  text-info "
                          to={``}
                          onClick={(e) => handleEdit(e, lt.id)}
                        >
                          <FiEdit />
                        </Link>
                      ) : null}

                      {permissions?.find((ps) => ps.name === "note-delete") !=
                        null ? (
                        <Link
                          id="RemoveNote"
                          className="pr-1 text-danger"
                          to={`#`}
                          onClick={(e) => handleDelete(e, lt.id)}
                        >
                          <FiTrash />
                        </Link>
                      ) : null}
                      {/* <Link
                        id="RemoveNote"
                        className="pr-1"
                        to={`#`}
                        onClick={(e) => handleDelete(e, lt.id)}
                      >
                        Remove
                      </Link>
                      <Link
                        id="EditNote"
                        to={`#`}
                        onClick={(e) => handleEdit(e, lt.id)}
                      >
                        Edit
                      </Link> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4">
            <div className="overflow-hidden rounded bg-white border">
              <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  Disposition Type
                </span>
              </div>
              <div className="p-2">
                <Input
                  type="select"
                  className="custom-select cursor-pointer"
                  onChange={(e) => setDispositionType(e.target.value)}
                  value={dispositiontype}
                >
                  <option value=""> Select </option>
                  <option value="CallBack/Busy">CallBack/Busy</option>
                  <option value="Franchise Fee Picked">
                    Franchise Fee Picked
                  </option>
                  <option value="Membership Picked">Membership Picked</option>
                  <option value="On Negotiation">On Negotiation</option>
                  <option value="Praposal Shared">Praposal Shared</option>
                  <option value="Budget Issue">Budget Issue</option>
                  <option value="Wrong Number">Wrong Number</option>

                  <option value="Meeting Met">Meeting Met</option>
                  <option value="Not Reachable">Not Reachable</option>
                  <option value="Not Answering/Ringing">
                    Not Answering/Ringing
                  </option>
                  <option value="Not Intrested">Not Intrested</option>
                  <option value="Meeting Booked">Meeting Booked</option>
                  <option value="Token Picked">Token Picked</option>
                  <option value="Switch off">Switch off</option>
                  <option value="Dump Lead">Dump Lead</option>
                </Input>
                {createNote?.errors && Array.isArray(createNote.errors.disposition) && createNote.errors.disposition.length > 0 && (
                  <p className="text-danger">{createNote.errors.disposition[0]}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Note;
