/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { FiMenu } from "react-icons/fi";
import UserImg from "../assets/img/avtar.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Pusher from "pusher-js";
import Axios from "axios";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import CallBackModal from "../components/callback-modal/callbackmodal";

import { MdNotificationsActive } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState } from "react";
import { assign } from "lodash";
import ReminderPopUp from "../components/callback-modal/ReminderPopUp";
const HeaderNavBar = ({ setToggle }) => {
  const dispatch = useDispatch();
  const [task, setTask] = useState([]);
  const [task1, setTask1] = useState([]);
  const [task2, setTask2] = useState([]);
  const [notify, setNotify] = useState(false);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reminderTask, setReminderTask] = useState([]);
  const [reminderTaskModal, setReminderTaskModal] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const {
    auth: { name },
  } = useSelector(({ auth }) => auth);
  const { auth } = useSelector(({ auth }) => auth);

  useEffect(() => {
    Axios.get(`/api/crm/getusertask?agent_id=${auth?.id}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
      .then((res) => {
        setTask1(res.data?.getUserTask);
        setTask2(res.data?.getUserTask1);
      })
      .catch((err) => console.log(err));
  }, [auth?.token]);

  const newData = [...task1, ...task2];

  const ids = newData.map(({ id }) => id);
  const filtered = newData.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );

  useEffect(() => {
    if (filtered?.length > 0) {
      setNotify(true);
    }
  }, [filtered]);

  //Pusher
  // useEffect(() => {
  //   const pusher = new Pusher("105db936cbaca4890044", {
  //     cluster: "ap2",
  //   });

  //   const channel = pusher.subscribe("demo-t");
  //   channel.bind("post-created", function (data) {
  //     setDetails(data.post.find((detail) => detail.assign_to == auth.id));
  //     if (data.post.some((detail) => detail.assign_to == auth.id)) {
  //       setModal(true);
  //     }
  //   });
  // }, [auth.id]);

  useEffect(() => {
    if (auth?.campaign == "Associate" || auth?.campaign == "Franchise") {
      var pusher = new Pusher('350ec6f7307c6623a4da', {
        cluster: 'ap2'
      });

      var channel = pusher.subscribe('crm-task');
      channel.bind('task', function (data) {

        if (data?.task) {
          const filterTask = data?.task?.filter((v => v?.crm_agent?.id == auth?.id));
          if (filterTask) {

            if (filterTask.length > 0) {
              setReminderTask(filterTask);
              setReminderTaskModal(!reminderTaskModal)
            }

            //alert(JSON.stringify(filterTask));
          }
        }


      });
    }
  }, [auth])

  return (
    <Fragment>
      <CallBackModal modal={modal} setModal={setModal} details={details} />
      <nav className="navbar navbar-expand navbar-light bg-white sticky-top">
        <a
          className="sidebar-toggle d-flex mr-3"
          role="button"
          onClick={() => setToggle((toggle) => (toggle ? "" : "toggled"))}
        >
          <FiMenu className="feather align-self-center" />
        </a>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav ml-auto">
            <div>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle className="notify-container">
                  <MdNotificationsActive size={25} color="#036e65" />
                  <p className="notify">{filtered?.length}</p>
                </DropdownToggle>
                {notify ? (
                  <DropdownMenu style={{ top: 40, left: -100, width: "500px", height: "200px", overflow: "auto" }}>
                    {filtered?.map((t) => (
                      <DropdownItem key={t?.id}>
                        You have pending task on Lead{" "}
                        {/* <a href={`/lead/${auth?.campaign === "Franchise"?t?.crm_lead_franchise
                            ?.id :
                            t?.crm_lead_user?.id}
                            /details`}></a> */}
                        <Link
                          to={
                            auth?.campaign === "Franchise" && t?.crm_lead_franchise
                              ? `/franchise-investor-detail/${t?.crm_lead_franchise?.id}`
                              : auth?.campaign === "Associate" ? `associate-detail/${t?.crm_franchise?.id}` : `/lead/${t?.crm_lead_user?.id}/details`
                          }
                        >
                          {auth?.campaign == "Franchise" && t?.crm_lead_franchise
                            ? t?.crm_lead_franchise?.customer_name
                            : auth?.campaign === "Associate" ? `${t?.crm_franchise?.name}` : t?.crm_lead_user?.full_name}
                        </Link>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                ) : (
                  <DropdownMenu style={{ top: 40, left: -100 }}>
                    <DropdownItem>You have no notifications</DropdownItem>
                  </DropdownMenu>
                )}
              </Dropdown>
            </div>
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle ml-2 d-inline-block d-sm-none"
                href="#"
                data-toggle="dropdown"
              >
                <div className="position-relative">
                  <i className="align-middle mt-n1" data-feather="settings"></i>
                </div>
              </a>

              <a
                className="nav-link nav-link-user dropdown-toggle d-none d-sm-inline-block"
                href="#"
                data-toggle="dropdown"
              >
                <img
                  src={UserImg}
                  className="avatar img-fluid rounded-circle mr-1"
                  alt="Kathy Davis"
                />{" "}
                <span className="text-dark">{name}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={() => dispatch({ type: "LOGOUT" })}
                >
                  Sign out
                </a>
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={() => dispatch({ type: "CHANGE_PASSWORD" })}
                  href="/password-change"
                >
                  Change Password
                </a>
              </div>

            </li>
          </ul>
        </div>
      </nav>
      <ReminderPopUp modal={reminderTaskModal} setModal={setReminderTaskModal} details={reminderTask} auth={auth} />
    </Fragment>
  );
};

export default HeaderNavBar;
