import React, { useState, useMemo, useEffect, Fragment } from "react";
import MainWrapper from "../../MainWrapper";
import axios from "axios";
import {
    Container,
    InputGroup,
    Row,
    Col,
    Input,
    CustomInput,
    ButtonGroup,
    Modal,
    ModalBody,
    FormGroup,
    ModalHeader,
    Label,
    Button,
    ModalFooter
} from "reactstrap";
import Loader from "../../../layouts/Loader";
import { useSelector } from "react-redux";
import { FiTrash, FiEdit, FiChevronDown, FiDelete } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { FaList } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { getallPermissions } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const VerticalTarget = () => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("desc");
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [deleteIds, setDeleteIds] = useState([]);
    const [paginate, setPaginate] = useState(20);
    const [search, setSearch] = useState("")
    const [isEditing, setIsEditing] = useState(false)

    const [isOpen, setIsOpen] = useState(false);
    const [advanceSearch, setAdvanceSearch] = useState(false);
    const [formData, setFormData] = useState({
        vertical: "",
        target: "",
        verticalType: "",
        startDate: "",
        endDate: ""
    })
    const [errors, setErrors] = useState({})

    const {
        auth: { token },
    } = useSelector((state) => state.auth);





    const inputHndler = (e) => {
        e.persist()
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))

    }

    const handleSubmit = () => {
        axios.post(isEditing ? `/api/crm/update-vertical-target` : "/api/crm/create-vertical-target", isEditing ? {
            id: formData.id,
            vertical_name: formData.vertical,
            target: formData.target,
            vertical_type: formData.verticalType,
            start_date: formData.startDate,
            end_date: formData.endDate
        } : {
            vertical_name: formData.vertical,
            target: formData.target,
            vertical_type: formData.vertical,
            start_date: formData.startDate,
            end_date: formData.endDate
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(({ data }) => {
                console.log(data)
                setIsOpen(false)
                setRefresh(refresh + 1)
                NotificationManager.success(data.success)
            })
            .catch(err => {
                console.error(err)
                console.log(err?.response);
                setErrors(err?.response?.data?.errors)
            })
    }


    const {
        auth,
        permissions: { permission },
    } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getallPermissions(auth?.token));
    }, [auth]);

    const permissions = React.useMemo(() => permission ?? [], [permission]);
    useEffect(() => {
        setLoading(true);
        axios
            .get(
                `/api/crm/get-vertical-target?sort=${sort}&search=${search}&page=${page}&paginate=${paginate}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            )
            .then((res) => {

                setContent(res.data?.verticalTarget?.data || []);
                setFrom(res.data?.verticalTarget?.from);
                setTo(res.data?.verticalTarget?.to);
                setTotal(res.data?.verticalTarget?.total);
                setPerPage(res.data?.verticalTarget?.per_page);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                NotificationManager.error(err?.response?.data?.message);
            });
    }, [sort, page, refresh, paginate, search, auth]);


    const toggle = () => setIsOpen(prev => !prev)

    const handleDelete = (id) => {
        axios.post(`/api/crm/vertical-target-delete`, { id }, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then(({ data }) => {
            NotificationManager.success(data?.message)
            setRefresh(refresh + 1)
        })
            .catch(err => console.error(err))
    }

    const handleSearch = (e) => {
        setPage(1);
        setSearch(e.target.value);
    };

    return (
        <MainWrapper>
            <main className="content pt-4">
                <Modal isOpen={isOpen} toggle={toggle} >
                    <ModalHeader toggle={toggle}> Edit Vertical </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Vertical Name</Label>
                            <Input type="select" invalid={Boolean(errors.vertical)} value={formData.vertical} name="vertical" onChange={inputHndler}>
                                <option value="">select</option>
                                <option value="Membership">Membership</option>
                                <option value="Workshop">Workshop</option>
                                <option value="Project_Report">
                                    Project Report
                                </option>
                                <option value="Industrial_Report">
                                    Industrial Report
                                </option>
                                <option value="Courses">Courses</option>
                                <option value="Franchise">Franchise</option>
                                <option value="Startup">Startup</option>

                                <option value="Edp">Edp</option>

                            </Input>


                            <span className="d-block text-danger"> {errors.vertical} </span>
                        </FormGroup>

                        <FormGroup>
                            <Label>Target</Label>
                            <Input invalid={Boolean(errors.target)} value={formData.target} name="target" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.target} </span>
                        </FormGroup>


                        <FormGroup>
                            <Label>Start Date</Label>
                            <Input invalid={Boolean(errors.startDate)} type="date" value={moment(formData.startDate).format("YYYY-MM-DD")} name="startDate" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.startDate} </span>
                        </FormGroup>

                        <FormGroup>
                            <Label>End Date</Label>
                            <Input invalid={Boolean(errors.endDate)} type="date" value={moment(formData.endDate).format("YYYY-MM-DD")} name="endDate" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.endDate} </span>
                        </FormGroup>

                        {/* <FormGroup>
                            <Label>Vertical Type</Label>
                            <Input invalid={Boolean(errors.verticalType)} value={formData.verticalType} name="verticalType" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.verticalType} </span>
                        </FormGroup> */}

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="mr-2" onClick={handleSubmit}> Submit </Button>
                        <Button onClick={() => setIsOpen(false)} > Cancel </Button>
                    </ModalFooter>
                </Modal>
                <div className="page-header mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="c-card-top d-flex flex-wrap justify-content-between ">
                                <div className="c-card-top-title ml-2">
                                    <h4 className="mb-0">Source List </h4>
                                </div>
                                <div className="d-flex">
                                    <div className="c-card-top-inputs c-card-top-search">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </div>
                                            <span className="custom-select">Search</span>

                                            <Input
                                                placeholder="Search By Target Name"
                                                onChange={handleSearch}
                                                className="c-form-control-serch"
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    title="Advance Search"
                                                    onClick={() => {
                                                        advanceSearch
                                                            ? setAdvanceSearch(false)
                                                            : setAdvanceSearch(true);
                                                    }}
                                                    id="c_searchBlock_toggle"
                                                    class="btn btn-secondary btn-sm c-card-top-block-icon"
                                                    type="button"
                                                >
                                                    <FiChevronDown />
                                                </button>
                                                <button
                                                    class="btn btn-secondary btn-sm"
                                                    type="button"
                                                    id="button-addon2"
                                                >
                                                    GO
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-card-top-buttons mr-2">
                                        <ButtonGroup className="">

                                            <button
                                                type="button"
                                                className="btn btn-secondary py-1 px-2"
                                                onClick={() => {
                                                    toggle()
                                                    setIsEditing(false)
                                                    setFormData({ vertical: "", verticalType: "", target: "", startDate: "", endDate: "" })
                                                }}
                                            >
                                                <i className="fa fa-plus btn-i-icon"></i> Add New
                                            </button>


                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card d-card-table">
                                    <div className="card-header d-card-header d-flex justify-content-between align-items-center">
                                        <div>
                                            <button className="btn btn-secondary btn-sm mr-1">
                                                <i className="fa fa-trash pr-1" /> Delete
                                            </button>

                                            <button
                                                className="btn btn-secondary btn-sm btn-circle"
                                                title="Refresh"
                                                onClick={() => setRefresh((refresh) => refresh + 1)}
                                            >
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                        <div>
                                            <select
                                                id="sort"
                                                onChange={(e) => setSort(e.target.value)}
                                                value={sort}
                                                className="custome-select small-select mr-1"
                                            // style={{ width: 135 }}
                                            >
                                                <option value="asc">Ascending</option>
                                                <option value="desc">Descending</option>
                                            </select>
                                            <button
                                                className="btn btn-secondary btn-floating btn-sm mr-1"
                                                title="Print"
                                            >
                                                <i className="fa fa-print" />
                                            </button>
                                            <button
                                                className="btn btn-secondary btn-floating btn-sm mr-1"
                                                title="Export To PDF"
                                            >
                                                <i className="fa fa-file-pdf-o" />
                                            </button>
                                            <button
                                                className="btn btn-secondary btn-floating btn-sm"
                                                title="Export To Excel"
                                            >
                                                <i className="fa fa-file-excel-o" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-hover mb-0 table-sm small">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th className="font-weight-bold">
                                                                    <CustomInput
                                                                        id="default"
                                                                        type="checkbox"
                                                                        checked={
                                                                            deleteIds.length === content.length &&
                                                                            deleteIds.length
                                                                        }
                                                                    />
                                                                </th>
                                                                <th className="font-weight-bold">Vertical Name </th>
                                                                <th className="font-weight-bold">Vertical Type </th>
                                                                <th className="font-weight-bold">Vertical Target </th>

                                                                <th className="font-weight-bold">Start Date </th>
                                                                <th className="font-weight-bold">End Date </th>






                                                                <th className="font-weight-bold text-right">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="d-table-body">
                                                            <Fragment>
                                                                {loading ? (
                                                                    <Fragment>
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <Loader loading={loading} />
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {content.length ? (
                                                                            <Fragment>
                                                                                {_.map(content, (is, i) => (
                                                                                    <tr key={is.id}>
                                                                                        <td>{i + 1}</td>

                                                                                        <td>{is.vertical_name}</td>
                                                                                        <td>{is.vertical_type}</td>
                                                                                        <td>{is.target}</td>
                                                                                        <td> {is.start_date?moment(is.start_date).format("YYYY-MM-DD") : "N/A"} </td>
                                                                                        <td> {is.end_date ?moment(is.end_date ).format("YYYY-MM-DD") : "N/A"} </td>








                                                                                        <td className="text-right">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span
                                                                                                    role="button"
                                                                                                    className="c-icon-btn-light mr-2"
                                                                                                    onClick={() => {
                                                                                                        toggle()
                                                                                                        setFormData({
                                                                                                            vertical: is.vertical_name,
startDate:is.start_date,       
endDate:is.end_date,                                                                                                verticalType: is.vertical_type,
                                                                                                            target: is.target,
                                                                                                            id: is.id
                                                                                                        })
                                                                                                        setIsEditing(true)
                                                                                                    }}
                                                                                                >
                                                                                                    <FiEdit />
                                                                                                </span>

                                                                                                <span className="cursor-pointer " onClick={() => handleDelete(is.id)

                                                                                                } >
                                                                                                    <FiDelete />
                                                                                                </span>

                                                                                            </div>

                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </Fragment>
                                                                        ) : (
                                                                            <tr className="text-center">
                                                                                <td colSpan={7}>No data found</td>
                                                                            </tr>
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            </Fragment>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="d-flex align-items-center justify-content-center justify-content-between">
                                            <span>
                                                Showing {from} to {to} of {total} entries
                                            </span>
                                            <div className="d-flex">
                                                <select
                                                    id="paginate"
                                                    onChange={(e) => setPaginate(e.target.value)}
                                                    value={paginate}
                                                    className="custom-select custom-select-page"
                                                >
                                                    <option value="10" selected>
                                                        10
                                                    </option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                    <option value="500">500</option>
                                                    <option value="1000">1000</option>
                                                </select>
                                                {/* <select className="custom-select custom-select-page">
                                  <option selected>10</option>
                                  <option value="1">
                                      20
                                  </option>
                                  <option value="2">
                                      30
                                  </option>
                                  <option value="3">
                                      40
                                  </option>
                              </select> */}
                                                <Pagination
                                                    activePage={page}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={total}
                                                    pageRangeDisplayed={5}
                                                    onChange={(page) => setPage(page)}
                                                    innerClass="pagination pagination-sm"
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </MainWrapper>
    )
}

export default VerticalTarget