import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import Loader from "../../layouts/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getCrmUser } from "../../store/actions/CrmUserActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import Pagination from "react-js-pagination";
import moment from "moment";
const Index = () => {
  const dispatch = useDispatch();
  const {
    auth,
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const { crmusers, crmusersstatus, loading } = useSelector(
    (state) => state.crmUser
  );

  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [search, setSearch] = useState("");
  const [crmuser, setCrmUser] = useState([]);
  const [manager, setManager] = useState("");
  const [crm, setCrm] = useState([]);

  useEffect(() => {
    dispatch(getCrmUser(auth.token, sort, paginate, search, page, manager));
  }, [auth.token, search, sort, page, paginate, manager]);

  useEffect(() => {
    setCrmUser(crmusers?.users?.data);
    setCrm(crmusers);
  }, [crmusers]);

  useEffect(() => {
    if (crm) {
      setFrom(crm?.users?.from);
      setTo(crm?.users?.to);
      setTotal(crm?.users?.total);
      setPerPage(crm?.users?.per_page);
    }
  }, [crm]);
  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(auth.token));
  }, [auth.token]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Crm User</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field mx-auto">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              value={search}
                              onChange={({ target: { value } }) =>
                                setSearch(value)
                              }
                              placeholder="Search..."
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        {permissions ? (
                          permissions.find(
                            (ps) => ps.name === "crm-user-create"
                          ) != null ? (
                            <Link
                              to="/add-crm-user"
                              className="btn btn-secondary text-nowrap btn-sm p-1"
                            >
                              <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                            </Link>
                          ) : null
                        ) : null}
                      </div>
                      <div className="btn-group mr-1">
                        <a
                          href="import-lead.html"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-download"></i>&nbsp;&nbsp;Import
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header btn-header">
                    <div className="left-box-tools">
                      <button
                        className="btn btn-secondary btn-sm  mr-1"
                        data-toggle="modal"
                        data-target="#sendSMS"
                      >
                        <i className="fa fa-mobile pr-1"></i> SMS
                      </button>
                      <button className="btn btn-secondary openEmail btn-sm  mr-1">
                        <i className="fa fa-envelope pr-1"></i> Email
                      </button>
                      {permissions ? (
                        permissions.find(
                          (ps) => ps.name === "crm-user-delete"
                        ) != null ? (
                          <button className="btn btn-secondary btn-sm mr-1">
                            <i className="fa fa-trash pr-1"></i> Delete
                          </button>
                        ) : null
                      ) : null}
                      {permissions ? (
                        permissions.find((ps) => ps.name === "crm-user-edit") !=
                        null ? (
                          <button
                            className="btn btn-secondary btn-sm  mr-1"
                            data-toggle="modal"
                            data-target="#massUpdate"
                          >
                            <i className="fa fa-list pr-1"></i> Mass Update
                          </button>
                        ) : null
                      ) : null}
                      <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>

                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                      >
                        <option value="">Managers</option>
                        <option value="Yes">Manager</option>
                        <option value="No">User</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print "></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold text-center">
                                  S.No
                                </th>
                                <th className="font-weight-bold ">Name</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold text-center">
                                  Mobile
                                </th>
                                <th className="font-weight-bold text-center">
                                  Password
                                </th>
                                <th className="font-weight-bold text-center">
                                  Role
                                </th>
                                <th className="font-weight-bold text-center">
                                  Department
                                </th>
                                <th className="font-weight-bold text-center">
                                  Designation
                                </th>
                                <th className="font-weight-bold text-center">
                                  Date
                                </th>
                                {permissions.find(
                                  (ps) => ps.name === "crm-user-edit"
                                ) != null ? (
                                  <th className="font-weight-bold text-center text-right">
                                    Action
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {crmuser
                                    ? crmuser?.map((user, i) => (
                                        <tr key={Math.random()}>
                                          <td className="text-center">
                                            {i + 1}
                                          </td>
                                          <td className="text-left">
                                            {user?.name}
                                          </td>
                                          <td className="text-left">
                                            {user?.email}
                                          </td>
                                          <td className="text-center">
                                            {user?.mobile}
                                          </td>
                                          <td className="text-center">
                                            {user?.plain_password}
                                          </td>
                                          <td className="text-center">
                                            {user?.roles[0]?.name}
                                          </td>
                                          <td className="text-center">
                                            {user?.departments?.label}
                                          </td>
                                          <td className="text-center">
                                            {user?.designations?.label}
                                          </td>
                                          <td className="text-center">
                                            {moment(user?.created_at).format(
                                              "Do MMM YYYY"
                                            )}
                                          </td>
                                          {permissions.find(
                                            (ps) => ps.name === "crm-user-edit"
                                          ) != null ? (
                                            <td className="text-right text-center">
                                              <a
                                                href={`/crm-user/edit/${user.id}`}
                                              >
                                                <FiEdit />
                                              </a>
                                            </td>
                                          ) : null}
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="10">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Index;
