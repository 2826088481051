import Axios from "axios";

export const addEdp = (data, token) =>async(dispatch) => {

  try {
    const resp = await Axios.post(`/api/crm/add-edp-crm`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: "ADD_EDP_FULFILLED",
      payload: { message: "Report Added Successfully" },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ADD_EDP_REJECTED",
      payload: error,
    });
  }

};
