import Axios from "axios";

export const addPriority = (data, token) => {
  return {
    type: "CREATE_PRIORITY",
    payload: Axios.post(`/api/crm/add-priority`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const Priority = (token, paginate, search, page, sort) => {
  return {
    type: "PRIORITY_LIST",
    payload: Axios.get(
      `/api/crm/priority?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => err),
  };
};

export const priorityDelete = (data, token) => {
  return {
    type: "PRIORITY_DELETE",
    payload: Axios.post(`/api/crm/priority/destroy`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getDataById = (slug, token) => {
  return {
    type: "PRIORITY_BY_SLUG",
    payload: Axios.get(`/api/crm/prioritybyslug/${slug}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const updatePriority = (data, token) => {
  return {
    type: "UPDATE_PRIORITY",
    payload: Axios.post(`/api/crm/update-priority`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};


