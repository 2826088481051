import React,{memo} from 'react'
import _ from "lodash";
const leadDeatil = ({leadDetails}) => {
  return (
    <div>
         <div className="px-3 py-1 bg-secondary">
                  <span className="font-weight-bold">Lead Details</span>
                </div>
                <div className="px-3 py-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Name
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "full_name", "N/A")}
                        </span>
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Lead Date
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "created_at")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Mobile
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "mobile", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Whatsapp No
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "phone", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Company
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "company", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Email
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "email", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Interested Sector
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails?.lead_sector, "name", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Industry
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails?.lead_industry, "name", "N/A")}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Lead Status
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.capitalize(
                            _.get(leadDetails, "lead_status.title", "N/A")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Source
                      </div>
                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.capitalize(
                            _.get(leadDetails, "lead_source.name", "N/A")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Email Notification
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">No</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        SMS Notification
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">No</span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Disposition
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        {_.get(leadDetails, "disposition", "N/A")}
                      </div>
                    </div>
                   
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Created By
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        {_.get(leadDetails, "create.name", "N/A")}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Modified By
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        {_.get(leadDetails, "updateby.name", "N/A")}
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  )
}

export default memo(leadDeatil)