/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
  FiPhoneCall,
} from "react-icons/fi";

import { FcCallback } from "react-icons/fc";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import { MdDateRange, MdCallEnd, MdPayment } from "react-icons/md";
import { ImHappy2 } from "react-icons/im";
import { BiDetail, BiSolidSad } from "react-icons/bi";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getLeadsDetail,
  getDashboardCount,
  SalesDashboardCount,
  DashboardCountById,
} from "../store/actions/DashboardActions";
import {
  Input,
  CustomInput,
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Label,
  Button,
} from "reactstrap";
import Pagination from "react-js-pagination";
import Axios from "axios";
const TataPlayDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [associate, setAssociate] = useState([]);
  const [toggle, setToggle] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [id, setId] = useState("");
  const [associatecount, setAssociateCount] = useState("");
  const [associateteam, setAssociateTeam] = useState("");
  const [tataPlayData, setTataPlayData] = useState("");
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-investor-tata-play-count`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setTataPlayData(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darked">
                  <div className="card-body py-4">
                    <a href={`/consultant?associate=11&leaddate=${leadDate}`}>
                      <div className="row align-items-center ">
                        <div className="col-4">
                          <div className="square-box blue">
                            <FaUser color="white" size={18} />
                          </div>
                        </div>

                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.todayInvestor}
                          </h3>
                          <div className="mb-0 font-commom-2">Today Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-green">
                  <div className="card-body py-4">
                    <a href="/consultant?associate=11">
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box skyblue">
                            <FaUserCheck color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalInvestor}
                          </h3>
                          <div className="mb-0 font-commom-2">Total Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darkblue">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected`}
                    >
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box orange">
                            <FaPhoneAlt color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-orange">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Not_Connected`}
                    >
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box pink">
                            <MdCallEnd color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalNotConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Follow_Up_Hot`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box green">
                            <FaSadCry color="#33d69f" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalFollowUpHot}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Follow Up(Hot)
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Not_Interested`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box green">
                            <FaSadCry color="#33d69f" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalNotInterested}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Follow_Up`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkred">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalFollowUp}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Follow up{" "}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Call_Back`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <HiPhoneIncoming color="#6C00FF" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalCallBack}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Call Back
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/consultant?associate=11&mailshared=Yes`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalMailSharedYes}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Is Mail Shared
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Meeting_Align`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalMeetingAlign}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Align
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Meeting_Done`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalMeetingDone}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Done
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Meeting_Done_NOI`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalMeetingDoneNOI}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Meeting Done Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/consultant?associate=11&dispositiontype=Connected&disposition=Payment_Done`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <MdPayment color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {tataPlayData?.totalPaymentDone}
                          </h3>
                          <div className="mb-0 font-commom-2">Payment Done</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default TataPlayDashboard;
