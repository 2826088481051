import React, { useMemo, Fragment, useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FiTrash } from "react-icons/fi";
import { getCallSingleDataById } from "../../../store/actions/CallActions";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import axios from "axios";
const WhatsAppSummary = memo(({ whatsAppSu }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);

  const { auth } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (Array.isArray(whatsAppSu)) {
      setData(whatsAppSu);
    }
  }, [whatsAppSu]);



  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-7">
            <Link
              className="card-title m-0 h5 text-white d-block p-2"
              to="#"
              data-toggle="collapse"
              data-target="#collapse-7"
              aria-expanded="true"
              aria-controls="collapse-7"
            >
              WhatsApp Activities ({data?.length})
            </Link>
          </div>
          <div
            id="collapse-7"
            className="collapse show"
            aria-labelledby="heading-1"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.no</th>
                      <th>Message</th>
                      <th>Agent</th>
                      <th>Sent at</th>
                      <th>Received at</th>
                      {/* <th>Mobile No.</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {
                      <Fragment>
                        {data
                          ? data.map((ca, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{ca?.message}</td>
                              <td>{ca?.agent?.name}</td>
                              <td>{ca?.created_at}</td>
                              <td>{ca?.updated_at}</td>
                              {/* <td>{ca?.agent_mobile
                              }</td> */}
                            </tr>
                          ))
                          : null}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default WhatsAppSummary;
