import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";

import { useHistory } from "react-router";
import { bookServices } from "../../store/actions/ServicesActions";
const AddServicesLead = memo(({ leadDetail, setWsStatus, userId }) => {
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [services, setServices] = useState([]);
  const [mode, setMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentid, setPaymentId] = useState("");
  const [amount, setAmount] = useState("");
  const [amountt, setAmountt] = useState("");
  const [id, setId] = useState("");
  const [servicesList, setServicesList] = useState("");
  const [leadId, setLeadId] = useState("");
  const [multipleSelection, setMultipleSelection] = useState(false);
  const [fssaiMultiple, setFssaiMultiple] = useState("");
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;
  const { servicespurchased, errors } = useSelector((state) => state.serviceReducer);

  useEffect(() => {
    setName(leadDetail?.full_name);
    setMobile(leadDetail?.mobile);
    setEmail(leadDetail?.email);
    setId(leadDetail?.id);
    setLeadId(leadDetail?.id);
  }, [leadDetail]);

  useEffect(() => {
    console.log(services,'servicesList');
    if (services && servicesList) {
      const amo = services.filter((item) => item.value == servicesList);
      setAmount(amo?.[0]?.amount);
    }
  }, [services, servicesList]);

  useEffect(() => {
    if(amount > 0){
    const amout = (parseInt(amount) * 18) / 100;

    setAmountt(parseInt(amount) + amout);
    }
  }, [amount]);

  useEffect(() => {
    Axios.get("/api/crm/service-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      setServices(
        response.data?.subCategories.filter((item) => item.value !== "0") || []
      )
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("services", servicesList);
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("mode", mode);
    data.append("paymentType", paymentType);
    data.append("payment_id", paymentid);
    data.append("amount", amount);
    data.append("id", userId ? userId : leadId);
    data.append("leadId", leadId);
    data.append("service_id", 1);
    data.append("engaged", 1);
    data.append("payment_img", file == undefined ? "" : file);
    dispatch(bookServices(data, token));
  };

  useEffect(() => {
    if (servicespurchased) {
      setModal(false);
      NotificationManager.success("Booked Successfully", "Success");
      setPaymentId("");
      history.push("/leads");
    }
  }, [servicespurchased]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Services
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add User Services
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>Services <span className="text-danger">*</span></Label>
                  <Input
                    type="select"
                    invalid={!!errors?.service_id}
                    className="custom-select"
                    value={servicesList}
                    onChange={({ target }) => {
                      setServicesList(target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {services?.map((item, i) => {
                      return (
                        <option value={item.value} key={i}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </Input>
                  <FormFeedback>
                    {!!errors?.service_id ? errors?.service_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              {multipleSelection && servicesList && (
                <Col sm="6">
                  <FormGroup>
                    <Label>Select Fsaai Registration</Label>
                    <Input
                      type="select"
                      name="multiple"
                      value={fssaiMultiple}
                      onChange={({ target }) => {
                        setFssaiMultiple(target.value);
                      }}
                      className="custom-select"
                    >
                      <option value="fssai-basic">
                        Fssai Basic Registration
                      </option>
                      <option value="fssai-state">FSSAI State License</option>
                      <option value="fssai-central">
                        FSSAI Central License
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              )}

              {/* {servicesList && ( */}
                <Col sm="6">
                  <FormGroup>
                    <Label>Amount <span className="text-danger">*</span></Label>
                    <Input value={amountt} invalid={!!errors?.amount} name="amount" disabled />
                    <FormFeedback>
                      {!!errors?.amount ? errors?.amount[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              {/* )} */}

              <Col sm="6">
                <FormGroup>
                  <Label>Name <span className="text-danger">*</span></Label>

                  <Input
                    value={name}
                    name="name"
                    invalid={!!errors?.name}
                    onChange={({ target: { value } }) => setName(value)}
                  />

                  <FormFeedback>
                    {!!errors?.name ? errors?.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Mobile <span className="text-danger">*</span></Label>
                  <Input
                    name="mobile"
                    invalid={!!errors?.mobile}
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />

                  <FormFeedback>
                    {!!errors?.mobile ? errors?.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email <span className="text-danger">*</span></Label>
                  <Input
                    name="email"
                    invalid={!!errors?.email}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />

                  <FormFeedback>
                    {!!errors?.email ? errors?.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    name="mode"
                    value={mode}
                    onChange={({ target: { value } }) => setMode(value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={paymentType}
                    name="paymentType"
                    onChange={({ target: { value } }) => setPaymentType(value)}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id <span className="text-danger">*</span></Label>
                  <Input
                    name="payment_id"
                    value={paymentid}
                    invalid={!!errors?.payment_id}
                    onChange={({ target: { value } }) => setPaymentId(value)}
                  />

                  <FormFeedback>
                    {!!errors?.payment_id ? errors?.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddServicesLead;
