import React, { useEffect, useRef } from "react"
import { Chart } from "chart.js"
const PieChart = ({ pieChartData, colors }) => {
    const canvas = useRef(null)
    useEffect(() => {
        const data = {
            labels: pieChartData?.map(item => item.label) || [],
            datasets: [{
                label: 'My First Dataset',
                data: pieChartData?.map(item => item.data) || [],
                backgroundColor: colors,
                hoverOffset: 4
            }]
        };
        if (canvas.current) {
            const pieChart = new Chart(canvas.current, {
                type: 'pie',
                data,
                options: {
                    maintainAspectRatio: false,
                    responsive: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Target Report',
                            font: {
                                weight: "bold",
                                size: 20,
                            },
                            color: "#000"

                        },
                        legend: {
                            display: true,
                            position: "bottom",
                            labels: {
                                boxHeight: 20,
                                boxWidth: 20,
                                usePointStyle: true,
                                pointStyle: "circle",
                                padding: 20
                            }
                        },


                    },

                },
            })

            return (() => {
                pieChart.destroy()
            })
        }
    }, [canvas.current, pieChartData])

    return (
        <canvas width={300} className="mx-auto" ref={canvas}></canvas>
    )
}

export default PieChart