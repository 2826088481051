import { useEffect, useRef } from 'react';

const useScrollTrigger = (callback, options = {}) => {
  const targetRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: options.threshold || 0.5, // Trigger when specified percentage of the element is visible
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, options]);

  return targetRef;
};

export default useScrollTrigger;