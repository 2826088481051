import React, { useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
// import EditCustomizeProjectReport from "./EditCustomizeProjectReport";
const ServiceSectorProjectReport = ({ leadDetails, setWsStatus }) => {
  const boodedUser = useMemo(() => {
    if (leadDetails) return leadDetails;
  }, [leadDetails]);

  const bookedProjectReport = useMemo(() => {
    if (leadDetails) return leadDetails?.user_customize_report;
  }, [leadDetails]);
  console.log(leadDetails, "leadDetails");
  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-3">
            <Link
              className="card-title m-0 h5 text-white d-block p-2 collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapse-8"
              aria-expanded="true"
              aria-controls="collapse-8"
            >
              Service Project Report ({_.size(leadDetails)})
            </Link>
          </div>
          <div
            id="collapse-8"
            className="collapse"
            aria-labelledby="heading-8"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>

                      <th>Amount</th>
                      <th>Payment Id</th>
                      <th>Payment Mode</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {leadDetails
                      ? leadDetails.map((customizeReport, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>{customizeReport?.user_name}</td>
                            <td>{customizeReport?.user_mobile}</td>
                            <td>{customizeReport?.user_email}</td>

                            <td>{customizeReport?.amount}</td>
                            <td>{customizeReport?.paymentsdd?.payment_id}</td>
                            <td>{customizeReport?.paymentsdd?.payment_mode}</td>
                            {/* {customizeReport.agentcode == null ? (
                              <td>
                                <EditCustomizeProjectReport
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  item={customizeReport}
                                  bookedProjectReport={bookedProjectReport}
                                  projectId={customizeReport?.customizeId}
                                />
                              </td>
                            ) : null} */}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSectorProjectReport;
