import React, { useMemo, Fragment, memo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import EditWorkshop from "./EditWorkShop";
import Axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
const MembershipServicesList = memo(({ leadDetails, setWsStatus }) => {
  const [memberShipServices, setMemberShipServices] = useState();

  const { auth } = useSelector(({ auth }) => auth);
  useEffect(() => {
    Axios.post(
      `/api/crm/membership-services`,
      {
        id: leadDetails.id,
        ids: [69, 60, 71, 68, 74, 65],
      },
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
      .then((res) => setMemberShipServices(res.data))
      .catch((err) => console.log(err));
  }, [leadDetails]);

  console.log(memberShipServices, "check");

  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-3">
            <Link
              className="card-title m-0 h5 text-white d-block p-2 collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapse-9"
              aria-expanded="true"
              aria-controls="collapse-3"
            >
              Membership Services List
            </Link>
          </div>
          {memberShipServices?.gstStatus != null ? (
            <div
              id="collapse-9"
              className="collapse"
              aria-labelledby="heading-3"
              data-parent="#ActivityAccordion"
            >
              <div className="card-body p-2">
                <h4>GST</h4>
                <div className="table-responsive">
                  <table className="table table-sm m-0">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Applied On</th>
                        <th>Service Type</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{memberShipServices?.gstStatus?.name}</td>
                        <td>{memberShipServices?.gstStatus?.type}</td>
                        <td>{memberShipServices?.gstStatus?.updated_at}</td>

                        <td>MemberShip</td>
                        <td>{memberShipServices?.gstStatus?.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {memberShipServices?.iprStatus != null ? (
            <div
              id="collapse-9"
              className="collapse"
              aria-labelledby="heading-3"
              data-parent="#ActivityAccordion"
            >
              <div className="card-body p-2">
                <h4>FASSAI Registration</h4>
                <div className="table-responsive">
                  <table className="table table-sm m-0">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Applied On</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{memberShipServices?.iprStatus?.updated_at}</td>
                        <td>{memberShipServices?.iprStatus?.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {memberShipServices?.customizeProjectReportStatus != null ? (
            <div
              id="collapse-9"
              className="collapse"
              aria-labelledby="heading-3"
              data-parent="#ActivityAccordion"
            >
              <div className="card-body p-2">
                <h4>Bankable Customized Project Report</h4>
                <div className="table-responsive">
                  <table className="table table-sm m-0">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Applied On</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.bankable_partner_name
                          }
                        </td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.bankable_mobile
                          }
                        </td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.bankable_email
                          }
                        </td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.updated_at
                          }
                        </td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.status
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {memberShipServices?.udyamStatus != null ? (
            <div
              id="collapse-9"
              className="collapse"
              aria-labelledby="heading-3"
              data-parent="#ActivityAccordion"
            >
              <div className="card-body p-2">
                <h4>Udyam Aadhar Registration</h4>
                <div className="table-responsive">
                  <table className="table table-sm m-0">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Applied On</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{memberShipServices?.udyamStatus?.name}</td>
                        <td>{memberShipServices?.udyamStatus?.email}</td>
                        <td>{memberShipServices?.udyamStatus?.mobile_no}</td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.updated_at
                          }
                        </td>
                        <td>
                          {
                            memberShipServices?.customizeProjectReportStatus
                              ?.status
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {memberShipServices?.startupStatus != null ? (
            <div
              id="collapse-9"
              className="collapse"
              aria-labelledby="heading-3"
              data-parent="#ActivityAccordion"
            >
              <div className="card-body p-2">
                <h3>Startup Registration</h3>
                <div className="table-responsive">
                  <table className="table table-sm m-0">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Transaction ID</th>
                        <th>Mode</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                    {boodedWorkshop
                      ? boodedWorkshop.map((workShop, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>{workShop?.work_shop_details?.name}</td>
                            <td>{workShop?.work_shop_details?.date}</td>
                            <td>{workShop?.work_shop_details?.end_date}</td>

                            <td>{workShop?.payment_id}</td>
                            <td>{workShop?.payment_mode}</td>

                            {workShop?.agent_id == null ? (
                              <td className="text-right">
                                <EditWorkshop
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  workShopId={workShop?.id}
                                  boodedWorkshop={boodedWorkshop}
                                />
                              </td>
                            ) : null}
                          </tr>
                        ))
                      : null}
                  </tbody> */}
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <div
            id="collapse-9"
            className="collapse"
            aria-labelledby="heading-3"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Transaction ID</th>
                      <th>Mode</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boodedWorkshop
                      ? boodedWorkshop.map((workShop, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>{workShop?.work_shop_details?.name}</td>
                            <td>{workShop?.work_shop_details?.date}</td>
                            <td>{workShop?.work_shop_details?.end_date}</td>

                            <td>{workShop?.payment_id}</td>
                            <td>{workShop?.payment_mode}</td>

                            {workShop?.agent_id == null ? (
                              <td className="text-right">
                                <EditWorkshop
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  workShopId={workShop?.id}
                                  boodedWorkshop={boodedWorkshop}
                                />
                              </td>
                            ) : null}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
});

export default MembershipServicesList;
