import _ from "lodash";

const initialState = {
  createActivity: [],
  createActivityLoading: false,
  createActivityStatus: false,

  activity: [],
  loading: false,
  activityStatus: false,

  activityDelete: [],
  activityDeleteStatus: false,

  activitySlugStatus: false,
  activitySlug: [],

  activityUpdate: [],
  activityUpdateStatus: false,
  activityUpdateLoading: false,

  getcampaignstatus: false,
  campaign: [],

  error: {},
};

const activityReducer = (state = initialState, actions) => {
  const { payload } = actions;
  switch (actions.type) {
    case "ADD_ACTIVITY_PENDING":
      state = {
        ...state,
        createActivityLoading: true,
        createActivityStatus: false,
        error: {},
      };
      break;
    case "ADD_ACTIVITY_FULFILLED":
      state = {
        ...state,
        createActivityLoading: false,
        createActivityStatus: true,
        createActivity: _.get(payload, "data", {}),
      };
      break;
    case "ADD_ACTIVITY_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = actions;
      if (status === 422) {
        state = {
          ...state,
          createActivityLoading: false,
          createActivityStatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          createActivityLoading: false,
          createActivityStatus: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "ACTIVITY_PENDING":
      state = {
        ...state,
        createActivityLoading: false,
        createActivityStatus: false,
        loading: true,
        activityStatus: false,
        activityDeleteStatus: false,
        activitySlugStatus: false,
        activityUpdateStatus: false,
        activityUpdateLoading: false,
        error: {},
      };
      break;
    case "ACTIVITY_FULFILLED":
      state = {
        ...state,
        loading: false,
        activityStatus: true,
        activity: _.get(payload, "data", {}),
      };
      break;
    case "ACTIVITY_REJECTED":
      state = {
        ...state,
        loading: false,
        activityStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "ACTIVITY_DELETE_PENDING":
      state = {
        ...state,
        activityDeleteStatus: false,
        error: {},
      };
      break;
    case "ACTIVITY_DELETE_FULFILLED":
      state = {
        ...state,
        activityDeleteStatus: true,
        activityDelete: _.get(payload, "data", {}),
      };
      break;
    case "ACTIVITY_DELETE_REJECTED":
      state = {
        ...state,
        activityDeleteStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "ACTIVITY_SLUG_PENDING":
      state = {
        ...state,
        activitySlugStatus: false,
        error: {},
      };
      break;
    case "ACTIVITY_SLUG_FULFILLED":
      state = {
        ...state,
        activitySlugStatus: true,
        activitySlug: _.get(payload, "data", {}),
      };
      break;
    case "ACTIVITY_SLUG_REJECTED":
      state = {
        ...state,
        activitySlugStatus: true,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "ACTIVITY_UPDATE_PENDING":
      state = {
        ...state,
        activityUpdateLoading: true,
        activityUpdateStatus: false,
        error: {},
      };
      break;
    case "ACTIVITY_UPDATE_FULFILLED":
      state = {
        ...state,
        activityUpdateLoading: true,
        activityUpdateStatus: true,
        activityUpdate: _.get(payload, "data", {}),
      };
      break;
    case "ACTIVITY_UPDATE_REJECTED":
      if (payload.response.status === 422) {
        state = {
          ...state,
          activityUpdateLoading: false,
          activityUpdateStatus: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          activityUpdateLoading: false,
          activityUpdateStatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }
      break;

    case "CAMPAIGN_PENDING":
      state = {
        ...state,
        getcampaignstatus: false,
        error: {},
      };
      break;
    case "CAMPAIGN_FULFILLED":
      state = {
        ...state,
        getcampaignstatus: true,
        campaign: _.get(payload, "data", {}),
      };
      break;
    case "CAMPAIGN_REJECTED":
      if (payload.response.status === 422) {
        state = {
          ...state,
          getcampaignstatus: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          getcampaignstatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }
      break;
  }
  return state;
};

export default activityReducer;
