import React, { Fragment, useEffect, useState } from "react";
import { changePassword } from "../../store/actions/AuthActions";
import Logo from "../../assets/img/logo.png";
import { Input, FormFeedback, Button } from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { Redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import MainWrapper from "../MainWrapper";
import { NotificationManager } from "react-notifications";
const Login = () => {
  const dispatch = useDispatch();
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isAuthenticated, error, loading,auth,message } = useSelector(
    (state) => state.auth
  );

  useEffect(()=>{
    if (message) {
      NotificationManager.success(message);
     
    }
   },[message])
   

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(changePassword( {currentPassword,newPassword,confirmPassword} ,auth.token));

  };
  console.log(isAuthenticated,"isAuthenticated")
  if (!isAuthenticated) return <Redirect to="/login" />;
  //message

// ":Password Updated Successfully"

  return (
    <MainWrapper>
      <main className="main h-100 w-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="form-area mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card mt-5 mb-5">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="text-center">
                        <img
                          src={Logo}
                          alt="Institute for Industrial Development"
                          className="img-fluid"
                        />
                      </div>
                      <h1 className="text-center mb-3 mt-3">CHANGE PASSWORD</h1>
                      <form onSubmit={handleSubmit}>
                        {/* <div className="form-group">
                          <label>Email</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.email}
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <FormFeedback>{error.email}</FormFeedback>
                        </div> */}
                        <div className="form-group">
                          <label>Old Password</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.currentPassword}
                            type="password"
                            name="old password"
                            // placeholder=" old password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                          <FormFeedback>{error.currentPassword}</FormFeedback>
                        </div>
                        <div className="form-group">
                          <label>New Password</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.newPassword}
                            type="password"
                            name="password"
                            // placeholder=" new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <FormFeedback>{error.newPassword}</FormFeedback>
                        </div>
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <Input
                            bsSize="lg"
                            invalid={!!error.confirmPassword}
                            type="password"
                            name="password"
                            // placeholder="confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <FormFeedback>{error.confirmPassword}</FormFeedback>
                        </div>
                        <div className="text-center mt-3">
                          <Button
                            size="lg"
                            color="primary"
                            block
                            disabled={loading}
                          >
                            {loading ? (
                              <FaSpinner className="fa-spin" />
                            ) : (
                              "CHANGE PASSWORD"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      </MainWrapper>
  );
};

export default Login;
