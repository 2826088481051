import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";

import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { getallPermissions } from "../../store/actions/AuthActions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";

const CallLeadList = ({ history: { push } }) => {
    const dispatch = useDispatch();
    const {
        auth: { token },
        isAuthenticated,
        permissions: { permission },
    } = useSelector((state) => state.auth);

    const permissions = useMemo(() => permission ?? [], [permission]);
    useEffect(() => {
        dispatch(getallPermissions(token));
    }, [token]);

    const {
        leadsLoader,
        
        leadsStatusListStatus,
        leadsStatus,
    } = useSelector((state) => state.lead);
    const {
        sectors: { subcategory },
        projects: { project_reports },
    } = useSelector((state) => state.memberShip);
    
    const [sort, setSort] = useState("desc");
    
    const [page, setPage] = useState(1);
    const [paginate, setPaginate] = useState(25);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [perPage, setPerPage] = useState(0);
 
    const [leadStatusData, setLeadStatusData] = useState("");
    const [search, setSearch] = useState("");
    const [searches, setSearches] = useState("");

    const [priority, setPriority] = useState("");

    const [leadDate, setLeadDate] = useState("");
    const [createdByAdmin, seCreatedByAdmin] = useState([]);
    
   
    const [createdby, setCreatedBy] = useState("");
    
    const [roleId, setRoleId] = useState("");
    const [crmuser, setCrmUser] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [createdByy, setCreatedByy] = useState("");
    const [endDate, setEndDate] = useState("");
   
   
    const [assignToUser, setassignToUser] = useState("");
   
    
    const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
    const { auth } = useSelector((state) => state.auth);

    useEffect(() => {
        if (auth) {
            setRoleId(auth?.roles?.[0]?.id);
        }
    }, [auth]);
    useEffect(() => {
        if (createdby) {
            setCreatedByy(createdby.value);
        }
    }, [createdby]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const mo = urlParams.get('assign');
        

        const lead = urlParams.get('lead');
        const despo = urlParams.get("desposition");
        const currentDate = urlParams.get("TodayLead");
        const startDate = urlParams.get("StartDate");
        const endDate = urlParams.get("EndDate");
        // const startDate = urlParams.get("StartDate");

        // const endDate = urlParams.get("EndDate");
        if (currentDate) {
            setLeadDate(currentDate)
        }
        if (startDate) {
            setStartDate(startDate)
        }
        if (endDate) {
            setEndDate(startDate)
        }
        if (mo) {
            setassignToUser(mo)
        }
        // if(endDate)
        // {
        //   setEndDate(endDate)
        // }
        if (lead) {
            setPriority(lead)
        }

        
    }, [createdByAdmin]);

    useEffect(() => {
        if (leadsStatusListStatus) {
            setLeadStatusData(leadsStatus.leadstatus);
        }
    }, [leadsStatusListStatus]);

    useEffect(() => {
        const debounce = setTimeout(() => {
            setSearches(search);
        }, 500);
        return () => {
            clearTimeout(debounce);
        };
    }, [search]);
    const handleSendLead = (e) => {
        e.preventDefault();
        setOpenSendLeadModal(!openSendLeadModal);
    };
    useEffect(() => {
        const page = new URL(window.location.href);
        const getPage = page.searchParams.get("page");
        if (getPage) {
            setPage(getPage);
        }
    }, []);

    useEffect(() => {
        Axios.get(`/api/crm/get-leads-list-call-detail?id=${assignToUser}&start=${start}&end=${end}&paginate=${paginate}&sort=${sort}&call_date=${leadDate}&page=${page}`, {
            headers: { authorization: `Bearer ${token}` },
        })
            .then((res) => {setCrmUser(res?.data?.leadsActivity
            );
            setPerPage(res?.data?.leadsActivity ? res?.data?.leadsActivity?.per_page : null)})
            .catch((error) => console.log(error));
    }, [token, assignToUser, start, end, paginate, sort,page]);


    




    if (!isAuthenticated) return <Redirect to="/login" />;


    return (
        <Fragment>
            <MainWrapper>
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="page-header mb-2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="c-card-top">
                                        <div className="c-card-top-title">
                                            <h4 className="mb-0">Leads Call List</h4>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card d-card-table">
                                    <div className="card-header pl-3 d-card-header">

                                        

                                        {permissions ? (
                                            permissions.find((ps) => ps.name === "lead-edit") !=
                                                null ? (
                                                <button
                                                    className="btn btn-secondary btn-sm"
                                                    data-toggle="modal"
                                                    data-target="#massUpdate"
                                                >
                                                    <i className="fa fa-list pr-1" /> Mass Update
                                                </button>
                                            ) : null
                                        ) : null}
                                        

                                        


                                        <div className="box-tools pull-right">
                                            <select
                                                id="ddlSortBy"
                                                className="custome-select small-select mr-1"
                                                onChange={(e) => setSort(e.target.value)}
                                                value={sort}
                                            >
                                                <option value="">Sort By</option>
                                                <option value="asc">Ascending</option>
                                                <option value="desc">Descending</option>
                                            </select>


                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-hover mb-0 table-sm small">
                                                        <thead className="thead-light">
                                                            <tr>


                                                                <th className="font-weight-bold">Full Name</th>
                                                                <th className="font-weight-bold">Mobile</th>
                                                                {/* <th className="font-weight-bold">Email</th> */}
                                                                {/* <th className="font-weight-bold">
                                  Bussiness Name
                                </th> */}
                                                                <th className="font-weight-bold">
                                  Disposition type
                                </th>
                                                                
                                                                <th className="font-weight-bold">Priority</th>
                                                                <th className="font-weight-bold">
                                                                    Sector 
                                                                </th>
                                                                <th className="font-weight-bold">
                                                                    Industry 
                                                                </th>

                                                                {/* <th className="font-weight-bold">
                                  Want to Start Business
                                </th> */}

                                                                <th className="font-weight-bold">Lead Date</th>
                                                               
                                                                {/* <th className="font-weight-bold">Pincode</th> */}

                                                                {permissions.find(
                                                                    (ps) =>
                                                                        ps.name === "lead-delete" ||
                                                                        ps.name === "lead-edit"
                                                                ) != null ? (
                                                                    <th className="text-right font-weight-bold">
                                                                        Actions
                                                                    </th>
                                                                ) : null}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="d-table-body">
                                                            {leadsLoader ? (
                                                                <Fragment>
                                                                    <tr>
                                                                        <td colSpan={7}>
                                                                            <Loader loading={leadsLoader} />
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>

                                                                    {crmuser?.data?.map((item, index) => {
                                                                        return (
                                                                            <tr key={item?.id}>

                                                                                <td>
                                                                                    {/* {item?.lead

                                                                                        ?.full_name 

                                                                                    } ({(item?.lead?.activity?.length)}) */
                                                                                    <Link
                                            to={`/lead/${item?.lead_id}/details?page=${page}`}
                                          >
                                            {item?.lead

?.full_name 

} ({(item?.lead?.activity?.length)})
                                          </Link>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.mobile
}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.disposition
}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.priority
}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.lead_sector?.name
}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.lead_industry?.name
}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.lead?.lead_date
}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </Fragment>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card-footer">
                                        <div className="d-flex align-items-center justify-content-between">


                                            <div className="d-flex">
                                                <Input
                                                    type="select"
                                                    className="custom-select custom-select-page"
                                                    onChange={(e) => setPaginate(e.target.value)}
                                                    value={paginate}
                                                >
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                    <option value="500">500</option>
                                                    <option value="1000">1000</option>
                                                    <option value="2000">2000</option>
                                                    <option value="3000">3000</option>
                                                    <option value="5000">5000</option>
                                                    <option value="7000">7000</option>
                                                </Input>
                                                <Pagination
                                                    activePage={page}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={crmuser
?.total}
                                                    pageRangeDisplayed={5}
                                                    onChange={(page) => setPage(page)}
                                                    innerClass="pagination pagination-sm"
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                    Showing {crmuser?.from
                                    } to {crmuser?.to} of {crmuser?.total} entries
                                </div>
                                    </div> */}
                                     <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                     
                      
                        <span>
                         Showing {crmuser?.from
                                    } to {crmuser?.to} of {crmuser?.total} entries
                        </span>
                      
                      
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2000">2000</option>
                          <option value="3000">3000</option>
                          <option value="5000">5000</option>
                          <option value="7000">7000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={perPage}
                          totalItemsCount={crmuser
?.total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </MainWrapper>
        </Fragment>
    );
};

export default CallLeadList;
