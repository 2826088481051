import React, { Fragment, useState } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import MainWrapper from "../MainWrapper";
import Subheader from "./Subheader";

const LeadStatus = () => {
  const [modal, setModal] = useState(false);
  const [modalrating, setModalrating] = useState("call not responding");
  const [modaldescription, setModaldescription] = useState(
    "call not responding"
  );
  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <Subheader name={"LeadStatus"} />
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header p-2">
                    <div className="row">
                      <div className="col-md-9 ">
                        <Button className="btn btn-secondary btn-sm pl-1 pr-1 mr-1 ml-2 ">
                          <i className="fa fa-trash pr-1"></i> Delete
                        </Button>
                        <Button
                          className="btn btn-secondary btn-sm"
                          title="Refresh"
                        >
                          <i className="fa fa-refresh"></i>
                        </Button>
                      </div>
                      <div className="col-md-3 ">
                        <div className="d-flex justify-content-center">
                          <select
                            id="ddlSortBy"
                            className=" custome-select small-select mr-1 "
                          >
                            <option value="">Sort By</option>
                            <option value="Ascending">Ascending</option>
                            <option value="Descending">Descending</option>
                          </select>
                          <Button
                            className="btn btn-secondary btn-floating btn-sm mr-1"
                            title="Print"
                          >
                            <i className="fa fa-print"></i>
                          </Button>
                          <Button
                            className="btn btn-secondary btn-floating btn-sm mr-1"
                            title="Export To PDF"
                          >
                            <i className="fa fa-file-pdf-o"></i>
                          </Button>
                          <Button
                            className="btn btn-secondary btn-floating btn-sm"
                            title="Export To Excel"
                          >
                            <i className="fa fa-file-excel-o"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">Select</th>
                                <th className="font-weight-bold">
                                  Lead Status
                                </th>
                                <th className="font-weight-bold">
                                  Description
                                </th>
                                <th className="font-weight-bold">Status</th>
                                <th className="font-weight-bold text-right pr-3">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="custom-checkbox custom-control custom-control-inline ml-2">
                                    <Input
                                      id="first"
                                      name="first"
                                      type="checkbox"
                                      className="custom-control-input  "
                                    />
                                    <label
                                      className="custom-control-label "
                                      htmlFor="first"
                                    ></label>
                                  </div>
                                </td>
                                <td>Call Not Responding</td>
                                <td>Call Not Responding</td>
                                <td>
                                  <span className="badge badge-success">A</span>
                                </td>
                                <td className="text-right pr-3">
                                  <a
                                    href="#"
                                    className="mr-2 text-info"
                                    data-toggle="modal"
                                    data-target="#updateLeadStatus"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      onClick={toggle}
                                    ></i>
                                  </a>
                                  <a href="#" className="text-danger">
                                    <i className="fa fa-trash-o"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <Input
                                      id="second"
                                      name="second"
                                      type="checkbox"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="second"
                                    ></label>
                                  </div>
                                </td>
                                <td>Call Not Responding</td>
                                <td>Call Not Responding</td>
                                <td>
                                  <span className="badge badge-success">A</span>
                                </td>
                                <td className="text-right">
                                  <a
                                    href="#"
                                    className="mr-2 text-info"
                                    data-toggle="modal"
                                    data-target="#updateLeadStatus"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      onClick={toggle}
                                    ></i>
                                  </a>
                                  <a href="#" className="text-danger">
                                    <i className="fa fa-trash-o"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <Input
                                      id="third"
                                      type="checkbox"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="third"
                                    ></label>
                                  </div>
                                </td>
                                <td>Call Not Responding</td>
                                <td>Call Not Responding</td>
                                <td>
                                  <span className="badge badge-success">A</span>
                                </td>
                                <td className="text-right">
                                  <a
                                    href="#"
                                    className="mr-2 text-info"
                                    data-toggle="modal"
                                    data-target="#updateLeadStatus"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      onClick={toggle}
                                    ></i>
                                  </a>
                                  <a href="#" className="text-danger">
                                    <i className="fa fa-trash-o"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <Input
                                      id="fourth"
                                      type="checkbox"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="fourth"
                                    ></label>
                                  </div>
                                </td>
                                <td>Call Not Responding</td>
                                <td>Call Not Responding</td>
                                <td>
                                  <span className="badge badge-success">A</span>
                                </td>
                                <td className="text-right">
                                  <a
                                    href="#"
                                    className="mr-2 text-info"
                                    data-toggle="modal"
                                    data-target="#updateLeadStatus"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      onClick={toggle}
                                    ></i>
                                  </a>
                                  <a href="#" className="text-danger">
                                    <i className="fa fa-trash-o"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="custom-checkbox custom-control">
                                    <Input
                                      id="fifth"
                                      type="checkbox"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="fifth"
                                    ></label>
                                  </div>
                                </td>
                                <td>Call Not Responding</td>
                                <td>Call Not Responding</td>
                                <td>
                                  <span className="badge badge-success">A</span>
                                </td>
                                <td className="text-right">
                                  <a
                                    href="#"
                                    className="mr-2 text-info"
                                    data-toggle="modal"
                                    data-target="#updateLeadStatus"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      onClick={toggle}
                                    ></i>
                                  </a>
                                  <a href="#" className="text-danger">
                                    <i className="fa fa-trash-o"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Source</ModalHeader>
              <ModalBody>
                <div className="form-group">
                  <label className="m-0">Lead Status</label>
                  <Input
                    type="text"
                    value={modalrating}
                    onChange={(e) => setModalrating(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="m-0">Description</label>
                  <Input
                    type="text"
                    value={modaldescription}
                    onChange={(e) => setModaldescription(e.target.value)}
                  />
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <Input
                    type="checkbox"
                    id="isActive"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="isActive">
                    Is Active
                  </label>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Close
                </Button>
                <Button color="primary" onClick={toggle}>
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default LeadStatus;
