import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { Link, useHistory } from "react-router-dom";
import { Collapse } from "reactstrap";
import { useParams } from "react-router";
import _ from "lodash";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import { fetchLeadDetail } from "../../store/actions/LeadActions";
import Note from "./sub-component/Note";
import AddWorkshop from "./addWorkshop";
import callingGif from "../../assets/img/calling.gif";
import AddMembership from "./addMembership";
import List from "../users/List";
import ProjectReport from "../projectreport/ProjectReport";
import CourseList from "../courses/CourseList";
import AddServicesLead from "./addServicesLead";
import AddProjectReport from "../projectreport/addProjectReport";
import CustomizeProjectReport from "../customizeprojectreport/CustomizeProjectReport";
import MembershipServicesList from "../users/membershiplist";
import AddIndustrialReport from "../industrialreport/addIndustrialReport";
import AddCustomizeProjectReport from "../customizeprojectreport/addCustomizeProjectReport";
import AddEdpLead from "./addEdpLead";
import ServiceSectorProjectReport from "../servicesectorprojectreport/ServiceSectorProjectReport";
import LeadClosed from "./sub-component/LeadClosed";
import { getLeadUser } from "../../store/actions/LeadActions";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
import moment from "moment";
import {
  getSourceList,
  getLeadStatusList,
} from "../../store/actions/LeadActions";
import Task from "./Task";
import Axios from "axios";
import TaskList from "./sub-component/TaskList";
import { Modal } from "reactstrap";
import AddCoursesLead from "./addCoursesLead";
import LoginToCall from "./logintotelecall";
import { Calling } from "../../store/actions/LoginCallActions";
import SendSms from "./sendSms";
import SendFeedback from "./sendFeedback"
import SendSmsSale from "./sendSmsSale";
import NewCall from "./newCall";
import CallList from "./sub-component/CallList";
import Select from "react-select";
import { FaNoteSticky, FaNotesMedical } from "react-icons/fa";
import {
  FiEdit,
  FiCheckCircle,
  FiUser,
  FiFileText,
  FiArrowLeft,
} from "react-icons/fi";
import { BiTask } from "react-icons/bi";
import AddServices from "../services/addServices";
import { Input } from "reactstrap";
import { updateLeader } from "../../store/actions/LeadActions";
import WhatsAppSummary from "./sub-component/WhatsAppSummary";
import { FaWhatsapp } from "react-icons/fa";
import IndustrialReport from "../industrialreport/IndustrialReport";
import { crmUserDetails } from "../../store/actions/CrmUserActions";
import { FaSpinner } from "react-icons/fa";
import Loader from "../../layouts/Loader";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import AddCourses from "../courses/addCourses";
import { getPriority } from "../../store/actions/AllActivityActions";
import LeadDetails from "../../components/lead-Detail/leadDeatil";
import UserDetail from "../lead-Detail/userDetail"
const Details = ({ history }) => {
  const [tab, setTab] = useState("2");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [whatsAppSu, setWhatsAppSu] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [leadStatusData, setLeadStatusData] = useState("");
  const [leadStatuss, setLeadStatuss] = useState("");
  const [wsStatus, setWsStatus] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [activityType, setActivityType] = useState("");
  const [updateName, setUpdateName] = useState("N/A");
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [activityList, SetActivityList] = useState([]);
  const [purchasedServices, setPurchasedServices] = useState([]);
  const [coursePayment, setCoursePayment] = useState([]);
  const [source, setSource] = useState("");
  const [sources, setSources] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [priority, setPriority] = useState("");
  const [campaign, setCampaign] = useState("");
  const [initialSector, setInitialSector] = useState("");
  const [leadIdd, setLeadIdd] = useState("");
  const [leadId, setLeadId] = useState("");
  const [activityLength, setActivityLength] = useState(false);
  const [showuser, setShowUser] = useState(false);
  const [sort, setSort] = useState("desc");
  const [disposition, setDisposition] = useState("");
  const [dispositionList, setDispositionList] = useState([]);
  const [serviceProjectReport, setServiceProjectReport] = useState([]);

  const {
    auth,
    campaigndetails,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const [paginate, setPaginate] = useState(20);
  const { activity } = useSelector(({ activity }) => activity);
  const { taskDeleteStatus } = useSelector(({ task }) => task);
  const { details } = useSelector(({ leadPage }) => leadPage);
  const token = useMemo(() => auth?.token, [auth]);
  const { createStatus, booked } = useSelector(({ workShop }) => workShop);
  const [page, setPage] = useState("");
  const { bookedMember } = useSelector(({ memberShip }) => memberShip);
  const [userId, setUserId] = useState("");
  const [notify, setNotify] = useState(0);
  const [verticalType, setVerticalType] = useState("");
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  let incID = parseInt(id);
  const {
    getSources,
    getSourcesStatus,
    leadsStatusListStatus,
    leadsStatus,
  } = useSelector((state) => state.lead);
  const { priorityy } = useSelector((state) => state.allActivityReducer)
  const { isLoading, results } = useSelector(({ leadPage }) => leadPage);

  const permissions = useMemo(() => permission ?? [], [permission]);
  const { users, loading, mssg } = useSelector(({ crmUser }) => crmUser);

  const leadData = useMemo(() => results, [results]);
  const { user } = leadData;
  const { servicespurchased } = useSelector((state) => state.serviceReducer);

  useEffect(() => {
    dispatch(fetchLeadDetail(id, token));
    return () => {
      dispatch({ type: "LEAD_RESET_DATA" });
    };
  }, [auth, id, wsStatus, refresh]);
  useEffect(() => {
    dispatch(getPriority(token));
  }, [token]);
  const getServicesList = (id) => {
    Axios.get(`/api/crm/get-service-list?user_id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setPurchasedServices(res.data?.servicepurchased || []))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  const leadDetails = useMemo(() => details?.lead, [details]);

  useEffect(() => {
    if (leadDetails) {
      setPriority(leadDetails?.priority);
      setSource(leadDetails?.source_id);
      setLeadStatuss(leadDetails?.status_id);
      setCampaign(leadDetails?.compains);
      setInitialSector(leadDetails?.sector);
      setVerticalType(leadDetails?.vertical_type);
      setDisposition(leadDetails?.disposition)
    }
  }, [leadDetails]);

  useEffect(() => {
    if (details?.lead?.mobile) {
      // dispatch(getLeadUser(details?.lead?.mobile, auth?.token));
      setLeadIdd(details?.lead?.id);
    }
  }, [details]);

  useEffect(() => {
    if (details?.lead) {
      Axios.get(`/api/crm/get-lead/${details?.lead?.mobile}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
        .then(({ data }) => {
          if (Object.keys(data?.user).length) {
            setShowUser(true);
            dispatch(crmUserDetails(auth?.token, data?.user?.id));
            getServicesList(data?.user?.id);
            getCourseList(data?.user?.id);
            setUserId(data?.user?.id);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [auth.token, details?.lead?.mobile, refresh]);

  const getCourseList = (userId) => {
    Axios.get(`/api/crm/course-payment-report?sort=${sort}&user_id=${userId}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    })
      .then((res) => setCoursePayment(res?.data?.order?.data))
      .catch((err) => console.log(err));
  };
  // useEffect(() => {
  //   console.log(userId ,'detailsdetailsdetails')
  //   Axios.get(`/api/crm/course-payment-report?sort=${sort}&user_id=${userId}`, {
  //     headers: { Authorization: `Bearer ${auth.token}` },
  //   })
  //     .then((res) => setCoursePayment(res?.data?.order?.data))
  //     .catch((err) => console.log(err));
  // }, [auth.token]);

  useEffect(() => {
    Axios.get(
      `/api/crm/service-project-report?sort=${sort}&paginate=${paginate}&page=${page}`,
      {
        headers: { Authorization: `Bearer ${auth.token}` },
      }
    )
      .then((res) => setServiceProjectReport(res?.data?.order?.data))
      .catch((err) => console.log(err));
  }, [auth.token]);



  useEffect(() => {
    dispatch(getLeadStatusList(token));
  }, [token]);

  useEffect(() => {
    dispatch(getSourceList(token));
  }, [token]);

  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  useEffect(() => {
    if (leadsStatusListStatus) {
      setLeadStatusData(leadsStatus.leadstatus);
    }
  }, [leadsStatusListStatus]);
  useEffect(() => {
    axios
      .get(`/api/crm/get-disposition`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((res) => {
        setDispositionList(res.data?.dispositions);
      })
      .catch((err) => console.log(err));
  }, [auth?.token]);
  useEffect(() => {
    Axios.get(`/api/crm/fetch-lead-task/${id}?type=lead`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setTaskList(res.data?.task);
      })
      .catch((err) => console.log(err));
  }, [wsStatus, taskDeleteStatus]);

  const mobileNo = useMemo(() => {
    if (leadDetails) {
      if (leadDetails?.mobile) {
        return leadDetails?.mobile?.startsWith("+91" || "91+" || "91")
          ? leadDetails?.mobile
          : `91${leadDetails?.mobile}`;
      }
    }
  }, [leadDetails]);

  const { isCallAuthenticated, loadingg, error, telecall } =
    useSelector((state) => state.callReducer);

  useEffect(() => {
    if (telecall) {
      setIsModalOpen(false);
    }
  }, [telecall]);

  const handleUpdateName = () => {
    const data = new FormData();
    data.append("full_name", updateName);
    data.append("assign_to", auth.id);
    data.append("id", id);
    Axios.post("/api/crm/update-lead-name", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.status == 200 && dispatch(fetchLeadDetail(id, token)))
      .catch((err) => console.log(err));
    setIsEditing(false);
  };

  useEffect(() => {
    if (user && user?.lead_id === null && leadDetails) {
      setLeadId(leadDetails.id);
      Axios.get(
        `/api/crm/save-user-lead?user_id=${user?.id}&lead_id=${leadDetails?.id}`,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user, leadDetails]);

  useEffect(() => {
    const path = new URL(window.location.href);
    setPage(path.searchParams.get("page"));

    if (leadDetails && tab == "1") {
      dispatch(Activity(leadDetails?.id, token));
    }
  }, [leadDetails, tab]);

  const setType = (type) => {
    setActivityType(type);
  };

  useEffect(() => {
    if (leadDetails) {
      Axios.get(`/api/crm/get-lead-type-task?lead_id=${leadDetails?.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => setTask(res.data.data.getLeadtask))
        .catch((err) => console.log(err));
    }
  }, [leadDetails]);

  useEffect(() => {
    Axios.get(`/api/crm/getusertask?agent_id=${auth?.id}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
      .then(
        (res) =>
          res.data.getUserTask &&
          NotificationManager.success("You have new task")
      )
      .catch((err) => console.log(err));
  }, [auth?.token, notify]);

  const handleSubmit = ({
    label,
    activity_type,
    sourceType,
    leadstatus,
    priorityType,
    campaignType,
    industryType,
    verticalType,
    dispositionType
  }) => {
    if (activityType || activity_type) {

      const inputData = new FormData();
      inputData.append("id", leadDetails?.id);
      inputData.append("assign_to", leadDetails?.assign_to);
      inputData.append("lead_date", leadDetails?.lead_date);
      inputData.append("full_name", leadDetails?.full_name);
      inputData.append("first_name", leadDetails?.first_name);
      inputData.append("last_name", leadDetails?.last_name);
      inputData.append("mobile", leadDetails?.mobile);
      inputData.append("company", leadDetails?.company);
      inputData.append("email", leadDetails?.email);
      inputData.append("address", leadDetails?.address);
      inputData.append("city", leadDetails?.city);
      inputData.append("service_id", leadDetails?.service_id);
      inputData.append("remark", leadDetails?.remark);
      inputData.append("email_notify", leadDetails?.email_notify);
      inputData.append("sms_notify", leadDetails?.sms_notify);
      inputData.append("visibility", leadDetails?.visibility);
      inputData.append("source_id", sourceType || source);
      inputData.append(
        "source_type",
        leadDetails?.source_type ? leadDetails?.source_type : null
      );
      inputData.append("source_details", leadDetails?.source_details);
      inputData.append("status_id", leadstatus || leadStatuss);
      inputData.append("rating_id", leadDetails?.rating_id);
      inputData.append(
        "sector",
        JSON.stringify(leadDetails?.lead_sector) || subCategory
      );
      inputData.append(
        "industry",
        (industryType && JSON.stringify(industryType)) ||
        JSON.stringify(leadDetails?.lead_industry) ||
        subSubCategory
      );
      inputData.append("state", leadDetails?.state);
      inputData.append("country", leadDetails?.country);
      inputData.append("mobile", leadDetails?.mobile);
      inputData.append("disposition_type", leadDetails?.disposition_type);
      inputData.append("disposition", dispositionType || disposition);
      inputData.append("compains", campaignType || campaign);
      inputData.append("token", token);
      inputData.append("priority", priorityType || priority);
      inputData.append(
        "vertical_type",
        leadDetails?.verticalType || verticalType
      );
      dispatch(updateLeader(inputData, token));
      const data = {
        lead_id: leadIdd,
        activity_type: activity_type || activityType,
        description:
          label || description || priorityType || campaignType || verticalType,
      };
      dispatch(addActivity(data, token));
    }
  };

  useEffect(() => {
    if (task.length > 0) {
      setTimeout(() => {
        NotificationManager.info("You have new Task, Please Complete it Today");
      }, 3000);
    }
  }, [task]);

  useEffect(() => {
    if (activity) {

      SetActivityList(activity?.activity);

      if (activity?.activity?.length > 0) {
        setActivityLength(true);
      }
    }
  }, [activity]);

  useEffect(() => {
    Axios.get(
      `/api/crm/get-whatsapp?agent_id=${auth?.id}&mobile=${mobileNo}&leadid=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setWhatsAppSu(res?.data?.getSMS || []);
      })
      .catch((err) => console.log(err));

    Axios.get(`/api/crm/get-service-list?user_id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setPurchasedServices(res.data?.servicepurchased || []))
      .catch((err) => console.log(err));
  }, [mobileNo, auth, servicespurchased]);



  useEffect(() => {
    if (activity?.activity?.length > 0 && leadDetails) {
      axios
        .get(`/api/crm/lead-update-status?lead_id=${leadDetails?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [activity, leadDetails]);

  const getValues = ({
    label,
    activity_type,
    sourceType,
    leadstatus,
    priorityType,
    campaignType,
    industryType,
    verticalType,
    dispositionType
  }) => {
    handleSubmit({
      label,
      activity_type,
      sourceType,
      leadstatus,
      priorityType,
      campaignType,
      industryType,
      verticalType,
      dispositionType
    });
  };
  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${5}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(()=>
  // {
  //   console.log(sectors,"sectors");
  //   const newValue=sectors.find((item)=>item?.value===subCategory?.value);
  //   console.log(newValue,"newValue")

  // },[sectors])
  useEffect(() => {
    setSubCategory(leadDetails?.lead_sector?.name);
  }, [leadDetails]);

  const Other = { value: "other", label: "OTHER" };
  const data = sectors.concat(Other);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordata?industry=${e}`, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };

  let historys = useHistory();
  return (
    <>
      <MainWrapper>
        <main className="content">
          <a onClick={() => historys.goBack()}>
            <FiArrowLeft size={30} className="cursor-pointer" />
          </a>
          <div className="row">
            <div className="col-md-4">
              <div className="overflow-hidden rounded bg-white border">
                <div className="px-3 py-2 border-bottom bg-secondary">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span
                        className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mr-2"
                        style={{
                          fontSize: 25,
                          height: 50,
                          minWidth: 50,
                          width: 50,
                        }}
                      >
                        <FiUser />
                      </span>
                      <div>
                        {isEditing ? (
                          <Input
                            value={updateName}
                            onChange={(e) => setUpdateName(e.target.value)}
                          />
                        ) : (
                          <h4
                            className="font-weight-bold mb-0 text-capitalize"
                            style={{ fontSize: 16 }}
                          >
                            {_.get(leadDetails, "full_name", "N/A")}
                          </h4>
                        )}
                        {leadDetails?.full_name === "N/A" && (
                          <span className="ml-2">
                            {isEditing ? (
                              <FiCheckCircle onClick={handleUpdateName} />
                            ) : (
                              <FiEdit onClick={() => setIsEditing(true)} />
                            )}
                          </span>
                        )}
                        <span className="d-block" style={{ fontSize: 12 }}>
                          {_.get(leadDetails?.updated_at, "name", "N/A")}
                        </span>
                      </div>
                    </div>

                    {permissions?.find((ps) => ps.name === "agent-lead-edit") !=
                      null ? (
                      <Link
                        className="text-decoration-none btn btn-secondary btn-sm text-primary"
                        to={`/leads/edit/${leadDetails?.id}`}
                      >
                        <i className="fa fa-pencil" /> Edit
                      </Link>
                    ) : null}
                  </div>
                </div>



                <UserDetail leadDetails={leadDetails} showuser={showuser} users={users} refresh={refresh} />

                <LeadDetails leadDetails={leadDetails} />
                <div className="px-3 py-1 bg-secondary">
                  <span className="font-weight-bold">Address Details</span>
                </div>
                <div className="px-3 py-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        City
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {" "}
                          {_.get(leadDetails, "city", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        State
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "state", "N/A")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Address
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "address", "N/A")}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="font-weight-bold d-inline-block label-tag">
                        Country
                      </div>
                      <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                        :
                      </div>
                      <div className="d-inline-block data-tag">
                        <span id="l_Code">
                          {_.get(leadDetails, "country", "N/A")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3 py-1 bg-secondary">
                  <span className="font-weight-bold">General Notes</span>
                </div>
                <div className="px-3 py-2">
                  <div className="remark-box bg-secondary p-2">
                    {_.get(leadDetails, "remark", "N/A")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom d-flex align-items-center bg-secondary">
                  <span
                    onClick={() => setTab("1")}
                    style={{ fontSize: 15, cursor: "pointer", fontWeight: 500 }}
                    className={`d-flex px-3 py-2 align-items-center border-bottom ${tab == "1" ? "border-primary text-primary" : ""
                      }`}
                  >
                    <FiFileText className="mr-2" />
                    Activities
                  </span>
                  <span
                    onClick={() => setTab("2")}
                    style={{ fontSize: 15, cursor: "pointer", fontWeight: 500 }}
                    className={`d-flex px-3 py-2 align-items-center border-bottom ${tab == "2" ? "border-primary text-primary" : ""
                      }`}
                  >
                    <FiEdit className="mr-2" />
                    Notes
                  </span>

                  {showuser && (
                    <span
                      onClick={() => setTab("3")}
                      style={{
                        fontSize: 15,
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                      className={`d-flex px-3 py-2 align-items-center border-bottom ${tab === "3" ? "border-primary text-primary" : ""
                        }`}
                      role="button"
                    >
                      <FiEdit className="mr-2" />
                      User Report
                    </span>
                  )}
                  <span
                    onClick={() => setTab("4")}
                    style={{
                      fontSize: 15,
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    className={`d-flex px-3 py-2 align-items-center border-bottom ${tab === "4" ? "border-primary text-primary" : ""
                      }`}
                    role="button"
                  >
                    <FaWhatsapp className="mr-2" />
                    Whatsapp Activity
                  </span>

                  <span
                    onClick={() => setTab("5")}
                    style={{
                      fontSize: 15,
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    className={`d-flex px-3 py-2 align-items-center border-bottom ${tab === "5" ? "border-primary text-primary" : ""
                      }`}
                    role="button"
                  >
                    <BiTask className="mr-2" />
                    Task
                  </span>
                </div>
                <div className="p-3" style={{ minHeight: 280 }}>
                  <div className={tab == "1" ? "d-block" : "d-none"}>
                    {!activityLength ? <h4>No Activities to Show</h4> : null}

                    <div>
                      <div className="d-flex pb-3 align-items-center">
                        <div
                          style={{ minWidth: 80, maxWidth: 80 }}
                          className="font-weight-bold text-primary"
                        >
                          July 2023
                        </div>{" "}
                        <hr className="m-0 border-top w-100" />
                      </div>

                      {activityList?.map((active) => {
                        const time = `${new Date(
                          active?.created_at
                        ).getHours()}:${new Date(
                          active?.created_at
                        ).getMinutes()}`;
                        const formattedTime = moment(time, ["HH.mm"]).format(
                          "hh:mm a"
                        );
                        return (
                          <div className="d-flex align-items-center mb-3">
                            <div
                              style={{ minWidth: 150, maxWidth: 150 }}
                              className="d-flex align-items-center"
                            >
                              <span
                                className="rounded-circle bg-dark text-white d-flex align-items-center justify-content-center mr-3"
                                style={{
                                  fontSize: 18,
                                  height: 40,
                                  minWidth: 40,
                                  width: 40,
                                }}
                              >
                                <FaNotesMedical />
                              </span>
                              <span style={{ fontSize: 12 }}>
                                {moment(active?.created_at).format("DD MMMM")}
                                <br />
                                {formattedTime}
                              </span>
                            </div>
                            <div>
                              <span className="">
                                <p className="mb-0 text-primary">
                                  {" "}
                                  {active?.activity_type}{" "}
                                </p>
                                <p> {active?.description}</p>
                              </span>
                              <span
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                <em>Activity Done By: {active?.agent?.name}</em>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={tab == "2" ? "d-block" : "d-none"}>
                    {permissions?.find((ps) => ps.name === "note-list") !=
                      null ? (
                      <Note
                        leadId={id}
                        permissions={permissions}
                        userid={userId}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={tab == "3" ? "d-block" : "d-none"}>
                    <List leadDetails={users} setWsStatus={setWsStatus} />

                    <ProjectReport
                      leadDetails={users}
                      setWsStatus={setWsStatus}
                    />

                    <CourseList
                      leadDetails={coursePayment}
                      setWsStatus={setWsStatus}
                    />
                    <IndustrialReport
                      leadDetails={users}
                      setWsStatus={setWsStatus}
                    />

                    <CustomizeProjectReport
                      leadDetails={users}
                      setWsStatus={setWsStatus}
                    />
                    <ServiceSectorProjectReport
                      leadDetails={serviceProjectReport}
                      setWsStatus={setWsStatus}
                    />
                    <MembershipServicesList
                      leadDetails={users}
                      setWsStatus={setWsStatus}
                    />

                    <div className="accordion" id="ActivityAccordion">
                      <div className="card">
                        <div className="card-header p-0" id="heading-6">
                          <Link
                            className="card-title m-0 h5 text-white d-block p-2"
                            to="#"
                            data-toggle="collapse"
                            data-target="#collapse-2"
                            aria-expanded="false"
                            aria-controls="collapse-2"
                          >
                            Services List ({purchasedServices?.length || 0})
                          </Link>
                        </div>
                        <div
                          id="collapse-2"
                          className="collapse "
                          aria-labelledby="heading-6"
                          data-parent="#ActivityAccordion"
                        >
                          <div className="card-body p-2">
                            <div className="table-responsive">
                              <table className="table table-sm m-0">
                                <thead className="bg-dark text-white">
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Title</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {purchasedServices
                                    ? purchasedServices.map((item, i) => (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          {item?.service?.name || item?.title}
                                        </td>
                                        <td>{item?.service?.amount}</td>
                                      </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion" id="ActivityAccordion">
                      <div className="card">
                        <div className="card-header p-0" id="heading-6">
                          <Link
                            className="card-title m-0 h5 text-white d-block p-2"
                            to="#"
                            data-toggle="collapse"
                            data-target="#collapse-3"
                            aria-expanded="false"
                            aria-controls="collapse-3"
                          >
                            Courses List ({coursePayment?.length || 0})
                          </Link>
                        </div>
                        <div
                          id="collapse-3"
                          className="collapse "
                          aria-labelledby="heading-6"
                          data-parent="#ActivityAccordion1"
                        >
                          <div className="card-body p-2">
                            <div className="table-responsive">
                              <table className="table table-sm m-0">
                                <thead className="bg-dark text-white">
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>	Transaction-Id</th>
                                    <th>	Amount</th>
                                    <th>	Mode</th>
                                    <th>	Purchased Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {coursePayment
                                    ? coursePayment.map((item, i) => (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          {item?.details?.project_name
                                          }
                                        </td>
                                        <td>{item?.paymentsdd?.transaction_id

                                        }</td>
                                      </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className={tab == "4" ? "d-block" : "d-none"}>
                    <WhatsAppSummary whatsAppSu={whatsAppSu} />
                  </div>

                  <div className={tab == "5" ? "d-block" : "d-none"}>
                    <TaskList taskList={task} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="overflow-hidden rounded bg-white border mb-3">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Quick Actions
                  </span>
                </div>
                <div className="p-2">
                  {permissions?.find(
                    (ps) => ps.name === "crm-whatsapp-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <SendSmsSale
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {permissions?.find(
                    (ps) => ps.name === "crm-whatsapp-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <SendSms
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {!isCallAuthenticated ? (
                    <div className="col-md-12 mb-1">
                      <LoginToCall
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                      />
                    </div>
                  ) : null} */}
                  <div className="col-md-12 mb-1">
                    <SendFeedback
                      leadDetail={leadDetails}
                      setWsStatus={setWsStatus}
                    />
                  </div>
                  {isCallAuthenticated ? (
                    <div className="col-md-12 mb-1">
                      <NewCall
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                      />
                    </div>
                  ) : null}
                  <div className="col-md-12 mb-1">
                    <AddCourses
                      leadDetail={leadDetails}
                      setWsStatus={setWsStatus}
                      setRefresh={setRefresh}
                    />
                  </div>

                  {permissions?.find(
                    (ps) => ps.name === "crm-workshop-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddWorkshop
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        showuser={showuser}
                        userId={userId}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!users?.plan_detail_all &&
                    permissions?.find(
                      (ps) => ps.name === "crm-membership-create"
                    ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddMembership
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        userId={userId}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {permissions?.find((ps) => ps.name === "task-create") !=
                    null ? (
                    <div className="col-md-12 mb-1">
                      <Task
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        type="lead"

                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {permissions?.find(
                    (ps) => ps.name === "crm-projectreport-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddProjectReport
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        userId={userId}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {permissions?.find(
                    (ps) => ps.name === "crm-projectreport-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddServicesLead
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        userId={userId}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {permissions?.find(
                    (ps) => ps.name === "crm-industrial-report-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddIndustrialReport
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                        userId={userId}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {permissions?.find(
                    (ps) => ps.name === "crm-customize-create"
                  ) != null ? (
                    <div className="col-md-12 mb-1">
                      <AddCustomizeProjectReport
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {permissions?.find((ps) => ps.name === "crm-edp-create") !=
                    null ? (
                    <div className="col-md-12 mb-1">
                      <AddEdpLead
                        leadDetail={leadDetails}
                        setWsStatus={setWsStatus}
                        setRefresh={setRefresh}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12">
                    <LeadClosed setRefresh={setRefresh} />
                  </div>
                </div>
              </div>
              <div className="overflow-hidden rounded bg-white border mb-3">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Lead Asignment
                  </span>
                </div>
                <div className="px-4 py-3">
                  {leadDetails?.assign_lead_history?.map((assignlead) => {
                    return (
                      <div
                        className="border-left d-flex align-items-start"
                        style={{ height: 30 }}
                      >
                        <span
                          className="rounded-circle bg-primary position-relative"
                          style={{ height: 10, width: 10, left: "-5px" }}
                        ></span>{" "}
                        <span className="ml-1 small" style={{ lineHeight: 1 }}>
                          {assignlead?.crm_user_tbl?.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Disposition
                  </span>
                </div>

                <Input
                  type="select"
                  className="custom-select cursor-pointer"
                  value={disposition}
                  onChange={(e) => {
                    setDisposition(e.target.value);
                    setActivityType("Disposition Updated");
                    getValues({
                      dispositionType: e.target.value,
                      activity_type: "Disposition Updated",
                    });
                    setNotify((prev) => prev + 1);
                  }}
                >
                  <option value="">select</option>
                  {dispositionList?.map((item) => {
                    return (
                      <option value={item?.remarks} key={item?.id}>{item?.remarks}</option>
                    )
                  })}
                </Input>
              </div> */}


              {/* <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Priority(Interested)
                  </span>
                </div>
                <div className="p-2">
                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    value={priority}
                    onChange={(e) => {
                      setPriority(e.target.value);
                      setActivityType("Priority Updated");
                      getValues({
                        priorityType: e.target.value,
                        activity_type: "Priority Updated",
                      });
                      setNotify((prev) => prev + 1);
                    }}
                  >
                    <option value="">select</option>
                    {priorityy?.map((item) => {
                      return (
                        <option value={item?.title} key={item?.id}>{item?.title}</option>
                      )
                    })}
                  </Input>
                </div>
              </div> */}

              <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>Source</span>
                </div>
                <div className="p-2">
                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value);
                      setActivityType("Source Updated");
                      const label = sources.find(
                        (item) => item.value === parseInt(e.target.value)
                      )?.label;
                      getValues({
                        label: label,
                        activity_type: "Source Updated",
                        sourceType: e.target.value,
                      });
                      setNotify((prev) => prev + 1);
                    }}
                  >
                    <option value="">Select</option>
                    {sources
                      ? sources?.map((sou, i) => (
                        <option value={sou?.value} key={i}>
                          {sou?.label}
                        </option>
                      ))
                      : null}
                  </Input>
                </div>
              </div>

              {/* <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Lead Status
                  </span>
                </div>
                <div className="p-2">
                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    value={leadStatuss}
                    onChange={(e) => {
                      setLeadStatuss(e.target.value);
                      setActivityType("Leadstatus Updated");
                      const label = leadStatusData.find(
                        (item) => item.value === parseInt(e.target.value)
                      )?.label;
                      getValues({
                        label: label,
                        activity_type: "Leadstatus Updated",
                        leadstatus: e.target.value,
                      });
                      setNotify((prev) => prev + 1);
                    }}
                  >
                    <option value="">Select</option>
                    {leadStatusData
                      ? leadStatusData?.map((cr) => (
                        <option value={cr?.value} key={cr.value}>
                          {cr?.label}
                        </option>
                      ))
                      : null}
                  </Input>
                </div>
              </div> */}

              <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Campaign
                  </span>
                </div>
                <div className="p-2">
                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    value={campaign}
                    onChange={(e) => {
                      setCampaign(e.target.value);
                      setActivityType("Campaign Updated");
                      getValues({
                        campaignType: e.target.value,
                        activity_type: "Campaign Updated",
                      });
                      setNotify((prev) => prev + 1);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="workshop">Workshop</option>
                    <option value="IID Sale">IID-Sale</option>
                  </Input>
                </div>
              </div>
              <div className="overflow-hidden rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Vertical
                  </span>
                </div>
                <Input
                  // type="select"
                  // className="custom-select"
                  // value={verticalType}
                  // onChange={(e) => setVerticalType(e.target.value)}

                  type="select"
                  className="custom-select cursor-pointer"
                  value={verticalType}
                  onChange={(e) => {
                    setVerticalType(e.target.value);
                    setActivityType("Vertical Updated");
                    getValues({
                      verticalType: e.target.value,
                      activity_type: "Vertical Updated",
                    });
                    setNotify((prev) => prev + 1);
                  }}
                >
                  <option value="">select</option>
                  <option value="Membership">Membership</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Project_Report">Project Report</option>
                  <option value="Industrial_Report">Industrial Report</option>
                  <option value="Courses">Courses</option>
                  <option value="Franchise">Franchise</option>
                  <option value="Startup">Startup</option>
                  <option value="Edp">Edp</option>
                </Input>
              </div>
              {/* <div className="col-lg-12">
                                  <Select
                                    options={[...sectors]}
                                    onChange={handleSector}
                                    value={subCategory}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                  />
                                </div> */}
              {/* <div className=" rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Sector
                  </span>
                </div>
                <div className="p-2">
                 
                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    onChange={handleSector}
                    value={subCategory}
                  >
                    <option value="">Select</option>
                    {data?.map((item) => {
                      return (
                        <><option value={item?.value} key={item?.value}>{item?.label}</option>
                        </>

                      )
                    })}

                  </Input>

                </div>
              </div> */}

              {/* <div className=" rounded bg-white border">
                <div className="border-bottom px-3 py-2 d-flex align-items-center bg-secondary">
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    Industry
                  </span>
                </div>
                <div className="p-2">

                  <Input
                    type="select"
                    className="custom-select cursor-pointer"
                    onChange={(e) => {
                      setSubSubCategory(e.target.value)
                      const findItem = industry.find((item) => item.value == e.target.value)
                      getValues({ industryType: { label: findItem?.label, id: findItem.value, value: findItem.value }, activity_type: "industry" })
                    }}
                    value={subSubCategory}
                  >
                    <option value="">Select</option>
                    {industry?.map((item) => {
                      return (
                        <><option value={item?.value}>{item?.label}</option>
                        </>

                      )
                    })}

                  </Input>

                </div>
              </div> */}
            </div>
          </div>
        </main>
      </MainWrapper>
    </>
  );
};

export default Details;
