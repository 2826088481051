import _ from "lodash";

const initialState = {
    loading: false,
    details: [],
    status: false,
    assignPermission: [],
    assignPermissionStatus: false,
    assignPermissionsLoading: false,
    error: {},
};

const assignPermissionReducer = (state = initialState, action) => {
    const { payload } = action;
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "ROLES_LIST_PENDING":
            state = {
                ...state,
                loading: true,
                status: false,
                error: {},
            };
            break;
        case "ROLES_LIST_FULFILLED":
            state = {
                ...state,
                loading: false,
                status: true,
                details: _.get(payload, "data", {}),
            };
            break;
        case "ROLES_LIST_REJECTED":
            state = {
                ...state,
                loading: false,
                status: false,
                error: _.get(payload.response.data, "message", {}),
            };
            break;
        case "ASSIGN_PERMISSIONS_PENDING":
            state = {
                ...state,
                assignPermissionsLoading: true,
                assignPermissionStatus: false,
                error: {},
            };
            break;
        case "ASSIGN_PERMISSIONS_FULFILLED":
            state = {
                ...state,
                assignPermissionsLoading: false,
                assignPermissionStatus: true,
                assignPermission: _.get(payload, "data", {}),
            };
            break;
        case "ASSIGN_PERMISSIONS_REJECTED":
            const {
                payload: {
                    response: { status, data },
                },
            } = action;
            if (status === 422) {
                state = {
                    ...state,
                    assignPermissionsLoading: false,
                    assignPermissionStatus: false,
                    error: _.get(data, "errors", {}),
                };
            } else {
                state = {
                    ...state,
                    assignPermissionsLoading: false,
                    assignPermissionStatus: false,
                    error: _.get(data, "message", {}),
                };
            }
            break;
    }
    return state;
};

export default assignPermissionReducer;
