/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState, lazy, Suspense, useRef } from "react";
import MainWrapper from "./MainWrapper";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
import {
  ConsultantUserCount,
} from "../store/actions/DashboardActions";
import AssociateLeadStatus from "./superAdmin/AssociateLeadStatus";
import FranchiseInvestor from "./superAdmin/FranchiseInvestor";
import TataPlay from "./superAdmin/TataPlay";
import Sales from "./superAdmin/SalesLeadStatus";
import ConsultancyStats from "./superAdmin/ConsultancyStats";
// const AssociateLeadStatus = React.lazy(() => import('./superAdmin/AssociateLeadStatus'));
// const FranchiseInvestor = React.lazy(() => import('./superAdmin/FranchiseInvestor'));
const SuperAdmin = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [associate, setAssociate] = useState([]);
  const [toggle, setToggle] = useState("");
  const [id, setId] = useState("");
  const [associateteam, setAssociateTeam] = useState("");
  const {
    leaddetails,
    dashboardcount,
    dashboardconsultant,
    dashboarduserconsultant,
  } = useSelector((state) => state.dashboardReducer);
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [date, setDate] = useState("");
  const [fdate, setFdate] = useState("");


  const [step, setStep] = useState(0);
  // const [saleShowMore, setSaleShowMore] = useState(false);
  // const [saleTeamShowMore, setSaleTeamShowMore] = useState(false);

  const handleSaleViewMoreClick = () => {
    setStep((prevStep) => prevStep + 1);
    if (step === 3) {
      setStep(0);
    }
  };
  useEffect(() => {
    dispatch(
      ConsultantUserCount(token, {
        consultantId: toggle,
        date: date,
        fdate: fdate,
      })
    );
  }, [token, toggle, fdate]);
  useEffect(() => {
    SetLead(leaddetails?.convertedlead?.data);
  }, [leaddetails, dashboardcount, dashboardconsultant]);
  useEffect(() => {
    if (id) {
      Axios.get(`/api/crm/get-franchise-count?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => setAssociateTeam(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    Axios.get(`/api/crm/get-associate-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociate(res.data.franchise))
      .catch((err) => console.log(err));
  }, [auth.token]);





  useEffect(() => {
    SetLead(leaddetails.convertedlead);

  }, [leaddetails, dashboardcount]);


  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <AssociateLeadStatus />
            <FranchiseInvestor />
            <TataPlay />
            <Sales />
            <ConsultancyStats />
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default SuperAdmin;
