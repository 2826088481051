import React, { useState, useEffect, memo } from "react";
import { NotificationManager } from "react-notifications";
import {
  createNoteApi,
  getListUserNote,
  updateNoteApi,
  NoteDelete,
} from "../../store/actions/NoteActions";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import Axios from "axios";
import _ from "lodash";

const Note = memo(({ id, permissions, leadDetails }) => {
  const [activeTitle, setActiveTitle] = useState(false); // title input swith state
  const [title, setTitle] = useState(""); // Note title state
  const [note, setNote] = useState(""); // Note State
  const [editSwich, setEditSwich] = useState(false);
  const [editNoteId, setEditNoteId] = useState("");
  const [noteListData, setNoteListData] = useState();
  const [leadNote, setLeadNote] = useState([]);
  const { auth } = useSelector(({ auth }) => auth);
  const {
    createNoteData,
    createNoteStatus,
    Note,
    listStatus,
    updateNoteStatus,
  } = useSelector(({ note }) => note);

  const dispatch = useDispatch();
  //handle title input show and hide
  const handleTitleSwich = (e) => {
    e.preventDefault();
    setActiveTitle(!activeTitle);
  };
  // handle note fields reset
  const handleCancelNote = (e) => {
    e.preventDefault();
    setActiveTitle(false);
    setTitle("");
    setNote("");
  };
  //Insert Note method
  const handleNoteSubmit = (e) => {
    e.preventDefault();
    const data = { id, title, note, type: "user", engaged: 1 };
    dispatch(createNoteApi(data, auth.token));
    setTimeout(() => {
      dispatch(getListUserNote(id, auth.token));
      NotificationManager.success("Note added successfully");
      setNote("");
      setTitle("");
    }, 1000);
  };

  useEffect(() => {
    if (createNoteStatus) {
      NotificationManager.success("Note Created Successfully", "Sccess");
      setTitle("");
      setNote("");
      // setNoteListData([...noteListData, createNoteData.employeeNote]);
    }
  }, [createNoteStatus]);
  useEffect(() => {
    if (listStatus) {
      setNoteListData(Note.note);
    }
  }, [listStatus, auth.token]);

  // get list of employee note
  useEffect(() => {
    dispatch(getListUserNote(id, auth.token));
  }, [auth.token]);
  // handle Employee Edition Action
  const handleEdit = (e, id) => {
    e.preventDefault();
    let editNote = Note.note.find((nt) => nt.id === id);
    setEditNoteId(id);
    setTitle(editNote?.title);
    setNote(editNote?.note);
    if (editNote?.title) {
      setActiveTitle(true);
    }
    setEditSwich(!editSwich);
  };
  useEffect(() => {
    if (updateNoteStatus) {
      NotificationManager.success("Note Updated Successfully", "Sccess");
      dispatch(getListUserNote(id, auth.token));
    }
  }, [updateNoteStatus]);

  // handle Employee Delete Action
  const handleDelete = (e, deleteId) => {
    e.preventDefault();
    dispatch(NoteDelete({ ids: JSON.stringify(deleteId) }, auth.token));
    setTimeout(() => {
      dispatch(getListUserNote(id, auth.token));
      NotificationManager.success("Note Deleted Successfully", "Sccess");
    }, 1000);
  };

  // handle Note Update
  const handleUpdateEmployeeNote = (e) => {
    e.preventDefault();
    const updateData = { editNoteId, id, title, note };
    setEditSwich(false);
    setEditNoteId("");
    setEditNoteId("");
    setTitle("");
    setNote("");
    setActiveTitle(false);
    dispatch(updateNoteApi(updateData, auth.token));
  };
  console.log(auth, "auth");

  //getnote
  useEffect(() => {
    Axios.get(`/api/crm/get-lead-by-mobile?mobile=${leadDetails?.mobile}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setLeadNote(res.data.leadDetails.lead_note))
      .catch((error) => console.log(error));
  }, [auth.token]);
  console.log("noteee", leadNote);
  return (
    <>
      <div className="note-area my-3">
        <div className="row ">
          <div className="col-md-8 col-sm-12 ">
            {_.map(noteListData, (lt, i) => (
              <div className="note-data mb-2" key={i}>
                {
                  lt?.title &&
                  <div className="d-flex align-items-center ">
                    <p className=" mb-0 fw-bold">{lt?.title}</p>
                  </div>
                }
                <p className="mb-1">{lt.note} </p>
                <div className="note-bottom small d-flex justify-content-between">
                  <div className="note-data-section">
                    <span>{lt?.created_user?.name}</span>
                    &nbsp;&nbsp;
                    <span className="text-muted">{lt.created_at}</span>
                  </div>
                  <div className="right-links pl-2">
                    {permissions?.find((ps) => ps.name === "note-edit") !=
                      null ? (
                      <Link
                        id="EditNote"
                        className="pr-1  text-info "
                        to={``}
                        onClick={(e) => handleEdit(e, lt.id)}
                      >
                        <FiEdit />
                      </Link>
                    ) : null}

                    {permissions?.find((ps) => ps.name === "note-delete") !=
                      null ? (
                      <Link
                        id="RemoveNote"
                        className="pr-1 text-danger"
                        to={`#`}
                        onClick={(e) => handleDelete(e, lt.id)}
                      >
                        <FiTrash />
                      </Link>
                    ) : null}
                    {/* <Link
                        id="RemoveNote"
                        className="pr-1"
                        to={`#`}
                        onClick={(e) => handleDelete(e, lt.id)}
                      >
                        Remove
                      </Link>
                      <Link
                        id="EditNote"
                        to={`#`}
                        onClick={(e) => handleEdit(e, lt.id)}
                      >
                        Edit
                      </Link> */}
                  </div>
                </div>
              </div>
            ))}
            <div className="bg-light lter p-2">
              <div className="form-group mb-2 d-flex align-items-center justify-content-between">
                <h5 className="inline mt-0">Note for this employee</h5>
                <Link
                  id="AddTitle"
                  to={`#`}
                  className="pull-right"
                  onClick={handleTitleSwich}
                >
                  Add a Title
                </Link>
              </div>

              <div
                className={`form-group ${activeTitle ? "" : "addTitleInput"}`}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add a Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Add a Note"
                  style={{ height: 100, resize: "none" }}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
              </div>
              <div className="form-group text-right">
                {editSwich ? (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm"
                    onClick={handleUpdateEmployeeNote}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-secondary btn-sm mr-1"
                    onClick={handleNoteSubmit}
                  >
                    Save
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={handleCancelNote}
                >
                  Cancel
                </button>
              </div>
              {_.map(leadNote, (lt, i) => (
                <div className="note-data mb-2" key={i}>
                  <p className="mb-1">{lt.note} </p>
                  <div className="note-bottom small d-flex justify-content-between">
                    <div className="note-data-section">
                      <span>{auth ? auth.name : null}</span>
                      &nbsp;&nbsp;
                      <span className="text-muted">{lt.created_at}</span>
                    </div>
                    {/* <div className="right-links pl-2">
                      {permissions?.find((ps) => ps.name === "note-edit") !=
                      null ? (
                        <Link
                          id="EditNote"
                          className="pr-1  text-info "
                          to={``}
                          onClick={(e) => handleEdit(e, lt.id)}
                        >
                          <FiEdit />
                        </Link>
                      ) : null}

                      {permissions?.find((ps) => ps.name === "note-delete") !=
                      null ? (
                        <Link
                          id="RemoveNote"
                          className="pr-1 text-danger"
                          to={`#`}
                          onClick={(e) => handleDelete(e, lt.id)}
                        >
                          <FiTrash />
                        </Link>
                      ) : null}
                    </div> */}
                  </div>
                </div>
              ))}
              <div>
                <hr />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Note;
