import React, { useState, useEffect } from 'react'
import {
    Input,
} from "reactstrap";
import {
    FaUser,
    FaUserCheck,
    FaPhoneAlt,
    FaSadCry,
} from "react-icons/fa";
import moment from "moment";
import Axios from "axios";
import { BiDetail } from "react-icons/bi";
import { MdCallEnd } from "react-icons/md";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import CollapseCard from "../../components/dashboard-accordian/CollapseCard";
import { ImHappy2 } from "react-icons/im";
import Accordian from "../../components/dashboard-accordian/Accordian";
import useScrollTrigger from './useScrollTrigger';
import { FaUserTie, FaUsers, FaSchool } from "react-icons/fa";
import { RiUserStarFill } from "react-icons/ri";
import { ImUserCheck } from "react-icons/im";
import { HiLightBulb } from "react-icons/hi";
import {
    ConsultantDashboardCount,
    ConsultantUserCount,
} from "../../store/actions/DashboardActions";
import {
    FiUser,
    FiActivity,
    FiUserCheck,
    FiArchive,
    FiRepeat,
    FiPhoneCall,
} from "react-icons/fi";
const ConsultancyStats = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const { auth } = useSelector((state) => state.auth);
    const [isInview, setIsinview] = useState(false);
    const [reportings, setReportings] = useState({});
    const {
        leaddetails,
        dashboardcount,
        dashboardconsultant,
        dashboarduserconsultant,
    } = useSelector((state) => state.dashboardReducer);
    useEffect(() => {
        dispatch(ConsultantDashboardCount(auth?.token, auth?.id, startDate, endDate));
    }, [auth, startDate, endDate]);
    const targetRef = useScrollTrigger((entries) => {

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Element is now in view
                setIsinview(true);
            }
        });
    });
    useEffect(() => {
        if (isInview) fetchData();
    }, [isInview]);
    const fetchData = () => {
        dispatch(ConsultantDashboardCount(auth?.token, auth?.id, startDate, endDate));
    };
    return (
        <div ref={targetRef}>
            <div className="container-fluid p-0">
                <h2> Consultancy Stats </h2>

                <div className="col-md-12">
                    <div className="filter-container">
                        <div className="row">
                            <div class="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="form-label  mb-2">
                                        Start Date
                                    </label>
                                    <Input
                                        type="date"
                                        value={startDate}
                                        onChange={({ target: { value } }) =>
                                            setStartDate(value)
                                        }
                                        style={{ borderRadius: "5px" }}
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="form-label mb-2">
                                        End Date
                                    </label>
                                    <Input
                                        type="date"
                                        value={endDate}
                                        onChange={({ target: { value } }) => setEndDate(value)}
                                        style={{ borderRadius: "5px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {auth?.id == "74" ? (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row align-items-center">
                                                    <div className="col-4">
                                                        <div className="square-box dark-orangebg">
                                                            <FaUser color="#fa5a7e" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className=" font-commom mb-0">
                                                            {dashboardconsultant?.today}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Today Members
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row align-items-center">
                                                    <div className="col-4">
                                                        <div className="square-box mt-0">
                                                            <FaUsers color="#FF947A" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className="font-commom mb-0">
                                                            {dashboardconsultant?.total}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Total Members
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="square-box dark-orangebg mt-0">
                                                            <FaUserTie color="#3CD856" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className="font-commom mb-0">
                                                            {dashboardconsultant?.professionalExpert}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Professional Members
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="d-inline-block mt-2">
                                                            <RiUserStarFill color="#BF83FF" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className="font-commom mb-0">
                                                            {dashboardconsultant?.supplier}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Supplier Member
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="d-inline-block mt-2">
                                                            <ImUserCheck size={30} color="#FFCF00" />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className=" font-commom mb-0">
                                                            {dashboardconsultant?.industry}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Industry Expert
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="d-inline-block mt-2">
                                                            <HiLightBulb color="#0094FE" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className=" font-commom mb-0">
                                                            {dashboardconsultant?.enterpreneur}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Entrepreneur
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                                        <div className="card flex-fill round-border common-card">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="d-inline-block mt-2">
                                                            <FaSchool color="#F64D60" size={30} />
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <h3 className=" font-commom mb-0">
                                                            {dashboardconsultant?.institutional}
                                                        </h3>
                                                        <div className="mb-0 font-commom-2">
                                                            Institutional
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.today}
                                                        </h3>
                                                        <div className="mb-0">Today Members</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.total}
                                                        </h3>
                                                        <div className="mb-0">Total Members</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.professionalExpert}
                                                        </h3>
                                                        <div className="mb-0">Professional Members</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.supplier}
                                                        </h3>
                                                        <div className="mb-0">Supplier Member</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.industry}
                                                        </h3>
                                                        <div className="mb-0">Industry Expert</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.enterpreneur}
                                                        </h3>
                                                        <div className="mb-0">Entrepreneur</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                                        <div className="card flex-fill">
                                            <div className="card-body py-4">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <h3 className="mb-2">
                                                            {dashboardconsultant?.institutional}
                                                        </h3>
                                                        <div className="mb-0">Institutional</div>
                                                    </div>
                                                    <div className="col-4 ml-auto text-right">
                                                        <div className="d-inline-block mt-2">
                                                            <FiUser className="feather-lg text-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-md-12 cursor-pointer">
                            <div className="row">
                                {reportings?.consultantLeadList?.map((item) => {
                                    return (
                                        <div className="col-md-3" key={item.id}>
                                            <CollapseCard
                                                name={item.name}
                                                id={item.id}
                                                moduleType="consultancy"
                                                showFilters={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div></div>
                </div>
            </div>
        </div>

    )
}

export default ConsultancyStats