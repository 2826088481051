import Axios from "axios";

export const bookServices = (data, token) =>async(dispatch) => {
  // return {
  //   type: "BOOK_SERVICES",
  //   payload: Axios.post(`/api/crm/add-services-crm`, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }).catch((err)=>err),
  // };
  try {
    const resp = await Axios.post(`/api/crm/add-services-crm`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: "BOOK_SERVICES_FULFILLED",
      payload: { message: "Report Added Successfully" },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "BOOK_SERVICES_REJECTED",
      payload: error,
    });
  }
};
