import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "./MainWrapper";
import { Table, Spinner, FormGroup, Label } from "reactstrap";
import Loader from "../layouts/Loader";
import _ from "lodash";
import Axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getCrmUsersApi } from "../store/actions/LeadActions";
import Pagination from "react-js-pagination";
import { Input, CustomInput } from "reactstrap";
const TaskReport = () => {
    const { auth } = useSelector(({ auth }) => auth);
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [paginate, setPaginate] = useState(25);
    const [crm, setCrm] = useState([]);
    const [user, setUser] = useState("");
    const [perPage, setPerPage] = useState(0);
    const dispatch = useDispatch();
    const { leadData } = useSelector(({ lead }) => lead);

    useEffect(() => {
        dispatch(getCrmUsersApi(auth?.token));

    }, [auth]);

    useEffect(() => {
        setLoading(true);
        Axios.get(
            `/api/crm/get-task-list?agent_id=${user}&page=${page}&paginate=${paginate}`,
            {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            }
        )
            .then((res) => {
                setReport(
                    res.data
                    ,
                    "check"
                );
                setPerPage(res.data.getTask ? res.data.getTask.per_page : null);

                setLoading(false);
            })
            .catch((err) => console.log(err));
    }, [auth, user, page, perPage, paginate]);

    console.log(report?.getTask?.data, "report?.getTask?.data")
    return (
        <Fragment>
            <MainWrapper>
                <div className="container">
                    <div className=" mt-5">
                        <div className="row mb-4">
                            <div className="col-md-3">
                                <h3 className="mb-0">Task Report</h3>
                            </div>
                            <div className="col-md-4">

                            </div>
                            <div className="col-md-5">
                                <div className="criteria-search-box">
                                    <select className="form-control custome-dropdown-menu-adv radius-3" onChange={(e) => setUser(e.target.value)}>
                                        <option value="">Enter Agent Name</option>
                                        {leadData?.users?.map((item) => {
                                            return (
                                                <option value={item?.value} key={item?.value}>{item?.label}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>

                        </div>
                        <Table bordered>
                            <thead>
                                <tr className="table-primary">
                                    <th>S.No</th>
                                    <th>Subject</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>

                                {loading ? (
                                    <Fragment>
                                        <tr>
                                            <td colSpan={7}>
                                                <Loader loading={loading} />
                                            </td>
                                        </tr>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {(report ? report?.getTask?.data : [])?.map((item, index) => {
                                            return (
                                                <tr key={item?.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.subject ? item?.subject : "N/A"}</td>
                                                    <td>{item?.is_completed === 0 ? "Not Completed" : "Completed"
                                                    }</td>
                                                    <td>{item?.type
                                                        ? item?.type
                                                        : "N/A"}</td>
                                                    <td>{item?.created_at
                                                    }</td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </Fragment>
                                )}

                            </tbody>
                        </Table>
                        <div >

                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    Showing {report.getTask?.from
                                    } to {report.getTask?.to} of {report?.getTask?.total} entries
                                </div>


                                <div className="d-flex justify-content-end align-items-center">
                                    <Input
                                        type="select"
                                        className="custom-select custom-select-page"
                                        onChange={(e) => setPaginate(e.target.value)}
                                        value={paginate}
                                    >
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                        <option value="2000">2000</option>
                                        <option value="3000">3000</option>
                                        <option value="5000">5000</option>
                                        <option value="7000">7000</option>
                                    </Input>



                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={parseInt(perPage)}
                                        totalItemsCount={report.getTask?.total}
                                        pageRangeDisplayed={5}
                                        onChange={(page) => setPage(page)}
                                        innerClass="pagination pagination-sm"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </MainWrapper>
        </Fragment>
    );
};

export default TaskReport;
