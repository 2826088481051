import Axios from "axios";

export const logintoCall = (data, token) => {
  return {
    type: "LOGIN_TO_CALL",
    payload: Axios.post("/api/crm/smartflow/login", data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const Calling = (data, token) => {
  return {
    type: "CALLING",
    payload: Axios.post("/api/crm/smartflow/clicktocall", data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const sendSms = (data, token) => {
  return {
    type: "SEND_SMS",
    payload: Axios.post("/api/crm/send-whats-app", data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};
// export const sendSmsSales = (data, token) => {
//   return {
//     type: "SEND_SMS_SALE",
//     payload: Axios.post("/api/crm/send-whats-app-sales", data, {
//       headers: { Authorization: `Bearer ${token}` },
//     }).catch((err)=>err),
//   };
// };

export const sendSmsSales = (data, token) => async (dispatch) =>{
  try{
      const resp = await Axios.post(`/api/crm/send-whats-app-sales`,data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        dispatch({
          type: "SEND_SMS_SALE_FULFILLED",
          payload: { message: "Added Successfully" },
        });

  } catch(errors){

      dispatch({
          type: "SEND_SMS_SALE_REJECTED",
          payload: errors,
        });

  }


};


export const hangUpCalling = (data, token) => {
  return {
    type: "CALL_HANG_UP",
    payload: Axios.post("/api/crm/hangup-call", data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const addSms = (da, token) => {
  return {
    type: "ADD_SMS",
    payload: Axios.post("/api/crm/add-whatsapp", da, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};
