import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import OptionModel from "../../components/callback-modal/optionsmodel";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
const ReminderPopUp = ({ modal, setModal, details, auth }) => {
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [checkedTasks, setCheckedTasks] = useState({});
  const [switchh, setSwitch] = useState(0);
  const handleLink = (data) => {
    if (data) {
      if (data?.type == "franchise_investor") {
        return `/franchise-investor-detail/${data?.crm_lead_user?.id}`;
      } else if (data?.type == "lead") {
        return `/lead/${data?.crm_lead_user?.id}/details`;
      } else if (data?.type == "franchise_lead") {
        return `/franchise-investor-detail/${data?.lead_id}`;
      } else if (data?.type == "associate_lead") {
        return `/associate-detail/${data?.crm_lead_user?.id}`;
      }
    }
  };

  const { token } = auth;
  const handleUser = (data) => {
    if (data) {
      if (data?.type == "franchise_investor") {
        return {
          name: data?.crm_lead_franchise?.customer_name,
          email: data?.crm_lead_franchise?.email,
          mobile: data?.crm_lead_franchise?.contact_number,
        };
      } else if (data?.type == "lead") {
        return {
          name: data?.crm_lead_user?.full_name,
          email: data?.crm_lead_user?.email,
          mobile: data?.crm_lead_user?.mobile,
        };
      } else if (data?.type == "franchise_lead") {
        return {
          name: data?.crm_lead_franchise?.customer_name,
          email: data?.crm_lead_franchise?.email,
          mobile: data?.crm_lead_franchise?.contact_number,
        };
      } else if (data?.type == "associate_lead") {
        return {
          name: data?.crm_franchise?.name,
          email: data?.crm_franchise?.email,
          mobile: data?.crm_franchise?.mobile,
        };
      }
    }
  };
  const handleClick = (e, id) => {
    if (e?.target?.type === "checkbox") {
      if (e?.target?.checked) {
        setCheckedTasks((prev) => ({
          ...prev,
          [id]: e?.target?.checked,
        }));
        axios
          .get(
            `/api/crm/view-user-task?id=${id}&view=${e.target.checked ? 1 : 0}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);

            NotificationManager.success(res.data?.message);
          })
          .catch((err) =>
            NotificationManager.error(err.response?.data?.message)
          );
      }
    }
  };

  return (
    <>
      <Modal toggle={() => setModal(!modal)} isOpen={modal} size={"lg"}>
        <ModalHeader toggle={() => setModal(!modal)}>Alert</ModalHeader>
        <ModalBody>
          <h5>Hi, {auth?.name} You Have New Call Back at this time</h5>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
                <table className="table table-hover mb-0 table-sm small">
                  <thead className="thead-light">
                    <tr>
                      <th className="pl-4">S.No.</th>
                      <th className="font-weight-bold">Lead Name</th>
                      <th className="font-weight-bold">Lead Mobile</th>
                      <th className="font-weight-bold">Lead Email</th>
                      <th className="font-weight-bold">Subject</th>
                      <th className="font-weight-bold">Activity</th>
                      <th className="font-weight-bold">Remark</th>
                      <th className="font-weight-bold">Start Date</th>
                      <th className="font-weight-bold">Start Time</th>
                      <th className="text-right font-weight-bold pr-4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody class="d-table-body">
                    <Fragment>
                      {details
                        ? details.map((v, i) => (
                            <tr key={Math.random()}>
                              <td className="pl-4">{i + 1}</td>
                              <td>{handleUser(v)?.name}</td>
                              <td>{handleUser(v)?.mobile}</td>
                              <td>{handleUser(v)?.email}</td>
                              <td>{v?.subject}</td>
                              <td>{v?.activity}</td>
                              <td>{v?.remark}</td>
                              <td>{v?.start_date}</td>
                              <td>{v?.start_time}</td>

                              <td className="text-right pr-4">
                                <OptionModel
                                  itemid={v?.id}
                                  openNotesModal={openNotesModal}
                                  setOpenNotesModal={setOpenNotesModal}
                                  openDispositionModal={openDispositionModal}
                                  setOpenDispositionModal={
                                    setOpenDispositionModal
                                  }
                                />
                                {/* <div class="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={v?.id}
                                    checked={checkedTasks[v?.id] || false}
                                    onChange={(e) => handleClick(e, v?.id)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexSwitchCheckDefault"
                                  >
                                    Task Completed
                                  </label>
                                </div> */}
                                <Link to={handleLink(v)}>
                                  <Button>Go to lead</Button>
                                </Link>
                                <br />
                                {/* <Button
                                  color={"danger"}
                                  className="ml-4"
                                  onClick={() => setModal(!modal)}
                                >
                                  Cancel
                                </Button> */}
                              </td>
                            </tr>
                          ))
                        : null}
                    </Fragment>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReminderPopUp;
