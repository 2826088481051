export const LEAD_REQUEST = "LEAD_REQUEST";
export const LEAD_REQUEST_PENDING = "LEAD_REQUEST_PENDING";
export const LEAD_REQUEST_SUCCESS = "LEAD_REQUEST_FULFILLED";
export const LEAD_REQUEST_REJECTED = "LEAD_REQUEST_REJECTED";

export const LEAD_DETAIL_REQUEST = "LEAD_DETAIL_REQUEST";
export const LEAD_DETAIL_REQUEST_PENDING = "LEAD_DETAIL_REQUEST_PENDING";
export const LEAD_DETAIL_REQUEST_FULFILLED = "LEAD_DETAIL_REQUEST_FULFILLED";
export const LEAD_DETAIL_REQUEST_REJECTED = "LEAD_DETAIL_REQUEST_REJECTED";


export const CAMPAIGN_CREATE_REQUEST_PENDING = "CAMPAIGN_CREATE_REQUEST_PENDING";
export const CAMPAIGN_CREATE_REQUEST_FULFILLED = "CAMPAIGN_CREATE_REQUEST_FULFILLED";
export const CAMPAIGN_CREATE_REQUES_REJECTED = "CAMPAIGN_CREATE_REQUEST_REJECTED";

export const CAMPAIGNS_REQUEST_PENDING = "CAMPAIGNS_REQUEST_PENDING";
export const CAMPAIGNS_REQUEST_FULFILLED = "CAMPAIGNS_REQUEST_FULFILLED";
export const CAMPAIGNS_REQUES_REJECTED = "CAMPAIGNS_REQUES_REJECTED";

export const CAMPAIGN_UPDATE_REQUEST_PENDING = "CAMPAIGN_UPDATE_REQUEST_PENDING";
export const CAMPAIGN_UPDATE_REQUEST_FULFILLED = "CAMPAIGN_UPDATE_REQUEST_FULFILLED";
export const CAMPAIGN_UPDATE_REQUES_REJECTED = "CAMPAIGN_UPDATE_REQUES_REJECTED";

export const CAMPAIGN_DELETE_REQUEST_PENDING = "CAMPAIGN_DELETE_REQUEST_PENDING";
export const CAMPAIGN_DELETE_REQUEST_FULFILLED = "CAMPAIGN_DELETE_REQUEST_FULFILLED";
export const CAMPAIGN_DELETE_REQUES_REJECTED = "CAMPAIGN_DELETE_REQUES_REJECTED";

export const LEAD_DATA_PENDING = "LEAD_DATA_PENDING";
export const LEAD_DATA_FULFILLED = "LEAD_DATA_FULFILLED";
export const LEAD_DATA_REJECTED = "LEAD_DATA_REJECTED";

export const LEADS_PENDING = "LEADS_PENDING";
export const LEADS_FULFILLED = "LEADS_FULFILLED";
export const LEADS_REJECTED = "LEADS_REJECTED";

export const LEAD_BY_ID_PENDING = "LEAD_BY_ID_PENDING";
export const LEAD_BY_ID_FULFILLED = "LEAD_BY_ID_FULFILLED";
export const LEAD_BY_ID_REJECTED = "LEAD_BY_ID_REJECTED";

export const UPDATE_LEAD_PENDING = "UPDATE_LEAD_PENDING";
export const UPDATE_LEAD_FULFILLED = "UPDATE_LEAD_FULFILLED";
export const UPDATE_LEAD_REJECTED = "UPDATE_LEAD_REJECTED";

export const CREATE_LEAD_PENDING = "CREATE_LEAD_PENDING";
export const CREATE_LEAD_FULFILLED = "CREATE_LEAD_FULFILLED";
export const CREATE_LEAD_REJECTED = "CREATE_LEAD_REJECTED";

export const SOURCE_CREATE_PENDING = "SOURCE_CREATE_PENDING";
export const SOURCE_CREATE_FULFILLED = "SOURCE_CREATE_FULFILLED";
export const SOURCE_CREATE_REJECTED = "SOURCE_CREATE_REJECTED";

export const PERMISSIONS_LIST = "PERMISSIONS_LIST";
export const PERMISSIONS_LIST_PENDING = "PERMISSIONS_LIST_PENDING";
export const PERMISSIONS_LIST_FULFILLED = "PERMISSIONS_LIST_FULFILLED";
export const PERMISSIONS_LIST_REJECTED = "PERMISSIONS_LIST_REJECTED";

export const CHANGE_REDUCER_STATE = "CHANGE_REDUCER_STATE";