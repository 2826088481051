/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {FiUser,FiActivity,FiUserCheck,FiArchive,FiRepeat,} from "react-icons/fi";
import {FaUser,FaUserFriends,FaUserTie,FaUserCog ,FaUserCheck ,FaSchool} from "react-icons/fa";
import{HiLightBulb} from "react-icons/hi"
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Input,
  Label,
  Button,
} from "reactstrap";
import {
  getLeadsDetail,
  getDashboardCount,
  ConsultantDashboardCount,
  ConsultantUserCount,
} from "../store/actions/DashboardActions";
import { Link } from "react-router-dom";

const ConsultantDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [toggle, setToggle] = useState("");
  const {
    leaddetails,
    dashboardcount,
    dashboardconsultant,
    dashboarduserconsultant,
  } = useSelector((state) => state.dashboardReducer);
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [date, setDate] = useState("");
  const [fdate, setFdate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    dispatch(getLeadsDetail(token));
    dispatch(
      ConsultantUserCount(token, {
        consultantId: toggle,
        date: date,
        fdate: fdate,
      })
    );
  }, [token, toggle, fdate]);

  useEffect(() => {
    dispatch(ConsultantDashboardCount(token, auth?.id, startDate, endDate));
  }, [auth, startDate, endDate]);

  useEffect(() => {
    SetLead(leaddetails?.convertedlead?.data);
  }, [leaddetails, dashboardcount, dashboardconsultant]);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="col-md-12">
              <div className="filter-container">
                <div className="row">
                  <div class="col-md-5">
                    <div className="form-group">
                      <label htmlFor="" className="form-label  mb-2">
                        Start Date
                      </label>
                      <Input
                        type="date"
                        value={startDate}
                        onChange={({ target: { value } }) =>
                          setStartDate(value)
                        }
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div className="form-group">
                      <label htmlFor="" className="form-label mb-2">
                        End Date
                      </label>
                      <Input
                        type="date"
                        value={endDate}
                        onChange={({ target: { value } }) => setEndDate(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {auth?.id == "74" ? (
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.today}
                                </h3>
                                <div className="mb-0">Today Members</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.total}
                                </h3>
                                <div className="mb-0">Total Members</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.professionalExpert}
                                </h3>
                                <div className="mb-0">Professional Members</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.supplier}
                                </h3>
                                <div className="mb-0">Supplier Member</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.industry}
                                </h3>
                                <div className="mb-0">Industry Expert</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.enterpreneur}
                                </h3>
                                <div className="mb-0">Entrepreneur</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                              <div className="col-4">
                                <h3 className="mb-2">
                                  {dashboardconsultant?.institutional}
                                </h3>
                                <div className="mb-0">Institutional</div>
                              </div>
                              <div className="col-4 ml-auto text-right">
                                <div className="d-inline-block mt-2">
                                  <FiUser className="feather-lg text-primary" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaUser color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.today}
                                </h3>
                                <div className="mb-0 font-commom-2">Today Members</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaUserFriends color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.total}
                                </h3>
                                <div className="mb-0 font-commom-2">Total Members</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaUserTie color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.professionalExpert}
                                </h3>
                                <div className="mb-0 font-commom-2">Professional Members</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaUserCog color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.supplier}
                                </h3>
                                <div className="mb-0 font-commom-2">Supplier Member</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaUserCheck color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.industry}
                                </h3>
                                <div className="mb-0 font-commom-2">Industry Expert</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <HiLightBulb  color="#fff"  size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.enterpreneur}
                                </h3>
                                <div className="mb-0 font-commom-2">Entrepreneur</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-12 d-flex">
                        <div className="card flex-fill common-card round-border ">
                          <div className="card-body py-4">
                            <div className="row">
                            <div className="col-4">
                                <div className="square_box">
                                  <FaSchool color="#fff" size={30}/>
                                </div>
                              </div>
                              <div className="col-8">
                                <h3 className="mb-0 font-commom">
                                  {dashboardconsultant?.institutional}
                                </h3>
                                <div className="mb-0 font-commom-2">Institutional</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12 cursor-pointer">
                  <div className="row">
                    {dashboardconsultant?.consultantlists?.map((consultant) => (
                      <div className="col-md-4">
                        <Card>
                          <CardHeader onClick={() => setToggle(consultant?.id)}>
                            <span className="font-weight-bold cursor-pointer">
                              {consultant?.name}
                            </span>
                          </CardHeader>
                          <Collapse
                            isOpen={toggle === consultant?.id ? true : false}
                          >
                            <CardBody>
                              <Label>Start Date</Label>
                              <Input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />

                              <Label className="pt-2">End Date</Label>
                              <Input
                                type="date"
                                value={fdate}
                                onChange={(e) => setFdate(e.target.value)}
                              />

                              <div className="d-flex justify-content-between pt-4">
                                {" "}
                                <div>
                                  <h6>Total</h6>
                                </div>{" "}
                                <div>{dashboarduserconsultant.total}</div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Today</h6>
                                </div>{" "}
                                <div>{dashboarduserconsultant.today}</div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Professional Members</h6>
                                </div>{" "}
                                <div>
                                  {dashboarduserconsultant.professionalExpert}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Supplier Member</h6>
                                </div>{" "}
                                <div>{dashboarduserconsultant.supplier}</div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Industry Expert</h6>
                                </div>{" "}
                                <div>{dashboarduserconsultant.industry}</div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Entrepreneur</h6>
                                </div>{" "}
                                <div>
                                  {dashboarduserconsultant.enterpreneur}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between pt-2">
                                {" "}
                                <div>
                                  <h6>Institutional</h6>
                                </div>{" "}
                                <div>
                                  {dashboarduserconsultant.institutional}
                                </div>
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* { dashboardconsultant?.consultantlists?.map((consultant, index) => (

             <div className="accordion" id="ActivityAccordion">
             <div className="card">
             <div className="card-header p-0" id="heading-2">
            <Link
              className="card-title m-0 h5 text-white d-block p-2"
              to="#"
              data-toggle="collapse"
              data-target= {`#collapse-${index+1}`}
              aria-expanded="true"
              aria-controls={`#collapse-${index+1}`}
             
            >
              {consultant.name} 
            </Link>
          </div>
          <div
            id={`collapse-${index+1}`}
            className="collapse"
            aria-labelledby="heading-2"
            data-parent="#ActivityAccordion"  
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>Total</th>
                      <th>Professional Members</th>
                      <th>Supplier Member</th>
                      <th>Industry Expert</th>
                      <th>Entrepreneur</th>
                      <th>Institutional</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      <Fragment>
                         
                              <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                              
                              </tr>
                            
                           
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
            ))
            } */}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default ConsultantDashboard;
