import axios from "axios";
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import { FaList, FaTrash } from "react-icons/fa";
import { FiEdit, FiTrash } from "react-icons/fi";
import MainWrapper from "../../components/MainWrapper";
import { NotificationManager } from "react-notifications";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { map, capitalize, last, split, mapValues } from "lodash";
import { FiEye, FiUpload } from "react-icons/fi";
const UdyamRequest = memo(() => {
  const [data, setData] = useState({});

  const [statuses, setStatuses] = useState({});
  const [prevData, setPrevData] = useState({
    value: "",
    idx: "",
    key: "",
  });
  const [modal, setModal] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [activeTab, setActiveTab] = useState(5);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [fields, setFields] = useState({});
  const [partnernames, setPartnernames] = useState([]);
  const [memname, setMebershipName] = useState([]);
  const [singleuser, setSingle] = useState({});
  const [member, setMemeber] = useState([]);
  const [uploadmodal, setUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(true);
  const [deleteIds, setDeleteIds] = useState("");
  const [modalHold, setModalHold] = useState(false);
  const [getId, setGstId] = useState("");
  const [remark, setRemark] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [memberDetail, setMemberDetail] = useState({});
  const dropdownOpenToggle = () =>
    setDropdownOpen((dropdownOpen) => !dropdownOpen);

  useMemo(() => {
    const url = new URL(window.location).searchParams;
    const start = url.get("start");
    const end = url.get("end");

    if (start) {
      console.log(start, "checkk");
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/crm/service-requests/details?activeTab=${activeTab}&search=${search}&sort=${sort}&paginate=${paginate}&page=${page}&service_status=${serviceStatus}&start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        setData(res.data.udyamDetails.data);
        setFrom(res.data.udyamDetails.from);
        setTo(res.data.udyamDetails.to);
        setTotal(res.data.udyamDetails.total);
        setPerPage(res.data.udyamDetails.per_page);
        setLoading(false);
        mapValues(res.data.udyamDetails, (value, key) => {
          setStatuses((prevState) => {
            return {
              ...prevState,
              [key]: map(value, (v) => ({
                id: v.id,
                status: v.status,
              })),
            };
          });
        });
      })
      .catch((err) => console.log(err.response));
  }, [refresh, activeTab, search, sort, paginate, page, serviceStatus]);
  const handleStatusChange = (value, idx, key, file, gst) => {
    console.log(value);
    console.log(idx);
    console.log(key);
    console.log(gst, "sfsvfsv");
    if (value === "INVALID") {
      setPrevData({
        value,
        idx,
        key,
      });
      setModal(true);
    } else if (file != "" && value != "COMPLETED" && value != "IN_PROGRESS") {
      setGstId(gst?.id);
      setModalHold(true);
      NotificationManager.error("do not change status on this stage..");
    } else if (file == null && value === "COMPLETED") {
      NotificationManager.error("Please Upload File..");
    } else if (value == "On Hold") {
      setModalHold(true);
    } else {
      setPrevData({
        value,
        idx,
        key,
      });
      if (value != "COMPLETED" && value != "IN_PROGRESS") {
        setStatuses((prev) => {
          const updatedKey = prev[prevData.key];
          updatedKey[prevData.idx].status = prevData.value;
          return {
            ...prev,
            [prevData.key]: updatedKey,
          };
        });
      }

      handleStatusSubmit(value, idx, key);
    }
  };
  const handleFeedbackSubmit = () => {
    setStatuses((prev) => {
      const updatedKey = prev[prevData.key];
      updatedKey[prevData.idx].status = prevData.value;
      return {
        ...prev,
        [prevData.key]: updatedKey,
      };
    });
    setModal(false);
    handleStatusSubmit();
  };

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  const handleStatusSubmit = (value, idx, key) => {
    axios
      .post(`/service-requests/status`, {
        key: key ?? key,
        status: statuses[key ?? prevData.key]?.[idx ?? prevData.idx] ?? idx,
        status1: value,
        feedBack,
      })
      .then(() => {
        setPrevData({
          value: "",
          idx: "",
          key: "",
        });
        setFeedBack("");
        setRefresh((prev) => prev + 1);
      })
      .catch((error) =>
        console.log(
          "🚀 ~ file: ServiceRequests.js ~ line 105 ~ handleStatusSubmit ~ error",
          error
        )
      );
  };

  const handleView = (e, data, user) => {
    e.preventDefault();
    setModal(true);
    setFields(data);
    setSingle(user);

    axios
      .get(`/api/crm/getUserbyId/` + user.id, {
        headers: {
          authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setMemeber(res.data.memebership);
        setMemberDetail(res.data.user?.plan_detail_all);
      })
      .catch((err) => console.log(err));
  };

  const datediff = (date1, date2) => {
    const date4 = new Date(date1);
    const date5 = new Date();

    const diffInMs = Math.abs(date5 - date4);
    if (diffInMs > 0) {
      return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    } else {
      return 0;
    }
  };
  const UploadView = (e, data, user, partner) => {
    e.preventDefault();
    setUploadModal(true);
    setFields(data);
    setSingle(user);
    setPartnernames(partner);

    axios
      .get(`/getUserbyId/` + user.id)
      .then((res) => {
        setMemeber(res.data.memebership);
      })
      .catch((err) => console.log(err));
  };
  const submitForm = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", fields.id);
    data.append("uploadFile", uploadFile);

    axios
      .post("/service-requests/udyamfile", data)
      .then((res) => {
        NotificationManager.success("Uploaded successfully");
        window.location.reload();
        setUploadModal(false);
      })
      .catch((err) => NotificationManager.error(err.response.data.message));
    //  updateProfile(data, token);
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .get(`/udyam-aadhar-delete/${id}`, id)
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          setDeleteIds([]);
          NotificationManager.success(res.data.message);
          console.log(res.data, "delete");
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const submitRemarkForm = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", getId);
    data.append("remark", remark);

    axios
      .post("/service-requests-remark/udyamfile", data)
      .then((res) => {
        NotificationManager.success("Added successfully");
        window.location.reload();
        setModalHold(false);
      })
      .catch((err) => NotificationManager.error(err.response.data.message));
  };

  return (
    <Fragment>
      <MainWrapper fluid>
        <main className="content pt-4">
          <div className="page-header mb-4">
            <Row>
              <Col md="12">
                <div className="c-card-top">
                  <div className="c-card-top-title">
                    {/* <h4 className="mb-0">Udyam Aadhar Requests</h4> */}
                    <h4 className="mb-0">Udyam Aadhar Registration</h4>
                  </div>
                  <div className="c-card-top-inputs c-card-top-search">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                      <span className="custom-select">Search</span>
                      <Input
                        placeholder="Search here..."
                        onChange={handleSearch}
                        className="c-form-control-serch"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-secondary btn-sm"
                          type="button"
                          id="button-addon2"
                        >
                          GO
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="c-card-top-buttons">
                    <ButtonGroup className="">
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={dropdownOpenToggle}
                      >
                        <DropdownToggle caret size="sm">
                          <FaList />
                          &nbsp;Service Tools
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="#">
                            Tool Name
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </ButtonGroup>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center">
                                <h4>Udyam Aadhar Requests</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                    <InputGroup className="mr-1">
                                        <Input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={({ target: { value } }) =>
                                                setSearch(value)
                                            }
                                        />
                                    </InputGroup>
                                    <ButtonDropdown
                                        isOpen={dropdownOpen}
                                        toggle={dropdownOpenToggle}
                                    >
                                        <DropdownToggle caret>
                                            <FaList />
                                            &nbsp;Service Tools
                                        </DropdownToggle>
                                        <DropdownMenu className="w-100">
                                            <DropdownItem tag="a" href="#">
                                                Tool Name
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div> */}
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <div>
                    <Button
                      color="secondary"
                      size="sm"
                      className="btn btn-sm btn-secondry btn-circle mr-1"
                    >
                      <i className="fa fa-trash pr-1" /> Delete
                    </Button>
                    <Button
                      className="btn btn-sm btn-secondry btn-circle"
                      color="secondary"
                      size="sm"
                    >
                      <i className="fa fa-refresh" />
                    </Button>
                  </div>

                  <div className="d-flex align-items-center">
                    <select
                      id="serviceStatus"
                      onChange={(e) => setServiceStatus(e.target.value)}
                      value={serviceStatus}
                      className="custome-select mr-1"
                      style={{
                        width: 195,
                      }}
                    >
                      <option value="">Select Service Status</option>
                      <option value="APPLIED">Applied</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="ON HOLD">On Hold</option>
                      <option value="INVALID">Invalid</option>
                      <option value="CANCEL">Cancel</option>
                    </select>
                    <select
                      id="sort"
                      onChange={(e) => setSort(e.target.value)}
                      value={sort}
                      className="custome-select mr-1"
                      style={{ width: 135 }}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                    <select
                      id="paginate"
                      onChange={(e) => setPaginate(e.target.value)}
                      value={paginate}
                      className="custome-select custom-select-page"
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  {/* <Nav pills>
                                    <NavItem onClick={() => setActiveTab(5)}>
                                        <NavLink
                                            href="#"
                                            active={activeTab === 5}
                                        >
                                            Udyam Aadhar Registration
                                        </NavLink>
                                    </NavItem>
                                </Nav> */}
                  <TabContent className="" activeTab={activeTab}>
                    <TabPane tabId={5}>
                      <Row>
                        <Col sm="12">
                          <div className="table-responsive">
                            <Table size="sm" className="small mb-0" hover>
                              <thead className="thead-light">
                                <tr>
                                  <th>SNo.</th>
                                  <th className="font-weight-bold">
                                    Member Name
                                  </th>
                                  <th className="font-weight-bold">Mobile</th>
                                  <th className="font-weight-bold">email</th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    Membership Type
                                  </th>
                                  <th className="font-weight-bold">
                                    Applied On
                                  </th>

                                  <th className="font-weight-bold">Status</th>
                                  <th className="font-weight-bold">Remark</th>
                                  <th className="font-weight-bold text-right">
                                    Action
                                  </th>
                                  {/* <th className="font-weight-bold">
                                                                    Feedback
                                                                </th> */}
                                </tr>
                              </thead>
                              <tbody className="d-table-body">
                                {map(data, (udyam, idx) => (
                                  <Fragment key={`udyam-${udyam?.id}`}>
                                    <tr>
                                      <td>{idx + 1}</td>
                                      <td>
                                        <a
                                          className="c-text-btn-primary"
                                          href={`/user-profile/${udyam?.user?.id}`}
                                        >
                                          {capitalize(udyam?.user?.name)}
                                        </a>
                                      </td>
                                      <td>{udyam?.user?.mobile}</td>
                                      <td>{udyam?.user?.email}</td>
                                      {/* <td>
                                                                                {
                                                                                    udyam.date_of_commencement
                                                                                }
                                                                            </td> */}
                                      <td>
                                        {udyam?.userpl
                                          ? udyam?.userpl?.name
                                          : ""}
                                        <span className="badge badge-sm badge-info">
                                          {member === null
                                            ? ""
                                            : datediff(
                                                udyam?.userpl
                                                  ? udyam?.userpl?.expiry
                                                  : "",
                                                udyam?.userpl
                                                  ? udyam?.userpl?.purchased_at
                                                  : ""
                                              )}

                                          {member === null ? (
                                            ""
                                          ) : (
                                            <span>Days Left</span>
                                          )}
                                        </span>
                                      </td>
                                      <td>{udyam?.created_at}</td>

                                      <td>{udyam?.status}</td>
                                      <td>
                                        {udyam?.status === "COMPLETED"
                                          ? ""
                                          : udyam?.remark}
                                      </td>
                                      <td className="text-left">
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="c-icon-btn-light"
                                          onClick={(e) =>
                                            handleView(e, udyam, udyam.user)
                                          }
                                        >
                                          <FiEye />
                                        </span>
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>

                <div className="card-footer">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      Showing {from} to {to} of {total} entries
                    </span>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={(page) => setPage(page)}
                      innerClass="pagination pagination-sm"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </main>
      </MainWrapper>
      <Modal isOpen={uploadmodal}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setUploadModal(false)}
        ></ModalHeader>
        <ModalBody className="px-2 py-1">
          <FormGroup>
            <Label>Upload Document</Label>
            <Input
              type="file"
              onChange={(e) => setUploadFile(e.target.files[0])}
            />
          </FormGroup>
          <Button color="success" size="sm" onClick={submitForm}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: 700 }}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setModal(false)}
        >
          {singleuser.name ? singleuser.name : ""}
        </ModalHeader>
        <ModalBody className="px-2 py-3" style={{ fontSize: 12 }}>
          <FormGroup>
            <div className="container-fluid">
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Membership Type:</strong>
                </div>
                <div className="col-md-9">
                  <span>{memberDetail?.name ? memberDetail.name : ""}</span>{" "}
                  <span className="badge badge-info badge-sm">
                    {member === null
                      ? ""
                      : datediff(memberDetail?.expiry, memberDetail?.joining)}

                    {member === null ? "" : <span>Days Left</span>}
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Mobile:</strong>
                </div>
                <div className="col-md-9">
                  <span>{singleuser.mobile ? singleuser.mobile : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Email:</strong>
                </div>
                <div className="col-md-9">
                  <span>{singleuser.email ? singleuser.email : ""}</span>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Business Name:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.name ? fields.name : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>
                    Date Of Commencement:
                  </strong>
                </div>
                <div className="col-md-9">
                  <span>
                    {fields.date_of_commencement
                      ? fields.date_of_commencement
                      : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Category:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.category ? fields.category : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>
                    Business Category:
                  </strong>
                </div>
                <div className="col-md-9">
                  <span>
                    {fields.business_category ? fields.business_category : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>
                    Major Business Activity:
                  </strong>
                </div>
                <div className="col-md-9">
                  <span>
                    {fields.major_business_activity
                      ? fields.major_business_activity
                      : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Investment:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.investment ? fields.investment : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>GSTIN:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.gstin ? fields.gstin : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Total Member:</strong>
                </div>
                <div className="col-md-9">
                  <span>
                    {fields.total_male ? fields.total_male : ""}{" "}
                    <span style={{ fontWeight: 600 }}>Male</span>,{" "}
                    {fields.total_female ? fields.total_female : ""}{" "}
                    <span style={{ fontWeight: 600 }}>Female</span>,{" "}
                    {fields.total_other ? fields.total_other : ""}{" "}
                    <span style={{ fontWeight: 600 }}>Other</span>
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Filed Itr:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.filed_itr ? fields.filed_itr : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Gem Portal:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.gem_portal ? fields.gem_portal : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Treds Portal:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.treds_portal ? fields.treds_portal : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Status:</strong>
                </div>
                <div className="col-md-9">
                  <span className="badge badge-sm badge-warning">
                    {fields.status ? fields.status : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-12">
                  <div className="py-1 px-2" style={{ background: "#e5e5e5" }}>
                    <strong style={{ fontWeight: 600 }}>Contact Detail</strong>
                  </div>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Mobile No:</strong>
                </div>
                <div className="col-md-9">
                  <span> {fields.mobile_no ? fields.mobile_no : ""}</span>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Email:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.email ? fields.email : ""}</span>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Location:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.delhi ? fields.delhi : ""} </span>
                </div>
              </div>

              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Address:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.address ? fields.address : ""}</span>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-12">
                  <div className="py-1 px-2" style={{ background: "#e5e5e5" }}>
                    <strong style={{ fontWeight: 600 }}>Bank Detail</strong>
                  </div>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Bank Name:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.bank_name ? fields.bank_name : ""}</span>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>IFSC Code:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields.ifsc_code ? fields.ifsc_code : ""}</span>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Account Number:</strong>
                </div>
                <div className="col-md-9">
                  <span>
                    {fields.account_number ? fields.account_number : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-12">
                  <div className="py-1 px-2" style={{ background: "#e5e5e5" }}>
                    <strong style={{ fontWeight: 600 }}>
                      Business Document
                    </strong>
                  </div>
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-10">
                  <strong style={{ fontWeight: 600 }}>Aadhar Card:</strong>
                </div>
                <div className="col-md-2">
                  {fields.aadhar_card ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.aadhar_card
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>

              <div className="row mb-1 px-2">
                <div className="col-md-10">
                  <strong style={{ fontWeight: 600 }}>Pan Card:</strong>
                </div>
                <div className="col-md-2">
                  {fields.pan_card ? (
                    <a
                      href={`/storage/${fields == null ? "" : fields.pan_card}`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalHold}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setModalHold(false)}
        ></ModalHeader>
        <ModalBody className="px-2 py-1">
          <FormGroup>
            <Label>Remark</Label>
            <Input
              type="textarea"
              cols={40}
              onChange={(e) => setRemark(e.target.value)}
            ></Input>
          </FormGroup>
          <Button color="success" size="sm" onClick={submitRemarkForm}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default UdyamRequest;
