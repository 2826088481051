import axios from "axios";
export const getFranchiseList =
  (token, paginate, search, sort, page, assignDate, leadstatus, segment) =>
  async (dispatch) => {
    dispatch({ type: "GET_FRANCHISE_LIST" });
    try {
      const resp = await axios(
        `/api/crm/get-crm-team-details?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&assign_date=${assignDate}&lead_status=${leadstatus}&segment=${segment}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "GET_FRANCHISE_LIST_FULFILLED", payload: resp.data });
    } catch (error) {
      dispatch({
        type: "GET_FRANCHISE_LIST_REJECTED",
        payload: error.response?.data,
      });
    }
  };
