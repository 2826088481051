import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../components/MainWrapper";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import _ from "lodash";
import { getallPermissions } from "../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import Note from "./subComponent/Note";
import Task from "../components/lead/Task";
import TaskList from "../components/lead/sub-component/TaskList";
const Detail = () => {
  const [updateName, setUpdateName] = useState("N/A");
  const [wsStatus, setWsStatus] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const { taskDeleteStatus } = useSelector(({ task }) => task);
  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const token = useMemo(() => auth?.token, [auth]);
  const dispatch = useDispatch();
  const permissions = useMemo(() => permission ?? [], [permission]);
  const { users, mssg } = useSelector(({ crmUser }) => crmUser);
  const user = useMemo(() => users, [users, mssg]);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);

  useEffect(() => {
    Axios.get(`/api/crm/get-pmfmeby/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLeadDetails(res.data?.pmfme);
      })
      .catch((err) => console.log(err));
  }, [id, token]);

  useEffect(() => {
    Axios.get(`/api/crm/fetch-lead-task/${id}?type=franchise`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setTaskList(res.data?.task);
      })
      .catch((err) => console.log(err));
  }, [wsStatus, taskDeleteStatus]);

  console.log(leadDetails, "raa");
  return (
    <>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Franchise Details</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <div className="card">
                  <div className="card-header btn-header p-2">
                    <div className="left-box-tools">
                      <Link
                        to="/franchise-list"
                        className="btn btn-secondary btn-sm pl-1 pr-1"
                      >
                        <i className="fa fa-mail-reply" aria-hidden="true" />{" "}
                        Back
                      </Link>
                      {permissions?.find(
                        (ps) => ps.name === "agent-lead-edit"
                      ) != null ? (
                        <button className="btn btn-secondary btn-sm pr-1 ml-1">
                          <i className="fa fa-pencil" />

                          <Link
                            to={`/consultant-edit/${leadDetails?.id}`}
                            className="mr-2 text-info"
                          >
                            Edit
                          </Link>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="card-body p-2">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="small-details">
                          <div className="details">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-md-flex justify-content-between">
                                  <div className="order-md-2"></div>
                                  <div className="order-md-1 d-flex align-items-center">
                                    <h4 className="font-weight-bold mb-0">
                                      {leadDetails?.name || "N/A"}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row lead-info">
                              <div className="col-md-12">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Name
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {leadDetails?.name || "N/A"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Email
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {leadDetails?.email || "N/a"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Mobile
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {leadDetails?.mobile || "N/a"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="font-weight-bold d-inline-block label-tag ">
                                  State
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">{leadDetails?.state}</span>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  City
                                </div>
                                <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">{leadDetails?.city}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 mb-2 mt-2">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold mt-0 mb-0 inline">
                                  Remarks
                                </h4>
                              </div>
                              <hr className="my-0" />
                            </div>
                            <div className="col-md-12 editable-row mb-2">
                              <div className="remark-box bg-secondary p-2">
                                {_.get(leadDetails, "remarks", "N/A")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Note id={id} permissions={permissions} />
                      </div>
                    </div>
                  </div>
                </div>

                <TaskList
                  taskList={taskList}
                  setWsStatus={setWsStatus}
                  type="franchise"
                />
              </div>
              <div className="col-lg-3">
                <div className="accordion" id="ActionsAccordion">
                  <div className="card mb-3">
                    <div className="card-header p-0" id="heading-1">
                      <Link
                        className="card-title m-0 h5 text-white d-block p-2"
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapse-3"
                        aria-expanded="true"
                        aria-controls="collapse-3"
                      >
                        Quick Actions
                      </Link>
                    </div>
                    <div
                      id="collapse-3"
                      className="collapse show"
                      aria-labelledby="heading-1"
                      data-parent="#ActionsAccordion"
                    >
                      <div className="card-body p-2">
                        <div className="row">
                          <div
                            id="addSourcePop"
                            className="dv3 dvPopB1"
                            style={{ display: "none" }}
                          >
                            <div className="arrowB"></div>
                            <div className="arrow-borderB"></div>
                          </div>

                          <div className="col-md-6 mb-2">
                            <Task
                              leadDetail={leadDetails}
                              setWsStatus={setWsStatus}
                              type="franchise"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </>
  );
};

export default Detail;
