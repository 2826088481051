import Axios from "axios";

export const createRole = (data) => {
    return {
        type: "USER_ROLE",
        payload: Axios.post(`/api/crm/create-role`, data, {
            headers: { Authorization: `Bearer ${data.token}` },
        }).catch((err)=>err),
    };
};
export const users = (token, paginate, search, page, sort) => {
    return {
        type: "USER_LIST",
        payload: Axios.get(
            `/api/crm/user-list?sort=${sort}&page=${page}&search=${search}&paginate=${paginate}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        ).catch((err)=>err),
    };
}
export const roles = (token, sort, page, search, paginate) => {
    return {
        type: "ROLES",
        payload: Axios.get(
            `/api/crm/roles?sort=${sort}&page=${page}&search=${search}&paginate=${paginate}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        ).catch((err)=>err),
    };
};

export const roleDestroy = (token, ids) => {
    return {
        type: "ROLE_DESTROY",
        payload: Axios.post(
            `/api/crm/role-destroy`,
            { ids: JSON.stringify([ids]) },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        ).catch((err)=>err),
    };
};

export const roleUpdate = (data) => {
    return {
        type: "ROLE_UPDATE",
        payload: Axios.post(`/api/crm/update-role`, data, {
            headers: { Authorization: `Bearer ${data.token}` },
        }).catch((err)=>err),
    };
};

export const createPermission = (data) => {
    return {
        type:"CREATE_PERMISSION",
        payload:Axios.post(`/api/crm/create-permission`,data,{headers: { Authorization: `Bearer ${data.token}` }}).catch((err)=>err)
    };
};

export const permissions = (token, sort, page, search, paginate) => {
       return {
           type:"PERMISSIONS",
           payload:Axios.get(`/api/crm/permissions?sort=${sort}&page=${page}&search=${search}&paginate=${paginate}`,{
                headers: { Authorization: `Bearer ${token}` },
            }).catch((err)=>err)
       };
};

export const permnissionUpdate = (data) => {
     return {
         type:"PERMISSION_UPDATE",
         payload:Axios.post(`/api/crm/update-permission`,data,{headers: { Authorization: `Bearer ${data.token}` }})
     }.catch((err)=>err)
};

export const permissionDelete = (token, ids) => {
    
        return {
            type:"PERMISSION_DELETE",
            payload: Axios.post(`/api/crm/permission-destroy`,{ ids: JSON.stringify([ids]) }, { headers: { Authorization: `Bearer ${token}` }, }).catch((err)=>err),
        };
};

export const userDetails = (token,ids) => {
    return {
        type:"USER_DETAILS_FULFILLED",
        payload: Axios.get(`/api/crm/getUserbyId/${ids}`, { headers: { Authorization: `Bearer ${token}` }, }).catch((err)=>err),
    }
} 