import Axios from "axios";

export const callFormHandler = (data) => {
  return {
    type: "CALL_DATA",
    payload: Axios.post(`/api/crm/new-call`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    }).catch((err)=>err),
  };
};

export const callOptionHandler = (data, token) => {
  return {
    type: "ADD_CALL",
    payload: Axios.post(`/api/crm/add-call`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const getCallSingleDataById = (id, token) => {
  console.log(id);
  return {
    type: "GET_CALL_BY_ID",
    payload: Axios.get(`/api/crm/getCallById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};
