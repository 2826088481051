/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import Axios from "axios";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadsDetail,
  getDashboardCount,
} from "../store/actions/DashboardActions";
import {
  FaUser,
  FaUserCheck,
  FaPhoneAlt,
  FaSadCry,
  FaMailBulk,
  
} from "react-icons/fa";
import {BiSolidBox} from "react-icons/bi"
import { BiDetail } from "react-icons/bi";
import { HiPhoneIncoming } from "react-icons/hi";
import Accordian from "../../src/components/dashboard-accordian/Accordian";
import { Link } from "react-router-dom";
import moment from "moment";
const FranchiseDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [disposition, setDisposition] = useState("");
  const [franchiseData, setFranchiseData] = useState([]);
  const [assignToUser, setassignToUser] = useState([]);
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    dispatch(getDashboardCount(token, "get-franchise-team-dashboard-count"));
  }, [token]);

  useEffect(() => {
    SetLead(leaddetails.convertedlead);
  }, [leaddetails, dashboardcount]);
  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-investor-dashboard-count`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => setFranchiseData(res.data))
      .catch((err) => console.log(err));
  }, []);
  const today = new Date().toISOString().slice(0, 10);
 
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            {/* <div className="row">
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill box-darked">
                  <a
                    href={`/franchise-list?leaddate=${leadDate}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4 ">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-2 text-white">
                            {dashboardcount?.todayLead ?? 0}
                          </h3>
                          <div className="mb-0 text-white">Today Brand Onboarding</div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                          <div className="d-inline-block mt-2">
                            <FaUser color="white" size={28} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill box-green">
                  <a
                    href={`/franchise-list`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-2 text-white">
                            {dashboardcount?.totalLead ?? 0}
                          </h3>
                          <div className="mb-0 text-white">Total Brand Onboarding</div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                          <div className="d-inline-block mt-2 text-white">
                            <FaUserCheck color="white" size={28} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill box-darkblue">
                  <a
                    href={`/franchise-list?status=hot`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-2 text-white">
                            {dashboardcount?.hotLead ?? 0}
                          </h3>
                          <div className="mb-0 text-white">Total Hot Lead</div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                          <div className="d-inline-block mt-2">
                            <FaUserCheck color="white" size={28} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill box-orange">
                  <a
                    href={`/franchise-list?status=warm`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-2 text-white">
                            {dashboardcount?.warmLead ?? 0}
                          </h3>
                          <div className="mb-0 text-white">Total Warm Lead</div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                          <div className="d-inline-block mt-2">
                            <FiRepeat className="feather-lg text-white"  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill box-brown">
                  <a
                    href={`/franchise-list?status=cold`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-2 text-white">
                            {dashboardcount?.coldLead ?? 0}
                          </h3>
                          <div className="mb-0 text-white">Total Cold Lead</div>
                        </div>
                        <div className="col-4 ml-auto text-right">
                          <div className="d-inline-block mt-2">
                            <BiSolidBox className="feather-lg text-white " />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}

            <div>
              <h5>Franchise Investor</h5>
              <div className="row">
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill box-darked">
                    <a
                      href={`/franchise-list-investor?leaddate=${leadDate}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2 text-white">
                              {franchiseData?.todayInvestor ?? 0}
                            </h3>
                            <div className="mb-0 text-white">Today Franchise Investor</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiUser className="feather-lg text-white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill box-green">
                    <a
                      href={`/franchise-list-investor`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2 text-white">
                              {franchiseData?.totalInvestor ?? 0}
                            </h3>
                            <div className="mb-0 text-white">Total Franchise Investor</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiUserCheck className="feather-lg text-white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill box-darkblue">
                    <a
                      href={`/franchise-list-investor?disposition_type=Connected`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2 text-white">
                              {franchiseData?.totalConnected ?? 0}
                            </h3>
                            <div className="mb-0 text-white">Total Connected</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiRepeat className="feather-lg text-white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill box-orange">
                    <a
                      href={`/franchise-list-investor?disposition_type=Not_Connected`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2 text-white">
                              {franchiseData?.totalNotConnected ?? 0}
                            </h3>
                            <div className="mb-0 text-white">Total Not Connected</div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <FiRepeat className="feather-lg text-white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl d-flex">
                  <div className="card flex-fill box-brown">
                    <a
                      href={`/franchise-list-investor?disposition_type=Connected&disposition=Follow_Up
                    `}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body py-4">
                        <div className="row">
                          <div className="col-8">
                            <h3 className="mb-2 text-white">
                              {franchiseData?.totalFollowUp ?? 0}
                            </h3>
                            <div className="mb-0 text-white">Total Follow up(Hot) </div>
                          </div>
                          <div className="col-4 ml-auto text-right">
                            <div className="d-inline-block mt-2">
                              <div className="square-box darkred">
                                <BiDetail className="feather-lg text-white" size={18} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <FaSadCry color="#33d69f" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkred">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalFollowUp ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Follow up(Hot){" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.todayConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.todayNotConnected ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Not Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <Link
                    to={`/franchise-list-investor?disposition_type=Connected&disposition=Call_Back
                    `}
                    // style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <HiPhoneIncoming color="#6C00FF" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {franchiseData?.totalCallBack ?? 0}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Call Back
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <Link
                    to={`/franchise-list-investor?disposition_type=Connected&disposition=Mail_shared
                    `}
                    // style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <FaMailBulk color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {franchiseData?.totalMailShared ?? 0}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Mail Shared
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <Link
                    to={`/franchise-list-investor?disposition_type=Connected&disposition=Manufacturing
                    `}
                    // style={{ textDecoration: "none" }}
                  >
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {franchiseData?.totalManufacturing ?? 0}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Manufacturing
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalHot ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Hot Lead</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalWarm ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Warm Lead
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {franchiseData?.totalCold ?? 0}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Cold Lead
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {auth?.id == "40" || auth?.id == "41" || auth?.id == "166"? (
              <>
                <div>
                  <h5>Franchise Team</h5>
                  <div className="row cursor-pointer">
                    {franchiseData?.getTeamList?.map((consultant) => (
                      <div className="col-md-4 my-2">
                        <div>
                          {/* <Card>
                            <CardHeader onClick={() => setId(consultant?.id)}>
                              <span className="font-weight-bold cursor-pointer">
                                {consultant?.name}
                              </span>
                            </CardHeader>
                            <Collapse
                              isOpen={id === consultant?.id ? true : false}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between pt-4">
                                  {" "}
                                  <div>
                                    <h6>Today Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.todayLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Today Connected</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalConnected}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total CallBack</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalCallBack}</div>
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card> */}
                          <Accordian
                            consultant={consultant}
                            key={consultant?.id}
                            type="3"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default FranchiseDashboard;
