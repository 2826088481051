import Axios from "axios";

export const MemberShipData = (token) => {
  return {
    type: "MEMBERSHIP",
    payload: Axios.get(`/api/crm/membership-master-api`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const sectors = (token) => {
  return {
    type: "SECTOR_LIST",
    payload: Axios.get(`/api/crm/sectordata12`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const sectordata = (token,planId) => {
  return {
    type: "SECTOR_LIST_DROP",
    payload: Axios.get(`/api/crm/sectordata-dropdown?plan_id=${planId}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const projects = (token, sectorValue) => {
  return {
    type: "PROJECT_LIST",
    payload: Axios.get(`/api/crm/sub-sub-categories-membership/list`, sectorValue, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const memberShipCreate = (data, token) => {
  console.log(data)
  return {
    type: "MEMBERSHIP_CREATE",
    payload: Axios.post(`/api/crm/membership-registration`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const memberShipList = (token, paginate, search, page, sort) => {
  return {
    type: "MEMBERSHIP_LIST",
    payload: Axios.get(
      `/api/crm/membership-list?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err)=>err),
  };
};

export const getMemberShipbyId = (token, membershipId) => {
  return {
    type: "MEMBERSHIP_BY_ID",
    payload: Axios.get(`/api/crm/membership/${membershipId}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const memberShipUpdate = (data, token, membershipId) => {
  return {
    type: "MEMBERSHIP_EDIT",
    payload: Axios.post(`/api/crm/membership/${membershipId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const changeReducerState = () => {
  return {
    type: "CHANGE_MEMBERSHIP_STATE",
  };
};
