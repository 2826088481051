import Axios from "axios";

export const crmUserDetails = (token, ids) => {
  return {
    type: "USER_DETAIL_BY_ID",
    payload: Axios.get(`/api/crm/getUserbyId/${ids}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const createCrmLead = (data, token) => async (dispatch) => {
  dispatch({ type: "CREATE_CRM_LEAD" });
  try {
    const response = await Axios.post("/api/crm/create-user-lead", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: "CREATE_CRM_LEAD_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "CREATE_CRM_LEAD_REJECTED", payload: error });
  }
};

export const addCrmUser = (data, token) => {
  return {
    type: "ADD_CRM_USER",
    payload: Axios.post(`/api/crm/add-crm-user`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getCrmUser = (token, sort, paginate, search, page, manager) => {
  return {
    type: "GET_CRM_USERS",
    payload: Axios.get(
      `/api/crm/get-crm-users?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&manager=${manager}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err) => err),
  };
};

export const getSingleCrmUser = (id, token) => {
  return {
    type: "GET_SINGLE_CRM_USER",
    payload: Axios.get(`/api/crm/getsinglecrmuser/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const updateCrmUser = (data, token) => {
  return {
    type: "UPDATE_CRM_USER",
    payload: Axios.post(`/api/crm/update-crm-user`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};
