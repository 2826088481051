import React from 'react'
import MainWrapper from '../../MainWrapper'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import { useDispatch, useSelector } from 'react-redux'
import { getCampaign } from '../../../store/actions/CampaignActions'
import axios from "axios"
import {NotificationManager} from "react-notifications"
const AddUserTarget = () => {
    const [formData, setFormData] = useState({
        campaign: "",
        user: "",
        startDate: "",
        endDate: "",
        target: ""
    })
    const [crmUsers, setCrmUsers] = useState([])
    const [errors, setErrors] = useState({})

    const { getcampaigndropdown } = useSelector((state) => state.campaignReducer);
    const {
        auth: { token },
        permissions: { permission },
    } = useSelector((state) => state.auth);
    const history = useHistory()
    const dispatch = useDispatch()

    const getCrmUsers = (camp) => {
        axios(`/api/crm/get-campaign-user?campaign=${camp}`, {
            headers: { authorization: `Bearer ${token}` },
        })
            .then((res) => {
                setCrmUsers(res?.data?.campaignsUser)
                setFormData(prev => ({ ...prev, user: "" }))
            })
            .catch((error) => console.log(error));
    }


    const inputHndler = (e) => {
        e.persist()
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        if (e.target.name === "campaign") {
            getCrmUsers(e.target.value)
        }
    }

    const handleSubmit = () => {
        console.log("working");
        axios.post(`/api/crm/create-user-target`, {
            campaign_id: formData.campaign,
            crm_user_id: formData.user,
            start_date: formData.startDate,
            end_date: formData.endDate,
            target: formData.target,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(({ data }) =>{ 
                console.log(data)
                history.goBack()
                NotificationManager.success(data.success)
            })
            .catch(err => {
                console.error(err)
            console.log(err?.response);
            setErrors(err?.response?.data?.errors)
        })
    }
    useEffect(() => {
        dispatch(getCampaign())
    }, [])
console.log(errors);
    return (
        <MainWrapper>
            <Container className="mx-auto  mt-5"  >

                <Row  >
                    <Col sm={6} >
                        <FormGroup>
                            <Label>Campaign</Label>
                            <Input invalid={Boolean(errors.campaign_id)} type="select" value={formData.campaign} name="campaign" onChange={inputHndler}>
                                <option value="">Select</option>
                                {getcampaigndropdown?.activity?.map?.((item) => {
                                    return <option value={item.name} key={item.id}>
                                        {item.name}
                                    </option>
                                })}
                            </Input>
                            <span className="d-block text-danger"> {errors.campaign_id} </span>
                        </FormGroup>
                    </Col>
                    <Col sm={6} >
                        <FormGroup>
                            <Label>User</Label>
                            <Input type="select" invalid={Boolean(errors.crm_user_id)} value={formData.user} name="user" onChange={inputHndler} >
                                <option value="">Select</option>
                                {crmUsers.map((item) => {
                                    return <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                })}
                            </Input>
                            <span className="d-block text-danger"> {errors.crm_user_id} </span>
                        </FormGroup>
                    </Col>
                    <Col sm={6} >
                        <FormGroup>
                            <Label>Start Date</Label>
                            <Input type="date" invalid={Boolean(errors.start_date)} value={formData.startDate} name="startDate" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.start_date} </span>
                        </FormGroup>
                    </Col>
                    <Col sm={6} >
                        <FormGroup>
                            <Label>End Date</Label>
                            <Input type="date" invalid={Boolean(errors.end_date)} value={formData.endDate} name="endDate" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.end_date} </span>
                        </FormGroup>
                    </Col>
                    <Col sm={6} >
                        <FormGroup>
                            <Label>Target</Label>
                            <Input value={formData.target} invalid={Boolean(errors.target)} name="target" onChange={inputHndler} />
                            <span className="d-block text-danger"> {errors.target} </span>
                        </FormGroup>
                    </Col>

                    <Col sm={12}  >
                        <Button color="primary" className="mr-2" onClick={handleSubmit}> Submit </Button>
                        <Button onClick={() => history.goBack()} > Cancel </Button>
                    </Col>
                </Row>
            </Container>
        </MainWrapper>
    )
}

export default AddUserTarget