import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  workShopUser,
  workShopCreate,
  workShopList,
  workShopData,
  changeReducerState,
} from "../../store/actions/WorkShopActions";
import Select from "react-select";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { Redirect } from "react-router";
import { data } from "jquery";
import { getCourses, bookCourse } from "../../store/actions/CourseActions";
import axios from "axios";
const AddCourses = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mode, setMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentid, setPaymentId] = useState("");
  const [amount, setAmount] = useState(0);
  const [id, setId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState("");
  const [category, setCategory] = useState([]);
  const [cat, setCat] = useState("");
  const [courseop, setCourseop] = useState("");
  const [course, setCourse] = useState([]);
  const [sectorId, setSectorId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [singlecourse, setSingleCourse] = useState("");
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { courses, coursepurchased, error } = useSelector(
    (state) => state.courseReducer
  );

  const { token } = auth;

  useEffect(() => {
    axios
      .get(`/api/crm/course-category`)
      .then((res) => setCategory(res.data.coursecategory))
      .catch((error) => console.log(error));

    axios
      .get(`/api/crm/course-sector`)
      .then((res) => setSectors(res.data.sector))
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    setName(leadDetail?.full_name);
    setMobile(leadDetail?.mobile);
    setEmail(leadDetail?.email);
    setId(leadDetail?.id);
    setLeadId(leadDetail?.id);
  }, [leadDetail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("mode", mode);
    data.append("paymentType", paymentType);
    data.append("payment_id", paymentid);
    data.append("amount", amount ?? 0);
    data.append("user_id", id);
    data.append("leadId", leadId);
    data.append("type", "course_cart");
    data.append("project_id", singlecourse.value ?? "");
    data.append("project_name", singlecourse.label);
    data.append("receipt", file);
    dispatch(bookCourse(data, token));
  };

  useEffect(() => {
    setCourse(courses.courses);
  }, [courses]);

  const handleSector = (val) => {
    setSector(val);
    setSectorId(val.value);
  };

  const handleCategory = (val) => {
    setCat(val);
    setCategoryId(val.value);
  };

  const handleCourse = (val) => {
    setSingleCourse(val);
  };

  useEffect(() => {
    dispatch(getCourses(token, sectorId, categoryId));
  }, [sectorId, categoryId, token]);

  const courseoptions = course?.map((cours, index) => {
    return { label: cours?.title, value: cours?.id };
  });

  useMemo(() => {
    const getAmount = course.find((item) => item?.id == singlecourse.value);
    setAmount(getAmount?.amount);
  }, [singlecourse]);

  useEffect(() => {

    if (coursepurchased) {
      setModal(false);
      NotificationManager.success("Booked Successfully", "Success");
      setPaymentId("");
    }
  }, [coursepurchased]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Courses
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Buy Course
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              {/* <Col sm="6">
                <FormGroup>
                  <Label>Course Category</Label>
                  <Select
                    options={[...category]}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={handleCategory}
                    value={cat}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Sector</Label>
                  <Select
                    options={[...sectors]}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isSearchable={true}
                    onChange={handleSector}
                    value={sector}
                  />
                </FormGroup>
              </Col> */}

              <Col sm="6">
                <FormGroup>
                  <Label>Courses</Label>
                  <Select
                    options={courseoptions}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isSearchable={true}
                    onChange={handleCourse}
                    value={singlecourse}
                  />
                  <FormFeedback>
                      {!!error?.project_id ? error?.project_id[0] : ""}
                    </FormFeedback>
                </FormGroup>
              </Col>

              {amount ? (
                <Col sm="6">
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input value={amount} name="amount" onChange={(e)=>setAmount(e.target.value)} />
                    <FormFeedback>
                      {!!error?.amount ? error?.amount[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              ) : null}

              <Col sm="6">
                <FormGroup>
                  <Label>Name <span className="text-danger pl-1">*</span></Label>
                  <Input
                    value={name}
                    name="name"
                    onChange={({ target: { value } }) => setName(value)}
                  />
                  <FormFeedback>
                    {!!error?.name ? error?.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Mobile <span className="text-danger pl-1">*</span></Label>
                  <Input
                    name="mobile"
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                  <FormFeedback>
                    {!!error?.mobile ? error?.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Email <span className="text-danger pl-1">*</span></Label>
                  <Input
                    name="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <FormFeedback>
                    {!!error?.email ? error?.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    name="mode"
                    value={mode}
                    onChange={({ target: { value } }) => setMode(value)}
                  >
                    <option value="">Select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    type="select"
                    className="custom-select"
                    value={paymentType}
                    name="paymentType"
                    onChange={({ target: { value } }) => setPaymentType(value)}
                  >
                    <option>Select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label>Payment Id <span className="text-danger pl-1">*</span></Label>
                  <Input
                    invalid={!!error?.payment_id}
                    name="payment_id"
                    value={paymentid}
                    onChange={({ target: { value } }) => setPaymentId(value)}
                  />
                  <FormFeedback>
                    {!!error?.payment_id ? error?.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default AddCourses;
