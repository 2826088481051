import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";

function CallBackModal({ modal, setModal, details }) {
  const type = () => {
    if (details?.type == "franchise-lead") {
      return `/franchise-investor-detail/${details.lead_id}`;
    } else if (details?.type == "lead") {
      return `/lead/${details.lead_id}/details`;
    } else if (details?.type == "associate_lead") {
      return `/associate-detail/${details?.crm_lead_user?.id}`;
    } else return "/";
  };
  return (
    <div>
      <Modal toggle={() => setModal(!modal)} isOpen={modal}>
        <ModalHeader toggle={() => setModal(!modal)}>Alert</ModalHeader>
        <ModalBody>
          <h5>You Have New Call Back at this time</h5>

          <div className="d-flex justify-content-center">
            <Link to={type()}>
              <Button>Go to lead</Button>
            </Link>

            <Button
              color={"danger"}
              className="ml-4"
              onClick={() => setModal(!modal)}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CallBackModal;
