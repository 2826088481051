import React, { useMemo, Fragment, useState, useEffect,memo } from "react";
import { Link } from "react-router-dom";
import { getCallSingleDataById } from "../../store/actions/CallActions";
import { useSelector, useDispatch } from "react-redux";
const UserCallList = memo(({ leadDetail }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [callData, setCallData] = useState([]);

  const { auth } = useSelector(({ auth }) => auth);
  const { getCallById } = useSelector((state) => state.callingReducer);

  useEffect(() => {
    if (leadDetail) {
      dispatch(getCallSingleDataById(leadDetail?.id, auth.token));
    }
  }, [auth.token, leadDetail]);

  useEffect(() => {
    if (getCallById) {
      setCallData(getCallById.call);
    }
  }, [getCallById]);

  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-1">
            <Link
              className="card-title m-0 h5 text-white d-block p-2"
              to="#"
              data-toggle="collapse"
              data-target="#collapse-1"
              aria-expanded="true"
              aria-controls="collapse-1"
            >
              Call Activities ({callData?.length})
            </Link>
          </div>
          <div
            id="collapse-1"
            className="collapse show"
            aria-labelledby="heading-1"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.no</th>
                      <th>Call Purpose</th>
                      <th>Call Type</th>
                      <th>Call Start Time</th>
                      <th>Call Duration</th>
                      <th>Call Disposition</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      <Fragment>
                        {callData
                          ? callData.map((ca, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{ca?.call_purpose}</td>
                                <td>{ca?.call_type}</td>
                                <td>{ca?.start_time}</td>
                                <td>
                                  {ca?.call_duration_minutes} :{" "}
                                  {ca?.call_duration_second}
                                </td>
                                {ca?.remark ? <td>{ca?.remark}</td> : null}
                              </tr>
                            ))
                          : null}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default UserCallList;
