import Axios from "axios";

export const addActivity = (data, token) => {
  return {
    type: "ADD_ACTIVITY",
    payload: Axios.post(`/api/crm/add-activity`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const Activity = (leadid, token) => {
  return {
    type: "ACTIVITY",
    payload: Axios.get(`/api/crm/activity?leadid=${leadid}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const ActivityDelete = (ids, token) => {
  return {
    type: "ACTIVITY_DELETE",
    payload: Axios.post(`/api/crm/activityDelete`, ids, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const ActivitySlug = (data, token) => {
  return {
    type: "ACTIVITY_SLUG",
    payload: Axios.get(`/api/crm/activitybyslug?slug=${data}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const UpdateActivity = (data, token) => {
  return {
    type: "ACTIVITY_UPDATE",
    payload: Axios.post(`/api/crm/update-activity`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};
