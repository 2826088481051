import React, { useMemo, Fragment, memo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import EditIndustrialReport from "./EditIndustrialReport";
const IndustrialReport = memo(({ leadDetails, setWsStatus }) => {
  const boodedUser = useMemo(() => {
    if (leadDetails) return leadDetails;
  }, [leadDetails]);

  const bookedProjectReport = useMemo(() => {
    if (leadDetails) return leadDetails?.user_data_bank;
  }, [leadDetails]);

  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-3">
            <Link
              className="card-title m-0 h5 text-white d-block p-2 collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapse-5"
              aria-expanded="true"
              aria-controls="collapse-5"
            >
              Industrial Report ({_.size(bookedProjectReport)})
            </Link>
          </div>
          <div
            id="collapse-5"
            className="collapse"
            aria-labelledby="heading-4"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Transaction Id</th>

                      <th>Amount</th>
                      <th>Mode</th>
                      <th>Purchased Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookedProjectReport
                      ? bookedProjectReport.map((projectreport, i) => (
                          <tr key={Math.random()}>
                            <td>{i + 1}</td>
                            <td>
                              {projectreport?.detailsdatabank?.project_title}
                            </td>
                            <td>{projectreport?.payment_id}</td>
                            <td>{projectreport?.amount}</td>

                            <td>{projectreport?.payment_mode}</td>
                            <td>{projectreport?.update_at}</td>

                            {projectreport?.agent_id == null ? (
                              <td className="text-right">
                                <EditIndustrialReport
                                  leadDetail={leadDetails}
                                  setWsStatus={setWsStatus}
                                  projectId={projectreport?.orderItemId}
                                  bookedProjectReport={bookedProjectReport}
                                  item={projectreport}
                                />
                              </td>
                            ) : null}
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default IndustrialReport;
