import Axios from "axios";

export const addCampaign = (data, token) => {
  return {
    type: "ADD_CAMPAIGN",
    payload: Axios.post(`/api/crm/add-campaign`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const Campaign = (token, paginate, search, page, sort) => {
  return {
    type: "CAMPAIGN",
    payload: Axios.get(
      `/api/crm/getallcampaign?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err)=>err),
  };
};

export const CampaignDelete = (ids, token) => {
  return {
    type: "CAMPAIGN_DELETE",
    payload: Axios.post(`/api/crm/campaignDelete`, ids, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  };
};

export const getCampaignbyId = (id, token) => {
  return {
    type: "GET_CAMPAIGN_ID",
    payload: Axios.get(`/api/crm/campaign-to-by-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  };
};

export const UpdateCampaign = (data, token) => {
  return {
    type: "CAMPAIGN_UPDATE",
    payload: Axios.post(`/api/crm/update-campaign`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  };
};

export const getCampaign = (token) => {
  return {
    type: "GET_CAMPAIGN_DROPDOWN",
    payload: Axios.get("/api/crm/campaign"),
  };
};
