import Axios from "axios";

export const workShopList = (token, paginate, search, page, sort) => {
    return {
        type: "WORKSHOP_LIST",
        payload: Axios.get(`/api/crm/booked-workshop?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err),
    };
};

export const workShopCreate = (data, token) => async (dispatch) => {
    try{
        const resp = await Axios.post(`/api/crm/book-workshop`,data, {
            headers: { Authorization: `Bearer ${token}` },
          });
          dispatch({
            type: "WORKSHOP_CREATE_FULFILLED",
            payload: { message: "Added Successfully" },
          });

    } catch(errors) {
        dispatch({
            type: "WORKSHOP_CREATE_REJECTED",
            payload: errors,
          });
    }
}


export const workShopData = (token) => {
    return {
        type: "WORKSHOP",
        payload: Axios.get(`/api/crm/all-workshop`, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err)
    }
}

export const workShopUser = (token, mobile) => {
    return {
        type: "WORKSHOP_USER",
        payload: Axios.get(`/api/crm/checkworkshopuser?mobile=${mobile}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err)
    }
}

export const getWorkShopById = (token, workshop) => {
    return {
        type: "WORKSHOP_BY_ID",
        payload: Axios.get(`/api/crm/booked-workshopbyid/${workshop}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err)
    }
}

export const workShopEdit = (data, token, workshop) => {
    return {
        type: "WORKSHOP_EDIT",
        payload: Axios.post(`/api/crm/update-booked-workshop/${workshop}`, data, {
            headers: { Authorization: `Bearer ${token}` },
        }).catch((err)=>err),
    };
}

export const changeReducerState = () => {
    return {
        type: "CHANGE_WORKSHOP_STATE"
    }
}