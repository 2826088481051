import React, { Fragment } from "react";
import { Input } from "reactstrap";

const Subtable = (props) => {
  return (
    <Fragment>
      <tbody>
        <tr>
          <td>
            <div className="custom-checkbox custom-control">
              <Input
                id="first"
                type="checkbox"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="first"></label>
            </div>
          </td>
          <td>Cold</td>
          <td>Cold</td>
          <td>
            <span className="badge badge-success">A</span>
          </td>
          <td className="text-right">
            <a>
              <i
                className="fa fa-pencil-square-o mr-2 text-info"
                onClick={props.toggle}
              ></i>
            </a>
            <a href="#" className="text-danger">
              <i className="fa fa-trash-o"></i>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div className="custom-checkbox custom-control">
              <Input
                id="second"
                type="checkbox"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="second"></label>
            </div>
          </td>
          <td>Hot</td>
          <td>Hot</td>
          <td>
            <span className="badge badge-success">A</span>
          </td>
          <td className="text-right">
            <a href="#" className="mr-2 text-info">
              <i className="fa fa-pencil-square-o" onClick={props.toggle}></i>
            </a>
            <a href="#" className="text-danger">
              <i className="fa fa-trash-o"></i>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div className="custom-checkbox custom-control">
              <Input
                id="third"
                type="checkbox"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="third"></label>
            </div>
          </td>
          <td>Warm</td>
          <td>Warm</td>
          <td>
            <span className="badge badge-success">A</span>
          </td>
          <td className="text-right">
            <a
              href="#"
              className="mr-2 text-info"
              data-toggle="modal"
              data-target="#updateLeadRating"
            >
              <i className="fa fa-pencil-square-o" onClick={props.toggle}></i>
            </a>
            <a href="#" className="text-danger">
              <i className="fa fa-trash-o"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </Fragment>
  );
};

export default Subtable;
