import Axios from "axios";

export const getLeadsDetail = (token, paginate, page, sort) => {
  return {
    type: "GET_LEADS",
    payload: Axios.get(
      `/api/crm/converted-lead?paginate=${paginate}&page=${page}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
  };
};

// export const getDashboardCount = (token) => {
//   return {
//     type: "GET_LEADS_COUNT",
//     payload: Axios.get("/api/crm/get-dashboard-count", {
//       headers: { Authorization: `Bearer ${token}` },
//     }),
//   };
// };

export const getDashboardCount =
  (
    token,
    api = "get-user-dashboard-count",
    associateFilter,
    leadSource,
    purpose
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LEADS_COUNT_PENDING" });
      const response = await Axios.get(
        `/api/crm/${api}?start_date=${associateFilter.startDate}&end_date=${associateFilter.endDate}&lead_source=${leadSource}&purpose_of_meeting=${purpose}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "GET_LEADS_COUNT_FULFILLED", payload: response });
    } catch (error) {
      console.log(error);
    }
  };

export const DmtDashboardCount =
  (token, api = "consultant-reffer-count") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LEADS_COUNT_PENDING" });
      const response = await Axios.get(`/api/crm/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: "GET_LEADS_COUNT_FULFILLED", payload: response });
    } catch (error) {
      console.log(error);
    }
  };

export const SalesDashboardCount =
  (token, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: "GET_SALES_LEADS_COUNT_PENDING" });
      const response = await Axios.get(
        `/api/crm/get-dashboard-detail?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "GET_SALES_LEADS_COUNT_FULFILLED", payload: response });
    } catch (error) {
      console.log(error);
    }
  };

export const DashboardCountById =
  (token, toggle, date, fdate) => async (dispatch) => {
    try {
      dispatch({ type: "LEADS_COUNT_ID_PENDING" });
      const response = await Axios.get(
        `/api/crm/get-user-dashboard-detail?id=${toggle}&start_date=${date}&end_date=${fdate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "LEADS_COUNT_ID_FULFILLED", payload: response });
    } catch (error) {
      console.log(error);
    }
  };

export const ConsultantDashboardCount =
  (token, id, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: "GET_CONSULTANT_COUNT_PENDING" });
      const response = await Axios.get(
        `/api/crm/get-Consultant-data?consultantId=${id}&start=${startDate}&end=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "GET_CONSULTANT_COUNT_FULFILLED", payload: response });
    } catch (error) {
      console.log(error);
    }
  };

export const ConsultantUserCount = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USERCONSULTANT_COUNT_PENDING" });
    const response = await Axios.post(`/api/crm/get-Consultant-filter`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "GET_USERCONSULTANT_COUNT_FULFILLED", payload: response });
  } catch (error) {
    console.log(error);
  }
};

export const getServiceDashboardCount =
  (token, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: "GET_SERVICES_COUNT_PENDING" });
      const response = await Axios.get(
        `/api/crm/get-service-dashboard-count?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_SERVICES_COUNT_FULFILLED",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
