import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../../MainWrapper";
import SMS from "../../utils/SMS";
import Mail from "../../utils/Mail";
import {
  CustomInput,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { FiEdit, FiTrash } from "react-icons/fi";
import { FaPlus, FaList } from "react-icons/fa";
import Loader from "../../../layouts/Loader";
import { connect } from "react-redux";
import {
  createRole,
  roles,
  roleDestroy,
  roleUpdate,
} from "../../../store/actions/UserActions";
import { Redirect } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Pagination from "react-js-pagination";
import { set } from "lodash";
import { useSelector, useDispatch } from "react-redux";
const List = () => {
  const [roleId, setRoleId] = useState("");
  const [name, setName] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector(({ auth }) => auth);
  const {
    loading,
    error,
    createRoleStatus,
    role,
    rolesList,
    rolesLoading,
    rolesStatus,
    roleDestroyStatus,
  } = useSelector(({ user }) => user);

  const handleModal = (e) => setModal(!modal);
  const handleCloseEditModal = (e) => setEditModal(!editModal);
  const handleEditModal = (e, dat) => {
    setEditModal(!editModal);
    setRoleId(dat.id);
    setName(dat.name);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createRole({ name, token }));
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const data = {
      roleId,
      name,
      token,
    };
    dispatch(roleUpdate(data));
    NotificationManager.success("Role Updated Successfully", "Sccess");
    window.location.reload();
  };

  useEffect(() => {
    if (roleDestroyStatus) {
      NotificationManager.success("Role Deleted Successfully", "Sccess");
      setRefresh((refresh) => refresh + 1);
    }
  }, [roleDestroyStatus]);
  useEffect(() => {
    if (rolesStatus) {
      setRolesData(rolesList?.roles?.data);
      setFrom(rolesList?.roles?.from);
      setTo(rolesList?.roles?.to);
      setTotal(rolesList?.roles?.total);
      setPerPage(rolesList?.roles?.per_page);
    }
  }, [rolesStatus]);
  useEffect(() => {
    dispatch(roles(token, sort, page, search, paginate));
  }, [token, sort, page, search, paginate]);
  useEffect(() => {
    if (createRoleStatus) {
      NotificationManager.success("Role Created Successfully", "Sccess");
      setModal(false);
      setName("");
      setRolesData([...rolesData, role.role]);
    }
  }, [createRoleStatus]);
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        rolesData.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      dispatch(roleDestroy(token, id));
      setRolesData(rolesData.filter((f) => f.id !== id));
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    setSearch(e.target.value);
  };
  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      dispatch(roleDestroy(token, deleteIds));
    }
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h4>Roles</h4>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <input
                              type="text"
                              id="search"
                              className="form-control search-input"
                              placeholder="Search..."
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Subject</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Assigned To</label>
                              <div className="form-control border-0 p-0">
                                <select className="select2-example">
                                  <option>Select</option>
                                  <option value="Option 1">Option 1</option>
                                  <option value="Option 2">Option 2</option>
                                  <option value="Option 3">Option 3</option>
                                  <option value="Option 4">Option 4</option>
                                  <option value="Option 5">Option 5</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Contact</label>
                              <div className="form-control border-0 p-0">
                                <select className="select2-example">
                                  <option>Select</option>
                                  <option value="Option 1">Option 1</option>
                                  <option value="Option 2">Option 2</option>
                                  <option value="Option 3">Option 3</option>
                                  <option value="Option 4">Option 4</option>
                                  <option value="Option 5">Option 5</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="invisible">Hidden</label>
                              <div className="form-control border-0 p-0">
                                <select className="select2-example">
                                  <option>Select</option>
                                  <option value="Option 1">Option 1</option>
                                  <option value="Option 2">Option 2</option>
                                  <option value="Option 3">Option 3</option>
                                  <option value="Option 4">Option 4</option>
                                  <option value="Option 5">Option 5</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            borderTop: "1px #ccc solid",
                            paddingTop: "10px",
                          }}
                        >
                          <div
                            className="col-md-6"
                            style={{ position: "relative" }}
                          >
                            <select
                              className="form-control custome-dropdown-menu-adv radius-3"
                              style={{ maxWidth: "150px" }}
                            >
                              <option value="Option 1">Option 1</option>
                              <option value="Option 2">Option 2</option>
                              <option value="Option 3">Option 3</option>
                              <option value="Option 4">Option 4</option>
                              <option value="Option 5">Option 5</option>
                            </select>
                          </div>
                          <div className="col-md-6 text-right">
                            <button
                              type="button"
                              className="btn btn-small btn-secondary"
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn-small btn-secondary"
                              style={{ margin: "0px 5px" }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              className="btn btn-small btn-secondary advance-search-close"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        <button
                          type="button"
                          className="btn btn-secondary text-nowrap btn-sm p-1 dropdown-toggle"
                          aria-expanded="false"
                          onClick={handleModal}
                        >
                          <i className="fa fa-plus" />
                          &nbsp;&nbsp;Add New
                          <span className="caret" />
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary text-nowrap btn-sm p-1 dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-list" />
                          &nbsp;&nbsp;Activity Tools
                          <span className="caret" />
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu" role="menu">
                          <li className="pt-1 pb-1 pr-2 pl-2 w-100">
                            <a href="#">Call Purpose</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-card-header">
                    <SMS />
                    <Mail />

                    <button
                      className="btn btn-secondary btn-sm mr-1"
                      data-toggle="modal"
                      data-target="#massUpdate"
                    >
                      <i className="fa fa-list pr-1" /> Mass Update
                    </button>
                    <button
                      className="btn btn-secondary btn-sm mr-1"
                      title="Refresh"
                      onClick={() => setRefresh((refresh) => refresh + 1)}
                    >
                      <i className="fa fa-refresh" />
                    </button>
                    <div className="box-tools pull-right">
                      <select
                        id="ddlSortBy"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                        className="custome-select small-select mr-1"
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1 ml-1"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    onChange={(e) => handleMultiSelect(e)}
                                  />
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Type</th>
                                <th className="font-weight-bold">Status</th>
                                <th className="text-right font-weight-bold pr-4">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody class="d-table-body">
                              {rolesLoading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={rolesLoading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {rolesData
                                    ? rolesData.map((rs) => (
                                        <tr key={Math.random()}>
                                          <td className="pl-4">
                                            <CustomInput
                                              id={`default-${rs.id}`}
                                              type="checkbox"
                                              checked={deleteIds.some(
                                                (d) => d === rs.id
                                              )}
                                              onChange={(e) =>
                                                handleSingleSelect(e, rs.id)
                                              }
                                            />
                                          </td>
                                          <td>{rs.name}</td>
                                          <td>{rs.type}</td>
                                          <td>
                                            <span
                                              className={`badge ${
                                                rs.status === "Active"
                                                  ? "badge-success"
                                                  : "badge-danger"
                                              } `}
                                            >
                                              {rs.status === "Active"
                                                ? "Active"
                                                : "Inactive"}
                                            </span>
                                          </td>
                                          <td className="text-right pr-4">
                                            <a
                                              href="#"
                                              className="mr-2 text-info"
                                              onClick={(e) =>
                                                handleEditModal(e, rs)
                                              }
                                            >
                                              <FiEdit />
                                            </a>
                                            <a
                                              href="#"
                                              className="text-danger"
                                              onClick={(e) =>
                                                handleOneDelete(e, rs.id)
                                              }
                                            >
                                              <FiTrash />
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer p-2">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span className="pl-3">
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage) ?? 0}
                        totalItemsCount={total ?? 0}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                        className="m-0 pr-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal isOpen={modal} toggle={handleModal}>
          <ModalHeader toggle={handleModal}>Create Role</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label for="name" className="text-center col-md-3">
                      Role
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      className="col-md-9"
                      onChange={(e) => setName(e.target.value)}
                      invalid={!!error.name}
                    />
                    <FormFeedback className="text-center">
                      {error.name}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <div className="text-right">
                    <Button color="success" size="sm">
                      {loading ? <FaSpinner className="fa-spin" /> : "Submit"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        <Modal isOpen={editModal} toggle={handleCloseEditModal}>
          <ModalHeader toggle={handleCloseEditModal}>Update Role</ModalHeader>
          <ModalBody>
            <form onSubmit={handleEditSubmit}>
              <Row>
                <Col md={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="align-items-center col-md-3 d-flex justify-content-center"
                    >
                      Role
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      className="col-md-8 ml-4"
                      onChange={(e) => setName(e.target.value)}
                      invalid={!!error.name}
                    />
                    <FormFeedback className="text-center">
                      {error.name}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <div className="text-right mr-3">
                    <Button color="success" size="sm">
                      {loading ? <FaSpinner className="fa-spin " /> : "Submit"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      </MainWrapper>
    </Fragment>
  );
};

export default List;
