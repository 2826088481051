/* eslint-disable default-case */
import _ from "lodash";
import { encrypted, decrypted } from "../../components/utils/Helper";
import {
  PERMISSIONS_LIST_PENDING,
  PERMISSIONS_LIST_FULFILLED,
  PERMISSIONS_LIST_REJECTED,
} from "../../components/utils/constant";
const authData = localStorage.getItem("iid-crm");
const campaign = localStorage.getItem("campaign-details");

const initialState = {
  auth: !!authData ? decrypted(authData) : {},
  isAuthenticated: !!authData,
  permissions: [],
  campaigndetails: campaign,
  error: {},
  loading: false,
  message:""
};

const authReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case "LOGIN_PENDING":
      case "CHANGE_PASSWORD_PENDING":
    case PERMISSIONS_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "LOGIN_FULFILLED":
      
      state = {
        ...state,
        loading: false,
        auth: _.get(payload, "data", {}),
        isAuthenticated: true,
      };
      localStorage.setItem("iid-crm", encrypted(state.auth));
      localStorage.removeItem("iid-tele-call");
      break;

      case "CHANGE_PASSWORD_FULFILLED":
        console.log(payload,"payload")
        localStorage.removeItem("iid-crm");
    return{...state,
      loading: false,
      isAuthenticated: false,
message: payload.data.message   
   }
        
      break;
    case PERMISSIONS_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        permissions: _.get(payload, "data", {}),
      };
      break;
    case "LOGIN_REJECTED":
      case "CHANGE_PASSWORD_REJECTED":
      return {
        ...state,
        loading: false,
        status: false,
        error: payload.response.data?.errors || {},
      };
    case PERMISSIONS_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        status: false,
        error: _.get(payload.response.data, "message", {}),
      };
      // const {
      //   payload: {
      //     response: { status, data },
      //   },
      // } = action;

      // if (status === 422) {
      //   state = {
      //     ...state,
      //     loading: false,
      //     error: _.get(data, "errors", {}),
      //   };
      // } else {
      //   state = {
      //     ...state,
      //     loading: false,
      //     error: _.get(data, "message", {}),
      //   };
      // }
      break;

    case "SET_CAMPAIGN":
      state = {
        ...state,
        campaigndetails: payload,
      };
      localStorage.setItem("campaign-details", state.campaigndetails);
      break;
    case "LOGOUT":
      state = {
        ...state,
        isAuthenticated: false,
        auth: {},
      };
      localStorage.removeItem("iid-tele-call");
      localStorage.removeItem("newMobile");
      localStorage.removeItem("iid-crm");
      localStorage.removeItem("campaign-details");
      break;
  }

  return state;
};

export default authReducer;
