import React, { Fragment, useState } from "react";
import MainWrapper from "../MainWrapper";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../layouts/Loader";
import _ from "lodash";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const LeadDetails = ({}) => {
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleClick = (e) => {
    setLoading(true);
    Axios.get(`/api/crm/get-lead?search=${search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLoading(false);
        setData(res?.data?.leads);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Lead</h4>
                    <div className="d-flex text-right right-side-links">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="c-card-top-inputs c-card-top-search">
                            <div className="input-group">
                              <Input
                                placeholder="Search here..."
                                value={search}
                                onChange={handleSearch}
                                className="c-form-control-serch"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-secondary btn-sm"
                                  type="button"
                                  onClick={handleClick}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="table-responsive">
                  <table className="table table-hover mb-0 table-sm small">
                    <thead className="thead-light">
                      <tr>
                        <th className="font-weight-bold">Full Name</th>
                        <th className="font-weight-bold">Mobile</th>
                        <th className="font-weight-bold">Email</th>
                        <th className="font-weight-bold">Bussiness Name</th>
                        <th className="font-weight-bold">Sector</th>
                        <th className="font-weight-bold">Industry</th>
                        <th className="font-weight-bold">Source</th>
                        <th className="font-weight-bold">Lead Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <Fragment>
                          <tr>
                            <td colSpan={7}>
                              <Loader loading={loading} />
                            </td>
                          </tr>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {data
                            ? data.map((leadsl, i) => (
                                <tr key={Math.random()}>
                                  <td>
                                    <Link to={`/lead/${leadsl.id}/details`}>
                                      {leadsl.full_name}
                                    </Link>
                                  </td>
                                  <td>{leadsl.mobile}</td>
                                  <td>{leadsl.email}</td>
                                  <td>{leadsl.company}</td>
                                  <td>
                                    {leadsl?.lead_sector
                                      ? leadsl?.lead_sector?.name
                                      : ""}
                                  </td>
                                  <td>
                                    {leadsl?.lead_industry
                                      ? leadsl?.lead_industry?.name
                                      : ""}
                                  </td>
                                  <td>
                                    {leadsl.lead_source
                                      ? leadsl.lead_source.name
                                      : ""}
                                  </td>
                                  <td>{leadsl.created_at}</td>
                                </tr>
                              ))
                            : null}
                        </Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default LeadDetails;
