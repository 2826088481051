import _ from "lodash";

const initialState = {
  users: [],
  crmusersstatus: false,
  crmusers: {},
  crmsingleuser: {},
  crmadduserstatus: false,
  roless: {},
  error: {},
  crmuser: {},
  loading: false,
  mssg: "",
};

const crmUserReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case "USER_DETAIL_BY_ID_PENDING":
      return {
        ...state,
        loading: true,
        error: {},
      };
    case "USER_DETAIL_BY_ID_FULFILLED":
      return {
        ...state,
        loading: false,
        users: _.get(payload.data, "user", {}),
      };
    case "USER_DETAIL_BY_ID_REJECTED":
      return {
        ...state,
        loading: false,
        users: _.get(payload, "data", {}),
      };

    case "ADD_CRM_USER_PENDING":
    case "CREATE_CRM_LEAD":
      return {
        ...state,
        loading: true,
        error: {},
      };
    case "ADD_CRM_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        crmadduserstatus: payload?.response?.status === 422 ? false : true,
        crmuser: _.get(payload, "data", {}),
        error: _.get(payload?.response?.data, "errors", {}),
      };

    case "CREATE_CRM_LEAD_FULFILLED":
      return { ...state, mssg: "Your Form has Been Submitted", loading: false };
    case "ADD_CRM_USER_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          loading: false,
          crmadduserstatus: false,
          // error: _.get(data, "errors", {}),
          error: _.get(payload.response.data.errors, "errors", {}),
        };
      } else {
        return {
          ...state,
          loading: false,
          crmadduserstatus: false,
          error: _.get(data, "message", ""),
        };
      }
    case "UPDATE_CRM_USER_PENDING":
      return {
        ...state,
        loading: true,
        error: {},
      };
    case "UPDATE_CRM_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        crmupdateuserstatus: true,
        crmuser: _.get(payload, "data", {}),
      };
    case "UPDATE_CRM_USER_REJECTED":
    case "CREATE_CRM_LEAD_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          loading: false,
          crmupdateuserstatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        return {
          ...state,
          loading: false,
          crmupdateuserstatus: false,
          error: _.get(data, "message", ""),
        };
      }
    case "GET_CRM_USERS_FULFILLED":
      return {
        ...state,
        loading: false,
        crmusersstatus: true,
        crmusers: _.get(payload, "data", {}),
      };
    case "GET_SINGLE_CRM_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        crmsingleuser: _.get(payload, "data", {}),
      };

    case "RESET_MESSAGE":
      return { ...state, mssg: action.payload };
    case "RESET_ADD_USER_STATUS":
      return {
        ...state,
        crmadduserstatus: action.payload,
      };

    default:
      return state;
  }
};

export default crmUserReducer;
