import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { Table, Spinner, FormGroup, Label, Input } from "reactstrap";
import PieChart from "../Chart/PieChart";
import axios from "axios";
import Loader from "../../layouts/Loader";
const SourceWiseReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  useEffect(() => {
    {
      setPieChartData([
        { label: "Membership", data: 33 },
        { label: "Webinar", data: 37 },
        { label: "PPR", data: 26 },
        { label: "Course", data: 1 },
      ]);
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-source-wise-data?startDate=${filters.startDate}&endDate=${filters.endDate}`
      )
      .then((res) => {
        setData(res?.data?.getLists);
        setLoading(false);
      })
      .catch((err) => console.log(err.response));
  }, [filters.endDate, filters.startDate]);
  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getCount = (arr) => {
    console.log(arr);
    return arr.filter((item) => item?.activity.length).length;
  };

  return (
    <Fragment>
      <MainWrapper>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-3 offset-md-6">
              <FormGroup>
                <Label>Start Date</Label>
                <Input
                  type="date"
                  name="startDate"
                  value={filters.startDate}
                  onChange={inputHandler}
                />
              </FormGroup>
            </div>

            <div className="col-md-3">
              <FormGroup>
                <Label>End Date</Label>
                <Input
                  type="date"
                  name="endDate"
                  value={filters.endDate}
                  onChange={inputHandler}
                />
              </FormGroup>
            </div>
            <div className="col-md-12">
              <Table bordered>
                <thead className="green">
                  <tr>
                    <th>Sr.No</th>
                    <th>General Sources</th>
                    <th>Lead Count</th>
                    <th>Activity Done on Leads</th>
                    <th>Contribution</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <Fragment>
                      <tr>
                        <td colSpan={7}>
                          <Loader loading={loading} />
                        </td>
                      </tr>
                    </Fragment>
                  ) : (
                    (data ? data : [])?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <th scope="row" key={item?.id}>
                            {item?.name}
                          </th>
                          <td>{item?.crm_lead?.length}</td>
                          <td>{getCount(item?.crm_lead)}</td>
                          <td>
                            {item?.crm_lead.length > 0
                              ? (
                                  (getCount(item?.crm_lead) /
                                    item?.crm_lead?.length) *
                                  100
                                ).toFixed(2)
                              : null}
                            %
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
            {/* <div className="col-md-4">
                            
                        </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <PieChart
                pieChartData={pieChartData}
                colors={[
                  "rgb(255, 99, 132)",
                  "rgb(54, 162, 235)",
                  "rgb(255,140,0)",
                  "rgb(0,206,209)",
                ]}
              />
            </div>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default SourceWiseReport;
