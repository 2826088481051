import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import MainWrapper from "../MainWrapper";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Redirect } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  customizeProjectReport,
  changeReducerState,
} from "../../store/actions/ProjectReportActions";

import moment from "moment";
import _ from "lodash";
import Creatable, { components } from "react-select";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const addCustomizeProjectReport = memo(({ leadDetail, setWsStatus }) => {
  const [fields, setFields] = useState({
    name: leadDetail ? leadDetail?.full_name : leadDetail?.name,
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    pincode: leadDetail?.pincode ?? "",
    leadId: leadDetail?.id ?? "",
  });
  const [project, setProject] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({});
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [projectreport, setProjectreport] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);

  const {
    projectReports,
    errors,
    createStatus,
    users: { user },
    customizedprojectReportStatus,
    success,
  } = useSelector((state) => state.projectReport);
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Add Cutsomized Project Report",
      description: "Add Cutsomized Project Report Sale",
    };
    if (customizedprojectReportStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [customizedprojectReportStatus]);

  useMemo(() => projectReports, [projectReports]);

  useEffect(() => {
    axios
      .get(`/api/crm/sub-categories/list?category=${3}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);

  useMemo(() => {
    if (success?.message) {
      NotificationManager.success(success?.message);
      dispatch({ type: "RESET_SUCCESS", payload: false });
      setWsStatus((prev) => !prev);
      setModal(false);
    }
  }, [success]);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/subsectordata?industry=${e.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };

  const handleProjectReport = (e) => {
    setProjectreport(e);
    axios
      .get(`/api/crm/projectreport-list/${subCategory.value}/${e.value}`)
      .then((res) => {
        setProject(res?.data?.projectReport);
      })
      .catch((err) => console.log(err));
  };

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = project?.find(
        (v) => v?.projectreports?.id == fields?.selectedMemberShip
      );
      return selected;
    }
  }, [fields?.selectedMemberShip]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  // const validationForm = () => {
  //   let formIsValid = true;
  //   setError(error);
  //   return formIsValid;
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    // const data = {
    //   project_id: 1,
    //   user_id: fields?.id,
    //   name: fields?.name,
    //   email: fields?.email,
    //   mobile: fields?.mobile,
    //   payment_id: fields?.payment_id,
    //   report_price: fields?.report_price,
    //   project_name: selectedMemberShip?.projectreports?.title,
    //   pincode: fields?.pincode,
    //   mode: fields?.mode,
    //   paymentType: fields?.paymentType,
    //   leadId: leadDetail?.id ?? "",
    //   engaged: 1,
    // };
    const formData = new FormData();

    formData.append("payment_img", file);

    formData.append("project_id", fields?.selectedMemberShip == undefined ? "" : fields?.selectedMemberShip);
    formData.append("user_id", fields?.id);
    formData.append("name", fields?.name);
    formData.append("email", fields?.email);
    formData.append("mobile", fields?.mobile);
    formData.append("payment_id", fields?.payment_id == undefined ? "" : fields?.payment_id);
    formData.append("project_name", selectedMemberShip?.projectreports?.title);
    formData.append("pincode", fields?.pincode);
    formData.append("mode", fields?.mode);
    formData.append("paymentType", fields?.paymentType);
    formData.append("leadId", leadDetail?.id ?? "");
    formData.append("engaged", 1);
    formData.append("price", fields?.report_price);
    dispatch(customizeProjectReport(formData, token));
    setError(error);
  };
  useEffect(() => {
    setFields({
      ...fields,
      name: leadDetail ? leadDetail?.full_name : leadDetail?.name,
      mobile: leadDetail?.mobile ?? "",
      email: leadDetail?.email ?? "",
    });
  }, [leadDetail, modal]);

  // const isValidNewOption = (inputValue, selectValue) =>
  //   inputValue.length > 0 &&
  //   selectValue.length < parseInt(selectedMemberShip?.industries);
  useEffect(() => {
    if (createStatus) {
      setWsStatus((prev) => !prev);
      dispatch(changeReducerState());
    }
  }, [createStatus]);

  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Customize Report
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Customize Project Report
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="sector">Sector</Label>

                  <Creatable
                    options={[...sectors]}
                    onChange={handleSector}
                    value={subCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errors?.errors?.sector?.[0]}
                </FormFeedback>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="industry">Industry</Label>
                  <Creatable
                    options={[...industry]}
                    onChange={(e) => {
                      setSubSubCategory(e);
                      handleProjectReport(e);
                    }}
                    // onChange={handleProjectReport}
                    value={subSubCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errors?.errors?.industry}
                </FormFeedback>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">Project Report <span className="text-danger">*</span></Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.project_id}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option>select</option>
                    {_.size(project)
                      ? _.map(project, (mem) => (
                        <option
                          value={mem.projectreports?.id}
                          key={mem.projectreports?.id}
                        >
                          {mem.projectreports?.title}
                        </option>
                      ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                  <FormFeedback>
                    {!!errors?.project_id
                      ? errors?.project_id[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors?.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors?.mobile ? errors?.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors?.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors?.name ? errors?.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors?.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors?.email ? errors?.email?.[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Choose Pay Now or Pay Later</Label>
                  <Input
                    type="select"
                    invalid={!!errors?.mode}
                    id="mode"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="pay-now">Pay Now</option>
                    <option value="pay-later">Pay Later</option>
                  </Input>
                  <FormFeedback>
                    {!!errors?.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              {paymentType == "pay-now" ? (
                <Col sm={6}>
                  <FormGroup>
                    <Label for="report_price">
                      Customized Report Price For
                    </Label>
                    <Input
                      type="select"
                      className="custom-select"
                      id="report_price"
                      invalid={!!errors?.report_price}
                      value={fields?.report_price ? fields?.report_price : ""}
                      onChange={handleChange}
                    >
                      <option>select</option>
                      <option value="5310">
                        Project upto Rs. 1.00 crores-Rs. 5900.00
                      </option>
                      <option value="59000">
                        Ethanol project report rupees 59000
                      </option>
                      <option value="7965">
                        Project from Rs. 1.00 crores to 3.00 crores - Rs.
                        8850.00
                      </option>
                      <option value="10620">
                        Project from Rs. 3.00 crores to Rs. 5.00 crores - Rs.
                        11800.00
                      </option>
                      <option value="15930">
                        Project from Rs. 5.00 crores to 10.00 cores - Rs.
                        17700.00
                      </option>
                      <option value="0">
                        For Project Cost above Rs. 10.00 crores charges as per
                        discussion
                      </option>
                    </Input>
                    <FormFeedback>
                      {!!errors?.report_price ? errors?.report_price?.[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              ) : null}

              {paymentType == "pay-later" ? (
                <Col sm={6}>
                  <FormGroup>
                    <Label for="report_price">
                      Customized Report Price For
                    </Label>
                    <Input
                      type="select"
                      className="custom-select"
                      id="report_price"
                      invalid={!!errors?.report_price}
                      value={fields?.report_price ? fields?.report_price : ""}
                      onChange={handleChange}
                    >
                      <option>select</option>
                      <option value="5900">
                        Project upto Rs. 1.00 crores-Rs. 5900.00
                      </option>
                      <option value="59000">
                        Ethanol project report rupees 59000
                      </option>
                      <option value="8850">
                        Project from Rs. 1.00 crores to 3.00 crores - Rs.
                        8850.00
                      </option>
                      <option value="11800">
                        Project from Rs. 3.00 crores to Rs. 5.00 crores - Rs.
                        11800.00
                      </option>
                      <option value="17700">
                        Project from Rs. 5.00 crores to 10.00 cores - Rs.
                        17700.00
                      </option>
                      <option value="0">
                        For Project Cost above Rs. 10.00 crores charges as per
                        discussion
                      </option>
                    </Input>
                    <FormFeedback>
                      {!!errors?.report_price ? errors?.report_price?.[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              ) : null}

              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors?.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors?.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors?.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>

                  <FormFeedback>
                    {!!errors?.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="payment_id">Payment Id <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors?.payment_id}
                    id="payment_id"
                    value={fields.payment_id ? fields.payment_id : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors?.payment_id ? errors?.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
              <Col sm="10">
                <div className="text-center mb-3">
                  <Button
                    color="secondary"
                    onClick={() => setModal(false)}
                    className="mr-2"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!_.isEmpty(user?.plan_detail)}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default addCustomizeProjectReport;
