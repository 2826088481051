import _ from "lodash";

const initialState = {
  addCampaign: [],
  addCampaignLoading: false,
  addCampaignStatus: false,
  loading: false,
  error: {},

  campaignStatus: false,
  campaignDeleteStatus: false,
  campaignUpdateStatus: false,
  campaignUpdateLoading: false,
  campaign: [],
  campaignDelete: [],
  getcampaignidStatus: false,
  getcampaignbyid: [],
  campaignUpdate: [],
  getcampaigndropdownstatus: false,
  getcampaigndropdown: [],
};

const campaignReducer = (state = initialState, actions) => {
  const { payload } = actions;
  // eslint-disable-next-line default-case
  switch (actions.type) {
    case "ADD_CAMPAIGN_PENDING":
      state = {
        ...state,
        loading: true,
        addCampaignStatus: false,
        error: {},
      };
      break;
    case "ADD_CAMPAIGN_FULFILLED":
      state = {
        ...state,
        loading: false,
        addCampaignStatus: true,
        addCampaign: _.get(payload, "data", {}),
      };
      break;
    case "ADD_CAMPAIGN_REJECTED":
      state = {
        ...state,
        loading: false,
        addCampaignStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "CAMPAIGN_PENDING":
      state = {
        ...state,

        loading: true,
        campaignStatus: false,
        campaignDeleteStatus: false,
        campaignUpdateStatus: false,
        campaignUpdateLoading: false,
        error: {},
      };
      break;
    case "CAMPAIGN_FULFILLED":
      state = {
        ...state,
        loading: false,
        campaignStatus: true,
        campaign: _.get(payload, "data", {}),
      };
      break;
    case "CAMPAIGN_REJECTED":
      state = {
        ...state,
        loading: false,
        campaignStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "CAMPAIGN_DELETE_PENDING":
      state = {
        ...state,
        campaignDeleteStatus: false,
        error: {},
      };
      break;
    case "CAMPAIGN_DELETE_FULFILLED":
      state = {
        ...state,
        campaignDeleteStatus: true,
        campaignDelete: _.get(payload, "data", {}),
      };
      break;
    case "CAMPAIGN_DELETE_REJECTED":
      state = {
        ...state,
        campaignDeleteStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "GET_CAMPAIGN_ID_PENDING":
      state = {
        ...state,
        getcampaignidStatus: false,
        error: {},
      };
      break;
    case "GET_CAMPAIGN_ID_FULFILLED":
      state = {
        ...state,
        getcampaignidStatus: true,
        getcampaignbyid: _.get(payload, "data", {}),
      };
      break;
    case "GET_CAMPAIGN_ID_REJECTED":
      state = {
        ...state,
        getcampaignidStatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
    case "CAMPAIGN_UPDATE_PENDING":
      state = {
        ...state,
        campaignUpdateStatus: false,
        campaignUpdateLoading: false,
        error: {},
      };
      break;
    case "CAMPAIGN_UPDATE_FULFILLED":
      state = {
        ...state,

        campaignUpdateStatus: true,
        campaignUpdateLoading: true,
        campaignUpdate: _.get(payload, "data", {}),
      };
      break;
    case "CAMPAIGN_UPDATE_REJECTED":
      if (payload.response.status === 422) {
        state = {
          ...state,
          campaignUpdateStatus: false,
          campaignUpdateLoading: false,
          error: _.get(payload.response.data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          activityUpdateLoading: false,
          activityUpdateStatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }
      break;
    case "GET_CAMPAIGN_DROPDOWN_PENDING":
      state = {
        ...state,
        getcampaigndropdownstatus: false,
        error: {},
      };
      break;
    case "GET_CAMPAIGN_DROPDOWN_FULFILLED":
      state = {
        ...state,
        getcampaigndropdownstatus: true,
        getcampaigndropdown: _.get(payload, "data", {}),
      };
      break;
    case "GET_CAMPAIGN_DROPDOWN_REJECTED":
      state = {
        ...state,
        getcampaigndropdownstatus: false,
        error: _.get(payload.response.data, "message", {}),
      };
      break;
  }
  return state;
};

export default campaignReducer;
