import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Table, Spinner, FormGroup, Label } from "reactstrap";
import Loader from "../../layouts/Loader";
import Axios from "axios";
import { Input } from "reactstrap";
import moment from "moment";
const AgentReport = () => {
  const { auth } = useSelector(({ auth }) => auth);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignn, setCampaignn] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [filters, setFilters] = useState({
    startDate: "2023-08-08",
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `/api/crm/get-crmuser-target-report?startDate=${
        filters.startDate
      }&endDate=${filters.endDate}&campaign=${campaign?.label || ""}
      
      `,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
      .then((res) => {
        setReport(res.data.getusers);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [auth, filters.startDate, filters.endDate, campaign]);

  useEffect(() => {
    Axios.get(`/api/crm/get-campaign-list`, {
      headers: { authorization: `Bearer ${auth?.token}` },
    })
      .then((res) => setCampaignn(res.data?.campaigns))
      .catch((error) => console.log(error));
  }, [auth?.token]);

  const getSum = (arr) => {
    return arr.reduce(
      (prev, curr) => (prev += curr.amount ? parseInt(curr.amount) : 0),
      0
    );
  };

  const getSumWorkshop = (arr) => {
    return arr.reduce(
      (prev, curr) => (prev += curr.amount ? parseInt(curr.amount) : 0),
      0
    );
  };

  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Fragment>
      <MainWrapper>
        <div className="container">
          <div className=" mt-5">
            <div className="row mb-4">
              <div className="col-md-3">
                <h3 className="mb-0">Agent Wise Report</h3>
              </div>

              <div className="col-md-3 offset-md-3">
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label>End Date</Label>
                  <Input
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <Label>Select Campaign</Label>
                {/* <Input
                  type="select"
                  className="custom-select"
                  value={campaign}
                  onChange={({ target }) => setCampaign(target.value)}
                >
                  <option value="">select</option>
                  <option value="workshop">Workshop</option>
                  <option value="IID Sale">IID-Sale</option>
                </Input> */}
                <Select
                  id="paginate"
                  onChange={(e) => setCampaign(e)}
                  value={campaign}
                  className="react-select-container col-md-12 p-0"
                  classNamePrefix="react-select"
                  options={campaignn?.map?.((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                />
              </div>
            </div>
            <Table bordered>
              <thead>
                <tr className="table-primary">
                  <th>S.No</th>
                  <th>Agent Name</th>
                  <th>Target</th>
                  <th>Grand Total</th>
                  <th>% Achieved</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="mx-auto mt-3">
                    <Spinner>Loading..</Spinner>
                  </div>
                ) : (
                  <>
                    {report?.map((re, index) => (
                      <tr key={re?.id}>
                        <th scope="row">{index + 1}</th>

                        <td>{re?.name}</td>
                        <td>{re?.user_target?.target || 0}</td>
                        <td>
                          {getSum(re?.agent_payment) +
                            getSumWorkshop(re?.agent_work_payment)}
                        </td>
                        <td>
                          {getSum(re.agent_payment) && re?.user_target?.target
                            ? (
                                (getSum(re.agent_payment) /
                                  (re?.user_target?.target
                                    ? parseInt(re?.user_target?.target)
                                    : 0)) *
                                100
                              ).toFixed(2)
                            : 0}
                          %
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default AgentReport;
