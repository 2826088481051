import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Loader from "../../layouts/Loader";
import { FiEdit, FiTrash, FiTablet } from "react-icons/fi";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useLocation } from "react-router-dom";
import { FiChevronDown, FiEye } from "react-icons/fi";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Button,
  Row,
  Col,
  Label,
  ButtonGroup,
  FormFeedback,
} from "reactstrap";
import moment from "moment";
import { CSVLink } from "react-csv";
import axios from "axios";
import Note from "./subComponent/Note";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import { eventInsertHandler } from "../../store/actions/TaskActions";
import { useHistory, useParams } from "react-router";
const FranchiseInvestorList = () => {
  const [activity, setActivity] = useState("Franchise");
  const [subject, setSubject] = useState("Call Back");
  const dispatch = useDispatch();
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [frenchiseList, setFranchiseList] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [leadsource, setLeadsource] = useState("");
  const [assignDate, setAssignDate] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [endCreateDate, setEndCreateDate] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingg, setLoading] = useState(true);
  const [fields, setFields] = useState("");
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [id, setId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [assignTo, setAssignTo] = useState("");
  const [assign, setAssign] = useState(" ");
  const [assignn, setAssignn] = useState(" ");
  const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [franchiseteamList, setFranchiseTeamList] = useState([]);
  const [brand, setBrand] = useState("");
  const [assignEndDate, setAssignEndDate] = useState("");
  const [franchiseData, setFranchiseData] = useState([]);
  const [modalId, setModalId] = useState("");
  const [dispositionTypeModal, setDispositionTypeModal] = useState("Connected");
  const [dispositionModal, setDispositionModal] = useState("");
  const [startdate, setStartdate] = useState("");
  const [starttime, setStarttime] = useState("");
  const [purposeModal, SetPurposeModal] = useState("");
  const [city, setCity] = useState("");
  const [notes, setNotes] = useState("");
  const [loadinggg, setLoadingg] = useState(true);
  const [assignUser, setAssignUser] = useState([]);
  const [leadStatus, setLeadStatus] = useState("");
  const {
    auth,
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const history = useHistory();
  const { loading, usersData } = useSelector(
    ({ FranchiseReducer }) => FranchiseReducer
  );
  const { errorss, taskCreateStatus } = useSelector(({ task }) => task);
  console.log(errorss?.remark?.[0], "errorss");
  useEffect(() => {
    const url = new URL(window.location).searchParams;
    const mo = url.get("page");
    const date = url.get("leaddate");
    const desposition_type = url.get("disposition_type");
    const Connected = url.get("disposition");
    const assignn = url.get("assign_to");
    if (mo) {
      setPage(mo);
    }
    if (assignn) {
      setAssign(assignn);
    }
    if (date) {
      setAssignDate(date);
    }
    if (desposition_type) {
      setDispositionType(desposition_type);
    }
    if (Connected) {
      setDisposition(Connected);
    }
  }, []);

  useEffect(() => {
    if (leadStatus == "call_back_leads") {
      history.push("/call-back-list");
    } else if (leadStatus == "dead_leads") {
      history.push("/dead-call-list");
    } else if (leadStatus == "hot_leads") {
      history.push("/hot-call-list");
    } else if (leadStatus == "cold_leads") {
      history.push("/cold-call-list");
    } else if (leadStatus == "dump_leads") {
      history.push("/franchise-dump-data");
    } else if (leadStatus == "warm_leads") {
      history.push("/warm-call-list");
    }
  }, [leadStatus]);
  const { userStatus, leadData } = useSelector(({ lead }) => lead);
  useEffect(() => {
    if (userStatus) {
      setAssignUser(leadData.users);
    }
  }, [userStatus]);

  useEffect(() => {
    Axios.get(
      `/api/crm/get-franchise-investor-new?sort=${sort}&page=${page}&paginate=${paginate}&search=${search}&disposition_type=${dispositiontype}&disposition=${disposition}&source=${leadsource}&start=${createDate}&end=${endCreateDate}&start_assign=${assignDate}&end_assign=${assignEndDate}&assign_to=${assign}&campaign=Franchise`,
      {
        headers: { authorization: `Bearer ${auth?.token}` },
      }
    )
      .then((res) => {
        setFranchiseList(res.data?.franchiseInvestor);
        setFrom(res.data.franchiseInvestor?.from);
        setTo(res.data.franchiseInvestor?.to);
        setTotal(res.data.franchiseInvestor?.total);
        setPerPage(res.data.franchiseInvestor?.per_page);
      })
      .catch((error) => console.log(error));
  }, [
    sort,
    page,
    paginate,
    search,
    dispositiontype,
    disposition,
    leadsource,
    assignDate,
    assignEndDate,
    auth?.token,
    refresh,
    assign,
    endCreateDate,
  ]);
  const permissions = React.useMemo(() => permission ?? [], [permission]);
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  // useEffect(() => {
  //   axios
  //     .get(`/api/crm/get-franchise-investor-dashboard-count`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //       },
  //     })
  //     .then((res) => setFranchiseData(res.data))
  //     .catch((err) => console.log(err));
  // }, []);
  useEffect(() => {
    axios
      .get(`/api/crm/franchise-brands`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => setBrand(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  // useEffect(() => {
  //   if (usersData) {
  //     setFrom(usersData?.from);
  //     setTo(usersData?.to);
  //     setTotal(usersData?.total);
  //     setPerPage(usersData?.per_page);
  //   }
  // }, [usersData]);

  useEffect(() => {
    axios
      .get(`/api/crm/get-franchise-investor-dashboard-count`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setTeamList(res.data?.getTeamList);
      })

      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/crm/crm-franchise-user`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setFranchiseTeamList(res.data?.getList);
      })

      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
  }, [auth?.token]);
  useEffect(() => {
    if (taskCreateStatus) {
      NotificationManager.success("task added");
      setOpenDispositionModal(!openDispositionModal);
      setRefresh(refresh + 1);
    }
  }, [taskCreateStatus]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("file", fields);
    data.append("brand", brand);
    data.append("campaign", auth.campaign);
    axios
      .post("/api/crm/get-crm-franchise-investor-import", data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        window.location.reload();
        NotificationManager.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoading(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  const handleDisposition = (e) => {
    if (dispositionModal == "Call_Back") {
      e.preventDefault();

      const inputData = new FormData();
      inputData.append("lead_id", modalId);
      inputData.append("activity", activity);
      inputData.append("subject", subject);
      inputData.append("start_date", startdate);
      inputData.append("start_time", starttime);
      inputData.append("assign_to", assignn);
      inputData.append("type", "franchise_lead");
      inputData.append("remark", notes);
      dispatch(eventInsertHandler(inputData, auth?.token));
    }
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post(
          "/api/crm/franchise-investor-destroy",
          {
            ids: JSON.stringify(deleteIds),
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };

  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/api/crm/franchise-investor-destroy", {
          ids: JSON.stringify([id]),
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        frenchiseList?.data.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };
  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };

  const handleSendLead = (e) => {
    e.preventDefault();
    setOpenSendLeadModal(!openSendLeadModal);
  };

  const handleSendLeads = (e) => {
    let filterList;
    e.preventDefault();

    filterList = frenchiseList?.data.filter((v) =>
      deleteIds.some((i) => i == v?.id)
    );

    axios
      .post(
        `/api/crm/update-franchise-investor-assignto`,
        {
          assignTo: assignTo,
          leadIds: filterList,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Lead Send Successfully");
        setDeleteIds([]);
        setOpenSendLeadModal(!openSendLeadModal);
      })
      .catch((err) => console.log(err));
  };

  const csvHead = [
    [
      " Name",
      "Contact Number",
      "Email",
      "Brand Name",
      "Investment",
      "Disposition",
      "Disposition Type",
      "Lead Location",
      "Assign To",
      "Client Remarks",
      "Lead Date",
      
    ],
  ];
  const csvData = (
    Array.isArray(frenchiseList?.data) ? frenchiseList?.data : []
  )?.map?.((cv) => [
    cv?.customer_name,
    cv?.contact_number,
    cv?.email,
    cv?.brand_name ? cv?.brand_name?.brand_name : cv?.brand,
    cv?.investment,
    cv?.disposition,
    cv?.disposition_type,
    cv?.assign_lead_location,
    cv?.crm_user?.name,
    cv?.client_remarks,
    moment(cv?.created_at).format("Do MMM YYYY"),
    cv?.type,
    cv?.crm_user_tbl?.name,
    cv?.lead_date,
    
  ]);
  const exportData = csvHead.concat(csvData);

  const uniqueMobileNumbersSet = new Set();

  // Use filter to create a new array with unique elements based on the mobile number
  const uniqueFranchiseList = (frenchiseList?.data || []).filter((lead) => {
    // Ensure that lead and lead.contact_number are not undefined or null
    if (lead && lead.contact_number) {
      // If the mobile number is not in the set, add it and include the lead in the filtered array
      if (!uniqueMobileNumbersSet.has(lead.contact_number)) {
        uniqueMobileNumbersSet.add(lead.contact_number);
        return true;
      }
    }

    // If the mobile number is already in the set or is undefined/null, exclude the lead from the filtered array
    return false;
  });

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="c-card-top">
                    <div className="c-card-top-title ml-2">
                      <h4>Franchise Investor List</h4>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <span className="custom-select">Search</span>

                        <Input
                          placeholder="Search here..."
                          onChange={handleSearch}
                          className="c-form-control-serch"
                        />
                        <div className="input-group-append">
                          <button
                            title="Advance Search"
                            onClick={() => {
                              advanceSearch
                                ? setAdvanceSearch(false)
                                : setAdvanceSearch(true);
                            }}
                            id="c_searchBlock_toggle"
                            className="btn btn-secondary btn-sm c-card-top-block-icon"
                            type="button"
                          >
                            <FiChevronDown />
                          </button>
                          <button
                            className="btn btn-secondary btn-sm"
                            id="button-addon2"
                            type="button"
                            onClick={handleSerchByClick}
                          >
                            GO
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          advanceSearch ? "c-card-top-block" : "d-none"
                        }
                        id="c_searchBlock"
                      >
                        <div className="c-card-top-block-sec">
                          <div className="c-card-top-block-item">
                            <label>Assign To</label>
                            <select
                              id="paginate"
                              onChange={(e) => setAssign(e.target.value)}
                              value={assign}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              {auth.id === 166 ?
                              <option value="166">Amardeep</option>
                              :null
                               }
                              {teamList?.map((cr) => (
                                <option value={cr?.id} key={cr.id}>
                                  {cr?.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Lead Source</label>
                            <select
                              id="paginate"
                              onChange={(e) => setLeadsource(e.target.value)}
                              value={leadsource}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              <option value="facebook">Facebook</option>
                              <option value="instagram">Instagram</option>
                              <option value="google">google</option>
                              <option value="website">Website</option>
                              <option value="webinar">Webinar</option>
                              <option value="refrence">refrence</option>
                              <option value="walk-in">walk-in</option>
                            </select>
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Disposition Type</label>
                            <select
                              id="paginate"
                              onChange={(e) =>
                                setDispositionType(e.target.value)
                              }
                              value={dispositiontype}
                              className="custom-select"
                            >
                              <option value=""> Select </option>
                              <option value="untouched">Untouched</option>
                              <option value="Invalid Number">
                                Invalid Number
                              </option>
                              <option value="CallBack/Busy">
                                CallBack/Busy
                              </option>
                              <option value="Franchise Fee Picked">
                                Franchise Fee Picked
                              </option>
                              <option value="Membership Picked">
                                Membership Picked
                              </option>
                              <option value="On Negotiation">
                                On Negotiation
                              </option>
                              <option value="Praposal Shared">
                                Praposal Shared
                              </option>
                              <option value="Budget Issue">Budget Issue</option>
                              <option value="Wrong Number">Wrong Number</option>

                              <option value="Meeting Met">Meeting Met</option>
                              <option value="Not Reachable">
                                Not Reachable
                              </option>
                              <option value="Not Answering/Ringing">
                                Not Answering/Ringing
                              </option>
                              <option value="Not Intrested">
                                Not Intrested
                              </option>
                              <option value="Meeting Booked">
                                Meeting Booked
                              </option>
                              <option value="Token Picked">Token Picked</option>

                              <option value="Switch off">Switch off</option>
                              <option value="Dump Lead">Dump Lead</option>
                            </select>
                          </div>
                          {dispositiontype == "Connected" ? (
                            <div className="c-card-top-block-item">
                              <label>Connected</label>
                              <select
                                id="paginate"
                                onChange={(e) => setDisposition(e.target.value)}
                                value={disposition}
                                className="custom-select"
                              >
                                <option value=""> Select </option>
                                <option value="Mail_Shared">Mail Shared</option>
                                <option value="Meeting_Align">
                                  Meeting Align
                                </option>
                                <option value="Meeting_Done">
                                  Meeting Done
                                </option>
                                <option value="Follow_Up">
                                  Follow up(Hot)
                                </option>
                                <option value="CallBack/Busy">
                                  CallBack/Busy
                                </option>
                                <option value="Manufacturing">
                                  Manufacturing
                                </option>
                                <option value="Not_Interested">
                                  Not Interested
                                </option>
                                <option value="Meeting_Done_NOI">
                                  Meeting Done Not Interested
                                </option>
                                <option value="Payment_Done">
                                  Payment Done
                                </option>
                              </select>
                            </div>
                          ) : null}

                          {dispositiontype == "Not_Connected" ? (
                            <div className="c-card-top-block-item">
                              <label>Not Connected</label>
                              <select
                                id="paginate"
                                onChange={(e) => setDisposition(e.target.value)}
                                value={disposition}
                                className="custom-select"
                              >
                                <option value=""> Select </option>

                                <option value="Npc">NPC</option>
                                <option value="Switch_Off">Switch Off</option>
                                <option value="Invalid_Number">
                                  Invalid Number
                                </option>
                                <option value="Call_Rejected">
                                  Call Rejected
                                </option>
                              </select>
                            </div>
                          ) : null}
                          <div className="c-card-top-block-item">
                            <label>Lead Status</label>
                            <select
                              id="leadStatus"
                              onChange={(e) => setLeadStatus(e.target.value)}
                              value={leadStatus}
                              className="custom-select"
                            >
                              <option value=""> Select </option>
                              <option value="call_back_leads">
                                Call Back Lead
                              </option>
                              <option value="call_back_leads">Warm Lead</option>
                              <option value="dead_leads">Dead Lead</option>
                              <option value="hot_leads">Hot Lead</option>
                              <option value="cold_leads">Cold Lead</option>
                              <option value="warm_leads">Warm Lead</option>
                              <option value="dump_leads">Dump Lead</option>
                            </select>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Created start date</label>

                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setCreateDate(e.target.value)}
                              value={createDate}
                            />
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Created end date</label>

                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setEndCreateDate(e.target.value)}
                              value={endCreateDate}
                            />
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Assigned Start Date</label>

                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setAssignDate(e.target.value)}
                              value={assignDate}
                            />
                          </div>

                          <div className="c-card-top-block-item">
                            <label>Assigned End Date</label>

                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setAssignEndDate(e.target.value)}
                              value={assignEndDate}
                            />
                          </div>

                          <div className="c-card-top-block-action">
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Apply
                            </button>

                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-card-top-buttons">
                      {permissions.some(
                        (item) => item?.name === "franchise-team-create"
                      ) && (
                          <Link to="/create-franchise-investor">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm mr-2"
                            >
                              <i className="fa fa-plus"></i> Add New
                            </button>
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    {/* <div className="left-area-field">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div> */}
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header d-card-header">
                    <div className="left-box-tools">
                      <button
                        className="btn btn-secondary btn-sm mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleDelete}
                      >
                        <i className="fa fa-trash pr-1" /> Delete
                      </button>
                      {/* <button
                        className="btn btn-secondary btn-sm mr-1"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button> */}
                      <ButtonGroup className="">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm ml-1"
                          onClick={() => setModal(true)}
                        >
                          <i className="fa fa-plus btn-i-icon"></i> Import Data
                        </button>
                      </ButtonGroup>
                      <Modal isOpen={modal} toggle={() => setModal(false)}>
                        <ModalHeader toggle={() => setModal(false)}>
                          Import Data
                          <a
                            href="https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/franchiseInvester.xlsx"
                            style={{ "margin-left": "170px", color: "blue" }}
                          >
                            Sample Sheet
                          </a>
                        </ModalHeader>
                        <ModalBody>
                          <form onSubmit={handleSubmit}>
                            <FormGroup>
                              <div>
                                <label>Franchise Team</label>

                                <select
                                  id="paginate"
                                  onChange={(e) => setBrand(e.target.value)}
                                  value={brand}
                                  className="custom-select mt-3"
                                >
                                  <option value="">Select Brand</option>

                                  {brand?.brand?.map((cr) => (
                                    <option value={cr?.id} key={cr.id}>
                                      {cr?.brand_name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="pt-3">
                                <CustomInput
                                  type="file"
                                  id="file"
                                  name="file"
                                  label="Choose File"
                                  onChange={handleChange}
                                  invalid={!!errors?.file}
                                  required
                                />
                              </div>
                              <FormFeedback>
                                {!!errors?.file ? errors?.file[0] : ""}
                              </FormFeedback>
                            </FormGroup>
                            <div className="text-center mb-3">
                              <Loader loading={loading}>
                                <Button
                                  type="submit"
                                  color="secondary"
                                  size="sm"
                                  className="mr-1"
                                >
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  color="secondary"
                                  size="sm"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Loader>
                            </div>
                          </form>
                        </ModalBody>
                      </Modal>
                      <button
                        className="btn btn-secondary btn-sm ml-1"
                        disabled={!deleteIds.length}
                        onClick={handleSendLead}
                      >
                        <i className="fa fa-list pr-1"></i> Send Lead
                      </button>
                    </div>
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      {(auth?.id == 166 || auth?.id == 10 || auth?.isManager == "yes") && (
                        <CSVLink
                          filename={"LeadList.csv"}
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Export To CSV"
                          data={exportData}
                        >
                          <i className="fa fa-file-excel-o" />
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold text-center">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    onChange={handleMultiSelect}
                                    checked={
                                      deleteIds.length ===
                                      frenchiseList?.data?.length &&
                                      deleteIds.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold text-center">
                                  Name
                                </th>

                                <th className="font-weight-bold text-center">
                                  Contact Number
                                </th>

                                <th className=" font-weight-bold text-center">
                                  Email
                                </th>
                                <th className="font-weight-bold text-center">
                                  Brand Name
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Investment
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Disposition
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Disposition Type
                                </th>
                                <th className="font-weight-bold text-center">
                                  Lead Location
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Assign To
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Lead Date
                                </th>
                                <th className=" font-weight-bold text-center">
                                  Client Remarks
                                </th>

                                <th className=" font-weight-bold text-center">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {Array.isArray(uniqueFranchiseList)
                                    ? uniqueFranchiseList
                                      ?.filter(
                                        (item) =>
                                          item?.disposition_type !==
                                          "Dump Lead"
                                      )
                                      ?.map((leadsl, i) => (
                                        <tr
                                          key={i}
                                          className={
                                            leadsl?.disposition_type ===
                                              "Meeting Met" ||
                                              leadsl?.disposition_type ===
                                              "Meeting Booked" ||
                                              leadsl?.disposition_type ===
                                              "On Negotiation" ||
                                              leadsl?.disposition_type ===
                                              "Praposal Shared"
                                              ? "bg-warning"
                                              : leadsl.disposition_type ===
                                                "Token Picked" ||
                                                leadsl.disposition_type ===
                                                "Franchise Fee Picked" ||
                                                leadsl.disposition_type ===
                                                "Membership Picked"
                                                ? "bg-success"
                                                : leadsl.disposition_type ===
                                                  "Not Answering/Ringing" ||
                                                  leadsl.disposition_type ===
                                                  "Not Reachable" ||
                                                  leadsl.disposition_type ===
                                                  "Switch off"
                                                  ? "bg-blue"
                                                  : leadsl.disposition_type ===
                                                    "Budget Issue" ||
                                                    leadsl.disposition_type ===
                                                    "Not Intrested" ||
                                                    leadsl.disposition_type ===
                                                    "Invalid Number"
                                                    ? "badge-secondary"
                                                    : leadsl.disposition_type ===
                                                      "CallBack/Busy"
                                                      ? "bg-yellow"
                                                      : "child"
                                          }
                                        >
                                          <td>
                                            <CustomInput
                                              id={`default-${leadsl.id}`}
                                              type="checkbox"
                                              checked={deleteIds.some(
                                                (d) => d === leadsl.id
                                              )}
                                              onChange={(e) =>
                                                handleSingleSelect(
                                                  e,
                                                  leadsl.id
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center cursor-pointer">
                                            <Link
                                              to={`/franchise-investor-detail/${leadsl.id}?page=${page}&disposition=${disposition}&disposition_type=${dispositiontype}&source=${leadsource}&start=${createDate}&end=${endCreateDate}&start_assign=${assignDate}&end_assign=${assignEndDate}&assign_to=${assign}&campaign=Franchise`}
                                              style={{ color: "#000000" }}
                                            >
                                              {leadsl?.customer_name}
                                            </Link>
                                          </td>

                                          <td className="text-center">
                                            {leadsl.contact_number}{" "}
                                          </td>

                                          <td className="text-center">
                                            {leadsl?.email}
                                          </td>
                                          <td className="text-center">
                                            {leadsl?.brand_name
                                              ? leadsl?.brand_name?.brand_name
                                              : leadsl?.brand}
                                          </td>
                                          <td className="text-center">
                                            {leadsl?.investment}
                                          </td>
                                          <td className="text-center">
                                            {leadsl?.disposition}
                                          </td>
                                          <td className="text-center">
                                            {leadsl?.disposition_type}
                                          </td>

                                          <td className="text-center">
                                            {leadsl?.assign_lead_location}
                                          </td>
                                          <td className="text-center">
                                            {leadsl?.crm_user?.name}
                                          </td>
                                          <td className="text-center">
                                            {leadsl.lead_date}
                                          </td>
                                          <td className="text-center">
                                            {leadsl.client_remarks}
                                          </td>

                                          <td className="text-center">
                                            <Link
                                              to={`/franchise-edit-investor/edit/${leadsl?.id}?page=${page}&disposition=${disposition}&disposition_type=${dispositiontype}&source=${leadsource}&start=${createDate}&end=${endCreateDate}&start_assign=${assignDate}&end_assign=${assignEndDate}&assign_to=${assign}&campaign=Franchise
                                              `}
                                            >
                                              <FiEdit />
                                            </Link>
                                            <a
                                              href="#"
                                              className="text-danger ml-2"
                                              onClick={() => {
                                                setOpenDispositionModal(true);
                                                setModalId(leadsl?.id);
                                              }}
                                            >
                                              <FiTablet />
                                            </a>
                                            <a
                                              href="#"
                                              className="text-danger pl-3"
                                              onClick={() => {
                                                setOpenNotesModal(true);
                                                setId(leadsl?.id);
                                              }}
                                            >
                                              <FiEye />
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    isOpen={openDispositionModal}
                    toggle={() =>
                      setOpenDispositionModal(!openDispositionModal)
                    }
                  >
                    <ModalHeader
                      toggle={() => setIsOpen(!openDispositionModal)}
                    >
                      Add Task
                    </ModalHeader>
                    <ModalBody className="p-0 m-0">
                      <Row className="m-3">
                        <Col sm={12}>
                          <FormGroup className="row">
                            <Label
                              for="name"
                              className="col-md-12 control-label col-form-label col-12"
                            >
                              Disposition Type
                            </Label>
                            <select
                              id="paginate"
                              onChange={(e) =>
                                setDispositionTypeModal(e.target.value)
                              }
                              value={dispositionTypeModal}
                              className="custom-select"
                            >
                              <option value=""> Select </option>
                              <option value="Connected">Connected</option>
                            </select>
                          </FormGroup>
                        </Col>

                        {dispositionTypeModal == "Connected" ? (
                          <Col sm={12}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-12 control-label col-form-label col-12"
                              >
                                Connected
                              </Label>
                              <select
                                id="paginate"
                                onChange={(e) =>
                                  setDispositionModal(e.target.value)
                                }
                                value={dispositionModal}
                                className="custom-select"
                              >
                                <option value=""> Select </option>
                                {/* <option value="Mail_Shared">Mail Shared</option>
                                <option value="Meeting_Align">
                                  Meeting Align
                                </option>
                                <option value="Meeting_Done">
                                  Meeting Done
                                </option>
                                <option value="Follow_Up">
                                  Follow up(Hot)
                                </option>
                                <option value="Mail_Reshared">
                                  Mail Reshared
                                </option> */}
                                <option value="Call_Back">Call Back</option>
                                {/* <option value="Not_Interested">
                                  Not Interested
                                </option>
                                <option value="Meeting_Done_NOI">
                                  Meeting Done Not Interested
                                </option> */}
                              </select>
                            </FormGroup>
                          </Col>
                        ) : null}
                        {dispositionModal == "Call_Back" ? (
                          //  ||
                          // dispositionModal == "Mail_Shared" ||
                          // dispositionModal == "Meeting_Align" ||
                          // dispositionModal == "Meeting_Done"
                          <>
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="row">
                                <label className="col-md-4 control-label col-form-label col-12 text-md-right text-danger">
                                  Start Date &amp; Time
                                </label>
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-6 form-group">
                                      <Input
                                        type="date"
                                        value={startdate}
                                        onChange={(e) =>
                                          setStartdate(e.target.value)
                                        }
                                        invalid={!!errors.start_date}
                                      />
                                      {errorss.start_date && (
                                        <div className="text-left text-danger">
                                          {errorss.start_date?.[0]}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <div className="input-group clockpicker-example">
                                        <Input
                                          type="time"
                                          value={starttime}
                                          onChange={(e) =>
                                            setStarttime(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row form-group">
                            <Label
                              for="name"
                              className="col-md-12 control-label col-form-label col-12"
                            >
                              Assing To
                            </Label>
                            <div className="col-md-12">
                              <Input
                                type="select"
                                className="custom-select"
                                id="assignTo"
                                onChange={(e) => setAssignn(e.target.value)}
                                value={assignn}
                                invalid={!!errors.assign_to}
                              >
                                <option>select</option>
                                {franchiseteamList.map((cr) => (
                                  <option value={cr?.id} key={cr.id}>
                                    {cr?.name}
                                  </option>
                                ))}
                              </Input>
                              {errorss?.assign_to && (
                                <div className="text-left text-danger">
                                  {errorss?.assign_to?.[0]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <Col sm={12}>
                          <FormGroup className="row">
                            <Label
                              for="name"
                              className="col-md-12 control-label col-form-label col-12"
                            >
                              Purpose Of Meeting
                            </Label>
                            <select
                              id="paginate"
                              onChange={(e) => SetPurposeModal(e.target.value)}
                              value={purposeModal}
                              className="custom-select"
                            >
                              <option value=""> Select </option>
                              <option value="CallBack/Busy">
                                CallBack/Busy
                              </option>
                              <option value="Franchise Fee Picked">
                                Franchise Fee Picked
                              </option>
                              <option value="Membership Picked">
                                Membership Picked
                              </option>
                              <option value="On Negotiation">
                                On Negotiation
                              </option>
                              <option value="Praposal Shared">
                                Praposal Shared
                              </option>
                              <option value="Budget Issue">Budget Issue</option>
                              <option value="Wrong Number">Wrong Number</option>

                              <option value="Meeting Met">Meeting Met</option>
                              <option value="Not Reachable">
                                Not Reachable
                              </option>
                              <option value="Not Answering/Ringing">
                                Not Answering/Ringing
                              </option>
                              <option value="Not Intrested">
                                Not Intrested
                              </option>
                              <option value="Meeting Booked">
                                Meeting Booked
                              </option>
                              <option value="Token Picked">Token Picked</option>

                              <option value="Switch off">Switch off</option>
                              <option value="Dump Lead">Dump Lead</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col sm={12}>
                          <FormGroup className="row">
                            <Label
                              for="city"
                              className="col-md-12 control-label col-form-label col-12"
                            >
                              Remarks
                            </Label>
                            <textarea
                              type="text"
                              value={notes}
                              onChange={({ target: { value } }) =>
                                setNotes(value)
                              }
                              className="custom-select"
                              placeholder="Write Your Remarks Here"
                            />
                            {errorss?.remark?.[0] && (
                              <div className="text-left text-danger">
                                {errorss?.remark?.[0]}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col sm={12}>
                          <div className="text-center">
                            <Loader loading={loading}>
                              <Button
                                type="submit"
                                onClick={handleDisposition}
                                color="secondary"
                                size="sm"
                                className="mr-1"
                              >
                                Submit
                              </Button>
                              <Button
                                type="button"
                                color="secondary"
                                size="sm"
                                onClick={() =>
                                  (window.location.href = "/associate-team")
                                }
                              >
                                Close
                              </Button>
                            </Loader>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custom-select custom-select-page"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2000">2000</option>
                          <option value="5000">5000</option>
                        </select>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          isOpen={openSendLeadModal}
          toggle={() => setOpenSendLeadModal(!openSendLeadModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openSendLeadModal)}>
            <form onSubmit={handleSendLeads}>
              <Row className="m-3">
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-12 control-label col-form-label col-12"
                    >
                      Franchise Investor
                    </Label>
                    <Input
                      type="select"
                      className="col-md-12"
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                    >
                      <option>select</option>
                      {franchiseteamList?.map((cr) => (
                        <option value={cr?.id} key={cr.id}>
                          {cr?.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Loader loading={loading}>
                      <Button
                        type="submit"
                        color="secondary"
                        size="sm"
                        className="mr-1"
                      >
                        Send
                      </Button>
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          (window.location.href = "/franchise-list-investor")
                        }
                      >
                        Close
                      </Button>
                    </Loader>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalHeader>
          <ModalBody className="p-0 m-0"></ModalBody>
        </Modal>
        <Modal
          isOpen={openNotesModal}
          toggle={() => setOpenNotesModal(!openNotesModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openNotesModal)}>
            Notes
          </ModalHeader>
          <ModalBody className="p-0 m-0">
            <Note id={id} />
          </ModalBody>
        </Modal>
      </MainWrapper>
    </Fragment>
  );
};

export default FranchiseInvestorList;
