import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  CustomInput,
  FormText,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import MainWrapper from "../components/MainWrapper";
import { useParams } from "react-router";
import _ from "lodash";
import { getCrmUsersApi } from "../store/actions/LeadActions";
import { getSourceList } from "../store/actions/LeadActions";

const Edit = () => {
  const [ids, setIds] = useState("");
  const [users, setUsers] = useState({});
  const [consultantId, setConsultantId] = useState("");
  const [userId, setUserId] = useState("");
  const [valid, setValid] = useState(false);
  const [associate, setAssociate] = useState("1");
  const [dispositiontype, setDispositionType] = useState("");
  const [disposition, setDisposition] = useState("");
  const [professionalservices, setProfessionalServices] = useState([]);
  const [consultancyType, setConsultancyType] = useState(0);
  const [professionalName, setProfessionalName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [expertName, setExpertName] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [industries, setIndustries] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadSource, setleadSource] = useState("");
  const [errors, setErrors] = useState({});
  const [industory, setIndustory] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subsubcategory, setSubSubcategory] = useState([]);
  const [instituteType, setInstituteType] = useState("");
  const [source, setSource] = useState("");
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [skills, setSkills] = useState("");
  const [type, setType] = useState("1");
  const [socialIds, setSocialIDs] = useState({});
  const [eventDate, setEventDate] = useState("");
  const [eventName, setEventName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedSubindustry, setSelectedSubindustry] = useState([]);
  const [subsubcategories, setSubSubCategories] = useState([]);
  const [industry, setIndustry] = useState({});
  const [other, setOther] = useState("");
  const [linkedID, setLinkedID] = useState("");
  const [facebookID, setFacebookID] = useState("");
  const [instagramID, setInstagramID] = useState("");
  const [multiAssociate, setMultiAssociate] = useState([
    { value: 1, label: "Professional Expert" },
  ]);
  const [otherIds, setOtherIds] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [mailShared, setMailShared] = useState("");
  const [sources, setSources] = useState([]);
  const [leadDate, setLeadDate] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState([]);
  const [selectedIndustryId, setSelectedIndustryId] = useState([]);
  const dispatch = useDispatch();
  const {
    leadData,
    createSource: createSourcee,
    createSourceStatus,
    createSourceLoading,
    getSourcesStatus,
    getSources,
  } = useSelector(({ lead }) => lead);

  const [selectError, setSelectError] = useState({
    name: "",
    email: "",
    whatsApp: "",
    organisation: "",
    designation: "",
    experience: "",
    qualification: "",
    skills: "",
    speakIn: "",
    dob: "",
    profilesummary: "",
  });
  let tempArr = [];
  let tempArr1 = [];
  const { id } = useParams();
  const { auth } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  useEffect(() => {
    dispatch(getSourceList(auth?.token));
  }, [auth?.token]);

  useEffect(() => {
    setOtherIds(socialIds?.otherId);
    setLinkedID(socialIds?.linkedinid);
    setInstagramID(socialIds?.instagramid);
    setFacebookID(socialIds?.facebookid);
  }, [socialIds]);
  useEffect(() => {
    axios
      .get(`/api/crm/sectordata-mega`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        tempArr = res.data.subcategory;
        setIndustries(res.data.subcategory);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/crm/professionalServices`)
      .then((res) => {
        tempArr1 = res.data.professionalservices;
        setProfessionalServices(res.data.professionalservices);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/crm/edit-consultant/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setSelectedSectorId(
          res?.data?.consultant?.industry?.map((v) => v?.industry)
        );
        let selectedInd = res?.data?.consultant?.industry?.map(
          (sub) => sub?.sub_industries ?? null
        );
        var filtered = selectedInd
          .filter(function (el) {
            if (el != null || el != "") {
              return el;
            }
          })
          .join(",");

        setSelectedIndustryId(filtered.split(","));
        setSocialIDs(
          res.data?.consultant?.social_ids
            ? JSON.parse(res.data?.consultant?.social_ids || {})
            : {}
        );
        setIds(res.data.consultant?.id);
        setUserId(res.data.consultant?.user_id);
        setLeadDate(
          moment(res.data?.consultant?.lead_date).format("YYYY-MM-DD") || ""
        );
        setAssignTo(res.data.consultant?.assign_to);
        setName(res.data.consultant?.name ?? "");
        setLeadStatus(res.data.consultant?.lead_status || "");
        setMobile(res.data.consultant?.mobile ?? "");
        setBussinessName(res.data.consultant?.bussinessName ?? "");
        setPhoneNo(res.data.consultant?.phone_no ?? "");
        setSupplier(res.data.consultant?.supplier_type);
        setInstituteType(res.data.consultan?.institute_type ?? "");
        setEmail(res.data.consultant?.email ?? "");
        setDisposition(res.data.consultant?.disposition ?? "");
        setDispositionType(res.data.consultant?.disposition_type ?? "");
        setMailShared(res.data.consultant?.mail_shared ?? "");
        setDesignation(res.data.consultant?.designation ?? "");
        setExperience(res.data.consultant?.experience ?? "");
        setSkills(res.data.consultant?.skill ?? "");
        setThumbnail(res.data.consultant?.image ?? "");
        setSource(res.data.consultant?.source ?? "");
        setWebsiteUrl(res.data.consultant?.website_url ?? "");
        setState(res.data.consultant?.state ?? "");
        setCity(res.data.consultant?.city ?? "");
        setRemarks(res.data.consultan?.remarks ?? "");
        setContactPerson(res.data.consultant?.contact_person ?? "");
        setAddress(res.data.consultant?.address ?? "");
        setleadSource(res.data.consultant?.source ?? "");
        setPincode(res.data.consultant?.pincode ?? "");
        setType(res.data.consultant?.type ?? "");
        setMultiAssociate(handleType(res.data.consultant?.type ?? ""));
        setEventName(res.data.consultant?.eventname ?? "");
        setEventDate(res.data.consultant?.eventdate ?? "");
        if (res.data.consultant?.type == 1 || res.data.consultant?.type == 5) {
          setConsultancyType(1);
        }
      })
      .catch((err) => console.log(err));

    dispatch(getCrmUsersApi(auth?.token));
  }, [auth]);

  useEffect(() => {
    if (!_.isNull(industory)) {
      const subCat = industory ? industory.map((s) => s.value) : [];
      axios
        .get(`/api/crm/subsectordatanew?industry=${subCat.sort()}`)
        .then((res) => {
          setSubIndustry(res.data.subSubCats);
        })
        .catch((err) => console.log(err));
    }
    setSelectedSubindustry([]);
  }, [industory]);

  useEffect(() => {
    setIndustry({});
  }, [consultancyType]);

  const sectorList = useMemo(() => industries, [industries]);
  const industryList = useMemo(() => subIndustry, [subIndustry, industries]);
  useEffect(() => {
    setIndustory(
      sectorList.filter((v) => selectedSectorId.some((s) => s == v.value))
    );
  }, [selectedSectorId]);
  useEffect(() => {
    if (industryList) {
      setSubSubcategory(
        industryList.filter((v) => selectedIndustryId.some((s) => s == v.value))
      );
    }
  }, [industryList]);
  const handleSubIndustry = (id) => {
    let list = selectedSubindustry;

    list = list.some((re) => re === id)
      ? list.filter((me) => me != id)
      : [...list, id];

    setSelectedSubindustry(list);
  };

  // useEffect(() => {
  //   const subCat = subcategory
  //     ? subcategory.map(s => s.value)
  //     : [];
  //   const subSubCat = subsubcategory
  //     ? subsubcategory
  //       .filter(s => s.parent === 0)
  //       .map(s => s.id)
  //     : [];

  //   axios.get(`/api/crm/sub-sub-categories/consultant?subCategories=${subCat}&subSubCategories=${subSubCat}`).then(res => {
  //     setSubSubCategories(res.data.subSubCategories);
  //   }).catch(err => console.log(err))

  //   let sub = subCat ? subsubcategory && subsubcategory.filter(res => subCat.find(p => (res.parent === p))) : []
  //  // console.log(sub, 'hhh')
  //   setSubSubcategory(sub);

  // }, [subcategory])

  const handleIndustriesChange = (value) => {
    setSubCategory(value);
    setIndustory(value);
    setPermissions(value);

    setSelectedSubindustry([]);
  };
  const handleAssociate = (value) => {
    setMultiAssociate(value);
    setSubCategory([]);
    setIndustory([]);
    setPermissions([]);
  };

  const handleType = (type) => {
    let typeArray = [];
    let option = [
      { value: 1, label: "Professional Expert" },
      { value: 2, label: "Supplier" },
      { value: 3, label: "Industry Expert" },
      { value: 4, label: "Enterpreneur" },
      { value: 5, label: "Institutional" },
      { value: 6, label: "Exhibitors" },
      { value: 7, label: "Visitors" },
      { value: 8, label: "Franchise Network" },
      { value: 9, label: "Startup Network" },
      { value: 10, label: "Others" },
      { value: 11, label: "Tata Play" },
      { value: 12, label: "Lecturer" },
      { value: 13, label: "Brand Onboarding" },
    ];
    if (type) {
      type.split(",").map((op) => {
        let ld = option.find((m) => {
          if (m.value == op) {
            return m;
          }
        });
        typeArray.push(ld);
      });
    }

    return typeArray;
  };
  const handleTypeMatched = (type) => {
    if (multiAssociate) {
      if (multiAssociate.find((m) => m.value == type)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const mAssociate = useMemo(() => {
    if (multiAssociate) {
      return multiAssociate?.map((m) => m?.value).join(",");
    } else {
      return null;
    }
  }, [multiAssociate]);

  const handleImageChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  // const changeMobile = (e) => {

  //   if ((_.size(e.target.value) < 11 && !isNaN(parseInt(e.target.value))) || _.isEmpty(e.target.value)) {
  //     setMobile(e.target.value);
  //     setName('');
  //     setValid(false);
  //     if (_.size(e.target.value) >= 10) {

  //       setUsers({});
  //       setErrors({});
  //       axios.get(`/api/crm/verify-mobile/${e.target.value}`).then(res => {
  //         setUsers(res.data.user ?? {});
  //         setName(res.data.user.name || '')
  //         setUserId(res.data.user.id || "")
  //         setEmail(res.data.user.email || "")
  //         setValid(res.data.valid);

  //       }).catch(err => console.log(err));

  //     }

  //   }
  // }
  const validation = () => {
    let formIsValid = true;
    let errors = {};
    setSelectError({
      mobile: "",
      name: "",
      email: "",
      whatsApp: "",
      organisation: "",
      designation: "",
      experience: "",
      qualification: "",
      skills: "",
      speakIn: "",
      dob: "",
      profilesummary: "",
      subsubcategory: "",
      profile: "",
      departmentLocation: "",
      departmentName: "",
    });
    if (_.isEmpty(mobile)) {
      errors["mobile"] = "The mobile field is required.";

      formIsValid = false;
    }
    if (_.isEmpty(name)) {
      errors["name"] = "The name field is required.";

      formIsValid = false;
    }
    if (_.isEmpty(email)) {
      errors["email"] = "The email field is required.";

      formIsValid = false;
    }
    if (handleTypeMatched(7)) {
      if (_.isEmpty(selectError?.dob)) {
        errors["dob"] = "The birthday field is required.";

        formIsValid = false;
      }
    }

    if (handleTypeMatched(2) && handleTypeMatched(6) && handleTypeMatched(7)) {
      if (handleTypeMatched(8)) {
        if (_.isEmpty(designation)) {
          errors["designation"] = "The designation field is required.";

          formIsValid = false;
        }
        if (_.isEmpty(experience)) {
          errors["experience"] = "The experience field is required.";

          formIsValid = false;
        }
      }
    }

    if (handleTypeMatched(2) && handleTypeMatched(6) && handleTypeMatched(7)) {
      if (handleTypeMatched(8)) {
        if (_.isEmpty(designation)) {
          errors["designation"] = "The designation field is required.";

          formIsValid = false;
        }
        if (_.isEmpty(experience)) {
          errors["experience"] = "The experience field is required.";

          formIsValid = false;
        }
      }
    }
    if (handleTypeMatched(7)) {
      if (_.isEmpty(skills)) {
        errors["skills"] = handleTypeMatched(7)
          ? "The about Institute field is required."
          : "The expertize field is required.";

        formIsValid = false;
      }
    }

    if (
      handleTypeMatched(2) &&
      handleTypeMatched(6) &&
      handleTypeMatched(7) &&
      handleTypeMatched(8)
    ) {
    }

    if (handleTypeMatched(6) || handleTypeMatched(7)) {
      // if (_.isEmpty(departmentLocation)) {
      //   errors['departmentLocation'] = handleTypeMatched(7) ? 'The Institute location field is required.' : 'The department location field is required.';
      //   formIsValid = false;
      // }
      // if (_.isEmpty(departmentName)) {
      //   errors['departmentName'] = handleTypeMatched(7) ? 'The Institute name field is required.' : 'The department name field is required.';
      //   formIsValid = false;
      // }
    }

    setSelectError(errors);

    return formIsValid;
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const subVals = subsubcategory.map((sm) => sm.value).join(",");
    e.preventDefault();
    const data = new FormData();

    data.append("associate", mAssociate);
    data.append("name", name);
    data.append("supplier", supplier);
    data.append("lead_status", leadStatus);
    data.append("bussinessName", bussinessName);
    data.append("expertName", expertName);
    industory
      ? industory.map((s, i) => {
          data.append(`subcategory[${i}][value]`, s.value);
          data.append(`subcategory[${i}][label]`, s.label);
          data.append(`subcategory[${i}][child]`, subVals);
        })
      : data.append("subcategory[]", []);
    subsubcategory
      ? subsubcategory.map((s, i) => {
          data.append(`subsubcategory[${i}][id]`, s.id);
          data.append(`subsubcategory[${i}][value]`, s.value);
          data.append(`subsubcategory[${i}][label]`, s.label);
          data.append(`subsubcategory[${i}][child]`, subVals);
          data.append(`subsubcategory[${i}][parent]`, s.parent);
        })
      : data.append("subcategory[]", []);
    // data.append(`subsubcategory[${0}][parent]`, (industory.length > 0 ? industory : subIndustry).map(item => item?.value).join(","));
    data.append("address", address);
    data.append("remarks", remarks);
    data.append("pincode", pincode);
    data.append("city", city);
    data.append("state", state);
    data.append("contactPerson", contactPerson);
    data.append("mobile", mobile);
    data.append("phoneNo", phoneNo);
    data.append("email", email);
    data.append("websiteUrl", websiteUrl);
    data.append("profilePic", profilePic ? profilePic : "");
    data.append("other", other);
    data.append("instituteType", instituteType);
    data.append("source", source);
    data.append("assign_to", parseInt(assignTo));
    data.append("lead_date", leadDate);
    data.append("form", "edit");
    data.append("id", id);
    data.append("designation", designation);
    data.append("experience", experience);
    data.append("disposition_type", dispositiontype);
    data.append("disposition", disposition);
    data.append("mail_shared", mailShared);
    data.append(
      "social_ids",
      JSON.stringify({
        linkedinid: linkedID,
        instagramid: instagramID,
        facebookid: facebookID,
        otherId: otherIds,
      })
    );

    data.append("thumbnail", thumbnail);
    setLoading(true);
    setErrors({});

    axios
      .post(`/api/crm/update-consultant`, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        NotificationManager.success(res.data.message);
        setTimeout(() => {
          window.location.href = "/consultant";
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        }
      });
  };
  const SavefeeIndustry = (e) => {
    e.preventDefault();
    if (_.isEmpty(industry)) {
      if (consultancyType) {
        setErrors({
          professionalservices: ["Select professional service"],
        });
      } else {
        setErrors({
          industry: ["Select sector"],
        });
      }
    } else {
      const data = new FormData();
      data.append("consultant_profile", ids);
      data.append("user_id", userId);
      data.append("industry", !_.isEmpty(industry) && industry.value);
      data.append(
        "sub_industries",
        !_.isEmpty(selectedSubindustry) && selectedSubindustry.toString()
      );
      setErrors({});
      setLoading(true);
      axios
        .post(`/consultant-fee`, data)
        .then((res) => {
          setLoading(true);
          NotificationManager.success(res.data.message);
          setIndustry({});
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setLoading(false);
            setErrors(err.response.data.errors);
          } else {
            setLoading(false);
            NotificationManager.error(err.response.data.message);
          }
        });
    }
  };
  let obj = { value: "others", label: "Others" };
  // console.log("cp", contactPerson);
  return (
    <MainWrapper>
      <main className="content">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>EDIT CONSULTANT</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Assigned To
                    </label>

                    <Input
                      className="custom-select col-md-7"
                      type="select"
                      id="assignTo"
                      disabled={true}
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                      invalid={!!errors?.assign_to}
                    >
                      <option value="">select</option>

                      {(Array.isArray(leadData.users)
                        ? leadData?.users
                        : []
                      ).map((item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label className="col-md-5 control-label col-form-label col-12 text-md-right">
                      Associate with us as
                    </Label>
                    {/* <Input type="select" name="associate" className="col-md-7 p-0" value={type} onChange={(e) => setType(e.target.value)}>
                          <option value="1">Professional Expert</option>
                          <option value="2">Supplier</option>
                          <option value="3">Industry Expert</option>
                          <option value="4">Enterpreneur</option>
                          <option value="5">Institutional</option>
                        </Input> */}
                    <Select
                      isMulti
                      options={[
                        { value: 1, label: "Professional Expert" },
                        { value: 2, label: "Supplier" },
                        { value: 3, label: "Industry Expert" },
                        { value: 4, label: "Enterpreneur" },
                        { value: 5, label: "Institutional" },
                        { value: 6, label: "Exhibtiors" },
                        { value: 7, label: "Visitors" },
                        { value: 8, label: "Franchise Network" },
                        { value: 9, label: "Startup Network" },
                        { value: 10, label: "Others" },
                        { value: 11, label: "Tata Play" },
                        { value: 12, label: "Lecturer" },
                        { value: 13, label: "Brand Onboarding" },
                      ]}
                      onChange={handleAssociate}
                      value={multiAssociate}
                      invalid={!!errors.type}
                      className="col-md-7"
                    />
                  </FormGroup>
                </Col>
                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 1) ||
                  multiAssociate.find((m) => m.value == 4) ||
                  multiAssociate.find((m) => m.value == 6) ||
                  multiAssociate.find((m) => m.value == 7) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="name"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          {multiAssociate.find((m) => m.value == 4)
                            ? "Enterpreneur Name"
                            : "Professional Name"}
                        </Label>
                        <Input
                          id="name"
                          className="col-md-7"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          invalid={!!errors.name || selectError.name}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.name ? errors.name[0] : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 2) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="supplier"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Supplier Type
                        </Label>
                        <Input
                          type="select"
                          id="supplier"
                          className="col-md-7 custom-select"
                          value={supplier}
                          onChange={(e) => setSupplier(e.target.value)}
                          invalid={!!errors.supplier}
                        >
                          <option value="">select</option>
                          <option value="1">Platinum</option>
                          <option value="2">Gold</option>
                          <option value="3">Silver</option>
                          <option value="4">General</option>
                        </Input>

                        <FormFeedback className="offset-5">
                          {!!errors.supplier ? errors.supplier[0] : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 3) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="name"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Expert Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="name"
                          className="col-md-7"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          invalid={!!errors.name || selectError.name}
                        />
                        <FormFeedback className="offset-5">
                          {(!!errors.name ? errors.name[0] : "") ||
                            selectError.name}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 2) ||
                  multiAssociate.find((m) => m.value == 4) ||
                  multiAssociate.find((m) => m.value == 11) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="bussinessName"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Business Name
                        </Label>
                        <Input
                          id="bussinessName"
                          className="col-md-7"
                          value={bussinessName}
                          onChange={(e) => setBussinessName(e.target.value)}
                          invalid={!!errors.bussinessName}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.bussinessName
                            ? errors.bussinessName[0]
                            : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 5) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="name"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Institutional Name
                        </Label>
                        <Input
                          id="name"
                          className="col-md-7"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          invalid={!!errors.name || selectError.name}
                        />
                        <FormFeedback className="offset-5">
                          {!!errors.name ? errors.name[0] : ""}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}

                {multiAssociate ? (
                  multiAssociate.find((m) => m.value == 5) ? (
                    <Col sm={6}>
                      <FormGroup className="row">
                        <Label
                          for="instituteType"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Choose Institute Type
                        </Label>
                        <Input
                          type="select"
                          id="instituteType"
                          className="col-md-7 custom-select"
                          value={instituteType}
                          onChange={(e) => setInstituteType(e.target.value)}
                        >
                          <option value="Select">Select</option>
                          <option value="University">University</option>
                          <option value="Engineering College">
                            Engineering College
                          </option>
                          <option value="Medical College">
                            Medical College
                          </option>
                          <option value="Research Institute">
                            Research Institute
                          </option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="College">College</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Sector Skill Council">
                            Sector Skill Council
                          </option>
                          <option value="Association">Association</option>
                          <option value="other">Others</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  ) : null
                ) : null}
                {multiAssociate ? (
                  multiAssociate.find((m) => m.value != 5) ? (
                    <>
                      {multiAssociate ? (
                        multiAssociate.find((m) => m.value == 1) ||
                        multiAssociate.find((m) => m.value == 5) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="industries"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Service Category
                              </Label>
                              <Select
                                isMulti
                                options={[...professionalservices, obj]}
                                onChange={handleIndustriesChange}
                                value={permissions}
                                className="col-md-7 p-0"
                              />
                              <FormFeedback className="d-block">
                                {!!errors.permissions
                                  ? errors.permissions[0]
                                  : ""}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="industries"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Sector/Industry
                              </Label>
                              <Select
                                isMulti
                                options={[...sectorList, obj]}
                                className="col-md-7 p-0"
                                onChange={handleIndustriesChange}
                                value={industory}
                              />
                              <FormFeedback className="d-block offset-5">
                                {!!errors.industory ? errors.industory[0] : ""}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        )
                      ) : null}
                      {industory.some((item) => item.value === "others") ? (
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="other"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Other
                            </Label>
                            <Input
                              id="other"
                              className="col-md-7"
                              value={other}
                              onChange={(e) => setOther(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="industries"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Choose Industry
                            </Label>
                            <Select
                              options={subIndustry}
                              isMulti
                              className="col-md-7 p-0"
                              onChange={(value) => {
                                if (value) {
                                  const parent = value.filter(
                                    (v) => v.parent === 0
                                  );
                                  value = value.filter(
                                    (v) =>
                                      !parent.some((p) => p.id === v.parent)
                                  );
                                }
                                setSubSubcategory(value);
                              }}
                              value={subsubcategory}
                            />
                            <FormFeedback className="d-block offset-5">
                              {!!errors.subsubcategory
                                ? errors.subsubcategory[0]
                                : ""}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                    </>
                  ) : null
                ) : null}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      id="address"
                      className="col-md-7"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      invalid={!!errors.address}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.address ? errors.address[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="pincode"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Pincode
                    </Label>
                    <Input
                      id="pincode"
                      className="col-md-7"
                      value={pincode}
                      onChange={(e) =>
                        ((_.size(e.target.value) < 7 &&
                          !isNaN(parseInt(e.target.value))) ||
                          _.isEmpty(e.target.value)) &&
                        setPincode(e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="city"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      id="city"
                      className="col-md-7"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      invalid={!!errors.city}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.city ? errors.city[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="state"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      id="state"
                      className="col-md-7"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      invalid={!!errors.state}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.state ? errors.state[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="contactPerson"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Contact Person
                    </Label>
                    <Input
                      id="contactPerson"
                      className="col-md-7"
                      value={contactPerson}
                      onChange={(e) => setContactPerson(e.target.value)}
                      invalid={!!errors.contactPerson}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.contactPerson ? errors.contactPerson[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile No
                    </Label>
                    <Input
                      type="text"
                      id="mobile"
                      value={mobile}
                      onChange={(e) =>
                        e.target.value.length < 11 &&
                        !isNaN(e.target.value) &&
                        setMobile(e.target.value)
                      }
                      className="col-md-7 p-0"
                    />
                    <FormFeedback
                      className="d-block offset-5"
                      valid={_.size(mobile) == 10}
                    >
                      {_.size(mobile) == 10 && valid}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="phoneNo"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Phone No
                    </Label>
                    <Input
                      id="phoneNo"
                      className="col-md-7"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      invalid={!!errors.phoneNo}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.phoneNo ? errors.phoneNo[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email Id
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      className="col-md-7"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!!errors.email}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.email ? errors.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="websiteUrl"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Website(if any)
                    </Label>
                    <Input
                      id="websiteUrl"
                      className="col-md-7"
                      value={websiteUrl}
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                      invalid={!!errors.websiteUrl}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.websiteUrl ? errors.websiteUrl[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="designation"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Designation<span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="designation"
                      className="col-md-7"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      invalid={selectError.designation}
                    />
                    <FormFeedback className="offset-5">
                      {selectError.designation}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="experience"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Total experience<span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="experience"
                      className="col-md-7"
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                      invalid={selectError.experience}
                    />
                    <FormFeedback className="offset-5">
                      {selectError.experience}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.remarks ? errors.remarks[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="source"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Source
                    </Label>
                    <Input
                      type="select"
                      id="source"
                      className="col-md-7 custom-select"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="IID Reference">IID Reference</option>
                      <option value="Google">Google</option>
                      <option value="Youtube">Youtube</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Walking">Walking</option>
                      <option value="Exhibition">Exhibition</option>
                      <option value="Event">Event</option>
                      <option value="Other">Other</option>
                    </Input>
                    <FormFeedback className="offset-5">
                      {!!errors.source ? errors.source[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col> */}

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label> Source</Label>
                      </Col>

                      <Col>
                        <Input
                          type="select"
                          className="custom-select"
                          value={leadSource}
                          onChange={(e) => setleadSource(e.target.value)}
                        >
                          <option>Select</option>
                          {sources.map((asu) => (
                            <option value={asu.label} key={asu.label}>
                              {asu.label}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                {leadSource === "Exhibition" || leadSource === "Events" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="eventName"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Event Date
                      </Label>
                      <Input
                        id="eventDate"
                        type="date"
                        className="col-md-7"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                      />
                      <FormFeedback className="offset-5">
                        {!!errors.eventDate ? errors.eventDate[0] : ""}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                {leadSource === "Exhibition" || leadSource === "Events" ? (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="eventName"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Event Name
                      </Label>
                      <Input
                        id="eventName"
                        className="col-md-7"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        invalid={!!errors.eventName}
                      />
                      <FormFeedback className="offset-5">
                        {!!errors.eventName ? errors.eventName[0] : ""}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}

                <Col sm={6}>
                  <FormGroup className="row">
                    {multiAssociate ? (
                      multiAssociate.find((m) => m.value == 0) ||
                      multiAssociate.find((m) => m.value == 1) ||
                      multiAssociate.find((m) => m.value == 3) ||
                      multiAssociate.find((m) => m.value == 6) ||
                      multiAssociate.find((m) => m.value == 7) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Profile Pic
                        </Label>
                      ) : null
                    ) : null}
                    {multiAssociate ? (
                      multiAssociate.find((m) => m.value == 2) ||
                      multiAssociate.find((m) => m.value == 11) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Company Logo/Profile Pic
                        </Label>
                      ) : null
                    ) : null}

                    {multiAssociate ? (
                      multiAssociate.find((m) => m.value == 4) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Business Logo
                        </Label>
                      ) : null
                    ) : null}
                    {multiAssociate ? (
                      multiAssociate.find((m) => m.value == 5) ? (
                        <Label
                          for="profilePic"
                          className="col-md-5 control-label col-form-label col-12 text-md-right"
                        >
                          Institutional Logo
                        </Label>
                      ) : null
                    ) : null}

                    <Col md={7} className="p-0">
                      <Input
                        type="file"
                        id="thumbnail"
                        data-default-file={
                          typeof thumbnail === "string"
                            ? `https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/${thumbnail}`
                            : ""
                        }
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={handleImageChange}
                      />
                      <FormText color="danger">
                        accept only PNG, JPEG and JPG{" "}
                      </FormText>
                      {!_.isEmpty(thumbnail) && (
                        <a
                          href={`https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/${thumbnail}`}
                          download
                        >
                          <img
                            src={`https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/${thumbnail}`}
                            height="70px"
                            width="70px"
                          />
                        </a>
                      )}
                    </Col>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Lead Status
                    </Label>

                    <Input
                      type="select"
                      value={leadStatus}
                      onChange={({ target }) => setLeadStatus(target.value)}
                      className="w-100 col-md-7 mb-3"
                    >
                      <option value="">Select</option>
                      <option value="converted">Converted</option>
                      <option value="active">Active</option>
                      <option value="shoot-plan">Shoot Plan</option>
                      <option value="follow-up">Follow up</option>
                      <option value="not-intrested">Not Intrested</option>
                    </Input>
                  </Row>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Disposition Type
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDispositionType(e.target.value)}
                      value={dispositiontype}
                    >
                      <option value=""> Select </option>

                      <option value="CallBack/Busy">CallBack/Busy</option>
                      <option value="Franchise Fee Picked">
                        Franchise Fee Picked
                      </option>
                      <option value="Membership Picked">
                        Membership Picked
                      </option>
                      <option value="On Negotiation">On Negotiation</option>
                      <option value="Praposal Shared">Praposal Shared</option>
                      <option value="Budget Issue">Budget Issue</option>
                      <option value="Wrong Number">Wrong Number</option>

                      <option value="Meeting Met">Meeting Met</option>
                      <option value="Not Reachable">Not Reachable</option>
                      <option value="Not Answering/Ringing">
                        Not Answering/Ringing
                      </option>
                      <option value="Invalid_Number">Invalid Number</option>
                      <option value="Meeting Booked">Meeting Booked</option>
                      <option value="Token Picked">Token Picked</option>
                      <option value="Membership Picked">
                        Membership Picked
                      </option>
                      <option value="Fee Picked">Fee Picked</option>
                      <option value="Switch_Off">Switch off</option>
                      <option value="Dump Lead">Dump Lead</option>
                      <option value="Follow_Up_Hot">Follow up(Hot)</option>
                      <option value="Meeting_Align">Meeting Align</option>
                      <option value="Meeting_Done">Meeting Done</option>
                      <option value="Follow_Up">Follow up</option>
                      <option value="Call_Back">Call Back</option>
                      <option value="Not_Interested">Not Interested</option>
                      <option value="Payment_Done">Payment Done</option>
                      <option value="Call_Rejected">Call Rejected</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Is Mail Shared</Label>
                      </Col>

                      <Col>
                        <Input
                          type="select"
                          value={mailShared}
                          onChange={(e) => setMailShared(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>

                <div className="col-md-6">
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Linkedin ID
                    </Label>

                    <Input
                      className="col-md-7"
                      value={linkedID}
                      onChange={({ target }) => setLinkedID(target.value)}
                    />
                  </Row>
                </div>

                <div className="col-md-6 my-2">
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Facebook ID
                    </Label>

                    <Input
                      className="col-md-7"
                      value={facebookID}
                      onChange={({ target }) => setFacebookID(target.value)}
                    />
                  </Row>
                </div>

                <div className="col-md-6 my-2">
                  <Row>
                    <Label className="col-md-5 text-md-right">
                      Instagram ID
                    </Label>

                    <Input
                      className="col-md-7"
                      value={instagramID}
                      onChange={({ target }) => setInstagramID(target.value)}
                    />
                  </Row>
                </div>

                <div className="col-md-6 ">
                  <Row>
                    <Label className="col-md-5 text-md-right">Other</Label>

                    <Input
                      className="col-md-7 "
                      value={otherIds}
                      onChange={({ target }) => setOtherIds(target.value)}
                    />
                  </Row>
                </div>

                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </main>
    </MainWrapper>
  );
};

export default Edit;
