import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../../MainWrapper";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAllList, assignPermissions } from "../../../store/actions/AsignActions";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Collapse,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import { FiEye } from "react-icons/fi";
import { Redirect } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Loader from "../../../layouts/Loader";
const AssignPermission = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState("");

  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector(({ auth }) => auth);
  const { loading, status, error, details, assignPermissionStatus } =
    useSelector(({ assign }) => assign);

  useEffect(() => {
    dispatch(getAllList(token));
  }, [token]);
  useEffect(() => {
    if (status) {
      setRoles(details.roles);
      setPermissions(details.data);
    }
  }, [status]);
  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setIsOpen(true);
    setSelectedPermission(
      roles.some((r) => r.id === parseInt(e.target.value))
        ? roles
            .find((r) => r.id === parseInt(e.target.value))
            .permissions.reduce((init, obj) => [...init, obj.id], [])
        : []
    );
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleAllChecked = (ids) => {
    setSelectedPermission(
      ids.every((id) => selectedPermission.includes(id))
        ? selectedPermission.filter((s) => !ids.includes(s))
        : [...selectedPermission.filter((s) => !ids.includes(s)), ...ids]
    );
  };
  const handleChecked = (id) => {
    setSelectedPermission(
      selectedPermission.some((s) => s === id)
        ? selectedPermission.filter((s) => s !== id)
        : [...selectedPermission, id]
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const arr = {
      role,
      selectedPermission,
      token,
    };
    dispatch(assignPermissions(arr));
  };
  useEffect(() => {
    if (assignPermissionStatus) {
      NotificationManager.success("Permission Assign successfully", "Sccess");
    }
  }, [assignPermissionStatus]);
  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Role Permissions</h4>
                </div>
              </div>
            </div>
            <Loader loading={loading}>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body pl-2 pr-2">
                        <div className="row">
                          <div className="col-12 col-md-6 offset-md-3">
                            <form>
                              <FormGroup>
                                <Input
                                  type="select"
                                  className="custom-select"
                                  name="role"
                                  id="role"
                                  onChange={handleRoleChange}
                                >
                                  <option value="">Choose...</option>
                                  {roles.length > 0
                                    ? roles.map((ro) => (
                                        <option value={ro.id} key={ro.id}>
                                          {ro.name}
                                        </option>
                                      ))
                                    : null}
                                </Input>
                                <FormFeedback className="offset-3">
                                  {!!error.selectrole
                                    ? error.selectrole[0]
                                    : ""}
                                </FormFeedback>
                              </FormGroup>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col sm={12}>
                        {role ? (
                          <Fragment>
                            <Card className="border rounded-0">
                              <CardHeader>
                                <a
                                  href="#"
                                  onClick={handleToggle}
                                  className="d-block text-white"
                                >
                                  <FiEye /> Modules & Activities
                                </a>
                              </CardHeader>
                              <Collapse isOpen={isOpen}>
                                <CardBody className="p-2">
                                  <div className="table-responsive border mb-3">
                                    <Table className="table table-hover table-sm small m-0">
                                      <thead className="bg-dark text-white">
                                        <tr>
                                          <th className="text-center">
                                            Module
                                          </th>
                                          <th className="text-center">All</th>
                                          <th className="text-center">View</th>
                                          <th className="text-center">Add</th>
                                          <th className="text-center">
                                            Update
                                          </th>
                                          <th className="text-center">
                                            Delete
                                          </th>
                                          <th className="text-center">
                                            Copy/Clone
                                          </th>
                                          <th className="text-center">
                                            SendSMS
                                          </th>
                                          <th className="text-center">
                                            SendEmail
                                          </th>
                                          <th className="text-center">
                                            ImportExcel
                                          </th>
                                          <th className="text-center">
                                            ExportExcel
                                          </th>
                                          <th className="text-center">
                                            ExportPDF
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {permissions.map((ps, i) => (
                                          <tr key={i}>
                                            <td className="visible-md visible-lg text-center">
                                              {ps.module}
                                            </td>
                                            <td className="visible-md visible-lg text-center">
                                              <input
                                                type="checkbox"
                                                id={ps.module}
                                                checked={ps.child
                                                  .map((c) => c.id)
                                                  .every((c) =>
                                                    selectedPermission.includes(
                                                      c
                                                    )
                                                  )}
                                                onChange={() =>
                                                  handleAllChecked(
                                                    ps.child.map((c) => c.id)
                                                  )
                                                }
                                              />
                                            </td>
                                            {ps.child.map((ch, i) => (
                                              <td
                                                key={i}
                                                className="visible-md visible-lg text-center"
                                              >
                                                <input
                                                  id={ch.name}
                                                  type="checkbox"
                                                  checked={selectedPermission.some(
                                                    (s) => s === ch.id
                                                  )}
                                                  onChange={() =>
                                                    handleChecked(ch.id)
                                                  }
                                                />
                                              </td>
                                            ))}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-success"
                                    >
                                      Save Settings{" "}
                                    </button>
                                  </div>
                                </CardBody>
                              </Collapse>
                            </Card>
                          </Fragment>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Loader>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default AssignPermission;
