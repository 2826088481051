import React, { useMemo, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { FiTrash } from "react-icons/fi";
import EditTask from "./EditTask";
import {
  taskDestroy,
  changeReducerState,
} from "../../../store/actions/TaskActions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
const TaskList = ({ taskList, type }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [taskData, setTaskData] = useState({});
  const tasks = useMemo(() => taskList, [taskList]);
  const { auth } = useSelector(({ auth }) => auth);
  const { taskDeleteStatus } = useSelector(({ task }) => task);
  //HANDLE ONE DELETE
  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
        type,
      };
      dispatch(taskDestroy(delArr, auth?.token));
    }
  };
  useMemo(() => {
    if (taskDeleteStatus) {
      dispatch(changeReducerState());
      NotificationManager.success("Deleted Successfully", "Sccess");
    }
  }, [taskDeleteStatus]);
  const handleModal = (e, data) => {
    e.preventDefault();
   
    setModal(!modal);
    setTaskData(data);
  };

  const handleChecked = (e, id) => {
    if (e.target.checked) {
      const is_completed = 1;
      axios
        .post(
          `/api/crm/update-task-status`,
          { is_completed, id },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          NotificationManager.success(res.data.message);
        })
        .catch((err) => console.log(err));
    }
  };
console.log(taskList,"taskList")
  return (
    <>
      <div className="accordion" id="ActivityAccordion">
        <div className="card">
          <div className="card-header p-0" id="heading-1">
            <Link
              className="card-title m-0 h5 text-white d-block p-2"
              to="#"
              data-toggle="collapse"
              data-target="#collapse-1"
              aria-expanded="true"
              aria-controls="collapse-1"
            >
              Task
            </Link>
          </div>
          <div
            id="collapse-1"
            className="collapse show"
            aria-labelledby="heading-1"
            data-parent="#ActivityAccordion"
          >
            <div className="card-body p-2">
              <div className="table-responsive">
                <table className="table table-sm m-0">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>S.no</th>
                      <th>Subject</th>
                      <th>Activity Type</th>
                      <th>Assigned to</th>
                      <th>Start-Date Time</th>
                      <th>Remarks</th>
                      <th>Task Done</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      <Fragment>
                        {tasks
                          ? tasks?.map((leadsl, i) => (
                              <tr key={Math.random()}>
                                <td>{i + 1}</td>
                                <td>{leadsl.subject}</td>
                                <td>{leadsl.activity}</td>

                                <td>
                                  {leadsl.crm_agent ? leadsl.crm_agent.name : ""}
                                </td>
                                <td>
                                  {leadsl.start_date} {leadsl.start_time}
                                </td>

                                <td>{leadsl?.remark}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChecked(e, leadsl?.id)
                                    }
                                  />
                                </td>

                                <td className="text-right">
                                  <Link
                                    to={`#`}
                                    className="mr-2 text-info"
                                    onClick={(e) => {
                                      handleModal(e, leadsl);
                                    }}
                                  >
                                    <FiEdit />
                                  </Link>

                                  {/* <Link
                                    to={`#`}
                                    className="text-danger"
                                    onClick={(e) =>
                                      handleOneDelete(
                                        e,
                                        leadsl.id,
                                        leadsl.edit_url
                                      )
                                    }
                                  >
                                    <FiTrash />
                                  </Link> */}
                                </td>
                              </tr>
                            ))
                          : null}
                        <EditTask
                          leadsl={taskData}
                          setModal={setModal}
                          modal={modal}
                          handleModal={handleModal}
                        />
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskList;
