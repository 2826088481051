import Axios from "axios";

export const ProjectReportList = (token) => {
  console.log(token, "test");
  return {
    type: "PROJECTREPPORT_FULFILLED",
    payload: Axios.get(`/api/crm/projectreport-list`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const projectreportCreate = (data, token) => async (dispatch) => {
  try {
    const resp = await Axios.post(`/api/crm/book-projectreport`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: "PROJECTREPORT_CREATE_FULFILLED",
      payload: { message: "Report Added Successfully" },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "PROJECTREPORT_CREATE_REJECTED",
      payload: error,
    });
  }
};

export const customizeProjectReport = (data, token) => async (dispatch) => {
  try {
    const resp = await Axios.post(`/api/crm/add-customize-report`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: "CUSTOMIZE_PROJECTREPORT_CREATE_FULFILLED",
      payload: { message: "Report Added Successfully" },
    });
  } catch (error) {
    dispatch({
      type: "CUSTOMIZE_PROJECTREPORT_CREATE_REJECTED",
      payload: error,
    });
  }
};

export const projectreportEdit = (data, token, workshop) => {
  return {
    type: "PROJECTREPORT_EDIT",
    payload: Axios.post(
      `/api/crm/update-booked-project-report/${workshop}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err) => err),
  };
};

export const changeReducerState = () => {
  return {
    type: "CHANGE_MEMBERSHIP_STATE",
  };
};
