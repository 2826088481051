/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
  FiPhoneCall,
} from "react-icons/fi";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FcCallback } from "react-icons/fc";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { ImHappy2 } from "react-icons/im";
import { BiDetail } from "react-icons/bi";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadsDetail,
  getDashboardCount,
  SalesDashboardCount,
  DashboardCountById,
} from "../store/actions/DashboardActions";
import {
  Input,
  CustomInput,
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Label,
  Button,
} from "reactstrap";
import Pagination from "react-js-pagination";
import Accordian from "../../src/components/dashboard-accordian/Accordian";
const AgentDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leaddetails, dashboardcount, dashboardcountsale, countbyid } =
    useSelector((state) => state.dashboardReducer);
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [date, setDate] = useState("");
  const [fdate, setFdate] = useState("");
  const [toggle, setToggle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    dispatch(getLeadsDetail(token, paginate, page));
    dispatch(SalesDashboardCount(token, startDate, endDate));
  }, [token, endDate]);

  useEffect(() => {
    dispatch(DashboardCountById(token, toggle, date, fdate));
  }, [toggle, fdate]);

  useEffect(() => {
    SetLead(leaddetails?.convertedlead?.data);
    setFrom(leaddetails?.convertedlead ? leaddetails.convertedlead.from : null);
    setTo(leaddetails?.convertedlead ? leaddetails.convertedlead.to : null);
    setTotal(
      leaddetails?.convertedlead ? leaddetails.convertedlead.total : null
    );
    setPerPage(
      leaddetails?.convertedlead ? leaddetails.convertedlead.per_page : null
    );
  }, [leaddetails, dashboardcount]);
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                  Agent
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label htmlFor="" className="form-label  mb-2">
                    Start Date
                  </label>
                  <Input
                    type="date"
                    style={{ borderRadius: "6px" }}
                    value={startDate}
                    onChange={({ target: { value } }) => setStartDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label htmlFor="" className="form-label mb-2">
                    End Date
                  </label>
                  <Input
                    type="date"
                    style={{ borderRadius: "6px" }}
                    value={endDate}
                    onChange={({ target: { value } }) => setEndDate(value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
           
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darked">
                <Link
                    to={`/leads?StartDate=${leadDate}`}>
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box blue">
                          <FaUser color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.todayLead}
                        </h3>
                        <div className="mb-0 font-commom-2">Today Lead</div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-green">
                <Link
                    to={`/leads`}>
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box skyblue">
                          <FaUserCheck color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalLead}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Lead</div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darkblue">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box orange">
                          <FaPhoneAlt color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalConnected}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Connected
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-orange">
                <Link
                    to={`/leads?desposition=Interested`}>
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box pink">
                          <ImHappy2 color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalInterested}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Interested
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                <Link
                    to={`/leads?desposition=Not_Interested`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <FaSadCry color="#33d69f" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalNotInterested}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Not Interested
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkred">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalDetailShared}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Detailed{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                <Link
                    to={`/leads?desposition=CALLBK - Call Back *`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalCallBack}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Call Back
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                <Link
                    to={`/leads?Priority=Hot`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <FaSadCry color="#33d69f" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.getHot}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Hot Lead</div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                <Link
                    to={`/leads?Priority=Cold`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkred">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.getCold}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Cold Lead{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                <Link
                    to={`/leads?Priority=Warm`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.getWarm}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Warm Lead
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                <Link
                    to={`/leads?Priority=Converted`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.getConverted}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Converted
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                <Link
                    to={`/leads?Priority=Perspective`}>
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.Perspective}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Perspective
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.totalActive}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Activity</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <HiPhoneIncoming color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcountsale?.todayActive}
                        </h3>
                        <div className="mb-0 font-commom-2">Today Activity</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row cursor-pointer">
              {dashboardcountsale?.getTeamList?.map((consultant) => (
                <div className="col-md-4" key={consultant?.id}>
                  <div>
                    {/* <Card>
                      <CardHeader onClick={() => setToggle(consultant?.id)}>
                        <div className="d-flex justify-content-between">
                          <div className="font-weight-bold cursor-pointer">
                            {consultant?.name}
                          </div>
                          <div>
                            <FaChevronDown />
                          </div>
                        </div>
                      </CardHeader>
                      <Collapse
                        isOpen={toggle === consultant?.id ? true : false}
                      >
                        <CardBody>
                          <Label>Start Date</Label>
                          <Input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />

                          <Label className="pt-2">End Date</Label>
                          <Input
                            type="date"
                            value={fdate}
                            onChange={(e) => setFdate(e.target.value)}
                          />

                          <div className="d-flex justify-content-between pt-4">
                            {" "}
                            <div>
                              <h6>Today Lead</h6>
                            </div>{" "}
                            <div>{countbyid?.todayLead}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Lead</h6>
                            </div>{" "}
                            <div>{countbyid?.totalLead}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Connected</h6>
                            </div>{" "}
                            <div>{countbyid?.totalConnected}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Interested</h6>
                            </div>{" "}
                            <div>{countbyid?.totalInterested}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Not Interested</h6>
                            </div>{" "}
                            <div>{countbyid?.totalNotInterested}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Detailed Shared</h6>
                            </div>{" "}
                            <div>{countbyid?.totalDetailShared}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total CallBack</h6>
                            </div>{" "}
                            <div>{countbyid?.totalCallBack}</div>
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card> */}
                    <Accordian
                      consultant={consultant}
                      key={consultant?.id}
                      type="4"
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.no</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Campaign</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lead?.map((le, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>{le.full_name}</td>
                                    <td>{le.email}</td>
                                    <td>{le.mobile}</td>
                                    <td>{le?.compains}</td>
                                    <td>{le?.state}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default AgentDashboard;
