import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import MainWrapper from "../MainWrapper";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Redirect } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  projectreportCreate,
  changeReducerState,
} from "../../store/actions/IndustrialReportActions";

import _ from "lodash";
import Creatable, { components } from "react-select";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const addIndustrialReport = memo(({ leadDetail, setWsStatus, userId }) => {
  const [fields, setFields] = useState({
    name: leadDetail ? leadDetail?.full_name : leadDetail?.name,
    mobile: leadDetail?.mobile ?? "",
    email: leadDetail?.email ?? "",
    pincode: leadDetail?.pincode ?? "",
    leadId: leadDetail?.id ?? "",
  });
  const [project, setProject] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({});
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [projectreport, setProjectreport] = useState("");
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);

  const {
    projectReports,
    errors,
    createStatus,
    projectReportStatus,
    users: { user },
  } = useSelector((state) => state.projectReport);
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Add Industrial Report",
      description: "Add Industrial Report Sale",
    };
    if (projectReportStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [projectReportStatus]);
  useEffect(() => {
    axios
      .get(`/api/crm/databankreport/list`)
      .then((res) => {
        setSectors(res.data.reportTypes);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSector = (e) => {
    setSubCategory(e);

    axios
      .get(`/api/crm/databankreport-categories/list/${e.value}`)
      .then((res) => {
        setIndustry(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  };

  const handleProjectReport = (e) => {
    setProjectreport(e);
    axios
      .get(`/api/crm/databankreport-data/${subCategory.value}/${e.value}`)
      .then((res) => {
        setProject(res?.data?.industrialreports);
      })
      .catch((err) => console.log(err));
  };

  const selectedMemberShip = useMemo(() => {
    if (fields?.selectedMemberShip) {
      let selected = project?.find((v) => v?.id == fields?.selectedMemberShip);
      return selected;
    }
  }, [fields?.selectedMemberShip]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFields({ ...fields, [id]: value });
  };
  const validationForm = () => {
    let formIsValid = true;
    setError(error);
    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
     if (validationForm()) {
      // const data = {
      //   project_id: fields?.selectedMemberShip,
      //   user_id: fields?.id,
      //   name: fields?.name,
      //   email: fields?.email,
      //   mobile: fields?.mobile,
      //   payment_id: fields?.payment_id,
      //   type: "databank",
      //   amount: selectedMemberShip?.price,
      //   associate_amount: selectedMemberShip?.associate_amount,
      //   project_name: selectedMemberShip?.title,
      //   pincode: fields?.pincode,
      //   mode: fields?.mode,
      //   paymentType: fields?.paymentType,
      //   leadId: leadDetail?.id ?? "",
      //   engaged: 1,
      // };
      const formData = new FormData();

      formData.append("payment_img", file);
      formData.append("type", "databank");
      formData.append("project_id", fields?.selectedMemberShip == undefined ? "" : fields?.selectedMemberShip);
      formData.append("user_id", userId ? userId : fields?.id);
      formData.append("name", fields?.name);
      formData.append("email", fields?.email);
      formData.append("mobile", fields?.mobile);
      formData.append("payment_id", fields?.payment_id == undefined ? "" : fields?.payment_id);
      formData.append("amount", selectedMemberShip?.price == undefined ? "" : selectedMemberShip?.price);

      formData.append("project_name", selectedMemberShip?.title);
      formData.append("pincode", fields?.pincode);
      formData.append("mode", fields?.mode);
      formData.append("paymentType", fields?.paymentType);
      formData.append("leadId", leadDetail?.id ?? "");
      formData.append("engaged", 1);
      formData.append("payment_img", file);

      dispatch(projectreportCreate(formData, token));
     }
  };
  console.log(errors ,'error')
  useEffect(() => {
    setFields({
      ...fields,
      name: leadDetail ? leadDetail?.full_name : leadDetail?.name,
      mobile: leadDetail?.mobile ?? "",
      email: leadDetail?.email ?? "",
    });
  }, [leadDetail, modal]);

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 &&
    selectValue.length < parseInt(selectedMemberShip?.industries);
  useEffect(() => {
    if (createStatus) {
      setWsStatus((prev) => !prev);
      dispatch(changeReducerState());
      NotificationManager.success("Created Successfully", "Success");
    }
  }, [createStatus]);

  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Industrial Report
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Add Industrial Report
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="sector">Industrial Report Type</Label>

                  <Creatable
                    options={[...sectors]}
                    onChange={handleSector}
                    value={subCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">{errors.sector}</FormFeedback>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="industry">Industrial Report Sector</Label>
                  <Creatable
                    options={[...industry]}
                    onChange={(e) => {
                      setSubSubCategory(e);
                      handleProjectReport(e);
                    }}
                    // onChange={handleProjectReport}
                    value={subSubCategory}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errors.industry}
                </FormFeedback>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="selectedMemberShip">Industrial Report <span className="text-danger">*</span></Label>
                  <Input
                    type="select"
                    className="custom-select"
                    id="selectedMemberShip"
                    invalid={!!errors?.project_id}
                    value={
                      fields?.selectedMemberShip
                        ? fields?.selectedMemberShip
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <option>select</option>
                    {_.size(project)
                      ? _.map(project, (mem) => (
                          <option value={mem?.id} key={mem?.id}>
                            {mem?.title}
                          </option>
                        ))
                      : null}
                  </Input>
                  <FormFeedback>
                    {!!errors?.selectedMemberShip
                      ? errors?.selectedMemberShip[0]
                      : ""}
                  </FormFeedback>
                  <FormFeedback>
                    {!!errors?.project_id
                      ? errors?.project_id[0]
                      : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="mobile">Mobile <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.mobile}
                    id="mobile"
                    value={fields?.mobile ? fields?.mobile : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.mobile ? errors.mobile[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">Name <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.name}
                    id="name"
                    value={fields.name ? fields.name : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.name ? errors.name[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">Email <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.email}
                    id="email"
                    value={fields.email ? fields.email : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.email ? errors.email[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Amount <span className="text-danger">*</span></Label>
                  <Input
                    id="total"
                    invalid={!!errors.amount}
                    value={selectedMemberShip?.price}
                    disabled
                  />
                  <FormFeedback>
                    {!!errors.amount ? errors.amount[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="total">Associate_amount</Label>
                  <Input
                    id="total"
                    invalid={!!errors.associate_amount}
                    value={selectedMemberShip?.associate_amount}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    invalid={!!errors.pincode}
                    id="pincode"
                    value={fields.pincode ? fields.pincode : ""}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <Label for="mode">Payment Mode</Label>
                  <Input
                    type="select"
                    invalid={!!errors.mode}
                    id="mode"
                    value={fields.mode ? fields.mode : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </Input>
                  <FormFeedback>
                    {!!errors.mode ? errors.mode[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="paymentType">Payment Type</Label>
                  <Input
                    type="select"
                    invalid={!!errors.paymentType}
                    id="paymentType"
                    value={fields.paymentType ? fields.paymentType : ""}
                    onChange={handleChange}
                    className="custom-select"
                  >
                    <option>select</option>
                    <option value="upi">UPI</option>
                    <option value="imps">IMPS</option>
                    <option value="cheque">Cheque</option>
                    <option value="internet-banking">Internet banking</option>
                    <option value="other">Other</option>
                  </Input>

                  <FormFeedback>
                    {!!errors.paymentType ? errors.paymentType[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="payment_id">Payment Id <span className="text-danger">*</span></Label>
                  <Input
                    invalid={!!errors.payment_id}
                    id="payment_id"
                    value={fields.payment_id ? fields.payment_id : ""}
                    onChange={handleChange}
                  />
                  <FormFeedback>
                    {!!errors.payment_id ? errors.payment_id[0] : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <label for="formFileSm" class="form-label">
                  Payment Receipt{" "}
                </label>
                <input
                  class="form-control form-control-sm p-1 mb-2"
                  id="formFileSm"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Col>
              <Col sm="10">
                <div className="text-center mb-3">
                  <Button
                    color="secondary"
                    onClick={() => setModal(false)}
                    className="mr-2"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!_.isEmpty(user?.plan_detail)}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default addIndustrialReport;
