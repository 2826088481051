import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { Table, Spinner, FormGroup, Label,Input } from "reactstrap";
import PieChart from "../Chart/PieChart";
import axios from "axios";
import moment from "moment";
const CrmSourceReport = () => {
  const [pieChartData, setPieChartData] = useState([]);
  const [data, setData] = useState([]);
  const [prevIncome, setPrevIncome] = useState([]);
  const [nextIncome, setNextIncome] = useState([]);
  const[chartData,setChartData]=useState()
  const[verticalTarget,setVerticalTarget]=useState()
  const [filters, setFilters] = useState({
    startDate: "2023-08-08",
    endDate: moment().format("YYYY-MM-DD"),
  })
  useEffect(() => {
    {
      setPieChartData(chartData);
    }
  }, [chartData]);
  const getAmount = (curr,prev) => {
    const arr = [...Object.values(curr),...Object.values(prev)]
    const exist = {}
    let newArr = []
    
    for(let i = 0;  i< arr.length;i++){
      if(arr[i].type in exist && arr[i].amount){
        exist[arr[i].type]+=parseInt(arr[i].amount)
       newArr =  newArr.map((item)=>item.label===arr[i].type?({...item,data:exist[arr[i].type]}):item)
      }
      else{
        if(arr[i].amount){
        exist[arr[i].type]=parseInt(arr[i].amount)
        newArr.push({label:arr[i].type,data:exist[arr[i].type]})
        }
      }
    }
    return newArr
  }
  useEffect(() => {
    axios
      .get(`/api/crm/get-agent-target?startDate=${filters.startDate}&endDate=${filters.endDate}`)
      .then((res) => {
        setData(res?.data);
        setPrevIncome(Object.values(res?.data?.getAgentPaymentCurrentMonth));
        setNextIncome(Object.values(res?.data?.getAgentPaymentLastMonth));
        setVerticalTarget(res?.data?.verticalTarget)
        setChartData(getAmount(res?.data?.getAgentPaymentCurrentMonth,res?.data?.getAgentPaymentLastMonth));

      })
      .catch((err) => console.log(err.response));
  }, [filters.startDate,filters.endDate]);

  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target
    setFilters(prev => ({ ...prev, [name]: value }))
  }

 
  return (
    <Fragment>
      <MainWrapper>
        <div className="container mt-5">
          <div className="row">
          <div className="col-md-3 offset-md-6">
                <FormGroup>
                  <Label>
                    Start Date
                  </Label>
                  <Input type="date" name="startDate" value={filters.startDate} onChange={inputHandler} />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label>
                    End Date
                  </Label>
                  <Input type="date" name="endDate" value={filters.endDate} onChange={inputHandler} />
                </FormGroup>
              </div>
            <div className="col-md-8">
              <Table bordered>
                <thead className="green">
                  <tr>
                    <th>Vertical</th>
                    <th>
                       Achived In {data?.getAgentPaymentLastMonth?.month}
                    </th>
                    <th>
                       Achived In {data?.getAgentPaymentCurrentMonth?.month}
                    </th>
                    <th> Target Reduction</th>
                    <th>Actual % Rev. Growth</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.verticalTarget?.map((item) => {
                    return (
                      <tr key={item?.id}>
                        <th scope="row">{item?.vertical_name}</th>
                        <td>{" "}
                          {(((nextIncome
                            .filter(
                              (it) =>
                                it.type === item?.vertical_type.toLowerCase()
                            )
                            .reduce(
                              (accu, curr) => (accu += parseInt(curr?.amount)),
                              0
                            )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)}{" "}%</td>
                        <td>
                          {" "}
                          {(((prevIncome
                            .filter(
                              (it) =>
                                it.type === item?.vertical_type.toLowerCase()
                            )
                            .reduce(
                              (accu, curr) => (accu += parseInt(curr?.amount)),
                              0
                            )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)}{" "}%

                            
                        </td>
                        <td>
                         {

                         (((prevIncome
                              .filter(
                                (it) =>
                                  it.type === item?.vertical_type.toLowerCase()
                              )
                              .reduce(
                                (accu, curr) => (accu += parseInt(curr?.amount)),
                                0
                              )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)-(((nextIncome
                            .filter(
                              (it) =>
                                it.type === item?.vertical_type.toLowerCase()
                            )
                            .reduce(
                              (accu, curr) => (accu += parseInt(curr?.amount)),
                              0
                            )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)<0?0:(((prevIncome
                              .filter(
                                (it) =>
                                  it.type === item?.vertical_type.toLowerCase()
                              )
                              .reduce(
                                (accu, curr) => (accu += parseInt(curr?.amount)),
                                0
                              )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)-(((nextIncome
                            .filter(
                              (it) =>
                                it.type === item?.vertical_type.toLowerCase()
                            )
                            .reduce(
                              (accu, curr) => (accu += parseInt(curr?.amount)),
                              0
                            )/parseInt(item?.target?.replaceAll?.(",",""))))*100).toFixed(2)

                            }%
                          
                              </td>
                        
                        <td>{
                           
                           
                        ((((((nextIncome
                            .filter(
                              (it) =>
                                it.type === item?.vertical_type.toLowerCase()
                            )
                            .reduce(
                              (accu, curr) => (accu += parseInt(curr?.amount)),
                              0
                            ))))+(((prevIncome
                                .filter(
                                  (it) =>
                                    it.type === item?.vertical_type.toLowerCase()
                                )
                                .reduce(
                                  (accu, curr) => (accu += parseInt(curr?.amount)),
                                  0
                                )))))/parseInt(item?.target?.replaceAll?.(",","")))*100).toFixed(3)
                                }%</td>
                      </tr>
                    );
                  })}

                  {/* <tr>
                                        <th scope="row">
                                            Webinar

                                        </th>
                                        <td>
                                            30%


                                        </td>
                                        <td>
                                            16%
                                        </td>
                                        <td>
                                            37%	
                                        </td>
                                        <td>
                                            -23%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            PPR

                                        </th>
                                        <td>
                                            42%

                                        </td>
                                        <td>
                                            8%
                                        </td>
                                        <td>
                                            26%	
                                        </td>
                                        <td>
                                            8%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            Course

                                        </th>
                                        <td>
                                        32%	



                                        </td>
                                        <td>
                                        47%	
                                        </td>
                                        <td>
                                        1%	
                                        </td>
                                        <td>
                                        -16%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            Total

                                        </th>
                                        <td>
                                            49%



                                        </td>
                                        <td>
                                            28%
                                        </td>
                                        <td>
                                            32%
                                        </td>
                                        <td>
                                            -11%
                                        </td>
                                    </tr> */}
                </tbody>
              </Table>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">
                  <PieChart
                    pieChartData={pieChartData}
                    colors={[
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255,140,0)",
                      "rgb(0,206,209)",
                      "rgb(189,183,107)",
                      "rgb(72,209,204)"
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default CrmSourceReport;
