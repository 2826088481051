import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../../MainWrapper";
import { Input, Button, FormFeedback, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect, useSelector, useDispatch } from "react-redux";
import Loader from "../../../layouts/Loader";
import { FiEdit, FiTrash } from "react-icons/fi";
import {
  Priority,
  priorityDelete,
} from "../../../store/actions/PriorityActions";
import { users } from "../../../store/actions/UserActions";
import Pagination from "react-js-pagination";
import _ from "lodash";
const Index = () => {
  const [listData, setListData] = useState([]);
  const [sort, setSort] = useState("desc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(25);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  // get State
  const {
    auth: { token },
  } = useSelector((store) => store.auth);
  const { users: userList } = useSelector((store) => store.user);
  const { loading } = useSelector((store) => store.priority);

  const usersData = useMemo(() => userList?.users, [userList]);
  console.log(usersData?.data);
  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
      };
      dispatch(priorityDelete(delArr, token));
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh(refresh + 1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify(deleteIds),
      };
      dispatch(priorityDelete(delArr, token));
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh(refresh + 1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        listData.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  useEffect(() => {
    dispatch(Priority(token, paginate, search, page, sort));
    dispatch(users(token, paginate, search, page, sort));
  }, [token, paginate, search, page, sort]);
  useEffect(() => {
    if (usersData) {
      setListData(usersData?.data);
      setFrom(usersData?.from);
      setTo(usersData?.to);
      setTotal(usersData?.total);
      setPerPage(usersData?.per_page);
    }
  }, [usersData]);
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Users</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field">
                      <Input
                        type="select"
                        className="custom-select"
                        style={{ height: "30px", width: "80px" }}
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              placeholder="Search..."
                              value={search}
                              onChange={handleSearch}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                              onClick={handleSerchByClick}
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="btn-group mr-1">
                        <a
                          href="import-lead.html"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-download"></i>&nbsp;&nbsp;Import
                          Users
                        </a>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle btn-sm p-1"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-list"></i>&nbsp;&nbsp;Users Tools
                          <span className="caret"></span>
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu" role="menu">
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-status.html"
                            >
                              Lead Status
                            </a>
                          </li>
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-rating.html"
                            >
                              Lead Rating
                            </a>
                          </li>
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-contact-source.html"
                            >
                              Source
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header p-2">
                    <div className="left-box-tools pl-3">
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#sendSMS"
                      >
                        <i className="fa fa-mobile pr-1"></i> SMS
                      </button>
                      <button className="btn btn-secondary openEmail btn-sm pl-1 pr-1 mr-1">
                        <i className="fa fa-envelope pr-1"></i> Email
                      </button>
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleDelete}
                      >
                        <i className="fa fa-trash pr-1"></i> Delete
                      </button>

                      <button
                        className="btn btn-secondary btn-sm"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                    <div className="right-box-tools pr-3">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    S.No.
                                  </div>
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Mobile</th>
                                <th className="font-weight-bold">
                                  Alternate No.
                                </th>
                                <th className="font-weight-bold">Email</th>

                                <th className="font-weight-bold">Sector</th>
                                <th className="font-weight-bold">Industry</th>
                                <th className="font-weight-bold">Source</th>
                                <th className="font-weight-bold">Category</th>
                                <th className="font-weight-bold">
                                  Investment Upto
                                </th>
                                <th className="font-weight-bold">Remark</th>
                                <th className="font-weight-bold">
                                  Particulars
                                </th>
                                <th
                                  style={{ width: 150 }}
                                  className="font-weight-bold text-center"
                                >
                                  Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {listData
                                    ? listData.map((is, i) => (
                                        <tr key={Math.random()}>
                                          <td>
                                            <div className="custom-checkbox custom-control">
                                              {i + 1}
                                            </div>
                                          </td>

                                          <td>
                                            <a
                                              className="c-text-btn-primary"
                                              href={`/user-profile/${is.id}`}
                                            >
                                              {is.name}
                                            </a>
                                            <br />
                                            {is.institute_id !== 300 ? (
                                              <a
                                                href={`/institutes/${is.institute_id}/edit`}
                                                className="text-danger"
                                                target="_blank"
                                              >
                                                {is.institute_id}
                                              </a>
                                            ) : null}
                                          </td>

                                          <td>{is.mobile}</td>
                                          <td>
                                            {_.get(is, "alternate_no", "")}
                                          </td>
                                          <td>{is.email}</td>

                                          <td>
                                            {_.uniq(
                                              _.map(
                                                is.user_industrial_solution,
                                                (uIm) => uIm.name
                                              )
                                            ).join(",")}
                                          </td>
                                          <td>
                                            {_.uniq(
                                              _.map(
                                                is.user_industrial_solution,
                                                (uIm) => {
                                                  return uIm.sub_sub_category
                                                    .filter(
                                                      (st) =>
                                                        st.id == uIm.project
                                                    )
                                                    .map(
                                                      (subsub) => subsub.name
                                                    )
                                                    .join(",");
                                                }
                                              )
                                            ).join(",")}
                                          </td>
                                          <td>{_.get(is, "source", "")}</td>
                                          <td>
                                            {_.get(
                                              is,
                                              "plan_detail_all.name",
                                              ""
                                            )}
                                          </td>
                                          <td>{_.get(is, "investment", "")}</td>
                                          <td>{_.get(is, "remark", "")}</td>
                                          <td>Member</td>
                                          <td className="text-center">
                                            {is.created_at}
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Index;
