import React from 'react'
const initialState = {
    loading: false,
    usersData: {},
}
const FranchiseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FRANCHISE_LIST':
            return { ...state, loading: true }

        case 'GET_FRANCHISE_LIST_FULFILLED':
            return { ...state, loading: false,usersData:action.payload?.crmFranchiseTeam }

        default:
            return state
    }
}

export default FranchiseReducer