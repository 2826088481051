import React, { useState, useEffect, useCallback, useMemo } from "react";
import MainWrapper from "../../components/MainWrapper";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";

const EditAssociateLocation = () => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.auth);

  const [disposition, setDisposition] = useState("");

  const { id } = useParams();

  const { leadData } = useSelector(({ lead }) => lead);

  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [state, setState] = useState("");
  const [model, setModel] = useState("");
  const [data, setData] = useState("");

  const [errors, setErrors] = useState({});

  const history = useHistory();

  const { meetingCreateStatus, loading } = useSelector(
    (state) => state.meetingReducer
  );

  const handleValidate = () => {
    let error = {
      city: "",
    };
    let valid = true;

    if (!city) {
      error["city"] = " City field is required";
      valid = false;
    }

    setErrors(error);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidate()) {
      const d = {
        city: city,
        lat: latitude,
        lon: longitude,
        model: model,
        location: state,
        id: id,
      };

      axios
        .post(`/api/crm/update-associate-location`, d, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          NotificationManager.success(res.data?.message);
          history.push("/associate-location");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/crm/get-associate-location?id=${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setCity(res.data?.data?.getDetail.city);
        setLatitude(res.data?.data?.getDetail?.lon);
        setLongitude(res.data?.data?.getDetail?.lat);
        setModel(res.data?.data?.getDetail?.model);
        setState(res.data?.data?.getDetail?.location);
      })
      .catch((err) => console.log(err));
  }, [auth.token]);



  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Create Associate Location</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>City</Label>
                      </Col>

                      <Col>
                        <Input
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          invalid={Boolean(errors?.city)}
                        />
                      </Col>
                      <span className="d-block text-danger ml-auto col-md-7">
                        {errors?.city}
                      </span>
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Latitude</Label>
                      </Col>

                      <Col>
                        <Input
                          value={latitude}
                          onChange={(e) => setLatitude(e.target.value)}
                          //   invalid={Boolean(errors?.city)}
                        />
                      </Col>
                      {/* <span className="d-block text-danger ml-auto col-md-7">
                        {errors?.city}
                      </span> */}
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Longitude</Label>
                      </Col>

                      <Col>
                        <Input
                          value={longitude}
                          onChange={(e) => setLongitude(e.target.value)}
                        />
                      </Col>
                      {/* <span className="d-block text-danger ml-auto col-md-7">
                        {errors?.city}
                      </span> */}
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>State</Label>
                      </Col>

                      <Col>
                        <Input
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </Col>
                      {/* <span className="d-block text-danger ml-auto col-md-7">
                        {errors?.city}
                      </span> */}
                    </Row>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Associate Model</Label>
                      </Col>

                      <Col>
                        <Input
                          value={model}
                          onChange={(e) => setModel(e.target.value)}
                        />
                      </Col>
                      {/* <span className="d-block text-danger ml-auto col-md-7">
                        {errors?.city}
                      </span> */}
                    </Row>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Button
                      type="submit"
                      color="secondary"
                      size="sm"
                      className="mr-1"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>

                    <Button color="secondary" size="sm" className="mr-1">
                      Save & New
                    </Button>

                    <a href="/associate-location">
                      <Button type="button" color="secondary" size="sm">
                        Close
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </MainWrapper>
    </div>
  );
};

export default EditAssociateLocation;
