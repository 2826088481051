import Axios from "axios";

export const eventInsertHandler = (data, token) => {
  return {
    type: "ADD_MEETING",
    payload: Axios.post(`/api/crm/add-meeting`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const addMeeting = (d, token) => {
  return {
    type: "ADD_MEETING_DETAILS",
    payload: Axios.post(`/api/crm/get-crm-meetingdetails-create`, d, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getMeeting = (
  token,
  paginate,
  search,
  sort,
  page,
  disposition,
  purpose,
  leadsource,
  startDate,
  endDate,
  dispositiontype,
  assignDate,
  assignEndDate,
  createdBy,
  assign
) => {
  return {
    type: "GET_MEETING_DETAILS",
    payload: Axios.get(
      `/api/crm/get-crm-meetingdetails?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&disposition=${disposition}&purpose_of_meeting=${purpose}&lead_source=${leadsource}&start=${startDate}&end=${endDate}&disposition_type=${dispositiontype}&assign_date_start=${assignDate}&assign_date_end=${assignEndDate}&created_by=${createdBy}&assign_to=${assign}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err) => err),
  };
};

export const editMeeting = (d, token) => {
  return {
    type: "UPDATE_MEETING",
    payload: Axios.post(`/api/crm/get-crm-meetingdetails-update`, d, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};
