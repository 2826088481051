import React, { Fragment, useState } from "react";
import Sidebar from "../layouts/Sidebar";
import HeaderNavBar from "../layouts/HeaderNavBar";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const MainWrapper = ({ children, nested = false }) => {
  const [toggle, setToggle] = useState("");
  const { isAuthenticated } = useSelector((store) => store.auth);

  if (!isAuthenticated) return <Redirect to="/login" />;
  if (nested) {
    return children
  }
  return (
    <Fragment>
      <div className="wrapper">
        <Sidebar toggle={toggle} />
        <div className="main overflow-hidden">
          <HeaderNavBar setToggle={setToggle} />
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default MainWrapper;
