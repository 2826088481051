import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import MainWrapper from "../components/MainWrapper";
import { useParams } from "react-router";
import _ from "lodash";
import { getCrmUsersApi } from "../store/actions/LeadActions";

const Edit = () => {
  const [valid, setValid] = useState(false);

  const [professionalservices, setProfessionalServices] = useState([]);
  const [consultancyType, setConsultancyType] = useState(0);

  const [supplier, setSupplier] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [name, setName] = useState("");

  const [remarks, setRemarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [mobile, setMobile] = useState("");

  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [industries, setIndustries] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [industory, setIndustory] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);

  const [subsubcategory, setSubSubcategory] = useState([]);

  const [selectedSubindustry, setSelectedSubindustry] = useState([]);

  const [industry, setIndustry] = useState({});

  const [multiAssociate, setMultiAssociate] = useState([
    { value: 1, label: "Professional Expert" },
  ]);
  const [assignTo, setAssignTo] = useState("");
  const [leadDate, setLeadDate] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState([]);
  const [selectedIndustryId, setSelectedIndustryId] = useState([]);
  const dispatch = useDispatch();
  const { leadData } = useSelector(({ lead }) => lead);

  const [selectError, setSelectError] = useState({
    name: "",
    email: "",
  });
  const { id } = useParams();
  const { auth } = useSelector(({ auth }) => auth);

  useEffect(() => {
    axios
      .get(`/api/crm/get-pmfmeby/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLeadDate(
          moment(res.data?.pmfme?.lead_date).format("YYYY-MM-DD") || ""
        );

        setName(res.data.pmfme?.name ?? "");

        setMobile(res.data.pmfme?.mobile ?? "");
        setBussinessName(res.data.pmfme?.bussinessName ?? "");
        setPhoneNo(res.data.pmfme?.phone_no ?? "");

        setEmail(res.data.pmfme?.email ?? "");

        setState(res.data.pmfme?.state ?? "");
        setCity(res.data.pmfme?.city ?? "");
        setRemarks(res.data.pmfme?.remarks ?? "");
      })
      .catch((err) => console.log(err));
  }, [auth]);

  useEffect(() => {
    setIndustry({});
  }, [consultancyType]);

  const sectorList = useMemo(() => industries, [industries]);
  const industryList = useMemo(() => subIndustry, [subIndustry, industries]);
  useEffect(() => {
    setIndustory(
      sectorList.filter((v) => selectedSectorId.some((s) => s == v.value))
    );
  }, [selectedSectorId]);
  useEffect(() => {
    if (industryList) {
      setSubSubcategory(
        industryList.filter((v) => selectedIndustryId.some((s) => s == v.value))
      );
    }
  }, [industryList]);

  const handleTypeMatched = (type) => {
    if (multiAssociate) {
      if (multiAssociate.find((m) => m.value == type)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const mAssociate = useMemo(() => {
    if (multiAssociate) {
      return multiAssociate.map((m) => m.value).join(",");
    } else {
      return null;
    }
  }, [multiAssociate]);

  // const changeMobile = (e) => {

  //   if ((_.size(e.target.value) < 11 && !isNaN(parseInt(e.target.value))) || _.isEmpty(e.target.value)) {
  //     setMobile(e.target.value);
  //     setName('');
  //     setValid(false);
  //     if (_.size(e.target.value) >= 10) {

  //       setUsers({});
  //       setErrors({});
  //       axios.get(`/api/crm/verify-mobile/${e.target.value}`).then(res => {
  //         setUsers(res.data.user ?? {});
  //         setName(res.data.user.name || '')
  //         setUserId(res.data.user.id || "")
  //         setEmail(res.data.user.email || "")
  //         setValid(res.data.valid);

  //       }).catch(err => console.log(err));

  //     }

  //   }
  // }
  const validation = () => {
    let formIsValid = true;
    let errors = {};
    setSelectError({
      mobile: "",
      name: "",
      email: "",
      whatsApp: "",
      organisation: "",
      designation: "",
      experience: "",
      qualification: "",
      skills: "",
      speakIn: "",
      dob: "",
      profilesummary: "",
      subsubcategory: "",
      profile: "",
      departmentLocation: "",
      departmentName: "",
    });
    if (_.isEmpty(mobile)) {
      errors["mobile"] = "The mobile field is required.";

      formIsValid = false;
    }
    if (_.isEmpty(name)) {
      errors["name"] = "The name field is required.";

      formIsValid = false;
    }
    if (_.isEmpty(email)) {
      errors["email"] = "The email field is required.";

      formIsValid = false;
    }
    if (handleTypeMatched(7)) {
      if (_.isEmpty(selectError?.dob)) {
        errors["dob"] = "The birthday field is required.";

        formIsValid = false;
      }
    }

    if (handleTypeMatched(6) || handleTypeMatched(7)) {
    }

    setSelectError(errors);

    return formIsValid;
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const subVals = subsubcategory.map((sm) => sm.value).join(",");
    e.preventDefault();
    const data = new FormData();

    data.append("name", name);

    data.append("remarks", remarks);
    data.append("city", city);
    data.append("state", state);

    data.append("mobile", mobile);
    data.append("email", email);

    data.append("lead_date", leadDate);
    data.append("id", id);

    setLoading(true);
    setErrors({});

    axios
      .post(`/api/crm/get-pmfme-update`, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        NotificationManager.success(res.data.message);
        setTimeout(() => {
          window.location.href = "/pmfme";
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        }
      });
  };

  return (
    <MainWrapper>
      <main className="content">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>EDIT PMFME</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/consultant")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="bussinessName"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Name
                    </Label>
                    <Input
                      id="bussinessName"
                      className="col-md-7"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      invalid={!!errors.name}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.name ? errors.name[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="city"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      id="city"
                      className="col-md-7"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      invalid={!!errors.city}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.city ? errors.city[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="state"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      id="state"
                      className="col-md-7"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      invalid={!!errors.state}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.state ? errors.state[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Mobile No
                    </Label>
                    <Input
                      type="text"
                      id="mobile"
                      value={mobile}
                      onChange={(e) =>
                        e.target.value.length < 11 &&
                        !isNaN(e.target.value) &&
                        setMobile(e.target.value)
                      }
                      className="col-md-7 p-0"
                    />
                    <FormFeedback
                      className="d-block offset-5"
                      valid={_.size(mobile) == 10}
                    >
                      {_.size(mobile) == 10 && valid}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="email"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Email Id
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      className="col-md-7"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!!errors.email}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.email ? errors.email[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="remarks"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Remarks
                    </Label>
                    <Input
                      type="textarea"
                      id="remarks"
                      className="col-md-7"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      invalid={!!errors.remarks}
                    />
                    <FormFeedback className="offset-5">
                      {!!errors.remarks ? errors.remarks[0] : ""}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center mb-3">
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button size="sm" type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                    {loading ? (
                      <FaSpinner className="fa fa-spin" />
                    ) : (
                      <Button
                        color="secondary"
                        size="sm"
                        className="mx-1"
                        type="submit"
                      >
                        Save & New
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => (window.location.href = "/pmfme")}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </main>
    </MainWrapper>
  );
};

export default Edit;
