/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";

import { ImHappy2 } from "react-icons/im";

import { HiPhoneIncoming } from "react-icons/hi";
import { Link } from "react-router-dom";

import { IoIosPie, IoMdFiling, IoMdCash } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDashboardCount } from "../store/actions/DashboardActions";
import { Input } from "reactstrap";

const ServiceDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leaddetails, dashboardcount, services } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    dispatch(getServiceDashboardCount(token, startDate, endDate));
  }, [token, startDate, endDate]);

  useEffect(() => {
    SetLead(leaddetails.convertedlead);
  }, [leaddetails, dashboardcount]);
 

  let today = new Date().toISOString().slice(0, 10);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="filter-container">
                  <div className="row">
                    <div class="col-md-6">
                      <div className="form-group">
                        <label htmlFor="" className="form-label  mb-2">
                          Start Date
                        </label>
                        <Input
                          type="date"
                          value={startDate}
                          onChange={({ target: { value } }) =>
                            setStartDate(value)
                          }
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div className="form-group">
                        <label htmlFor="" className="form-label mb-2">
                          End Date
                        </label>
                        <Input
                          type="date"
                          value={endDate}
                          onChange={({ target: { value } }) =>
                            setEndDate(value)
                          }
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box blue">
                          <FaUser color="#723adb" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/membership-report?start=${today}&end=${today}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.todayMembershipRegistrations}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Today Memberships Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box skyblue">
                          <FaUserCheck color="#4ab8ff" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/membership-report?start=${startDate}&end=${endDate}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.totalMembershipRegistrations}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Memberships Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box orange">
                          <FaPhoneAlt color="#ff8b58" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-gst-requests?start=${today}&end=${today}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.todayGstRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Today Gst Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box pink">
                          <ImHappy2 color="#fb5182" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-gst-requests=${startDate}&end=${endDate}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.totalGstRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Gst Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box orange">
                          <FaSadCry color="#ff8b58" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.todayStartupRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Startups Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box blue">
                          <BiDetail color="#723adb" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.totalStartupRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Startups Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box pink">
                          <HiPhoneIncoming color="#fb5182" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-udyam-requests?start=${today}&end=${today}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.todayUdyamRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Today Udyam Registrations
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box skyblue">
                          <IoMdCash color="#4ab8ff" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-udyam-requests?start=${startDate}&end=${endDate}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.totalUdyamRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Udyam Registrations
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <IoMdFiling color="#33d69f" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-bankable-requests?start=${today}&end=${today}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.todayBankableRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Today Bankable Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box pink">
                          <IoIosPie color="#fb5182" size={18} />
                        </div>
                      </div>
                      <Link
                        to={`/service-bankable-requests?start=${startDate}&end=${endDate}`}
                      >
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {services?.totalBankableRequests}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Bankable Requests
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box green">
                          <IoMdFiling color="#33d69f" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.todayBankableRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Bankable Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box pink">
                          <IoIosPie color="#fb5182" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.totalBankableRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Bankable Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <IoIosCard color="#6C00FF" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.todayIprRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Today Iprs Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box orange">
                          <IoIosBriefcase color="#ff8b58" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {services?.totalIprRequests}
                        </h3>
                        <div className="mb-0 font-commom-2">
                          Total Iprs Requests
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div>
              <h3>Service Dashboard</h3>
              <div className="row">
                <div className="col-md-3">
                  <div className="card flex-fill common-card round-border">
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box green">
                            <IoMdFiling color="#33d69f" size={18} />
                          </div>
                        </div>

                        <Link
                          to={`/service-payment?start=${today}&end=${today}`}
                        >
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {services?.todayServices}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Today Service Request
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card flex-fill round-border common-card">
                    <div className="card-body py-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box pink">
                            <IoIosPie color="#fb5182" size={18} />
                          </div>
                        </div>
                        <Link
                          to={`/service-payment?start=${startDate}&end=${endDate}`}
                        >
                          <div className="col-8">
                            <h3 className="mb-0 font-commom">
                              {services?.totalServices}
                            </h3>
                            <div className="mb-0 font-commom-2">
                              Total Service Request
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row cursor-pointer">
              {servicessale?.getTeamList?.map((consultant) => (
                <div className="col-md-4">
                  <div>
                    <Card>
                      <CardHeader onClick={() => setToggle(consultant?.id)}>
                        <span className="font-weight-bold cursor-pointer">
                          {consultant?.name}
                        </span>
                      </CardHeader>
                      <Collapse
                        isOpen={toggle === consultant?.id ? true : false}
                      >
                        <CardBody>
                          <Label>Start Date</Label>
                          <Input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />

                          <Label className="pt-2">End Date</Label>
                          <Input
                            type="date"
                            value={fdate}
                            onChange={(e) => setFdate(e.target.value)}
                          />

                          <div className="d-flex justify-content-between pt-4">
                            {" "}
                            <div>
                              <h6>Today Lead</h6>
                            </div>{" "}
                            <div>{countbyid?.todayLead}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Lead</h6>
                            </div>{" "}
                            <div>{countbyid?.totalLead}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Connected</h6>
                            </div>{" "}
                            <div>{countbyid?.totalConnected}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Interested</h6>
                            </div>{" "}
                            <div>{countbyid?.totalInterested}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Not Interested</h6>
                            </div>{" "}
                            <div>{countbyid?.totalNotInterested}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total Detailed Shared</h6>
                            </div>{" "}
                            <div>{countbyid?.totalDetailShared}</div>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            {" "}
                            <div>
                              <h6>Total CallBack</h6>
                            </div>{" "}
                            <div>{countbyid?.totalCallBack}</div>
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </div>
              ))}
            </div> */}

            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.no</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Campaign</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lead?.map((le, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>{le.full_name}</td>
                                    <td>{le.email}</td>
                                    <td>{le.mobile}</td>
                                    <td>{le?.compains}</td>
                                    <td>{le?.state}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default ServiceDashboard;
