import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { useSelector } from "react-redux";
import { Table, Spinner, FormGroup, Label } from "reactstrap";
import Loader from "../../layouts/Loader";
import _ from "lodash";
import Axios from "axios";
import { Input } from "reactstrap";
import moment from "moment";
const AgentLeadReportNew = () => {
  const { auth } = useSelector(({ auth }) => auth);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vertical, setVertical] = useState("")
  const [filters, setFilters] = useState({
    // startDate: "2023-08-08",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    setLoading(true);
      Axios.get(
        `/api/crm/get-agent-wise-lead-report-new?startDate=${filters.startDate}&endDate=${filters.endDate}&vertical=${vertical}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
        .then((res) => {
  
          setReport(res.data.assignLeadUser);
          setLoading(false);
        })
        .catch((err) => console.log(err));
  }, [auth, filters.startDate, filters.endDate, vertical]);


  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const MemberShipData = {};

  return (
    <Fragment>
      <MainWrapper>
        <div className="container">
          <div className=" mt-5">
            <div className="row mb-4">
              <div className="col-md-3">
                <h3 className="mb-0">Agent Lead Report</h3>
              </div>
             
              <div className="col-md-3 offset-md-3">
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label>End Date</Label>
                  <Input
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                  Vertical 
                </label>
                <div className="col-lg-8">
                  <Input
                    type="select"
                    className="custom-select"
                    value={vertical}
                    
                    onChange={({ target }) =>
                      setVertical(target.value)
                    }
                  >
                    <option value="">select</option>
                    <option value="Membership">Membership</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Project Report">
                      Project Report
                    </option>
                    <option value="Courses">Courses</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Startup">Startup</option>

                    <option value="Edp">Edp</option>
                  </Input>
                
                </div>
              </div>
            </div>
            <Table bordered>
              <thead>
                <tr className="table-primary">
                  <th>S.No</th>
                  <th>Agent Name</th>
                  <th>Total Count</th>
                  <th>Action Initiated Count</th>
                  <th>Action Not Initiated Count</th>
                  <th>Hot Lead Count</th>
                  <th>Warm Lead Count</th>
                  <th>Cold Lead Count</th>
                  <th>Priority Not Added</th>
                  <th>Source Not Added</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Fragment>
                    <tr>
                      <td colSpan={11}>
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  <>
                  {(report && report.length > 0) ? (
                    report.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <th>{item?.user_name}</th>
                          <th>{item?.total_leads}</th>
                          <th>{item?.action_initiated_count}</th>
                          <th>{item?.action_not_initiated_count}</th>
                          <th>{item?.hot_leads_count}</th>
                          <th>{item?.warm_leads_count}</th>
                          <th>{item?.cold_leads_count}</th>
                          <th>{item?.priority_not_added}</th>
                          <th>{item?.source_not_added}</th>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="text-center">Data Not Found!</div>
                      </td>
                    </tr>
                  )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default AgentLeadReportNew;
