import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  workShopUser,
  workShopCreate,
  workShopList,
  workShopData,
  changeReducerState,
} from "../../store/actions/WorkShopActions";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Axios from "axios";
import { useHistory } from "react-router";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
import { logintoCall } from "../../store/actions/LoginCallActions";
const LoginToCall = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector((state) => state.auth);

  const { telecallauth, isCallAuthenticated, loggedin } = useSelector(
    (state) => state.callReducer
  );

  const { token } = auth;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("email", email);
    data.append("password", password);

    dispatch(logintoCall(data, token));
  };

  useEffect(() => {
    if (loggedin) {
      NotificationManager.success("LoggedIn Successfully", "Success");
      setModal(false);
    }
  }, [loggedin]);

  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Login To Call
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="sm">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Login To Tata Tele Call
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Login
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default LoginToCall;
