import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { useSelector } from "react-redux";
import { Table, Spinner, FormGroup, Label } from "reactstrap";
import Loader from "../../layouts/Loader";
import _ from "lodash";
import Axios from "axios";
import { Input } from "reactstrap";
import moment from "moment";
const LeadReport = () => {
  const { auth } = useSelector(({ auth }) => auth);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: "2023-08-08",
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `/api/crm/get-lead-report?startDate=${filters.startDate}&endDate=${filters.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
      .then((res) => {
        setReport(
          _.chain(res.data.totalLeadCountAll)
            .groupBy("vertical_type")
            .map((value, key) => ({ vertical: key, data: value }))
            .value(),
          "check"
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [auth, filters.startDate, filters.endDate]);

  console.log(report, "report");

  const getSum = (arr) => {
    return arr.reduce((prev, curr) => (prev += parseInt(curr.amount)), 0);
  };

  const getIntitated = (arr, type) => {
    return arr.filter((item) =>
      type ? item.activity.length : !item.activity.length
    ).length;
  };

  const getHotCount = (arr) => {
    return arr.filter((item) => item?.priority === "Hot").length;
  };

  const getWarmCount = (arr) => {
    return arr.filter((item) => item?.priority === "Warm").length;
  };

  const getPriorityCount = (arr) => {
    return arr.filter((item) => item?.priority === null).length;
  };

  const getSourceCount = (arr) => {
    return arr.filter((item) => item?.source_id === 0).length;
  };

  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const MemberShipData = {};

  return (
    <Fragment>
      <MainWrapper>
        <div className="container">
          <div className=" mt-5">
            <div className="row mb-4">
              <div className="col-md-3">
                <h3 className="mb-0">Lead Report</h3>
              </div>
              <div className="col-md-3 offset-md-3">
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label>End Date</Label>
                  <Input
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>
            </div>
            <Table bordered>
              <thead>
                <tr className="table-primary">
                  <th>S.No</th>
                  <th>Vertical Type</th>
                  <th>Total Count</th>
                  <th>Action Initiated Count</th>
                  <th>Action Not Initiated Count</th>
                  <th>Hot Lead Count</th>
                  <th>Warm Lead Count</th>
                  <th>Priority Not Added</th>
                  <th>Source Not Added</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Fragment>
                    <tr>
                      <td colSpan={7}>
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  (report ? report : [])?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <th>
                          {item?.vertical == "null"
                            ? "Vertical Not Added"
                            : item?.vertical}
                        </th>
                        <td>{item?.data?.length}</td>
                        <td>{getIntitated(item?.data, true)}</td>
                        <td> {getIntitated(item?.data, false)} </td>
                        <td>{getHotCount(item?.data)}</td>
                        <td>{getWarmCount(item?.data)}</td>
                        <td>{getPriorityCount(item?.data)}</td>
                        <td>{getSourceCount(item?.data)}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default LeadReport;
