import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Form,
  FormGroup,
  CustomInput,
  Button,
  FormFeedback,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Select from "react-select";
import { importLead } from "../../store/actions/LeadActions";
import { sectors, projects } from "../../store/actions/MemberShipActions";
import MainWrapper from "../MainWrapper";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { workShopData } from "../../store/actions/WorkShopActions";
import { getSourceList } from "../../store/actions/LeadActions";
import { getCampaign } from "../../store/actions/CampaignActions";
import { useDispatch, useSelector } from "react-redux";
const ImportLead = ({ history: { push } }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { error, importLeadStatus, loading, getSources, getSourcesStatus, isDuping } =
    useSelector((state) => state.lead);
  const {
    sectors: { subcategory },
    projects: { project_reports },
  } = useSelector((state) => state.memberShip);
  const { workShops } = useSelector((state) => state.workShop);

  const {
    auth: { token },
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const { getcampaigndropdown, getcampaigndropdownstatus } = useSelector(
    (state) => state.campaignReducer
  );
  const [files, setFiles] = useState(null);
  const [project, setProject] = useState({});
  const [sector, setSector] = useState({});
  const [source, setSource] = useState("");
  const [workShop, setWorkShop] = useState("");
  const [sources, setSources] = useState([]);
  const [compains, setCompains] = useState([]);
  const [priority, setPriority] = useState("");
  const [vertical, setVertical] = useState("");
  const [topic, setTopic] = useState("");
  const onFileChange = (e) => {
    setFiles(e.target.files[0]);
  };
  useEffect(() => {
    dispatch(sectors(token));
  }, [token]);
  useEffect(() => {
    dispatch(workShopData(token));
  }, [token]);

  useEffect(() => {
    dispatch(getSourceList(token));
    dispatch(getCampaign(token));
  }, [token]);
  const onFileUpload = (e) => {
    if (e) {
      e.preventDefault();
    }
    const data = new FormData();
    data.append("sector", sector?.value);
    data.append("project", project?.value);
    data.append("priority", priority);
    data.append("source", source);
    data.append("workShop", workShop?.value);
    data.append("compains", compains);
    data.append("vertical_type", vertical);
    data.append("topic", topic);
    data.append("duplicate", modal ? 1 : 0)
    data.append("file", files);
    dispatch(importLead(data, token));
  };
  useEffect(() => {
    if (importLeadStatus && !isDuping.length) {
      NotificationManager.success("Import Successfully", "Success");
      dispatch({ type: "RESET_IMPORT_LEAD_STATUS", payload: false });
      push("/leads");
    }
  }, [importLeadStatus, push, isDuping]);

  useEffect(() => {
    if (isDuping.length) {
      toggle()
    }
  }, [isDuping])

  console.log(error, "error");
  const sectorData = useMemo(() => subcategory, [subcategory]);
  const subSubCategories = useMemo(() => project_reports, [project_reports]);
  const handleSectorChange = (e) => {
    setSector(e);

    setProject({});
  };
  useEffect(() => {
    if (!_.isNull(sector) && sector) {
      dispatch(projects(token, [sector?.value]));
    }
  }, [token, sector]);
  const handleProjectChange = (e) => {
    setProject(e);
  };
  useEffect(() => {
    if (getSourcesStatus) {
      setSources(getSources.source);
    }
  }, [getSourcesStatus]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex align-items-center justify-content-between justify-content-center">
                    <h4>Import Leads</h4>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1">
                        <Link
                          to="/leads/add"
                          className="btn btn-secondary text-nowrap"
                        >
                          <i className="fa fa-plus"></i>
                          &nbsp;&nbsp;Add New
                        </Link>
                      </div>
                      <div className="btn-group mr-1">
                        <a href="#" className="btn btn-secondary text-nowrap">
                          <i className="fa fa-download"></i>
                          &nbsp;&nbsp;Import Leads
                        </a>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-list"></i>
                          &nbsp;&nbsp;Leads Tools
                          <span className="caret"></span>
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu" role="menu">
                          <li className="w-100">
                            <Link
                              to="/leads/status"
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                            >
                              Lead Status
                            </Link>
                          </li>
                          <li className="w-100">
                            <Link
                              to="/leads/rating"
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                            >
                              Lead Rating
                            </Link>
                          </li>
                          <li className="w-100">
                            <Link
                              to="/leads/source"
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                            >
                              Lead Source
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body pl-2 pr-2">
                    <div className="row">
                      <div className="col-12 col-md-6 offset-md-3">
                        <Form onSubmit={onFileUpload}>
                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Sector
                            </label>
                            <div className="col-lg-8">
                              <Select
                                type="select"
                                id="sector"
                                value={sector}
                                options={sectorData || []}
                                onChange={handleSectorChange}
                                invalid={!!error?.sector}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />

                              <FormFeedback className="text-left">
                                {error?.sector}
                              </FormFeedback>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Industry
                            </label>
                            <div className="col-lg-8">
                              <Select
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                id="project"
                                value={project}
                                options={subSubCategories || []}
                                onChange={handleProjectChange}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Priority
                            </label>
                            <div className="col-lg-8">
                              <Input
                                type="select"
                                className="custom-select"
                                value={priority}
                                onChange={({ target }) =>
                                  setPriority(target.value)
                                }
                              >
                                <option value="">select</option>
                                <option value="Hot">Hot</option>
                                <option value="Cold">Cold</option>
                                <option value="A">A</option>
                                <option value="B+">B+</option>
                                <option value="Warm">Warm</option>
                              </Input>
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Vertical <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <Input
                                type="select"
                                className="custom-select"
                                value={vertical}
                                invalid={!!error?.vertical_type}
                                onChange={({ target }) =>
                                  setVertical(target.value)
                                }
                              >
                                <option value="">select</option>
                                <option value="Membership">Membership</option>
                                <option value="Workshop">Workshop</option>
                                <option value="Project_Report">
                                  Project Report
                                </option>
                                <option value="Industrial_Report">
                                  Industrial Report
                                </option>
                                <option value="Courses">Courses</option>
                                <option value="Franchise">Franchise</option>
                                <option value="Startup">Startup</option>

                                <option value="Edp">Edp</option>
                              </Input>
                              <FormFeedback className="text-left">
                                {error?.vertical_type}
                              </FormFeedback>
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Source <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <Input
                                type="select"
                                className="custom-select"
                                id="source"
                                invalid={!!error?.source}
                                onChange={(e) => setSource(e.target.value)}
                              >
                                <option value="">select</option>
                                {sources
                                  ? sources.map((sou, i) => (
                                    <option value={sou?.value} key={i}>
                                      {sou?.label}
                                    </option>
                                  ))
                                  : null}
                              </Input>
                              <FormFeedback className="text-left">
                                {error?.source}
                              </FormFeedback>
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              WorkShop
                            </label>
                            <div className="col-lg-8">
                              <Select
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                id="workshop"
                                options={workShops?.upcomming?.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                onChange={(e) => setWorkShop(e)}
                              />
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Campaign
                            </label>
                            <div className="col-lg-8">
                              <Input
                                type="select"
                                className="custom-select"
                                id="campaign"
                                onChange={(e) => setCompains(e.target.value)}
                              >
                                <option value="">select</option>
                                {getcampaigndropdown
                                  ? _.map(
                                    getcampaigndropdown?.activity,
                                    (ws, index) => (
                                      <option value={ws.name} key={index}>
                                        {ws.name}
                                      </option>
                                    )
                                  )
                                  : null}
                              </Input>
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Remarks
                            </label>
                            <div className="col-lg-8">
                              <textarea
                                type="textarea"
                                id="topic"
                                cols={6}
                                rows={5}
                                className="custom-select"
                                onChange={(e) => setTopic(e.target.value)}
                              ></textarea>
                            </div>
                          </div>

                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                              Upload<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <CustomInput
                                type="file"
                                id="file"
                                name="file"
                                label="Choose File"
                                onChange={onFileChange}
                                invalid={!!error?.file}
                              />
                              <FormFeedback className="text-left">
                                {error?.file}
                              </FormFeedback>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-lg-4 control-label col-form-label col-12 text-lg-right"></label>
                            <div className="col-lg-8 text-center">
                              <Button color="primary" type="submit">
                                {loading ? "...loading" : "Upload"}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-8">
                    <div className="card">
                      <div className="card-header btn-header p-2">
                        <h4 className="m-0 text-white">Data Import Solution</h4>
                      </div>
                      <div className="card-body">
                        <div className="box-content">
                          <h5>
                            <strong>Supported Formats</strong>
                          </h5>
                          <ul className="list-arrow pl-3">
                            <li>
                              You can only use XLS or XLSX format for importing
                              your data
                            </li>
                          </ul>
                          <h5>
                            <strong>Maximum Data Limit</strong>
                          </h5>
                          <ul className="list-arrow pl-3">
                            <li>Maximum of 500 per batch</li>
                          </ul>
                          <h5>
                            <strong>Supported Formats</strong>
                          </h5>
                          <ul className="list-arrow pl-3">
                            <li>
                              Only .XLS or XLXS formats are supported while
                              import
                            </li>
                            <li>
                              Fisrt row of the files will be treated as Field
                              Names.{" "}
                              <b>
                                (Please don't make any changes in field name)
                              </b>
                            </li>
                            <li>
                              Duplicate check will be performed on basis of
                              Mobile number or Email Id
                            </li>
                            <li>
                              Date always must be in <strong>DD/MM/YYYY</strong>{" "}
                              format. Other Date formats will be automatically
                              ignored by the system
                            </li>
                            <li>
                              Check box value must either <strong>Yes</strong>{" "}
                              or <strong>No</strong>
                            </li>
                            <li>
                              Enter the same Values or Field Names which are
                              matching with the system (ex: City Name, Location
                              Name etc.)
                            </li>
                            <li>
                              Create Master Data whereever required before
                              importing your data if those master names are not
                              already defined by you
                            </li>
                            <li>
                              Have a manual check of everything of your import
                              file before you proceed
                            </li>
                            <li>
                              Unexpected errors may occur if the XLS file
                              contains any special controls like combo filters
                              or images embedded within it
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card">
                      <div className="card-header btn-header p-2">
                        <h4 className="m-0 text-white">
                          Download Import Format
                        </h4>
                      </div>
                      <div className="card-body">
                        <a href="http://admin.iid.org.in/public/leads-example.xlsx">
                          <i className="fa fa-download mr-2"></i>
                          Import Leads
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>


        <Modal isOpen={modal} toggle={toggle} >
          <ModalHeader toggle={toggle}>Duplicates Entry </ModalHeader>
          <ModalBody>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                {
                  isDuping.map((item) => {
                    return <tr key={item.id} >
                      <th scope="row">1</th>
                      <td> {item?.full_name} </td>
                      <td>{item?.email}</td>
                      <td> {item?.email} </td>
                    </tr>
                  })
                }



              </tbody>
            </table>


          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onFileUpload}>
              Override
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

      </MainWrapper>
    </Fragment>
  );
};

export default ImportLead;
