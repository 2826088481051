import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import {
  Container,
  InputGroup,
  Input
} from "reactstrap";
import Pusher from "pusher-js";
import { FiChevronDown } from "react-icons/fi";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import axios from "axios";
import Location from "./Location";
import moment from "moment";
import { CSVLink } from "react-csv";
import Axios from "axios";

const AgentLocationReport = () => {
  const dispatch = useDispatch();
  const [locationList, setLocationList] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(0);
  const [userId, setUserId] = useState("");
  const [crmUser, setCrmUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [manager, setManager] = useState([]);
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };

  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);
  const permissions = React.useMemo(() => permission ?? [], [permission]);

  const { dashboardcount } = useSelector((state) => state.dashboardReducer);
  const { leadData } = useSelector(({ lead }) => lead);
  useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));

  }, [auth]);

  useEffect(() => {
    Axios.get(`/api/crm/crm-managers`, {
      headers: { Authorization: `Bearer ${auth}` },
    })
      .then((res) => {
        setManager(res?.data)
      }).catch((err) => err)
  }, [auth])

  useEffect(() => {
    if(userId){
    Axios.get(`/api/crm/crm-users-by-manager/${userId}`, {
      headers: { Authorization: `Bearer ${auth}` },

    }).then((res) => {
      setCrmUser(res?.data)
    }).catch((err) => err).catch((err) => err)
  }else{
    setCrmUser([])
  }
  }, [auth, userId])

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/get-location-report?user_id=${user}&is_manager=${userId}&paginate=${paginate}&search=${search}&page=${page}&sort=${sort}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { authorization: `Bearer ${auth?.token}` },
        }
      )
      .then((res) => {
        setLocationList(res.data.getReport.data);
        setFrom(res.data.getReport.from)
        setTo(res.data.getReport.to)
        setTotal(res.data.getReport.total)
        setPerPage(res.data.getReport.per_page)
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [user, userId, paginate, search, page, sort, startDate, endDate, auth?.token]);

  const csvHead = [
    [
      "Agent Name",
      "Punch IN Latitude",
      "Punch IN Longitude",
      "Punch IN Date Time",
      "Punch IN Address",
      "Punch OUT Latitude",
      "Punch OUT Longitude",
      "Punch OUT Date Time",
      "Punch OUT Address",
    ]
  ];
  const csvData = locationList.map(cv => [
    cv?.crm_user?.name,
    cv?.punch_In_lat,
    cv?.punch_In_long,
    cv?.punch_In_datetime,
    cv?.punch_In_address,
    cv?.punch_out_lat,
    cv?.punch_out_long,
    cv?.punch_out_datetime,
    cv?.punch_out_address,
  ]);
  const exportData = csvHead.concat(csvData);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="page-header mb-2">
                  <div className="row">
                    <div className="col-md-12">
                      <h4>Agent Location Report</h4>
                    </div>
                  </div>
                </div>
                <div className="page-header mb-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="c-card-top d-flex flex-wrap justify-content-between">
                        <div className="c-card-top-title ml-2">
                          <div className="col-md-2">
                            <select
                              id="paginate"
                              className="custom-select custom-select-page"
                              style={{ height: 30 }}
                              value={paginate}
                              onChange={(e) => setPaginate(e.target.value)}
                            >
                              <option>Select</option>
                              <option value="10" selected>10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                        <div className="c-card-top-inputs c-card-top-search">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-search"></i>
                              </span>
                            </div>
                            {/* <span className="custom-select">Search</span> */}

                            <Input
                              placeholder="Search here..."
                              onChange={handleSearch}
                              className="c-form-control-serch"
                            />
                            <div class="input-group-append">
                              <button
                                title="Advance Search"
                                onClick={() => {
                                  advanceSearch
                                    ? setAdvanceSearch(false)
                                    : setAdvanceSearch(true);
                                }}
                                id="c_searchBlock_toggle"
                                class="btn btn-secondary btn-sm c-card-top-block-icon"
                                type="button"
                              >
                                <FiChevronDown />
                              </button>
                              <button
                                class="btn btn-secondary btn-sm"
                                type="button"
                                id="button-addon2"
                              >
                                GO
                              </button>
                            </div>
                          </div>
                          <div
                            className={advanceSearch ? "c-card-top-block" : "d-none"}
                            id="c_searchBlock"
                          >
                            <div className="c-card-top-block-sec">
                              <div className="c-card-top-block-item">
                                <label>Manager List</label>
                                <select
                                  id="paginate"
                                  onChange={(e) => setUserId(e.target.value)}
                                  value={userId}
                                  className="custom-select"
                                >
                                  <option value="">Select</option>
                                  {manager?.users?.map((cr) => (
                                    <option value={cr?.value} key={cr.value}>
                                      {cr?.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="c-card-top-block-item">
                                <label>Agent List</label>
                                <select
                                  id="paginate"
                                  onChange={(e) => setUser(e.target.value)}
                                  value={user}
                                  className="custom-select"
                                >
                                  <option value="">Select</option>
                                  {crmUser?.users?.map((cr) => (
                                    <option value={cr?.value} key={cr.value}>
                                      {cr?.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="c-card-top-block-item">
                                <label>Start Date</label>
                                <Input
                                  type="date"
                                  placeholder="Start Date"
                                  onChange={(e) => setStartDate(e.target.value)}
                                  value={startDate}
                                />
                              </div>
                              <div className="c-card-top-block-item">
                                <label>End Date</label>
                                <Input
                                  type="date"
                                  placeholder="End Date"
                                  onChange={(e) => setEndDate(e.target.value)}
                                  value={endDate}
                                />
                              </div>
                              <div className="c-card-top-block-action">
                                <button
                                  onClick={() => setAdvanceSearch(false)}
                                  className="btn btn-sm btn-secondary mr-2"
                                >
                                  Apply
                                </button>

                                <button
                                  onClick={() => setAdvanceSearch(false)}
                                  className="btn btn-sm btn-secondary mr-2"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card d-card-table">
                      <div className="card-header btn-header d-card-header">
                        <div className="right-box-tools">
                          <select
                            id="ddlSortBy"
                            className="custome-select small-select mr-1"
                            onChange={({ target }) => setSort(target.value)}
                          >
                            <option value="">Sort By</option>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                          </select>
                          <CSVLink
                            data={exportData}
                            filename={"agent-location-report.csv"}
                            className="btn btn-secondary btn-floating btn-sm mr-1"
                            title="Export To Excel"
                            target="_blank">
                            <i className="fa fa-file-excel-o" />
                          </CSVLink>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="table-responsive">
                              <table className="table table-hover mb-0 table-sm small">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="font-weight-bold">S.No</th>
                                    <th className="font-weight-bold">Agent Name</th>
                                    <th className="font-weight-bold">Punch IN</th>
                                    <th className="font-weight-bold">Punch In Date Time</th>
                                    <th className="font-weight-bold">Punch In Address</th>
                                    <th className="font-weight-bold">Punch Out</th>
                                    <th className="font-weight-bold">Punch Out Date Time</th>
                                    <th className="font-weight-bold">Punch Out Address</th>
                                  </tr>
                                </thead>
                                <tbody className="d-table-body">
                                  <Fragment>
                                    {Array.isArray(locationList)
                                      ? locationList?.map((agentLocList, i) => (
                                        <tr key={i + 1}>
                                          <td>{i + 1}</td>
                                          <td>{agentLocList?.crm_user?.name}</td>
                                          <td>{agentLocList?.punch_In_lat},{agentLocList?.punch_In_long}</td>
                                          <td>{agentLocList?.punch_In_datetime}</td>
                                          <td>{agentLocList?.punch_In_address}</td>
                                          <td>{agentLocList?.punch_out_lat},{agentLocList?.punch_out_long}</td>
                                          <td>{agentLocList?.punch_out_datetime}</td>
                                          <td>{agentLocList?.punch_out_address}</td>
                                        </tr>
                                      ))
                                      : null}
                                  </Fragment>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="d-flex justify-content-center justify-content-between">
                          <span>
                            Showing {from} to {to} of {total} entries
                          </span>
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={parseInt(perPage)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={(page) => setPage(page)}
                            innerClass="pagination pagination-sm"
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <Location locationList={locationList} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default AgentLocationReport;
