import axios from "axios";
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import MainWrapper from "../../components/MainWrapper";
import { FaList, FaTrash } from "react-icons/fa";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  CardFooter,
} from "reactstrap";
import { map, capitalize, last, split, mapValues } from "lodash";
import { FiEye, FiUpload } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
import { getallPermissions } from "../../store/actions/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";

const ServiceGstRequest = memo(() => {
  const [getId, setGstId] = useState("");
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [statuses, setStatuses] = useState({});
  const [fields, setFields] = useState({});
  const [partnernames, setPartnernames] = useState([]);
  const [memname, setMebershipName] = useState([]);
  const [singleuser, setSingle] = useState({});
  const [from, setFrom] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [page, setPage] = useState(1);
  const [deleteIds, setDeleteIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [prevData, setPrevData] = useState({
    value: "",
    idx: "",
    key: "",
  });
  const [modal, setModal] = useState(false);
  const [modalHold, setModalHold] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [member, setMemeber] = useState([]);
  const [uploadmodal, setUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const [remark, setRemark] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [memberDetails, setMemberDetails] = useState({});
  const dropdownOpenToggle = () =>
    setDropdownOpen((dropdownOpen) => !dropdownOpen);

  useMemo(() => {
    const url = new URL(window.location).searchParams;
    const start = url.get("start");
    const end = url.get("end");

    if (start) {
      console.log(start, "checkk");
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/crm/service-requests/details?activeTab=${activeTab}&search=${search}&sort=${sort}&paginate=${paginate}&page=${page}&service_status=${serviceStatus}&start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        setData(res.data.gstDetails.data);
        setFrom(res.data.gstDetails.from);
        setTo(res.data.gstDetails.to);
        setTotal(res.data.gstDetails.total);
        setPerPage(res.data.gstDetails.per_page);
        mapValues(res.data.gstDetails, (value, key) => {
          setStatuses((prevState) => {
            return {
              ...prevState,
              [key]: map(value, (v) => ({
                id: v.id,
                status: v.status,
              })),
            };
          });
        });
      })
      .catch((err) => console.log(err.response));
  }, [refresh, activeTab, sort, paginate, search, page, serviceStatus]);
  const handleStatusChange = (value, idx, key, file, gst) => {
    console.log(value);
    if (value === "INVALID") {
      setPrevData({
        value,
        idx,
        key,
      });
      setModal(true);
    } else if (file != "" && value != "COMPLETED" && value != "IN_PROGRESS") {
      setGstId(gst?.id);
      setModalHold(true);
      NotificationManager.error("do not change status on this stage..");
    } else if (file == null && value === "COMPLETED") {
      NotificationManager.error("Please Upload File..");
    } else if (value == "On Hold") {
      setModalHold(true);
    } else {
      setPrevData({
        value,
        idx,
        key,
      });
      setStatuses((prev) => {
        const updatedKey = prev[key];
        updatedKey[idx].status = value;
        return {
          ...prev,
          [key]: updatedKey,
        };
      });
      handleStatusSubmit(value, idx, key);
    }
  };
  const handleFeedbackSubmit = () => {
    setStatuses((prev) => {
      const updatedKey = prev[prevData.key];
      updatedKey[prevData.idx].status = prevData.value;
      return {
        ...prev,
        [prevData.key]: updatedKey,
      };
    });
    setModal(false);
    handleStatusSubmit();
  };

  const handleStatusSubmit = (value, idx, key) => {
    axios
      .post(`/service-requests/status`, {
        key: key ?? prevData.key,
        status: statuses[key ?? prevData.key]?.[idx ?? prevData.idx],
        feedBack,
      })
      .then(() => {
        setPrevData({
          value: "",
          idx: "",
          key: "",
        });
        setFeedBack("");
        setRefresh((prev) => prev + 1);
      })
      .catch((error) =>
        console.log(
          "🚀 ~ file: ServiceRequests.js ~ line 105 ~ handleStatusSubmit ~ error",
          error
        )
      );
  };

  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  const handleEdit = (e, data, user, partner) => {
    e.preventDefault();
    setModal(true);
    setFields(data);
    setSingle(user);
    setPartnernames(partner);

    axios
      .get(`/api/crm/getUserbyId/` + user.id, {
        headers: {
          authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setMemeber(res.data.memebership);
        setMemberDetails(res.data.user?.plan_detail_all);
      })
      .catch((err) => console.log(err));
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const datediff = (date1, date2) => {
    const date4 = new Date(date1);
    const date5 = new Date();

    const diffInMs = Math.abs(date5 - date4);
    if (diffInMs > 0) {
      return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    } else {
      return 0;
    }
  };
  const UploadView = (e, data, user, partner) => {
    e.preventDefault();
    setUploadModal(true);
    setFields(data);
    setSingle(user);
    setPartnernames(partner);

    axios
      .get(`/getUserbyId/` + user.id)
      .then((res) => {
        setMemeber(res.data.memebership);
      })
      .catch((err) => console.log(err));
  };
  const submitForm = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", fields.id);
    data.append("uploadFile", uploadFile);

    axios
      .post("/service-requests/gstfile", data)
      .then((res) => {
        NotificationManager.success("Uploaded successfully");
        window.location.reload();
        setUploadModal(false);
      })
      .catch((err) => NotificationManager.error(err.response.data.message));
    //  updateProfile(data, token);
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .get(`/delete/${id}`, id)
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          setDeleteIds([]);
          NotificationManager.success(res.data.message);
          console.log(res.data, "delete");
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const submitRemarkForm = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", getId);
    data.append("remark", remark);

    axios
      .post("/service-requests-remark/gstfile", data)
      .then((res) => {
        NotificationManager.success("Added successfully");
        window.location.reload();
        setModalHold(false);
      })
      .catch((err) => NotificationManager.error(err.response.data.message));
  };
  return (
    <Fragment>
      <MainWrapper fluid>
        <main className="content pt-4">
          <div className="page-header mb-4">
            <Row>
              <Col md="12">
                <div className="c-card-top">
                  <div className="c-card-top-title">
                    {/* <h4 className="mb-0">
                                        Service GST Requests
                                    </h4> */}
                    <h4 className="mb-0">GST Registration of Business</h4>
                  </div>
                  <div className="c-card-top-inputs c-card-top-search">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                      <span className="custom-select">Search</span>
                      <Input
                        placeholder="Search here..."
                        onChange={handleSearch}
                        className="c-form-control-serch"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-secondary btn-sm"
                          type="button"
                          id="button-addon2"
                        >
                          GO
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="c-card-top-buttons">
                    <ButtonGroup className="">
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={dropdownOpenToggle}
                      >
                        <DropdownToggle caret size="sm">
                          <FaList />
                          &nbsp;Service Tools
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="#">
                            Tool Name
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </ButtonGroup>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center">
                                <h4>Service GST Requests</h4>
                                <div className="d-flex justify-content-between align-items-center">
                                    <InputGroup className="mr-1">
                                        <Input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={({ target: { value } }) =>
                                                setSearch(value)
                                            }
                                        />
                                    </InputGroup>
                                    <ButtonDropdown
                                        isOpen={dropdownOpen}
                                        toggle={dropdownOpenToggle}
                                    >
                                        <DropdownToggle caret>
                                            <FaList />
                                            &nbsp;Service Tools
                                        </DropdownToggle>
                                        <DropdownMenu className="w-100">
                                            <DropdownItem tag="a" href="#">
                                                Tool Name
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div> */}
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <div>
                    <button className="btn btn-secondary btn-sm mr-1">
                      <i className="fa fa-trash pr-1" /> Delete
                    </button>
                    <Button
                      color="secondary"
                      className="btn btn-sm btn-secondry btn-circle"
                      size="sm"
                    >
                      <i className="fa fa-refresh" />
                    </Button>
                  </div>
                  <div>
                    <select
                      id="serviceStatus"
                      onChange={(e) => setServiceStatus(e.target.value)}
                      value={serviceStatus}
                      className="custome-select mr-1"
                      style={{
                        width: 195,
                      }}
                    >
                      <option value="">Select Service Status</option>
                      <option value="Applied">Applied</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="COMPLETED">Completed</option>
                      <option value="ON HOLD">On Hold</option>
                      <option value="INVALID">Invalid</option>
                      <option value="CANCEL">Cancel</option>
                    </select>

                    <select
                      id="sort"
                      onChange={(e) => setSort(e.target.value)}
                      value={sort}
                      className="custome-select"
                      style={{ width: 135 }}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                    <select
                      id="paginate"
                      onChange={(e) => setPaginate(e.target.value)}
                      value={paginate}
                      className="custome-select custom-select-page"
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  {/* <Nav pills>
                                    <NavItem onClick={() => setActiveTab(1)}>
                                        <NavLink
                                            active={activeTab === 1}
                                            href="#"
                                        >
                                            GST Registration of Business
                                        </NavLink>
                                    </NavItem>
                                </Nav> */}
                  <TabContent className="" activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Row>
                        <Col sm="12">
                          <div className="table-responsive">
                            <Table size="sm" className="small" hover>
                              <thead className="thead-light">
                                <tr>
                                  <th>SNo.</th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    {/* User Name */}
                                    Member Name
                                  </th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    Mobile
                                  </th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    Email
                                  </th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    {/* Name */}
                                    Membership Type
                                  </th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    {/* Name */}
                                    Service Type
                                  </th>
                                  <th rowSpan={2} className="font-weight-bold">
                                    Applied on
                                  </th>
                                  <th
                                    style={{
                                      width: 150,
                                    }}
                                    rowSpan={2}
                                    className="font-weight-bold"
                                  >
                                    Status
                                  </th>
                                  <th
                                    style={{
                                      width: 150,
                                    }}
                                    rowSpan={2}
                                    className="font-weight-bold"
                                  >
                                    Remark
                                  </th>
                                  <th
                                    rowSpan={2}
                                    className="font-weight-bold text-right"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="d-table-body">
                                {map(data, (gst, idx) => (
                                  <Fragment key={`gst-${gst.id}`}>
                                    {console.log(gst)}
                                    <tr>
                                      <td>{idx + 1}</td>
                                      <td rowSpan={2}>
                                        <a
                                          className="c-text-btn-primary"
                                          href={`/user-profile/${gst?.user?.id}`}
                                        >
                                          {capitalize(gst.user?.name)}
                                        </a>
                                      </td>
                                      <td rowSpan={2}>{gst.user?.mobile}</td>
                                      <td rowSpan={2}>{gst.user?.email}</td>

                                      <td rowSpan={2}>
                                        {gst?.userpl ? gst?.userpl?.name : ""}
                                        <span className="badge badge-sm badge-info">
                                          {member === null
                                            ? ""
                                            : datediff(
                                                gst?.userpl
                                                  ? gst.userpl.expiry
                                                  : "",
                                                gst?.userpl
                                                  ? gst.userpl.purchased_at
                                                  : ""
                                              )}

                                          {member === null ? (
                                            ""
                                          ) : (
                                            <span>Days Left</span>
                                          )}
                                        </span>
                                      </td>
                                      <td rowSpan={2}>
                                        {gst.service_id != ""
                                          ? "Membership"
                                          : "Individual"}
                                      </td>
                                      <td rowSpan={2}>{gst.created_at}</td>
                                      <td rowSpan={2}>{gst?.status}</td>
                                      <td>
                                        {gst?.status === "COMPLETED"
                                          ? ""
                                          : gst?.remark}
                                      </td>
                                      <td className="text-left" rowSpan={2}>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="c-icon-btn-light"
                                          onClick={(e) =>
                                            handleEdit(
                                              e,
                                              gst,
                                              gst?.user,
                                              gst.partner_names
                                            )
                                          }
                                        >
                                          <FiEye />
                                        </span>
                                      </td>

                                      {/* <td
                                                                                rowSpan={
                                                                                    2
                                                                                }
                                                                            >
                                                                                {
                                                                                    gst.feedback
                                                                                }
                                                                            </td> */}
                                    </tr>
                                    <tr>
                                      {/* <td>
                                                                                {gst?.document_proof && (
                                                                                    <a
                                                                                        href={`/storage/${gst?.document_proof}`}
                                                                                        target="_blank"
                                                                                        className="text-primary"
                                                                                    >
                                                                                        View
                                                                                        Document
                                                                                        Proof

                                                                                    </a>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {gst?.agreement_proof && (
                                                                                    <a
                                                                                        href={`/storage/${gst?.agreement_proof}`}
                                                                                        target="_blank"
                                                                                        className="text-primary"
                                                                                    >
                                                                                        View
                                                                                        Agreement
                                                                                        Proof

                                                                                    </a>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {gst?.noc_proof && (
                                                                                    <a
                                                                                        href={`/storage/${gst?.noc_proof}`}
                                                                                        target="_blank"
                                                                                        className="text-primary"
                                                                                    >
                                                                                        View
                                                                                        NOC
                                                                                        Proof

                                                                                    </a>
                                                                                )}
                                                                            </td> */}
                                    </tr>
                                  </Fragment>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <div className="card-footer">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      Showing {from} to {to} of {total} entries
                    </span>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={(page) => setPage(page)}
                      innerClass="pagination pagination-sm"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </main>
      </MainWrapper>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: 700 }}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setModal(false)}
        >
          {fields ? singleuser?.name : ""}
        </ModalHeader>
        <ModalBody className="px-2 py-3" style={{ fontSize: 12 }}>
          <FormGroup>
            <div className="container-fluid">
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Membership Type:</strong>
                </div>
                <div className="col-md-9">
                  <span>{memberDetails?.name}</span>{" "}
                  <span className="badge badge-info badge-sm">
                    {member === null
                      ? ""
                      : datediff(memberDetails?.expiry, memberDetails?.joining)}

                    {member === null ? "" : <span>Days Left</span>}
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Mobile:</strong>
                </div>
                <div className="col-md-9">
                  <span> {fields ? singleuser.mobile : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Email:</strong>
                </div>
                <div className="col-md-9">
                  <span> {fields ? singleuser.email : ""}</span>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Business Name:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields ? fields?.name : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Business Type:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields ? fields.type : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Business Nature:</strong>
                </div>
                <div className="col-md-9">
                  <span>{fields ? fields.business_nature : ""}</span>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <strong style={{ fontWeight: 600 }}>Status:</strong>
                </div>
                <div className="col-md-9">
                  <span className="badge badge-sm badge-warning">
                    {fields ? fields.status : ""}
                  </span>
                </div>
              </div>
            </div>

            {partnernames === ""
              ? ""
              : partnernames.map((item, index) => (
                  <div className="container-fluid mt-3">
                    <div className="row mb-1">
                      <div className="col-md-12">
                        <div
                          className="py-1 px-2"
                          style={{
                            background: "#e5e5e5",
                          }}
                        >
                          <strong
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {index + 1} - Director
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-3">
                        <strong style={{ fontWeight: 600 }}>Name:</strong>
                      </div>
                      <div className="col-md-9">
                        <span>{item.name ? item?.name : ""}</span>
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-3">
                        <strong style={{ fontWeight: 600 }}>Mobile:</strong>
                      </div>
                      <div className="col-md-9">
                        <span>{item.mobile_no ? item.mobile_no : ""}</span>
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-3">
                        <strong style={{ fontWeight: 600 }}>Email:</strong>
                      </div>
                      <div className="col-md-9">
                        <span>{item.email ? item.email : ""}</span>
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-9">
                        <strong style={{ fontWeight: 600 }}>Adhar Card:</strong>
                      </div>
                      <div className="col-md-3">
                        {item.aadhar_card != null ? (
                          <a
                            href={`/storage/${
                              item == null ? "" : item.aadhar_card
                            }`}
                            className="text-primary"
                          >
                            View
                          </a>
                        ) : (
                          "No Attachment"
                        )}
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-9">
                        <strong style={{ fontWeight: 600 }}>Pan Card:</strong>
                      </div>
                      <div className="col-md-3">
                        {item.pan_card != null ? (
                          <a
                            href={`/storage/${
                              item == null ? "" : item.pan_card
                            }`}
                            className="text-primary"
                          >
                            View
                          </a>
                        ) : (
                          "No Attachment"
                        )}
                      </div>
                    </div>
                    <div className="row mb-1 px-2">
                      <div className="col-md-9">
                        <strong style={{ fontWeight: 600 }}>
                          Proprietorship Photo:
                        </strong>
                      </div>
                      <div className="col-md-3">
                        {item.photo != null ? (
                          <a
                            href={`/storage/${item == null ? "" : item.photo}`}
                            className="text-primary"
                          >
                            View
                          </a>
                        ) : (
                          "No Attachment"
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            <div className="container-fluid mt-3">
              <div className="row mb-1">
                <div className="col-md-12">
                  <div className="py-1 px-2" style={{ background: "#e5e5e5" }}>
                    <strong style={{ fontWeight: 600 }}>
                      Business Documents
                    </strong>
                  </div>
                </div>
              </div>

              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>Bank Statement:</strong>
                </div>
                <div className="col-md-3">
                  {fields.pan_card != null ? (
                    <a
                      href={`/storage/${fields == null ? "" : fields.pan_card}`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>

              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>
                    Electricity Bill/Municipal Khata copy/Property tax
                    receipt/Water tax receipt/Sale Deed:
                  </strong>
                </div>
                <div className="col-md-3">
                  {fields.document_proof != null ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.document_proof
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>
                    Lease/Rent Agreement (if user has chosen rented option):
                  </strong>
                </div>
                <div className="col-md-3">
                  {fields.agreement_proof != null ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.agreement_proof
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>
                    Consent Letter/NOC from the Owner (if user has chosen rented
                    option):
                  </strong>
                </div>
                <div className="col-md-3">
                  {fields.noc_proof != null ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.noc_proof
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>Partnership Deed:</strong>
                </div>
                <div className="col-md-3">
                  {fields.partnership_deed != null ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.partnership_deed
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>
                    Authorization Letter:
                  </strong>
                </div>
                <div className="col-md-3">
                  {fields.authorization_letter != null ? (
                    <a
                      href={`/storage/${
                        fields == null ? "" : fields.authorization_letter
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                </div>
              </div>
              <div className="row mb-1 px-2">
                <div className="col-md-9">
                  <strong style={{ fontWeight: 600 }}>
                    Certificate Incorporation:
                  </strong>
                </div>
                <div className="col-md-3">
                  {fields.certificate_incorporation_llp != null ? (
                    <a
                      href={`/storage/${
                        fields == null
                          ? ""
                          : fields.certificate_incorporation_llp
                      }`}
                      className="text-primary"
                    >
                      View
                    </a>
                  ) : (
                    "No Attachment"
                  )}
                  {/* <span>{ fields === null ? '' :  fields.certificate_incorporation_llp}</span> */}
                </div>
              </div>
            </div>
          </FormGroup>
          {/* <div className="text-center mt-2 mb-2">
                   <Button
                        color="secondary"
                        size="sm"
                        onClick={()=>setModal(false)}
                    >
                        Close
                    </Button>
                   </div> */}
        </ModalBody>
      </Modal>
      <Modal isOpen={uploadmodal}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setUploadModal(false)}
        ></ModalHeader>
        <ModalBody className="px-2 py-1">
          <FormGroup>
            <Label>Upload Document</Label>
            <Input
              type="file"
              onChange={(e) => setUploadFile(e.target.files[0])}
            />
          </FormGroup>
          <Button color="success" size="sm" onClick={submitForm}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalHold}>
        <ModalHeader
          style={{ background: "#e5e5e5" }}
          toggle={() => setModalHold(false)}
        ></ModalHeader>
        <ModalBody className="px-2 py-1">
          <FormGroup>
            <Label>Remark</Label>
            <Input
              type="textarea"
              cols={40}
              onChange={(e) => setRemark(e.target.value)}
            ></Input>
          </FormGroup>
          <Button color="success" size="sm" onClick={submitRemarkForm}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
});

export default ServiceGstRequest;
