import Axios from "axios";

export const createNoteApi = (data, token) => {
  return {
    type: "ADD_NOTE",
    payload: Axios.post(`/api/crm/add-employee-node`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getFranchiseList = (id, token) => {
  return {
    type: "GET_FRANCHISE_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-team-note/${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getTataPlayList = (id, token) => {
  return {
    type: "GET_TATAPLAY_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-tata-lead?id=${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getFranchiseOnboardingList = (id, token) => {
  return {
    type: "GET_FRANCHISE_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-franchise-onboarding-note/${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getPmfmeList = (id, token) => {
  return {
    type: "GET_PMFME_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-pmfme-note/${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getAssociateNote = (id, token) => {
  return {
    type: "GET_ASSOCIATE_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-lead-note/${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getInstituteNote = (id, token) => {
  return {
    type: "GET_INSTITUTE_LIST_NOTE",
    payload: Axios.get(`/api/crm/get-university-note/${id}`, {
      headers: { Authorization: `Bearer ${token}  ` },
    }).catch((err) => err),
  };
};

export const getListNote = (id, token) => {
  return {
    type: "NOTE_LIST",
    payload: Axios.get(`/api/crm/employee-note/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const NoteDelete = (data, token) => {
  return {
    type: "NOTE_DELETE",
    payload: Axios.post(`/api/crm/employee-note-destroy`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getDataById = (slug, token) => {
  return {
    type: "NOTE_BY_SLUG",
    payload: Axios.get(`/api/crm/note/${slug}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const updateNoteApi = (data, token) => {
  return {
    type: "UPDATE_NOTE",
    payload: Axios.post(`/api/crm/employee-note-update`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getListUserNote = (id, token) => {
  return {
    type: "NOTE_LIST",
    payload: Axios.get(`/api/crm/employee-user-note/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};

export const getListConsultantNote = (id, token) => {
  return {
    type: "NOTE_LIST",
    payload: Axios.get(`/api/crm/get-consultant-note/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => err),
  };
};
