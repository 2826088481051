import _ from "lodash";

const initialState = {
  meetingData: [],
  loading: false,
  instituteCreateStatus: false,
  associatestatus: false,
  associateData: [],
  getmeeting: {},
  getupdate: {},
  updateinstitutestatus: false,
  error: {},
};

const instituteReducer = (state = initialState, action) => {
  const { payload } = action;
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "ADD_INSTITUTE_PENDING":
      state = {
        ...state,
        loading: true,
        instituteCreateStatus: false,
        error: {},
      };
      break;

    case "RESET_CREATE_STATUS":
      return { ...state, instituteCreateStatus: payload }
    case "ADD_INSTITUTE_FULFILLED":
      state = {
        ...state,
        loading: false,
        instituteCreateStatus: true,
        meetingData: _.get(payload, "data", {}),
      };
      break;
    case "ADD_INSTITUTE_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          instituteCreateStatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "ADD_MEETING_DETAILS_PENDING":
      state = {
        ...state,
        loading: true,
        meetingCreateStatus: false,
        error: {},
      };
      break;
    case "ADD_MEETING_DETAILS_FULFILLED":
      state = {
        ...state,
        loading: false,
        meetingCreateStatus: true,
        meetingData: _.get(payload, "data", {}),
      };
      break;
    case "ADD_MEETING_DETAILS_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "GET_INSTITUTE_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_INSTITUTE_FULFILLED":
      state = {
        ...state,
        loading: false,
        getmeeting: _.get(payload, "data", {}),
      };
      break;
    case "GET_INSTITUTE_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "UPDATE_INSTITUTE_PENDING":
      state = {
        ...state,
        loading: true,
        updateinstitutestatus: false,
        error: {},
      };
      break;
    case "UPDATE_INSTITUTE_FULFILLED":
      state = {
        ...state,
        loading: false,
        getupdate: _.get(payload, "data", {}),
        updateinstitutestatus: true,
      };
      break;
    case "UPDATE_INSTITUTE_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          updateinstitutestatus: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          meetingCreateStatus: false,
          error: _.get(data, "message", {}),
        };
      }
      break;

    case "RESET_UPDATE_STATUS":
      return {
        ...state,
        updateinstitutestatus: false,
      };
  }

  return state;
};

export default instituteReducer;
