import _ from "lodash";

const initialState = {
    listData: [],
    listDataStatus: false,
    loading: false,
    eventUpdateStatus: false,
    createEventStatus: false,
    error: {},
    priorityy:[]
};

const allActivityReducer = (state = initialState, actions) => {
    const { payload } = actions;
    // eslint-disable-next-line default-case
    switch (actions.type) {
        case "ALL_ACTIVITY_PENDING":
            state = {
                ...state,
                loading: true,
                listDataStatus: false,
                eventUpdateStatus: false,
                createEventStatus: false,
                error: {},
            };
            break;
        case "ALL_ACTIVITY_FULFILLED":
            state = {
                ...state,
                loading: false,
                listDataStatus: true,
                eventUpdateStatus: false,
                createEventStatus: false,
                listData: _.get(payload, "data", {}),
            };
            break;
            // case "ALL_PRIORITY":
            //     console.log(payload,"paylaod")
            // state = {
            //     ...state,
            //     loading: false,
                
            //     priorityy: _.get(payload, "data", {}),
            // };
            // break;
            case "ALL_PRIORITY":
              console.log(payload,"pppppppppppppppp");
                return {
                  ...state,
                  loading: false,
                
                  priorityy: payload?.data 
                    
                };
                case "ALL_PRIORITY_FULFILLED":
            state = {
                ...state,
                loading: false,
                
                  priorityy: payload?.data?.priority
            };
            break;
        case "ALL_ACTIVITY_REJECTED":
            console.log(payload.responce);
            state = {
                ...state,
                loading: false,
                listDataStatus: false,
                eventUpdateStatus: false,
                createEventStatus: false,
                error: _.get(payload.responce, "message", {}),
            };
            break;
    }
    return state;
};

export default allActivityReducer;
