import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import {
  Input,
  CustomInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  FormGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Loader from "../../layouts/Loader";
import { FiEdit, FiTrash } from "react-icons/fi";
import { Campaign, CampaignDelete } from "../../store/actions/CampaignActions";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { getFranchiseList } from "../../store/actions/FranchiseActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import LeadImport from "./leadimport";
import Note from "./subComponent/NoteOnboarding";
import { FiChevronDown, FiEye } from "react-icons/fi";
import moment from "moment";
import axios from "axios";
const FranchiseList = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(20);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [leadstatus, setLeadStatus] = useState("");
  const [assignDate, setAssignDate] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingg, setLoading] = useState(true);
  const [fields, setFields] = useState("");
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});
  const [segment, setSegment] = useState("");
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [id, setId] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openSendLeadModal, setOpenSendLeadModal] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const {
    auth,
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);

  const { loading, usersData } = useSelector(
    ({ FranchiseReducer }) => FranchiseReducer
  );

  const permissions = React.useMemo(() => permission ?? [], [permission]);
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  useEffect(() => {
    const url = new URL(window.location).searchParams;
    const mo = url.get("leaddate");
    const status = url.get("status");

    if (mo) {
      setAssignDate(mo);
    }
    if (status) {
      setLeadStatus(status);
    }
  }, []);
  useEffect(() => {
    dispatch(
      getFranchiseList(
        auth?.token,
        paginate,
        search,
        sort,
        page,
        assignDate,
        leadstatus,
        segment
      )
    );
  }, [
    auth,
    paginate,
    search,
    sort,
    page,
    assignDate,
    leadstatus,
    segment,
    refresh,
  ]);

  useEffect(() => {
    dispatch(getallPermissions(auth?.token));
  }, [auth]);

  useEffect(() => {
    if (usersData) {
      setFrom(usersData?.from);
      setTo(usersData?.to);
      setTotal(usersData?.total);
      setPerPage(usersData?.per_page);
    }
  }, [usersData]);

  useEffect(() => {
    axios
      .get(`/api/crm/get-franchise-investor-dashboard-count`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        console.log(res.data, "qwerty");
        setTeamList(res.data?.getTeamList);
      })

      .catch((err) => console.log(err));
  }, []);

  if (!isAuthenticated) return <Redirect to="/login" />;
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("file", fields);
    axios
      .post("/api/crm/import-franchise-onboarding", data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        window.location.reload();
        NotificationManager.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setLoading(false);
          setErrors(err.response.data.errors);
          NotificationManager.error(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.errors);
        }
      });
  };

  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };
  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post(
          "/api/crm/franchise-onboarding-destroy",
          {
            ids: JSON.stringify(deleteIds),
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .post("/api/crm/franchise-onboarding-destroy", {
          ids: JSON.stringify([id]),
        })
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          NotificationManager.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      console.log(usersData);
      setDeleteIds(
        usersData?.data?.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  const handleSendLead = (e) => {
    e.preventDefault();
    setOpenSendLeadModal(!openSendLeadModal);
  };

  const handleSendLeads = (e) => {
    let filterList;
    e.preventDefault();

    filterList = usersData?.data.filter((v) =>
      deleteIds.some((i) => i == v?.id)
    );

    axios
      .post(
        `/api/crm/update-franchise-onboarding-assignto`,
        {
          assignTo: assignTo,
          leadIds: filterList,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Onboarding Lead Assigned Successfully");
        setDeleteIds([]);
        setOpenSendLeadModal(!openSendLeadModal);
      })
      .catch((err) => console.log(err));
  };

  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="c-card-top d-flex flex-wrap justify-content-between">
                    <div className="c-card-top-title">
                      <h4>Franchise Team List</h4>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <span className="custom-select">Search</span>

                        <Input
                          placeholder="Search here..."
                          onChange={handleSearch}
                          className="c-form-control-serch"
                        />
                        <div class="input-group-append">
                          <button
                            title="Advance Search"
                            onClick={() => {
                              advanceSearch
                                ? setAdvanceSearch(false)
                                : setAdvanceSearch(true);
                            }}
                            id="c_searchBlock_toggle"
                            class="btn btn-secondary btn-sm c-card-top-block-icon"
                            type="button"
                          >
                            <FiChevronDown />
                          </button>
                          <button
                            class="btn btn-secondary btn-sm"
                            id="button-addon2"
                            type="button"
                            onClick={handleSerchByClick}
                          >
                            GO
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          advanceSearch ? "c-card-top-block" : "d-none"
                        }
                        id="c_searchBlock"
                      >
                        <div className="c-card-top-block-sec">
                          <div className="c-card-top-block-item">
                            <label>Assigned Date</label>

                            <Input
                              type="date"
                              placeholder="Start Date"
                              onChange={(e) => setAssignDate(e.target.value)}
                              value={assignDate}
                            />
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Lead Status</label>
                            <select
                              id="paginate"
                              onChange={(e) => setLeadStatus(e.target.value)}
                              value={leadstatus}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              <option value="Hot">Hot</option>
                              <option value="Warm">Warm</option>
                              <option value="Cold">Cold</option>
                            </select>
                          </div>
                          <div className="c-card-top-block-item">
                            <label>Segment</label>
                            <select
                              id="paginate"
                              onChange={(e) => setSegment(e.target.value)}
                              value={segment}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              <option value="Brand Acquisition">
                                Brand Acquisition
                              </option>
                              <option value="Grow Your Franchise">
                                Grow Your Franchise
                              </option>
                            </select>
                          </div>

                          <div className="c-card-top-block-action">
                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Apply
                            </button>

                            <button
                              onClick={() => setAdvanceSearch(false)}
                              className="btn btn-sm btn-secondary mr-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-card-top-buttons">
                      {permissions.some(
                        (item) => item?.name === "franchise-team-create"
                      ) && (
                          <Link to="/add-franchise">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm mr-2"
                            >
                              <i className="fa fa-plus"></i> Add New
                            </button>
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    {/* <div className="left-area-field">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div> */}
                    {/* <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              placeholder="Search..."
                              value={search}
                              onChange={handleSearch}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                              onClick={handleSerchByClick}
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        {permissions.some(
                          (item) => item?.name === "franchise-team-create"
                        ) && (
                          <Link
                            to="/add-franchise"
                            className="btn btn-secondary text-nowrap btn-sm p-1"
                          >
                            <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                          </Link>
                        )}
                      </div>
                     
                    </div> */}
                    {/* <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="c-card-top">
                        <div className="c-card-top-title ml-2">
                          <div className="c-card-top-inputs c-card-top-search">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-search"></i>
                                </span>
                              </div>
                              <span className="custom-select">Search</span>

                              <Input
                                placeholder="Search here..."
                                onChange={handleSearch}
                                className="c-form-control-serch"
                              />
                              <div class="input-group-append">
                                <button
                                  title="Advance Search"
                                  onClick={() => {
                                    advanceSearch
                                      ? setAdvanceSearch(false)
                                      : setAdvanceSearch(true);
                                  }}
                                  id="c_searchBlock_toggle"
                                  class="btn btn-secondary btn-sm c-card-top-block-icon"
                                  type="button"
                                >
                                  <FiChevronDown />
                                </button>
                                <button
                                  class="btn btn-secondary btn-sm"
                                  id="button-addon2"
                                  type="button"
                                  onClick={handleSerchByClick}
                                >
                                  GO
                                </button>
                              </div>
                            </div>

                            <div
                              className={
                                advanceSearch ? "c-card-top-block" : "d-none"
                              }
                              id="c_searchBlock"
                            >
                              <div className="c-card-top-block-sec">
                                <div className="c-card-top-block-item">
                                  <label>Assigned Date</label>

                                  <Input
                                    type="date"
                                    placeholder="Start Date"
                                    onChange={(e) =>
                                      setAssignDate(e.target.value)
                                    }
                                    value={assignDate}
                                  />
                                </div>
                                <div className="c-card-top-block-item">
                                  <label>Lead Status</label>
                                  <select
                                    id="paginate"
                                    onChange={(e) =>
                                      setLeadStatus(e.target.value)
                                    }
                                    value={leadstatus}
                                    className="custom-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="hot">Hot</option>
                                    <option value="warm">Warm</option>
                                    <option value="cold">Cold</option>
                                  </select>
                                </div>
                                <div className="c-card-top-block-action">
                                  <button
                                    onClick={() => setAdvanceSearch(false)}
                                    className="btn btn-sm btn-secondary mr-2"
                                  >
                                    Apply
                                  </button>

                                  <button
                                    onClick={() => setAdvanceSearch(false)}
                                    className="btn btn-sm btn-secondary mr-2"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {permissions.some(
                          (item) => item?.name === "franchise-team-create"
                        ) && (
                            <Link
                              to="/add-franchise"
                              className="btn btn-secondary text-nowrap btn-sm p-1"
                            >
                              <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                            </Link>
                          )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header d-card-header">
                    <div className="left-box-tools">
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        disabled={!deleteIds.length}
                        onClick={handleDelete}
                      >
                        <i className="fa fa-trash pr-1"></i> Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm mr-1"
                        onClick={() => setModal(true)}
                      >
                        <i className="fa fa-plus btn-i-icon"></i> Import Data
                      </button>
                      <Modal isOpen={modal} toggle={() => setModal(false)}>
                        <ModalHeader toggle={() => setModal(false)}>
                          Import Data
                          <a
                        href="https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/franchiseInvester.xlsx"
                        style={{ "margin-left": "170px", color: "blue" }}
                      >
                        Sample Sheet
                      </a>

                        </ModalHeader>
                        <ModalBody>
                          <form onSubmit={handleSubmit}>
                            <FormGroup>
                              <div>
                                <label>Franchise Onboarding</label>
                              </div>

                              <div className="pt-3">
                                <CustomInput
                                  type="file"
                                  id="file"
                                  name="file"
                                  label="Choose File"
                                  onChange={handleChange}
                                  invalid={!!errors?.file}
                                  required
                                />
                              </div>
                              <FormFeedback>
                                {!!errors?.file ? errors?.file[0] : ""}
                              </FormFeedback>
                            </FormGroup>
                            <div className="text-center mb-3">
                              <Loader loading={loading}>
                                <Button
                                  type="submit"
                                  color="secondary"
                                  size="sm"
                                  className="mr-1"
                                >
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  color="secondary"
                                  size="sm"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Loader>
                            </div>
                          </form>
                        </ModalBody>
                      </Modal>
                      <button
                        className="btn btn-secondary btn-sm mr-1"
                        title="Refresh"
                        onClick={() => setRefresh((refresh) => refresh + 1)}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-sm ml-1"
                        disabled={!deleteIds.length}
                        onClick={handleSendLead}
                      >
                        <i className="fa fa-list pr-1"></i> Send Lead
                      </button>
                    </div>
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">
                                  <CustomInput
                                    id="default"
                                    type="checkbox"
                                    onChange={handleMultiSelect}
                                    checked={
                                      deleteIds.length ===
                                      usersData?.data?.length
                                    }
                                  />
                                </th>
                                <th className="font-weight-bold">
                                  Company Name
                                </th>
                                <th className="font-weight-bold">Website</th>

                                <th className="font-weight-bold">
                                  Contact Number
                                </th>
                                <th className=" font-weight-bold">
                                  Contact Person Name
                                </th>

                                <th className=" font-weight-bold ">Email</th>
                                <th className=" font-weight-bold ">Segment</th>
                                <th className=" font-weight-bold ">
                                  Bussiness Type
                                </th>

                                <th className=" font-weight-bold ">
                                  Brand Name
                                </th>
                                <th className=" font-weight-bold ">
                                  Lead Status
                                </th>
                                <th className=" font-weight-bold ">
                                  Assigned Date
                                </th>

                                <th className=" font-weight-bold ">Action</th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {Array.isArray(usersData?.data)
                                    ? usersData?.data.map((leadsl, i) => (
                                      <tr key={i}>
                                        <td>
                                          <CustomInput
                                            id={`default-${leadsl.id}`}
                                            type="checkbox"
                                            checked={deleteIds.some(
                                              (d) => d === leadsl.id
                                            )}
                                            onChange={(e) =>
                                              handleSingleSelect(e, leadsl.id)
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Link
                                            to={`/franchise-detail/${leadsl?.id}`}
                                          >
                                            {leadsl?.company_name}
                                          </Link>
                                        </td>

                                        <td>{leadsl?.website}</td>

                                        <td>
                                          {leadsl.contact_number}{" "}
                                          {
                                            leadsl?.contact_detail?.[0]
                                              ?.contact_number
                                          }
                                        </td>

                                        <td>
                                          {
                                            leadsl?.contact_detail?.[0]
                                              ?.contact_person_name
                                          }
                                        </td>

                                        <td>
                                          {leadsl?.email}{" "}
                                          {leadsl?.contact_detail?.[0]?.email}
                                        </td>

                                        <td>{leadsl.segment}</td>
                                        <td>{leadsl.bussiness_type}</td>
                                        <td>{leadsl.brand_name}</td>
                                        <td>{leadsl.lead_status}</td>
                                        <td>
                                          {moment(leadsl.created_at).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {permissions.some(
                                            (item) =>
                                              item?.name ===
                                              "franchise-team-edit"
                                          ) && (
                                              <Link
                                                to={`/franchise/edit/${leadsl?.id}`}
                                              >
                                                <FiEdit />
                                              </Link>
                                            )}

                                          <a
                                            href="#"
                                            className="text-danger pl-3"
                                            onClick={() => {
                                              setOpenNotesModal(true);
                                              setId(leadsl?.id);
                                            }}
                                          >
                                            <FiEye />
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <select
                          id="paginate"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                          className="custom-select custom-select-page"
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          isOpen={openSendLeadModal}
          toggle={() => setOpenSendLeadModal(!openSendLeadModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openSendLeadModal)}>
            <form onSubmit={handleSendLeads}>
              <Row className="m-3">
                <Col sm={12}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-12 control-label col-form-label col-12"
                    >
                      Franchise Onboarding
                    </Label>
                    <Input
                      type="select"
                      className="col-md-12"
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                    >
                      <option>select</option>
                      {teamList?.map((cr) => (
                        <option value={cr?.id} key={cr.id}>
                          {cr?.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Loader loading={loading}>
                      <Button
                        type="submit"
                        color="secondary"
                        size="sm"
                        className="mr-1"
                      >
                        Send
                      </Button>
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          (window.location.href = "/franchise-list")
                        }
                      >
                        Close
                      </Button>
                    </Loader>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalHeader>
          <ModalBody className="p-0 m-0"></ModalBody>
        </Modal>
        <Modal
          isOpen={openNotesModal}
          toggle={() => setOpenNotesModal(!openNotesModal)}
        >
          <ModalHeader toggle={() => setIsOpen(!openNotesModal)}>
            Notes
          </ModalHeader>
          <ModalBody className="p-0 m-0">
            <Note id={id} />
          </ModalBody>
        </Modal>
      </MainWrapper>
    </Fragment>
  );
};

export default FranchiseList;
