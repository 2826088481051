import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import Loader from "../../layouts/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getCrmUser } from "../../store/actions/CrmUserActions";
import { getallPermissions } from "../../store/actions/AuthActions";
import Pagination from "react-js-pagination";
import moment from "moment";
import Axios from "axios";
const Index = () => {
  const dispatch = useDispatch();
  const {
    auth,
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);


  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [search, setSearch] = useState("");
  const [manager, setManager] = useState("");
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    Axios.get(`/api/crm/get-all-payments?search=${search}&sort=${sort}&page=${page}&paginate=${paginate}`,{
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
        .then((res) => {
          const data = res?.data?.payments;
          setFrom(data?.from);
          setTo(data?.to);
          setTotal(data?.total);
          setPerPage(data?.per_page);
          setPayments(data?.data);
          setLoading(false);
          // console.log('setPayments>>>',data);
        })
        .catch((err) => console.log(err));
  }, [auth.token, search, sort, page, paginate, manager]);

  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(getallPermissions(auth.token));
  }, [auth.token]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Payments</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field mx-auto">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              value={search}
                              onChange={({ target: { value } }) =>
                                setSearch(value)
                              }
                              placeholder="Search..."
                            />
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        {permissions ? (
                          permissions.find(
                            (ps) => ps.name === "crm-user-create"
                          ) != null ? (
                            <Link
                              to="/add-crm-user"
                              className="btn btn-secondary text-nowrap btn-sm p-1"
                            >
                              <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                            </Link>
                          ) : null
                        ) : null}
                      </div>
                      {/* <div className="btn-group mr-1">
                        <a
                          href="import-lead.html"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-download"></i>&nbsp;&nbsp;Import
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header d-card-header btn-header">
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                      >
                        <option value="">Short By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>

                      {/* <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                      >
                        <option value="">Managers</option>
                        <option value="Yes">Manager</option>
                        <option value="No">User</option>
                      </select> */}
                      {/* <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print "></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button> */}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold text-center">S.No</th>
                                <th className="font-weight-bold text-center">Name of Customer</th>
                                <th className="font-weight-bold text-center">Mobile number</th>
                                <th className="font-weight-bold text-center">Date of Payment</th>
                                <th className="font-weight-bold text-center">Product Sold</th>
                                <th className="font-weight-bold text-center">Agent name</th>
                                <th className="font-weight-bold text-center">Mode of Payment</th>
                                <th className="font-weight-bold text-center">Agent Confirmation</th>
                                <th className="font-weight-bold text-center">Account confirmation</th>
                                <th className="font-weight-bold text-center">Source</th>
                                <th className="font-weight-bold text-center">Email id</th>
                                <th className="font-weight-bold text-center">Vertical</th>
                                <th className="font-weight-bold text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={13}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {payments
                                    ? payments?.map((p, i) => (
                                        <tr key={Math.random()}>
                                          <td className="text-center">
                                            {i + 1}
                                          </td>
                                          <td className="text-left">
                                          {p?.lead?.full_name}
                                          </td>
                                          <td className="text-left">
                                          {p?.lead?.mobile}
                                          </td>
                                          <td className="text-center">
                                          {(p?.payment)? p?.payment.created_at:p?.work_shop_payment?.created_at}
                                          </td>
                                          <td className="text-center">
                                          {(p?.payment)? p?.payment.type:'work_shop_payment'}
                                          </td>
                                          <td className="text-center">
                                          {p?.agent?.name}
                                          </td>
                                          <td className="text-center">
                                          {(p?.payment)? p?.payment.gatway_type:p?.work_shop_payment?.gatway_type}
                                          </td>
                                          <td className="text-center">
                                            <span className={
                                            `badge badge-${(p?.qa_id != null) 
                                            ? "success" 
                                            : "warning"}`
                                          }>{(p?.qa_id) ?'QA Approved':'Pending'}</span>
                                          </td>
                                          <td className="text-center">
                                            <span className={
                                            `badge badge-${(p?.accountant_id != null) 
                                            ? "success" 
                                            : "warning"}`
                                          }>{(p?.accountant_id) ?'Accounts Approved':'Pending'}</span>
                                          </td>
                                          <td className="text-center">
                                          
                                          </td>
                                          <td className="text-center">
                                          {p?.lead?.email}
                                          </td>
                                          <td className="text-center">
                                          {p?.agent?.vertical}
                                          </td>
                                          <td className="text-center">
                                              <a
                                                href={`/payment-detail/${p.id}`}
                                              >
                                                <FiEye />
                                              </a>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="10">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Index;
