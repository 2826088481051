/* eslint-disable default-case */
import _ from "lodash";

const initialState = {
  leaddetails: {},
  dashboardcount: {},
  dashboardcountsale: {},
  dashboardconsultant: {},
  dashboarduserconsultant: {},
  countbyid: {},
  services:{},
  error: {},
  loading: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEADS":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_LEADS_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        leaddetails: _.get(payload, "data", {}),
      };

      break;
    case "GET_LEADS_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "GET_LEADS_COUNT_PENDING":
    case "GET_SERVICES_COUNT_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_LEADS_COUNT_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        dashboardcount: _.get(payload, "data", {}),
      };

      break;
    case "GET_LEADS_COUNT_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "GET_SALES_LEADS_COUNT_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;

    case "GET_SERVICES_COUNT_FULFILLED":
      return {
        ...state,
        services:action.payload,
        loading:false,
        errors:{}
      }
    case "GET_SALES_LEADS_COUNT_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        dashboardcountsale: _.get(payload, "data", {}),
      };

      break;
    case "GET_SALES_LEADS_COUNT_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "LEADS_COUNT_ID_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "LEADS_COUNT_ID_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        countbyid: _.get(payload, "data", {}),
      };

      break;
    case "LEADS_COUNT_ID_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "GET_CONSULTANT_COUNT_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_CONSULTANT_COUNT_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        dashboardconsultant: _.get(payload, "data", {}),
      };

      break;
    case "GET_CONSULTANT_COUNT_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
    case "GET_USERCONSULTANT_COUNT_PENDING":
      state = {
        ...state,
        loading: true,
        error: {},
      };
      break;
    case "GET_USERCONSULTANT_COUNT_FULFILLED":
      var { payload } = action;
      state = {
        ...state,
        loading: false,
        dashboarduserconsultant: _.get(payload, "data", {}),
      };

      break;
    case "GET_USERCONSULTANT_COUNT_REJECTED":
      var {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "errors", {}),
        };
      } else {
        state = {
          ...state,
          loading: false,
          error: _.get(data, "message", {}),
        };
      }
      break;
  }

  return state;
};

export default dashboardReducer;
