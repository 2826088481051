/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeadsDetail,
  getDashboardCount,
} from "../store/actions/DashboardActions";
import { Input, CustomInput } from "reactstrap";
import Pagination from "react-js-pagination";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);

  useEffect(() => {
    dispatch(getLeadsDetail(token, paginate, page));
    dispatch(getDashboardCount(token));
  }, [token, page, paginate]);

  useEffect(() => {
    SetLead(leaddetails?.convertedlead?.data);
    setFrom(leaddetails?.convertedlead ? leaddetails.convertedlead.from : null);
    setTo(leaddetails?.convertedlead ? leaddetails.convertedlead.to : null);
    setTotal(
      leaddetails?.convertedlead ? leaddetails.convertedlead.total : null
    );
    setPerPage(
      leaddetails?.convertedlead ? leaddetails.convertedlead.per_page : null
    );
  
  }, [leaddetails, dashboardcount]);

 
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill ">
                  <div className="card-body py-4 ">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">{dashboardcount?.todayLead}</h3>
                        <div className="mb-0 tet-xt">Today Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiUser className="feather-lg text-primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl d-flex ">
                <div className="card flex-fill">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">{dashboardcount?.totalLead}</h3>
                        <div className="mb-0">Total Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiUserCheck className="feather-lg text-warning" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">
                          {dashboardcount?.totalConverted}
                        </h3>
                        <div className="mb-0">Converted Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiRepeat className="feather-lg text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl d-flex">
                <div className="card flex-fill">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-2">{dashboardcount?.totalCDS}</h3>
                        <div className="mb-0">CDS Lead</div>
                      </div>
                      <div className="col-4 ml-auto text-right">
                        <div className="d-inline-block mt-2">
                          <FiArchive className="feather-lg text-success" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.no</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Campaign</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lead?.map((le, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>{le.full_name}</td>
                                    <td>{le.email}</td>
                                    <td>{le.mobile}</td>
                                    <td>{le?.compains}</td>
                                    <td>{le?.state}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Dashboard;
