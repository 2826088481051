import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import { Input } from "reactstrap";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { FiEdit, FiTrash } from "react-icons/fi";
import { NotificationManager } from "react-notifications";
const AssociateLocation = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [locationList, setLocationList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };

  const {
    auth,
    permissions: { permission },
  } = useSelector((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/crm/associate-location?&paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
        {
          headers: { authorization: `Bearer ${auth?.token}` },
        }
      )
      .then((res) => {
        setLocationList(res.data.data.getList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [paginate, search, page, sort, auth?.token]);
  useEffect(() => {
    setFrom(locationList?.from ? locationList.from : null);
    setTo(locationList?.to ? locationList.to : null);
    setTotal(locationList?.total ? locationList.total : null);
    setPerPage(locationList?.per_page ? locationList.per_page : null);
  }, [locationList]);
  const csvHead = [
    ["City", "Longitude", "Latitude", "State", "Associate Model"],
  ];
  const csvData = (
    Array.isArray(locationList?.data) ? locationList?.data : []
  )?.map?.((cv) => [
    cv?.city,
    cv?.lat,
    cv?.lon,
    cv?.location,
    cv?.model,

    moment(cv?.created_at).format("Do MMM YYYY"),
  ]);
  const exportData = csvHead.concat(csvData);
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoadings(true);
      axios
        .post(
          `/api/crm/delete-associate-location`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then((res) => {
          setLoadings(false);
          setRefresh((refresh) => refresh + 1);
          setDeleteIds([]);
          NotificationManager.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setLoadings(false);
          NotificationManager.error(err.response.data.message);
        });
    }
  };
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Associate Location List</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field">
                      <Input
                        type="select"
                        className="select"
                        value={paginate}
                        onChange={(e) => setPaginate(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>

                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="c-card-top">
                        <div className="c-card-top-title ml-2">
                          <div className="c-card-top-inputs c-card-top-search">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-search"></i>
                                </span>
                              </div>
                              <span className="custom-select">Search</span>
                              <Input
                                placeholder="Search here..."
                                onChange={handleSearch}
                                className="c-form-control-serch"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link
                          to="/add-associate-location"
                          type="button"
                          className="btn btn-secondary text-nowrap btn-sm"
                        >
                          <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card d-card-table">
                  <div className="card-header btn-header d-card-header">
                    <div className="right-box-tools">
                      {auth?.id == 10 && (
                        <CSVLink
                          filename={"LeadList.csv"}
                          className="btn btn-secondary btn-floating btn-sm mr-1"
                          title="Export To CSV"
                          data={exportData}
                        >
                          <i className="fa fa-file-excel-o" />
                        </CSVLink>
                      )}
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={({ target }) => setSort(target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">S.No</th>
                                <th className="font-weight-bold">City</th>
                                <th className="font-weight-bold">Longitude</th>

                                <th className="font-weight-bold">Latitude</th>
                                <th className="font-weight-bold">State</th>
                                <th className="font-weight-bold">
                                  Associate Model
                                </th>

                                <th className="text-right font-weight-bold">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              <Fragment>
                                {Array.isArray(locationList?.data)
                                  ? locationList?.data?.map((meet, i) => (
                                      <tr key={i + 1}>
                                        <td>{i + 1}</td>

                                        <td>{meet?.city}</td>

                                        <td>{meet?.lat}</td>

                                        <td>{meet?.lon}</td>
                                        <td>{meet?.location}</td>
                                        <td>{meet?.model}</td>

                                        <td className="text-right">
                                          <Link
                                            to={`/associate-location/edit/${meet?.id}`}
                                            className="mr-2 text-info"
                                          >
                                            <FiEdit />
                                          </Link>
                                          <Link
                                            className="text-danger"
                                            onClick={(e) =>
                                              handleOneDelete(e, meet?.id)
                                            }
                                          >
                                            <FiTrash />
                                          </Link>
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default AssociateLocation;
