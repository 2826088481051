import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router";
import { FaSpinner } from "react-icons/fa";
import { getCrmUsersApi } from "../../store/actions/LeadActions";
import moment from "moment";
import { useHistory } from "react-router";
const CreateFranchiseInvestor = () => {
  const [companyName, setCompanyName] = useState("");
  const [brandName, setBrandname] = useState("");
  const [customername, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [leadSource, setleadSource] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [Amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [website, setWebsite] = useState("");
  const [investment, setInvestment] = useState("");
  const [dispositiontype, setDispositionType] = useState("");
  const [assignedLocation, setAssignLocation] = useState("");
  const [disposition, setDisposition] = useState("");
  const [errors, setErrors] = useState({});
  const [leadDate, setLeadDate] = useState("");
  const [brand, setBrand] = useState("");
  const dispatch = useDispatch();
  const { auth } = useSelector(({ auth }) => auth);
  const [assignTo, setAssignTo] = useState(auth?.id);
  const { leadData } = useSelector(({ lead }) => lead);

  const history = useHistory();

  React.useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
    setLeadDate(moment().format("YYYY-MM-DD"));
  }, [auth]);
  useEffect(() => {
    axios
      .get(`/api/crm/franchise-brands`)
      .then((res) => setBrand(res.data))
      .catch((err) => console.log(err));
  }, []);
  
  useEffect(() => {
    if (pincode.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  useEffect(()=>{
    console.log("brandName => " + brandName);
  },[brandName]);

  const handleSubmit = () => {
    setLoading(true);
    const data = {};
    data["company_name"] = companyName;
    data["customer_name"] = customername;
    data["contact_number"] = contactno;
    data["email"] = email;
    data["pincode"] = pincode;
    data["city"] = city;
    data["state"] = state;
    data["lead_status"] = leadStatus;
    data["lead_source"] = leadSource;
    data["investment"] = investment;
    data["pincode"] = pincode;
    data["client_remarks"] = note;
    data["amount"] = Amount;
    data["company_name"] = companyName;
    data["assign_to"] = assignTo;
    data["lead_date"] = leadDate;
    data["website"] = website;
    data["status"] = 1;
    data["disposition_type"] = dispositiontype;
    data["disposition"] = disposition;
    data["occupation"] = occupation;
    data["assign_lead_location"] = assignedLocation;
    data["brand"] = brandName;
    data["campaign"] = auth.campaign;

    axios
      .post(
        "/api/crm/create-franchise-investor",
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          NotificationManager.success("Added Successfully");
          // window.location.href = "/franchise-list";
          setLoading(false);
        }
      })
      .catch((errors) => {
        NotificationManager.error("Something went wrong");
        setLoading(false);
        setErrors(errors?.response?.data?.errors);
      });
  };

  const phoneValid = (val) => {
    return val.length < 11 && !isNaN(val) ? true : false;
  };
  return (
    <MainWrapper>
      <main className="content">
        <Card>
          <CardHeader>Franchise Investor Acquisition </CardHeader>
          <CardBody>
            <Row>
              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Assigned To
                  </label>

                  <Input
                    className="custom-select col-md-7"
                    type="select"
                    id="assignTo"
                    disabled={true}
                    onChange={(e) => setAssignTo(e.target.value)}
                    value={assignTo}
                  >
                    {(Array.isArray(leadData.users) ? leadData?.users : []).map(
                      (item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      }
                    )}
                  </Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="row form-group">
                  <label className="col-md-5 control-label col-form-label text-md-right">
                    Lead Date
                  </label>

                  <Input
                    type="date"
                    className=" col-md-7"
                    value={leadDate}
                    onChange={({ target: { value } }) => setLeadDate(value)}
                  ></Input>
                  <FormFeedback className="text-left">
                    {errors?.assign_to}
                  </FormFeedback>
                </div>
              </div>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Customer Name</Label>
                    </Col>

                    <Col>
                      <Input
                        value={customername}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Contact No</Label>
                    </Col>

                    <Col>
                      <Input
                        value={contactno}
                        onChange={(e) => setContactNo(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Email</Label>
                    </Col>

                    <Col>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Pincode</Label>
                    </Col>

                    <Col>
                      <Input
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>City</Label>
                    </Col>

                    <Col>
                      <Input
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>State</Label>
                    </Col>

                    <Col>
                      <Input
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Brand Name</Label>
                    </Col>

                    <Col>
                      <select
                        id="paginate"
                        onChange={(e) => setBrandname(e.target.value)}
                        value={brandName}
                        className="custom-select mt-3"
                      >
                        <option value="">Select</option>

                        {brand?.brand?.map((cr) => {
                          return (
                            <option value={cr?.id} key={cr.id}>
                              {cr?.brand_name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Investment</Label>
                    </Col>

                    <Col>
                      <Input
                        value={investment}
                        onChange={(e) => setInvestment(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Occupation</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Business">Business</option>
                        <option value="Services">Services</option>
                        <option value="N/A ">N/A +</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Lead Source</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={leadSource}
                        onChange={(e) => setleadSource(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="google">google</option>
                        <option value="website">Website</option>
                        <option value="webinar">Webinar</option>
                        <option value="refrence">refrence</option>
                        <option value="walk-in">walk-in</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Lead Status</Label>
                    </Col>

                    <Col>
                      <Input
                        type="select"
                        value={leadStatus}
                        onChange={(e) => setLeadStatus(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Hot">Hot</option>
                        <option value="Warm">Warm</option>
                        <option value="Cold">Cold</option>
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup className="row">
                  <Label
                    for="mobile"
                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                  >
                    Disposition Type
                  </Label>
                  <Input
                    type="select"
                    className="col-md-7"
                    onChange={(e) => setDispositionType(e.target.value)}
                    value={dispositiontype}
                  >
                    <option value=""> Select </option>

                    <option value="CallBack/Busy">CallBack/Busy</option>
                    <option value="Franchise Fee Picked">
                      Franchise Fee Picked
                    </option>
                    <option value="Membership Picked">Membership Picked</option>
                    <option value="On Negotiation">On Negotiation</option>
                    <option value="Praposal Shared">Praposal Shared</option>
                    <option value="Budget Issue">Budget Issue</option>
                    <option value="Wrong Number">Wrong Number</option>

                    <option value="Meeting Met">Meeting Met</option>
                    <option value="Not Reachable">Not Reachable</option>
                    <option value="Not Answering/Ringing">
                      Not Answering/Ringing
                    </option>
                    <option value="Not Intrested">Not Intrested</option>
                    <option value="Meeting Booked">Meeting Booked</option>
                    <option value="Token Picked">Token Picked</option>
                    <option value="Switch off">Switch off</option>
                    <option value="Dump Lead">Dump Lead</option>
                  </Input>
                </FormGroup>
              </Col>

              {/* {dispositiontype == "Connected" ? (
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Disposition
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDisposition(e.target.value)}
                      value={disposition}
                    >
                      <option value=""> Select </option>
                      <option value="Mail_Shared">Mail Shared</option>
                      <option value="Meeting_Align">Meeting Align</option>
                      <option value="Meeting_Done">Meeting Done</option>
                      <option value="Follow_Up">Follow up(Hot)</option>
                      <option value="Call_Back">Call Back</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Not_Interested">Not Interested</option>
                      <option value="Meeting_Done_NOI">
                        Meeting Done Not Interested
                      </option>
                      <option value="Payment_Done">Payment Done</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null} */}
              <Col sm={6}>
                <FormGroup className="row">
                  <Label
                    for="mobile"
                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                  >
                    Assigned Lead Location
                  </Label>
                  <Input
                    type="select"
                    className="col-md-7"
                    onChange={(e) => setAssignLocation(e.target.value)}
                    value={assignedLocation}
                  >
                    <option value=""> Select </option>
                    <option value="Lucknow">Lucknow</option>
                    <option value="Noida">Noida</option>
                  </Input>
                </FormGroup>
              </Col>
              {dispositiontype == "Not_Connected" ? (
                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Not Connected
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      onChange={(e) => setDisposition(e.target.value)}
                      value={disposition}
                    >
                      <option value=""> Select </option>

                      <option value="Npc">NPC</option>
                      <option value="Switch_Off">Switch Off</option>
                      <option value="Invalid_Number">Invalid Number</option>
                      <option value="Call_Rejected">Call Rejected</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null}

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Row className="align-items-center">
                    <Col md={5} className="text-md-right">
                      <Label>Client Remarks</Label>
                    </Col>

                    <Col>
                      <Input
                        type="textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={12}>
                <div className="btn-wrapper text-center">
                  {loading ? (
                    <FaSpinner className="fa fa-spin" />
                  ) : (
                    <Button color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                  <Button className="ml-2" onClick={() => history.goBack()}>
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </main>
    </MainWrapper>
  );
};

export default CreateFranchiseInvestor;
