import React, { useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../layouts/Loader";
import _ from "lodash";
import { Collapse } from "reactstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { crmUserDetails } from "../../store/actions/CrmUserActions";
import AddWorkshop from "./addWorkshop";
import AddMembership from "./addMembership";
import AddProjectReport from "../projectreport/addProjectReport";
import AddIndustrialReport from "../industrialreport/addIndustrialReport";
import AddCustomizeProjectReport from "../customizeprojectreport/addCustomizeProjectReport";

import List from "./List";
import Note from "./Note";
import ProjectReport from "../projectreport/ProjectReport";

import IndustrialReport from "../industrialreport/IndustrialReport";

import CustomizeProjectReport from "../customizeprojectreport/CustomizeProjectReport";
import AddEdp from "../edp/addEdp";
import AddServices from "../services/addServices";
import AddCourses from "../courses/addCourses";
import LoginToCall from "../lead/logintotelecall";
import { Modal } from "reactstrap";
import { Calling } from "../../store/actions/LoginCallActions";
import callingGif from "../../assets/img/calling.gif";
import UserCall from "./userCall";
import SendSms from "../lead/sendSms";
import UserCallList from "./userCallList";
import { getallPermissions } from "../../store/actions/AuthActions";
import WhatsAppSummary from "../lead/sub-component/WhatsAppSummary";
import LeadDetails from "./LeadDetails";
import EditServicesDetail from "../services/EditServicesDetail";
import MembershipServicesList from "./membershiplist";
import { NotificationManager } from "react-notifications";

const Details = () => {
  const [wsStatus, setWsStatus] = useState(false);
  const [isDetailShow, setIsDetailShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [whatsAppSu, setWhatsAppSu] = useState([]);
  const [purchasedServices, setPurchasedServices] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    auth,
    permissions: { permission },
  } = useSelector(({ auth }) => auth);
  const token = useMemo(() => auth?.token, [auth]);
  const { users, loading, mssg } = useSelector(({ crmUser }) => crmUser);
  const permissions = useMemo(() => permission ?? [], [permission]);
  useEffect(() => {
    dispatch(crmUserDetails(auth?.token, id));
  }, [auth, id, wsStatus, mssg]);

  const user = useMemo(() => users, [users, mssg]);
  const { servicespurchased } = useSelector((state) => state.serviceReducer);
  const { telecallauth, isCallAuthenticated, loadingg, error, telecall } =
    useSelector((state) => state.callReducer);
  useEffect(() => {
    dispatch(getallPermissions(token));
  }, [token]);
  useEffect(() => {
    if (telecall) {
      setIsModalOpen(false);
    }
  }, [telecall]);

  useEffect(() => {
    setTimeout(() => {
      if (refresh) {
        window.location.reload();
      }
    }, 3000);
  }, [refresh]);
  const mobileNo = useMemo(() => {
    if (user) {
      if (user?.mobile) {
        return user?.mobile?.startsWith("+91" || "91+" || "91")
          ? user?.mobile
          : `91${user?.mobile}`;
      }
    }
  }, [user]);
  useEffect(() => {
    Axios.get(`/api/crm/get-whatsapp?agent_id=${auth?.id}&mobile=${mobileNo}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log(res);
        setWhatsAppSu(res?.data?.getSMS);
      })
      .catch((err) => console.log(err));

    Axios.get(`/api/crm/get-service-list?user_id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setPurchasedServices(res.data?.servicepurchased || []))
      .catch((err) => console.log(err));
  }, [mobileNo, auth, servicespurchased]);

  console.log("user", user);
  console.log(purchasedServices, "ser");

  useEffect(() => {}, []);
  const handlemembership = () => {
    if (!_.isEmpty(user?.plan_detail_all)) {
      NotificationManager.error("User already taken MemberShip", "Purchased");
    }
  };

  return (
    <>
      <MainWrapper>
        <Loader loading={loading}>
          <main className="content">
            <div className="container-fluid p-0">
              <div className="page-header mb-2">
                <div className="row">
                  <div className="col-md-12">
                    <h4>Profile</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="card">
                    <div className="card-header btn-header p-2">
                      <div className="left-box-tools">
                        <button className="btn btn-secondary btn-sm pl-1 pr-1">
                          <i className="fa fa-mail-reply" aria-hidden="true" />{" "}
                          Back
                        </button>
                        {/* <button className="btn btn-secondary btn-sm pr-1">
                          <i className="fa fa-pencil" /> Edit
                        </button>
                        <button className="btn btn-secondary btn-sm">
                          <i className="fa fa-trash" /> Delete
                        </button> */}
                      </div>
                      <div className="right-box-tools">
                        <button
                          className="btn btn-secondary btn-rounded btn-sm mr-2"
                          title="Prev"
                        >
                          <i className="fa fa-angle-left" aria-hidden="true" />
                        </button>
                        <button
                          className="btn btn-secondary btn-rounded btn-sm"
                          title="Next"
                        >
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="small-details">
                            <div className="details">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="d-md-flex justify-content-between">
                                    <div className="order-md-2"></div>
                                    <div className="order-md-1">
                                      <h4 className="font-weight-bold mb-2">
                                        {_.get(user, "name", "N/A")}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row lead-info">
                                <div className="col-md-12">
                                  <div className="font-weight-bold d-inline-block label-tag">
                                    MemberShip
                                  </div>
                                  <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                    :
                                  </div>
                                  <div className="d-inline-block data-tag">
                                    <span id="l_Code">
                                      {_.get(
                                        user?.plan_detail_all,
                                        "name",
                                        "N/A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="font-weight-bold d-inline-block label-tag">
                                    Expiry Date
                                  </div>
                                  <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                    :
                                  </div>
                                  <div className="d-inline-block data-tag">
                                    <span id="l_Code">
                                      {_.get(
                                        user?.plan_detail_all,
                                        "expiry",
                                        "N/A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="font-weight-bold d-inline-block label-tag">
                                    Email
                                  </div>
                                  <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                    :
                                  </div>
                                  <div className="d-inline-block data-tag">
                                    <span id="l_Code">
                                      {_.get(user, "email", "N/A")}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="font-weight-bold d-inline-block label-tag">
                                    Mobile
                                  </div>
                                  <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                    :
                                  </div>
                                  <div className="d-inline-block data-tag">
                                    <span id="l_Code">
                                      {_.get(user, "mobile", "N/A")}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="font-weight-bold d-inline-block label-tag">
                                    Created Date
                                  </div>
                                  <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                    :
                                  </div>
                                  <div className="d-inline-block data-tag">
                                    <span id="l_Code">
                                      {_.get(user, "created_at", "N/A")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-2 my-3">
                              <button
                                className="btn btn-secondary btn-sm moreDetailbtn"
                                onClick={() => setIsDetailShow(!isDetailShow)}
                              >
                                More Details...
                              </button>
                            </div>
                          </div>

                          <Collapse isOpen={isDetailShow}>
                            <div className="lead-details mt-3">
                              <div className="row">
                                <div className="col-sm-12 mb-2">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold mt-0 mb-0 inline">
                                      Lead Details
                                    </h4>
                                    <LeadDetails user={user} id={id} />
                                  </div>
                                  <hr className="my-0" />
                                </div>

                                <div className="row p-3">
                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Name
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {user?.user_lead
                                          ? user?.user_lead?.name
                                            ? user?.user_lead?.name
                                            : user?.name
                                          : user?.name}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Assigned To
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">{auth?.name}</span>
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Lead Status
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {user?.user_lead?.status_id == 1
                                          ? "CDF"
                                          : user?.user_lead?.status_id == 2
                                          ? "Not Contacted"
                                          : user?.user_lead?.status_id}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Mobile
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {user?.user_lead?.mobile
                                          ? user?.user_lead.mobile
                                          : user.mobile}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Whatsapp No
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {_.get(user?.user_lead, "phone", "N/A")}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Email
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {user?.user_lead
                                          ? user?.user_lead?.email ==
                                              "undefined" ||
                                            user?.user_lead?.email == false
                                            ? user?.email
                                            : user?.user_lead?.email
                                          : user?.email}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Company
                                    </div>
                                    <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {_.get(
                                          user?.user_lead,
                                          "company",
                                          "N/A"
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Product Offered
                                    </div>
                                    <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      <span id="l_Code">
                                        {_.get(
                                          user?.user_lead,
                                          "rating_id",
                                          "N/A"
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  {/* <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Created By
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {_.get(
                                        user?.user_lead?.created_by,
                                        "name",
                                        "N/A"
                                      )}
                                    </div>
                                  </div> */}

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Source Type
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {user?.user_lead?.source_type
                                        ? JSON.parse(
                                            user?.user_lead?.source_type
                                          )?.label
                                        : ""}
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Email Notification
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {!user?.user_lead?.email_notify ||
                                      user?.user_lead?.email_notify == 0
                                        ? "No"
                                        : "yes"}
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Sector
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {user?.user_lead?.sector?.name}
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Industry
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {user?.user_lead?.industry?.name}
                                    </div>
                                  </div>

                                  <div className="col-md-6 editable-row mb-2">
                                    <div className="font-weight-bold d-inline-block label-tag">
                                      Investment{" "}
                                    </div>
                                    <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                      :
                                    </div>
                                    <div className="d-inline-block data-tag">
                                      {user?.user_lead?.service_id}
                                    </div>
                                  </div>
                                  <div className="col-sm-12 mb-2 mt-2 mx-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h4 className="font-weight-bold mt-0 mb-0 inline">
                                        General Notes
                                      </h4>
                                    </div>
                                    <hr className="my-0 mr-4" />
                                  </div>
                                  <div className="col-6 editable-row  mx-2">
                                    <div className="remark-box bg-secondary p-2">
                                      {_.get(user?.user_lead, "remark", "N/A")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 mb-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h4 className="font-weight-bold mt-0 mb-0 inline">
                                    MemberShip Details
                                  </h4>
                                </div>
                                <hr className="my-0" />
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Name
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(user, "name", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  MemberShip
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(
                                      user?.plan_detail_all,
                                      "name",
                                      "N/A"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Sector
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.map(
                                      user?.user_plan_details,
                                      (planSector) =>
                                        _.get(planSector, "sector.name", "")
                                    ).join(",")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Industry
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.map(
                                      user?.user_plan_details,
                                      (planSector) =>
                                        _.get(planSector, "project.name", "")
                                    ).join(",")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Joinning Date
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(
                                      user?.plan_detail_all,
                                      "purchased_at",
                                      "N/A"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Mobile
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(user, "mobile", "N/A")}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Email
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {_.get(user, "email", "N/A")}
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-12 mb-2 mt-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h4 className="font-weight-bold mt-0 mb-0 inline">
                                    Address Details
                                  </h4>
                                </div>
                                <hr className="my-0" />
                              </div>

                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  City
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {" "}
                                    {_.get(user, "city", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Location
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {" "}
                                    {_.get(user, "location", "N/A")}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 editable-row mb-2">
                                <div className="font-weight-bold d-inline-block label-tag">
                                  Address
                                </div>
                                <div className="font-weight-bold  pl-1 pr-1 d-inline-block colon-tag">
                                  :
                                </div>
                                <div className="d-inline-block data-tag">
                                  <span id="l_Code">
                                    {" "}
                                    {_.get(user, "address", "N/A")}
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-12 mb-2 mt-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h4 className="font-weight-bold mt-0 mb-0 inline">
                                    Remarks
                                  </h4>
                                </div>
                                <hr className="my-0" />
                              </div>
                              <div className="col-md-12 editable-row mb-2">
                                <div className="remark-box bg-secondary p-2">
                                  This is dummy text.
                                </div>
                              </div>
                            </div>
                          </Collapse>
                          {permissions?.find((ps) => ps.name === "note-list") !=
                          null ? (
                            <Note
                              id={id}
                              permissions={permissions}
                              leadDetails={user}
                            />
                          ) : (
                            ""
                          )}

                          {permissions?.find(
                            (ps) => ps.name === "crm-workshop-list"
                          ) != null ? (
                            <List
                              leadDetails={user}
                              setWsStatus={setWsStatus}
                            />
                          ) : (
                            ""
                          )}

                          {permissions?.find(
                            (ps) => ps.name === "crm-projectreport-list"
                          ) != null ? (
                            <ProjectReport
                              leadDetails={user}
                              setWsStatus={setWsStatus}
                            />
                          ) : (
                            ""
                          )}

                          {permissions?.find(
                            (ps) => ps.name === "crm-industrialreport-list"
                          ) != null ? (
                            <IndustrialReport
                              leadDetails={user}
                              setWsStatus={setWsStatus}
                            />
                          ) : (
                            ""
                          )}

                          {permissions?.find(
                            (ps) => ps.name === "crm-customize-report"
                          ) != null ? (
                            <CustomizeProjectReport
                              leadDetails={user}
                              setWsStatus={setWsStatus}
                            />
                          ) : (
                            ""
                          )}

                          <MembershipServicesList
                            leadDetails={user}
                            setWsStatus={setWsStatus}
                          />

                          {/* <div className="accordion" id="servicesAccordion">
                            <div className="card">
                              <div className="card-header p-0" id="heading-12">
                                <Link
                                  className="card-title m-0 h5 text-white d-block p-2 collapsed"
                                  href="#"
                                  data-toggle="collapse"
                                  data-target="#collapse-12"
                                  aria-expanded="true"
                                  aria-controls="collapse-12"
                                >
                                  Services List (
                                  {purchasedServices?.length || 0})
                                </Link>
                              </div>
                              <div
                                id="collapse-12"
                                className="collapse"
                                aria-labelledby="heading-12"
                                data-parent="#servicesAccordion"
                              >
                                <div className="card-body p-2">
                                  <div className="table-responsive">
                                    <table className="table table-sm m-0">
                                      <thead className="bg-dark text-white">
                                        <tr>
                                          <th>S.No.</th>
                                          <th>Title</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                      {console.log(
                                        purchasedServices,
                                        "servicessssssssssssssssss"
                                      )}
                                      <tbody>
                                        {purchasedServices
                                          ? purchasedServices.map((item, i) => (
                                              <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item?.service?.name}</td>
                                                <td>{item?.service?.amount}</td>
                                              </tr>
                                            ))
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {permissions?.find(
                            (ps) => ps.name === "crm-services-list"
                          ) != null ? (
                            <div className="accordion" id="ActivityAccordion">
                              <div className="card">
                                <div className="card-header p-0" id="heading-6">
                                  <Link
                                    className="card-title m-0 h5 text-white d-block p-2"
                                    to="#"
                                    data-toggle="collapse"
                                    data-target="#collapse-2"
                                    aria-expanded="false"
                                    aria-controls="collapse-2"
                                  >
                                    Services List (
                                    {purchasedServices?.length || 0})
                                  </Link>
                                </div>
                                <div
                                  id="collapse-2"
                                  className="collapse "
                                  aria-labelledby="heading-6"
                                  data-parent="#ActivityAccordion"
                                >
                                  <div className="card-body p-2">
                                    <div className="table-responsive">
                                      <table className="table table-sm m-0">
                                        <thead className="bg-dark text-white">
                                          <tr>
                                            <th>S.No.</th>
                                            <th>Title</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {purchasedServices
                                            ? purchasedServices.map(
                                                (item, i) => (
                                                  <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                      {item?.service?.name ||
                                                        item?.title}
                                                    </td>
                                                    <td>
                                                      {item?.service?.amount}
                                                    </td>
                                                    <td>
                                                      {item?.service_payments
                                                        ?.agentcode == null ? (
                                                        <EditServicesDetail
                                                          leadDetail={user}
                                                          setWsStatus={
                                                            setWsStatus
                                                          }
                                                          item={item}
                                                        />
                                                      ) : null}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <UserCallList leadDetail={user} />

                          {permissions?.find(
                            (ps) => ps.name === "crm-whatsapp-list"
                          ) != null ? (
                            <WhatsAppSummary whatsAppSu={whatsAppSu} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="accordion" id="ActionsAccordion">
                    <div className="card mb-3">
                      <div className="card-header p-0" id="heading-1">
                        <Link
                          className="card-title m-0 h5 text-white d-block p-2"
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapse-1"
                          aria-expanded="true"
                          aria-controls="collapse-1"
                        >
                          Quick Actions
                        </Link>
                      </div>
                      <div
                        id="collapse-1"
                        className="collapse show"
                        aria-labelledby="heading-1"
                        data-parent="#ActionsAccordion"
                      >
                        <div className="card-body p-2">
                          <div className="row">
                            {permissions?.find(
                              (ps) => ps.name === "crm-whatsapp-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <SendSms
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {!isCallAuthenticated ? (
                              <div className="col-md-6 mb-2">
                                <LoginToCall setWsStatus={setWsStatus} />
                              </div>
                            ) : null}

                            {isCallAuthenticated ? (
                              <div className="col-md-6 mb-2">
                                <UserCall user={user} />
                              </div>
                            ) : null}
                            {permissions?.find(
                              (ps) => ps.name === "crm-workshop-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddWorkshop
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {permissions?.find(
                              (ps) => ps.name === "crm-membership-create"
                            ) != null ? (
                              <div
                                className="col-md-6 mb-2"
                                onClick={() => handlemembership(true)}
                              >
                                <AddMembership
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {permissions?.find(
                              (ps) => ps.name === "crm-projectreport-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddProjectReport
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {permissions?.find(
                              (ps) => ps.name === "crm-industrial-report-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddIndustrialReport
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {permissions?.find(
                              (ps) => ps.name === "crm-customize-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddCustomizeProjectReport
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {permissions?.find(
                              (ps) => ps.name === "crm-edp-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddEdp
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {/* {permissions?.find(
                              (ps) => ps.name === "crm-services-create"
                            ) != null ? (
                              <div className="col-md-6 mb-2">
                                <AddServices
                                  leadDetail={user}
                                  setWsStatus={setWsStatus}
                                  setRefresh={setRefresh}
                                />
                              </div>
                            ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card">
                      <div className="card-header p-0" id="heading-2">
                        <Link
                          className="card-title m-0 h5 text-white d-block p-2"
                          href="#"
                          data-toggle="collapse"
                          data-target="#collapse-2"
                          aria-expanded="true"
                          aria-controls="collapse-1"
                        >
                          Quick Overview
                        </Link>
                      </div>
                      <div
                        id="collapse-2"
                        className="collapse show"
                        aria-labelledby="heading-2"
                        data-parent="#ActionsAccordion"
                      >
                        <div className="card-body p-2">
                          <div className="d-flex justify-content-between align-items-center border-bottom mb-1 pb-1">
                            <div className="label">Open Activity</div>
                            <label className="badge badge-primary">0</label>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="label">Closed Activity</div>
                            <label className="badge badge-danger">0</label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Loader>
      </MainWrapper>
    </>
  );
};

export default Details;
