import React from "react";
import PieChart from "../Chart/PieChart";
import { Table } from "reactstrap";
import { addDays, subDays } from "date-fns";
import { Button, Modal } from "reactstrap";
import { RiUserAddLine } from "react-icons/ri";
import { VscCallIncoming } from "react-icons/vsc";
import { BiHappy } from "react-icons/bi";
import { LuPhoneCall } from "react-icons/lu";
import { Link } from "react-router-dom";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import MainWrapper from "../MainWrapper";
import LineChart from "../Chart/LineChart";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRangePicker } from "react-date-range";
import Axios from "axios";
import { SalesDashboardCount } from "../../store/actions/DashboardActions";
Chart.register(
  ArcElement,
  LineElement,
  PieController,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  PointElement,
  BarElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale
);

const ChartReport = () => {
  const [targetList, setTargetList] = useState([]);
  const { auth } = useSelector(({ auth }) => auth);
  console.log(auth, "auth")
  const { dashboardcountsale } = useSelector(
    ({ dashboardReducer }) => dashboardReducer
  );
  const [pieChartData, setPieChartData] = useState([]);
  const [achievementCategory, setAchievementCategory] = useState({});
  const [achievement, setAchievement] = useState(0);
  const [yearlyData, setYearlyData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 15),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const resetDateRange = () =>
    setDateRange([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 15),
        key: "selection",
      },
    ]);

  const getAmount = (curr, prev) => {
    const arr = [...Object.values(curr), ...Object.values(prev)];
    const exist = {};
    let newArr = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].type in exist && arr[i].amount) {
        exist[arr[i].type] += parseInt(arr[i].amount);
        newArr = newArr.map((item) =>
          item.label === arr[i].type
            ? { ...item, data: exist[arr[i].type] }
            : item
        );
      } else {
        if (arr[i].amount) {
          exist[arr[i].type] = parseInt(arr[i].amount);
          newArr.push({ label: arr[i].type, data: exist[arr[i].type] });
        }
      }
    }
    return newArr;
  };

  useEffect(() => {
    Axios.get(
      `/api/crm/get-chart-values?from=${moment(dateRange[0]?.startDate).format(
        "YYYY-MM-DD"
      )}&to=${moment(dateRange[0]?.endDate).format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then(({ data }) => {
        setTargetList(data?.target || []);
        setAchievementCategory(data?.achive || {});
        setAchievement(
          data?.achive &&
          Object.values(data?.achive).reduce(
            (accu, item) => (accu += item),
            0
          )
        );
      })
      .catch((err) => console.error(err));

    Axios.get(`/api/crm/get-chart-values-month`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then(({ data }) => {
        setYearlyData(data?.getpayment);
      })
      .catch((err) => console.error(err));
    dispatch(SalesDashboardCount(auth.token, "", ""));
    Axios.get(`/api/crm/get-agent-target`)
      .then((res) => {
        setChartData(
          getAmount(
            res?.data?.getAgentPaymentCurrentMonth,
            res?.data?.getAgentPaymentLastMonth
          )
        );
      })
      .catch((err) => console.log(err.response));
  }, [auth.token, dateRange]);

  useEffect(() => {
    if (targetList.length) {
      setPieChartData([
        { label: "Target", data: getSumTarget(targetList, "target") },
        { label: "Achieved", data: achievement },
      ]);
    }
  }, [targetList, achievement, dateRange]);

  const getPercentage = (target, ach) => {
    return ((ach / target) * 100).toFixed(2);
  };

  const getSumTarget = (arr = [], key = "") => {
    return arr.reduce(
      (accu, item) => (accu += parseInt(item?.[key]?.replaceAll(",", "")) || 0),
      0
    );
  };
  return (
    <MainWrapper>
      <div className="px-3 py-5 chart-report-bg chart-container">
        <div className="d-flex stats-card-wrapper align-items-center">

          <div className="stats-card d-flex align-items-center justify-content-between">
            <div>
              <h5
                className="font-normal font-weight-normal  mb-2"
                style={{ color: "#6C757D" }}
              >
                Total Connected
              </h5>
              <h2 className="mb-0">
                {" "}
                {dashboardcountsale?.totalConnected || 0}{" "}
              </h2>
            </div>
            <div className="stats-icon-wrapper d-flex align-items-center justify-content-center">
              <LuPhoneCall size={32} />
            </div>
          </div>
          <Link to={`/leads`}>
            <div className="stats-card d-flex align-items-center justify-content-between">
              <div>
                <h5
                  className="font-normal font-weight-normal  mb-2"
                  style={{ color: "#6C757D" }}
                >
                  Total Leads
                </h5>
                <h2 className="mb-0"> {dashboardcountsale?.totalLead || 0} </h2>
              </div>
              <div className="stats-icon-wrapper d-flex align-items-center justify-content-center">
                <RiUserAddLine size={32} />
              </div>
            </div>
          </Link>
          <Link
            to={`/leads?desposition=CALLBK - Call Back *`}>
            <div className="stats-card d-flex align-items-center justify-content-between">

              <div>
                <h5
                  className="font-normal font-weight-normal  mb-2"
                  style={{ color: "#6C757D" }}
                >
                  Total Call Back
                </h5>
                <h2 className="mb-0">
                  {" "}
                  {dashboardcountsale?.totalCallBack || 0}{" "}
                </h2>
              </div>
              <div className="stats-icon-wrapper d-flex align-items-center justify-content-center">
                <VscCallIncoming size={32} />
              </div>
            </div>
          </Link>
          <Link
            to={`/leads?desposition=Interested`}>
            <div className="stats-card d-flex align-items-center justify-content-between">
              <div>
                <h5
                  className="font-normal font-weight-normal  mb-2"
                  style={{ color: "#6C757D" }}
                >
                  Total Intrested
                </h5>
                <h2 className="mb-0">
                  {" "}
                  {dashboardcountsale?.totalInterested || 0}{" "}
                </h2>
              </div>
              <div className="stats-icon-wrapper d-flex align-items-center justify-content-center">
                <BiHappy size={32} />
              </div>
            </div>
          </Link>
        </div>
        <div className="row align-items-stretch">
          <div className="col-md-8 mb-4">
            <div className=" report-card">
              <div className="d-flex align-items-center mb-4">
                <Button className="mr-3" onClick={toggle}>
                  Select Date
                </Button>
                <Button color="primary" onClick={resetDateRange}>
                  Reset
                </Button>
              </div>
              <Table>
                <thead>
                  <tr className="bg-secondary">
                    <th>Team</th>
                    <th>Target</th>
                    <th>Achievement</th>

                    <th>Per</th>
                  </tr>
                </thead>
                <tbody>
                  {targetList.map((item) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{item.vertical_type}</th>
                        <td>{item.target}</td>
                        <td>
                          {
                            achievementCategory[
                            Object.keys(achievementCategory).find(
                              (it) => it === item.vertical_name
                            )
                            ]
                          }
                        </td>
                        <td>
                          {getPercentage(
                            parseInt(item?.target.replaceAll(",", "")),
                            achievementCategory[
                            Object.keys(achievementCategory).find(
                              (it) => it === item.vertical_name
                            )
                            ]
                          )}
                          %
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row">Target</th>
                    <td>{getSumTarget(targetList, "target")} </td>
                    <th scope="row">Ach</th>
                    <td> {achievement} </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="report-card h-100 d-flex align-items-center ">
              <PieChart
                pieChartData={pieChartData}
                colors={["rgb(255, 99, 132)", "rgb(54, 162, 235)"]}
              />
            </div>
          </div>

          {/* <div className="col-md-4">
            <div className="report-card h-100 d-flex align-items-center ">
              <PieChart
                pieChartData={chartData}
                colors={[
                  "rgb(255, 99, 132)",
                  "rgb(54, 162, 235)",
                  "rgb(255,140,0)",
                  "rgb(0,206,209)",
                  "rgb(189,183,107)",
                  "rgb(72,209,204)",
                ]}
              />
            </div>
          </div> */}
          <div className="col-md-8">
            <div className="report-card h-100">
              <LineChart yearlyData={yearlyData} />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <DateRangePicker
          className="rounded-lg"
          onChange={(item) => setDateRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateRange}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
      </Modal>
    </MainWrapper>
  );
};

export default ChartReport;
