import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  CustomInput,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  CardTitle,
  Jumbotron,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  FormText,
  Badge,
} from "reactstrap";
import Loader from "../layouts/Loader";
import axios from "axios";
import Select, { components } from "react-select";
import classnames from "classnames";
import _ from "lodash";
import { FiTrash, FiEdit, FiEye, FiDownload } from "react-icons/fi";
import ModalQualification from "./ModalQualification";
import { useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import MainWrapper from "../components/MainWrapper";
import CKEditor from "react-ckeditor-component";
import { useSelector } from "react-redux";

const EditDetailed = () => {
  const [ids, setIds] = useState("");
  const [users, setUsers] = useState({});
  const [consultantId, setConsultantId] = useState("");
  const [userId, setUserId] = useState("");
  const [valid, setValid] = useState(false);
  const [associate, setAssociate] = useState("1");
  const [name, setName] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [designation, setDesignation] = useState("");
  const [certificate, setCertificate] = useState("");
  const [certificatename, setCertificateName] = useState("");
  const [message, setMessage] = useState("");
  const [experience, setExperience] = useState("");
  const [profilesummary, setProfilesummary] = useState("");
  const [educationaldetail, setEducationaldetail] = useState("");
  const [workexperience, setWorkExperience] = useState("");
  const [skills, setSkills] = useState("");
  const [speakIn, setSpeakIn] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [region, setRegion] = useState("");
  const [website, setWebsite] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [profile, setProfile] = useState("");
  const [status, setStatus] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [telephonicFee, setTelephonicFee] = useState("");
  const [videoCallFee, setVideoFee] = useState("");
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState({});
  const [subIndustryModel, setSubIndustryModel] = useState(false);
  const [subIndustry, setSubIndustry] = useState([]);
  const [suIndustryData, setSubIndustryData] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [consultantFee, setConsultantFee] = useState([]);
  const [language, setLanguage] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);

  const [inputList, setInputList] = useState([
    { selectedDays: [], start_time: "", end_time: "" },
  ]);
  const [days, setDays] = useState([
    { id: 1, value: "SUN" },
    { id: 2, value: "MON" },
    { id: 3, value: "TUES" },
    { id: 4, value: "WED" },
    { id: 5, value: "THURS" },
    { id: 6, value: "FRI" },
    { id: 7, value: "SAT" },
  ]);
  const [selectedSubindustry, setSelectedSubindustry] = useState([]);
  const [preferedIndustry, setPreferedIndustry] = useState("");
  const [preferedSector, setPreferedSector] = useState("");
  const [type, setType] = useState("1");
  const [dob, setDob] = useState("");
  const [today, setToday] = useState("");
  const [dobmax, setDobMax] = useState("");
  const [dobmin, setDobMin] = useState("");
  const [subcategory, setSubCategory] = useState([]);
  const [subsubcategory, setSubSubcategory] = useState([]);
  const [certificatedata, setCertificatedata] = useState([
    { certificatename: "", certificateimage: "", year: "" },
  ]);
  const [workexperincedata, setWorkexperincedata] = useState([
    { companyname: "", from: "", to: "", workarea: "" },
  ]);
  const [educationaldetaildata, setEducationaldetaildata] = useState([
    { board: "", degree: "", passingyear: "" },
  ]);

  const [selectError, setSelectError] = useState({
    name: "",
    bussinessName: "",
    email: "",
    whatsApp: "",
    organisation: "",
    designation: "",
    experience: "",
    qualification: "",
    skills: "",
    speakIn: "",
    dob: "",
    profilesummary: "",
  });
  const [consultancyType, setConsultancyType] = useState(0);

  const [professionalservices, setProfessionalServices] = useState([]);
  const [departmentLocation, setDepartmentLocation] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [tieUpDetail, setTieUpDetail] = useState("");
  const [log, setLog] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("1");
  const [mou, setMou] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [memprivilege, setMemPrivilege] = useState("");
  const [supplier, setSupplier] = useState("");
  const [other, setOther] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [multiAssociate, setMultiAssociate] = useState([
    { value: 1, label: "Professional Expert" },
  ]);
  const { id } = useParams();
  const { auth } = useSelector(({ auth }) => auth);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleIndustriesChange = (value) => {
    setIndustry(value);
    setSelectedSubindustry([]);
  };

  const educationalEditor = useRef(0);

  const handleType = (type) => {
    let typeArray = [];
    let option = [
      { value: 1, label: "Professional Expert" },
      { value: 2, label: "Supplier" },
      { value: 3, label: "Industry Expert" },
      { value: 4, label: "Enterpreneur" },
      { value: 5, label: "Institutional" },
      { value: 6, label: "Exhibitors" },
      { value: 7, label: "Visitors" },
      { value: 8, label: "Franchise Network" },
      { value: 9, label: "Startup Network" },
      { value: 10, label: "Others" },
      { value: 11, label: "Tata Play" },
    ];

    type.split(",").map((op) => {
      let ld = option.find((m) => {
        if (m.value == op) {
          return m;
        }
      });
      if (!_.isEmpty(ld)) {
        typeArray.push(ld);
      }
    });

    return typeArray;
  };
  const handleTypeMatched = (type) => {
    if (multiAssociate) {
      if (multiAssociate.find((m) => m.value == type)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleMouChange = (e) => {
    setMou(e.target.files[0]);
  };

  const mAssociate = useMemo(() => {
    if (multiAssociate) {
      return multiAssociate.map((m) => m.value).join(",");
    } else {
      return null;
    }
  }, [multiAssociate]);
  useEffect(() => {
    todaydate();
    let selectedLanguage = "";
    let selectedQualification = "";
    axios
      .get(`/api/crm/sectordata-mega`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setIndustries(res.data.subcategory);
      })
      .catch((err) => console.log(err));

    axios
      .get(`/api/crm/professionalServices`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setProfessionalServices(res.data.professionalservices);
      })
      .catch((err) => console.log(err));

    axios
      .get("/api/crm/language", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(
        (res) => (
          setLanguage(res.data.language), (selectedLanguage = res.data.language)
        )
      )
      .catch((err) => NotificationManager.error(err.response.data.message));

    axios
      .get("/api/crm/qualification", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(
        (res) => (
          setQualificationList(res.data.qualification),
          (selectedQualification = res.data.qualification)
        )
      )
      .catch((err) => NotificationManager.error(err.response.data.message));

    axios
      .get(`/api/crm/consultant/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setIds(res.data.consultant.id);
        setUserId(res.data.consultant.user_id);
        setName(res.data.consultant.name ?? "");
        setBussinessName(res.data.consultant.bussinessName ?? "");
        setMobile(res.data.consultant.mobile ?? "");
        setSupplier(res.data.consultant.supplier_type);
        setInstituteType(
          res.data.consultant.consultant_profile.institute_type ?? ""
        );
        setEmail(res.data.consultant.email ?? "");
        setDesignation(
          res.data.consultant.consultant_profile.designation ?? ""
        );
        setExperience(res.data.consultant.consultant_profile.experience ?? "");
        setSkills(res.data.consultant.consultant_profile.skill ?? "");
        setProfilesummary(
          res.data.consultant.consultant_profile.profile_summary ?? ""
        );
        setWorkExperience(
          res.data.consultant.consultant_profile.work_experience ?? ""
        );
        setEducationaldetail(
          res.data.consultant.consultant_profile.educational_detail ?? ""
        );
        setThumbnail(
          res.data.consultant.image ?? res.data.consultant.thumbnail ?? ""
        );
        setCertificate(
          res.data.consultant.consultant_profile.certificate ?? ""
        );
        setCertificateName(
          res.data.consultant.consultant_profile.certificate_name ?? ""
        );
        setWhatsApp(
          res.data.consultant.consultant_profile.whatsapp_number ?? ""
        );
        setWebsite(res.data.consultant.consultant_profile.website_url ?? "");
        setState(res.data.consultant.consultant_profile.state ?? "");
        setCity(res.data.consultant.consultant_profile.city ?? "");
        setAddress(res.data.consultant.consultant_profile.address ?? "");
        setProfile(res.data.consultant.consultant_profile.upload_profile ?? "");
        setOrganisation(
          res.data.consultant.consultant_profile.name_of_organisation ?? ""
        );
        setPincode(res.data.consultant.consultant_profile.pincode ?? "");
        setRegion(res.data.consultant.consultant_profile.region ?? "");
        setPreferedIndustry(
          res.data.consultant.consultant_profile.prefered_industry ?? ""
        );
        setPreferedSector(
          res.data.consultant.consultant_profile.preferred_sector ?? ""
        );
        setDob(res.data.consultant.consultant_profile.dob ?? "");
        setType(res.data.consultant.consultant_profile.type ?? "");
        setMultiAssociate(
          handleType(res.data.consultant.consultant_profile.type ?? "")
        );
        if (
          res.data.consultant.consultant_profile.type == 1 ||
          res.data.consultant.consultant_profile.type == 5
        ) {
          setConsultancyType(1);
        }
        setTieUpDetail(res.data.consultant.consultant_profile.note ?? "");
        setDepartmentLocation(
          res.data.consultant.consultant_profile.department_location ?? ""
        );
        setDepartmentName(
          res.data.consultant.consultant_profile.department_name ?? ""
        );
        setMou(res.data.consultant.consultant_profile.mou ?? "");
        if (
          !_.isEmpty(res.data.consultant.consultant_profile.certificate_json)
        ) {
          setCertificatedata(
            JSON.parse(res.data.consultant.consultant_profile.certificate_json)
          );
        }
        if (!_.isEmpty(res.data.consultant.consultant_profile.workplace_json)) {
          setWorkexperincedata(
            JSON.parse(res.data.consultant.consultant_profile.workplace_json)
          );
        }
        if (
          !_.isEmpty(
            res.data.consultant.consultant_profile.educationaldetail_json
          )
        ) {
          setEducationaldetaildata(
            JSON.parse(
              res.data.consultant.consultant_profile.educationaldetail_json
            )
          );
        }
        setStatus(
          parseInt(res.data.consultant.consultant_profile.status) ? true : false
        );
        setCurrentStatus(res.data.consultant.consultant_profile.current_status);
        setValid(res.data.consultant.consultant_profile.mobile ? true : false);
        setRefresh((refresh) => refresh + 1);
        if (
          !_.isEmpty(res.data.consultant.consultant_profile.speak_in) &&
          !_.isNull(res.data.consultant.consultant_profile.speak_in) &&
          !_.isEqual(res.data.consultant.consultant_profile.speak_in, "null")
        ) {
          setSpeakIn(
            res.data.consultant.consultant_profile.speak_in
              .split(", ")
              .map((re) =>
                selectedLanguage.find(function (e) {
                  return e.value === re;
                })
              )
          );
        }
        if (
          !_.isEmpty(
            res.data.consultant.consultant_profile.highest_qualification
          ) &&
          !_.isNull(
            res.data.consultant.consultant_profile.highest_qualification
          ) &&
          !_.isEqual(
            res.data.consultant.consultant_profile.highest_qualification,
            "null"
          )
        ) {
          setQualification(
            res.data.consultant.consultant_profile.highest_qualification
              .split(", ")
              .map((re) =>
                selectedQualification.find(function (e) {
                  return e.value === re;
                })
              )
          );
        }
        if (res.data.log) {
          setLog(res.data.consultant.log);
        }
        if (!_.isEmpty(res.data.consultant.consultant_profile.mem_privilege)) {
          setMemPrivilege(res.data.consultant.consultant_profile.mem_privilege);
        }
        setPermissions(res.data.permission);
      })
      .catch((err) => console.log(err));
  }, []);

  const todaydate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    let dob = yyyy - 10 + "-" + mm + "-" + dd;
    let dobmin = yyyy - 100 + "-" + mm + "-" + dd;
    setToday(today);
    setDobMax(dob);
    setDobMin(dobmin);
  };

  const qualificationListFunction = () => {
    axios
      .get("/api/crm/qualification", {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => setQualificationList(res.data.qualification))
      .catch((err) => NotificationManager.success(err.response.data.message));
  };
  const getConsultantFees = () => {
    axios
      .get(`/api/crm/consultant-fee-detail/${ids}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setConsultantFee(res.data.consultantdetail);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (ids) {
      setLoading(true);
      getConsultantFees();
      consultanttimeslotdetail();
    }
  }, [refresh, , ids]);

  const consultanttimeslotdetail = () => {
    setLoading(true);
    axios
      .get(`/api/crm/consultant-timeslot/${ids}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        if (!_.isEmpty(res.data.consultanttimeslot)) {
          setInputList(
            res.data.consultanttimeslot.map((cert) => {
              return {
                selectedDays: [
                  ...cert.days.split(",").map((res) => parseInt(res)),
                ],
                start_time: cert.start_time,
                end_time: cert.end_time,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.success(err.response.data.message);
      });
  };

  const handleImageChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const changeMobile = (e) => {
    if (
      (_.size(e.target.value) < 11 && !isNaN(parseInt(e.target.value))) ||
      _.isEmpty(e.target.value)
    ) {
      setMobile(e.target.value);
      //setName('');
      setValid(false);
      if (_.size(e.target.value) >= 10) {
        setMessage("");
        setUsers({});
        setErrors({});
        // setEmail('');

        axios
          .get(`/api/crm/verify-mobile/${e.target.value}`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then((res) => {
            setUsers(res.data.user ?? {});
            // res.data.user ? setName(res.data.user.name) : null
            setUserId(res?.data?.user?.id);
            // res.data.user ? setEmail(res.data.user.email) : null
            setValid(res.data.valid);
            setMessage(res.data.message);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const validation = () => {
    let formIsValid = true;
    let errors = {};
    setSelectError({
      mobile: "",
      name: "",
      email: "",
      whatsApp: "",
      organisation: "",
      designation: "",
      experience: "",
      qualification: "",
      skills: "",
      speakIn: "",
      dob: "",
      profilesummary: "",
      subsubcategory: "",
      profile: "",
      departmentLocation: "",
      departmentName: "",
    });
    if (_.isEmpty(mobile)) {
      errors["mobile"] = "The mobile field is required.";

      formIsValid = false;
    }
    if (_.isEmpty(name)) {
      errors["name"] = "The name field is required.";

      formIsValid = false;
    }

    if (_.isEmpty(email)) {
      errors["email"] = "The email field is required.";

      formIsValid = false;
    }
    if (handleTypeMatched(7)) {
      if (_.isEmpty(dob)) {
        errors["dob"] = "The birthday field is required.";

        formIsValid = false;
      }
    }
    if (_.isEmpty(whatsApp)) {
      errors["whatsApp"] = "The whatspp number field is required.";

      formIsValid = false;
    }

    if (handleTypeMatched(2) && handleTypeMatched(6) && handleTypeMatched(7)) {
      if (_.isEmpty(organisation)) {
        errors["organisation"] = "The name of organisation field is required.";

        formIsValid = false;
      }
      if (handleTypeMatched(8)) {
        if (_.isEmpty(designation)) {
          errors["designation"] = "The designation field is required.";

          formIsValid = false;
        }
        if (_.isEmpty(experience)) {
          errors["experience"] = "The experience field is required.";

          formIsValid = false;
        }

        if (_.isEmpty(speakIn)) {
          errors["speakIn"] = "The language speak field is required.";

          formIsValid = false;
        }
      }
    }
    if (handleTypeMatched(7)) {
      if (_.isEmpty(skills)) {
        errors["skills"] = handleTypeMatched(7)
          ? "The about Institute field is required."
          : "The expertize field is required.";

        formIsValid = false;
      }
    }

    if (
      handleTypeMatched(2) &&
      handleTypeMatched(6) &&
      handleTypeMatched(7) &&
      handleTypeMatched(8)
    ) {
      if (_.isEmpty(qualification)) {
        errors["qualification"] = "The qualification field is required.";

        formIsValid = false;
      }

      if (_.isEmpty(profilesummary)) {
        errors["profilesummary"] = "The profile summary field is required.";

        formIsValid = false;
      }
    }

    if (handleTypeMatched(6) || handleTypeMatched(7)) {
      if (_.isEmpty(departmentLocation)) {
        errors["departmentLocation"] = handleTypeMatched(7)
          ? "The Institute location field is required."
          : "The department location field is required.";

        formIsValid = false;
      }
      if (_.isEmpty(departmentName)) {
        errors["departmentName"] = handleTypeMatched(7)
          ? "The Institute name field is required."
          : "The department name field is required.";

        formIsValid = false;
      }
    }

    setSelectError(errors);

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      let lang = "";
      let quali = "";
      speakIn &&
        speakIn.map(
          (item, index) =>
            (lang = index ? lang + ", " + item?.label : item?.label)
        );
      qualification &&
        qualification.map(
          (item, index) =>
            (quali = index ? quali + ", " + item?.label : item?.label)
        );

      const data = new FormData();
      data.append("name", name);
      data.append("bussinessName", bussinessName);
      data.append("user_id", userId);
      data.append("mobile", mobile);
      data.append("email", email);
      data.append("skill", skills);
      if (handleTypeMatched(6) && handleTypeMatched(7)) {
        data.append("certificate_name", certificatename);
        data.append("certificate", certificate);
        data.append("upload_profile", profile);
      }
      if (handleTypeMatched(6)) {
        data.append("thumbnail", thumbnail);
      }
      data.append("state", state);
      data.append("city", city);
      data.append("address", address);
      data.append("pincode", pincode);
      data.append("whatsapp_number", whatsApp);
      data.append("other", other);
      data.append("instituteType", instituteType);
      data.append("region", region);

      data.append("name_of_organisation", organisation);

      data.append("work_experience", workexperience);
      data.append("workplace_json", JSON.stringify(workexperincedata));
      data.append("designation", designation);
      data.append("experience", experience);
      data.append("speakIn", lang);

      data.append("profile_summary", profilesummary);

      if (handleTypeMatched(2) && handleTypeMatched(6)) {
        data.append("website_url", website);
      }
      // if (handleTypeMatched(2) && handleTypeMatched(6) && handleTypeMatched(7) && handleTypeMatched(8)) {
      data.append("highest_qualification", quali);
      data.append("educational_detail", educationaldetail);
      data.append(
        "educationaldetail_json",
        JSON.stringify(educationaldetaildata)
      );
      // }
      // data.append("highest_qualification", quali);
      data.append("type", mAssociate);
      if (handleTypeMatched(7)) {
        data.append("dob", dob);
      }

      //if (handleTypeMatched(6) && handleTypeMatched(7)) {
      certificatedata.forEach((res, i) => {
        data.append(
          `certificate_json[${i}][certificatename]`,
          res.certificatename
        );
        data.append(`certificate_json[${i}][year]`, res.year);
        data.append(
          `certificate_json[${i}][certificateimage]`,
          res.certificateimage
        );
      });
      // }

      if (handleTypeMatched(6) || handleTypeMatched(7)) {
        data.append("department_name", departmentName);
        data.append("department_location", departmentLocation);
        data.append("tieup_details", tieUpDetail);
      }

      data.append("form", "edit-detail");
      data.append("status", status ? "1" : "0");
      data.append("current_status", currentStatus);
      data.append("mou", mou);
      data.append("mem_privilege", memprivilege);
      setLoading(true);
      setErrors({});
      axios
        .post(`/api/crm/consultant/${ids}`, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          NotificationManager.success(res.data.message);
          setRefresh((refresh) => refresh + 1);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setLoading(false);
            setErrors(err.response.data.errors);
          } else {
            setLoading(false);
            NotificationManager.success(err.response.data.message);
            setRefresh((refresh) => refresh + 1);
          }
        });
      // for (var pair of data.entries()) {
      //   console.log(pair[0] + " - " + pair[1]);
      // }
    }
  };

  const SavefeeIndustry = (e) => {
    e.preventDefault();
    if (_.isEmpty(industry)) {
      if (consultancyType) {
        setErrors({
          professionalservices: ["Select professional service"],
        });
      } else {
        setErrors({
          industry: ["Select sector"],
        });
      }
    } else {
      const data = new FormData();
      data.append("consultant_profile", ids);
      data.append("user_id", ids);
      data.append("telephone_fee", telephonicFee);
      data.append("video_call_fee", videoCallFee);
      data.append("industry", !_.isEmpty(industry) && industry.value);
      data.append(
        "sub_industries",
        !_.isEmpty(selectedSubindustry) && selectedSubindustry.toString()
      );
      setErrors({});
      setLoading(true);
      axios
        .post(`/api/crm/consultant-fee`, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setLoading(true);
          NotificationManager.success(res.data.message);
          setRefresh((refresh) => refresh + 1);
          setIndustry({});
          setTelephonicFee("");
          setVideoFee("");

          setTimeout(() => {
            getConsultantFees();
          }, 1000);
          return;
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            setLoading(false);
            setErrors(err?.response?.data?.errors);
          } else {
            setLoading(false);
            NotificationManager.success(err.response?.data?.message);
          }
        });
    }
  };

  useEffect(() => {
    if (_.isEmpty(!industry.value))
      axios
        .get(`/api/crm/subsectordata?industry=${industry.value}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setSubIndustry(res.data?.subSubCats);
        })
        .catch((err) => console.log(err));
    setSelectedSubindustry([]);
  }, [industry]);

  useEffect(() => {
    setIndustry({});
  }, [consultancyType]);

  const handleSubIndustry = (value, ids) => {
    let list = selectedSubindustry;

    list = list.some((re) => re === value)
      ? list.filter((me) => me != value)
      : [...list, value];

    setSelectedSubindustry(list, ids);
  };

  //console.log(selectedSubindustry)
  const handleChecked = (id, index) => {
    const list = [...inputList];
    list[index].selectedDays = list[index].selectedDays.some((s) => s === id)
      ? list[index].selectedDays.filter((s) => s !== id)
      : [...list[index].selectedDays, id];
    setInputList(list);
  };

  const handleAllChecked = (ids, index) => {
    const list = [...inputList];
    list[index].selectedDays = ids.every((id) =>
      list[index].selectedDays.includes(id)
    )
      ? list[index].selectedDays.filter((s) => !ids.includes(s))
      : [...list[index].selectedDays.filter((s) => !ids.includes(s)), ...ids];
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleInputFileChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { selectedDays: [], start_time: "", end_time: "" },
    ]);
  };

  const saveTimeslot = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("consultant_profile", ids);
    data.append("user_id", userId);
    inputList.forEach((time) => {
      data.append(
        "time[]",
        JSON.stringify({
          days: time["selectedDays"],
          start_time: time["start_time"],
          end_time: time["end_time"],
        })
      );
    });
    setLoading(true);
    setErrors({});
    axios
      .post(`/api/crm/consultant-timeslot`, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(true);
        setRefresh((refresh) => refresh + 1);
        NotificationManager.success(res.data.message);
        setTimeout(() => {
          consultanttimeslotdetail();
        }, 1000);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setLoading(false);
          setErrors(err?.response?.data?.errors);
        } else {
          setLoading(false);
          NotificationManager.success(err?.response?.data?.message);
        }
      });
  };

  const handleRemoveClickCertificate = (index) => {
    const list = [...certificatedata];
    list.splice(index, 1);
    setCertificatedata(list);
  };

  // handle click event of the Add button
  const handleAddClickCertificate = () => {
    setCertificatedata([
      ...certificatedata,
      { certificatename: "", certificateimage: "", year: "" },
    ]);
  };
  const handleInputChangeCertificate = (e, index) => {
    const { name, value } = e.target;
    if (name == "year") {
      const re = /^[0-9\b]+$/;
      if (value === "" || (re.test(value) && value.length <= 4)) {
        const list = [...certificatedata];
        list[index][name] = value;
        setCertificatedata(list);
      }
    } else if (name == "certificateimage") {
      const list = [...certificatedata];
      list[index][name] = e.target.files[0];
      setCertificatedata(list);
    } else {
      const list = [...certificatedata];
      list[index][name] = value;
      setCertificatedata(list);
    }
  };
  // handle click event of the Remove button
  const handleRemoveClickWorkExperince = (index) => {
    const list = [...workexperincedata];
    list.splice(index, 1);
    setWorkexperincedata(list);
  };

  // handle click event of the Add button
  const handleAddClickExperince = () => {
    setWorkexperincedata([
      ...workexperincedata,
      { companyname: "", from: "", to: "", workarea: "" },
    ]);
  };
  const handleInputChangeExperince = (e, index) => {
    const { name, value } = e.target;

    const list = [...workexperincedata];
    list[index][name] = value;
    setWorkexperincedata(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickEducational = (index) => {
    const list = [...educationaldetaildata];
    list.splice(index, 1);
    setEducationaldetaildata(list);
  };

  // handle click event of the Add button
  const handleAddClickEducational = () => {
    setEducationaldetaildata([
      ...educationaldetaildata,
      { board: "", degree: "", passingyear: "" },
    ]);
  };
  const handleInputChangeEducational = (e, index) => {
    const { name, value } = e.target;

    if (name == "passingyear") {
      const re = /^[0-9\b]+$/;
      if (value === "" || (re.test(value) && value.length <= 4)) {
        const list = [...educationaldetaildata];
        list[index][name] = value;
        setEducationaldetaildata(list);
      }
    } else {
      const list = [...educationaldetaildata];
      list[index][name] = value;
      setEducationaldetaildata(list);
    }
  };

  const CurrentStatus = (e, i) => {
    switch (e.task) {
      case "1":
        return (
          <>
            <Badge color="primary">
              {i + 1}. Change to Draft by {e.user.name} at {e.created_at}{" "}
            </Badge>{" "}
            <br />
          </>
        );
      case "2":
        return (
          <>
            {" "}
            <Badge color="secondary">
              {i + 1}. Change to New by {e.user.name} at {e.created_at}{" "}
            </Badge>{" "}
            <br />
          </>
        );
      case "3":
        return (
          <>
            <Badge color="warning">
              {i + 1}. Change to Active by {e.user.name} at {e.created_at}{" "}
            </Badge>{" "}
            <br />
          </>
        );
      case "4":
        return (
          <>
            <Badge color="success">
              {i + 1}. Change to Publish by {e.user.name} at {e.created_at}{" "}
            </Badge>{" "}
            <br />
          </>
        );

      case "5":
        return (
          <>
            <Badge color="danger">
              {i + 1}. Change to Unpublish by {e.user.name} at {e.created_at}{" "}
            </Badge>{" "}
            <br />
          </>
        );
    }
  };

  const handleAssociate = (value) => {
    setMultiAssociate(value);
    setSubCategory([]);
  };

  return (
    <MainWrapper>
      <main className="content">
        <Fragment>
          <Container fluid>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Sector
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Time Slot
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader>EDIT CONSULTANT PROFILE</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm={12}>
                          <div className="text-center mb-3">
                            <Loader loading={loading}>
                              <Button
                                type="submit"
                                color="secondary"
                                size="sm"
                                className="mr-1"
                                onClick={() => setRedirect(true)}
                              >
                                Save
                              </Button>
                              <Button
                                color="secondary"
                                size="sm"
                                className="mr-1"
                              >
                                Save & New
                              </Button>
                              <Button
                                type="button"
                                color="secondary"
                                size="sm"
                                onClick={() => window.history.back()}
                              >
                                Close
                              </Button>
                            </Loader>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label className="col-md-5 control-label col-form-label col-12 text-md-right">
                              Associate with us as
                            </Label>

                            <Select
                              isMulti
                              options={[
                                { value: 1, label: "Professional Expert" },
                                { value: 2, label: "Supplier" },
                                { value: 3, label: "Industry Expert" },
                                { value: 4, label: "Enterpreneur" },
                                { value: 5, label: "Institutional" },
                                { value: 6, label: "Exhibitors" },
                                { value: 7, label: "Visitors" },
                                { value: 8, label: "Franchise Network" },
                                { value: 9, label: "Startup Network" },
                                { value: 10, label: "Others" },
                                { value: 11, label: "Tata Play" },
                                { value: 12, label: "Lecturer" },
                                { value: 13, label: "Brand Onboarding" },
                              ]}
                              onChange={handleAssociate}
                              value={multiAssociate}
                              invalid={!!errors.type}
                              className="col-md-7 p-0"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={12}>
                          <h5>
                            {handleTypeMatched(7)
                              ? "Basic Detail"
                              : "Contact Personal Detail"}
                          </h5>{" "}
                          <hr className="mt-0" />{" "}
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="user"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Mobile <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="mobile"
                              value={mobile}
                              onChange={(e) => changeMobile(e)}
                              className="col-md-7 p-0"
                              invalid={!!errors.mobile || selectError.mobile}
                            />
                            <FormFeedback
                              className="d-block offset-5"
                              valid={_.size(mobile) == 10 && valid}
                            >
                              {_.size(mobile) == 10 && valid && message}
                            </FormFeedback>
                            <FormFeedback className="offset-5">
                              {(!!errors.mobile ? errors.mobile[0] : "") ||
                                selectError.mobile}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="whatsApp"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              WhatsApp Number
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="whatsApp"
                              className="col-md-7"
                              value={whatsApp}
                              onChange={(e) =>
                                ((_.size(e.target.value) < 11 &&
                                  !isNaN(parseInt(e.target.value))) ||
                                  _.isEmpty(e.target.value)) &&
                                setWhatsApp(e.target.value)
                              }
                              invalid={selectError.whatsApp}
                            />
                            <FormFeedback className="offset-5">
                              {selectError.whatsApp}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        {handleTypeMatched(1) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Professional Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="name"
                                className="col-md-7"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                invalid={!!errors.name || selectError.name}
                              />
                              <FormFeedback className="offset-5">
                                {(!!errors.name ? errors.name[0] : "") ||
                                  selectError.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {handleTypeMatched(2) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Business Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="name"
                                className="col-md-7"
                                value={bussinessName}
                                onChange={(e) =>
                                  setBussinessName(e.target.value)
                                }
                                invalid={
                                  !!errors.bussinessName ||
                                  selectError.bussinessName
                                }
                              />
                              <FormFeedback className="offset-5">
                                {(!!errors.bussinessName
                                  ? errors.bussinessName[0]
                                  : "") || selectError.bussinessName}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {handleTypeMatched(3) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Expert Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="name"
                                className="col-md-7"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                invalid={!!errors.name || selectError.name}
                              />
                              <FormFeedback className="offset-5">
                                {(!!errors.name ? errors.name[0] : "") ||
                                  selectError.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {handleTypeMatched(4) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Enterpreneur Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="name"
                                className="col-md-7"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                invalid={!!errors.name || selectError.name}
                              />
                              <FormFeedback className="offset-5">
                                {(!!errors.name ? errors.name[0] : "") ||
                                  selectError.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {handleTypeMatched(5) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="name"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Institutional Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="name"
                                className="col-md-7"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                invalid={!!errors.name || selectError.name}
                              />
                              <FormFeedback className="offset-5">
                                {(!!errors.name ? errors.name[0] : "") ||
                                  selectError.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}

                        {handleTypeMatched(2) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="supplier"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Supplier Type
                              </Label>
                              <Input
                                type="select"
                                id="supplier"
                                className="col-md-7 custom-select"
                                value={supplier}
                                onChange={(e) => setSupplier(e.target.value)}
                                invalid={!!errors.supplier}
                              >
                                <option value="">select</option>
                                <option value="1">Platinum</option>
                                <option value="2">Gold</option>
                                <option value="3">Silver</option>
                                <option value="4">General</option>
                              </Input>
                              <FormFeedback className="offset-5">
                                {!!errors.supplier ? errors.supplier[0] : ""}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        ) : null}
                        {handleTypeMatched(5) ? (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="instituteType"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Choose Institute Type
                              </Label>
                              <Input
                                type="select"
                                id="instituteType"
                                className="col-md-7 custom-select"
                                value={instituteType}
                                onChange={(e) =>
                                  setInstituteType(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="University">University</option>
                                <option value="College">College</option>
                                <option value="Engineering">Engineering</option>
                                <option value="Research">Research</option>
                                <option value="School">School</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="email"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Email<span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="email"
                              className="col-md-7"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              invalid={!!errors.email || selectError.email}
                            />
                            <FormFeedback className="offset-5">
                              {(!!errors.email ? errors.email[0] : "") ||
                                selectError.email}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="skills"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              {handleTypeMatched(1)
                                ? "About Professional Expert"
                                : handleTypeMatched(2)
                                ? "About Supplier Expert"
                                : handleTypeMatched(3)
                                ? "About Industry Expert"
                                : handleTypeMatched(4)
                                ? "About Enterpreneur"
                                : handleTypeMatched(5)
                                ? "About Institution"
                                : "About Associate"}
                            </Label>
                            <Input
                              id="skills"
                              className="col-md-7"
                              value={skills}
                              placeholder={
                                handleTypeMatched(7)
                                  ? "describe yourself in one line."
                                  : "describe institute in one line."
                              }
                              onChange={(e) =>
                                e.target.value.length <= 80 &&
                                setSkills(e.target.value)
                              }
                              invalid={selectError.skills}
                            />
                            <FormText className="offset-5" color="danger">
                              Max {80 - skills.length} character allowed
                            </FormText>
                            <FormFeedback className="offset-5">
                              {selectError.skills}
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        {handleTypeMatched(7) && (
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="dob"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Birthday <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="dob"
                                className="col-md-7"
                                value={dob}
                                type="date"
                                max={dobmax}
                                min={dobmin}
                                onChange={(e) => setDob(e.target.value)}
                                invalid={selectError.dob}
                              />
                              <FormFeedback className="offset-5">
                                {selectError.dob}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        )}
                        {(handleTypeMatched(6) || handleTypeMatched(7)) && (
                          <Fragment>
                            <Col sm={12}>
                              <h5>
                                {handleTypeMatched(7)
                                  ? "Institution Detail"
                                  : "Department Detail"}
                              </h5>{" "}
                              <hr className="mt-0" />{" "}
                            </Col>
                            <Col sm={6}>
                              <FormGroup className="row">
                                <Label
                                  for="departmentName"
                                  className="col-md-5 control-label col-form-label col-12 text-md-right"
                                >
                                  {type !== "7"
                                    ? "Department Name"
                                    : "Institute Name"}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="departmentName"
                                  value={departmentName}
                                  onChange={(e) =>
                                    setDepartmentName(e.target.value)
                                  }
                                  invalid={selectError.departmentName}
                                  className="col-md-7 p-0"
                                />
                                <FormFeedback className="offset-5">
                                  {selectError.departmentName}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup className="row">
                                <Label
                                  for="departmentLocation"
                                  className="col-md-5 control-label col-form-label col-12 text-md-right"
                                >
                                  {type === "7" ? "Institution" : "Department"}{" "}
                                  Location{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  id="departmentLocation"
                                  className="col-md-7"
                                  value={departmentLocation}
                                  invalid={selectError.departmentLocation}
                                  onChange={(e) =>
                                    setDepartmentLocation(e.target.value)
                                  }
                                />
                                <FormFeedback className="offset-5">
                                  {selectError.departmentLocation}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              <FormGroup>
                                <Label
                                  for="tieUpDetail"
                                  className="col-md-3 control-label col-form-label col-12 text-md-center"
                                >
                                  Tie up Detail
                                </Label>
                                <CKEditor
                                  activeClass="offset-1 col-md-11"
                                  className="col-md-7"
                                  content={tieUpDetail}
                                  events={{
                                    change: (e) =>
                                      setTieUpDetail(e.editor.getData()),
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Fragment>
                        )}
                        <Fragment>
                          <Col sm={12}>
                            <h5>Educational Details</h5>
                            <hr className="mt-0" />
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="qualification"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Highest Qualification
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                options={qualificationList}
                                className="col-md-7 p-0"
                                isMulti
                                isSearchable
                                onChange={(value) => setQualification(value)}
                                value={qualification}
                              />
                              {selectError.qualification != "" ? (
                                <span className="text-danger offset-5 small">
                                  {selectError.qualification}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm={3}>
                            <ModalQualification
                              qualificationListFunction={
                                qualificationListFunction
                              }
                            />
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <Label
                                for="educationaldetail"
                                className="col-md-3 control-label col-form-label col-12 text-md-center"
                              >
                                Educational Detail
                              </Label>
                              <CKEditor
                                activeClass="offset-1 col-md-11"
                                className="col-md-12"
                                content={educationaldetail}
                                events={{
                                  change: (e) =>
                                    setEducationaldetail(e.editor.getData()),
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={12}>
                            <Table responsive striped>
                              <thead>
                                <tr>
                                  <th>Board / University Name</th>
                                  <th>Qualifying Degree</th>
                                  <th>Passing Year</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {educationaldetaildata &&
                                  educationaldetaildata.map((ix, i) => (
                                    <tr>
                                      <th>
                                        <Input
                                          type="text"
                                          name="board"
                                          value={ix.board}
                                          onChange={(e) =>
                                            handleInputChangeEducational(e, i)
                                          }
                                        />
                                      </th>
                                      <th>
                                        <Input
                                          type="text"
                                          name="degree"
                                          value={ix.degree}
                                          onChange={(e) =>
                                            handleInputChangeEducational(e, i)
                                          }
                                        />
                                      </th>
                                      <th>
                                        <Input
                                          type="text"
                                          value={ix.passingyear}
                                          name="passingyear"
                                          onChange={(e) =>
                                            handleInputChangeEducational(e, i)
                                          }
                                        />
                                      </th>
                                      <th>
                                        {" "}
                                        <span>
                                          {educationaldetaildata.length !==
                                            1 && (
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger text-center"
                                              onClick={() =>
                                                handleRemoveClickEducational(i)
                                              }
                                            >
                                              <i className="fa fa-trash" />
                                            </button>
                                          )}
                                          {educationaldetaildata.length - 1 ===
                                            i && (
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-success text-center mr-1"
                                              onClick={
                                                handleAddClickEducational
                                              }
                                            >
                                              <i className="fa fa-plus" />
                                            </button>
                                          )}
                                        </span>
                                      </th>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </Col>
                        </Fragment>

                        <Fragment>
                          {handleTypeMatched(7) && (
                            <Fragment>
                              <Col sm={12}>
                                {type === "8" ? (
                                  <h5>Company Detail</h5>
                                ) : (
                                  <h5>Work / Professional Experience</h5>
                                )}
                                <hr className="mt-0" />
                              </Col>

                              <Fragment></Fragment>
                            </Fragment>
                          )}
                          <Col sm={12}>
                            {type === "8" ? (
                              <h5>Company Detail</h5>
                            ) : (
                              <h5>Work / Professional Experience</h5>
                            )}
                            <hr className="mt-0" />
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="organisation"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Name of {type !== "8" && "Current"} Organisation
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="organisation"
                                className="col-md-7"
                                value={organisation}
                                invalid={selectError.organisation}
                                onChange={(e) =>
                                  setOrganisation(e.target.value)
                                }
                              />
                              <FormFeedback className="offset-5">
                                {selectError.organisation}
                              </FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col sm={12}>
                            <FormGroup>
                              <Label
                                for="profilesummary"
                                className="col-md-3 control-label col-form-label col-12 text-md-center"
                              >
                                {handleTypeMatched(8)
                                  ? "About Company"
                                  : "Profile Summary"}
                              </Label>
                              <Input
                                type="textarea"
                                id="profilesummary"
                                className="offset-1 col-md-11 h-100"
                                value={profilesummary}
                                rows={10}
                                onChange={(e) =>
                                  e.target.value.length <= 500 &&
                                  setProfilesummary(e.target.value)
                                }
                                invalid={!!errors.profilesummary}
                              />
                              <FormText
                                className="offset-1 pl-1"
                                color="danger"
                              >
                                Max {500 - profilesummary.length} character
                                allowed
                              </FormText>
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="speakIn"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Language Speak
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                options={language}
                                className="col-md-7 p-0"
                                isMulti
                                isSearchable
                                onChange={(value) => setSpeakIn(value)}
                                value={speakIn}
                              />
                              {selectError.speakIn != "" ? (
                                <span className="text-danger offset-5 small">
                                  {selectError.speakIn}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="row">
                              <Label
                                for="website"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                              >
                                Website Url
                              </Label>
                              <Input
                                id="website"
                                className="col-md-7"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                              />
                            </FormGroup>
                          </Col>

                          {handleTypeMatched(7) && (
                            <Fragment>
                              {handleTypeMatched(8) && (
                                <Col sm={12}>
                                  <FormGroup>
                                    <Label
                                      for="workexperience"
                                      className="col-md-3 control-label col-form-label col-12 text-md-center"
                                    >
                                      Work Experience
                                    </Label>
                                    <CKEditor
                                      activeClass="offset-1 col-md-11"
                                      className="col-md-7"
                                      content={workexperience}
                                      events={{
                                        change: (e) =>
                                          setWorkExperience(e.editor.getData()),
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              )}
                              <Col sm={12}>
                                <FormGroup>
                                  <Label
                                    for="profilesummary"
                                    className="col-md-3 control-label col-form-label col-12 text-md-center"
                                  >
                                    {handleTypeMatched(8)
                                      ? "About Company"
                                      : "Profile Summary"}
                                  </Label>
                                  <Input
                                    type="textarea"
                                    id="profilesummary"
                                    className="offset-1 col-md-11 h-100"
                                    value={profilesummary}
                                    rows={10}
                                    onChange={(e) =>
                                      e.target.value.length <= 500 &&
                                      setProfilesummary(e.target.value)
                                    }
                                    invalid={!!errors.profilesummary}
                                  />
                                  <FormText
                                    className="offset-1 pl-1"
                                    color="danger"
                                  >
                                    Max {500 - profilesummary.length} character
                                    allowed
                                  </FormText>
                                </FormGroup>
                              </Col>

                              {handleTypeMatched(8) && (
                                <Fragment>
                                  <Col sm={12}>
                                    <h5>Work Experience</h5>
                                    <hr className="mt-0" />
                                  </Col>
                                  <Col sm={12}>
                                    <Table responsive striped>
                                      <thead>
                                        <tr>
                                          <th>Previous Company / Firm Name</th>
                                          <th>From</th>
                                          <th>To</th>
                                          <th>Work Area</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {workexperincedata &&
                                          workexperincedata.map((ix, i) => (
                                            <tr>
                                              <th>
                                                <Input
                                                  type="text"
                                                  name="companyname"
                                                  value={ix.companyname}
                                                  onChange={(e) =>
                                                    handleInputChangeExperince(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </th>
                                              <th>
                                                <Input
                                                  type="date"
                                                  value={ix.from}
                                                  max={today}
                                                  name="from"
                                                  onChange={(e) =>
                                                    handleInputChangeExperince(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </th>
                                              <th>
                                                <Input
                                                  type="date"
                                                  value={ix.to}
                                                  name="to"
                                                  min={ix.from}
                                                  max={today}
                                                  onChange={(e) =>
                                                    handleInputChangeExperince(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </th>
                                              <th>
                                                <Input
                                                  type="text"
                                                  name="workarea"
                                                  value={ix.workarea}
                                                  onChange={(e) =>
                                                    handleInputChangeExperince(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </th>
                                              <th>
                                                {" "}
                                                <span>
                                                  {workexperincedata.length !==
                                                    1 && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-sm btn-danger text-center"
                                                      onClick={() =>
                                                        handleRemoveClickWorkExperince(
                                                          i
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-trash" />
                                                    </button>
                                                  )}
                                                  {workexperincedata.length -
                                                    1 ===
                                                    i && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-sm btn-success text-center mr-1"
                                                      onClick={
                                                        handleAddClickExperince
                                                      }
                                                    >
                                                      <i className="fa fa-plus" />
                                                    </button>
                                                  )}
                                                </span>
                                              </th>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </Fragment>

                        {handleTypeMatched(6) || handleTypeMatched(7) ? null : (
                          <Fragment>
                            <Col sm={12}>
                              <h5>Certificate / Achievement</h5>
                              <hr className="mt-0" />
                            </Col>
                            <Col sm={12}>
                              <Table responsive striped>
                                <thead>
                                  <tr>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Achievement Year</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {certificatedata &&
                                    certificatedata.map((ix, i) => (
                                      <tr>
                                        <th>
                                          <Input
                                            type="text"
                                            name="certificatename"
                                            value={ix.certificatename}
                                            onChange={(e) =>
                                              handleInputChangeCertificate(e, i)
                                            }
                                          />
                                        </th>
                                        <th className="d-inline-flex">
                                          <span>
                                            <Input
                                              type="file"
                                              data-default-file={
                                                typeof ix.certificateimage ===
                                                "string"
                                                  ? `/storage/${ix.certificateimage}`
                                                  : ""
                                              }
                                              id="certificateimage"
                                              className="dropify"
                                              name="certificateimage"
                                              accept="image/jpg, image/jpeg, image/png"
                                              onChange={(e) =>
                                                handleInputChangeCertificate(
                                                  e,
                                                  i
                                                )
                                              }
                                            />{" "}
                                            <FormText color="danger">
                                              accept only PNG, JPEG and JPG{" "}
                                            </FormText>
                                          </span>
                                          {!_.isEmpty(ix.certificateimage) && (
                                            <a
                                              href={`/storage/${ix.certificateimage}`}
                                              download
                                            >
                                              <img
                                                src={`/storage/${ix.certificateimage}`}
                                                height="30px"
                                                width="30px"
                                              />
                                            </a>
                                          )}
                                        </th>
                                        <th>
                                          <Input
                                            type="text"
                                            name="year"
                                            value={ix.year}
                                            onChange={(e) =>
                                              handleInputChangeCertificate(e, i)
                                            }
                                          />
                                        </th>
                                        <th>
                                          {" "}
                                          <span>
                                            {certificatedata.length !== 1 && (
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger text-center"
                                                onClick={() =>
                                                  handleRemoveClickCertificate(
                                                    i
                                                  )
                                                }
                                              >
                                                <i className="fa fa-trash" />
                                              </button>
                                            )}
                                            {certificatedata.length - 1 ===
                                              i && (
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-success text-center mr-1"
                                                onClick={
                                                  handleAddClickCertificate
                                                }
                                              >
                                                <i className="fa fa-plus" />
                                              </button>
                                            )}
                                          </span>
                                        </th>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Fragment>
                        )}

                        <Col sm={12}>
                          <h5>
                            {handleTypeMatched(7) ? "Address" : "Contact"}{" "}
                            Detail
                          </h5>
                          <hr className="mt-0" />
                        </Col>

                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="city"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              City
                            </Label>
                            <Input
                              id="city"
                              className="col-md-7"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="state"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              State
                            </Label>
                            <Input
                              id="state"
                              className="col-md-7"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="pincode"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Pincode
                            </Label>
                            <Input
                              id="pincode"
                              className="col-md-7"
                              value={pincode}
                              onChange={(e) =>
                                ((_.size(e.target.value) < 7 &&
                                  !isNaN(parseInt(e.target.value))) ||
                                  _.isEmpty(e.target.value)) &&
                                setPincode(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="address"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Address
                            </Label>
                            <Input
                              id="address"
                              value={address}
                              type="textarea"
                              className="col-md-7 h-100"
                              rows={5}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="region"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Region
                            </Label>
                            <Input
                              id="region"
                              className="col-md-7"
                              value={region}
                              type="select"
                              onChange={(e) => setRegion(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="Northern">Northern</option>
                              <option value="Southern">Southern</option>
                              <option value="Eastern">Eastern</option>
                              <option value="Northeastern">
                                Northeastern{" "}
                              </option>
                              <option value="Western">Western</option>
                              <option value="Central">Central</option>
                              <option value="Bay of Bengal">
                                Bay of Bengal
                              </option>
                              <option value="Arabian Sea">Arabian Sea</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        {handleTypeMatched(6) ? null : (
                          <Fragment>
                            {handleTypeMatched(7) && (
                              <Fragment>
                                <Col sm={6}>
                                  <FormGroup className="row">
                                    <Label
                                      for="profile"
                                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                                    >
                                      Upload Resume
                                    </Label>
                                    <Col md={7} className="p-0">
                                      <Input
                                        type="file"
                                        id="profile"
                                        className="dropify"
                                        data-default-file={
                                          typeof profile === "string"
                                            ? `/storage/${profile}`
                                            : ""
                                        }
                                        accept=".pdf"
                                        onChange={(e) => {
                                          setProfile(e.target.files[0]);
                                        }}
                                      />
                                      <FormText color="danger">
                                        accept only PDF{" "}
                                      </FormText>
                                      {!_.isEmpty(profile) && (
                                        <a
                                          href={`/storage/${profile}`}
                                          download
                                        >
                                          <FiDownload />
                                        </a>
                                      )}
                                    </Col>
                                  </FormGroup>
                                </Col>

                                <Col sm={6}>
                                  <FormGroup className="row">
                                    <Label
                                      for="designation"
                                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                                    >
                                      Certificate Name
                                    </Label>
                                    <Input
                                      id="certificatename"
                                      className="col-md-7"
                                      value={certificatename}
                                      onChange={(e) =>
                                        setCertificateName(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Fragment>
                            )}

                            {handleTypeMatched(7) && (
                              <Col sm={6}>
                                <FormGroup className="row">
                                  <Label
                                    for="certificate"
                                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                                  >
                                    Certificate File
                                  </Label>
                                  <Col md={7} className="p-0">
                                    <Input
                                      type="file"
                                      id="certificate"
                                      className="dropify"
                                      data-default-file={
                                        typeof certificate === "string"
                                          ? `/storage/${certificate}`
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setCertificate(e.target.files[0]);
                                      }}
                                    />
                                    {!_.isEmpty(certificate) && (
                                      <a
                                        href={`/storage/${certificate}`}
                                        download
                                      >
                                        <FiDownload />
                                      </a>
                                    )}
                                  </Col>
                                </FormGroup>
                              </Col>
                            )}
                            <Col sm={12} className="mt-2">
                              <h5>MOU</h5>
                              <hr className="mt-0" />
                            </Col>

                            <Col sm={6}>
                              <FormGroup className="row">
                                <Label
                                  for="profile"
                                  className="col-md-5 control-label col-form-label col-12 text-md-right"
                                >
                                  MoU
                                </Label>
                                <Col md={7} className="p-0">
                                  <Input
                                    type="file"
                                    id="mou"
                                    className="dropify"
                                    data-default-file={
                                      typeof mou === "string"
                                        ? `/storage/${mou}`
                                        : ""
                                    }
                                    onChange={handleMouChange}
                                  />
                                  {!_.isEmpty(mou) && (
                                    <a href={`/storage/${mou}`} download>
                                      <FiDownload />
                                    </a>
                                  )}
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col sm={6} />
                            {preferedSector && (
                              <>
                                {" "}
                                <Col sm={12} className="mt-2">
                                  <h5>Prefered Sector </h5>
                                  <hr className="mt-0" />
                                </Col>
                                <Col sm={12}>
                                  <div className="d-flex flex-cols">
                                    {preferedSector &&
                                      preferedSector.split("|").map((re) => (
                                        <Card className="mr-1 bg-light">
                                          <CardHeader>{re}</CardHeader>
                                        </Card>
                                      ))}
                                  </div>
                                </Col>
                              </>
                            )}
                            {preferedIndustry && (
                              <>
                                {" "}
                                <Col sm={12} className="mt-2">
                                  <h5>Prefered Industry </h5>
                                  <hr className="mt-0" />
                                </Col>
                                <Col sm={12}>
                                  <div className="d-flex flex-cols">
                                    {preferedIndustry &&
                                      preferedIndustry.split("|").map((re) => (
                                        <Card className="mr-1 bg-light">
                                          <CardHeader>{re}</CardHeader>
                                        </Card>
                                      ))}
                                  </div>
                                </Col>
                              </>
                            )}
                          </Fragment>
                        )}

                        <Col sm={6}>
                          <FormGroup className="row">
                            <Label
                              for="memprivilege"
                              className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                              Member Privilege
                            </Label>
                            <Input
                              type="select"
                              className="col-md-7"
                              id="memprivilege"
                              value={memprivilege}
                              onChange={(e) => setMemPrivilege(e.target.value)}
                            >
                              <option value="">Select </option>
                              <option value="platinum">Platinum</option>
                              <option value="gold">Gold</option>
                              <option value="associate">Associate</option>
                              <option value="general">General</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        {!_.isEmpty(log) && (
                          <>
                            <Col sm={12} className="mt-2">
                              <h5>Logs of Current Status </h5>
                              <hr className="mt-0" />
                            </Col>
                            <Col sm={12} className="mb-2">
                              {!_.isEmpty(log) &&
                                log.map((re, i) => CurrentStatus(re, i))}
                            </Col>
                          </>
                        )}

                        <Col sm={12}>
                          <div className="text-center mb-3">
                            <Loader loading={loading}>
                              <Button
                                type="submit"
                                color="secondary"
                                size="sm"
                                className="mr-1"
                                onClick={() => setRedirect(true)}
                              >
                                Save
                              </Button>
                              <Button
                                color="secondary"
                                size="sm"
                                className="mr-1"
                              >
                                Save & New
                              </Button>
                              <Button
                                type="button"
                                color="secondary"
                                size="sm"
                                onClick={() => window.history.back()}
                              >
                                Close
                              </Button>
                            </Loader>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </form>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={6}>
                        <FormGroup className="offset-3">
                          <Label>
                            <Input
                              type="radio"
                              name="type"
                              value={0}
                              checked={consultancyType === 0}
                              onChange={() => setConsultancyType(0)}
                            />{" "}
                            Industrial Consultancy
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="offset-3">
                          <Label>
                            <Input
                              type="radio"
                              name="type"
                              value={1}
                              checked={consultancyType === 1}
                              onChange={() => setConsultancyType(1)}
                            />{" "}
                            Professional Consultancy
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {consultancyType ? (
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="industries">Professional Service</Label>
                            <Select
                              options={professionalservices}
                              onChange={handleIndustriesChange}
                              value={industry}
                              invalid={!!errors.professionalservices}
                              className={`${
                                !!errors.professionalservices &&
                                "border border-danger"
                              } `}
                            />
                            <FormFeedback className="d-block">
                              {!!errors.professionalservices
                                ? errors.professionalservices[0]
                                : ""}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="industries">Sector</Label>
                            <Select
                              options={industries}
                              onChange={handleIndustriesChange}
                              value={industry}
                              invalid={!!errors.industry}
                              className={`${
                                !!errors.industry && "border border-danger"
                              } `}
                            />
                            <FormFeedback className="d-block">
                              {!!errors.industry ? errors.industry[0] : ""}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      )}

                      <Col sm={2}>
                        {!_.isEmpty(industry) && (
                          <Button
                            className="mt-4"
                            type="button"
                            onClick={() =>
                              setSubIndustryModel(!subIndustryModel)
                            }
                          >
                            Choose Industry
                          </Button>
                        )}{" "}
                      </Col>
                      <Col sm={2}>
                        <FormGroup>
                          <Label for="telephonicFee">Telephonic Fee</Label>
                          <Input
                            id="telephonicFee"
                            value={telephonicFee}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              (e.target.value === "" ||
                                re.test(e.target.value)) &&
                                setTelephonicFee(e.target.value);
                            }}
                            invalid={!!errors.telephone_fee}
                          />
                          <FormFeedback>
                            {!!errors.telephone_fee
                              ? errors.telephone_fee[0]
                              : ""}
                          </FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col sm={2}>
                        {" "}
                        <FormGroup>
                          <Label for="videoCallFee">Video Calling Fee</Label>
                          <Input
                            id="videoCallFee"
                            value={videoCallFee}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              (e.target.value === "" ||
                                re.test(e.target.value)) &&
                                setVideoFee(e.target.value);
                            }}
                            invalid={!!errors.video_call_fee}
                          />
                          <FormFeedback>
                            {!!errors.video_call_fee
                              ? errors.video_call_fee[0]
                              : ""}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={2}>
                        <Button
                          className="mt-4"
                          onClick={(e) => {
                            SavefeeIndustry(e);
                          }}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="font-weight-bold">Sector</th>

                                <th className="font-weight-bold">
                                  Telephonic Fee
                                </th>

                                <th className="font-weight-bold">
                                  Video Calling Fee
                                </th>

                                <th className="font-weight-bold">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <Fragment>
                                {loading ? (
                                  <Fragment>
                                    <tr>
                                      <td colSpan={7} />
                                    </tr>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {consultantFee.length ? (
                                      <Fragment>
                                        {_.map(consultantFee, (is, i) => (
                                          <tr key={is.id}>
                                            <td>
                                              {is.industrydata &&
                                                is.industrydata.name}
                                            </td>

                                            <td>Rs. {is.telephone_fee}</td>

                                            <td>Rs. {is.video_call_fee}</td>

                                            {/* <td>
                                                                                        <EditConsultantSubIndustry consultantprice={is.id} />{" "}
                                                                                        <ViewConsultantSubIndustry consultantprice={is.id} />{" "}
                                                                                        <a href="#"
                                                                                            onClick={(e) => handleOneDelete(e, is.id)}><FiTrash /></a>

                                                                                    </td> */}
                                          </tr>
                                        ))}
                                      </Fragment>
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan={7}>No data found</td>
                                      </tr>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={"3"}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={12}>
                        {" "}
                        <Button
                          className="btn btn-secondary btn-sm m-1"
                          title="Refresh"
                          onClick={() => setRefresh((refresh) => refresh + 1)}
                        >
                          <i className="fa fa-refresh" />
                        </Button>
                        <hr className="mt-0 pt-0"></hr>
                      </Col>
                      {inputList
                        ? inputList.map((ix, i) => (
                            <Fragment key={i}>
                              <Col sm={12} className="mt-2 pt-2">
                                <Row>
                                  <Col sm={2} />
                                  <Col>
                                    {" "}
                                    <Label>Days</Label>
                                  </Col>

                                  <Col className="ml-0 pl-0">
                                    {" "}
                                    <Label>
                                      <Input
                                        type="checkbox"
                                        checked={days
                                          .map((c) => c.id)
                                          .every((c) =>
                                            ix.selectedDays.includes(c)
                                          )}
                                        onChange={() =>
                                          handleAllChecked(
                                            days.map((c) => c.id),
                                            i
                                          )
                                        }
                                      />{" "}
                                      All
                                    </Label>
                                  </Col>
                                  {days.map((rs) => (
                                    <Col>
                                      {" "}
                                      <Label>
                                        <Input
                                          type="checkbox"
                                          name={"selectedDays"}
                                          id="selectedDays"
                                          key={rs.id}
                                          checked={ix.selectedDays.some(
                                            (s) => s === rs.id
                                          )}
                                          onChange={() =>
                                            handleChecked(rs.id, i)
                                          }
                                        />{" "}
                                        {rs.value}
                                      </Label>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                              <Col sm={6}>
                                <FormGroup className="row">
                                  <Label
                                    for="start_time"
                                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                                  >
                                    Start Time
                                  </Label>
                                  <Input
                                    type="time"
                                    name="start_time"
                                    id="start_time"
                                    className="col-md-7"
                                    value={ix.start_time}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <FormGroup className="row">
                                  <Label
                                    for="end_time"
                                    className="col-md-5 control-label col-form-label col-12 text-md-right"
                                  >
                                    End Time
                                  </Label>
                                  <Input
                                    type="time"
                                    id="end_time"
                                    name="end_time"
                                    className="col-md-5"
                                    value={ix.end_time}
                                    onChange={(e) =>
                                      handleInputFileChange(e, i)
                                    }
                                  />
                                  {inputList.length !== 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-danger text-center"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  )}
                                  {inputList.length - 1 === i && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-success text-center mr-1"
                                      onClick={handleAddClick}
                                    >
                                      <i className="fa fa-plus" />
                                    </button>
                                  )}
                                </FormGroup>
                              </Col>
                              <hr></hr>
                            </Fragment>
                          ))
                        : null}
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="float-right mr-2"
                          onClick={(e) => saveTimeslot(e)}
                        >
                          Save Time Slot
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>

            <Modal isOpen={subIndustryModel} size="lg">
              <ModalHeader
                toggle={() => setSubIndustryModel(!subIndustryModel)}
                charCode="&#10004;"
              >
                Choose Industries
              </ModalHeader>
              <ModalBody className="industry-overflow">
                <Row>
                  {subIndustry.map((res) => (
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md={6}>{res.label}</Col>

                            <Col
                              md={2}
                              className="industrial-consultancy  d-flex align-items-center justify-content-center"
                            >
                              <Label>
                                <Input
                                  type="checkbox"
                                  checked={selectedSubindustry.some(
                                    (s) => s === res.value
                                  )}
                                  onChange={() =>
                                    handleSubIndustry(res.value, ids)
                                  }
                                />
                                <span>Select</span>
                              </Label>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </ModalBody>
            </Modal>
          </Container>
        </Fragment>
      </main>
    </MainWrapper>
  );
};

export default EditDetailed;
