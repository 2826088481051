import React, { useState, useEffect,memo } from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Input, Label } from "reactstrap";
import moment from "moment";
import { DashboardCountById } from "../../store/actions/DashboardActions";
import { Link } from "react-router-dom";
const Accordian = ({ consultant, type }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [associateteam, setAssociateTeam] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const { token } = auth;
  const [associatecount, setAssociateCount] = useState("");
  const [franchiseCount, setFranchiseCount] = useState("");
  const [date, setDate] = useState("");
  const [fdate, setFdate] = useState("");
  const [associateData, setAssociateData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const { countbyid } = useSelector((state) => state.dashboardReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if(open)
    {
      Axios.get(
        `/api/crm/get-franchise-count?id=${consultant?.id}&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => setAssociateTeam(res.data))
        .catch((err) => console.log(err));
    }
   
  }, [consultant, startDate, endDate,open]);
  useEffect(() => {
    if(open)
    {
      Axios.get(`/api/crm/get-associate-dashboard?id=${consultant?.id}&start_date=${startDate}&end_date=${endDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => setAssociateCount(res.data))
        .catch((err) => console.log(err));
    }
   
  }, [consultant?.id]);

  useEffect(() => {
    if (type == 3 && open)
      Axios.get(
        `/api/crm/get-franchise-investor-count?id=${consultant?.id}&start=${startDate}&end=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => setFranchiseCount(res.data))
        .catch((err) => console.log(err));
  }, [consultant?.id, startDate, endDate,open]);

  useEffect(() => {
    if (type == 4 && open)
      Axios.get(
        `/api/crm/get-user-dashboard-detail?id=${consultant?.id}&start_date=${date}&end_date=${fdate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => setAssociateData(res.data))
        .catch((err) => console.log(err));
  }, [consultant?.id, fdate,open]);


  return (
    <div className="mb-3 ">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header mb-0" id="headingOne">
            <div
              className="d-flex justify-content-between card-header"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <h6 className="text-white mb-0">{consultant?.name}</h6>
              {open ? (
                <FaChevronUp size={16} color="white" />
              ) : (
                <FaChevronDown size={16} color="white" />
              )}
            </div>
          </h2>
          {open && (
            <div>
              {type == 1 && (
                <>
                  <div
                    className="form-group mt-2
"
                  >
                    <label htmlFor="" className="form-label  ">
                      Start Date
                    </label>
                    <Input
                      type="date"
                      style={{ borderRadius: "6px" }}
                      value={startDate}
                      onChange={({ target: { value } }) => setStartDate(value)}
                      className="cursor-pointer"
                    />
                  </div>

                  <div
                    className="form-group mt-2
"
                  >
                    <label htmlFor="" className="form-label ">
                      End Date
                    </label>
                    <Input
                      type="date"
                      style={{ borderRadius: "6px" }}
                      value={endDate}
                      onChange={({ target: { value } }) => setEndDate(value)}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="bg-white px-2">
                    <div className="d-flex justify-content-between pt-4">
                      {" "}
                      <div>
                        <h6>Total Note</h6>
                      </div>{" "}
                      <div>{associateteam?.getNoteCount}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-4">
                      {" "}
                      <div>
                        <h6>Today Lead</h6>
                      </div>{" "}
                      <div>{associateteam?.todayLead}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Connected</h6>
                      </div>{" "}
                      <div>{associateteam?.totalConnected}</div>
                    </div>
                    

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Not Connected</h6>
                      </div>{" "}
                      <div>{associateteam?.totalNotConnected}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total CallBack</h6>
                      </div>{" "}
                      <div>{associateteam?.totalCallBack}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Follow up</h6>
                      </div>{" "}
                      <div>{associateteam?.totalFollowUp}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total MailShared</h6>
                      </div>{" "}
                      <div>{associateteam?.totalMailShared}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Meeting Align</h6>
                      </div>{" "}
                      <div>{associateteam?.totalMeetingAlign}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Meeting Done</h6>
                      </div>{" "}
                      <div>{associateteam?.totalMeetingDone}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Meeting Done Not Interested</h6>
                      </div>{" "}
                      <div>{associateteam?.totalMeetingDoneNOI}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Not Interested</h6>
                      </div>{" "}
                      <div>{associateteam?.totalNotInterested}</div>
                    </div>
                  </div>
                </>
              )}
              {type == 2 && (
                <div className="bg-white px-2">
                  <div className="d-flex justify-content-between pt-4">
                    {" "}
                    <div>
                      <h6>Today Sale</h6>
                    </div>{" "}
                    <div>{associatecount?.todayAmount}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Monthly Sale</h6>
                    </div>{" "}
                    <div>{associatecount?.monthlyAmount}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Sale</h6>
                    </div>{" "}
                    <div>{associatecount?.totalAmount}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Lead</h6>
                    </div>{" "}
                    <div>{associatecount?.todayleadCount}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Monthly Lead</h6>
                    </div>{" "}
                    <div>{associatecount?.monthlyleadCount}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Lead</h6>
                    </div>{" "}
                    <div>{associatecount?.totalleadCount}</div>
                  </div>
                </div>
              )}
              {type == 3 && (
                <div className="bg-white px-2">
                  <div
                    className="form-group mt-2
"
                  >
                    <label htmlFor="" className="form-label  ">
                      Start Date
                    </label>
                    <Input
                      type="date"
                      style={{ borderRadius: "6px" }}
                      value={startDate}
                      onChange={({ target: { value } }) => setStartDate(value)}
                      className="cursor-pointer"
                    />
                  </div>

                  <div
                    className="form-group mt-2
"
                  >
                    <label htmlFor="" className="form-label ">
                      End Date
                    </label>
                    <Input
                      type="date"
                      style={{ borderRadius: "6px" }}
                      value={endDate}
                      onChange={({ target: { value } }) => setEndDate(value)}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="d-flex justify-content-between pt-4">
                    {" "}
                    <div>
                      <h6>Today Franchise Investor</h6>
                    </div>{" "}
                    <div>{franchiseCount?.todayfranchiseInvestor}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Franchise Investor</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseInvestor}</div>
                  </div>
                  <Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&desposition_type=${"Connected"}`}
                  >
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Connected</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseConnected}</div>
                  </div>
</Link>
<Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&desposition_type=${"Not_Connected"}`}
                  >
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Not Connected</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseNotConnected}</div>
                  </div>
                  </Link>
                  <Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&desposition_type=${"Connected"}&disposition=${"Call_Back"}`}
                  >
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total CallBack</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseCallBack}</div>
                  </div>
                  </Link>
                  <Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&desposition_type=${"Connected"}&disposition=${"Follow_Up"}`}
                  >
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total FollowUp </h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseFollowUp}</div>
                  </div>
                  </Link>
                  <Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&desposition_type=${"Connected"}&disposition=${"Mail_Shared"}`}
                  >
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total MailShared</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalfranchiseMailShared}</div>
                  </div>
</Link>
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Onboarding</h6>
                    </div>{" "}
                    <div>{franchiseCount?.totalOnBoarding}</div>
                  </div>

                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Today Onboarding</h6>
                    </div>{" "}
                    <div>{franchiseCount?.todayOnBoarding}</div>
                  </div>

                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Hot Lead</h6>
                    </div>{" "}
                    <div>{franchiseCount?.hotLead}</div>
                  </div>
                  {/* <Link
                    to={`/franchise-list-investor?assign=${
                      consultant.id
                    }&lead_status=${"Cold"}`}
                  > */}
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Cold Lead</h6>
                    </div>{" "}
                    <div>{franchiseCount?.coldLead}</div>
                  </div>
{/* </Link> */}
                  <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Warm Lead</h6>
                    </div>{" "}
                    <div>{franchiseCount?.warmLead}</div>
                  </div>
                </div>
              )}
              {type == 4 && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <Label className="pt-2">Start Date</Label>
                      <Input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="pt-2">End Date</Label>
                      <Input
                        type="date"
                        value={fdate}
                        onChange={(e) => setFdate(e.target.value)}
                      />
                    </div>
                  </div>
                  <Link
                    to={`/leads?assign=${consultant.id}&StartDate=${currentDate}&EndDate=${currentDate}`}
                  >
                    <div className="d-flex justify-content-between pt-4">
                      {" "}
                      <div>
                        <h6>Today Lead (Leads Assigned)</h6>
                      </div>{" "}
                      <div>{associateData?.todayLead}</div>
                    </div>
                  </Link>
                  {/* <Link
                    to={`/leads?assign=${consultant.id}
                 
                  `}
                  >
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Call</h6>
                      </div>{" "}
                      <div>{associateData?.todayCall}</div>
                    </div>
                  </Link> */}
                  

                  <Link to={`/leads?assign=${consultant.id}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Lead (Leads Assigned)</h6>
                      </div>{" "}
                      <div>{associateData?.totalLead}</div>
                    </div>
                  </Link>

                  {/* <div className="d-flex justify-content-between pt-2">
                    {" "}
                    <div>
                      <h6>Total Leads Assigned</h6>
                    </div>{" "}
                    <div>{associateData?.totalConnected}</div>
                  </div> */}
                  <Link
                    to={`/leads?assign=${
                      consultant.id
                    }&desposition=${"Interested"}`}
                  >
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Interested</h6>
                      </div>{" "}
                      <div>{associateData?.totalInterested}</div>
                    </div>
                  </Link>
                  <Link to={`/leads?assign=${consultant.id}&lead=${"Hot"}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Hot Leads (Interested) </h6>
                      </div>{" "}
                      <div>{associateData?.totalHot}</div>
                    </div>
                  </Link>
                  <Link to={`/leads?assign=${consultant.id}&lead=${"Warm"}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Warm Leads (Interested)</h6>
                      </div>{" "}
                      <div>{associateData?.totalWarm}</div>
                    </div>
                  </Link>
                  <Link to={`/leads?assign=${consultant.id}&lead=${"Cold"}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Cold Leads (Interested) </h6>
                      </div>{" "}
                      <div>{associateData?.totalCold}</div>
                    </div>
                  </Link>
                  <Link
                    to={`/leads?assign=${consultant.id}&lead=${"Virtual_Meet"}`}
                  >
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Virtual Meeting </h6>
                      </div>{" "}
                      <div>{associateData?.totalVirtualMeet}</div>
                    </div>
                  </Link>


                  <Link to={`/call-lead-list?assign=${consultant.id}&TodayLead=${currentDate}`}>
                  <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Today Activity</h6>
                      </div>{" "}
                      <div>{associateData?.todayCall}</div>
                    </div>
                    </Link>



                    <Link
                    to={`/call-lead-list?assign=${consultant.id}&start=${date}&end=${fdate}
                 
                  `}
                  >
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Activity</h6>
                      </div>{" "}
                      <div>{associateData?.totalCall}</div>
                    </div>
                    </Link>
                    <Link to={`/leads?assign=${consultant.id}&lead=${"Converted"}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Converted</h6>
                      </div>{" "}
                      <div>{associateData?.totalConverted
}</div>
                    </div>
                    </Link>
                    <Link to={`/leads?assign=${consultant.id}&lead=${"Perspective"}`}>
                    <div className="d-flex justify-content-between pt-2">
                      {" "}
                      <div>
                        <h6>Total Perspective
</h6>
                      </div>{" "}
                      <div>{associateData?.totalPerspective
}</div>
                    </div>
                    </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Accordian);
