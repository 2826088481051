import React, { Fragment, useState } from 'react';
import { Button, Input, Collapse } from 'reactstrap';

const Mail = () => {
    const [display, setDisplay] = useState(false);
    const [cc, setCc] = useState(false);
    const [bcc, setBcc] = useState(false);
    const [mini, setMini] = useState(true);
    const handleDisplay = () => {
        setDisplay(display => !display)
    }
    return (
        <Fragment>
            <Button color="secondary" size="sm" onClick={handleDisplay} className="mr-1"><i className="fa fa-envelope pr-1" /> Email</Button>
            <Collapse isOpen={display} className="fixedEmail" >
                <div className="modal-dialog">
                    <div className="modal-content box box-primary box-solid fixedEmail">
                        <div className="box-header with-border">
                            <h3 className="box-title">Send Email</h3>
                            <div className="box-tools pull-right">
                                <button className="btn btn-box-tool btn-secondary btn-sm minimize-modal" onClick={() => setMini(!mini)}><i
                                    className="fa fa-minus"></i></button>
                                <button className="btn btn-box-tool btn-secondary btn-sm close-modal" onClick={handleDisplay}><i
                                    className="fa fa-remove"></i></button>
                            </div>
                        </div>
                        <Collapse isOpen={mini}>
                            <div className="box-body">
                                <div className="form-group position-relative">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">To:</span>
                                        </div>
                                        <Input placeholder="To..." />
                                    </div>
                                    <div className="other-btns">
                                        <span className="openCCBox text-primary" onClick={() => setCc(cc => !cc)}>Cc</span>
                                        <span className="openBCCBox text-primary" onClick={() => setBcc(bcc => !bcc)}>BCC</span>
                                    </div>
                                </div>
                                <Collapse isOpen={cc} className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Cc:</span>
                                        </div>
                                        <Input placeholder="Cc..." />
                                    </div>
                                </Collapse>
                                <Collapse isOpen={bcc} className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">BCC:</span>
                                        </div>
                                        <Input placeholder="Bcc..." />
                                    </div>
                                </Collapse>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subject:</span>
                                        </div>
                                        <Input placeholder="Subject..." />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control message-box" placeholder="Message..."></textarea>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button className="btn btn-secondary btn-sm" onClick={handleDisplay}>Discard</button>
                                <button type="submit" className="btn btn-secondary btn-sm">Send</button>
                                <Input type="file" className="d-none" id="attachment" />
                                <label className="btn btn-secondary btn-sm" htmlFor="attachment"><i className="fa fa-paperclip"></i> Attachment (Max. 2MB)</label>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </Collapse>
        </Fragment>
    )
}

export default Mail;
