import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import moment from "moment";
import Axios from "axios";
import { BiDetail } from "react-icons/bi";
import { MdCallEnd } from "react-icons/md";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import CollapseCard from "../../components/dashboard-accordian/CollapseCard";
import useScrollTrigger from "./useScrollTrigger";
const TataPlay = () => {
  const { auth } = useSelector((state) => state.auth);
  const [tataPlayData, setTataPlayData] = useState("");
  const [isInview, setIsinview] = useState(false);
  const [tataPlayFilter, setTataPlayFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [reportings, setReportings] = useState({});
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const targetRef = useScrollTrigger((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Element is now in view
        setIsinview(true);
      }
    });
  });
  useEffect(() => {
    if (isInview) fetchData();
  }, [isInview, tataPlayFilter, auth?.token]);
  const fetchData = () => {
    Axios.get(
      `/api/crm/get-franchise-investor-tata-play-count?start=${tataPlayFilter.startDate}&end=${tataPlayFilter.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
      .then((res) => setTataPlayData(res.data))
      .catch((err) => console.log(err));
  };
  return (
    <div ref={targetRef}>
      <h3>Tata Play Lead Stats</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              Start Date
            </label>
            <Input
              type="date"
              value={tataPlayFilter.startDate}
              onChange={({ target: { value } }) =>
                setTataPlayFilter((prev) => ({
                  ...prev,
                  startDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form-label  mb-2">
              End Date
            </label>
            <Input
              type="date"
              value={tataPlayFilter.endDate}
              onChange={({ target: { value } }) =>
                setTataPlayFilter((prev) => ({
                  ...prev,
                  endDate: value,
                }))
              }
              style={{ borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darked">
            <div className="card-body py-4">
              <a href={`/tata-play-list?leaddate=${leadDate}`}>
                <div className="row align-items-center ">
                  <div className="col-4">
                    <div className="square-box blue">
                      <FaUser color="white" size={18} />
                    </div>
                  </div>

                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.todayInvestor}
                    </h3>
                    <div className="mb-0 font-commom-2">Today Lead</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-green">
            <div className="card-body py-4">
              <a href="/tata-play-list">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="square-box skyblue">
                      <FaUserCheck color="white" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalInvestor}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Lead</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-darkblue">
            <div className="card-body py-4">
              <a href={`/tata-play-list?dispositiontype=Connected`}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="square-box orange">
                      <FaPhoneAlt color="white" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalConnected}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Connected</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card flex-fill common-card round-border box-orange">
            <div className="card-body py-4">
              <a href={`/tata-play-list?dispositiontype=Not_Connected`}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="square-box pink">
                      <MdCallEnd color="white" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalNotConnected}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Not Connected
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card flex-fill common-card round-border">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Not_Interested`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box green">
                      <FaSadCry color="#33d69f" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalNotInterested}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Not Interested
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Follow_Up`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkred">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalFollowUp}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Follow up </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Call_Back`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <HiPhoneIncoming color="#6C00FF" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalCallBack}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Call Back</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?mailshared=Yes`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalMailSharedYes}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Is Mail Shared
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Meeting_Align`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalMeetingAlign}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Total Meeting Align
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Meeting_Done`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalMeetingDone}
                    </h3>
                    <div className="mb-0 font-commom-2">Total Meeting Done</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card flex-fill round-border common-card">
            <div className="card-body py-4">
              <a href={`/tata-play-list?disposition=Meeting_Done_NOI`}>
                <div className="row">
                  <div className="col-4">
                    <div className="square-box darkblue">
                      <BiDetail color="#FF0032" size={18} />
                    </div>
                  </div>
                  <div className="col-8">
                    <h3 className="mb-0 font-commom">
                      {tataPlayData?.totalMeetingDoneNOI}
                    </h3>
                    <div className="mb-0 font-commom-2">
                      Meeting Done Not Interested
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-8"></div>

        {reportings?.tataplayLeadList?.map((item) => {
          return (
            <div className="col-md-3" key={item.id}>
              <CollapseCard
                name={item.name}
                id={item.id}
                moduleType="tataplay"
                showFilters={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TataPlay;
