import React, { Fragment, useState, useEffect, useMemo, memo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import { sendSms, addSms } from "../../store/actions/LoginCallActions";
import { FaSpinner } from "react-icons/fa";
import { Activity, addActivity } from "../../store/actions/ActivityActions";
const SendSms = memo(({ leadDetail, setWsStatus }) => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector((state) => state.auth);

  const { send, addSmsStatus } = useSelector((state) => state.callReducer);

  const { token } = auth;

  const handleSubmit = (e) => {
    e.preventDefault();
    setRefresh(true);
    const data = new FormData();
    data.append("mobile", mobile);
    data.append("message", message);
    data.append("engaged", 1);
    data.append("city", city);
    
    dispatch(sendSms(data, token));
    const da = {
      agent_id: auth.id,
      agent_mobile: mobile,
      message: message,
      city: city,
      id: leadDetail?.id,
    };
    dispatch(addSms(da, token));
  };
  useEffect(() => {
    const dataa = {
      lead_id: leadDetail?.id,
      activity_type: "Whatsapp",
      description: message,
    };
    if (addSmsStatus) {
      dispatch(addActivity(dataa, token));
    }
  }, [addSmsStatus]);

 

  useEffect(() => {
    if (send && refresh) {
      NotificationManager.success("Message Send Successfully", "Success");
      setModal(false);
      setRefresh(false);
      setMessage("");
      dispatch({ type: "RESET_SMS_STATUS", payload: false });
    }
  }, [send, refresh]);

  useEffect(() => {
    if (leadDetail?.mobile) {
      setMobile(
        leadDetail?.mobile?.startsWith("+91" || "91+" || "91")
          ? leadDetail?.mobile
          : `91${leadDetail?.mobile}`
      );
    }
  }, [leadDetail]);

useEffect(()=>
{
if(city=="service-feedback")
{
    setMessage("https://www.iid.org.in/feedback/service-feedback");
}
if(city=="bankable-service"){
    setMessage("https://www.iid.org.in/feedback/bankable-project-report-feedback")
}

},[city])
  return (
    <Fragment>
      <a
        color="secondary"
        href="#"
        size="sm"
        onClick={() => setModal(true)}
        className="flex-grow-1"
      >
        Send Feedback
      </a>
      <Modal isOpen={modal} toggle={() => setModal(false)} size="sm">
        <ModalHeader tag="h5" toggle={() => setModal(false)}>
          Send Feedback
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>Mobile</Label>
                  <Input
                    name="mobile"
                    value={mobile}
                    onChange={({ target: { value } }) => setMobile(value)}
                  />
                  <small className="text-danger">
                    Enter the number with country code. Example: 918400999177
                  </small>
                </FormGroup>
              </Col>
              <Col sm="12">
                {/* <select
                  class="form-select "
                  aria-label="Default select example"
                  onChange={(e) => setcity(e.target.value)}
                  value={city}
                >
                  <option selected>Open this select menu</option>
                  <option value="1">Delhi</option>
                  <option value="2">Lucknow</option>
                </select> */}
                <FormGroup>
                  <Label for="paymentType">Select Feedback</Label>
                  <Input
                    type="select"
                    id="paymentType"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="custom-select"
                  >
                    <option value="1">Select Feedback</option>
                    
                    <option value="service-feedback">Service Feedback</option>
                    <option value="bankable-service">Bankable Service</option>
                    
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup>
                  <Label>Message</Label>
                  <Input
                    type="textarea"
                    className="h-auto"
                    rows={4}
                    value={message}
                    onChange={({ target: { value } }) => setMessage(value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {refresh ? <FaSpinner /> : "Send"}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default SendSms;
