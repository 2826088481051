import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../MainWrapper";
import { Input, FormFeedback } from "reactstrap";
import { Redirect } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import { addCampaign } from "../../store/actions/CampaignActions";
import { useSelector, useDispatch } from "react-redux";

const Create = ({
  history: { push },

  addPriorityApi,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { auth, isAuthenticated } = useSelector((state) => state.auth);
  const { error, addCampaignStatus, loading } = useSelector(
    (state) => state.campaignReducer
  );
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const arr = {
      name,
      description,
    };

    dispatch(addCampaign(arr, auth?.token));
  };
  useEffect(() => {
    if (addCampaignStatus) {
      NotificationManager.success("Created Successfully", "Success");
      setName("");
      setDescription("");
      push("/campaign");
    }
  }, [addCampaignStatus, push]);
  const handleClose = (e) => {
    e.preventDefault();
    push("/campaign");
  };

  if (!isAuthenticated) return <Redirect to="/login" />;

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between justify-content-center">
                    <h4>Create Campaign</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 offset-md-2 mb-15">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="row form-group">
                                <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                                  Title
                                </label>
                                <div className="col-md-8">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    invalid={!!error.title}
                                  />
                                  <FormFeedback className="text-left">
                                    {error.title}
                                  </FormFeedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="row form-group">
                                <label className="col-md-4 control-label col-form-label col-12 text-md-right">
                                  Description
                                </label>
                                <div className="col-md-8">
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    value={description}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="row form-group">
                                <div className="col-md-4 control-label col-form-label col-12 text-md-right"></div>
                                <div className="col-md-8 text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-primary mr-3"
                                  >
                                    {loading ? (
                                      <FaSpinner className="fa-spin" />
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default Create;
