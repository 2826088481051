import Axios from "axios";

export const addInstitute = (d, token) => {
  return {
    type: "ADD_INSTITUTE",
    payload: Axios.post("/api/crm/get-crm-university-create", d, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};

export const getInstitute = (token, paginate, search, sort, page) => {
  return {
    type: "GET_INSTITUTE",
    payload: Axios.get(
      `/api/crm/get-crm-university-team?paginate=${paginate}&search=${search}&page=${page}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch((err)=>err),
  };
};

export const editInstitute = (d, token) => {
  return {
    type: "UPDATE_INSTITUTE",
    payload: Axios.post(`/api/crm/get-crm-university-team-update`, d, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err)=>err),
  };
};
