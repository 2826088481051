import React, { Fragment, useState, useEffect } from "react";
import MainWrapper from "../../components/MainWrapper";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { FiEdit, FiTrash } from "react-icons/fi";
import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { useSelector, useDispatch } from "react-redux";
import { getMeeting } from "../../store/actions/MeetingActions";
import { getInstitute } from "../../store/actions/InstituteTeamActions";
import { getallPermissions } from "../../store/actions/AuthActions";
const InstituteTeam = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [meeting, setMeeting] = useState([]);

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        listData.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };
  const { auth, permissions: { permission } } = useSelector((state) => state.auth);

  const permissions = React.useMemo(() => permission ?? [], [permission]);

  const { getmeeting, loading } = useSelector(
    (state) => state.instituteReducer
  );

  // useEffect(() => {
  //   dispatch({
  //     ,
  //     payload: auth.token,
  //     sort: sort,
  //     page: page,
  //     refresh: refresh,
  //     search: search,
  //     paginate: paginate,
  //   });
  // }, [page, search, sort, paginate, search]);

  useEffect(() => {
    dispatch(getInstitute(auth.token, paginate, search, sort, page));
  }, [auth.token, paginate, search, sort, page]);


  useEffect(() => {
    dispatch(getallPermissions(auth?.token))
  }, [auth])

  useEffect(() => {
    if (getmeeting) {
      setMeeting(getmeeting?.crmUniversityTeam?.data);
      setFrom(getmeeting?.crmUniversityTeam?.from);
      setTo(getmeeting?.crmUniversityTeam?.to);
      setTotal(getmeeting?.crmUniversityTeam?.total);
      setPerPage(getmeeting?.crmUniversityTeam?.per_page);
    }
  }, [getmeeting]);

  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
      };
      dispatch({
        type: "@DELETE_MEETING_REQUEST",
        payload: delArr,
        token: auth.token,
      });
      NotificationManager.success("Deleted Successfully", "Sccess");
      setRefresh(refresh + 1);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Institute Team</h4>
                </div>
              </div>
            </div>
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field">
                      <Input
                        type="select"
                        className="select"
                        value={paginate}
                        onChange={(e) => setPaginate(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="asc">Demo 1</option>
                              <option value="desc">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              placeholder="Search..."
                              onChange={({ target }) => setSearch(target.value)}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {
                          permissions.some(item => item?.name === "institute-create") &&
                        <Link
                          to="/add-institute"
                          type="button"
                          className="btn btn-secondary text-nowrap btn-sm"
                        >
                          <i className="fa fa-plus"></i>&nbsp;&nbsp;Add New
                        </Link>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header btn-header d-card-header">
                    <div className="left-box-tools">
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#sendSMS"
                      >
                        <i className="fa fa-mobile pr-1"></i> SMS
                      </button>
                      <button className="btn btn-secondary openEmail btn-sm pl-1 pr-1 mr-1">
                        <i className="fa fa-envelope pr-1"></i> Email
                      </button>
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        disabled={!deleteIds.length}
                      >
                        <i className="fa fa-trash pr-1"></i> Delete
                      </button>
                      <button
                        className="btn btn-secondary btn-sm pl-1 pr-1 mr-1"
                        data-toggle="modal"
                        data-target="#massUpdate"
                      >
                        <i className="fa fa-list pr-1"></i> Mass Update
                      </button>
                      <button
                        className="btn btn-secondary btn-sm mr-1"
                        title="Refresh"
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                    <div className="right-box-tools">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select mr-1"
                        onChange={({ target }) => setSort(target.value)}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Print"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm mr-1"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    <Input id="default" type="checkbox" />
                                  </div>
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold">Mobile</th>
                                {/* <th className="font-weight-bold">Industry</th> */}
                                <th className="font-weight-bold">
                                  Purpose of Meeting
                                </th>

                                <th className="font-weight-bold">
                                  Lead Source
                                </th>
                                <th className="text-right font-weight-bold">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="d-table-body">
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {Array.isArray(meeting)
                                    ? meeting?.map((meet, i) => (
                                      <tr key={i + 1}>
                                        <td>
                                          <div className="custom-checkbox custom-control">
                                            <Input
                                              id={`default-${meet?.id}`}
                                              type="checkbox"
                                              checked={deleteIds.some(
                                                (d) => d === meet?.id
                                              )}
                                              onChange={(e) =>
                                                handleSingleSelect(
                                                  e,
                                                  meet?.id
                                                )
                                              }
                                            />
                                          </div>
                                        </td>

                                        <Link
                                          to={`/institution-detail/${meet?.id}`}
                                        >
                                          <td>{meet?.institution_name}</td>
                                        </Link>
                                        <td>
                                          {meet?.contact_detail?.[0]?.email}
                                        </td>
                                        <td>
                                          {
                                            meet?.contact_detail?.[0]
                                              ?.contact_person_name
                                          }
                                        </td>
                                        <td>
                                          {
                                            meet?.contact_detail?.[0]
                                              ?.contact_number
                                          }
                                        </td>

                                        <td>{meet?.lead_source}</td>
                                        <td className="text-right">
                                          {
                                            permissions.some(item => item?.name === "institute-edit") &&
                                            <Link
                                              to={`/institute/edit/${meet?.id}`}
                                              className="mr-2 text-info"
                                            >
                                              <FiEdit />
                                            </Link>
                                          }
                                          {/* <a
                                              href="#"
                                              className="text-danger"
                                              onClick={(e) =>
                                                handleOneDelete(e, meet?.id)
                                              }
                                            >
                                              <FiTrash />
                                            </a> */}
                                        </td>
                                      </tr>
                                    ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default InstituteTeam;
