import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import SMS from "../utils/SMS";
import Mail from "../utils/Mail";
import { Input, CustomInput } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

import { FiEdit } from "react-icons/fi";

import Loader from "../../layouts/Loader";
import Pagination from "react-js-pagination";
import { workShopList } from "../../store/actions/WorkShopActions";
import { useSelector, useDispatch } from "react-redux";
const List = ({ history: { push } }) => {
  const [sort, setSort] = useState("asc");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState("");
  const [workShops, setWorkShops] = useState([]);
  const { auth, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { bookedWorkShops, bookedWorkShop, loading, success, errors } =
    useSelector((state) => state.workShop);
  useEffect(() => {
    dispatch(workShopList(auth?.token, paginate, search, page, sort));
  }, [auth?.token, refresh, paginate, search, sort, page]);

  const workShopData = useMemo(() => {
   
    if (bookedWorkShops?.bookWorkShop) {
      setWorkShops(bookedWorkShops?.bookWorkShop.data);
      setFrom(bookedWorkShops?.bookWorkShop.from);
      setTo(bookedWorkShops?.bookWorkShop.to);
      setTotal(bookedWorkShops?.bookWorkShop.total);
      setPerPage(bookedWorkShops?.bookWorkShop.per_page);
    }
  }, [bookedWorkShops]);
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        workShopData.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify(deleteIds),
      };
    }
  };

  const handleOneDelete = (e, ids) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const delArr = {
        ids: JSON.stringify([ids]),
      };
      //leadDestroyApi(delArr, token);
    }
  };
  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  const handleSerchByClick = (e) => {
    setPage(1);
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-xl-flex justify-content-between justify-content-center">
                    <div className="left-area-field">
                      <Input
                        type="select"
                        className="custom-select"
                        onChange={(e) => setPaginate(e.target.value)}
                        value={paginate}
                      >
                        <option>Select</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Input>
                    </div>
                    <div className="d-md-flex text-right right-side-links position-relative justify-content-end">
                      <div className="btn-group mr-1 advance-search-options">
                        <div className="input-group">
                          <div className="input-group-btn">
                            <select className="form-control custome-select searchbyselect btn-secondary">
                              <option value="">All</option>
                              <option value="Ascending">Demo 1</option>
                              <option value="Descending">Demo 2</option>
                            </select>
                          </div>
                          <div className="advance-search">
                            <Input
                              className="form-control search-input"
                              placeholder="Search..."
                              value={search}
                              onChange={handleSearch}
                            />
                            <div
                              className="advance-search-btn"
                              title="Advance Search"
                            >
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <span className="input-group-btn">
                            <button
                              className="btn btn-secondary btn-flat btn-sm p-1"
                              type="button"
                              onClick={handleSerchByClick}
                            >
                              Go
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="advSearchDiv text-left border">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row border-top pt-2">
                          <div className="col-12 d-sm-flex justify-content-between">
                            <div className="criteria-search-box">
                              <select className="form-control custome-dropdown-menu-adv radius-3">
                                <option>Search Criteria</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                <option value="Option 4">Option 4</option>
                                <option value="Option 5">Option 5</option>
                              </select>
                            </div>
                            <div className="advance-search-box-btns">
                              <button
                                type="button"
                                className="btn btn-small btn-secondary btn-sm p-1"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary ml-1 mr-1 btn-sm p-1"
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-small btn-secondary advance-search-close btn-sm p-1"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group mr-1">
                        <Link
                          to="/booking-workshop/create"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-plus" />
                          &nbsp;&nbsp;Add New
                        </Link>
                      </div>
                      <div className="btn-group mr-1">
                        <Link
                          to="#"
                          className="btn btn-secondary text-nowrap btn-sm p-1"
                        >
                          <i className="fa fa-download" />
                          &nbsp;&nbsp;Import Leads
                        </Link>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle btn-sm p-1"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-list" />
                          &nbsp;&nbsp;Leads Tools
                          <span className="caret" />
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu" role="menu">
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-status.html"
                            >
                              Lead Status
                            </a>
                          </li>
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-rating.html"
                            >
                              Lead Rating
                            </a>
                          </li>
                          <li className="w-100">
                            <a
                              className="pt-1 pb-1 pr-2 pl-2 w-100 d-block"
                              href="lead-contact-source.html"
                            >
                              Source
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <SMS />
                    <Mail />
                    <button
                      className="btn btn-secondary btn-sm"
                      disabled={!deleteIds.length}
                      onClick={handleDelete}
                    >
                      <i className="fa fa-trash pr-1" /> Delete
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      data-toggle="modal"
                      data-target="#massUpdate"
                    >
                      <i className="fa fa-list pr-1" /> Mass Update
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      title="Refresh"
                    >
                      <i className="fa fa-refresh" />
                    </button>
                    <div className="box-tools pull-right">
                      <select
                        id="ddlSortBy"
                        className="custome-select small-select"
                        onChange={(e) => setSort(e.target.value)}
                        value={sort}
                      >
                        <option value="">Sort By</option>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Print"
                      >
                        <i className="fa fa-print" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To PDF"
                      >
                        <i className="fa fa-file-pdf-o" />
                      </button>
                      <button
                        className="btn btn-secondary btn-floating btn-sm"
                        title="Export To Excel"
                      >
                        <i className="fa fa-file-excel-o" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th>
                                  <div className="custom-checkbox custom-control">
                                    <CustomInput
                                      id="default"
                                      type="checkbox"
                                      onChange={(e) => handleMultiSelect(e)}
                                      checked={
                                        deleteIds.length ===
                                          workShopData?.length &&
                                        deleteIds.length
                                      }
                                    />
                                  </div>
                                </th>
                                <th className="font-weight-bold">Name</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold">Email</th>
                                <th className="font-weight-bold">TrxId</th>
                                <th className="font-weight-bold">Mode</th>
                                <th className="font-weight-bold">WorkShop</th>
                                <th className="text-right font-weight-bold">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <Fragment>
                                  <tr>
                                    <td colSpan={7}>
                                      <Loader loading={loading} />
                                    </td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {workShops
                                    ? workShops.map((workShop, i) => (
                                        <tr key={Math.random()}>
                                          <td>
                                            <div className="custom-checkbox custom-control">
                                              <CustomInput
                                                id={`default-${workShop?.id}`}
                                                type="checkbox"
                                                checked={deleteIds.some(
                                                  (d) => d === workShop?.id
                                                )}
                                                onChange={(e) =>
                                                  handleSingleSelect(
                                                    e,
                                                    workShop?.id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>{workShop?.name}</td>
                                          <td>{workShop?.mobile}</td>
                                          <td>{workShop?.email}</td>
                                          <td>{workShop?.payment_id}</td>
                                          <td>{workShop?.payment_mode}</td>
                                          <td>
                                            {workShop?.work_shop_details?.name}
                                          </td>
                                          <td className="text-right">
                                            <Link
                                              to={`/booking-workshop/edit/${workShop?.id}`}
                                              className="mr-2 text-info"
                                            >
                                              <FiEdit />
                                            </Link>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={parseInt(perPage)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => setPage(page)}
                        innerClass="pagination pagination-sm"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default List;
