import React, { useState, Fragment, useEffect } from "react";
import {
    Button,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import { FiPlus } from "react-icons/fi";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";

const ModalQualification = props => {
    const [modal, setModal] = useState(false);
    const [qualification, setQualification] = useState("");
    const { auth } = useSelector(({ auth }) => auth);
    useEffect(() => {
        setQualification("");
    }, [modal]);
    const handleSubmit = () => {
        axios
            .post("/api/crm/add-qualification", { title: qualification },{
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                  },
            })
            .then(res => {
                NotificationManager.success(res.data.message);
                props.qualificationListFunction();
                setModal(false);
            })
            .catch(err => NotificationManager.error(err.response.data.message));
    };

    return (
        <Fragment>
            <Button type="button" onClick={() => setModal(!modal)}>
                <FiPlus />
                &nbsp;Add More Qualification
            </Button>
            <Modal isOpen={modal}>
                <ModalHeader toggle={() => setModal(!modal)}>
                    Add Qualification
                </ModalHeader>
                <ModalBody>
                    <Input
                        type="text"
                        name="qualification"
                        value={qualification}
                        onChange={e => setQualification(e.target.value)}
                    />
                    <Button
                        className="mt-3"
                        style={{ height: 38 }}
                        color="secondary"
                        block
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default ModalQualification;
