import _ from "lodash";
import {
  LEAD_REQUEST_PENDING,
  LEAD_REQUEST_SUCCESS,
  LEAD_REQUEST_REJECTED,
  LEAD_DETAIL_REQUEST_PENDING,
  LEAD_DETAIL_REQUEST_FULFILLED,
  LEAD_DETAIL_REQUEST_REJECTED,
  LEAD_DATA_PENDING,
  LEAD_DATA_FULFILLED,
  LEAD_DATA_REJECTED,
  LEADS_PENDING,
  LEADS_FULFILLED,
  LEADS_REJECTED,
  LEAD_BY_ID_PENDING,
  LEAD_BY_ID_FULFILLED,
  LEAD_BY_ID_REJECTED,
  UPDATE_LEAD_PENDING,
  UPDATE_LEAD_FULFILLED,
  UPDATE_LEAD_REJECTED,
  CHANGE_REDUCER_STATE,
  CREATE_LEAD_PENDING,
  CREATE_LEAD_FULFILLED,
  CREATE_LEAD_REJECTED,
  SOURCE_CREATE_PENDING,
  SOURCE_CREATE_FULFILLED,
  SOURCE_CREATE_REJECTED,
} from "../../components/utils/constant";
const initialState = {
  users: [],
  engagedAgents: [],
  userStatus: false,
  leadData: [],
  isLoading: false,
  errors: {},
  success: {},
  results: [],
  details: [],
  users: [],
  leadData: [],
  loading: false,
  createSource: [],
  createLeadStatuss: false,
  createSourceLoading: false,
  getSources: [],
  getSourcesStatus: false,
  leadsStatusListStatus: false,
  addLeadStatus: [],
  leadStatusSuccess: false,
  leadStatusLoading: false,
  addRating: [],
  addRatingStatus: false,
  addRatingLoading: false,
  leadRatingListing: [],
  leadRatingListingStatus: false,
  leads: [],
  leadsStatus: false,
  createLead: [],
  leadsLoader: false,
  createLeadLoader: false,
  createLeadStatus: false,
  leadById: [],
  leadByIdLoader: false,
  leadByIdStatus: false,
  importLead: [],
  importLeadLoader: false,
  importLeadStatus: false,
  updateLeads: [],
  updateLeadLoader: false,
  updateLeadStatus: false,

  leadDestry: [],
  leadDestroyLoader: false,
  leadDestroyStatus: false,
  error: {},
  newMobile: JSON.parse(localStorage.getItem("newMobile")),
  leadDetail: {},
  isLoadingDetail: true,
  isDuping:[]
};

const leadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_REDUCER_STATE:
      return {
        ...state,
        leadsLoader: false,
        leadsStatus: false,
        leadByIdLoader: false,
        leadByIdStatus: false,
        updateLeadLoader: false,
        updateLeadStatus: false,
        leadStatusLoading: false,
        leadStatusSuccess: false,
        isLoading: false,
        users: [],
        userStatus: false,
        leadData: [],
        errors: {},
        success: {},
      };

    case LEAD_REQUEST_PENDING:
      return {
        ...state,
        isLoading: true,
        errors: {},
        success: {},
      };

    case "LEAD_RESET_DATA":
      return { ...initialState };
    case LEAD_DETAIL_REQUEST_PENDING:
    case LEAD_DATA_PENDING:
    case LEADS_PENDING:
    case LEAD_BY_ID_PENDING:
      return {
        ...state,
        leadsLoader: true,
        leadsStatus: false,
        leadByIdLoader: true,
        leadByIdStatus: false,
        users: [],
        userStatus: false,
        leadData: [],
        errors: {},
        success: {},
      };
    case CREATE_LEAD_PENDING:
    case UPDATE_LEAD_PENDING:
    case SOURCE_CREATE_PENDING:
      return {
        ...state,
        createLeadLoader: false,
        createLeadStatus: false,
        createSourceStatus: false,
        updateLeadLoader: true,
        updateLeadStatus: false,
        createLeadStatuss: false,
        createSourceLoading: true,
        errors: {},
        success: {},
      };
    case LEAD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: _.get(payload, "data", []),
      };

    case "SET_MOBILE":
      return {
        ...state,
        newMobile: payload,
      };
    case LEAD_DETAIL_REQUEST_FULFILLED:
      return {
        ...state,
        isLoading: false,
        details: _.get(payload, "data", []),
      };
    case LEAD_DATA_FULFILLED:
      return {
        ...state,
        loading: false,
        userStatus: true,
        leadData: _.get(payload, "data", {}),
      };
    case LEADS_FULFILLED:
      return {
        ...state,
        leadsLoader: false,
        leadsStatus: true,
        leads: _.get(payload, "data", {}),
      };

    case "ENGAGED_AGENTS_FULFILLED_FULFILLED":
      console.log(payload, "data");
      return {
        ...state,
        leadsLoader: false,
        leadsStatus: true,
        engagedAgents: _.get(payload, "data", {}),
      };
    case LEAD_BY_ID_FULFILLED:
      return {
        ...state,
        leadByIdLoader: false,
        leadByIdStatus: true,
        leadById: _.get(payload, "data", {}),
      };
    case UPDATE_LEAD_FULFILLED:
    case CREATE_LEAD_FULFILLED:
      return {
        ...state,
        createLeadStatuss: payload?.response?.status === 422 ? false : true,
        createLeadStatus: true,
        createSourceStatus: false,
        createSourceLoading: false,
        addLeadStatus: _.get(payload, "data", {}),
        updateLeadLoader: false,
        updateLeadStatus: true,
        updateLeads: _.get(payload, "data", {}),
        createSource: _.get(payload, "data", {}),
        errors: payload?.response?.status === 422 ? payload?.response?.data?.errors:{}
          
      };

    case "RESET_LEAD_STATUS":
      return {...state,createLeadStatus:false}
    case SOURCE_CREATE_FULFILLED:
      return {
        ...state,
        createSourceStatus: true,
        createSourceLoading: false,
        createLeadStatuss: true,
        createSource: _.get(payload, "data", {}),
      };
    case LEAD_REQUEST_REJECTED:
    case LEAD_DETAIL_REQUEST_REJECTED:
    case LEAD_DATA_REJECTED:
    case LEADS_REJECTED:
    case LEAD_BY_ID_REJECTED:
    case UPDATE_LEAD_REJECTED:
    case CREATE_LEAD_REJECTED:
    case SOURCE_CREATE_REJECTED:
      if (payload.response.status === 422) {
        return {
          ...state,
          createLeadLoader: false,
          createLeadStatus: false,
          updateLeadLoader: false,
          updateLeadStatus: false,
          leadsLoader: false,
          createLeadStatuss: false,
          leadsStatus: false,
          isLoading: false,
          isLoadingEdp: false,
          userStatus: false,
          errors: _.get(payload.response.data.errors, "errors", {}),
        };
      } else {
        return {
          ...state,
          createLeadLoader: false,
          createLeadStatus: false,
          updateLeadLoader: false,
          updateLeadStatus: false,
          leadsLoader: false,
          leadsStatus: false,
          isLoading: false,
          isLoadingEdp: false,
          userStatus: false,
          errors: _.get(payload, "data", {}),
        };
      }
    case "CREATE_LEAD_STATUS_PENDING":
      return {
        ...state,
        leadStatusLoading: true,
        leadStatusSuccess: false,
        error: {},
      };

    case "CREATE_LEAD_STATUS_FULFILLED":
      return {
        ...state,
        leadStatusLoading: false,
        leadStatusSuccess: true,
        addLeadStatus: _.get(payload, "data", {}),
      };

    case "CREATE_LEAD_STATUS_REJECTED":
      if (payload.response.status === 422) {
        return {
          ...state,
          leadStatusLoading: false,
          leadStatusSuccess: false,
          error: _.get(payload.response, "errors", {}),
        };
      } else {
        return {
          ...state,
          leadStatusLoading: false,
          leadStatusSuccess: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }

    case "LEAD_STATUS_LIST_PENDING":
      return {
        ...state,
        loading: true,
        leadsStatusListStatus: false,
        error: {},
      };

    case "LEAD_STATUS_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        leadsStatusListStatus: true,
        leadsStatus: _.get(payload, "data", {}),
      };

    case "LEAD_STATUS_LIST_REJECTED":
      return {
        ...state,
        loading: false,
        leadsStatusListStatus: false,
        error: _.get(payload.response, "data", {}),
      };

    case "LEAD_RATING_PENDING":
      return {
        ...state,
        addRatingLoading: true,
        addRatingStatus: false,
        error: {},
      };

    case "LEAD_RATING_FULFILLED":
      return {
        ...state,
        addRatingLoading: false,
        addRatingStatus: true,
        addRating: _.get(payload, "data", {}),
      };

    case "LEAD_RATING_REJECTED":
      if (payload.response.status === 422) {
        return {
          ...state,
          addRatingLoading: false,
          addRatingStatus: false,
          error: _.get(payload.response, "data", {}),
        };
      } else {
        return {
          ...state,
          addRatingLoading: false,
          addRatingStatus: false,
          error: _.get(payload.response, "data", {}),
        };
      }

    case "LEAD_RATING_LISTING_PENDING":
      return {
        ...state,
        loading: true,
        leadRatingListingStatus: false,
        error: {},
      };

    case "RESET_CREATE_LEAD_STATUS":
      return {
        ...state,
        createLeadStatuss: action.payload,
      };

    case "LEAD_RATING_LISTING_FULFILLED":
      return {
        ...state,
        loading: false,
        leadRatingListingStatus: true,
        leadRatingListing: _.get(payload, "data", {}),
      };

    case "LEAD_RATING_LISTING_REJECTED":
      return {
        ...state,
        loading: false,
        leadRatingListingStatus: false,
        error: _.get(payload.response, "data", {}),
      };
    case "IMPORT_LEAD_PENDING":
      return {
        ...state,
        importLeadLoader: true,
        importLeadStatus: false,
        error: {},
      };
    case "IMPORT_LEAD_FULFILLED":
      if (payload?.response?.status === 422) {
        return {
          ...state,
          error: payload?.response?.data?.errors,
        };
      }
      console.log(payload?.data?.items?.length,"dupesData");
      if (payload?.data?.items?.length) {
        return {
          ...state,
          isDuping:payload?.data?.items
        }
      } 
      else{

        return {
          ...state,
          importLeadLoader: false,
          importLeadStatus: true,
          importLead: _.get(payload, "data", {}),
        };
      } 

    case "RESET_IMPORT_LEAD_STATUS":
      return {
        ...state,
        importLeadStatus: false,
      };

    case "IMPORT_LEAD_REJECTED":
      if (payload.response.status === 422) {
        return {
          ...state,
          importLeadLoader: false,
          importLeadStatus: false,
          error: payload.response.data.errors,
        };
      } else {
        return {
          ...state,
          importLeadLoader: false,
          importLeadStatus: false,
          error: _.get(payload.response.data, "message", {}),
        };
      }
    case "SOURCE_LIST_PENDING":
      return {
        ...state,
        loading: true,
        getSourcesStatus: false,
        error: {},
      };
    case "SOURCE_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        getSourcesStatus: true,
        getSources: _.get(payload, "data", {}),
      };
    case "SOURCE_LIST_REJECTED":
      return {
        ...state,
        loading: false,
        getSourcesStatus: false,
        error: _.get(payload.response, "data", {}),
      };
    case "LEAD_DELETE_PENDING":
      return {
        ...state,
        leadDestroyLoader: true,
        leadDestroyStatus: false,
        error: {},
      };

    case "LEAD_DELETE_FULFILLED":
      return {
        ...state,
        leadDestroyStatus: true,
        leadDestroyLoader: false,
        leadDestry: _.get(payload, "data", {}),
      };

    case "LEAD_DELETE_REJECTED":
      return {
        ...state,
        leadDestroyStatus: false,
        leadDestroyLoader: false,
        error: _.get(payload.response.data, "data", {}),
      };

    default:
      return state;
  }
};
export default leadReducer;
