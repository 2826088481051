import React, { useState, useEffect, useCallback, useMemo } from "react";
import MainWrapper from "../../components/MainWrapper";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { addMeeting } from "../../store/actions/MeetingActions";
import { useHistory } from "react-router";
import { editInstitute } from "../../store/actions/InstituteTeamActions";
import { useParams } from "react-router";
import moment from "moment";
import { getCrmUsersApi } from "../../store/actions/LeadActions";

import { FaSpinner } from "react-icons/fa";

const EditInstitute = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [contactperson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [alternatemobile1, SetAlternateMobile1] = useState("");
  const [alternatemobile2, SetAlternateMobile2] = useState("");
  const [alternatemobile3, SetAlternateMobile3] = useState("");
  const [email, setEmail] = useState("");
  const [alternateemail1, SetAlternateemail1] = useState("");
  const [alternateemail2, SetAlternateemail2] = useState("");
  const [alternateemail3, SetAlternateemail3] = useState("");
  const [emailDetail, setEmailDetail] = useState({
    email: "",
  });
  const [contactDetails, setContactDetails] = useState({
    contactNo: "",
  });

  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [meetingPurpose, setMeetingPurpose] = useState("");
  const [occupation, setOccupation] = useState("");
  const [industry, setIndustry] = useState([]);
  const [sector, setSector] = useState("");
  const [bussinesstype, setBussinessType] = useState("");
  const [investment, setInvestment] = useState("");
  const [otherInvestment, setOtherInvestment] = useState("");
  const [timerequired, setTimeRequired] = useState("");
  const [leadtype, setLeadType] = useState("");
  const [institutetype, setInstituteType] = useState("");
  const [leadsource, setLeadSource] = useState("");
  const [leadstatus, setLeadStatus] = useState("");
  const [payment, setPayment] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const [sectors, setSectors] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [conversion, setConversion] = useState("");
  const [paymentstatus, setPaymentStatus] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [details, setDetails] = useState([
    { contact_person_name: "", contact_number: "", email: "" },
  ]);
  const history = useHistory();

  const { auth } = useSelector((state) => state.auth);

  const [assignTo, setAssignTo] = useState(auth?.id || "");
  const [leadDate, setLeadDate] = useState("");

  const { leadData } = useSelector(({ lead }) => lead);

  useEffect(() => {
    if (pincode.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setCity(response?.data[0]?.PostOffice[0]?.Block);
            setState(response?.data[0]?.PostOffice[0]?.State);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [pincode]);

  const handleValidate = () => {
    let error = {
      name: "",
    };
    let valid = true;

    if (!name) {
      error["name"] = " Name field is required";
      valid = false;
    }

    if (!leadsource) {
      error["leadsource"] = "Source field is required";
      valid = false;
    }

    if (!bussinesstype) {
      error["bussinesstype"] = "Business Type field is required";
      valid = false;
    }

    if (!institutetype) {
      error["institutetype"] = "Institute Type field is required";
      valid = false;
    }

    if (!pincode) {
      error["pincode"] = "Pincode field is required";
      valid = false;
    }
    if (!leadstatus) {
      error["leadstatus"] = "Leadstatus field is required";
      valid = false;
    }

    setErrors(error);
    return valid;
  };

  useEffect(() => {
    dispatch(getCrmUsersApi(auth?.token));
    axios(`/api/crm/get-crm-university-teamby/${id}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        setName(res.data?.crmUniversityTeam?.institution_name || "");
        setInstituteType(
          res.data?.crmUniversityTeam?.type_of_institution || ""
        );
        setDetails([...res?.data?.crmUniversityTeam?.contact_detail]);
        setAmount(res.data?.crmUniversityTeam?.amount);
        setPincode(res.data?.crmUniversityTeam?.pincode);
        setBussinessType(res.data?.crmUniversityTeam?.bussiness_type);
        setLeadStatus(res?.data?.crmUniversityTeam?.lead_status);
        setLeadSource(res?.data?.crmUniversityTeam?.lead_source);
        setPaymentStatus(res?.data?.crmUniversityTeam?.payment_status);
        setConversion(res?.data?.crmUniversityTeam?.conversion);
        setNotes(res?.data?.crmUniversityTeam?.note);
        setMeetingPurpose(res?.data?.crmUniversityTeam?.purpose_of_meeting);
        setPaymentStatus(res?.data?.crmUniversityTeam?.payment_status);
        setTimeRequired(res?.data?.crmUniversityTeam?.time_required_bussiness);
        setOccupation(res?.data?.crmUniversityTeam?.occupation);
        setLeadDate(
          moment(res.data?.crmFranchiseTeam?.lead_date).format("YYYY-MM-DD") ||
            ""
        );
      })
      .catch((err) => console.log(err));
  }, [auth]);

  const { updateinstitutestatus, loading } = useSelector(
    (state) => state.instituteReducer
  );

  useEffect(() => {
    if (updateinstitutestatus) {
      dispatch({ type: "RESET_UPDATE_STATUS", payload: false });
      NotificationManager.success("Added Successfully");
      history.push("/institute-team");
    }
  }, [updateinstitutestatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidate()) {
      const d = {
        id: id,
        institution_name: name,
        type_of_institution: institutetype,
        address: address,
        pincode: pincode,
        city: city,
        state: state,
        purpose_of_meeting: meetingPurpose,
        investment: investment,
        bussiness_type: bussinesstype,
        time_required_bussiness: timerequired,
        occupation: occupation,
        lead_type: leadtype,
        lead_source: leadsource,
        lead_status: leadstatus,
        conversion: conversion,
        payment_status: paymentstatus,
        amount: amount,
        note: notes,
        status: 1,
        assign_to: assignTo,
        lead_date: leadDate,
      };
      const detailedArr = [...details];

      d["contact_details"] = detailedArr;
      console.table(d);
      dispatch(editInstitute(d, auth.token));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/franchise/sub-categories/list?category=${3}`)
      .then((res) => {
        setSectors(res.data.subCategories);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSector = (value) => {
    setSubCategory(value);
    axios
      .get(`/api/franchise/subsectordata?industry=${value}`)
      .then((res) => {
        setIndustry(res.data.subSubCats);
      })
      .catch((err) => console.log(err));
  };
  //   useEffect(() => {
  //     dispatch({
  //       type: PARTNER_ALLOWED_PRODUCT_REQUEST,
  //       payload: auth?.token,
  //       fk_franchise_type: auth?.fk_franchise_type,
  //     });
  //   }, [auth]);

  //   const { saleTarget, allowedProduct } = useSelector(
  //     ({ dashboard }) => dashboard
  //   );
  //   const franchiseServicesList = useMemo(() => allowedProduct, [allowedProduct]);
  //   console.log(franchiseServicesList, "hiiiii");

  const phoneValid = (val) => {
    return val.length < 11 && !isNaN(val) ? true : false;
  };
  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Create User</CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Assigned To
                    </label>

                    <Input
                      className="custom-select col-md-7"
                      type="select"
                      id="assignTo"
                      disabled={true}
                      onChange={(e) => setAssignTo(e.target.value)}
                      value={assignTo}
                      invalid={!!errors?.assign_to}
                    >
                      <option value="">select</option>

                      {(Array.isArray(leadData.users)
                        ? leadData?.users
                        : []
                      ).map((item) => {
                        return (
                          <option value={item?.value} key={item?.id}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="row form-group">
                    <label className="col-md-5 control-label col-form-label text-md-right">
                      Lead Date
                    </label>

                    <Input
                      type="date"
                      className=" col-md-7"
                      value={leadDate}
                      onChange={({ target: { value } }) => setLeadDate(value)}
                    ></Input>
                    <FormFeedback className="text-left">
                      {errors?.assign_to}
                    </FormFeedback>
                  </div>
                </div>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Institute Type
                    </Label>
                    <Input
                      type="select"
                      value={institutetype}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setInstituteType(value)
                      }
                    >
                      {" "}
                      <option value="">Select </option>
                      <option value="Manufacturing">University</option>
                      <option value="services">School</option>
                      <option value="n/a">College</option>
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.institutetype}
                    </span>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5} className="text-md-right">
                        <Label>Institution Name</Label>
                      </Col>

                      <Col>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                {details.map((item, index) => {
                  return (
                    <div className="bg-secondary w-100 py-2" key={index}>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Row className="align-items-center">
                              <Col md={5} className="text-md-right">
                                <Label>Contact Person Name</Label>
                              </Col>

                              <Col>
                                <Input
                                  value={item?.contact_person_name}
                                  name="contact_person_name"
                                  onChange={(e) => {
                                    setDetails(
                                      details.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            [e.target.name]: e.target.value,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Row className="align-items-center">
                              <Col md={5} className="text-md-right">
                                <Label>Contact No.</Label>
                              </Col>

                              <Col md={7}>
                                <Input
                                  name="contact_number"
                                  value={item?.contact_number}
                                  onChange={(e) => {
                                    phoneValid(e.target.value) &&
                                      setDetails(
                                        details.map((item, i) => {
                                          if (index === i) {
                                            return {
                                              ...item,
                                              [e.target.name]: e.target.value,
                                            };
                                          }
                                          return item;
                                        })
                                      );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Row className="align-items-center">
                              <Col md={5} className="text-md-right">
                                <Label>Email</Label>
                              </Col>

                              <Col>
                                <Input
                                  name="email"
                                  value={item?.email}
                                  onChange={(e) => {
                                    setDetails(
                                      details.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            [e.target.name]: e.target.value,
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>

                        <Col>
                          <div className="btn-wrapper">
                            {!index ? (
                              <Button
                                onClick={() =>
                                  setDetails([
                                    ...details,
                                    {
                                      contact_number: "",
                                      contact_person_name: "",
                                      email: "",
                                    },
                                  ])
                                }
                              >
                                + Add More
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  setDetails(
                                    details.filter((it, i) => i !== index)
                                  )
                                }
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}

                <Col sm={6}>
                  <FormGroup className="row mt-2">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Pincode
                    </Label>
                    <Input
                      type="text"
                      value={pincode}
                      invalid={Boolean(errors?.pincode)}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setPincode(value)}
                    />
                    <span className="d-block text-danger ml-auto col-md-7">
                      {errors?.pincode}
                    </span>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      City
                    </Label>
                    <Input
                      type="text"
                      value={city}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setCity(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      State
                    </Label>
                    <Input
                      type="text"
                      value={state}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setState(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row mt-2">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Address
                    </Label>
                    <Input
                      type="textarea"
                      value={address}
                      rows={1}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setAddress(value)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Purpose of Meeting
                    </Label>
                    <Input
                      type="select"
                      value={meetingPurpose}
                      invalid={Boolean(errors?.leadsource)}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setMeetingPurpose(value)
                      }
                    >
                      <option value="">Select</option>

                      <option value="Visit">WLA</option>
                      <option value="Reference">Incubation</option>
                      <option value="Reference">Webinar/Seminar</option>
                      <option value="Reference">Courses</option>
                      <option value="Reference">Other</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.leadsource}
                  </span>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="address"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Occupation
                    </Label>
                    <Input
                      type="select"
                      value={occupation}
                      rows={1}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setOccupation(value)}
                    >
                      <option value="">Select</option>
                      <option value="service">Service</option>
                      <option value="business">Business</option>
                      <option value="n/a">N/A</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Sector
                    </Label>
                    <Input
                      type="select"
                      value={sector}
                      className="col-md-7"
                      invalid={Boolean(errors?.sector)}
                      onChange={({ target: { value } }) => {
                        setSector(value);
                        handleSector(value);
                      }}
                    >
                      <option value="">Select</option>
                      {sectors.map((a) => {
                        return <option value={a.value}>{a.label}</option>;
                      })}
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.sector}
                    </span>
                  </FormGroup>
                </Col> */}
                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Industry
                    </Label>
                    <Input
                      type="select"
                      className="col-md-7"
                      invalid={Boolean(errors?.industry)}
                      onChange={(e) => setSubCategory(e.target.value)}
                      value={subCategory}
                    >
                      <option value="">Select</option>
                      {industry.map((item) => {
                        return (
                          <option value={item.value} key={item.value}>
                            {item?.label}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.industry}
                    </span>
                  </FormGroup>
                </Col> */}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Business Type
                    </Label>
                    <Input
                      type="select"
                      value={bussinesstype}
                      invalid={Boolean(errors?.bussinesstype)}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setBussinessType(value)
                      }
                    >
                      {" "}
                      <option value="">Select </option>
                      <option value="Manufacturing">Education</option>
                      <option value="services">Engineer</option>
                      <option value="n/a">CA/CS</option>
                      <option value="n/a">Govt Constructor</option>
                      <option value="n/a">NGO</option>
                      <option value="n/a">Other</option>
                    </Input>
                    <span className="d-block text-danger col-md-7 ml-auto">
                      {errors?.bussinesstype}
                    </span>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Lead Source
                    </Label>
                    <Input
                      type="select"
                      value={leadsource}
                      invalid={Boolean(errors?.leadsource)}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setLeadSource(value)}
                    >
                      <option value="">Select</option>

                      <option value="Visit">Visit</option>
                      <option value="Reference">Reference</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.leadsource}
                  </span>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Lead Status
                    </Label>
                    <Input
                      type="select"
                      value={leadstatus}
                      className="col-md-7"
                      invalid={Boolean(errors?.investment)}
                      onChange={({ target: { value } }) => setLeadStatus(value)}
                    >
                      <option value="">Select</option>
                      <option value="active">Active</option>
                      <option value="hot">Hot</option>
                      <option value="warm">Warm</option>
                      <option value="not intrested">Not Interested</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.leadstatus}
                  </span>
                </Col>

                {/* <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="gender"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Investment
                    </Label>
                    <Input
                      type="select"
                      value={investment}
                      className="col-md-7"
                      invalid={Boolean(errors?.investment)}
                      onChange={({ target: { value } }) => {
                        setInvestment(value);
                        setPayment(value === "other");
                      }}
                    >
                      <option value="">Select</option>
                      <option value="walkin">0-10Lakh</option>
                      <option value="facebook">10-25Lakh</option>
                      <option value="google">20Lakh</option>
                      <option value="iid">30Lakh</option>
                      <option value="50lak">50Lakh</option>
                      <option value="1crore">1Crore</option>
                      <option value="2crore">2Crore</option>
                      <option value="5crore">5Crore</option>
                      <option value="other">Other</option>
                    </Input>
                  </FormGroup>
                  <span className="d-block text-danger col-md-7 ml-auto">
                    {errors?.investment}
                  </span>
                </Col> */}

                {/* {payment && (
                  <Col sm={6}>
                    <FormGroup className="row">
                      <Label
                        for="mobile"
                        className="col-md-5 control-label col-form-label col-12 text-md-right"
                      >
                        Investment Amount
                      </Label>
                      <Input
                        type="text"
                        value={otherInvestment}
                        className="col-md-7"
                        onChange={({ target: { value } }) => {
                          setOtherInvestment(value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )} */}

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Time required to start the association
                    </Label>
                    <Input
                      type="select"
                      value={timerequired}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setTimeRequired(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="15days">15 Days</option>
                      <option value="1months">1 Months</option>
                      <option value="2months">2 Months</option>
                      <option value="3months">3 Months</option>
                      <option value="6months">6 Months</option>
                      <option value="1year">1 Year</option>
                      <option value="2year">2 Years</option>
                      <option value="3year">3 Years</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Conversion
                    </Label>
                    <Input
                      type="select"
                      value={conversion}
                      className="col-md-7"
                      onChange={({ target: { value } }) => setConversion(value)}
                    >
                      <option value="">Select</option>
                      <option value="15days">Yes</option>
                      <option value="1months">No</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="mobile"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Payment Status
                    </Label>
                    <Input
                      type="select"
                      value={paymentstatus}
                      className="col-md-7"
                      onChange={({ target: { value } }) =>
                        setPaymentStatus(value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="15days">Full Paid</option>
                      <option value="1months">Partial Paid</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      Amount
                    </Label>
                    <Input
                      type="text"
                      value={amount}
                      className="col-md-7"
                      // invalid={Boolean(errors?.name)}
                      onChange={({ target: { value } }) => setAmount(value)}
                    />
                    {/* <span className="d-block text-danger  col-md-7 ml-auto">
                      {errors?.name}
                    </span> */}
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup className="row">
                    <Label
                      for="name"
                      className="col-md-5 control-label col-form-label col-12 text-md-right"
                    >
                      General Notes
                    </Label>
                    <textarea
                      type="text"
                      value={notes}
                      className="col-md-7"
                      // invalid={Boolean(errors?.name)}
                      onChange={({ target: { value } }) => setNotes(value)}
                    />
                    {/* <span className="d-block text-danger  col-md-7 ml-auto">
                      {errors?.name}
                    </span> */}
                  </FormGroup>
                </Col>

                <Col sm={12}>
                  <div className="text-center">
                    <Button
                      type="submit"
                      color="secondary"
                      size="sm"
                      className="mr-1"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>

                    <Button color="secondary" size="sm" className="mr-1">
                      Save & New
                    </Button>

                    <a href="/institute-team">
                      <Button type="button" color="secondary" size="sm">
                        Close
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </MainWrapper>
    </div>
  );
};

export default EditInstitute;
