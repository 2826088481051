import React, { useState, useEffect, useCallback, useMemo } from "react";
import MainWrapper from "../../components/MainWrapper";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import { useParams,useLocation } from "react-router";
import { FaSpinner } from "react-icons/fa";
import { Spinner } from "reactstrap";
import Axios from "axios";
import { FiEye, FiUser } from "react-icons/fi";
import { Redirect } from "react-router/cjs/react-router.min";
import Loading from "../../layouts/Loader";

const PaymentDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    auth,
    isAuthenticated,
    permissions: { permission },
  } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [agentDetails, setAgentDetails] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [paymentTable, setPaymentTable] = useState("payment");
  const [reciept, setReciept] = useState("");

  useEffect(() => {
    Axios.get(`/api/crm/payment-details/${id}`,{
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
        .then((res) => {
          const data = res?.data?.paymentDetails;
          // console.log('paymentDetails>>>',data);
          setLeadDetails(data?.lead);
          setAgentDetails(data?.agent);
          setPaymentDetails((data?.payment)?data?.payment:data?.work_shop_payment);
          setPaymentTable((data?.payment)?'payment':'work_shop_payment');
          setPaymentStatus(data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
  }, [auth.token]);

  const approvePaymentStatus = (paymentDetails) => { 
    const status = (auth?.roles[0]?.id == 90)? 'Qa Approved':(auth?.roles[0]?.id == 91)?'Account Approved':'Pending';
    const payment_id = paymentDetails?.id;
    const payment_type = paymentTable;
    const data ={
      payment_id,
      status,
      payment_type,
      authId:auth?.id,
      authRole:auth?.roles[0]?.name
    }
    axios
      .post(`/api/crm/approvePaymentStatus`, data, {
        headers: {
          Authorization: ` Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        if(res.data?.status == 200){
          NotificationManager.success("Payment Approved successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <div>
      <MainWrapper>
        <Card>
          <CardHeader>Payment Details</CardHeader>
            <CardBody>
              <Row>
                <div className="col-md-12">
                    <Loading loading={loading} />
                </div>
                <div className="col-md-8">
                    <div className="overflow-hidden rounded bg-white border">
                        <div className="px-3 py-1 bg-secondary">
                            <span className="font-weight-bold">Payment Details</span>
                        </div>
                        <div className="px-3 py-2">
                            <div className="row">
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Payment ID: </span><span>{paymentDetails?.payment_id??"N/A"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Amount: </span><span>{paymentDetails?.amount??"N/A"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Payment Mode: </span><span>{paymentDetails?.payment_mode??"N/A"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Gatway type: </span><span>{paymentDetails?.gatway_type??"N/A"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Payment For: </span><span>{paymentDetails?.type??paymentTable}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Payment Date: </span><span>{paymentDetails?.created_at??"N/A"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Payment Status: </span><span className={
                                        `badge badge-${(paymentDetails?.status === "PENDING") 
                                        ? "warning" 
                                        : (paymentDetails?.status === "success" || paymentDetails?.status === "SUCCESS") 
                                        ? "success" 
                                        : "danger" }`
                                      }>{paymentDetails?.status}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Agent Confirmation: </span><span className={
                                        `badge badge-${(paymentStatus?.qa_id != null) 
                                        ? "success" 
                                        : "warning"}`
                                      }>{(paymentStatus?.qa_id) 
                                        ? "Approved" 
                                        : "Pending"}</span>
                                </div>
                                <div className="col-md-4 mt-2 mb-2">
                                    <span className="font-weight-bold">Account confirmation: </span><span className={
                                        `badge badge-${(paymentStatus?.accountant_id != null) 
                                        ? "success" 
                                        : "warning"}`
                                      }>{(paymentStatus?.accountant_id) 
                                        ? "Approved" 
                                        : "Pending"}</span>
                                </div>
                                <div className="col-md-12" >
                                    <div className="card" style={{width:'140px',height:'175px' }}>
                                        <div className="card-body text-center" >
                                            <h6>Reciept</h6>
                                            <img src={`https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/${paymentDetails?.payment_img}`} className="" alt="" style={{width:'100px',height:'100px' }}  />
                                            <a href="#" onClick={()=>{
                                                setIsOpen(true);
                                                setReciept(paymentDetails?.payment_img);
                                            }}> View</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-3">
                                {(paymentStatus?.qa_id == null) && auth?.roles[0]?.id == 90 ? (
                                <Button className="btn btn-success" onClick={() => {
                                    approvePaymentStatus(paymentDetails);
                                    setLoading(true);
                                }}>
                                {loading ? <Spinner /> : "Approve Payment"} 
                                </Button>
                                ) : ((paymentStatus?.accountant_id == null) && auth?.roles[0]?.id == 91) ? (
                                <Button className="btn btn-success" onClick={() => {
                                    approvePaymentStatus(paymentDetails);
                                    setLoading(true);
                                    }}>
                                    {loading ? <Spinner /> : "Approve Payment"}
                                </Button>
                                ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="overflow-hidden rounded bg-white border">
                          <div className="px-3 py-1 bg-secondary">
                              <span className="font-weight-bold">Lead Details</span>
                          </div>
                          <div className="px-3 py-2">
                              <div className="row">
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Name</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{leadDetails?.full_name??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Email</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{leadDetails?.email??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Mobile</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{leadDetails?.mobile??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Lead Date</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{leadDetails?.created_at??"N/A"}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="overflow-hidden rounded bg-white border">
                          <div className="px-3 py-1 bg-secondary">
                              <span className="font-weight-bold">Agent Details</span>
                          </div>
                          <div className="px-3 py-2">
                              <div className="row">
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Name</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{agentDetails?.name??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Mobile</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{agentDetails?.mobile??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Email</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{agentDetails?.email??"N/A"}</span>
                                      </div>
                                  </div>
                                  <div className="col-md-12 mt-2 mb-2">
                                      <div className="font-weight-bold d-inline-block label-tag">Vertical</div>
                                      <div className="font-weight-bold pl-1 pr-1 d-inline-block colon-tag">
                                          :
                                      </div>
                                      <div className="d-inline-block data-tag">
                                          <span id="l_Code">{agentDetails?.vertical??"N/A"}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </CardBody>
        </Card>
      </MainWrapper>
      <Modal isOpen={isOpen} size="md" className="modal-md">
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          Payment Reciept
        </ModalHeader>
        <ModalBody className="industry-overflow ">
          <div className="row">
            <div className="col-md-12">
                <img src={`https://iid-bucket-s3-new.s3.ap-south-1.amazonaws.com/${reciept}`} style={{ width:'600px',height:'500px',objectFit:'contain'}} alt="" />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentDetail;
