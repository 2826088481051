/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import {
  FiUser,
  FiActivity,
  FiUserCheck,
  FiArchive,
  FiRepeat,
  FiPhoneCall,
} from "react-icons/fi";

import { FcCallback } from "react-icons/fc";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaUser, FaUserCheck, FaPhoneAlt, FaSadCry } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { ImHappy2 } from "react-icons/im";
import { BiDetail } from "react-icons/bi";
import { HiPhoneIncoming } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getLeadsDetail,
  getDashboardCount,
  SalesDashboardCount,
  DashboardCountById,
} from "../store/actions/DashboardActions";
import {
  Input,
  CustomInput,
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Label,
  Button,
} from "reactstrap";
import Pagination from "react-js-pagination";
import Axios from "axios";
import Accordian from "../../src/components/dashboard-accordian/Accordian";
const SalesDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [associate, setAssociate] = useState([]);
  const [toggle, setToggle] = useState("");
  const [leadDate, setLeadDate] = useState(moment().format("YYYY-MM-DD"));
  const [id, setId] = useState("");
  const [associatecount, setAssociateCount] = useState("");
  const [leadsource, setLeadsource] = useState("");
  const [purpose, SetPurpose] = useState("");
  const [associateFilter, setAssociateFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [associateteam, setAssociateTeam] = useState("");
  const { leaddetails, dashboardcount } = useSelector(
    (state) => state.dashboardReducer
  );
  const { token } = auth;
  const [lead, SetLead] = useState([]);

  useEffect(() => {
    dispatch(
      getDashboardCount(token, "get-associate-dashboard-count", associateFilter, leadsource, purpose)
    );
  }, [token, associateFilter, leadsource, purpose]);

  useEffect(() => {
    Axios.get(`/api/crm/get-associate-dashboard?id=${toggle}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociateCount(res.data))
      .catch((err) => console.log(err));
  }, [toggle]);

  useEffect(() => {
    Axios.get(`/api/crm/get-franchise-count?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociateTeam(res.data))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    Axios.get(`/api/crm/get-associate-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setAssociate(res.data.franchise))
      .catch((err) => console.log(err));
  }, [auth.token]);

  useEffect(() => {
    SetLead(leaddetails.convertedlead);
  }, [leaddetails, dashboardcount]);

  return (
    <Fragment>
      <MainWrapper>
        <main className="content">
          <div className="row gy-3 d-flex">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="" className="form-label  mb-2">
                  Start Date
                </label>
                <Input
                  type="date"
                  value={associateFilter.startDate}
                  onChange={({ target: { value } }) =>
                    setAssociateFilter((prev) => ({
                      ...prev,
                      startDate: value,
                    }))
                  }
                  style={{ borderRadius: "5px" }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="" className="form-label  mb-2">
                  End Date
                </label>
                <Input
                  type="date"
                  value={associateFilter.endDate}
                  onChange={({ target: { value } }) =>
                    setAssociateFilter((prev) => ({ ...prev, endDate: value }))
                  }
                  style={{ borderRadius: "5px" }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Lead Source</label>
                <select
                  id="paginate"
                  onChange={(e) =>
                    setLeadsource(e.target.value)
                  }
                  value={leadsource}
                  className="custom-select"
                >
                  <option value="">Select</option>
                  <option value="Ads">Ads</option>
                  <option value="Reference">Reference</option>
                  <option value="Inbound">Inbound</option>
                  <option value="Old_Leads">Old Leads</option>
                  <option value="Website">Website</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-card-top-block-item">
                <label>Purpose</label>
                <select
                  id="paginate"
                  onChange={(e) => SetPurpose(e.target.value)}
                  value={purpose}
                  className="custom-select"
                >
                  <option value="">Select</option>
                  <option value="Mitra">Mitra</option>
                  <option value="Sarthi">Sarthi</option>
                  <option value="Faciliation">
                    Faciliation
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darked">
                  <div className="card-body py-4">
                    <a href={`/associate-team?leaddate=${leadDate}`}>
                      <div className="row align-items-center ">
                        <div className="col-4">
                          <div className="square-box blue">
                            <FaUser color="white" size={18} />
                          </div>
                        </div>

                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.todayLead}
                          </h3>
                          <div className="mb-0 font-commom-2">Today Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-green">
                  <div className="card-body py-4">
                    <a href={`/associate-team?leadsource=Ads`}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box skyblue">
                            <FaUserCheck color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalLead}
                          </h3>
                          <div className="mb-0 font-commom-2">Total Lead</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-darkblue">
                  <div className="card-body py-4">
                    <a href={`/associate-team?dispositiontype=Connected`}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="square-box orange">
                            <FaPhoneAlt color="white" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card flex-fill common-card round-border box-orange">
                  <div className="card-body py-4">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="square-box pink">
                          <ImHappy2 color="white" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcount?.totalAmount}
                        </h3>
                        <div className="mb-0 font-commom-2">Total Sales</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="square-box darkblue">
                          <BiDetail color="#FF0032" size={18} />
                        </div>
                      </div>
                      <div className="col-8">
                        <h3 className="mb-0 font-commom">
                          {dashboardcount?.todayAmount}
                        </h3>
                        <div className="mb-0 font-commom-2">Today Sales</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill common-card round-border">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Not_Interested&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box green">
                            <FaSadCry color="#33d69f" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalNotInterested}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Follow_Up&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkred">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalFollowUp}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Follow up{" "}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Call_Back&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <HiPhoneIncoming color="#6C00FF" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalCallBack}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Call Back
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Mail_Shared&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMailShared}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Mail Shared
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Align&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingAlign}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Align
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Done&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingDone}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Meeting Done
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?disposition=Meeting_Done_NOI&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMeetingDoneNotInterested}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Meeting Done Not Interested
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?dispositiontype=Not_Connected&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalNotConnected}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Not Connected
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a href={`/associate-team?dispositiontype=Dump_Data&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}>
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalDumpData}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Dump Data
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/associate-team?disposition=Mail_Reshared&dispositiontype=Connected&start_date=${associateFilter.startDate}&end_date=${associateFilter?.endDate}`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.totalMailReshared}
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Mail Reshared
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card flex-fill round-border common-card">
                  <div className="card-body py-4">
                    <a
                      href={`/associate-notes-lead`}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="square-box darkblue">
                            <BiDetail color="#FF0032" size={18} />
                          </div>
                        </div>
                        <div className="col-8">
                          <h3 className="mb-0 font-commom">
                            {dashboardcount?.getNotesCount
                            }
                          </h3>
                          <div className="mb-0 font-commom-2">
                            Total Notes
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {auth?.id == "50" || auth?.id == "83" || auth?.id == "47" ? (
              <>
                <div>
                  <h5>Associate Team</h5>
                  <div className="row cursor-pointer">
                    {dashboardcount?.getTeamList?.map((consultant) => (
                      <div className="col-md-4 my-2">
                        <div>
                          {/* <Card>
                            <CardHeader onClick={() => setId(consultant?.id)}>
                              <span className="font-weight-bold cursor-pointer">
                                {consultant?.name}
                              </span>
                            </CardHeader>
                            <Collapse
                              isOpen={id === consultant?.id ? true : false}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between pt-4">
                                  {" "}
                                  <div>
                                    <h6>Today Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.todayLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalLead}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Today Connected</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalConnected}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total CallBack</h6>
                                  </div>{" "}
                                  <div>{associateteam?.totalCallBack}</div>
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card> */}
                          <Accordian
                            consultant={consultant}
                            key={consultant?.id}
                            type="1"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div>
                  <h5>Associate Partners</h5>
                  <div className="row cursor-pointer">
                    {associate?.map((consultant) => (
                      <div className="col-md-4 ">
                        <div> */}
                {/* <Card>
                            <CardHeader
                              onClick={() => setToggle(consultant?.id)}
                            >
                              <span className="font-weight-bold cursor-pointer">
                                {consultant?.name}
                              </span>
                            </CardHeader>
                            <Collapse
                              isOpen={toggle === consultant?.id ? true : false}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-between pt-4">
                                  {" "}
                                  <div>
                                    <h6>Today Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.todayAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Monthly Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.monthlyAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Sale</h6>
                                  </div>{" "}
                                  <div>{associatecount?.totalAmount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.todayleadCount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Monthly Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.monthlyleadCount}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-2">
                                  {" "}
                                  <div>
                                    <h6>Total Lead</h6>
                                  </div>{" "}
                                  <div>{associatecount?.totalleadCount}</div>
                                </div>
                              </CardBody>
                            </Collapse>
                          </Card> */}
                {/* <Accordian
                            consultant={consultant}
                            key={consultant?.id}
                            type="2"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </>
            ) : null}

            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0 table-sm small">
                            <thead className="thead-light">
                              <tr>
                                <th className="pl-4">S.no</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Campaign</th>
                                <th>State</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lead?.map((le, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>{le.full_name}</td>
                                    <td>{le.email}</td>
                                    <td>{le.mobile}</td>
                                    <td>{le?.compains}</td>
                                    <td>{le?.state}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Showing {from} to {to} of {total} entries
                      </span>
                      <div className="d-flex">
                        <Input
                          type="select"
                          className="custom-select custom-select-page"
                          onChange={(e) => setPaginate(e.target.value)}
                          value={paginate}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={parseInt(perPage)}
                          totalItemsCount={total}
                          pageRangeDisplayed={5}
                          onChange={(page) => setPage(page)}
                          innerClass="pagination pagination-sm"
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </MainWrapper>
    </Fragment>
  );
};

export default SalesDashboard;
