import Axios from "axios";
import { PERMISSIONS_LIST } from "../../components/utils/constant";
export const login = (data) => {
  return {
    type: "LOGIN",
    payload: Axios.post(`/api/crm/login`, data),
  };
};
export const changePassword = (data,token) => {
  console.log(data,"data")
  return {
    type: "CHANGE_PASSWORD",
    payload: Axios.post(`/api/crm/change-password`, data,{
      headers: { Authorization: `Bearer ${token}` },
    }),
  };
};

export const getallPermissions = (token) => {
  return {
    type: PERMISSIONS_LIST,
    payload: Axios.get(`/api/crm/get-users-permissions`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  };
};
