import React, { useEffect, useRef } from "react"
import { Chart, } from "chart.js"

const LineChart = ({yearlyData}) => {
    const canvas = useRef(null)
    useEffect(() => {
        const labels = yearlyData.map(item=>item.monthname11)
        const data = {
            labels: labels,
            datasets: [{
                label: 'My First Dataset',
                data: yearlyData.map(item=>item.amount),
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                fill: {
                    target: 'origin',
                    above: '#e2edf8',
                }

            }]
        };
        if (canvas.current) {
         const LineChart =   new Chart(canvas.current, {
                type: 'line',
                data,
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Target Report',
                            font: {
                                weight: "bold"
                            }
                        },
                        legend: {
                            display: false
                        }
                    },
                    elements:{
                        point:{
                          borderWidth:3,
                          hoverBorderWidth:15,
                          pointStyle:"circle"
                        },
                        line:{
                            borderCapStyle:"round"
                        }
                    }

                },
            })

            return (() => {
                LineChart.destroy()
            })
        }
    }, [canvas.current,yearlyData])
    return (
        <canvas width={700} ref={canvas}></canvas>
    )
}

export default LineChart