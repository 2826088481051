import React, { Fragment, useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import Loader from "../../layouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const LeadImport = (props) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});
  const [partner, setPartner] = useState("");
  const [fields, setFields] = useState("");
  const [franchise, setFranchise] = useState([]);
  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  const {
    auth: { token },
  } = useSelector((state) => state.auth);
  useEffect(() => {
    // axios
    //   .get(`/supplier-all`)
    //   .then((res) => {
    //     setFranchise(res?.data?.franchise);
    //   })
    //   .catch((err) => console.log(err));
    axios
      .get(`/api/crm/get-manager`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => setFranchise(res.data.manager))
      .catch((error) => console.log(error));
  }, []);
  console.log(partner);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("file", fields);
    data.append("partner", partner);
    axios
      .post(`/api/crm/import-franchise-lead`, data, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setLoading(false);
        NotificationManager.success(res.data.message);
        // toastr.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
        props.addCategory(res.data.category);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          NotificationManager.error(err.response.data.message);
          // toastr.error(err.response.data.message);
        }
      });
  };
  return (
    <Fragment>
      <ButtonGroup onClick={() => setModal(true)}>
        <Button className="btn btn-sm btn-secondary">
          <FaPlus className="btn-i-icon" />
          Import
        </Button>
      </ButtonGroup>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>
          Import Franchise Lead
          {/* <a
            href="/storage/partner_sample.csv"
            style={{ "margin-left": "170px", color: "blue" }}
          >
            sheet
          </a> */}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label>IID Partner</label>
              <CustomInput
                type="select"
                id="partner"
                name="partner"
                label="partner"
                value={partner}
                onChange={(e) => setPartner(e.target.value)}
                invalid={!!errors?.partner}
              >
                <option value="">select</option>
                {franchise.map((fc, i) => (
                  <option value={fc?.value} key={i}>
                    {fc?.label}
                  </option>
                ))}
              </CustomInput>
              <FormFeedback>
                {!!errors.partner ? errors.partner[0] : ""}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <CustomInput
                type="file"
                id="file"
                name="file"
                label="Choose File"
                onChange={handleChange}
                invalid={!!errors?.file}
              />
              <FormFeedback>{!!errors.file ? errors.file[0] : ""}</FormFeedback>
            </FormGroup>
            <div className="text-center mb-3">
              <Loader loading={loading}>
                <Button
                  type="submit"
                  color="secondary"
                  size="sm"
                  className="mr-1"
                >
                  Save
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  size="sm"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Loader>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default LeadImport;
