import React, { Fragment, useState, useEffect, useMemo } from "react";
import MainWrapper from "../MainWrapper";
import { useSelector } from "react-redux";
import { Table, Spinner, FormGroup, Label } from "reactstrap";
import Loader from "../../layouts/Loader";
import _ from "lodash";
import Axios from "axios";
import { Input } from "reactstrap";
import moment from "moment";
const AgentLeadReport = () => {
  const { auth } = useSelector(({ auth }) => auth);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vertical, setVertical] = useState("")
  const [filters, setFilters] = useState({
    startDate: "2023-08-08",
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `/api/crm/get-agent-wise-lead-report?startDate=${filters.startDate}&endDate=${filters.endDate}&vertical=${vertical}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
      .then((res) => {

        setReport(res.data.assignLeadUser);
        // setReport(
        //   _.chain(res.data.totalLeadCountAll)
        //     .groupBy("vertical_type")
        //     .map((value, key) => ({ v1ertical: key, data: value }))
        //     .value(),
        //   "check"
        // );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [auth, filters.startDate, filters.endDate, vertical]);





  const getHotCount = (data = []) => {
    return data?.reduce?.(
      (accu, item) => {
        if (item?.lead_list?.priority === "Hot") {
          accu.h += 1;
        }
        if (item?.lead_list?.priority === "Warm") {
          accu.w += 1;
        }

        if (item?.lead_list?.priority === "Cold") {
          accu.c += 1;
        }

        if (!item?.lead_list?.priority) {
          accu.n += 1;
        }

        return accu;
      },
      { h: 0, w: 0, c: 0, n: 0 }
    );
  };

  const getSourceCount = (data = []) => {
    return data?.reduce?.(
      (accu, item) => {
        if (item?.lead_list?.source_id === 0) {
          accu.s += 1;
        }

        return accu;
      },
      { s: 0 }
    );
  };



  const getActivityCount = (data = []) => {
    return data?.reduce?.(
      (accu, item) => {
        if (item?.lead_list?.activity?.length) {
          accu.active += 1;
        }
        if (!item?.lead_list?.activity?.length) {
          accu.notActive += 1;
        }

        return accu;
      },
      { notActive: 0, active: 0 }
    );
  };

  const inputHandler = (e) => {
    e.persist();
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const MemberShipData = {};

  return (
    <Fragment>
      <MainWrapper>
        <div className="container">
          <div className=" mt-5">
            <div className="row mb-4">
              <div className="col-md-3">
                <h3 className="mb-0">Agent Lead Report</h3>
              </div>
             
              <div className="col-md-3 offset-md-3">
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label>End Date</Label>
                  <Input
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={inputHandler}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <label className="col-lg-4 control-label col-form-label col-12 text-lg-right">
                  Vertical 
                </label>
                <div className="col-lg-8">
                  <Input
                    type="select"
                    className="custom-select"
                    value={vertical}
                    
                    onChange={({ target }) =>
                      setVertical(target.value)
                    }
                  >
                    <option value="">select</option>
                    <option value="Membership">Membership</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Project Report">
                      Project Report
                    </option>
                    <option value="Courses">Courses</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Startup">Startup</option>

                    <option value="Edp">Edp</option>
                  </Input>
                
                </div>
              </div>
            </div>
            <Table bordered>
              <thead>
                <tr className="table-primary">
                  <th>S.No</th>
                  <th>Agent Name</th>
                  <th>Total Count</th>
                  <th>Action Initiated Count</th>
                  <th>Action Not Initiated Count</th>
                  <th>Hot Lead Count</th>
                  <th>Warm Lead Count</th>
                  <th>Cold Lead Count</th>
                  <th>Priority Not Added</th>
                  <th>Source Not Added</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Fragment>
                    <tr>
                      <td colSpan={7}>
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  (report ? report : [])?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <th>{item?.name}</th>
                        <td>{item?.assign_lead?.length}</td>
                        <td>{getActivityCount(item.assign_lead).active}</td>
                        <td>{getActivityCount(item.assign_lead).notActive}</td>
                        <td>{getHotCount(item?.assign_lead).h}</td>
                        <td>{getHotCount(item?.assign_lead).w}</td>
                        <td>{getHotCount(item?.assign_lead).c}</td>
                        <td>{getHotCount(item?.assign_lead).n}</td>
                        <td>{getSourceCount(item?.assign_lead).s}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </MainWrapper>
    </Fragment>
  );
};

export default AgentLeadReport;
