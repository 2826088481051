import _ from "lodash";

const initialState = {
  memberShips: [],
  projectReports: [],
  memberShipbyId: {},
  bookedMemberShips: [],
  bookedMemberShip: {},
  users: [],
  sectors: [],
  projects: [],
  loading: false,
  success: false,
  createStatus: false,
  errors: {},
  bookedMember: {},
  projectReportStatus: false,
  customizedprojectReportStatus: false,
};

const ProjectReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MEMBERSHIP_LIST_PENDING":
    case "MEMBERSHIP_PENDING":
    case "PROJECTREPORT_CREATE_PENDING":
    case "MEMBERSHIP_EDIT_PENDING":
    case "MEMBERSHIP_USER_PENDING":
    case "MEMBERSHIP_BY_ID_PENDING":
    case "SECTOR_LIST_PENDING":
    case "PROJECT_LIST_PENDING":
    case "CHANGE_MEMBERSHIP_STATE":
      return {
        ...state,
        loading: true,
        success: false,
        createStatus: false,
        errors: {},
        bookedMember: {},
        projectReportStatus: false,
        customizedprojectReportStatus: false,
      };
    case "MEMBERSHIP_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        bookedMemberShips: _.get(action.payload, "data", {}),
      };
    case "RESET_SUCCESS":
      return {
        ...state,
        success: false,
      };
    case "PROJECTREPPORT_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        projectReportStatus: true,
        projectReports: action.payload?.data?.projectReport,
      };
    case "CUSTOMIZE_PROJECTREPORT_CREATE_FULFILLED":
      return {
        ...state,
        success: action.payload,
        customizedprojectReportStatus: true,
      };
    case "MEMBERSHIP_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        users: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_BY_ID_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        memberShipbyId: _.get(action.payload, "data", {}),
      };
    case "SECTOR_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        sectors: _.get(action.payload, "data", {}),
      };
    case "PROJECT_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        success: true,
        projects: _.get(action.payload, "data", {}),
      };
    case "MEMBERSHIP_LIST_REJECTED":
    case "MEMBERSHIP_REJECTED":
    case "PROJECTREPORT_CREATE_REJECTED":
    case "MEMBERSHIP_EDIT_REJECTED":
    case "MEMBERSHIP_USER_REJECTED":
    case "MEMBERSHIP_BY_ID_REJECTED":
    case "CUSTOMIZE_PROJECTREPORT_CREATE_REJECTED":
      const {
        payload: {
          response: { status, data },
        },
      } = action;
      if (status === 422) {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          projectReportStatus: false,
          customizedprojectReportStatus: false,
          errors: _.get(data, "errors", {}),
        };
      } else {
        return {
          ...state,
          loading: false,
          success: false,
          createStatus: false,
          projectReportStatus: false,
          customizedprojectReportStatus: false,
          errors: _.get(data, "message", ""),
        };
      }

    case "MEMBERSHIP_EDIT_FULFILLED":
    case "PROJECTREPORT_CREATE_FULFILLED":
      return {
        ...state,
        loading: false,
        createStatus: true,
        projectReportStatus: true,
        bookedMember: _.get(action?.payload?.data, "payment", {}),
      };
    case "PROJECTREPORT_CREATE_REJECTED": 
    state = {
      ...state,
      loading: false,
      createStatus: false,
      error: _.get(action?.payload?.response.data, "data", {}),
      projectReportStatus: false
    };
    break;

    default:
      return state;
  }
};

export default ProjectReportReducer;
